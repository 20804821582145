import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { parse } from 'qs'

import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { MarketersTable } from '../table'
import { marketersDashboardStyles } from './marketers-dashboard.styles'
import { useLocation } from 'react-router-dom'
import { MARKETERS_DEFAULT_QUERY } from '../../constants'
import { setMarketers } from '../../slices'
import { useCombos } from '../../../combos/hooks/combos.hook'

export const MarketersDashboard = withStyles(marketersDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...MARKETERS_DEFAULT_QUERY,
    ...urlSearchParams,
  }

  useCombos(['tipo_mercado_comercializadora', 'provincias_comercializadora'])
  useEffect(() => {
    dispatch(setMarketers(searchParams))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container className={classes.container} maxWidth="false">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.marketers.title' })}
          </Typography>
        </Box>
        <Box mb={2}></Box>
        <Box>
          <MarketersTable />
        </Box>
      </Container>
    </Box>
  )
})
