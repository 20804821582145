import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'
import { useCombos } from '../../../combos/hooks'

export const CancelAppDialog = ({ close, application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { put } = requests
  const combos = useCombos(['motivos_anulacion_ecommerce'])

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const data = { ...formState }
    const id = application['id']
    setLoading(true)
    put(`/solicitudes/anular_solicitud/${id}`, data)
      .then(() => {
        setLoading(false)
        getApplication(application['id'])
        feedback('success', formatMessage({ id: 'pages.applications.feedback.1' }))
        close()
      })
      .catch(({ response }) => {
        console.debug('Error canceling applicaiton')
        feedback(
          'error',
          response.data?.message || formatMessage({ id: 'calls.default.error.description' })
        )
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <SelectInput
            required
            fullWidth
            name="motivo_anulacion_gestor"
            title={`${formatMessage({ id: 'pages.applications.cancelDialog.fields.2.title' })}:`}
            value={formState['motivo_anulacion_gestor']}
            values={combos.motivos_anulacion_ecommerce?.data || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton type="submit" color="primary" disabled={loading} loading={loading}>
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
