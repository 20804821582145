import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { withStyles, Container, Typography, Box, CircularProgress } from '@material-ui/core'

import { requests } from '../../../core/ajax'
import { CPDashboard } from '../../../features/control-panel'
import { CP_ITEMS_GESTOR, CP_ITEMS_DELEGADO, CP_ITEMS_GESTOR_IP } from '../constants'
import { controlPanelStyles } from './control-panel.styles'

export const ControlPanelPage = withStyles(controlPanelStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { get } = requests
  const role = useSelector((state) => state.global.role)

  const [counters, setCounters] = useState({})
  const [loading, setLoading] = useState(true)

  const cardsByRole = (role) => {
    switch (role) {
      case 'gestor':
        return CP_ITEMS_GESTOR
      case 'delegado':
      case 'gestor_gc':
        return CP_ITEMS_DELEGADO
      case 'gestor_ip':
        return CP_ITEMS_GESTOR_IP
      default:
        return console.debug('Error: role is not defined')
    }
  }

  useEffect(() => {
    if (role === 'gestor' || role === 'delegado' || role === 'gestor_gc') {
      get(`/solicitudes/contar_estados`)
        .then(({ data }) => {
          setCounters({ ...data })
          setLoading(false)
        })
        .catch(
          ({ response }) => response && console.error('Error recuperando contadores', response.data)
        )
    } else {
      setLoading(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={4} textAlign="center">
          <Typography variant="h4">{formatMessage({ id: 'pages.controlPanel.title' })}</Typography>
        </Box>
        <Box mb={4}>
          <CPDashboard {...{ counters, cards: cardsByRole(role) }} />
        </Box>
      </Container>
    </Box>
  )
})
