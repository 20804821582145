export const ADD_FIELDS_CONFIG = ({ formState, onChange, combos }) => ({
  INSTALADORES: {
    value: formState['empresa'],
    name: 'empresa',
    optionKey: 'empresa',
    title: 'Escoge Instalador',
    required: true,
    renderOption: (option) =>
      `${option['nombre']} - ${
        combos.cod_zona_zeus.data.find((provincia) => {
          return parseInt(provincia.key) === parseInt(option['comunidad_id'])
        })?.value
      }`,
    onChange,
  },
})
