import { IP_INITIAL_STATE, IP_ACTIONS_TYPES } from './ip-reducer.constants'

export const ipReducer = (state = IP_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case IP_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case IP_ACTIONS_TYPES.SET_IP:
      return {
        ...state,
        ip: payload,
        formState: payload,
        detailLoading: false,
      }
    case IP_ACTIONS_TYPES.SET_FORM_STATE:
      return {
        ...state,
        formState: payload,
      }
    case IP_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case IP_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }

    case IP_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    case IP_ACTIONS_TYPES.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case IP_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }
    case IP_ACTIONS_TYPES.SET_EDIT_MODE:
      return {
        ...state,
        editMode: payload,
      }
    case IP_ACTIONS_TYPES.SET_IP_STATE: {
      let newData = [...state.data]
      const rowIndex = newData.findIndex((row) => row.id === payload.id)
      newData[rowIndex] = { ...state.data[rowIndex], activa: payload.state }
      return {
        ...state,
        data: newData,
      }
    }
    case IP_ACTIONS_TYPES.SET_IP_EVENTS:
      return {
        ...state,
        events: payload,
      }
    default:
      throw new Error('unknown action')
  }
}
