import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { AccountCircleOutlined, AnnouncementOutlined, ExtensionOutlined } from '@material-ui/icons'
import { DescriptionOutlined } from '@material-ui/icons'

import { ThemeButton } from '../../../../ui'

const GUILD_BUTTONS = (history, id) => ({
  APPLICATIONS: {
    id: 'link_applications',
    icon: <AnnouncementOutlined />,
    onClick: () =>
      history.push({
        pathname: '/applications',
        search: `?gremio=${id}`,
      }),
  },
  IP: {
    id: 'linkIP',
    icon: <DescriptionOutlined />,
    onClick: () =>
      history.push({
        pathname: '/ip',
        search: `?gremio_ip_id=${id}`,
      }),
  },
  JCA: {
    id: 'linkJCA',
    icon: <DescriptionOutlined />,
    onClick: () =>
      history.push({
        pathname: '/jca',
        search: `?gremio_ip_id=${id}`,
      }),
  },
  ASSOCIATES: {
    id: 'link_adscribed',
    icon: <ExtensionOutlined />,
    onClick: () =>
      history.push({
        pathname: '/eeii',
        search: `?gremio_id=${id}`,
      }),
  },
  ASSOCIATES_IP3: {
    id: 'linkAssociatesIP3',
    icon: <ExtensionOutlined />,
    onClick: () =>
      history.push({
        pathname: '/eeii-ip',
        search: `?gremio_ip_id=${id}`,
      }),
  },
  COLLABORATORS: {
    id: 'link_collaborators',
    icon: <AccountCircleOutlined />,
    onClick: () =>
      history.push({
        pathname: '/collaborators',
        search: `?gremio=${id}`,
      }),
  },
})

const userButtons = (userRole, company) => {
  let buttons = []
  if (company.grabadora) {
    buttons = ['IP', 'JCA', 'ASSOCIATES_IP3', ...buttons]
  }
  if (company.captadora && userRole === 'call_center') {
    buttons = ['APPLICATIONS', ...buttons, 'ASSOCIATES']
  }
  if (userRole === 'call_center') {
    buttons = [...buttons, 'COLLABORATORS']
  }
  if (userRole === 'soporte_procesos' || userRole === 'e_commerce') {
    buttons = ['APPLICATIONS', 'ASSOCIATES','COLLABORATORS']

  }
  return buttons
}

export const GuildActionButtons = ({ model }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const userRole = useSelector((state) => state.global.role)

  const buttons = GUILD_BUTTONS(history, model.id)
  const buttonsIds = userButtons(userRole, model)

  return (
    <Box mb={1} display="flex" flexWrap="wrap">
      {buttonsIds.map((buttonId, i) => {
        const button = buttons[buttonId]
        return (
          <ThemeButton
            key={button.id}
            style={{ marginRight: i !== buttonsIds.length - 1 ? '1em' : 0, marginBottom: '1em' }}
            size="small"
            color="primary"
            onClick={() => button.onClick()}
            startIcon={button.icon}
          >
            {formatMessage({ id: `pages.guilds.headerButtons.${button.id}.title` })}
          </ThemeButton>
        )
      })}
    </Box>
  )
}

export default GuildActionButtons
