import React, { useState } from 'react'
import { Box, IconButton, InputAdornment, Chip } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { TextInput, FileUploaderAgnostic } from '../../../../ui'
import { useCommunicationsCalls } from './communications-calls.hook'

const TEXT_AREA_LENGTH = 250

export const Sender = ({ application, target, getMessages }) => {
  const { sendMessage } = useCommunicationsCalls()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleRemoveFile = () => handleChangeForm({ target: { name: 'file', value: null } })

  const handleSendMessage = () => {

    setLoading(true)
    const payload = {
      mensaje: formState.message,
      solicitud_id: application.id,
      tipo_perfil_destinatario: target,
    }
    sendMessage(application.id, application.usuario_sir_id, payload, formState.file)
      .then(() => {
        setFormState({})
        getMessages()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box display="flex">
      <Box flex={1}>
        <TextInput
          name="message"
          value={formState.message}
          multiline
          inputProps={{ maxLength: TEXT_AREA_LENGTH }}
          onChange={handleChangeForm}
          InputProps={{
            endAdornment: !formState.file ? (
              <InputAdornment position="end">
                <FileUploaderAgnostic
                  {...{
                    name: 'file',
                    maxSize: 10000000, // 10Mb
                    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
                    onSuccess: handleChangeForm,
                    onError: () => console.error('Error attaching file'),
                  }}
                >
                  {() => <AttachFileIcon style={{ cursor: 'pointer' }} />}
                </FileUploaderAgnostic>
              </InputAdornment>
            ) : null,
          }}
        />
        {formState.file ? (
          <Chip
            avatar={<AttachFileIcon style={{ backgroundColor: 'transparent' }} />}
            label={formState.file.name}
            color="primary"
            onDelete={handleRemoveFile}
          />
        ) : null}
      </Box>
      <Box>
        <IconButton
          onClick={handleSendMessage}
          color="primary"
          disabled={!formState.message || loading}
        >
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  )
}
