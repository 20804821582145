import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ThemeButton } from '../../../../ui'

export const AddCampaignBtn = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()

  const handleNavigate = () => {
    const props = {
      pathname: '/campaigns/new',
      state: { prevSearch: history.location.search },
    }
    history.push(props)
  }

  return (
    <ThemeButton color="primary" onClick={handleNavigate}>
      {formatMessage({ id: 'pages.campaigns.button.new.label' })}
    </ThemeButton>
  )
}
