import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, CircularProgress } from '@material-ui/core'

import { loginUser } from '../../features/global'
import { useUserContext } from '../../core/user'
import { PrivateRouter } from './private.router'
import { PublicRouter } from './public.router'
import { NoticesPopupComponent } from "../../features/notices";

export const BootstrapRouter = () => {
  const dispatch = useDispatch()
  const { userState } = useUserContext()

  const isAuthorized = useSelector((state) => state.global.isAuthorized)
  const userId = useSelector((state) => state.global.userId)

  useEffect(() => {
    if (!isAuthorized && userState['token']) dispatch(loginUser(userState))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return useMemo(
    () =>
      isAuthorized || userState['token'] ? (
        !userId ? (
          <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
            <CircularProgress size={40} />
          </Box>
        ) : (
          <>
          <PrivateRouter />
          </>
        )
      ) : (
        <PublicRouter />
      ),
    [isAuthorized, userState] // eslint-disable-line react-hooks/exhaustive-deps
  )
}
