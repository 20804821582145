import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'

import { useCampaignsCalls } from './campaigns-calls.hook'
import { campaignsReducer } from '../reducers'
import { CAMPAINGS_INITIAL_STATE, CAMPAINGS_ACTIONS_TYPES } from '../reducers'

export const useCampaignsReducer = () => {
  const [state, dispatch] = useReducer(campaignsReducer, CAMPAINGS_INITIAL_STATE)
  const history = useHistory()
  const {
    getCampaignsCall,
    getCampaignCall,
    putCampaignCall,
    postCampaignCall,
    putCampaignStatusCall,
  } = useCampaignsCalls()

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: CAMPAINGS_ACTIONS_TYPES.SET_LOADING,
      })
      getCampaignsCall(config)
        .then(({ data }) => {
          dispatch({
            type: CAMPAINGS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['campanas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_campanas: data['total_campanas'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: CAMPAINGS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getCampaign = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: CAMPAINGS_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getCampaignCall(id)
          .then(({ data }) => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_CAMPAIGN,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const editCampaign = useCallback(
    (payload) => {
      const { id, ...restPayload } = payload
      return new Promise((resolve, reject) => {
        putCampaignCall(id, restPayload)
          .then(({ data }) => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_CAMPAIGN,
              payload: data,
            })
            return data
          })
          .then((data) => resolve(data))
          .catch(() => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const changeCampaignStatus = useCallback(
    (id, payload) => {
      return new Promise((resolve, reject) => {
        putCampaignStatusCall(id, payload)
          .then(({ data }) => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_CAMPAIGN_STATUS,
              payload: { id, state: data['activa'] },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const createCampaign = useCallback(
    (payload) => {
      return new Promise((resolve, reject) => {
        postCampaignCall({ ...payload })
          .then(({ data }) => resolve(data))
          .catch(() => {
            dispatch({
              type: CAMPAINGS_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: CAMPAINGS_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/campaigns/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  return {
    setSelectedTableRows,
    onClickTableRow,
    getTableData,
    getCampaign,
    editCampaign,
    createCampaign,
    changeCampaignStatus,
    ...state,
  }
}
