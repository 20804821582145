export const passwordInputStyles = {
  root: {
    margin: '12px 0',
    width: '100%',
  },
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    fontSize: 16,
  },
  input: {
    height: 48,
  },
}
