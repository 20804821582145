import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import feedback from '../../../core/feedback'

export const useInstallerCalls = () => {
  const { get, postPublic, putById } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getInstallers = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/instalador/tabla_usuarios', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resendValidation = useCallback(
    (mail) =>
      new Promise((resolve, reject) => {
        postPublic(`/usuario/resend_mail/${mail}`, { area: 'instalador' })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateInstallerInfo = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById(`/instalador`, id, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data?.code === 4304) {
                  feedback(
                    'error',
                    'Ya existe otro usuario de empresa externa con el mail de acceso indicado'
                  )
                  return reject('mail_in_use')
                }
                defaultError()
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getInstallers,
    resendValidation,
    updateInstallerInfo,
  }
}
