import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AcceptationDialog, ConfirmationDialog } from '../../../ui'

import { ModalContext } from '../../../ui/modal/contexts'
import feedback from "../../../core/feedback";

export const useExtractionDialogs = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useContext(ModalContext)

  const confirmExtractionProcess = (email, action) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: 'Extracción de datos',
        text:
          '¿Quieres extraer los datos actuales en el formato de la extracción diaria? ' +
          formatMessage({ id: 'operations.extraction.dialog.success.text' }, { email }),
        yesText: 'Continuar',
        noText: 'Cancelar',
        yesAction: () => {
          action()
          feedback("success", "Extracción de datos en proceso")
        },
        awaiting: false,
      },
    })
  }

  const confirmExtractionProcessByDates = (email, action) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: 'Extracción de datos',
        text:
            '¿Quieres extraer los datos actuales en el formato de la extracción diaria? ' +
            formatMessage({ id: 'operations.extraction.dialog.success.text' }, { email }),
        yesText: 'Continuar',
        noText: 'Cancelar',
        yesAction: () => {
          action()
          feedback("success", "Extracción de datos en proceso")
        },
        fromTo: true,
        awaiting: false,
      },
    })
  }

  const announceReceptionEmail = (email) => {
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'operations.extraction.dialog.success.title' }),
        text: formatMessage({ id: 'operations.extraction.dialog.success.text' }, { email }),
        yesText: formatMessage({ id: 'operations.extraction.dialog.success.yes' }),
      },
      type: 'centered',
    })
  }
  const announcePendingProcess = (email) => {
    open({
      Component: AcceptationDialog,
      data: {
        title: formatMessage({ id: 'operations.extraction.dialog.pending.title' }),
        text: formatMessage({ id: 'operations.extraction.dialog.pending.text' }, { email }),
        yesText: formatMessage({ id: 'operations.extraction.dialog.pending.yes' }),
      },
      type: 'centered',
    })
  }

  return {
    confirmExtractionProcess,
    confirmExtractionProcessByDates,
    announceReceptionEmail,
    announcePendingProcess,
  }
}
