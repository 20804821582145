export const ADD_FORM_CONFIG = ({
  formState,
  combos,
  intl,
  onChange,
  municipios = [],
  provincias = [],
}) => {
  const fm = intl.formatMessage
  return {
    COMUNIDAD: {
      fullWidth: true,
      value: formState['comunidad_id'],
      values: combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data,
      name: 'comunidad_id',
      title: fm({ id: 'pages.gran_consumo.fields.comunidad.title' }),
      onChange,
    },
    PROVINCIA: {
      fullWidth: true,
      value: formState['provincia_id'],
      values: provincias,
      name: 'provincia_id',
      title: fm({ id: 'pages.gran_consumo.fields.provincia.title' }),
      disabled: !formState['comunidad_id'],
      onChange,
    },
    MUNICIPIO: {
      fullWidth: true,
      value: formState['municipio'],
      name: 'municipio',
      title: fm({ id: 'pages.gran_consumo.fields.municipio.title' }),
      disabled: !formState['provincia_id'],
      onChange,
    },
    RANGO_PRESION: {
      fullWidth: true,
      value: formState['grupo_presion'],
      values: combos.grupo_presion.data,
      name: 'grupo_presion',
      title: fm({ id: 'pages.gran_consumo.fields.grupo_presion.title' }),
      onChange,
    },
    COD_POSTAL: {
      required: true,
      value: formState['cod_codigo_postal'],
      name: 'cod_codigo_postal',
      title: fm({ id: 'pages.gran_consumo.fields.cod_postal.title' }),
      disabled: !formState['municipio'],
      onChange,
    },
    MERCADO: {
      fullWidth: true,
      value: formState['mercado_id'],
      values: combos.tipo_mercado_gc.data,
      name: 'mercado_id',
      title: fm({ id: 'pages.gran_consumo.fields.mercado.title' }),
      onChange,
    },
    GESTOR: {
      fullWidth: true,
      value: formState['gestor_id'],
      values: combos.gestores.data,
      name: 'gestor_id',
      title: fm({ id: 'pages.gran_consumo.fields.gestor.title' }),
      onChange,
    },
  }
}
