import React from 'react'
import { useIntl } from 'react-intl'
import {
  withStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'

import { basicTableStyles } from './basic-table.styles'

const defaultKey = (i, row) => i

export const BasicTable = withStyles(basicTableStyles)(
  ({ rowKey = defaultKey, classes, config, data, context, getdata, size = 'medium', ...props }) => {
    const { formatDate, formatTime, formatMessage } = useIntl()

    const getParsedValue = (type, value, Component, context, row) => {
      switch (type) {
        case 'date':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatDate(new Date(value))}
            </Typography>
          )
        case 'datetime':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatDate(new Date(value)) + ' - ' + formatTime(new Date(value))}
            </Typography>
          )
        case 'component':
          return <Component value={value} context={context} row={row} />
        case 'responsable':
          const finalValue = row[value] ? `${row[value]} (${row['rol_usuario']})` : null
          return finalValue
        case 'comboDocumentData':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatMessage({ id: `pages.supervision.edit.documents.${value}` })}
            </Typography>
          )
        case 'comboData':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatMessage({ id: `global.basic_table.${value}` })}
            </Typography>
          )
        default:
          return value
      }
    }
    return (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table" size={size}>
          <TableHead>
            <TableRow>
              {config.map(({ align = 'left', label }, i) => (
                <TableCell key={i} align={align}>
                  <Typography variant="subtitle1">{label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow
                key={rowKey(i, row)}
                className={
                  props.onClick
                    ? props.loading
                      ? classes.loadingRow
                      : classes.clickableRow
                    : classes.row
                }
                hover={!!props.onClick}
                onClick={() => (props.onClick ? props.onClick(row) : null)}
              >
                {config.map(({ key, align = 'left', type, component }, i) => (
                  <TableCell key={i} align={align}>
                    {getParsedValue(type, row[key], component, context, row)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
)
