import React from 'react'

import { IPContext } from '../../features/ip'
import { IPRouter } from './routers'

export const IPPage = () => {
  return (
    <IPContext>
      <IPRouter />
    </IPContext>
  )
}
