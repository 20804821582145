import { useCallback } from 'react'
import { requests } from '../../../core/ajax'
import { useSelector } from 'react-redux'
import feedback from '../../../core/feedback'

export const useGCCalls = () => {
  const { get, post, put } = requests
  const combos = useSelector((state) => state.combos)

  const getGestoresGC = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('gestor/tabla_gran_consumo', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postGestorGc = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        const mercadoId = combos.tipo_mercado.data.find((mercado) => {
          return mercado.key === payload['mercado_id']
        }).id
        const parsedPayload = { ...payload, mercado_id: mercadoId }
        post('/zona_gran_consumo', parsedPayload)
          .then((response) => {
            resolve(response)
            feedback('success', 'cp guardado con exito')
          })
          .catch(({ response }) => {
            if (response) {
              if (response.status === 400 && response.data.code === 12202) {
                feedback('error', response.data.message)
              }
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getMunicipiosByProvincia = useCallback(
    (provincia_id) =>
      new Promise((resolve, reject) => {
        get('/potenciales/municipios', { params: { provincias: provincia_id } })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putReasingGestor = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        put('/zona_gran_consumo/reasignar', payload)
          .then((response) => {
            resolve(response)
            feedback('success', 'cp reasigando con exito')
            window.location.reload()
          })
          .catch(({ response }) => {
            if (response) {
              if (response.status === 400 && response.data.code === 12202) {
                feedback('error', response.data.message)
              } else {
                feedback('error', 'Se ha producido un error inesperado')
              }
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getGestoresGC,
    postGestorGc,
    getMunicipiosByProvincia,
    putReasingGestor,
  }
}
