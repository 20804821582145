import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Grid, ListItemText, TextField, FormHelperText } from '@material-ui/core'

import { SelectInput } from '../../../ui'
import { gridRowStyles } from './grid-row.styles'

const ROWS_MAX = 4
export const GridRowComponent = withStyles(gridRowStyles)(
  ({
    classes,
    items = [],
    data,
    spacing = 4,
    justify = 'flex-start',
    rowsMax = ROWS_MAX,
    ...props
  }) => {
    const intl = useIntl()

    const handleEnterKey = (event) => {
      if (event.keyCode === 13) {
        props.onChange(event)
      }
    }

    const getValueByType = (item, data) => {
      if (data[item.value] === null) return '--'
      if (item.type === 'combo') {
        let selectedOption =
          props.combos &&
          props.combos[item.comboId] &&
          props.combos[item.comboId].data &&
          props.combos[item.comboId].data.length !== 0 &&
          props.combos[item.comboId].data.find((o) => o.key === data[item.value])
        if (!selectedOption) {
          selectedOption = {}
        }
        return selectedOption.value || data[item.value]
      } else return data[item.value]
    }

    return (
      <Grid container spacing={spacing} justifyContent={justify}>
        {items.map((item, i) => {
          const error = props.alert ? props.alert[item.name] : null
          return (
            <Grid item key={i} style={item.fullWidth ? { flex: '1' } : {}}>
              {/* I wanted to add className={classes.text} somewhere to align the "props" to the right, but it's not obvious how to do it and there's no time for small nuisances*/}
              <ListItemText
                style={{ whiteSpace: 'pre-line' }}
                primary={intl.formatMessage({ id: item.label })}
                secondary={
                  <span>
                    {item.type === 'input' ? (
                      <TextField
                        type={item.subtype === 'numeric' ? 'number' : 'text'}
                        id={item.name}
                        name={item.name}
                        variant="outlined"
                        defaultValue={
                          item.valueParsed ? item.valueParsed(data, intl) : data[item.value]
                        }
                        onBlur={props.onChange}
                        onKeyDown={handleEnterKey}
                        size="small"
                        disabled={
                          item.disabledByApp
                            ? item.disabledByApp(data)
                            : props.disabled || item.disabled
                        }
                      />
                    ) : item.type === 'select' ? (
                      <SelectInput
                        name={item.name}
                        label={item.label}
                        value={data[item.name]}
                        onChange={props.onChange}
                        values={
                          props.combos && props.combos[item.comboId]
                            ? props.combos[item.comboId].data
                            : []
                        }
                        disabled={
                          item.disabledByApp
                            ? item.disabledByApp(data)
                            : props.disabled || item.disabled
                        }
                        // loading={!props.combos[item.value] || props.combos[item.value].isLoading}
                      />
                    ) : item.type === 'text_area' ? (
                      <TextField
                        maxRows={rowsMax}
                        fullWidth={item.fullWidth}
                        type="text"
                        name={item.name}
                        variant="outlined"
                        defaultValue={
                          item.valueParsed
                            ? item.valueParsed(data, intl)
                            : data[item.value] && data[item.value] !== ''
                            ? data[item.value]
                            : '--'
                        }
                        onBlur={props.onChange}
                        onKeyDown={handleEnterKey}
                        disabled={item.disabled}
                        multiline={item.multiline}
                        style={{ flex: '1' }}
                      />
                    ) : item.valueParsed ? (
                      item.valueParsed(data, intl) || '--'
                    ) : (
                      getValueByType(item, data)
                    )}
                  </span>
                }
                primaryTypographyProps={{ variant: 'subtitle2', component: 'span' }}
                secondaryTypographyProps={{ variant: 'body2', component: 'span' }}
              />
              {!!error ? (
                <FormHelperText id="my-helper-text" error={!!error}>
                  {error}
                </FormHelperText>
              ) : null}
            </Grid>
          )
        })}
        {props.append ? props.append : null}
      </Grid>
    )
  }
)
