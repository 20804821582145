import { useCallback } from 'react'

const { REACT_APP_WP_CONTENT_CREATOR_URL } = process.env

export const useWordpressTokenGenerator = () => {
  const openWordpressUrl = useCallback(() => {
    window.open(`${REACT_APP_WP_CONTENT_CREATOR_URL}/wp-admin/edit.php?post_type=page`)
  }, [])

  return {
    openWordpressUrl,
  }
}
