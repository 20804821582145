export const RESET = 'Reset state'
export const SET_LOADING = 'Set loading'
export const SET_STEP = 'Set step'
export const NEXT_STEP = 'Next step'
export const PREV_STEP = 'Previous step'
export const SET_ADDRESS_FORM = 'Set address form'
export const SET_TOWN_VALUES = 'Set town values'
export const SET_INSTALLER_VALUES = 'set installer values'
export const SET_ADDRESS_VALUES = 'Set address values'
export const SET_NUMBER_VALUES = 'Set number values'
export const SET_HOME_VALUES = 'Set home values'
export const RESET_ADDRESS_FORM = 'Reset home values'
export const SET_CONSULT_DATA = 'Set consult data'
export const SET_SELECTED_HOME = 'Set selected home'
export const SET_TMP_APPLICATION = 'Set tmp application'
export const SET_CUSTOMER_FORM = 'Set customer form'
export const SET_CUSTOMER_FORM_ARRAY = 'set customer form array'
export const CHANGE_TO_UNNORMALIZED = 'Change to unnormalized'
export const RENORMALIZE = 'Renormalize'
export const PREPARE_CERTIFICATE_FORM = 'Prepare certificate form'
export const SET_CERTIFICATE_FORM = 'Set certificate form'
export const SET_EMPRESA = 'Set empresa'
export const SET_IS_CONTRATABLE = 'Set is contratable'
