import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton } from '../../../../ui'
import { INPUTS_CONFIG, FORM_CONFIG } from './public-offer-form.constants'
import { GridForm } from './grid-form.component'
import { usePublicOfferContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'
import { usePublicOfferValidations } from '../../hooks'
import { preparePublicOfferDto } from './public-offer-form.utils'

export const PublicOfferCreateForm = () => {
  const permissions = useSelector((state) => state.global.permissions)
  const history = useHistory()
  const intl = useIntl()
  const combos = useCombos(['gremios'])
  const { createPublicOffer } = usePublicOfferContext()
  const { formErrors } = usePublicOfferValidations()

  const [formState, setFormState] = useState({
    activa: true,
    financiacion: '00',
    origen: 'instalador',
    is_op: true,
    tipo_gas: 'GAS_NATURAL',
    tiempograbacion: 0,
  })
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (e) => {
    if (e.target.name === 'origen') {
      eraseGremiosAndProvsFields(e)
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const eraseGremiosAndProvsFields = (e) => {
    delete formState.comunidades
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const errors = formErrors(formState)
    if (!errors) {
      setLoading(true)

      createPublicOffer(preparePublicOfferDto(formState))
        .then((data) => {
          setLoading(false)
          history.push(`/public-offer/${data.id}`)
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setErrorMssg(errors)
    }
  }
  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: false,
  })
  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" mt={2} mb={2} justifyContent="flex-end">
        {permissions['editar_op'] ? (
          <ThemeButton type="submit" color="primary" loading={loading}>
            {intl.formatMessage({
              id: `pages.campaigns.create.button.save.label`,
            })}
          </ThemeButton>
        ) : null}
      </Box>
      {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
      <GridForm {...{ grid: FORM_CONFIG, inputs: INPUTS }} />
    </form>
  )
}
