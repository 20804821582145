import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Hidden, Box, Tooltip } from '@material-ui/core'

import { LoginFormComponent } from '../../../features/login'
import { loginStyles } from './login.styles'
import { ReactComponent as LogoNedgiaFull } from '../../../resources/images/logo_nedgia_full.svg'
import { APP_VERSION_MSSG } from '../constants'

export const LoginPage = withStyles(loginStyles)(({ classes }) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <div className={classes.leftWrapper} />
      </Hidden>
      <div className={classes.rightWrapper}>
        <Container className={classes.container} maxWidth="sm">
          <div className={classes.panel}>
            <Box mb={6} textAlign="center">
              <Tooltip title={APP_VERSION_MSSG}>
                <LogoNedgiaFull className={classes.logo} />
              </Tooltip>
            </Box>
            <Box mb={4}>
              <Typography variant="h5">{formatMessage({ id: 'pages.login.title' })}</Typography>
            </Box>
            <Box maxWidth={370}>
              <LoginFormComponent />
            </Box>
          </div>
        </Container>
      </div>
    </div>
  )
})
