import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { PendingDatesDialog } from '../dialogs/pending-dates.dialog'

export const PendingDatesBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, updateApplication, generateBudget } = useSirContext()

  const handleValidateDates = () => {
    const dia_cierre = application.presupuesto_sir.dia_cierre
    const hora_cierre = application.presupuesto_sir.hora_cierre
    const dia_apertura = application.presupuesto_sir.dia_apertura
    const hora_apertura = application.presupuesto_sir.hora_apertura
    open({
      Component: PendingDatesDialog,
      data: {
        dia_cierre,
        hora_cierre,
        dia_apertura,
        hora_apertura,
        application,
        updateApplication,
        generateBudget,
      },
    })
  }

  return (
    <ThemeButton color="primary" onClick={handleValidateDates}>
      {formatMessage({ id: 'pages.application.detail.validateProgrammingDatesBtn.title' })}
    </ThemeButton>
  )
}
