import React,{useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { FilterInput } from '../filter-input'
import { FilterDate } from '../filter-date'
import { FilterSwitch } from '../filter-switch'
import { FilterAutocomplete } from '../filter-autocomplete'
import { FilterBasicInput } from '../filter-basic-input'

export const FilterURL = ({ filterType = 'input', filterComponent, ...item }) => {
  const history = useHistory()
  const location = useLocation()

  const appName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
  const currentSearch = new URLSearchParams(location.search)


  useEffect(() => {
    const value = localStorage.getItem(appName + item.name)
    if (value) {
      currentSearch.set(item.name, value)
    }
  }, []);

  //Url managing functions (added conveniently to not yet assembled filters)
  const handleChangeFilter = (event, cleanup) => {
    currentSearch.set('_pagina', 1)
    if(event.target.value) {
      if(event.target.value === '' || (Array.isArray(event.target.value) && event.target.value.length === 0)) {
        currentSearch.delete(event.target.name)
        localStorage.removeItem(appName + event.target.name)
      } else {
        currentSearch.set(
          event.target.name,
          Array.isArray(event.target.value) ? event.target.value.join(',') : event.target.value
        )
        localStorage.setItem(appName + event.target.name, event.target.value)
        if (cleanup) {
          cleanup.forEach((field) => {
            currentSearch.delete(field)
            localStorage.removeItem(appName + field)
          })
        }
      }

    }
    else {
      currentSearch.delete(event.target.name)
      localStorage.removeItem(appName + event.target.name)
    }

    if (cleanup) {
      cleanup.forEach((field) => {
        currentSearch.delete(field)
        localStorage.removeItem(appName + field)
      })
    }
    history.replace({ search: '?' + currentSearch.toString() })
  }
  const handleToggleFilter = (event, cleanup, cleanSelf) => {
    currentSearch.set('_pagina', 1)

    const value = !(currentSearch.get(event.target.name) === 'true')
    currentSearch.set(event.target.name, value)
    if (cleanup) {
      cleanup.forEach((field) => {
        currentSearch.delete(field)
      })
    }
    if (cleanSelf && !value) {
      currentSearch.delete(event.target.name)
    }
    history.replace({ search: '?' + currentSearch.toString() })
  }

  //Filter choice (applied unless component is submitted already assembled)
  const defaultComponent = (type, { cleanSelf, cleanup, ...item }) => {
    if (!item.name) {
      throw new Error('A nameless filter cannot be managed automatically via url')
    }
    switch (type) {
      case 'switch':
        return (
          <FilterSwitch
            {...{
              onClick: (event) => handleToggleFilter(event, cleanup, cleanSelf),
              checked: currentSearch.get(item.name) === 'true',
              ...item,
            }}
          />
        )
      case 'date':
        const { minDateField, maxDateField, ...newItem } = item
        return (
          <FilterDate
            {...{
              onChange: (event) => handleChangeFilter(event, cleanup),
              value: currentSearch.get(item.name) || item.default,
              minDate: currentSearch.get(minDateField),
              maxDate: currentSearch.get(maxDateField),
              ...newItem,
            }}
          />
        )
      case 'autocomplete':
        return (
          <FilterAutocomplete
            {...{
              onChange: (event) => handleChangeFilter(event, item.cleanup),
              value: currentSearch.get(item.name) || item.default,
              ...item,
            }}
          />
        )
      case 'basic-input':
        return (
          <FilterBasicInput
            {...{
              onChange: (event) => handleChangeFilter(event, item.cleanup),
              value: currentSearch.get(item.name) || item.default,
              ...item,
            }}
          />
        )
      case 'input':
      default:
        return (
          <FilterInput
            {...{
              onChange: (event) => handleChangeFilter(event, item.cleanup),
              value: currentSearch.get(item.name) || item.default,
              ...item,
            }}
          />
        )
    }
  }
  return filterComponent || defaultComponent(filterType, item)
}

export default FilterURL
