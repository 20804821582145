import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { InvalidatePaymentConfirmDialog } from '../dialogs'

export const InvalidatePaymentBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, invalidatePayment, editMode } = useSirContext()

  const handleValidatePayment = () => {
    open({
      Component: InvalidatePaymentConfirmDialog,
      data: { invalidatePayment, application },
    })
  }

  return (
    <ThemeButton color="primary" disabled={editMode} onClick={handleValidatePayment}>
      {formatMessage({ id: 'pages.sir.detail.button.invalidatePayment.label' })}
    </ThemeButton>
  )
}
