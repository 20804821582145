import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const managersSlice = createSlice({
  name: 'managers',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedUser: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setManagersData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload
    },
  },
})

export const managersReducer = managersSlice.reducer

export const { setLoading, setManagersData } = managersSlice.actions

export const setManagers = (searchParams) => (dispatch) => {
  const { _pagina, _num_x_pagina, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }

  dispatch(setLoading(true))
  get(`gestor/tabla_gestores`, config)
    .then(({ data }) => {
      dispatch(
        setManagersData({
          data: data.registros,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_comercializadoras: data['total_registros'],
          },
        })
      )
    })
    .catch(({ response }) => {
      console.error('Error recuperando comercializadoras', response)
      dispatch(setLoading(false))
    })
}

export const toggleManagerActive = (row, managers) => (dispatch) => {
  const { putById } = requests

  putById('/gestor', row['id'], { activo: !row['activo'] })
    .then(({ data }) => {
      feedback('success', 'Cambio realizado con éxito')
      const newManager = managers.slice()
      newManager[row.tableData.id] = { ...newManager[row.tableData.id], activo: data['activo'] }
      dispatch(
        setManagersData({
          data: newManager,
        })
      )
    })
    .catch(() => {
      feedback('error', 'No se han podido guardar los cambios')
      console.error('Error changing marketer state')
    })
}

export const editManager = (row, payload, managers) => (dispatch) => {
  const { putById } = requests
  const isPermissionsRequest = payload.extra_permission ? true : false

  putById('/gestor', row['id'], payload)
    .then(({ data }) => {
      feedback('success', 'Cambio realizado con éxito')
      const newManager = managers.slice()
      newManager[row.tableData.id] = !isPermissionsRequest
        ? payload
        : { ...newManager[row.tableData.id], extra_permission: payload }

      dispatch(
        setManagersData({
          data: newManager,
        })
      )
    })
    .catch(() => {
      feedback('error', 'No se han podido guardar los cambios')
      console.error('Error changing marketer state')
    })
}

export const addManager = (payload, managers) => (dispatch) => {
  const { post } = requests

  post('/gestor', payload)
    .then(({ data }) => {
      feedback('success', 'Cambio realizado con éxito')
      const newManager = managers.slice()
      newManager.push(payload)
      dispatch(
        setManagersData({
          data: newManager,
        })
      )
    })
    .catch(({ response }) => {
      if (response.data.code === 4313) {
        feedback('error', 'El gestor ya existe')
      } else {
        if(response.data?.message ) {
          feedback('error', response.data.message)
        }
        else {
          feedback('error', 'No se han podido guardar los cambios')
          console.error('Error changing marketer state')
        }
      }
    })
}
