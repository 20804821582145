import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { IPFilter, IPTable } from '../../../../features/ip'
import { useCustomCombo } from '../../../../features/combos'
import { IP_CUSTOM_COMBOS_IDS } from '../../constants'
import { ipDashboardStyles } from './ip-dashboard.styles'

export const IPDashboardPage = withStyles(ipDashboardStyles)(({ classes }) => {
  useCustomCombo(IP_CUSTOM_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.ip.dashboard.title' })}
        </Typography>
      </Box>
      <Box mb={2} display="flex">
        <IPFilter />
      </Box>
      <Box>
        <IPTable />
      </Box>
    </Container>
  )
})
