import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCombos } from '../../../combos/hooks'
import { useJCAContext } from '../../contexts'
import { GridForm } from './grid-form.component'
import { INPUTS_CONFIG, FORM_CONFIG, JCA_NONCONFORMITY_STATES } from './jca-form.constants'
import { JCA_VALIDATED_DELIVERY_FAILED_STATE } from './jca-form.constants'
import { JCANonconfomityView } from './jca-nonconformity-view.component'
import { JCAStateBtns } from './jca-state-btns.component'

export const JCAForm = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { jca } = useJCAContext()

  const [formState, setFormState] = useState(jca)

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: true,
  })

  const GRID = FORM_CONFIG({ intl })

  useEffect(() => setFormState(jca), [jca])

  return (
    <form>
      <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
        <JCAStateBtns />
      </Box>
      {jca.estado === JCA_VALIDATED_DELIVERY_FAILED_STATE && !!jca.error_zeus?.length ? (
        <Alert severity="warning">
          <Typography variant="subtitle1">Envío a Zeus fallido: </Typography>
          {jca.error_zeus.map((error, i) => (
            <Typography key={i} variant="body1" style={{ marginLeft: 10 }}>
              - {error.mensaje}
            </Typography>
          ))}
        </Alert>
      ) : null}
      {JCA_NONCONFORMITY_STATES[jca.estado] ? (
        <JCANonconfomityView />
      ) : (
        <GridForm {...{ grid: GRID, inputs: INPUTS }} />
      )}
    </form>
  )
}
