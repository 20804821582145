import { parse, stringify } from 'qs'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { loaderActions } from '../slices'

const DEFAULT_MIN_REFRESH_MS = 1000 * 60 * 60 * 24

export const useLoaderBasic = ({ identifier, call }) => {
  const state = useSelector((state) => state.loader[identifier] || {})
  const dispatch = useDispatch()

  const loadData = useCallback(
    async (config = {}) => {
      await dispatch(loaderActions.blockLoad({ identifier, config }))
      await call(config)
        .then(({ data }) => {
          dispatch(loaderActions.blockData({ identifier, data }))
        })
        .catch((err) => {
          dispatch(loaderActions.blockFail({ identifier, error: err }))
        })
    },
    [dispatch, call, identifier]
  )

  const addExtra = useCallback(
    (extra = {}) => dispatch(loaderActions.blockExtra({ identifier, extra })),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return { state, loadData, addExtra }
}

export const useLoader = ({ minRefreshMs = DEFAULT_MIN_REFRESH_MS, ...props }) => {
  //It should load when the user comes back to the page and minRefreshMs has passed since last load
  const loader = useLoaderBasic(props)
  const {
    loadData,
    state: { loading, msLoaded },
  } = loader

  useEffect(() => {
    if (!loading && (!msLoaded || !(Date.now() - msLoaded < minRefreshMs))) {
      loadData()
    }
  }, [loadData]) // eslint-disable-line react-hooks/exhaustive-deps

  return loader
}

export const useURLLoader = ({
  defaultChoices,
  minRefreshMs = DEFAULT_MIN_REFRESH_MS,
  ...props
}) => {
  //It should load under any of these circumstances:
  // - A: search parameters did change
  // - B: the user comes back to the page and minRefreshMs has passed since last load with the same config

  const loader = useLoaderBasic(props)
  const {
    loadData,
    state: { loading, msLoaded, config },
  } = loader

  const search = useLocation().search

  useEffect(() => {
    const searchString = search.split('?')[1]
    const urlSearchParams = parse(searchString)
    const searchParams = {
      ...defaultChoices,
      ...urlSearchParams,
    }
    const trueSearch = stringify(searchParams)
    const lastSearch = config && config.params ? stringify(config.params) : null
    if (trueSearch === lastSearch) {
      if (!loading && (!msLoaded || !(Date.now() - msLoaded < minRefreshMs))) {
        loadData({ params: searchParams })
      }
    } else {
      loadData({ params: searchParams })
    }
  }, [loadData, search]) // eslint-disable-line react-hooks/exhaustive-deps

  return loader
}

export const useRowSelector = ({ identifier, selectFilter, idField = 'id' }) => {
  const data = useSelector((state) => selectFilter(state.loader[identifier])) || []
  const selection = useSelector((state) => state.loader[identifier]?.selection || [])
  const dispatch = useDispatch()
  const addSelection = (newSelection) => {
    dispatch(
      loaderActions.blockExtra({
        identifier,
        extra: {
          selection: [
            ...selection.filter((el) => {
              return data.map((item) => item[idField]).indexOf(el[idField]) === -1
            }),
            ...newSelection,
          ],
        },
      })
    )
  }
  return { selection, addSelection }
}
