import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../ui'

export const CreateApplicationDialog = ({
  close,
  role,
  changeToNotNormalized,
  dataUpdate,
  handleRedirectionToWordpress,
  handleOpenRequestGasP,
}) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    setLoading(true)
    role === 'gestor_gc'
      ? changeToNotNormalized(dataUpdate)
      : setTimeout(() => handleRedirectionToWordpress(), 100)
    setLoading(false)
    close()
  }

  const handleRequestGasP = () => {
    setLoading(true)
    handleOpenRequestGasP(dataUpdate)
    setLoading(false)
  }

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.consult.dialogs.unnormalize.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {formatMessage({ id: 'pages.consult.dialogs.unnormalize.text' })}
        </Typography>
        {role === 'gestor_gc' && (
          <Box mt={3}>
            <Typography variant="body1" style={{ marginRight: '0.7em' }}>
              {formatMessage({ id: 'pages.consult.dialogs.RequestGASP.title' })}
            </Typography>
            <Typography
              variant="body1"
              color="primary"
              onClick={handleRequestGasP}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {formatMessage({ id: 'pages.consult.dialogs.RequestGASP.subtitle' })}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ThemeButton labelM="small" onClick={handleConfirm} disabled={loading} loading={loading}>
          {formatMessage({ id: 'pages.consult.dialogs.unnormalize.yesText' })}
        </ThemeButton>
        <ThemeButton
          labelM="small"
          disabled={loading}
          loading={loading}
          onClick={() => {
            close()
          }}
        >
          {formatMessage({ id: 'pages.consult.dialogs.unnormalize.noText' })}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}
