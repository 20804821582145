import React from 'react'
import { HashRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ReduxProvider from '../../core/redux'
import ThemeProvider from '../../core/theme'
import IntlProvider from '../../core/i18n'
import { UserProvider } from '../../core/user'
import { AjaxProvider } from '../../core/ajax'
import { Modal as ModalProvider } from '../../ui/modal'
import { BootstrapRouter } from '../routers/bootstrap.router'
import OnCrash from '../onCrash/onCrash.component'
import { OnServerDown } from '../onServerDown/onServerDown.component'

export const BootstrapComponent = () => {
  return (
    <ReduxProvider>
      <ThemeProvider>
        <IntlProvider>
          <UserProvider>
            <AjaxProvider>
              <OnServerDown>
                <OnCrash>
                  <ModalProvider>
                    <HashRouter hashType="hashbang">
                        <BootstrapRouter />
                    </HashRouter>
                  </ModalProvider>
                </OnCrash>
              </OnServerDown>
            </AjaxProvider>
            <ToastContainer limit={1} theme="colored" bodyClassName="toastClassName" />
          </UserProvider>
        </IntlProvider>
      </ThemeProvider>
    </ReduxProvider>
  )
}
