import React from 'react'
import {
  withStyles,
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { checkListInputStyles } from './check-list-input.styles'

export const CheckListInput = withStyles(checkListInputStyles)(
  ({ classes, readOnly = false, ...props }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const handleChange = (e) => {
      props.onChange({ target: { name: "presupuesto_sir." + e.target.name, value: e.target.checked } })
    }

    const readOnlyValues = () => {
      let valuesArray = []
      props.values.forEach((item) => {
        if (props.value[item.key]) valuesArray.push(item.value)
      })
      return valuesArray
    }

    return (
      <FormControl component="fieldset" className={classes.root}>
        <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
          {props.title}
        </Typography>
        {readOnly ? (
          readOnlyValues().map((item, i) => (
            <Typography key={i} variant={`body${typographyVariant}`} color="textSecondary">
              {item}
            </Typography>
          ))
        ) : (
          <FormGroup>
            {props.values.map((item, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={props.value[item.key]}
                      onChange={handleChange}
                      name={item.key}
                      size="small"
                    />
                  }
                  label={<Typography variant="body2">{item.value}</Typography>}
                />
              )
            })}
          </FormGroup>
        )}
      </FormControl>
    )
  }
)
