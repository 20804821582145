import React from 'react'

import { SIR_STATES } from '../../constants'
import { FormActionBtns } from '../form-action-btns'
import { CheckListInput } from '../custom-inputs'
import { DateInput } from '../../../../ui'
import { EditCompanyBtn } from '../form-action-btns/edit-company-btn.component'
import { EditUserBtn } from '../form-action-btns/edit-user-btn.component'

export const CAN_EDIT_BUDGET_STATES = {
  [SIR_STATES.CREATED.KEY]: true,
  [SIR_STATES.BUDGET_CREATED.KEY]: true,
}

export const REQUIRED_GODA_STATES = {
  [SIR_STATES.ORDER_MADE.KEY]: true,
  [SIR_STATES.CONSTRUCTION.KEY]: true,
  [SIR_STATES.SCHEDULED.KEY]: true,
  [SIR_STATES.FINISHED.KEY]: true,
}

export const CAN_EDIT_GODA_STATES = {
  [SIR_STATES.PAYMENT_VALIDATED.KEY]: true,
  ...REQUIRED_GODA_STATES,
}

export const REQUIRED_GORICARO_STATES = {
  [SIR_STATES.CONSTRUCTION.KEY]: true,
  [SIR_STATES.SCHEDULED.KEY]: true,
  [SIR_STATES.FINISHED.KEY]: true,
}

export const CAN_EDIT_GORICARO_STATES = {
  [SIR_STATES.PAYMENT_VALIDATED.KEY]: true,
  [SIR_STATES.ORDER_MADE.KEY]: true,
  ...REQUIRED_GORICARO_STATES,
}

export const REQUIRED_REOPENING_STATES = {
  [SIR_STATES.SCHEDULED.KEY]: true,
  [SIR_STATES.FINISHED.KEY]: true,
}

export const CAN_EDIT_REOPENING_STATES = {
  [SIR_STATES.CREATED.KEY]: true,
  [SIR_STATES.BUDGET_CREATED.KEY]: true,
  [SIR_STATES.BUDGET_SENDED.KEY]: true,
  [SIR_STATES.VALIDATE_PAYMENT.KEY]: true,
  [SIR_STATES.PAYMENT_VALIDATED.KEY]: true,
  [SIR_STATES.ORDER_MADE.KEY]: true,
  [SIR_STATES.CONSTRUCTION.KEY]: true,
  [SIR_STATES.PENDING_CONFIRMATION_PROGRAMMING_DATES.KEY]: true,
  ...REQUIRED_REOPENING_STATES,
}

export const HIDDEN_EDIT_BTNS_STATES = {
  [SIR_STATES.BUDGET_SENDED.KEY]: true,
  [SIR_STATES.VALIDATE_PAYMENT.KEY]: true,
  // [SIR_STATES.FINISHED.KEY]: true,
  [SIR_STATES.REVIEW.KEY]: true,
  [SIR_STATES.CANCELLED.KEY]: true,
}

export const NOT_CANCELLABLE_STATES = {
  [SIR_STATES.CANCELLED.KEY]: true,
  [SIR_STATES.FINISHED.KEY]: true,
}

export const GRID_ITEM_EXTRA_SMALL = {
  item: true,
  xs: 6,
  sm: 6,
  md: 2,
}
export const GRID_ITEM_SMALL = {
  item: true,
  xs: 6,
  sm: 6,
  md: 3,
}
export const GRID_ITEM_MID = {
  item: true,
  xs: 6,
  sm: 6,
  md: 6,
}
export const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
}

const TEXT_AREA_LENGTH = 250

export const SIR_FORM_CONFIG = ({
  combos,
  intl,
  editMode,
  formState,
  editCompanyMode,
  editUserMode,
}) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: 'pages.sir.detail.fields.' + extension })
  const isReopeningWork =
    !formState.presupuesto_sir.anulacion_acometida_existente &&
    !formState.presupuesto_sir.construccion_nueva_acometida &&
    !formState.presupuesto_sir.conexion_tallo_o_sustitucion_valvula &&
    formState.presupuesto_sir.reapertura_acometida_cierre_programado
  const DEFAULT_PROPS = {
    fontSize: 'small',
  }
  return {
    // INFORMACIÓN DE LA SOLICITUD
    NUM_SOLICITUD: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['solicitud_id'],
      }),
      title: txt('solicitud_id.title'),
      name: 'solicitud_id',
      readOnly: true,
    },
    ESTADO_SOLICITUD: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['estado'],
        values: combos['estados_solicitud_sir']?.data || [],
      }),
      title: txt('estado.title'),
      name: 'estado',
      readOnly: true,
    },
    TIPO_PAGO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['tipo_pago'],
      }),
      title: txt('tipo_pago.title'),
      name: 'Tipo Pago',
      readOnly: true,
    },
    IBAN_SOCIEDAD: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['iban_sociedad'],
      }),
      title: txt('iban_sociedad'),
      name: 'IBAN distribuidora',
      readOnly: true,
    },

    CENTRO_SSTT: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['presupuesto_sir']['sstt_id'],
        values: combos['sir_sstt']?.data || [],
      }),
      title: txt('centro_sstt.title'),
      name: 'presupuesto_sir.sstt_id',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
      fullWidth: true,
    },
    TRABAJOS: {
      ...DEFAULT_PROPS,
      component: CheckListInput,
      fromData: (data) => ({
        value: {
          anulacion_acometida_existente: data['presupuesto_sir']['anulacion_acometida_existente'],
          construccion_nueva_acometida: data['presupuesto_sir']['construccion_nueva_acometida'],
          conexion_tallo_o_sustitucion_valvula:
            data['presupuesto_sir']['conexion_tallo_o_sustitucion_valvula'],
          reapertura_acometida_cierre_programado:
            data['presupuesto_sir']['reapertura_acometida_cierre_programado'],
          modificacion_irc_propiedad_nedgia: data['presupuesto_sir']['modificacion_irc_propiedad_nedgia'],
        },
        values: combos['tipo_trabajos_realizar']?.data || [],
      }),
      title: txt('trabajos.title'),
      // name: 'trabajos',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
    },
    TALLO: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['presupuesto_sir']['diametro_tallo'],
        values: combos['diametro_tallo']?.data || [],
      }),
      title: txt('diametro_tallo.title'),
      name: 'presupuesto_sir.diametro_tallo',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
      fullWidth: true,
    },
    PRESION_POTENCIALES: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['rango_presion_potenciales'],
      }),
      title: txt('rango_presion_potenciales.title'),
      name: 'rango_presion_potenciales',
      readOnly: true,
    },
    PRESION: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['rango_presion'],
      }),
      title: txt('rango_presion.title'),
      name: 'presupuesto_sir.rango_presion',
      required: true,
      readOnly: !(CAN_EDIT_BUDGET_STATES[formState.estado] && editMode),
    },
    NUM_VIVIENDAS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['num_viviendas'],
      }),
      title: txt('num_viviendas.title'),
      name: 'presupuesto_sir.num_viviendas',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
      fontSize: 'small',
    },
    IMPORTE_PTO: {
      ...DEFAULT_PROPS,
      componentType: 'number',
      fromData: (data) => ({
        value: data['presupuesto_sir']['precio'],
      }),
      title: txt('precio.title'),
      name: 'presupuesto_sir.precio',
      units: '€',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
    },
    IMPORTE_PTO_IVA: {
      ...DEFAULT_PROPS,
      componentType: 'number',
      fromData: (data) => ({
        value: editMode ? parseFloat(data['presupuesto_sir']['precio'] * 1.21 + data['presupuesto_sir']['precio_irc'] * 1.21).toFixed(2) : data['presupuesto_sir']['precio_con_iva'],
      }),
      title: txt('precio_con_iva.title'),
      name: 'presupuesto_sir.precio_con_iva',
      units: '€',
      readOnly: true,
    },
    IMPORTE_IRC: {
      ...DEFAULT_PROPS,
      componentType: 'number',
      fromData: (data) => ({
        value: data['presupuesto_sir']['precio_irc'],
      }),
      title: txt('importe_irc.title'),
      name: 'presupuesto_sir.precio_irc',
      units: '€',
      readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
      hidden: !formState.presupuesto_sir?.modificacion_irc_propiedad_nedgia,
    },

    APLICA_APERTURA_CIERRE: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      // fullWidth: true,
      fromData: (data) => ({
        value: data['presupuesto_sir']['aplica_apertura_cierre'],
        values: [
          { key: '00', value: 'No' },
          { key: '01', value: 'Sí' },
        ],
      }),
      title: txt('aplica_apertura_cierre.title'),
      name: 'presupuesto_sir.aplica_apertura_cierre',
      readOnly: !(CAN_EDIT_REOPENING_STATES[formState.estado] && editMode),
    },
    DIA_APERTURA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      minDate: formState['presupuesto_sir']['dia_cierre'] || undefined,
      fromData: (data) => ({
        value: data['presupuesto_sir']['dia_apertura'],
      }),
      title: txt('dia_apertura.title'),
      name: 'presupuesto_sir.dia_apertura',
      readOnly: !(CAN_EDIT_REOPENING_STATES[formState.estado] && editMode),
      // required: REQUIRED_REOPENING_STATES[formState.estado],
    },
    HORA_APERTURA: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fullWidth: true,
      fromData: (data) => ({
        value: data['presupuesto_sir']['hora_apertura'],
        values: combos['horarios_apertura']?.data || [],
      }),
      title: txt('hora_apertura.title'),
      name: 'presupuesto_sir.hora_apertura',
      readOnly: !(CAN_EDIT_REOPENING_STATES[formState.estado] && editMode),
      // required: REQUIRED_REOPENING_STATES[formState.estado],
    },
    DIA_CIERRE: {
      ...DEFAULT_PROPS,
      component: DateInput,
      maxDate: formState['presupuesto_sir']['dia_apertura'] || undefined,
      fromData: (data) => ({
        value: data['presupuesto_sir']['dia_cierre'],
      }),
      title: txt('dia_cierre.title'),
      name: 'presupuesto_sir.dia_cierre',
      readOnly: !(CAN_EDIT_REOPENING_STATES[formState.estado] && editMode),
      required: REQUIRED_REOPENING_STATES[formState.estado],
    },
    HORA_CIERRE: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fullWidth: true,
      fromData: (data) => ({
        value: data['presupuesto_sir']['hora_cierre'],
        values: combos['horarios_apertura']?.data || [],
      }),
      title: txt('hora_cierre.title'),
      name: 'presupuesto_sir.hora_cierre',
      readOnly: !(CAN_EDIT_REOPENING_STATES[formState.estado] && editMode),
      required: REQUIRED_REOPENING_STATES[formState.estado],
    },
    OBSERVACIONES_CLIENTE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['observaciones'],
      }),
      multiline: true,
      rows: 3,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      title: txt('observaciones_cliente.title'),
      name: 'presupuesto_sir.observaciones_cliente',
      readOnly: true,
    },
    OBSERVACIONES: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['observaciones'],
      }),
      multiline: true,
      rows: 3,
      // inputProps: { maxLength: TEXT_AREA_LENGTH },
      title: txt('observaciones.title'),
      name: 'presupuesto_sir.observaciones',
      // readOnly: !CAN_EDIT_BUDGET_STATES[formState.estado] || !editMode,
      readOnly: !editMode,
    },
    OBSERVACIONES_INTERNAS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['observaciones_internas'],
      }),
      multiline: true,
      rows: 3,
      title: txt('observaciones_internas.title'),
      name: 'presupuesto_sir.observaciones_internas',
      readOnly: !editMode,
    },
    MOTIVO_ANULACION_CLIENTE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['motivo_anulacion_cliente'],
      }),
      multiline: true,
      rows: 3,
      title: txt('motivo_anulacion_cliente.title'),
      name: 'presupuesto_sir.motivo_anulacion_cliente',
      readOnly: true,
    },
    MOTIVO_ANULACION_GESTOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['motivo_anulacion_gestor'],
      }),
      multiline: true,
      rows: 3,
      title: txt('motivo_anulacion_gestor.title'),
      name: 'presupuesto_sir.motivo_anulacion_gestor',
      readOnly: true,
    },
    COD_ENCARGO_GODA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['num_encargo_goda'],
      }),
      title: txt('num_encargo_goda.title'),
      name: 'presupuesto_sir.num_encargo_goda',
      readOnly: isReopeningWork || !(CAN_EDIT_GODA_STATES[formState.estado] && editMode),
      required:
        REQUIRED_GODA_STATES[formState.estado] ||
        formState.presupuesto_sir.fecha_encargo_goda?.length > 0,
    },
    FECHA_ENCARGO_GODA: {
      ...DEFAULT_PROPS,
      component: DateInput,
      fromData: (data) => ({
        value: data['presupuesto_sir']['fecha_encargo_goda'],
      }),
      title: txt('fecha_encargo_goda.title'),
      name: 'presupuesto_sir.fecha_encargo_goda',
      readOnly: isReopeningWork || !(CAN_EDIT_GODA_STATES[formState.estado] && editMode),
      required:
        REQUIRED_GODA_STATES[formState.estado] ||
        formState.presupuesto_sir.num_encargo_goda?.length > 0,
    },
    COD_ENCARGO_GORICARO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['presupuesto_sir']['num_gor_icaro'],
      }),
      title: txt('num_gor_icaro.title'),
      name: 'presupuesto_sir.num_gor_icaro',
      readOnly: isReopeningWork || !(CAN_EDIT_GORICARO_STATES[formState.estado] && editMode),
      required:
        REQUIRED_GORICARO_STATES[formState.estado] ||
        formState.presupuesto_sir.fecha_gor_icaro?.length > 0,
    },
    FECHA_ENCARGO_GORICARO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      // minDate: formState['presupuesto_sir']['fecha_encargo_goda'],
      fromData: (data) => ({
        value: data['presupuesto_sir']['fecha_gor_icaro'],
      }),
      title: txt('fecha_gor_icaro.title'),
      name: 'presupuesto_sir.fecha_gor_icaro',
      readOnly: isReopeningWork || !(CAN_EDIT_GORICARO_STATES[formState.estado] && editMode),
      required:
        REQUIRED_GORICARO_STATES[formState.estado] ||
        formState.presupuesto_sir.num_gor_icaro?.length > 0,
    },
    FECHA_PTO: {
      ...DEFAULT_PROPS,
      componentType: 'date',
      fromData: (data) => ({
        value: data['presupuesto_sir']['fecha_generacion_presupuesto_sir'],
      }),
      title: txt('fecha_generacion_presupuesto_sir.title'),
      name: 'presupuesto_sir.fecha_generacion_presupuesto_sir',
      readOnly: true,
    },
    FECHA_FINALIZACION: {
      ...DEFAULT_PROPS,
      componentType: 'date',
      fromData: (data) => ({
        value: data['presupuesto_sir']['fecha_finalizacion'],
      }),
      title: txt('fecha_finalizacion.title'),
      name: 'presupuesto_sir.fecha_finalizacion',
      readOnly: !(CAN_EDIT_GORICARO_STATES[formState.estado] && editMode),
    },
    // DATOS DEL CLIENTE
    CLIENTE_NOMBRE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value:
          `${data['usuario_sir']['nombre_cliente'] || '--'} ` +
          `${data['usuario_sir']['apellidos_cliente'] || ''}`,
      }),
      title: txt('cliente_nombre.title'),
      name: 'usuario_sir.cliente_nombre',
      readOnly: true,
      hidden: editMode || editUserMode,
    },
    //EDITAR_CLIENTE_NOMBRE
    CLIENTE_NOMBRE_EDIT: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['usuario_sir']['nombre_cliente'] || ''}`,
      }),
      title: txt('cliente_nombre.title'),
      name: 'usuario_sir.nombre_cliente',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    //EDITAR_CLIENTE_APELLIDOS
    CLIENTE_APELLIDOS_EDIT: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['usuario_sir']['apellidos_cliente'] || ''}`,
      }),
      title: txt('cliente_apellidos.title'),
      name: 'usuario_sir.apellidos_cliente',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_IDENTIFICADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['identificador_cliente'],
      }),
      title: txt('cliente_identificador.title'),
      name: 'usuario_sir.identificador_cliente',
      readOnly: !editMode && !editUserMode,
    },
    CLIENTE_EMAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['email_cliente'],
      }),
      title: txt('cliente_email.title'),
      name: 'usuario_sir.email_cliente',
      readOnly: !editMode && !editUserMode,
    },
    CLIENTE_TELEFONO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['telefono_cliente'],
      }),
      title: txt('cliente_telefono.title'),
      name: 'usuario_sir.telefono_cliente',
      readOnly: !editMode && !editUserMode,
    },
    CUPS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['cups'],
      }),
      title: txt('cups.title'),
      name: 'cups',
      readOnly: true,
    },
    CLIENTE_PROVINCIA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['provincia'],
      }),
      title: txt('cliente_provincia.title'),
      name: 'provincia',
      readOnly: true,
    },
    CLIENTE_MUNICIPIO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['municipio'],
      }),
      title: txt('cliente_municipio.title'),
      name: 'municipio',
      readOnly: true,
    },
    CLIENTE_DIRECCION: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value:
          `${data['tipo_calle']} ${data['direccion']} ${data['numero']}` +
          `${data['anexo'] ? '-' + data['anexo'] : ''} ` +
          `${data['bloque'] || ''} ` +
          `${data['escalera'] || ''} ` +
          `${data['piso'] || ''} ${data['puerta'] || ''}` +
          '\n' +
          `${data['cod_postal']} ${data['municipio']}`,
      }),
      title: txt('cliente_direccion.title'),
      name: 'cliente_direccion',
      readOnly: true,
    },
    // EDITAR DIRECCIÓN INICIO
    /*  CLIENTE_TIPO_CALLE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['tipo_calle']}`,
      }),
      title: txt('cliente_tipo_calle.title'),
      name: 'tipo_calle',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_DIRECCION_EDIT: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['direccion']}`,
      }),
      title: txt('cliente_direccion.title'),
      name: 'direccion',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_NUMERO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['numero']}`,
      }),
      title: txt('cliente_numero.title'),
      name: 'numero',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_BLOQUE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['bloque'] || ''}`,
      }),
      title: txt('cliente_bloque.title'),
      name: 'bloque',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_ESCALERA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['escalera'] || ''}`,
      }),
      title: txt('cliente_escalera.title'),
      name: 'escalera',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_PISO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['piso'] || ''}`,
      }),
      title: txt('cliente_piso.title'),
      name: 'piso',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_PUERTA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['puerta'] || ''}`,
      }),
      title: txt('cliente_puerta.title'),
      name: 'puerta',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    },
    CLIENTE_CODIGO_POSTAL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: `${data['cod_postal']}`,
      }),
      title: txt('cliente_codigo_postal.title'),
      name: 'cod_postal',
      readOnly: false,
      hidden: !editMode && !editUserMode,
    }, */
    //EDITAR DIRECCIÓN FIN
    DISTRITO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['distrito'],
      }),
      title: txt('distrito.title'),
      name: 'distrito',
      readOnly: true,
    },
    ESTADO_FINCA: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['presupuesto_sir']['estado_finca'],
        values: combos['estado_finca']?.data || [],
      }),
      title: txt('estado_finca.title'),
      name: 'presupuesto_sir.estado_finca',
      readOnly: !editMode && !editUserMode,
      fullWidth: true,
    },
    // DATOS DE LA EMPRESA INSTALADORA
    EMPRESA_NOMBRE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['nombre_empresa'],
      }),
      title: txt('empresa_nombre.title'),
      name: 'usuario_sir.nombre_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
    EMPRESA_NIF: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['nif_empresa'],
      }),
      title: txt('empresa_nif.title'),
      name: 'usuario_sir.nif_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
    // EMPRESA_PROVINCIA: {
    //   // Falta
    //   fromData: (data) => ({
    //     value: data['empresa_ca']['provincia'],
    //   }),
    //   title: txt('empresa_provincia.title'),
    //   name: 'empresa_provincia',
    //   readOnly: true,
    // },
    // EMPRESA_MUNICIPIO: {
    //   // Falta
    //   fromData: (data) => ({
    //     value: data['empresa_ca']['municipio'],
    //   }),
    //   title: txt('empresa_municipio.title'),
    //   name: 'empresa_municipio',
    //   readOnly: true,
    // },
    // EMPRESA_DIRECCION: {
    //   fromData: (data) => ({
    //     value: data['empresa_ca']['direccion'],
    //   }),
    //   title: txt('empresa_direccion.title'),
    //   name: 'empresa_direccion',
    //   readOnly: true,
    // },

    EMPRESA_CONTACTO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value:
          `${data['usuario_sir']['nombre_instalador'] || '--'} ` +
          `${data['usuario_sir']['apellidos_instalador'] || ''}`,
      }),
      title: txt('empresa_contacto.title'),
      name: 'usuario_sir.empresa_contacto',
      readOnly: !editCompanyMode && !editMode,
      hidden: editCompanyMode || editMode,
    },
    EMPRESA_NOMBRE_INSTALADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['nombre_instalador'],
      }),
      title: txt('empresa_nombre_instalador.title'),
      name: 'usuario_sir.nombre_instalador',
      readOnly: !editCompanyMode && !editMode,
      hidden: !editCompanyMode && !editMode,
    },
    EMPRESA_APELLIDOS_INSTALADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['apellidos_instalador'],
      }),
      title: txt('empresa_apellidos_instalador.title'),
      name: 'usuario_sir.apellidos_instalador',
      readOnly: !editCompanyMode && !editMode,
      hidden: !editCompanyMode && !editMode,
    },
    EMPRESA_TELF: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['telefono_empresa'],
      }),
      title: txt('empresa_telefono.title'),
      name: 'usuario_sir.telefono_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
    EMPRESA_EMAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['email_empresa'],
      }),
      title: txt('empresa_email.title'),
      name: 'usuario_sir.email_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
    EMPRESA_NUM_REGISTRO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario_sir']['num_registro_empresa'],
      }),
      title: txt('empresa_num_registro.title'),
      name: 'usuario_sir.num_registro_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
    EMPRESA_CATEGORIA: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['usuario_sir']['categoria_empresa'],
        values: [
          { key: 'A', value: 'A' },
          { key: 'B', value: 'B' },
          { key: 'C', value: 'C' },
        ],
      }),
      title: txt('empresa_categoria.title'),
      name: 'usuario_sir.categoria_empresa',
      readOnly: !editCompanyMode && !editMode,
    },
  }
}

export const SIR_FORM_STRUCTURE = ({ intl, formState, setFormState, editMode }) => {
  //Item structure: Blocks > Rows on max size > Items,
  //Use prepend and append properties to add content at the top/bottom of blocks
  //Leave empty rows to indicate a <hr/> division at that point
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: 'pages.sir.detail.' + extension })
  const reopeningTimmings =
    formState.presupuesto_sir.aplica_apertura_cierre === '01'
      ? [
          [
            { ref: 'DIA_CIERRE', conf: GRID_ITEM_MID },
            { ref: 'HORA_CIERRE', conf: GRID_ITEM_MID },
            { ref: 'DIA_APERTURA', conf: GRID_ITEM_MID },
            { ref: 'HORA_APERTURA', conf: GRID_ITEM_MID },
          ],
        ]
      : []
  const orderFields =
    !formState.presupuesto_sir.anulacion_acometida_existente &&
    !formState.presupuesto_sir.conexion_tallo_o_sustitucion_valvula &&
    !formState.presupuesto_sir.construccion_nueva_acometida &&
    formState.presupuesto_sir.reapertura_acometida_cierre_programado
      ? []
      : [
          [
            { ref: 'COD_ENCARGO_GODA', conf: GRID_ITEM_MID },
            { ref: 'COD_ENCARGO_GORICARO', conf: GRID_ITEM_MID },
            { ref: 'FECHA_ENCARGO_GODA', conf: GRID_ITEM_MID },
            { ref: 'FECHA_ENCARGO_GORICARO', conf: GRID_ITEM_MID },
          ],
          [],
        ]
  const rejectedFields =
    formState.estado === SIR_STATES.CANCELLED.KEY
      ? [
          [
            { ref: 'MOTIVO_ANULACION_CLIENTE', conf: GRID_ITEM_LARGE },
            { ref: 'MOTIVO_ANULACION_GESTOR', conf: GRID_ITEM_LARGE },
          ],
          [],
        ]
      : []
  return [
    {
      //El append no podría aceptar el componente directamente en lugar de una función?
      append: () => <FormActionBtns />,
      title: txt('information.title'),
      items: [
        [
          { ref: 'NUM_SOLICITUD', conf: GRID_ITEM_SMALL },
          { ref: 'ESTADO_SOLICITUD', conf: GRID_ITEM_SMALL },
          { ref: 'CENTRO_SSTT', conf: GRID_ITEM_SMALL },
          { ref: 'FECHA_PTO', conf: GRID_ITEM_SMALL },
          { ref: 'FECHA_FINALIZACION', conf: GRID_ITEM_SMALL },
          { ref: 'TIPO_PAGO', conf: GRID_ITEM_SMALL },
          { ref: 'IBAN_SOCIEDAD', conf: GRID_ITEM_SMALL },
        ],
        ...rejectedFields,
        [],
        [
          { ref: 'TALLO', conf: GRID_ITEM_SMALL },
          { ref: 'NUM_VIVIENDAS', conf: GRID_ITEM_SMALL },
          { ref: 'PRESION', conf: GRID_ITEM_SMALL },
          { ref: 'PRESION_POTENCIALES', conf: GRID_ITEM_SMALL },
        ],
        [],
        [
          { ref: 'TRABAJOS', conf: GRID_ITEM_SMALL },
          { ref: 'IMPORTE_PTO', conf: GRID_ITEM_SMALL },
          { ref: 'IMPORTE_IRC', conf: GRID_ITEM_SMALL },
          { ref: 'IMPORTE_PTO_IVA', conf: GRID_ITEM_SMALL },
        ],
        [{ ref: 'APLICA_APERTURA_CIERRE', conf: GRID_ITEM_LARGE }],
        ...reopeningTimmings,
        [],
        ...orderFields,
        [
          { ref: 'OBSERVACIONES', conf: GRID_ITEM_LARGE },
          { ref: 'OBSERVACIONES_CLIENTE', conf: GRID_ITEM_LARGE },
          { ref: 'OBSERVACIONES_INTERNAS', conf: GRID_ITEM_LARGE },
        ],
        [],
      ],
    },
    {
      append: () =>
        formState.estado !== '03' &&
        formState.estado !== '90' &&
        formState.estado !== '100' &&
        formState.estado !== '102' &&
        formState.estado !== '112' &&
        formState.estado !== '118' &&
        formState.estado !== '120' ? (
          <EditUserBtn formState={formState} setFormState={setFormState} />
        ) : null,
      title: txt('client.title'),
      items: [
        [
          { ref: 'CLIENTE_NOMBRE', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_NOMBRE_EDIT', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_APELLIDOS_EDIT', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_IDENTIFICADOR', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_EMAIL', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_TELEFONO', conf: GRID_ITEM_SMALL },
          { ref: 'CUPS', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_PROVINCIA', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_MUNICIPIO', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_DIRECCION', conf: GRID_ITEM_SMALL },
          /*           
          { ref: 'CLIENTE_TIPO_CALLE', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_DIRECCION_EDIT', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_NUMERO', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_BLOQUE', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_ESCALERA', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_PISO', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_PUERTA', conf: GRID_ITEM_SMALL },
          { ref: 'CLIENTE_CODIGO_POSTAL', conf: GRID_ITEM_SMALL }, */
          { ref: 'DISTRITO', conf: GRID_ITEM_SMALL },
          { ref: 'ESTADO_FINCA', conf: GRID_ITEM_SMALL },
        ],
      ],
    },
    {
      append: () =>
        formState.estado !== '03' &&
        formState.estado !== '90' &&
        formState.estado !== '100' &&
        formState.estado !== '102' &&
        formState.estado !== '112' &&
        formState.estado !== '118' &&
        formState.estado !== '120' ? (
          <EditCompanyBtn formState={formState} setFormState={setFormState} />
        ) : null,
      title: txt('company.title'),
      items: [
        [
          { ref: 'EMPRESA_NOMBRE', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_NIF', conf: GRID_ITEM_SMALL },
          // { ref: 'EMPRESA_PROVINCIA', conf: GRID_ITEM_SMALL },
          // { ref: 'EMPRESA_MUNICIPIO', conf: GRID_ITEM_SMALL },
          // { ref: 'EMPRESA_DIRECCION', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_CONTACTO', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_NOMBRE_INSTALADOR', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_APELLIDOS_INSTALADOR', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_TELF', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_EMAIL', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_NUM_REGISTRO', conf: GRID_ITEM_SMALL },
          { ref: 'EMPRESA_CATEGORIA', conf: GRID_ITEM_SMALL },
        ],
      ],
    },
  ]
}

export const FILE_FIELD_NAMES = ['d_PS', 'd_CI', 'd_FI', 'd_justificante_pago_sir', 'd_ftni']
export const FILE_FIELD_IDS = [
  'd_PS_id',
  'd_CI_id',
  'd_FI_id',
  'd_justificante_pago_sir_id',
  'd_ftni_id',
]
