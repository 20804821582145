import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography, withStyles, IconButton } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

import { BasicTable, FoldingPanel, FormFoldingPanel, PageDisplayer } from '../../../../ui'
import { ThemeButton } from '../../../../ui'
import { HistoryTaskPanel } from '../history-panel'
import {
  NORMALIZATION_PENDING_STATE,
  NOT_PROCESSED_STATES,
  PROCESSED_STATE,
  SUPERVISION_DE_FORM_CONFIG,
  SUPERVISION_FORM_STRUCTURE,
  TASK_DOCUMENTS_HISTORY_CONFIG,
  TASK_HISTORY_TABLE_CONFIG,
  CANT_SAVE_STATES,
} from '../../constants'
import { supervisionFormStyles } from './supervision-form.style'
import { HeaderButtons } from '../header'
import { useAppSupervisionCalls } from '../../hooks'
import feedback from '../../../../core/feedback'

import { downloadFile } from '../../../../core/utils'
import { useAjaxContext } from '../../../../core/ajax'

export const SupervisionForm = withStyles(supervisionFormStyles)(({ classes }) => {
  const intl = useIntl()
  const { getById } = useAjaxContext()
  const combos = useSelector((state) => state.combos)
  const history = useHistory()
  const { search } = useLocation()
  const { getSupervisionAppDetail, manualNormalization, putTaskCall } = useAppSupervisionCalls()
  const { appId } = useParams()

  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [application, setApplication] = useState({})
  const [formState, setFormState] = useState({})
  const [task, setTask] = useState()
  const [taskType, setTaskType] = useState('')
  const [fieldHistory, setFieldHistory] = useState([])
  const [modifiedFields, setModifiedFields] = useState({})
  const [formConfig, setFormConfig] = useState({
    installer: true,
    extensionRights: false,
    gasp: false,
    no_captabilidad: false,
  })
  const [open, setOpen] = useState(false)
  const [newDocsArray, setNewDocsArray] = useState([])
  const [restOfDocs, setRestOfDocs] = useState([])
  const permissions = useSelector((state) => state.global.permissions.ver_tareas)

  useEffect(() => {
    getData()
  }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (task) {
      processData()

      return combos.tipologias_tareas?.data.forEach((type) =>
        type.key.split('|').find((tipologia) => {
          if (tipologia === task.tipologia_id.toString()) {
            setTaskType(type.value)
          }
        })
      )
    }
  }, [task, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    orderDocsByDate()
  }, [task?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = useCallback(async () => {
    const { data } = await getSupervisionAppDetail(appId)
    setTask(data)
    setOpen(data.alerta)
  }, [appId, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const processData = useCallback(() => {
    setFieldHistory(
      task.campos?.map((field) => {
        const value = getFieldValue(field.split('.'), task)

        return {
          date: task.dg_ts_insert,
          field: field,
          value: typeof value === 'string' ? value : '-',
        }
      }) || []
    )
    setFormConfig({
      installer: true, // !!task['solicitud'].empresa,
      extensionRights:
        task.campos.includes('solicitud.facturacion') && !!task['solicitud'].facturacion,
      gasp: !!task['solicitud'].gas_p,
      no_captabilidad: !!task['solicitud'].motivo_no_captabilidad,
    })
    setApplication({
      ...task['solicitud'],
      estado_tarea: task.estado,
      alerta: task.alerta,
      observaciones_sspp: task.observaciones_sspp,
      usuario_grabador: task.peticionario,
      fecha_creacion: task.dg_ts_insert,
      fecha_modificacion: task.dg_ts_update,
      tipologia_id: task.tipologia_id,
      observaciones_peticionario: task.observaciones_peticionario,
    })
    setFormState({
      ...task['solicitud'],
      estado_tarea: task.estado,
      alerta: task.alerta,
      observaciones_sspp: task.observaciones_sspp,
      usuario_grabador: task.peticionario,
      fecha_creacion: task.dg_ts_insert,
      fecha_modificacion: task.dg_ts_update,
      tipologia_id: task.tipologia_id,
      observaciones_peticionario: task.observaciones_peticionario,
      potencia: task.potencia,
      consumo: task.consumo,
      presion: task.presion
    })
    setLoading(false)
  }, [task, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const getFile = ({ id }) => {
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const getFieldValue = (fieldPath = [], obj = '') => {
    if (fieldPath.length && obj) {
      return getFieldValue(fieldPath, obj[fieldPath.shift()])
    }

    return obj
  }
  const getCorrectProcessedState = (data) => {
    if (!NOT_PROCESSED_STATES[data.estado]) {
      if (data.subtipo_solicitud === 'pdte_normalizar_direccion') {
        return NORMALIZATION_PENDING_STATE
      } else {
        return PROCESSED_STATE
      }
    } else {
      return data.estado
    }
  }

  const handleChangeForm = useCallback(
    (event) => {
      const { name, value } = event.target
      setFormState((formState) => ({
        ...formState,
        [name]: value,
      }))
      setModifiedFields((modifiedFields) => ({
        ...modifiedFields,
        [name === 'estado_tarea' ? 'estado' : name]: value,
      }))
    },
    [setFormState, setModifiedFields]
  )

  const goToSupervisionTable = () => {
    history.push({
      pathname: '/supervision',
      search: search,
    })
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (
      application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
      !formState['cups'] &&
      !formState['cidi'] &&
      formState.estado_tarea === '60'
    ) {
      return feedback(
        'error',
        'Es necesario indicar un CUPS o Cidi para completar la normalización'
      )
    }
    if (
      !formState.observaciones_sspp &&
      (formState.estado_tarea === '30' ||
        formState.estado_tarea === '00' ||
        formState.estado_tarea === '40')
    ) {
      return feedback('error', 'Es necesario añadir unas observaciones')
    }
    setSaveLoading(true)

    putTaskCall(task.id, modifiedFields)
      .then(() => {
        goToSupervisionTable()
        setSaveLoading(false)
      })
      .catch(() => {
        const stateSSPP = getCorrectProcessedState(application)
        setFormState({ ...application, estado: stateSSPP })
        setSaveLoading(false)
      })
  }

  const getCustomBlock = (block) => {
    let customBlock = undefined
    if (
      block.panel === 'empresa_instaladora' &&
      (application['subtipo_solicitud'] === 'sag' ||
        application['subtipo_solicitud'] === 'pdte_normalizar_direccion')
    ) {
      customBlock = deleteFieldOfBlock(block, 'NUM_CEDULA_HABITABILIDAD')
    }
    if (block.panel === 'informacion' && application['subtipo_solicitud'] === 'scr') {
      customBlock = deleteFieldOfBlock(block, 'PUNTOS_SUMINISTRO_POTENCIALES')
      customBlock = deleteFieldOfBlock(customBlock, 'PUNTOS_SUMINISTRO_INTERESADOS')
    }
    return customBlock
  }

  const deleteFieldOfBlock = (block, fieldToDelete) => {
    const items = block.items.map((fieldGroup) => {
      const newfieldGroup = fieldGroup.filter((field) => field.ref !== fieldToDelete)
      return newfieldGroup
    })
    return { ...block, items }
  }

  const handleDummy = () => {
    if (!formState.cups && !formState.cidi) {
      return feedback('error', 'Necesitas un cups')
    }
    manualNormalization(formState.cups, formState.cidi)
  }

  const fieldList = useMemo(
    () => {
      return SUPERVISION_DE_FORM_CONFIG({
        intl,
        combos,
        onChange: handleChangeForm,
        application,
        task,
        permissions,
      })
    },
    [application, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const orderDocsByDate = () => {
    let ArrayTypes = []
    let ObjectWithDocTypes = {}
    let ArrayOfNewestDocs = []
    let ArrayOfRestOfDocs = []

    if (task?.documentos.length > 0) {
      task['documentos'].forEach((documento) => {
        if (!ArrayTypes.includes(documento?.tipo)) {
          ArrayTypes.push(documento.tipo)
        }
      })
    }
    if (ArrayTypes.length > 0) {
      ArrayTypes.forEach((docType) => {
        ObjectWithDocTypes = { ...ObjectWithDocTypes, [docType]: [] }
      })
    }
    if (task?.documentos.length > 0) {
      task['documentos'].forEach((doc) => {
        ArrayTypes.forEach((type) => {
          if (type === doc.tipo) {
            ObjectWithDocTypes[type].push(doc)
          }
        })
      })
      ArrayTypes.forEach((type) => {
        ObjectWithDocTypes[type].sort(
          (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
        )
        let newDoc = ObjectWithDocTypes[type].shift()

        ArrayOfNewestDocs.push(newDoc)
        ObjectWithDocTypes[type].forEach((doc) => {
          ArrayOfRestOfDocs = [...ArrayOfRestOfDocs, doc]
        })
      })
    }

    setNewDocsArray(ArrayOfNewestDocs)
    setRestOfDocs(ArrayOfRestOfDocs)
  }

  const handleCloseWarning = () => {
    setModifiedFields({
      ...modifiedFields,
      alerta: !task.alerta,
    })
  }

  return (
    <PageDisplayer
      loading={loading}
      handleGoBack={() => history.goBack()}
      textGoBack={intl.formatMessage({ id: 'pages.supervision.edit.back' })}
    >
      {open && (
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false)
              }}
            >
              <CloseIcon
                fontSize="inherit"
                onClick={() => {
                  handleCloseWarning()
                }}
              />
            </IconButton>
          }
        >
          Esta tarea tiene cambios pendientes
        </Alert>
      )}

      {application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
      (process.env.REACT_APP_ENVIRONMENT === 'Local' ||
        process.env.REACT_APP_ENVIRONMENT === 'Development') ? (
        <div className={classes.dummyButton}>
          <ThemeButton onClick={handleDummy}>Normalización manual</ThemeButton>
        </div>
      ) : null}

      <form className={classes.fullForm}>
        <Box display="flex" flexDirection="row" justifyContent={'space-between'} mt={2}>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <HeaderButtons application={application} />
          </Box>
          {CANT_SAVE_STATES[task?.estado] || permissions === true ? null : (
            <ThemeButton
              color="primary"
              size="small"
              loading={saveLoading}
              type="submit"
              onClick={handleSubmitForm}
            >
              {intl.formatMessage({
                id: 'pages.supervision.edit.button.save.label',
              })}
            </ThemeButton>
          )}
        </Box>
        {SUPERVISION_FORM_STRUCTURE({ intl, config: formConfig, taskType }).map((b) => {
          const customBlock = getCustomBlock(b)
          const block = customBlock ? customBlock : b
          return (
            <div key={block.title}>
              <FormFoldingPanel {...{ data: formState, combos, intl, block, fieldList }} />
            </div>
          )
        })}
        {task?.documentos?.length <= 0 && taskType !== 'Grabar datos' ? null : (
          <FoldingPanel
            isExpanded={true}
            title={intl.formatMessage({ id: 'pages.supervision.edit.fields.title' })}
          >
            <Box display="flex" flexDirection="column" flexGrow={1}>
              {!!task?.documentos?.length && (
                <>
                  <Box display="flex" flexDirection="column" flexGrow={1} mb={3}>
                    <Typography variant="h6" color="primary" paragraph>
                      Documentos (Última versión)
                    </Typography>
                    <BasicTable
                      config={TASK_DOCUMENTS_HISTORY_CONFIG(intl)}
                      data={newDocsArray}
                      onClick={getFile}
                    />
                  </Box>
                  {restOfDocs.length > 0 && (
                    <Box display="flex" flexDirection="column" flexGrow={1} mb={3}>
                      <Typography variant="h6" color="primary" paragraph>
                        Documentos (Versiones anteriores)
                      </Typography>
                      <BasicTable
                        config={TASK_DOCUMENTS_HISTORY_CONFIG(intl)}
                        data={restOfDocs}
                        onClick={getFile}
                      />
                    </Box>
                  )}
                </>
              )}
              {!!fieldHistory?.length && taskType === 'Grabar datos' && (
                <Box display="flex" flexDirection="column" flexGrow={1} mb={3}>
                  <Typography variant="subtitle1">Campos</Typography>
                  <BasicTable config={TASK_HISTORY_TABLE_CONFIG(intl)} data={fieldHistory} />
                </Box>
              )}
            </Box>
          </FoldingPanel>
        )}
        <HistoryTaskPanel taskId={task?.id} />
      </form>
    </PageDisplayer>
  )
})
