import React from 'react'
import { Grid } from '@material-ui/core'

import { TextInput } from '../../../ui'
import { CAMPAIGN_FIELDS } from '../constants'

export const CampaignPanel = ({ formState, isFinanceCampaign }) => {
  const inputProps = { formState }
  const campaignProps = CAMPAIGN_FIELDS(inputProps)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <TextInput {...{ ...campaignProps.NOMBRE }} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput {...{ ...campaignProps.CODIGO }} />
      </Grid>
      {isFinanceCampaign ? (
        <>
          <Grid item xs={12} md={6}>
            <TextInput {...{ ...campaignProps.MESES }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput {...{ ...campaignProps.LIMITE }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextInput {...{ ...campaignProps.SUBVENCION }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput {...{ ...campaignProps.TRANSFERENCIA }} />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default CampaignPanel
