import { useCallback, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useJobsCalls } from './jobs-calls.hook'
import { jobsReducer } from '../reducers'
import { JOBS_INITIAL_STATE, JOBS_ACTIONS_TYPES } from '../reducers'
import feedback from '../../../core/feedback'
import { useIntl } from 'react-intl'
import { CAMPAINGS_ACTIONS_TYPES } from "../../campaigns/reducers";

export const useSirReducer = () => {
  const [state, dispatch] = useReducer(jobsReducer, JOBS_INITIAL_STATE)
  const {
    getBatchs,
    sendBatch,
    getBatchsReport,
    getReportsByBatchId,
    getReportByReportId,
  } = useJobsCalls()
  const history = useHistory()
  const { search } = useLocation()
  const { formatMessage } = useIntl()

  const getBatchsTable = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: JOBS_ACTIONS_TYPES.SET_LOADING,
      })
      getBatchs(config)
        .then(({ data }) => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_reports: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: CAMPAINGS_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const executeBatch = useCallback(
    (batchId, args, options) => {

      const config = {
        "batch_id" : batchId,
        "arguments" : args,
        "options" : options,

      }
      dispatch({
        type: JOBS_ACTIONS_TYPES.SET_LOADING,
      })
      sendBatch(config)
        .then(() => {
          feedback('success', formatMessage({ id: 'jobs.batch.success' }))
          getBatchsTable(search)
        })
        .catch(() => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_SEND_FAILURE,
          })
        })
    },
    [dispatch]
  )

  const getBatchsReportTable = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: JOBS_ACTIONS_TYPES.SET_LOADING,
      })
      getBatchsReport(config)
        .then(({ data }) => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_reports: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getBatchsReportTableById = useCallback(
    (id, search) => {
      const config = { params: search }
      dispatch({
        type: JOBS_ACTIONS_TYPES.SET_LOADING,
      })
      getReportsByBatchId(id, config)
        .then(({ data }) => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_reports: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getReportById = useCallback(
    (id, search) => {
      const config = { params: search }
      dispatch({
        type: JOBS_ACTIONS_TYPES.SET_LOADING,
      })
      getReportByReportId(id, config)
        .then(({ data }) => {
              dispatch({
                type: JOBS_ACTIONS_TYPES.SET_FORM_DATA,
                payload: {
                  instance_data: data
                },
              })
        })
        .catch(() => {
          dispatch({
            type: JOBS_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )



  const onClickReportTableRow = useCallback(
    (id) => {
      const props = {
        pathname: `/jobs/historic/${id}`,
        state: { prevSearch: history.location.search },
      }
      return history.push(props)
    },
    [search, history] // eslint-disable-line react-hooks/exhaustive-deps
  )


  return {
    getBatchsTable,
    getBatchsReportTable,
    getBatchsReportTableById,
    executeBatch,
    setSelectedTableRows,
    onClickReportTableRow,
    getReportById,
    ...state,
  }
}
