import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox, ThemeButton, useModalContext } from '../../../../ui'
import { ExportToXLSBtn } from '../exporttoxls-btn'
import { SIR_FILTERS } from './sir-filter.constants'
import { FilterConfirmDialog, FilterListDialog } from '../dialogs/index'
import { useSirContext } from '../../contexts'
import { selectUser } from '../../../profile/slices'
import feedback from '../../../../core/feedback'

export const SirFilter = () => {
  const {
    actions: { open, close },
  } = useModalContext()
  const { savedFilters, getFilters, saveFilter, deleteFilter } = useSirContext()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const location = useLocation()
  const user = useSelector(selectUser)
  const combos = useSelector((state) => state.combos)
  const currentSearch = new URLSearchParams(location.search)
  const provincia = currentSearch.get('provincia')
  useEffect(() => {
    getFilters(user.id)
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps
  const filters = SIR_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.name === 'municipio' && item.values && provincia) {
        item.values = item.values.filter((municipio) => {
          return -1 < provincia.split(',').indexOf(municipio.provincia)
        })
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.sir.filters.' + item.name + '.title',
      }),
    }
  })

  const onSaveFilter = (value) => {
    const filterNames = savedFilters.map(item => item.key)
    if (filterNames.includes(value)) {
      return feedback('error', formatMessage({ id: "pages.sir.table.save_filter.error" }))
    }
    saveFilter({
      nombre: value,
      filtro: `${location.pathname + location.search}`
    })
    close()
  }

  const onApplyFilter = (filter) => {
    if (!filter) return
    history.push(`${filter.value}`)
    close()
  }

  const onSaveClick = () => {
    open({
      Component: FilterConfirmDialog,
      data: {
        title: formatMessage({ id: "pages.sir.table.button.save_filter" }),
        text: formatMessage({ id: "pages.sir.table.save_filter.text" }),
        yesText: formatMessage({ id: "global.save" }),
        noText: formatMessage({ id: "global.discard" }),
        yesAction: onSaveFilter,
        yesAndClose: false,
      }
    })
  }

  const onFiltersClick = () => {
    open({
      Component: FilterListDialog,
      data: {
        title: formatMessage({ id: "pages.sir.table.button.my_filters" }),
        text: formatMessage({ id: "pages.sir.table.my_filters.text" }),
        values: savedFilters,
        yesText: formatMessage({ id: "global.apply" }),
        noText: formatMessage({ id: "global.discard" }),
        yesAction: onApplyFilter,
        yesAndClose: false,
        deleteFilter: deleteFilter,
      },
    })
  }

  return (
    <FilterBox filters={filters}>
      <ExportToXLSBtn />
      {filters.length &&
        <>
          <ThemeButton
            color="primary"
            onClick={onSaveClick}
            style={{ marginRight: '1em' }}
          >
            {formatMessage({ id: "pages.sir.table.button.save_filter" })}
          </ThemeButton>
          <ThemeButton
            color="primary"
            onClick={onFiltersClick}
            style={{ marginRight: '1em' }}
          >
            {formatMessage({ id: "pages.sir.table.button.my_filters" })}
          </ThemeButton>
        </>
      }
    </FilterBox>
  )
}
