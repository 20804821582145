const TEXT_AREA_LENGTH = 250
const BINARY_VALUES = [
  { key: '01', value: 'Sí' },
  { key: '00', value: 'No' },
]
export const FILE_PROPS = {
  maxSize: 10000000, // 10Mb
  accept: ['application/pdf'],
}
export const COMPANY_GUILD_PREFIX = 'pages.eeii.detail.guild.info'
export const COMPANY_FORM_CONFIG = ({ formState, combos, intl }) => {
  const fm = intl.formatMessage
  return {
    NOMBRE: {
      required: true,
      readOnly: true,
      value: formState['nombre'],
      name: 'nombre',
      title: fm({ id: 'pages.eeii.detail.fields.nombre.title' }),
    },
    RAZON: {
      required: true,
      readOnly: true,
      value: formState['razon_social'],
      name: 'razon_social',
      title: fm({ id: 'pages.eeii.detail.fields.razon_social.title' }),
    },
    TELEFONO: {
      required: true,
      inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
      readOnly: true,
      value: formState['telefono'],
      name: 'telefono',
      title: fm({ id: 'pages.eeii.detail.fields.telefono.title' }),
    },
    WEB: {
      readOnly: true,
      value: formState['web'],
      name: 'web',
      title: fm({ id: 'pages.eeii.detail.fields.web.title' }),
    },
    EMAIL: {
      required: true,
      type: 'email',
      readOnly: true,
      value: formState['email'],
      name: 'email',
      title: fm({ id: 'pages.eeii.detail.fields.email.title' }),
    },
    DIRECCION: {
      required: true,
      readOnly: true,
      value: formState['direccion'],
      name: 'direccion',
      title: fm({ id: 'pages.eeii.detail.fields.direccion.title' }),
    },
    COD_POSTAL: {
      required: true,
      inputProps: {
        pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
        maxLength: 5,
      },
      readOnly: true,
      value: formState['cod_codigo_postal'],
      name: 'cod_codigo_postal',
      title: fm({ id: 'pages.eeii.detail.fields.cod_codigo_postal.title' }),
    },
    NIF: {
      readOnly: true,
      value: formState['cif'],
      name: 'nif',
      title: fm({ id: 'pages.eeii.detail.fields.nif.title' }),
    },
    ACEPTA_F: {
      required: true,
      readOnly: true,
      value: formState['acepta_financiacion'],
      name: 'acepta_financiacion',
      title: fm({ id: 'pages.eeii.detail.fields.acepta_financiacion.title' }),
      values: BINARY_VALUES,
      fullWidth: true,
    },
    COD_ENTIDAD_FINANCIERA: {
      required: true,
      readOnly: true,
      value: formState['codigo_entidad_financiera'],
      name: 'codigo_entidad_financiera',
      title: fm({ id: 'pages.eeii.detail.fields.codigo_entidad_financiera.title' }),
    },
    PUBLICA: {
      required: true,
      readOnly: true,
      value: formState['publica'] ? 'Sí' : 'No',
      name: 'publica',
      title: fm({ id: 'pages.eeii.detail.fields.publica.title' }),
      fullWidth: true,
    },
    DESCRIPCION: {
      required: true,
      readOnly: true,
      value: formState['descripcion'],
      name: 'descripcion',
      title: fm({ id: 'pages.eeii.detail.fields.descripcion.title' }),
      multiline: true,
      rows: 5,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
    },
    CCAA: {
      fullWidth: true,
      readOnly: true,
      value: formState['cc_aa'],
      values: combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data,
      name: 'cc_aa',
      title: fm({ id: 'pages.eeii.detail.fields.cc_aa.title' }),
      multiple: true,
    },
    CATEGORIA: {
      readOnly: true,
      value: formState['categoria'],
      name: 'categoria',
      title: fm({ id: 'pages.eeii.detail.fields.categoria.title' }),
      values: combos['ip_categories']?.data || [],
      fullWidth: true,
    },
    IBAN: {
      readOnly: true,
      value: formState['iban'],
      name: 'iban',
      title: fm({ id: 'pages.eeii.detail.fields.iban.title' }),
    },
    ESTADO_IBAN: {
      readOnly: true,
      value: formState['estado_iban'],
      name: 'estado_iban',
      title: fm({ id: 'pages.eeii.detail.fields.estado_iban.title' }),
      values: combos['estados_iban']?.data || [],
      fullWidth: true,
    },
    RESPONSABLE_LEGAL: {
      readOnly: true,
      value: formState['responsable_legal'],
      name: 'responsable_legal',
      title: fm({ id: 'pages.eeii.detail.fields.responsable_legal.title' }),
    },
    NIF_RESPONSABLE_LEGAL: {
      readOnly: true,
      value: formState['nif_responsable_legal'],
      name: 'nif_responsable_legal',
      title: fm({ id: 'pages.eeii.detail.fields.nif_responsable_legal.title' }),
    },
    CODIGO_REG_INDUSTRIAL: {
      readOnly: true,
      value: formState['codigo_registro_industrial'],
      name: 'codigo_registro_industrial',
      title: fm({
        id: 'pages.eeii.detail.fields.codigo_registro_industrial.title',
      }),
    },
    IBAN_OP: {
      readOnly: true,
      value: formState['iban_op'],
      name: 'iban_op',
      title: 'Código IBAN',
    },
    CERTIFICADO_BANCARIO_IBAN_SWIFT: {
      readOnly: true,
      value: formState['certificado_iban_op_nuevo'],
      name: 'certificado_iban_op_nuevo',
      title: fm({ id: 'pages.eeii.detail.fields.certificado_iban_op.title' }),
      fileProps: FILE_PROPS,
      currentDoc: formState['certificado_iban_op'],
    },
  }
}

export const GUILD_INFO = ({ data, intl }) => {
  const text = (ext) => intl.formatMessage({ id: COMPANY_GUILD_PREFIX + '.' + ext })
  return {
    NOMBRE: {
      name: 'nombre',
      value: data['nombre'],
      title: text('name.title'),
      readOnly: true,
    },
    TELEFONO: {
      name: 'telefono',
      value: data['telefono'],
      title: text('phone.title'),
      readOnly: true,
    },
    STATUS: {
      name: 'status',
      value: data['status'] ? text('status.accepted') : text('status.pending'),
      title: text('status.title'),
      readOnly: true,
    },
    PERMIT: {
      name: 'permit',
      color: 'primary',
      size: 'small',
    },
    MAIL_CHOICE: {
      name: 'recibir_mails_solicitud_agremiado',
      color: 'primary',
      size: 'small',
    },
    DEMANDA_CHOICE: {
      name: 'gremio_recibe_demandas',
      color: 'primary',
      size: 'small',
    },
  }
}

export const INSPECTORES_LIST_CONFIG = [
  { key: 'nombre', label: 'Nombre completo' },
  { key: 'identificador', label: 'NIF/NIE' },
  { key: 'categoria', label: 'Categoría' },
  { key: 'codigo', label: 'Código Instalador' },
]
