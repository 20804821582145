import { useState, useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { useAppContext } from '../../../contexts'

export const useCampaignPanel = () => {
  const { formatMessage: fm } = useIntl()
  const { application, loading } = useAppContext()

  const [formState, setFormState] = useState(application)

  const i18nInfoPanel = useCallback(
    (field) => fm({ id: 'pages.application.detail.campaigns.' + field + '.label' }),
    [fm]
  )

  const campaignPanelFields = useMemo(() => {
    const DEFAULT_PROPS = (field) => ({
      name: field,
      value: formState.campana && formState.campana[field],
      title: i18nInfoPanel(field),
      fontSize: 'small',
      readOnly: true,
      disabled: loading,
    })
    return {
      codigo: {
        ...DEFAULT_PROPS('codigo'),
      },
      descuento: {
        ...DEFAULT_PROPS('descuento'),
        units: '€',
      },
      ingreso: {
        ...DEFAULT_PROPS('ingreso'),
        units: '€',
      },
      fechapublicacion: {
        ...DEFAULT_PROPS('fechapublicacion'),
      },
      fechafinvigencia: {
        ...DEFAULT_PROPS('fechafinvigencia'),
      },
      tiempograbacion: {
        ...DEFAULT_PROPS('tiempograbacion'),
      },
      fechainicio: {
        ...DEFAULT_PROPS('fechainicio'),
      },
      fechafin: {
        ...DEFAULT_PROPS('fechafin'),
      },
      limite_financiacion: {
        ...DEFAULT_PROPS('limite_financiacion'),
      },
      meses_financiacion: {
        ...DEFAULT_PROPS('meses_financiacion'),
      },
      nombre: {
        ...DEFAULT_PROPS('nombre'),
        decimalScale: 2,
      },
      oferta_especial: {
        ...DEFAULT_PROPS('oferta_especial'),
      },
      origen: {
        ...DEFAULT_PROPS('origen'),
      },
    }
  }, [formState, i18nInfoPanel, loading])

  const opCampaignPanelFields = useMemo(() => {
    const DEFAULT_PROPS = (field) => ({
      name: field,
      value: formState.campana_op && formState.campana_op[field],
      title: i18nInfoPanel(field),
      fontSize: 'small',
      readOnly: true,
      disabled: loading,
    })
    return {
      codigo: {
        ...DEFAULT_PROPS('codigo'),
      },
      descuento: {
        ...DEFAULT_PROPS('descuento'),
        units: '€',
      },
      ingreso: {
        ...DEFAULT_PROPS('ingreso'),
        units: '€',
      },
      fechainicio: {
        ...DEFAULT_PROPS('fechainicio'),
      },
      fechafin: {
        ...DEFAULT_PROPS('fechafin'),
      },
      limite_financiacion: {
        ...DEFAULT_PROPS('limite_financiacion'),
      },
      meses_financiacion: {
        ...DEFAULT_PROPS('meses_financiacion'),
      },
      nombre: {
        ...DEFAULT_PROPS('nombre'),
      },
      oferta_especial: {
        ...DEFAULT_PROPS('oferta_especial'),
      },
      origen: {
        ...DEFAULT_PROPS('origen'),
      },
    }
  }, [formState, i18nInfoPanel, loading])

  useEffect(() => {
    setFormState(application)
  }, [application])

  return {
    campaignPanelFields,
    opCampaignPanelFields,
  }
}
