import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { useModalContext } from '../../../../ui/modal/contexts'
import { ThemeButton } from '../../../../ui'
import { APPLICATION_STATES } from '../../constants'
import { RollbackAppDialog, AnswerIssueDialog } from '../dialogs'
import { useAppContext } from '../../contexts'

export const RollbackPanel = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, getApplication } = useAppContext()

  const handleApplicationRollback = () => {
    open({
      Component: RollbackAppDialog,
      data: { appId: application['id'], fetchApplication: getApplication },
    })
  }

  const handleAnswerIssue = () => {
    open({
      Component: AnswerIssueDialog,
      data: { application, getApplication },
    })
  }

  return (
    <Box display="flex">
      {application['estado'] === APPLICATION_STATES.REVIEW.KEY ? (
        <>
          <Box mr={1}>
            <ThemeButton color="primary" size="small" onClick={handleApplicationRollback}>
              {formatMessage({
                id: 'pages.application.view.detail.rollback.applicationButton.label',
              })}
            </ThemeButton>
          </Box>
          <ThemeButton color="secondary" size="small" onClick={handleAnswerIssue}>
            Resolver incidencia
          </ThemeButton>
        </>
      ) : null}
    </Box>
  )
}
