export const IP_EVENTS_TABLE_CONFIG = (intl) => [
  {
    key: 'dg_ts_insert',
    label: intl.formatMessage({ id: 'pages.ip.detail.events.columns.dg_ts_insert.title' }),
    type: 'datetime',
  },
  {
    key: 'mensaje',
    label: intl.formatMessage({ id: 'pages.ip.detail.events.columns.mensaje.title' }),
  },
  {
    key: 'responsable',
    label: intl.formatMessage({ id: 'pages.ip.detail.events.columns.responsable.title' }),
  },
]
