const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const filterInputStyles = (theme) => ({
  root: {
    maxWidth: 300,
    width: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: theme.palette.common.white,
  },
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    fontSize: 16,
  },
  input: {
    height: styledBy('size', {
      undefined: '48px',
      small: '32px',
      medium: '48px',
    }),
    padding: 0,
    '&:focus': {
      height: '32px',
    },
  },
  inlineValue: {
    fontFamily: 'FSEmeric-Medium',
    marginLeft: 4,
  },
})
