import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { GRANCONSUMO_TABLE_FILTERS } from '../../constants'
import { NewCPBtn } from '../new-cp-btn'
import { ReasingBtn } from '../change-gestor-btn'

export const GCHeader = ({ table, setTable }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const gestoresLoader = useSelector((state) => state.loader.gestoresGc)
  const selection = gestoresLoader?.selection

  const buttons = (
    <>
      <NewCPBtn table={table} setTable={setTable} />
      <ReasingBtn selectedRows={selection} table={table} setTable={setTable} />
    </>
  )

  const filters = GRANCONSUMO_TABLE_FILTERS

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.gc.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>{buttons}</FilterBox>
    </>
  )
}
