import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Box} from '@material-ui/core'

import {SelectInput, ThemeButton} from '../../../../ui'
import {useCombos} from '../../../combos';

export const AssignToDialog = ({close, callback}) => {
    const {formatMessage} = useIntl()

    const {gestores: managers} = useCombos([]);

    const [manager, setManager] = useState()

    const acceptHandler = () => {
        callback(manager);
        close();
    }

    const handleChangeSelect = (e) => {
        setManager(e.target.value);
    }

    return (
        <Box p={4}>
            <Box mb={4}
                 display="flex"
                 justifyContent="center">
                <SelectInput
                    name="gestor_id"
                    title={`${formatMessage({id: 'pages.applications.assignToDialog.fields.1.title'})}:`}
                    value={manager}
                    onChange={handleChangeSelect}
                    values={managers?.data || []}
                />
            </Box>
            <Box display="flex" justifyContent="center">
                <Box mr={6}>
                    <ThemeButton
                        color="default"
                        labelM="lg"
                        onClick={close}
                    >
                        {formatMessage({id: 'global.cancel'})}
                    </ThemeButton>
                </Box>
                <ThemeButton
                    type="submit"
                    color="primary"
                    labelM="lg"
                    disabled={!manager}
                    onClick={acceptHandler}
                >
                    {formatMessage({id: 'global.accept'})}
                </ThemeButton>
            </Box>
        </Box>
    )
}
