import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../../ui'
import { useInfoPanel } from './info-panel.hook'
import { AppStateChangerDialog } from './app-state-changer-dialog.component'

export const AppStateChangerBtn = () => {
  const { formatMessage: fm } = useIntl()
  const { getAvailableStatesToChange, changeToAvailableState, loading } = useInfoPanel()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenModal = () => {
    open({
      Component: AppStateChangerDialog,
      data: {
        getAvailableStatesToChange,
        changeToAvailableState,
        appLoading: loading,
      },
      type: 'responsive',
      maxWidth: 'xs',
    })
  }

  return (
    <ThemeButton size="small" color="secondary" onClick={handleOpenModal}>
      {fm({ id: 'pages.application.detail.info.stateChanger.btn.label' })}
    </ThemeButton>
  )
}
