import React from 'react'
import { toast } from 'react-toastify'

export const useInfoPanelFeedback = () => {
  const toastId = React.useRef(null)

  const reset = () => {
    if (toast.isActive(toastId.current)) {
      toast.dismiss()
    }
  }

  const init = (mssg = 'Guardando datos...') =>
    (toastId.current = toast.loading(mssg, { type: 'info', closeOnClick: true }))

  const update = (mssg) =>
    toast.update(toastId.current, {
      render: mssg,
      type: 'info',
      isLoading: true,
      closeOnClick: true,
    })

  const success = (mssg = 'Datos guardados.') =>
    toast.update(toastId.current, {
      render: mssg,
      type: 'success',
      isLoading: false,
      autoClose: 3000,
      closeOnClick: true,
    })

  const error = (mssg = 'No ha sido posible guardar los datos.') =>
    toast.update(toastId.current, {
      render: mssg,
      type: 'error',
      isLoading: false,
      autoClose: 3000,
      closeOnClick: true,
    })

  return {
    toastId,
    reset,
    init,
    update,
    success,
    error,
  }
}
