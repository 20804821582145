import React, { createContext, useContext } from 'react'
import { useSRReducer } from '../hooks/sr-reducer.hook'

const SRContext = createContext()

export const SRContextProvider = ({ children }) => {
  const reducer = useSRReducer()
  return <SRContext.Provider value={reducer}>{children}</SRContext.Provider>
}

export const useSRContext = () => useContext(SRContext)
