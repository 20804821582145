export const reportTableStyles = {
  header: {
    fontSize: 13,
    padding: 7,
    color: '#1a171b',
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
    border: 'none',
  },
  cell: {
    fontSize: 13,
    color: '#63666a',
    padding: 7,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  chipSuccess: {

  }
}
