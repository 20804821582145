import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { BasicTable } from '../basic-table'

export const DocumentViewAccordionComponent = ({ data, title, config, onClick, loading }) => {
  const { formatMessage } = useIntl()

  return (
    <Box mb={4}>
      <Accordion style={{ padding: 0 }}>
        <AccordionSummary style={{ padding: 0 }} color="primary" expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" color="primary" paragraph>
            {formatMessage({
              id: title,
            })}
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: 0 }}>
          <BasicTable config={config} data={data} onClick={onClick} loading={loading} />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
