export const IP3_HIST_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_FAILURE: 'SET_FAILURE',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
}

export const IP3_HIST_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: {},
  loading: false,
  msLoaded: false,
  error: false,
  tableSelection: [],
}
