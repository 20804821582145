import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PrivateLayoutComponent } from '../../ui'
import { setUserData } from '../../features/profile/slices'
import { getRoutes } from './private.utils'

export const PrivateRouter = () => {
  const dispatch = useDispatch()
  const global = useSelector((state) => state.global)

  useEffect(() => {
    if (global.userId) dispatch(setUserData(global.userId, global.accessToken))
  }, [global.userId]) // eslint-disable-line react-hooks/exhaustive-deps

  const router = useMemo(() => {
    return getRoutes(global.role, global.permissions)
  }, [global])

  return (
    <PrivateLayoutComponent {...{ userRole: global.role, userPermissions: global.permissions }}>
      <Switch>
        {router.routes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Redirect from="*" to={router.redirect} />
      </Switch>
    </PrivateLayoutComponent>
  )
}
