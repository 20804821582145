import { createSlice } from '@reduxjs/toolkit'

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedRows: [],
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setAppsData: (state, { payload }) => {
      state.data = payload.data
      state.search = payload.search
      state.pagination = payload.pagination
      state.isLoading = false
    },
    setSelectedApps: (state, { payload }) => {
      state.selectedRows = [
        ...state.selectedRows.filter(
          (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
        ),
        ...payload,
      ]
    },
    resetSelectedApps: (state) => {
      state.selectedRows = []
    },
    resetAppsData: (state) => {
      state.data = []
      state.search = {
        //_pagina: 1,
        //_num_x_pagina: 10,
      }
      state.pagination = {}
      state.selectedRows = []
    },
  },
})

export const applicationsReducer = applicationsSlice.reducer

export const {
  setLoading,
  setAppsData,
  setSelectedApps,
  resetAppsData,
  resetSelectedApps,
} = applicationsSlice.actions

export const setApplications = (search, data) => (dispatch) => {
  dispatch(
    setAppsData({
      data: data['solicitudes'],
      search: search,
      pagination: {
        total_paginas: data['total_paginas'],
        total_solicitudes: data['total_solicitudes'],
      },
    })
  )
}
export const setIsLoading = (loading) => (dispatch) => {
  dispatch(setLoading(loading))
}
