export const MARKETER_DETAIL_ITEMS = [
  {
    label: 'pages.marketers.detail.gridFields.name',
    value: 'nombre',
  },
  {
    label: 'pages.marketers.detail.gridFields.businessType',
    value: 'razon_social',
  },
  {
    label: 'pages.marketers.detail.gridFields.address',
    value: 'direccion',
  },
  {
    label: 'pages.marketers.detail.gridFields.phone',
    value: 'telefono',
  },
  {
    label: 'pages.marketers.detail.gridFields.email',
    value: 'email',
  },
  {
    label: 'pages.marketers.detail.gridFields.docNumber',
    value: 'cif',
  },{
    label: 'pages.marketers.detail.gridFields.website',
    value: 'web',
  },{
    label: 'pages.marketers.detail.gridFields.description',
    value: 'descripcion',
  },
]