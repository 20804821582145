import React from 'react'

import { ApplicationUserPermitsProvider } from './contexts'
import { ApplicationsRouter } from './routers'

export const ApplicationsPage = () => {
  return (
    <ApplicationUserPermitsProvider>
      <ApplicationsRouter />
    </ApplicationUserPermitsProvider>
  )
}
