export const SIR_DB_COMBOS_IDS = [
  'estados_solicitud_sir',
  'estado_finca',
  'filtro_estado_finca',
  'provincias_sir',
  'municipios_sir',
  'sstt_gestor_sir',
  'tipo_trabajos_realizar',
  'tipo_solicitante',
]

export const SIR_DB_ORDER_COMBOS_IDS = [
  // 'estados_solicitud_sir',
  'municipios_sir',
  'provincias_sir',
  'sstt_gestor_sir',
]
