import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { PORequestsDashboardPage, PORequestsDetailPage } from '../components'

export const PORequestsRouter = () => {
  return (
    <Switch>
      <Route exact path="/po-requests" component={PORequestsDashboardPage} />
      <Route exact path="/po-requests/:poRequestId" component={PORequestsDetailPage} />
      <Redirect from="*" to="/po-requests" />
    </Switch>
  )
}
