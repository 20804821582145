import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, TextInput, RatingInput } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'
import { useCombos } from '../../../combos/hooks'
import { CardRadioButton } from '../../../../ui/card-radio-button/card-radio-button.component'

const TEXT_AREA_LENGTH = 250

export const RatingAppDialog = ({ close, application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { post, putById } = requests
  const { userId } = useSelector((state) => state.global)
  const combos = useCombos(['tiempo_contacto_instalador'])
  const [formState, setFormState] = useState({
    valoracion: application?.valoracion?.valoracion || 0,
    comentario: application?.valoracion?.comentario || '',
  })
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const successCase = () => {
    setLoading(false)
    getApplication(application['id'])
    feedback('success', 'Se ha guardado la valoración')
    close()
  }
  const failCase = () => {
    setLoading(false)
    feedback('error', 'No se ha guardado la valoración')
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (
      !formState['tiempo_contacto_instalador'] ||
      !formState['valoracion_tiempo_instalacion'] ||
      !formState['valoracion']
    ) {
      return feedback('warning', 'Valoración no enviada. Rellene todos los campos')
    }
    setLoading(true)
    const payload = {
      ...formState,
      solicitud_id: application.id,
      empresa_ca_id: application.empresa_ca.id,
      gestor_id: parseInt(userId),
    }
    if (application.valoracion_id) {
      putById('/valoraciones', application.valoracion_id, payload)
        .then(() => {
          successCase()
        })
        .catch(() => {
          failCase()
        })
    } else {
      post('/valoraciones', payload)
        .then(() => {
          successCase()
        })
        .catch(() => {
          failCase()
        })
    }
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box display="flex" mb={3}>
          <CardRadioButton
            name="tiempo_contacto_instalador"
            title={formatMessage({
              id: 'pages.application.detail.reviewDialog.tiempo_contacto_instalador.title',
            })}
            value={formState['tiempo_contacto_instalador']}
            values={combos.tiempo_contacto_instalador?.data || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={3}>
          <RatingInput
            name="valoracion_tiempo_instalacion"
            value={formState['valoracion_tiempo_instalacion']}
            onChange={handleChangeForm}
            title={formatMessage({
              id: 'pages.application.detail.reviewDialog.valoracion_tiempo_instalacion.title',
            })}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={3}>
          <RatingInput
            name="valoracion"
            value={formState['valoracion']}
            onChange={handleChangeForm}
            title={formatMessage({ id: 'pages.application.detail.reviewDialog.valoracion.title' })}
          />
        </Box>
        <Box mb={2}>
          <TextInput
            required
            name="comentario"
            title={formatMessage({ id: 'pages.application.detail.reviewDialog.comment.title' })}
            value={formState['comentario']}
            multiline
            rows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
