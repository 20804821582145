import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { FormFoldingPanel } from '../../../../../ui'
import { useCombos } from '../../../../combos'
import { GC_PANEL_CONFIG, GC_PANEL_STRUCTURE } from './gc-panel.constants'
import { useAppContext } from '../../../contexts'

export const GcPanel = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { application } = useAppContext()

  const fieldList = useMemo(() => {
    return GC_PANEL_CONFIG({ intl })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormFoldingPanel
      {...{
        data: application,
        combos,
        intl,
        block: GC_PANEL_STRUCTURE({ intl }),
        fieldList,
      }}
    />
  )
}
