import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { JobsDashboardPage } from "../components";
import { JobsReportPage } from "../components/report";
import { ReportDetailedPage } from "../components/report-detailed";

export const JobsRouter = () => {
  return (
    <Switch>
      <Route exact path="/jobs" component={JobsDashboardPage} />
      <Route exact path="/jobs/historic" component={JobsReportPage} />
      <Route exact path="/jobs/historic/:executionId" component={ReportDetailedPage} />
    </Switch>
  )
}
