import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const usePORequestsCalls = () => {
  const { get, getById, putById, post,put } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getPORequestsCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/campana/tabla_peticiones', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            } else {
              if (response.data?.message) {
                feedback('error', response.data.message)
              } else {
                defaultError()
              }
              return reject(response)
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getPORequestCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/campana/adhesiones', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            } else {
              if (response.data?.message) {
                feedback('error', response.data.message)
              } else {
                defaultError()
              }
              return reject(response)
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putRequestCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) =>
        putById('/campana/adhesiones', id, config)
          .then((response) => {
          resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            } else {
              if (response.data?.message) {
                feedback('error', response.data.message)
              } else {
                defaultError()
              }
              return reject(response)
            }
          })
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putValidateChangedFieldsCall = useCallback(
    (id) =>
      new Promise((resolve, reject) =>
        putById('/bitacora_cambios', id)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            } else {
              if (response.data?.message) {
                feedback('error', response.data.message)
              } else {
                defaultError()
              }
              return reject(response)
            }
          })
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postSendMessageCall = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) =>
        post(`/campana/adhesiones/comunicaciones/${id}`, payload)
          .then(() => {
            resolve()
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            } else {
              if (response.data?.message) {
                feedback('error', response.data.message)
              } else {
                defaultError()
              }
              return reject(response)
            }
          })
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putAdhesionesAccionMasiva = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        put('/campana/adhesiones/accion_masiva', payload)
          .then((response) => {
            resolve(response)
            feedback(
              'success',
              formatMessage({ id: 'pages.poRequests.table.actions.feedback.success' })
            )
            //publicOfferData(company.id)
          })
          .catch(({ response }) => {
            if (
              response?.request?.status === 400 &&
              [3408, 3421].includes(response?.data?.code) &&
              response?.data?.message
            ) {
              feedback('error', response.data.message)
              return reject(response.data.message)
            } else {
              feedback(
                'error',
                formatMessage({ id: 'pages.poRequests.table.actions.feedback.error' })
              )
              return reject(
                formatMessage({ id: 'pages.poRequests.table.actions.feedback.error' })
              )
            }
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getPORequestsCall,
    getPORequestCall,
    putRequestCall,
    putValidateChangedFieldsCall,
    postSendMessageCall,
    putAdhesionesAccionMasiva,
  }
}
