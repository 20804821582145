import React from 'react'
import { Box, Typography, Checkbox } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { useCombos } from '../../../combos/hooks'

export const OpChecks = ({ checksOp }) => {
  const combos = useCombos(['cod_zona_zeus'])
  const intl = useIntl()

  const getCcAa = (comunidadId) => {
    let ccaa
    combos.cod_zona_zeus &&
      combos.cod_zona_zeus.data.forEach((comunidad) => {
        if (comunidad.key === String(comunidadId)) {
          ccaa = comunidad.value
        }
      })
    return ccaa
  }

  return (
    <Box display="flex" flexDirection="column" mt={1}>
      <Box ml={1} mt={3} display="flex" justifyContent="space-between">
        <Typography color="primary" paragraph>
          {intl.formatMessage({ id: 'pages.marketer.company.profile.op.documents.title' })}
        </Typography>
        <Typography color="primary" paragraph>
          {intl.formatMessage({ id: 'pages.marketer.company.profile.op.documents.date' })}
        </Typography>
      </Box>
      {checksOp.length > 0
        ? checksOp.map((checkOp, i) => {
            if (checkOp.checked) {
              return (
                <Box key={i} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Checkbox disabled={true} checked={true}></Checkbox>
                    <Box ml={1} mr={1}>
                      <Typography>{`Condiciones Generales Oferta Pública ${getCcAa(
                        checkOp.comunidad_id
                      )} 2022`}</Typography>
                    </Box>
                  </Box>
                  <Box alignItems="center" mr={4} ml={1}>
                    <Typography variant="body2" color="textSecondary">
                      {intl.formatDate(checkOp.fecha_aceptacion)}
                    </Typography>
                  </Box>
                </Box>
              )
            }
          })
        : null}
    </Box>
  )
}
