import { useCallback } from 'react'
import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { useIntl } from 'react-intl'

export const useCombosCalls = () => {
  const { get } = requests
  const { formatMessage } = useIntl()
  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getEmpresasCustomCombo = useCallback(
    () =>
      new Promise((resolve, reject) => {
        get('/empresa/empresas')
          .then(({ data = [] }) => {
            const customCombo = data.map((item) => ({
              key: item['id'] + '',
              value: item['nombre'],
            }))
            customCombo.sort((item1, item2) => {
              if (item1['value'] < item2['value']) {
                return -1
              }
              if (item1['value'] > item2['value']) {
                return 1
              }
              return 0
            })
            return resolve(customCombo)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getGremiosCustomCombo = useCallback(
    () =>
      new Promise((resolve, reject) => {
        get('/gremios/tabla_gremios', { params: { _num_x_pagina: 0, captadora: true } })
          .then(({ data = [] }) => {
            const customCombo = data.gremios.map((item) => ({
              key: item['gremio_id'] + '',
              value: item['nombre'],
            }))
            customCombo.sort((item1, item2) => {
              if (item1['value'] < item2['value']) {
                return -1
              }
              if (item1['value'] > item2['value']) {
                return 1
              }
              return 0
            })
            return resolve(customCombo)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getGremiosIPCustomCombo = useCallback(
    () =>
      new Promise((resolve, reject) => {
        get('/gremios/tabla_gremios', { params: { _num_x_pagina: 0, grabadora: true } })
          .then(({ data = [] }) => {
            const customCombo = data.gremios.map((item) => ({
              key: item['gremio_id'] + '',
              value: item['nombre'],
            }))
            customCombo.sort((item1, item2) => {
              if (item1['value'] < item2['value']) {
                return -1
              }
              if (item1['value'] > item2['value']) {
                return 1
              }
              return 0
            })
            return resolve(customCombo)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getManagers = useCallback(
    () =>
      new Promise((resolve, reject) => {
        get('/gestor/activos_asignacion')
          .then(({ data = [] }) => {
            const customCombo = data.map((item) => ({
              key: item['id'] + '',
              value: item['nombre'] + ' ' + item['apellidos'],
            }))
            return resolve(customCombo)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  return {
    getEmpresasCustomCombo,
    getGremiosCustomCombo,
    getGremiosIPCustomCombo,
    getManagers,
  }
}
