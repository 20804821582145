export const EEIIIP_TABLE_COLUMNS = [
  { field: 'dg_ts_insert', align: 'left', width: 140, type: 'date' },
  {
    field: 'nombre',
    align: 'left',
    width: 200,
    cellStyle: {
      textTransform: 'capitalize',
    },
  },
  {
    field: 'cif',
    align: 'left',
    width: 120,
    cellStyle: {
      textTransform: 'uppercase',
    },
  },
  { field: 'telefono', align: 'left', width: 120 },
  { field: 'email', align: 'left', width: 220 },
  { field: 'iban', align: 'left', width: 260 },
  { field: 'estado_iban', align: 'left', width: 140, exportType: 'combo', comboId: 'estados_iban' },
  { field: 'gremio_ip_id', align: 'left', width: 200, exportType: 'combo', comboId: 'gremios_ip' },
  { field: 'active', width: 100, align: 'center', sorting: false, disableClick: true },
]

export const EEIIIP_EXPORT_COLUMNS = [
  { field: 'dg_ts_insert', type: 'date' },
  { field: 'nombre' },
  { field: 'cif' },
  { field: 'telefono' },
  { field: 'email' },
  { field: 'iban' },
  { field: 'estado_iban', exportType: 'combo', comboId: 'estados_iban' },
]

export const EEIIIP_TABLE_FILTERS = [
  {
    name: 'activo',
    filterType: 'switch',
  },
  {
    name: 'estado_iban',
    comboId: 'estados_iban',
    size: 'small',
    multiple: true,
  },
  {
    name: 'gremio_ip_id',
    comboId: 'gremios_ip',
    size: 'small',
    multiple: true,
  },
]
