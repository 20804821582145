import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Container, Typography, Box, CircularProgress } from '@material-ui/core'

import { useAjaxContext } from '../../../core/ajax'
import { useUserContext } from '../../../core/user'
import { CPDashboard } from '../../../features/control-panel'
import { getAppsCountsEffect } from '../effects'
import { MA_ITEMS_GESTOR, MA_ITEMS_DELEGADO } from '../constants'
import { myApplicationsStyles } from './my-applications.styles'

export const MyApplicationsPage = withStyles(myApplicationsStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { get } = useAjaxContext()
  const { userState } = useUserContext()
  const accessAllowed =
    userState['role'] &&
    (userState['role'] === 'gestor' ||
      userState['role'] === 'delegado' ||
      userState['role'] === 'gestor_gc')
  const cards =
    userState['role'] === 'gestor'
      ? MA_ITEMS_GESTOR(userState['user_id'])
      : MA_ITEMS_DELEGADO(userState['user_id'])

  const [counters, setCounters] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(
    getAppsCountsEffect(setCounters, get, userState['user_id'], accessAllowed, setLoading),
    [userState['user_id']]
  )

  if (!accessAllowed)
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        {formatMessage({ id: 'global.accessDenied' })}
      </Box>
    )
  return loading ? (
    <Box display="flex" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box>
      <Container className={classes.container} maxWidth="lg">
        <Box mb={4} textAlign="center">
          <Typography variant="h4">
            {formatMessage({ id: 'pages.myApplications.title' })}
          </Typography>
        </Box>
        <Box mb={4}>
          <CPDashboard {...{ counters, cards }} />
        </Box>
      </Container>
    </Box>
  )
})
