export const myApplicationsStyles = (theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '60px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  titleWrapper: {
    marginBottom: 40,
  },
})
