export const APP_PATH = {
  NNSAG: 'NNSAG',
  SCR1: 'SCR1',
  SCR2: 'SCR2',
  SCR3: 'SCR3',
}

export const APP_TYPE = {
  SCR: 'scr',
  SAG: 'sag',
  NN: 'pdte_normalizar_direccion',
}

export const APP_PATH_RULES = {
  NNSAG: {
    id: APP_PATH.NNSAG,
    selector: [{ id: APP_TYPE.SAG, labelId: 'pages.consult.radioButtons.2.label' }],
    app_type: (doc) => APP_TYPE['NN'],
    certificate: false,
    condition: ({ homeData }) => {
      return !homeData['cidi']
    },
  },
  SCR1: {
    id: APP_PATH.SCR1,
    selector: [
      { id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' },
      { id: APP_TYPE.SAG, labelId: 'pages.consult.radioButtons.2.label' },
    ],
    app_type: (doc) => APP_TYPE[doc],
    certificate: false,
    condition: ({ consulta }) => consulta['camino_solicitud'] === '01',
  },
  SCR2: {
    id: APP_PATH.SCR2,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => APP_TYPE[doc],
    certificate: true,
    condition: ({ consulta }) => consulta['camino_solicitud'] === '03',
  },
  SCR3: {
    id: APP_PATH.SCR3,
    selector: [{ id: APP_TYPE.SCR, labelId: 'pages.consult.radioButtons.1.label' }],
    app_type: (doc) => APP_TYPE[doc],
    certificate: true,
    condition: () => false,
  },
}
