import React from 'react'
import { useIntl } from 'react-intl'
import { Grid } from '@material-ui/core'

import { useModalContext } from '../../../../ui/modal/contexts'
import { ThemeButton } from '../../../../ui'
import { RestrictionsDialog } from '../dialogs'

export const Restrictions = ({ lines }) => {
  const {
    actions: { open },
  } = useModalContext()
  const { formatMessage } = useIntl()

  const handleRestrictions = () => {
    open({
      Component: RestrictionsDialog,
      data: {
        lines: lines,
      },
    })
  }

  return (
    <Grid
      item
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <ThemeButton color="primary" size="small" onClick={handleRestrictions}>
        {formatMessage({
          id: 'pages.application.view.detail.information.restrictions.label',
        })}
      </ThemeButton>
    </Grid>
  )
}
