import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { ThemeButton, useModalContext } from '../../../../ui'
import { ExportSomethingDialog } from '../dialogs'
import { useApplicationExports } from '../../hooks'

export const ExportToXLSBtn = () => {
  const intl = useIntl()
  const selectedRows = useSelector((state) => state.applications.selectedRows)
  const { setLoading, loading, mainExport, handleExportAll } = useApplicationExports()

  const {
    actions: { open },
  } = useModalContext()

  const handleExport = async () => {
    if (selectedRows && selectedRows.length) {
      setLoading(true)
      const data = selectedRows
      mainExport(data)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  return (
    <>
      <ThemeButton
        color="primary"
        labelM="sm"
        onClick={handleExport}
        style={{ marginRight: '1em' }}
      >
        {intl.formatMessage({ id: 'global.exportSelected' })} ({selectedRows.length})
      </ThemeButton>
      <ThemeButton color="primary" labelM="sm" loading={loading} onClick={handleExportAll}>
        {intl.formatMessage({ id: 'global.exportAll' })}
      </ThemeButton>
    </>
  )
}
