import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { ThemeButton, useModalContext } from '../../../../ui'
import { LINK_CREATE_APP } from '../../constants'
import { useTokenGeneratorCalls } from '../../hooks'
import { ProfileChoiceDialog } from '../dialogs'

export const CreateAppBtn = ({ choosesSelectraProfile }) => {
  const intl = useIntl()
  const { userId, role } = useSelector((state) => state.global)
  const combos = useSelector((state) => state.combos)
  const { generateOneUseLink } = useTokenGeneratorCalls()

  const {
    actions: { open },
  } = useModalContext()

  const createApp = (profileId = false) => {
    generateOneUseLink(userId, profileId).then(({ data }) =>
      window.open(LINK_CREATE_APP(data.message, profileId))
    )
  }
  const handleCreateApp = () => {
    if (choosesSelectraProfile || role === 'accom') {
      open({
        Component: ProfileChoiceDialog,
        data: {
          options: combos['call_center_sub_origins']?.data,
          action: (profileId) => createApp(profileId),
        },
      })
    } else if (role === 'gss_vt') {
      createApp('GSS_VT')
    }  else {
      createApp()
    }
  }
  return (
    <Box ml={2} mr={2}>
      <ThemeButton color="primary" labelM="sm" onClick={handleCreateApp}>
        {intl.formatMessage({ id: 'pages.applications.new.label' })}
      </ThemeButton>
    </Box>
  )
}

export default CreateAppBtn
