import React, { createContext, useContext } from 'react'
import { useAppReducer } from '../hooks'

const AppContext = createContext()

export const AppContextProvider = ({ children }) => {
  const reducer = useAppReducer()
  return <AppContext.Provider value={reducer}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
