import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput, SelectInput } from '../../../../ui'
import { useAjaxContext } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'
import { setSelectedApps } from '../../slices'
import { HSE_URL } from '../../constants'

const TEXT_AREA_LENGTH = 250

export const CancelDialog = ({ close, history, getApplications }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { put, getById } = useAjaxContext()
  const combo = useSelector((state) => state.combos['motivos_anulacion_gestor'].data)
  const selectedApps = useSelector((state) => state.applications.selectedRows)
  const search = useSelector((state) => state.applications.search)
  const userId = useSelector((state) => state.global.userId)
  const userRole = useSelector((state) => state.global.role)

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const getDefaultClientReason = () => {
    if (selectedApps.length === 1) {
      getById('/solicitudes', selectedApps[0]['id'])
        .then(({ data }) => {
          const activeBudget = data['presupuesto_exterior']
          const reason =
            activeBudget && activeBudget['encuesta'] && activeBudget['encuesta']['respuesta']
          if (reason)
            setFormState({
              ...formState,
              motivo_anulacion_cliente: reason,
            })
        })
        .catch(({ response }) => {
          console.error(response && response.data)
        })
    }
  }

  useEffect(() => {
    getDefaultClientReason()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (
      selectedApps.some((element) => element['gestor_id'].toString() === userId) ||
      userRole === 'delegado' ||
      userRole === 'gestor_gc'
    ) {
      const data = { ...formState, list_solicitudes_id: selectedApps.map((item) => item['id']) }
      setLoading(true)
      put('/solicitudes/anular_solicitud', data)
        .then(({ data }) => {
          dispatch(setSelectedApps([]))
          getApplications(search)
          feedback('success', formatMessage({ id: 'pages.applications.feedback.1' }))
          setLoading(false)
          close()
        })
        .catch(() => {
          console.error('Error canceling applicaiton')
          setLoading(false)
        })
    } else if (selectedApps.some((element) => element['gestor_id'].toString() !== userId)) {
      return feedback('error', formatMessage({ id: 'pages.applications.feedback.2' }))
    }
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            required
            name="motivo_anulacion_cliente"
            title={`${formatMessage({ id: 'pages.applications.cancelDialog.fields.1.title' })}:`}
            value={formState['motivo_anulacion_cliente']}
            multiline
            rows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box mb={2}>
          <SelectInput
            required
            name="motivo_anulacion_gestor"
            title={`${formatMessage({ id: 'pages.applications.cancelDialog.fields.2.title' })}:`}
            value={formState['motivo_anulacion_gestor']}
            values={combo || []}
            onChange={handleChangeForm}
          />
        </Box>
        <Box mb={4} display="flex">
          <Typography variant="body2" color="textSecondary" style={{ marginRight: 6 }}>
            {formatMessage({ id: 'pages.applications.cancelDialog.extra' })}
          </Typography>
          <Typography variant="body2" color="primary">
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => window.open(HSE_URL)}
            >
              {HSE_URL}
            </span>
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
