import { IconButton, Tooltip, withStyles } from '@material-ui/core'
import { TableActionsStyles } from './table-actions.styles'
import React, { useCallback, useState } from 'react'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { ConfirmationDialog, useModalContext } from '../../../../ui'
import { useIntl } from 'react-intl'
import { useCampaignsCalls } from '../../hooks'
import feedback from '../../../../core/feedback'
import { useLocation } from 'react-router-dom'
import { useCampaignsContext } from '../../contexts'
import { parse } from 'qs'
import { DEFAULT_QUERY } from '../table/campaigns-table.constants'

export const TableActions = withStyles(TableActionsStyles)(
  (props) => {
    const { formatMessage } = useIntl()
    const {
      actions: { open },
    } = useModalContext()
    const location = useLocation()

    const {
      getTableData,
      setSelectedTableRows,
      tableSelection
    } = useCampaignsContext()

    const { deleteCampaigns } = useCampaignsCalls()

    const [loading, setLoading] = useState(false)

    const refreshTable = () => {
      const searchString = location.search.split('?')[1]
      if (searchString) {
        const urlSearchParams = parse(searchString)
        const searchParams = {
          ...DEFAULT_QUERY,
          ...urlSearchParams,
        }
        getTableData(searchParams)
      }
    }

    const deleteAction = async () => {
      await deleteCampaigns(props.rowData.id, {}).then((response) => {
        setLoading(false)
        feedback('success', formatMessage({ id: 'pages.campaigns.delete.success' }))
        setSelectedTableRows(tableSelection.filter((row) => row.id !== props.rowData.id))
        refreshTable()
      })
    }

    const handleDelete = useCallback(
      () => {
        setLoading(true)
        open({
          Component: ConfirmationDialog,
          data: {
            title: formatMessage({ id: 'pages.campaigns.delete.modal.title' }),
            text: formatMessage({ id: 'pages.campaigns.delete.modal.text' }),
            yesText: formatMessage({ id: 'pages.campaigns.delete.modal.yes' }),
            noText: formatMessage({ id: 'pages.campaigns.delete.modal.no' }),
            yesAction: deleteAction,
            noAction: () => setLoading(false),
            yesColor: '#00955E',
          },
        })
      },
      [props], // eslint-disable-line react-hooks/exhaustive-deps
    )
    if (props.rowData.is_removable)
      return (
        <IconButton
          className={props.classes.actionButton}
          size="small"
          onClick={handleDelete}
        >
          {<DeleteOutlineIcon />}
        </IconButton>
      )
    else
      return (
        <Tooltip title={formatMessage({ id: 'pages.campaigns.table.columns.actions.delete.disabled' })}>
          <div>
            <IconButton
              className={props.classes.actionButtonDisabled}
              size="small"
              disabled
            >
              {<DeleteOutlineIcon />}
            </IconButton>
          </div>
        </Tooltip>
      )
  },
)
