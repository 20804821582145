import { JCA_INITIAL_STATE, JCA_ACTIONS_TYPES } from './jca-reducer.constants'

export const jcaReducer = (state = JCA_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case JCA_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case JCA_ACTIONS_TYPES.SET_JCA:
      return {
        ...state,
        jca: payload,
        detailLoading: false,
      }
    case JCA_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case JCA_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }

    case JCA_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    case JCA_ACTIONS_TYPES.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case JCA_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }
    // case JCA_ACTIONS_TYPES.SET_EDIT_MODE:
    //   return {
    //     ...state,
    //     editMode: payload,
    //   }
    // case JCA_ACTIONS_TYPES.SET_JCA_STATUS: {
    //   let newData = [...state.data]
    //   const rowIndex = newData.findIndex((row) => row.id === payload.id)
    //   newData[rowIndex] = { ...state.data[rowIndex], activa: payload.state }
    //   return {
    //     ...state,
    //     data: newData,
    //   }
    // }
    case JCA_ACTIONS_TYPES.SET_JCA_EVENTS:
      return {
        ...state,
        events: payload,
      }
    default:
      throw new Error('unknown action')
  }
}
