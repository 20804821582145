export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }

export const SIR_FILTERS = [
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud_sir',
    size: 'small',
    multiple: true,
  },
  {
    name: 'sstt_id_filter',
    comboId: 'sstt_gestor_sir',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    comboId: 'municipios_sir',
    size: 'small',
    multiple: true,
  },
  {
    name: 'trabajos_sir',
    comboId: 'tipo_trabajos_realizar',
    size: 'small',
    multiple: true,
  },
  {
    name: 'estado_finca',
    comboId: 'filtro_estado_finca',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_solicitante',
    comboId: 'tipo_solicitante',
    size: 'small',
    multiple: true,
  },
  {
    name: 'fecha_desde',
    filterType: 'date',
    maxDateField: 'fecha_hasta',
  },
  {
    name: 'fecha_hasta',
    filterType: 'date',
    minDateField: 'fecha_desde',
  },
]
