import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ExportToXLSBtn, FilterBox } from '../../../../ui'
import {
  INSTALLER_TABLE_COLUMNS,
  INSTALLER_TABLE_FILTERS,
} from '../../constants/installer-table.constants'
import { installerDataPreparation } from '../../logics'
import { useInstallerCalls } from '../../hooks'

export const InstallersHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getInstallers } = useInstallerCalls()
  const useInstallers = useSelector((state) => state.loader.installers)

  const selection = useInstallers?.selection
  const search = useInstallers?.config?.params

  const filters = INSTALLER_TABLE_FILTERS

  const buttons = (
    <ExportToXLSBtn
      selectedRows={selection}
      columnsExport={INSTALLER_TABLE_COLUMNS}
      getAllData={() => {
        return new Promise((resolve, reject) => {
          getInstallers({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(installerDataPreparation(data.clientes, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }}
      titleRoot="installers_"
      headerNamespace="installers"
    />
  )

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.installers.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>{buttons}</FilterBox>
    </>
  )
}
