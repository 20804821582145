import React, { useState, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid, CircularProgress } from '@material-ui/core'

import { ThemeButton, FormPanel, FileUploader, UploadTooltip } from '../../../../ui'
import { requests } from '../../../../core/ajax/requests'
import {
  DEVOLUCION_EDIT_CONFIG,
  DEVOLUCION_EDIT_FIELDS,
  DOCUMENT_ATTACHMENT_CONFIG,
  UPLOAD_BUTTONS_CONFIG,
} from './add-devolution-form.constants'
import { feedback } from '../../../../core/feedback'

export const EditDevolucionForm = ({ close, application, getApplication }) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useSelector((state) => state.combos)
  const { put } = requests

  const [formState, setFormState] = useState(application)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [uploadButtonArray, setUploadButtonArray] = useState([])
  const [documentData, setDocumentData] = useState({
    d_CB: application.d_CB ? application.d_CB : null,
  })

  const handleUploadFile = (data, buttonData) => {
    setDocumentData({ ...documentData, [buttonData]: data })
    setFormState({ ...formState, d_CB_id: data.id })
  }

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    if (application) {
      setUploadButtonArray(
        UPLOAD_BUTTONS_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_CONFIG[doc]).map((doc) => {
          return {
            ...doc,
            title: intl.formatMessage({
              id: `pages.application.view.documentViewer.documents.${doc.field}`,
            }),
            label: intl.formatMessage({
              id: 'global.documents.button.add',
            }),
            name: documentData[doc.model]?.nombre || '',
          }
        })
      )
    }
  }, [application, documentData, intl])

  const handleSubmit = (e) => {
    const payload = {
      iban: formState.iban,
      titular_iban: formState.titular_iban,
      d_CB_id: formState.d_CB_id,
    }
    e.preventDefault()
    setLoading(true)

    put(`/solicitudes/validar/${application.id}/anular_devolver`, payload)
      .then(() => {
        setLoading(false)
        getApplication(application.id)
        close()
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  const fieldList = useMemo(
    () => DEVOLUCION_EDIT_FIELDS({ combos, intl, formState, onChange: handleChange }),
    [combos, formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const block = useMemo(
    () => DEVOLUCION_EDIT_CONFIG({ intl, formState }),
    [formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleUploadError = (data) => {
    feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormPanel {...{ data: formState, combos, intl, block, fieldList }} />
      <Grid container spacing={2}>
        {uploadButtonArray.map((uploadButton) => {
          return (
            <Grid key={uploadButton.model} item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Box display="flex" alignItems="center">
                    <Typography display="inline" variant="body2">
                      {uploadButton.title}
                    </Typography>
                    <UploadTooltip file={uploadButton} />
                  </Box>
                  <Typography
                    variant="body2"
                    color={uploadButton.estado === 'Rechazado' ? 'error' : 'initial'}
                  >
                    {uploadButton.name}
                  </Typography>
                </Box>
                <FileUploader
                  applicationId={application['id']}
                  budgetId={application['presupuesto']?.id}
                  clientId={application['usuario']['id']}
                  onSuccess={(data) => handleUploadFile(data, uploadButton.model)}
                  onError={handleUploadError}
                  {...uploadButton}
                >
                  {(props) => (
                    <span
                      className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                      style={{ marginLeft: 10 }}
                      disabled={loading}
                    >
                      {props.loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        intl.formatMessage({ id: 'global.documents.button.add' })
                      )}
                    </span>
                  )}
                </FileUploader>
              </Box>
            </Grid>
          )
        })}
      </Grid>
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
        <Box width={190} mb={[3, 0]} p="0 5px">
          <ThemeButton onClick={close} variant="outlined" fullWidth disabled={loading}>
            {fm({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box width={190} p="0 5px">
          <ThemeButton type="submit" color="primary" fullWidth loading={loading} disabled={loading}>
            {fm({ id: 'global.save' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
