import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { PUBLIC_OFFER_FILTERS } from './public-offer-filter.constants'
import { AddGeneralTermsBtn, AddPublicOfferBtn } from '../buttons'
import { parse } from 'qs'
import { useCampaignsCalls } from '../../../campaigns/hooks'
import { useLocation } from 'react-router-dom'
import { ExportToXLSBtn } from '../exporttoxls-btn'

export const PublicOfferFilter = () => {
  const { formatMessage } = useIntl()
  const permissions = useSelector((state) => state.global.permissions)
  const combos = useSelector((state) => state.combos)
  const { getCampaignCodesFilter } = useCampaignsCalls()
  const location = useLocation()
  const [codesFilterValues, setCodesFilterValues] = React.useState([])
  const [filters, setFilters] = React.useState([])

  const getCodes = () => {
    const searchString = location.search.split('?')[1]
    let config = {}
    if (searchString) {
      const urlSearchParams = parse(searchString)
      config = {
        params: {...urlSearchParams, is_op: true},
      }
    }
    getCampaignCodesFilter(config).then(({ data }) => {
      setCodesFilterValues(data)
    })
  }

  const generateFilters = () => {
    const fil = PUBLIC_OFFER_FILTERS.map((item, i) => {
      if (item.comboId) {
        item.values = combos[item.comboId] && combos[item.comboId].data
      }
      if (item.name === 'codigo') {
        return {
          ...item,
          label: formatMessage({
            id: 'pages.public.offer.filters.' + item.name + '.title',
          }),
          values: codesFilterValues
        }
      }
      if (item.values) {
        return {
          ...item,
          label: formatMessage({
            id: 'pages.public.offer.filters.' + item.name + '.title',
          }),
        }
      }

      return {
        ...item,
        label: formatMessage({
          id: 'pages.public.offer.filters.' + item.name + '.title',
        }),
      }
    })
    setFilters(fil)
  }

  React.useEffect(() => {
    generateFilters()
  }, [codesFilterValues]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    getCodes()
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <FilterBox filters={filters}>
      <ExportToXLSBtn />
      {permissions['editar_op'] ? (
        <Box display="flex" justifyContent="flex-end">
          <AddGeneralTermsBtn />
          <Box ml={'1em'}>
            <AddPublicOfferBtn />
          </Box>
        </Box>
      ) : null}
    </FilterBox>
  )
}
