import { GRID_ITEM_MEDIUM, GRID_ITEM_LARGE } from '../../../constants'

export const GC_PANEL_CONFIG = ({ intl }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.application.detail.gc.fields.' + extension })
  const DEFAULT_PROPS = {
    fontSize: 'small',
    readOnly: true,
  }
  return {
    CONTADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['contador_previsto'],
      }),
      title: txt('contador_previsto.title'),
      name: 'contador_previsto',
    },
    HORAS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['horas_funcionamiento'],
      }),
      title: txt('horas_funcionamiento.title'),
      name: 'horas_funcionamiento',
    },
    POTENCIA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['potencia_instalada_total'],
      }),
      title: txt('potencia_instalada_total.title'),
      name: 'potencia_instalada_total',
    },
    PRESION: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['presion_contaje'],
      }),
      title: txt('presion_contaje.title'),
      name: 'presion_contaje',
    },
    CNAE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['punto_suministro_cnae'],
      }),
      title: txt('punto_suministro_cnae.title'),
      name: 'punto_suministro_cnae',
    },
    NIF_PUNTO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['punto_suministro_nif'],
      }),
      title: txt('punto_suministro_nif.title'),
      name: 'punto_suministro_nif',
    },
    NOMBRE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['punto_suministro_nombre'],
      }),
      title: txt('punto_suministro_nombre.title'),
      name: 'punto_suministro_nombre',
    },
    CARGO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_cargo'],
      }),
      title: txt('solicitante_cargo.title'),
      name: 'solicitante_cargo',
    },
    EMAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_email'],
      }),
      title: txt('solicitante_email.title'),
      name: 'solicitante_email',
    },
    INTERLOCUTOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_interlocutor'],
      }),
      title: txt('solicitante_interlocutor.title'),
      name: 'solicitante_interlocutor',
    },
    NIF_SOLICITANTE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_nif'],
      }),
      title: txt('solicitante_nif.title'),
      name: 'solicitante_nif',
    },
    NOMBRE_EMPRESA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_nombre_empresa'],
      }),
      title: txt('solicitante_nombre_empresa.title'),
      name: 'solicitante_nombre_empresa',
    },
    TELEFONO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['solicitante_telefono'],
      }),
      title: txt('solicitante_telefono.title'),
      name: 'solicitante_telefono',
    },
    UBICACION: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.gran_consumo['ubi_equipo_medida'],
      }),
      title: txt('ubi_equipo_medida.title'),
      name: 'ubi_equipo_medida',
    },
    EQUIPO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        componentType: 'list',
        value: data.gran_consumo['equipo_gran_consumo'],
        subtitle: TEAM_LIST_CONFIG,
      }),
      title: txt('equipo_gran_consumo.title'),
      name: 'equipo_gran_consumo',
    },
  }
}

export const GC_PANEL_STRUCTURE = ({ intl }) => {
  const { formatMessage } = intl
  return {
    isFolded: true,
    title: formatMessage({ id: 'pages.application.detail.gc.title' }),
    items: [
      [
        { ref: 'CONTADOR', conf: GRID_ITEM_MEDIUM },
        { ref: 'HORAS', conf: GRID_ITEM_MEDIUM },
        { ref: 'POTENCIA', conf: GRID_ITEM_MEDIUM },
        { ref: 'PRESION', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'NOMBRE', conf: GRID_ITEM_MEDIUM },
        { ref: 'NIF_PUNTO', conf: GRID_ITEM_MEDIUM },
        { ref: 'CNAE', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'CARGO', conf: GRID_ITEM_MEDIUM },
        { ref: 'EMAIL', conf: GRID_ITEM_MEDIUM },
        { ref: 'INTERLOCUTOR', conf: GRID_ITEM_MEDIUM },
        { ref: 'NIF_SOLICITANTE', conf: GRID_ITEM_MEDIUM },
      ],
      [
        { ref: 'NOMBRE_EMPRESA', conf: GRID_ITEM_MEDIUM },
        { ref: 'TELEFONO', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'EQUIPO', conf: GRID_ITEM_LARGE },
        { ref: 'UBICACION', conf: GRID_ITEM_MEDIUM },
      ],
    ],
  }
}

const TEAM_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Tipo',
  },
  {
    key: 'cantidad',
    label: 'Cantidad',
  },
  {
    key: 'potencia',
    label: 'Potencia',
  },
  {
    key: 'instalado_previsto',
    label: 'I/P',
  },
]
