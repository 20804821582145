import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { withStyles, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { FormFoldingPanel, PageDisplayer } from '../../../../ui'
import { ThemeButton } from '../../../../ui/theme-button'
import { useCombos } from '../../../combos/hooks'
import {
  NON_CAPTABLES_VALIDATION_FORM_CONFIG,
  NON_CAPTABLES_VALIDATION_FORM_STRUCTURE,
} from '../../constants'
import { CANT_EDIT_COMMENTS_STATES } from '../../constants'
import { nonCaptablesValidationFormStyles } from './non-captables-validation-form.style'
import { useAppNonCaptablesValidationCalls } from '../../hooks'
import { prepareSelectedCertificateData } from '../../logics'
import { HeaderButtons } from '../header'

export const NonCaptablesValidationCertificateForm = withStyles(nonCaptablesValidationFormStyles)(
  ({ classes }) => {
    const intl = useIntl()
    const combos = useCombos([])
    const history = useHistory()
    const { search } = useLocation()
    const { getNonCaptablesValidationAppDetail, putNonCaptablesValidationCertificate } =
      useAppNonCaptablesValidationCalls()
    const { appId, certificateType } = useParams()

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [application, setApplication] = useState({})
    const [formState, setFormState] = useState({})

    const canEdit =
      application.estado_certificado !== 'Anulado' &&
      !CANT_EDIT_COMMENTS_STATES[application.estado_solicitud]

    useEffect(() => {
      getNonCaptablesValidationAppDetail(appId).then(({ data }) => {
        const preparedData = prepareSelectedCertificateData(certificateType, data)
        if (!preparedData) {
          //Means that certificateType is not a valid certificate
          goToNonCaptablesValidationTable()
        } else {
          setApplication(preparedData)
          setFormState(preparedData)
        }
        setLoading(false)
      })
    }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeForm = useCallback(
      (event) => {
        const { name, value } = event.target
        setFormState((formState) => ({
          ...formState,
          [name]: value,
        }))
      },
      [setFormState]
    )

    const goToNonCaptablesValidationTable = () => {
      history.push({
        pathname: '/non-captables-validation',
        search: search,
      })
    }

    const handleSubmitForm = (e) => {
      e.preventDefault()
      setSaveLoading(true)
      const payload = {
        solicitud_id: parseInt(appId),
        codigo_solicitud: application.solicitud_id,
        mercado: formState.mercado,
        estado: formState.estado_certificado,
        cod_solicitud_zeus: formState.cod_solicitud_zeus,
        cups: formState.cups,
        observaciones_cambio_estado: formState.observaciones_cambio_estado,
      }
      for (let key in payload) {
        if (!payload[key]) delete payload[key]
      }
      putNonCaptablesValidationCertificate(
        application.selectedCertificateType,
        application.selectedCertificateId,
        payload
      )
        .then(() => {
          goToNonCaptablesValidationTable()
        })
        .catch(() => {
          setFormState(application)
          setSaveLoading(false)
        })
    }

    const getCustomBlock = (block) => {
      let customBlock = undefined
      if (
        block.panel === 'empresa_instaladora' &&
        (application['subtipo_solicitud'] === 'sag' ||
          application['subtipo_solicitud'] === 'pdte_normalizar_direccion')
      ) {
        customBlock = deleteFieldOfBlock(block, 'NUM_CEDULA_HABITABILIDAD')
      }
      if (block.panel === 'informacion' && application['subtipo_solicitud'] === 'scr') {
        customBlock = deleteFieldOfBlock(block, 'PUNTOS_SUMINISTRO_POTENCIALES')
        customBlock = deleteFieldOfBlock(customBlock, 'PUNTOS_SUMINISTRO_INTERESADOS')
      }
      return customBlock
    }

    const deleteFieldOfBlock = (block, fieldToDelete) => {
      const items = block.items.map((fieldGroup) => {
        const newfieldGroup = fieldGroup.filter((field) => field.ref !== fieldToDelete)
        return newfieldGroup
      })
      return { ...block, items }
    }

    const fieldList = useMemo(() => {
      return NON_CAPTABLES_VALIDATION_FORM_CONFIG({
        intl,
        combos,
        onChange: handleChangeForm,
        application,
      })
    }, [NON_CAPTABLES_VALIDATION_FORM_CONFIG, intl, application, combos]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <PageDisplayer
        loading={loading}
        handleGoBack={() => goToNonCaptablesValidationTable()}
        textGoBack={intl.formatMessage({ id: 'pages.supervision.edit.back' })}
      >
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <HeaderButtons application={application} />
        </Box>
        <form onSubmit={handleSubmitForm} className={classes.fullForm}>
          {canEdit ? (
            <Box display="flex" flexDirection="row-reverse" mt={1}>
              <ThemeButton color="primary" size="small" loading={saveLoading} type="submit">
                {intl.formatMessage({
                  id: 'pages.supervision.edit.button.save.label',
                })}
              </ThemeButton>
            </Box>
          ) : null}
          {NON_CAPTABLES_VALIDATION_FORM_STRUCTURE({ intl }).map((b) => {
            const customBlock = getCustomBlock(b)
            const block = customBlock ? customBlock : b
            return (
              <div key={block.title}>
                <FormFoldingPanel {...{ data: formState, combos, intl, block, fieldList }} />
              </div>
            )
          })}
        </form>
      </PageDisplayer>
    )
  }
)

export default NonCaptablesValidationCertificateForm
