import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { BlockAppDialog, ValidateAppDialog } from '../dialogs'
import { useAppContext } from '../../contexts'

export const ValidateApplication = () => {
  const { application, getApplication } = useAppContext()
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenCancelDialog = () => {
    open({
      Component: BlockAppDialog,
      data: { application, getApplication },
    })
  }

  const handleApplicationValidation = () => {
    open({
      Component: ValidateAppDialog,
      data: { appId: application['id'], fetchApplication: getApplication },
    })
  }

  return (
    <Box p="1">
      <Typography paragraph variant="body2" style={{ fontFamily: 'FSEmeric-Medium' }}>
        {formatMessage({ id: 'pages.application.view.detail.validate.application.title' })}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        <Typography paragraph variant="body2" style={{ marginRight: '0.5em' }}>
          {formatMessage({ id: 'pages.application.view.detail.validate.application.motive.label' })}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {application.motivo_no_captabilidad}
        </Typography>
      </Box>
      <Box flexWrap="wrap" display="flex" flexDirection="row">
        <ThemeButton
          size="small"
          style={{ marginRight: '1em', marginBottom: '1em' }}
          onClick={handleApplicationValidation}
        >
          {formatMessage({
            id: 'pages.application.view.detail.validate.application.validateButton.label',
          })}
        </ThemeButton>
        <ThemeButton size="small" color="default" onClick={handleOpenCancelDialog}>
          {formatMessage({
            id: 'pages.application.view.detail.validate.application.cancelButton.label',
          })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
