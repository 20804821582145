import React, { createContext, useContext } from 'react'

import { useIP3HistReducer } from '../hooks'

const IP3HistContext = createContext()

export const IP3HistContextProvider = ({ children }) => {
  const reducer = useIP3HistReducer()
  return <IP3HistContext.Provider value={reducer}>{children}</IP3HistContext.Provider>
}

export const useIP3HistContext = () => useContext(IP3HistContext)
