import React from 'react'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { useURLLoader } from "../../../features/loader/hooks"
import { noticesStyles } from './notices.styles'
import { useNotificationsCalls } from "../../../features/notices/hooks";
import { NoticesHeader } from "../../../features/notices/components/notices-header";
import { NoticesTableComponent } from "../../../features/notices";
import { useCombos } from "../../../features/combos";
import { NOTICES_COMBOS_IDS } from "../constants";

export const NoticesPage = withStyles(noticesStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const {getAvisos} = useNotificationsCalls()
  useCombos(NOTICES_COMBOS_IDS)

  useURLLoader({
    call: getAvisos,
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'notices',
  })

  return (
    <Container className={classes.container} maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.notices.title' })}
        </Typography>
      </Box>
      <Box mb={3}>
        <NoticesHeader />
      </Box>
      <Box>
        <NoticesTableComponent />
      </Box>
    </Container>
  )
})

export default NoticesPage
