import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { COLLABORATORS_TABLE_ACTIONS, COLLABORATORS_TABLE_COLUMNS } from '../../constants'
import { collaboratorsTableStyles } from './collaborators-table.styles'
import { TableUrlPagination } from '../../../../ui'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const CollaboratorsTable = () => {
  const { formatMessage } = useIntl()
  const collaboratorsLoader = useSelector((state) => state.loader.collaborators)
  const data = collaboratorsLoader?.data?.registros || []
  const totalEntries = collaboratorsLoader?.data?.total_registros
  const search = collaboratorsLoader?.config?.params
  const loading = collaboratorsLoader?.loading
  const combos = useSelector((state) => state.combos)
  const formattedColumns = useMemo(
    () =>
      [...COLLABORATORS_TABLE_COLUMNS, ...COLLABORATORS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: formatMessage({
            id: `pages.collaborators.table.columns.${column.field}.title`,
          }),
          cellStyle: collaboratorsTableStyles.adjustableCell,
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data]
  )

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      tableLayout: 'fixed',
      headerStyle: collaboratorsTableStyles.header,
      draggable: false,
    }),
    []
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.children}
        </MTableCell>
      ),
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      components={tableComponents}
      //Not transformed in the url table interface:
      data={processedData}
      isLoading={loading}
      onRowClick={null}
    />
  )
}
