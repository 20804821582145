import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { BasicListItem, FileUploaderInput } from '../../../../ui'

export const JCAAddressesViewer = ({ addresses, instType }) => {
  if (instType === 'IRC') {
    return addresses.map((address, i) => (
      <Grid container key={i} spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
        <Grid item xs={12} sm={address.anomalias?.length ? 6 : 12}>
          <Typography variant="subtitle1" gutterBottom>
            {address.cod_derivacion}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {`${address['tipo_via'] || ''} ${address['via'] || ''} ${address['numero'] || ''}, ${
              address['poblacion'] || ''
            } (${address['codigopostal'] || ''}), ${address['provincia'] || ''}` +
              '\n' +
              `${address['bloque'] ? ', Portal: ' + address['bloque'] : ''}` +
              `${address['escalera'] ? ', Escalera: ' + address['escalera'] : ''}`}
          </Typography>
          <BasicListItem
            {...{
              label: 'Nº CUPS',
              value: address['count_cups'] || 'No hay información',
            }}
          />
          <BasicListItem {...{ label: 'Tipo de gas', value: address['tipo_gas'] }} />
          {address['situacion_suministro'] ? (
            <BasicListItem
              {...{ label: 'Situación de suministro', value: address['situacion_suministro'] }}
            />
          ) : null}
          {address['co_ambiente'] ? (
            <BasicListItem {...{ label: 'CO Ambiente', value: address['co_ambiente'] }} />
          ) : null}
          {address['co_corregido'] ? (
            <BasicListItem {...{ label: 'CO Corregido', value: address['co_corregido'] }} />
          ) : null}
          {address['ticket_combustion'] ? (
            <BasicListItem
              {...{
                label: 'Ticket combustión',
                value: (
                  <FileUploaderInput
                    {...{
                      readOnly: true,
                      name: 'ticket_combustion',
                      currentDoc: address['ticket_combustion'],
                    }}
                  />
                ),
              }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          {address.anomalias?.map((anomalie, j) => {
            return (
              <Typography key={j} variant="body2" gutterBottom>
                - {anomalie.cod_anomalia} {anomalie.value}
              </Typography>
            )
          })}
        </Grid>
      </Grid>
    ))
  } else {
    const address = addresses.length ? addresses[0] : {}
    return (
      <Grid container spacing={4} style={{ marginTop: 0, marginBottom: 0 }}>
        <Grid item xs={12} sm={address.anomalias?.length ? 6 : 12}>
          <Typography variant="subtitle1" gutterBottom>
            {address.cups}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {`${address['tipo_via'] || ''} ${address['via'] || ''} ${address['numero'] || ''}, ${
              address['poblacion'] || ''
            } (${address['codigopostal'] || ''}), ${address['provincia'] || ''}` +
              '\n' +
              `${address.numero ? `, Número finca: ${address.numero}` : ''}` +
              `${address.bloque ? `, Portal: ${address.bloque}` : ''}` +
              `${address.escalera ? `, Escalera: ${address.escalera}` : ''}` +
              `${address.piso ? `, Piso: ${address.piso}` : ''}` +
              `${address.puerta ? `, Puerta: ${address.puerta}` : ''}`}
          </Typography>
          <BasicListItem {...{ label: 'Tipo de gas', value: address['tipo_gas'] }} />
          {address['situacion_suministro'] ? (
            <BasicListItem
              {...{ label: 'Situación de suministro', value: address['situacion_suministro'] }}
            />
          ) : null}
          {address['co_ambiente'] ? (
            <BasicListItem {...{ label: 'CO Ambiente', value: `${address['co_ambiente']} ppm` }} />
          ) : null}
          {address['co_corregido'] ? (
            <BasicListItem
              {...{ label: 'CO Corregido', value: `${address['co_corregido']} ppm` }}
            />
          ) : null}
          {address['ticket_combustion'] ? (
            <BasicListItem
              {...{
                label: 'Ticket combustión',
                value: (
                  <FileUploaderInput
                    {...{
                      readOnly: true,
                      name: 'ticket_combustion',
                      currentDoc: address['ticket_combustion'],
                    }}
                  />
                ),
              }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          {address.anomalias?.map((anomalie, j) => {
            return (
              <Typography key={j} variant="body2" gutterBottom>
                - {anomalie.cod_anomalia} {anomalie.value}
              </Typography>
            )
          })}
        </Grid>
      </Grid>
    )
  }
}
