import { useCallback } from 'react'

import { requests } from '../../../core/ajax'

export const useExtractionCalls = () => {
  const { get } = requests

  const requestNewExtractionByEmail = useCallback(
    () =>
      new Promise(async (resolve, reject) => {
        try {
          const {
            data: { status },
          } = await get('/extracciones/en_marcha').catch((err) => {
            throw err
          })
          if (status) {
            return reject('already started')
          }
          resolve()
        } catch (err) {
          return reject(err)
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const launchNewExtractionByEmail = () => get('/extracciones/by_email')

  const launchNewSSPPExtraction = (config) => get('/extracciones/by_email', config)

  return {
    requestNewExtractionByEmail,
    launchNewExtractionByEmail,
    launchNewSSPPExtraction
  }
}
