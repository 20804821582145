export const EDIT_FORM_CONFIG = ({ formState, combos, intl, onChange }) => {
  const fm = intl.formatMessage
  return {
    NOMBRE: {
      fullWidth: true,
      value: formState['nombre'],
      name: 'nombre',
      title: fm({ id: 'pages.managers.fields.nombre.title' }),
      onChange,
      required: true,
    },
    APELLIDOS: {
      fullWidth: true,
      value: formState['apellidos'],
      name: 'apellidos',
      title: fm({ id: 'pages.managers.fields.apellidos.title' }),
      onChange,
      required: true,
    },
    USERNAME: {
      fullWidth: true,
      value: formState['username'],
      name: 'username',
      title: fm({ id: 'pages.managers.fields.username.title' }),
      onChange,
      required: true,
      inputProps: {type: 'text', pattern: '^[A-Za-z0-9][A-Za-z0-9_.+-@]{3,}$', maxLength: 255},
      tooltip: fm({ id: 'pages.managers.fields.username.tooltip' }),
    },
    TELEFONO: {
      fullWidth: true,
      value: formState['telefono'],
      name: 'telefono',
      title: fm({ id: 'pages.managers.fields.telefono.title' }),
      onChange,
      inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
      required: true,
    },
    ROL: {
      fullWidth: true,
      value: formState['rol'],
      values: combos.roles.data,
      name: 'rol',
      title: fm({ id: 'pages.managers.fields.rol.title' }),
      onChange,
      required: true,
    },
    CORREO_ELECTRONICO: {
      value: formState['email'],
      name: 'email',
      title: fm({ id: 'pages.managers.fields.correo_electronico.title' }),
      onChange,
      required: true,
      inputProps: { type: 'email' },
    },
  }
}
