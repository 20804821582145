import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../../ui'
import { RatingAppDialog } from '../../dialogs'
import { useAppContext } from '../../../contexts'

export const ReviewAppBtn = () => {
  const { formatMessage: fm } = useIntl()
  const { application, getApplication } = useAppContext()
  const {
    actions: { open },
  } = useModalContext()

  const handleRatingAppDialog = () => {
    open({
      Component: RatingAppDialog,
      data: { application, getApplication },
      maxWidth: 'sm',
    })
  }

  return (
    <ThemeButton color="primary" size="small" onClick={handleRatingAppDialog}>
      {fm({ id: 'pages.application.detail.reviewBtn.label' })}
    </ThemeButton>
  )
}
