import React from 'react'

import { PublicOfferContext } from '../../features/public-offer'
import { PublicOfferRouter } from './routers'

export const PublicOfferPage = (props) => {
  return (
    <PublicOfferContext>
      <PublicOfferRouter {...props} />
    </PublicOfferContext>
  )
}
