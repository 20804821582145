import { useCallback, useReducer } from 'react'

import { useIP3HistCalls } from './ip3-hist-calls.hook'
import { ip3HistReducer } from '../reducers'
import { IP3_HIST_INITIAL_STATE, IP3_HIST_ACTIONS_TYPES } from '../reducers'

export const useIP3HistReducer = () => {
  const [state, dispatch] = useReducer(ip3HistReducer, IP3_HIST_INITIAL_STATE)
  const { getIP3HistCall } = useIP3HistCalls()

  const getTableData = useCallback(
    (type, search) => {
      const config = { params: search }
      dispatch({
        type: IP3_HIST_ACTIONS_TYPES.SET_LOADING,
      })
      getIP3HistCall(type, config)
        .then(({ data }) => {
          dispatch({
            type: IP3_HIST_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_registros: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: IP3_HIST_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: IP3_HIST_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    setSelectedTableRows,
    getTableData,
    ...state,
  }
}
