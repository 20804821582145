import React from 'react'

import { GRID_ITEM_MEDIUM } from '../../../constants'
import ClientPath from '../../client-path/client-path.component'

export const CLIENT_PANEL_CONFIG = ({ combos, intl, application }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.application.detail.client.fields.' + extension })
  const DEFAULT_PROPS = {
    fontSize: 'small',
    readOnly: true,
  }
  return {
    NAME: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value:
          application.usuario.persona === 'J'
            ? `${data['usuario']['apellidos'] || ''}`
            : `${data['usuario']['nombre'] || ''} ${data['usuario']['apellidos'] || ''}`,
      }),
      title: application.usuario.persona === 'J' ? txt('juridic_name.title') : txt('name.title'),
      name: 'name',
    },
    IDENTIFIER: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario']['identificador'],
      }),
      title: txt('identificador.title'),
      name: 'identificador',
    },
    EMAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario']['email'],
      }),
      title: txt('email.title'),
      name: 'email',
    },
    PHONE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['usuario']['telefono'],
      }),
      title: txt('telefono.title'),
      name: 'telefono',
    },
    TYPE_CLIENT: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['uso'],
        values: combos?.uso_punto_suministro?.data || [],
      }),
      title: txt('uso_punto_suministro.title'),
      name: 'uso_punto_suministro',
    },
    CUPS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['cups'],
      }),
      title: txt('cups.title'),
      name: 'cups',
    },
    ESTADO_CUPS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['estado_cups'],
      }),
      title: txt('estado_cups.title'),
      name: 'estado_cups',
    },
    PROVINCIA: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['provincia'],
      }),
      title: txt('provincia.title'),
      name: 'provincia',
    },
    ADDRESS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value:
          `${data['tipo_calle']} ${data['direccion']} ${data['numero']}` +
          `${data['anexo'] ? '-' + data['anexo'] : ''} ` +
          `${data['bloque'] ? '-' + data['bloque'] : ''} ` +
          `${data['escalera'] ? '-' + data['escalera'] : ''} ` +
          `${data['piso'] ? data['piso'] : ''} ${data['puerta'] ? data['puerta'] : ''}` +
          '\n' +
          `${data['cod_postal']} ${data['municipio']}`,
      }),
      title: txt('address.title'),
      name: 'address',
    },
    ZEUS_APP_NUM: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['cod_solicitud_zeus'],
      }),
      title: txt('cod_solicitud_zeus.title'),
      name: 'cod_solicitud_zeus',
    },
    ZEUS_EXP_NUM: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['cod_expediente_zeus'],
      }),
      title: txt('cod_expediente_zeus.title'),
      name: 'cod_expediente_zeus',
    },
    MARKET: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['mercado'],
        values: combos?.tipo_mercado?.data || [],
      }),
      title: txt('mercado.title'),
      name: 'mercado',
    },
    MARKET_ACTUAL: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['mercado_actual'],
        values: combos?.tipo_mercado?.data || [],
      }),
      title: txt('mercado_actual.title'),
      name: 'mercado_actual',
    },
    TIPO_PETICION: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['camino_solicitud']
          ? data['camino_solicitud'] === '01'
            ? 'Acometida'
            : data['camino_solicitud'] === '02' || data['camino_solicitud'] === '03'
            ? 'Instalación interior'
            : data['camino_solicitud'] === '04'
            ? 'Trámite certificado'
            : 'Lo desconozco'
          : 'Lo desconozco',
      }),
      title: txt('tipo_peticion.title'),
      name: 'tipo_peticion',
    },
    SOCIETY: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['sociedad'],
      }),
      title: txt('sociedad.title'),
      name: 'sociedad',
    },
    MANAGER: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['gestor']
          ? `${data['gestor']['nombre'] || ''} ${data['gestor']['apellidos'] || ''}`
          : txt('manager.placeholder'),
      }),
      title: txt('manager.title'),
      name: 'manager',
    },
  }
}

export const CLIENT_PANEL_STRUCTURE = ({ intl, followClient, application }) => {
  //Item structure: Blocks > Rows on max size > Items,
  //Use prepend and append properties to add content at the top/bottom of blocks
  //Leave empty rows to indicate a <hr/> division at that point
  const { formatMessage } = intl
  const structure = {
    title: formatMessage({ id: 'pages.application.detail.client.title' }),
    items: [
      [
        { ref: 'NAME', conf: GRID_ITEM_MEDIUM },
        { ref: 'IDENTIFIER', conf: GRID_ITEM_MEDIUM },
        { ref: 'EMAIL', conf: GRID_ITEM_MEDIUM },
        { ref: 'PHONE', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'CUPS', conf: GRID_ITEM_MEDIUM },
        { ref: 'ESTADO_CUPS', conf: GRID_ITEM_MEDIUM },
        { ref: 'PROVINCIA', conf: GRID_ITEM_MEDIUM },
        { ref: 'ADDRESS', conf: GRID_ITEM_MEDIUM },
        { ref: 'ZEUS_APP_NUM', conf: GRID_ITEM_MEDIUM },
        { ref: 'ZEUS_EXP_NUM', conf: GRID_ITEM_MEDIUM },
        { ref: 'TYPE_CLIENT', conf: GRID_ITEM_MEDIUM },
        { ref: 'SOCIETY', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'MARKET', conf: GRID_ITEM_MEDIUM },
        { ref: 'MARKET_ACTUAL', conf: GRID_ITEM_MEDIUM },
        { ref: 'TIPO_PETICION', conf: GRID_ITEM_MEDIUM },
        { ref: 'MANAGER', conf: GRID_ITEM_MEDIUM },
      ],
    ],
  }
  if (followClient) {
    structure.items = [...structure.items, []]
    structure.append = (data) => <ClientPath {...data} />
  }
  return structure
}

export const CLIENT_PATH_DATES = [
  {
    key: 'contact',
    field: (p) => p.contacto_instalador_fecha,
    alert: (p) => p.alerta_contacto_instalador,
  },
  {
    key: 'inner_budget',
    field: (p) => p.fecha_aceptacion_presupuesto_instalador,
    alert: (p) => p.alerta_aceptar_presupuesto_instalador,
  },
  {
    key: 'certificate',
    field: (p) => p.certificado_IRG3?.fecha_certificado,
    alert: (p) => p.alerta_subida_certificado,
  },
]
