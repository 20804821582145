import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import XLSX from 'xlsx'

import { ThemeButton, useModalContext } from '../../../../ui'
import { dataToXLS, getFormattedTime } from '../../../../core/utils'
import { ExportSomethingDialog } from './export-something.dialog'
import { CAMPAIGNS_TABLE_COLUMNS } from '../../../campaigns/components/table'
import { useCampaignsContext } from '../../../campaigns'
import { useCampaignsCalls } from '../../hooks'

export const ExportToXLSBtn = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const {
    actions: { open },
  } = useModalContext()
  const { tableSelection, search } = useCampaignsContext()
  const {getCampaignsCall} = useCampaignsCalls()
  const from = 'campaigns'

  const [loading, setLoading] = useState(false)

  const mainExport = (data) => {
    const result = dataToXLS(data, CAMPAIGNS_TABLE_COLUMNS, combos, intl, from)
    var worksheet = XLSX.utils.aoa_to_sheet(result)
    var new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
    XLSX.writeFile(new_workbook, `campañas_${getFormattedTime()}.xlsx`, {
      bookType: 'xlsx',
      type: 'binary',
    })
    setLoading(false)
  }

  const handleExportAll = () => {
    setLoading(true)
    getCampaignsCall({
      params: { ...search, _num_x_pagina: 10000, _pagina: 1 },
    })
      .then(({ data }) => {
        mainExport(data['campanas'])
      })
      .catch(() => setLoading(false))
  }
  const handleExport = async () => {
    if (tableSelection && tableSelection.length) {
      setLoading(true)
      mainExport(tableSelection)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  return (
    <>
      <ThemeButton
        color="primary"
        onClick={handleExport}
        style={{ marginRight: '1em' }}
      >
        {intl.formatMessage(
          { id: 'pages.sir.exportToXLS.exportSelectionBtn.title' },
          { count: tableSelection.length }
        )}
      </ThemeButton>
      <ThemeButton
        color="primary"
        loading={loading}
        style={{ marginRight: '1em' }}
        onClick={handleExportAll}
      >
        {intl.formatMessage({ id: 'pages.sir.exportToXLS.exportAllBtn.title' })}
      </ThemeButton>
    </>
  )
}
