import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { CERTIFICATE_TYPE } from '../constants'

export const useAppNonCaptablesValidationCalls = () => {
  const { get, put, post } = requests
  const { formatMessage } = useIntl()

  const getCertificateURL = (certificateType, id) => {
    switch (certificateType) {
      case CERTIFICATE_TYPE.IRG1:
        return `/certificado_IRG1/${id}`
      case CERTIFICATE_TYPE.IRG2:
        return `/certificado_IRG2/${id}`
      case CERTIFICATE_TYPE.IRG3:
        return `/certificado_IRG3/${id}`
      case CERTIFICATE_TYPE.CM:
        return `/certificado_modificacion/${id}`
      default:
        throw new Error('Unrecognized certificate type')
    }
  }
  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getNonCaptablesValidationApps = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/solicitudes/tabla_no_captables', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getNonCaptablesValidationAppDetail = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        get(`/solicitudes/${id}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getAllNonCaptablesValidationApps = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/solicitudes/tabla_no_captables', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putNonCaptablesValidationApp = useCallback(
    (id, data) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/${id}`, data)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.supervision.error.put.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putNonCaptablesValidationCertificate = useCallback(
    (certificateType, selectedCertificateId, data) =>
      new Promise((resolve, reject) => {
        const certificateUrl = getCertificateURL(certificateType, selectedCertificateId)
        put(`${certificateUrl}`, data)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.certificate.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.supervision.certificate.error.put.description' })
                )
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const manualNormalization = useCallback(
    (cups, cod_cidi) =>
      new Promise((resolve, reject) => {
        //const cod_cidi = Math.floor(Math.random() * 1000000)
        post(
          `/potenciales/dummy`,
          {
            cups,
            cod_cidi,
            estado_cups: 'CONTRATABLE',
          },
          { timeout: 0 }
        )
          .then((response) => {
            resolve(response)
            return feedback('success', 'Potencial creado')
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getNonCaptablesValidationApps,
    getAllNonCaptablesValidationApps,
    putNonCaptablesValidationApp,
    getNonCaptablesValidationAppDetail,
    putNonCaptablesValidationCertificate,
    manualNormalization,
  }
}
