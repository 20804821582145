export const GRID_ITEM = {
  FULL: {
    item: true,
    xs: 12,
  },
  LARGE: {
    item: true,
    xs: 12,
    sm: 6,
  },
  SMALL: {
    item: true,
    xs: 6,
    sm: 4,
    md: 3,
  },
}
