export const USER_GRID_ROW_ITEMS = [
  {
    label: 'pages.profile.user.fields.name',
    valueParsed: (data) => (data['nombre'] || '') + ' ' + (data['apellidos'] || ''),
  },
  {
    label: 'pages.profile.user.fields.id',
    value: 'username',
  },
  {
    label: 'pages.profile.user.fields.rol',
    valueParsed: (data, intl) =>
      intl.formatMessage({ id: `pages.profile.user.fields.rol.${data['rol']}` }),
  },
]
