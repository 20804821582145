import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ExportToXLSBtn, FilterBox } from '../../../../ui'
import { eeiiIpDataPreparation } from '../../logics'
import { useEEIIIPCalls } from '../../hooks'
import {
  EEIIIP_EXPORT_COLUMNS,
  EEIIIP_TABLE_FILTERS,
} from '../../constants/eeii-ip-table.constants'

export const EEIIIPHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getEEIIIP } = useEEIIIPCalls()
  const eeiiIpLoader = useSelector((state) => state.loader.eeiiIp)

  const selection = eeiiIpLoader?.selection
  const search = eeiiIpLoader?.config?.params
  const filters = EEIIIP_TABLE_FILTERS

  const data = filters.map((item, i) => {
    if (!!item.comboId) {
      item.values = combos[item.comboId]?.data || []
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.eeiiIp.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>
        <ExportToXLSBtn
          exportSelectionEnabled={false}
          exportAllEnabled={true}
          selectedRows={selection}
          columnsExport={EEIIIP_EXPORT_COLUMNS.filter((column) => column.field !== 'active')}
          getAllData={() => {
            return new Promise((resolve, reject) => {
              getEEIIIP({ params: { ...search, _num_x_pagina: 0 } })
                .then(({ data }) => {
                  return resolve(eeiiIpDataPreparation(data.empresas, { intl }))
                })
                .catch((err) => {
                  return reject(err)
                })
            })
          }}
          titleRoot="eeiiIp_"
          headerNamespace="eeiiIp"
        />
      </FilterBox>
    </>
  )
}
