import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../ui'
import { setSelectedApps } from '../../../applications/slices'
import { useAjaxContext } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

export const AssignToDialog = ({ close, selectedRow, getApplications, history }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const { get, putById } = useAjaxContext()
  const selectedApps = useSelector((state) => state.applications.selectedRows)
  const searchParams = useSelector((state) => state.applications.search)

  const [gestores, setGestores] = useState([])
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const getAvailableUsers = () => {
    get('/gestor/activos_asignacion')
      .then(({ data }) => {
        const managersValues = data.map((item) => {
          return {
            key: item['id'],
            value: `${item['username']} - ${item['nombre']} ${item['apellidos']}`,
          }
        })
        setGestores(managersValues)
      })
      .catch(() => console.error('Error fetching available managers'))
  }

  useEffect(() => {
    getAvailableUsers()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAssign = (e) => {
    e.preventDefault()
    if (!formState['gestor_id']) return
    const appsIds = selectedRow ? [selectedRow] : selectedApps.map((item) => item.id)
    setLoading(true)
    putById('/gestor/asignar_solicitud', formState['gestor_id'], { solicitud_ids: appsIds })
      .then(() => {
        dispatch(setSelectedApps([]))
        getApplications(searchParams)
        feedback('success', formatMessage({ id: 'pages.applications.feedback.3' }))
        setLoading(false)
        close()
      })
      .catch(() => {
        feedback('warning', formatMessage({ id: 'pages.applications.feedback.4' }))
        setLoading(false)
      })
  }

  const handleChangeSelect = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  return (
    <Box p={4}>
      <form onSubmit={handleAssign}>
        <Box mb={4}>
          <SelectInput
            name="gestor_id"
            title={`${formatMessage({ id: 'pages.applications.assignToDialog.fields.1.title' })}:`}
            value={formState['gestor_id']}
            onChange={handleChangeSelect}
            values={gestores || []}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            loading={loading}
            disabled={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
