import React, { useState } from "react"
import { Box, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core"
import { AutocompleteInput, ThemeButton } from "../../../../ui"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export const FilterListDialog = ({
    title = 'Warning:',
    text = 'Are you sure you want to proceed?',
    values = [],
    yesText = 'Yes',
    yesColor = 'primary',
    yesAction = () => { },
    yesAndClose = true,
    noText = 'No',
    noColor = 'default',
    noAction = () => { },
    noAndClose = true,
    deleteFilter = () => { },
    close,
}) => {
    const [value, setValue] = useState(null)
    const [filters, setFilters] = useState(values)
    const onChange = (payload) => {
        setValue(payload.value)
    }

    const onDelete = () => {
        setFilters(filters => filters.filter(item => item.key !== value))
        deleteFilter(value)
        setValue(null)
    }

    return (
        <Box p={4}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h4" color="primary">
                        {title}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1">{text}</Typography>
                <Box display="flex" style={{gap: 5}}>
                    <AutocompleteInput style={{flex: 1}} value={value} values={filters.map(item => item.key)} onChange={onChange}/>
                    <IconButton style={{visibility: value ? "visible" : "hidden"}} onClick={onDelete}>
                        <DeleteOutlineIcon />
                    </IconButton>
                </Box>
            </DialogContent>
            <DialogActions>
                <ThemeButton
                    labelM="small"
                    color={noColor}
                    onClick={() => {
                        noAction()
                        if (noAndClose) {
                            close()
                        }
                    }}
                >
                    {noText}
                </ThemeButton>
                <ThemeButton
                    labelM="small"
                    color={yesColor}
                    onClick={() => {
                        yesAction(filters.find(item => item.key === value))
                        if (yesAndClose) {
                            close()
                        }
                    }}
                >
                    {yesText}
                </ThemeButton>
            </DialogActions>
        </Box>
    )
}