import React from 'react'
import { withStyles, Button, CircularProgress, Box, Typography } from '@material-ui/core'

import { themeButtonStyles } from './theme-button.styles'

export const ThemeButton = withStyles(themeButtonStyles)(
  ({
    classes,
    variant = 'contained',
    color = 'primary',
    size = 'large',
    labelM = 'sm',
    children,
    loading = false,
    loadingShowText = false,
    truncate = false,
    ...props
  }) => (
    <Box display="block">
      <Button
        {...props}
        size={size}
        classes={{ root: classes.root, label: classes.label }}
        variant={variant}
        color={color}
        disabled={props.disabled || loading}
        disableElevation
      >
        {loading ? (
            {loadingShowText} ? (
              <Box display="flex" alignItems="center" gridGap={10}>
                <CircularProgress size={size === 'large' ? 25 : 15} color={'primary'} />
                {children}
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <CircularProgress size={size === 'large' ? 30 : 25} color={'primary'} />
              </Box>
            )
        ) : (
          <Box display="flex" alignItems="center">
            {children}
          </Box>
        )}
      </Button>
      {props.extra && (
        <Box>
          <Typography
            variant="caption"
            color="textSecondary"
            className={truncate ? classes.truncate : classes.multiline}
            style={{ width: truncate }}
          >
            {props.extra}
          </Typography>
        </Box>
      )}
    </Box>
  )
)
