import React, { useEffect } from "react";
import { useIntl } from 'react-intl'
import { Box, Button, Container, Tab, Tabs, Typography, withStyles } from "@material-ui/core";
import { reportDetailedStyles } from './report-detailed.styles'
import { JobsTable, useJobsContext } from "../../../../features/jobs";
import PropTypes from "prop-types";
import { ReportTable } from "../../../../features/jobs/components/report-table/report-table.component";
import { useHistory, useParams } from "react-router-dom";
import { ReportDetailedComponent } from "../../../../features/jobs/components/detailed/report-detailed.component";

export const ReportDetailedPage = withStyles(reportDetailedStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getReportById } = useJobsContext()
  let history = useHistory();
  const { executionId } = useParams()

  useEffect(() => {
    getReportById(executionId)
  }, [executionId]);

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.jobs.dashboard.title' })}
        </Typography>
      </Box>
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Button variant="contained" color="primary" onClick={() => history.goBack()}>
            {formatMessage({ id: 'global.return' })}
          </Button>
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <ReportDetailedComponent />
        </Box>

      </Box>
    </Container>
  )
})
