import { PO_REQ_INITIAL_STATE, PO_REQ_ACTIONS_TYPES } from './po-requests-reducer.constants'

export const poRequestsReducer = (state = PO_REQ_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case PO_REQ_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        email_f1: payload.email_f1,
        loading: false,
        msLoaded: Date.now(),
        tableSelection: [],
      }
    case PO_REQ_ACTIONS_TYPES.SET_PO_REQUEST:
      return {
        ...state,
        poRequest: payload,
        detailLoading: false,
      }
    case PO_REQ_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case PO_REQ_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }

    case PO_REQ_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id_peticion).indexOf(el.id_peticion) === -1
          ),
          ...payload,
        ],
      }
    case PO_REQ_ACTIONS_TYPES.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case PO_REQ_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }

    default:
      throw new Error('unknown action')
  }
}
