import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { feedback } from '../../../../core/feedback'
import { ExportToXLSBtn } from '../../../../ui'
import { APPS_EXPORT_COLUMNS } from '../../constants'
import { useNonCaptablesValidationContext } from '../../contexts'
import { useAppNonCaptablesValidationCalls } from '../../hooks/non-captables-validation-calls.hook'
import { nonCaptablesValidationExportDataPreparation } from '../../logics'

export const NonCaptablesExportToXLSBtn = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  const { getAllNonCaptablesValidationApps } = useAppNonCaptablesValidationCalls()
  const {
    state: { tableSelection: selectedRows, search },
  } = useNonCaptablesValidationContext()

  return (
    <ExportToXLSBtn
      exportSelectionEnabled={true}
      exportAllEnabled={true}
      selectedRows={selectedRows}
      columnsExport={APPS_EXPORT_COLUMNS}
      getAllData={() => {
        return new Promise((resolve, reject) => {
          getAllNonCaptablesValidationApps({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(
                nonCaptablesValidationExportDataPreparation({
                  data: data.solicitudes,
                  combos,
                  intl,
                })
              )
            })
            .catch((err) => {
              feedback('error', formatMessage({ id: 'global.errorDownload' }))
              return reject(err)
            })
        })
      }}
      titleRoot="non_captables_"
      headerNamespace="nonCaptablesValidation"
    />
  )
}
