import { SIR_STATES } from '../../constants'

export const CAN_GENERATE_BUDGET_STATES = {
  [SIR_STATES.CREATED.KEY]: SIR_STATES.CREATED.DESCRIPTION,
  [SIR_STATES.BUDGET_CREATED.KEY]: SIR_STATES.BUDGET_CREATED.DESCRIPTION,
}

export const CAN_SEND_BUDGET_STATES = {
  [SIR_STATES.BUDGET_CREATED.KEY]: SIR_STATES.BUDGET_CREATED.DESCRIPTION,
  [SIR_STATES.BUDGET_SENDED.KEY]: SIR_STATES.BUDGET_SENDED.DESCRIPTION,
}

export const CAN_VIEW_BILL_STATES = {
  [SIR_STATES.VALIDATE_PAYMENT.KEY]: SIR_STATES.VALIDATE_PAYMENT.DESCRIPTION,
  [SIR_STATES.PAYMENT_VALIDATED.KEY]: SIR_STATES.PAYMENT_VALIDATED.DESCRIPTION,
  [SIR_STATES.ORDER_MADE.KEY]: SIR_STATES.ORDER_MADE.DESCRIPTION,
  [SIR_STATES.CONSTRUCTION.KEY]: SIR_STATES.CONSTRUCTION.DESCRIPTION,
  [SIR_STATES.SCHEDULED.KEY]: SIR_STATES.SCHEDULED.DESCRIPTION,
  [SIR_STATES.FINISHED.KEY]: SIR_STATES.FINISHED.DESCRIPTION,
  [SIR_STATES.REVIEW.KEY]: SIR_STATES.REVIEW.DESCRIPTION,
  [SIR_STATES.CANCELLED.KEY]: SIR_STATES.CANCELLED.DESCRIPTION,
}

export const CAN_CONFIRMATION_PROGRAMMING_DATES = {
  [SIR_STATES.PENDING_CONFIRMATION_PROGRAMMING_DATES.KEY]:
    SIR_STATES.PENDING_CONFIRMATION_PROGRAMMING_DATES.DESCRIPTION,
}
