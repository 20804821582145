import { getBrowser } from '../../../core/utils/browserUtils'
import { APP_TYPE } from '../constants/application-types.constants'

export const assembleNormalizedHome = (selectedHome, state) => {
  return {
    ...selectedHome,
    ...state.addressForm.cp_municipio,
    ...state.addressForm.direccion,
    ...state.addressForm.numero,
    nombre_via: state.addressForm.direccion.nombreVia,
    tipo_calle: state.addressForm.direccion.tipoVia,
  }
}

export const assembleNotNormalizedHome = (state, dataUpdate) => {
  const numero = state.addressForm.numero || { numero: '' }
  const direccion = state.addressForm.direccion || {}
  return {
    ...state.addressForm.cp_municipio,
    numero: numero.numero + (numero.anexo ? ' ' + numero.anexo : ''),
    nombre_via: direccion.nombreVia || '',
    tipo_calle: direccion.tipoVia || '',
    bloque: direccion.bloque || '',
    escalera: direccion.escalera || '',
    piso: direccion.piso || '',
    puerta: direccion.puerta || '',
    ...dataUpdate,
  }
}

export const assembleTmpAppFromState = (state, tipo_solicitud = 'Call_center', userId) => ({
  ip: '',
  navegador: getBrowser(),
  nombre: state.customerForm.nombre,
  apellidos_cliente: state.customerForm.apellidos,
  email_cliente: state.customerForm.email,
  identificador: state.customerForm.identificador,
  tipo_identificador: state.customerForm.tipo_identificador,
  telefono1: state.customerForm.telefono,
  tipo_solicitud,
  cidi: state.selectedHome['cidi']?.toString(),
  tipo_vivienda: state.selectedHome['tipo_vivienda'],
  pasar_contratable: state.customerForm['pasar_contratable'],
  cod_postal: state.selectedHome['cod_postal'],
  municipio: state.selectedHome['municipio'],
  tipo_calle: state.selectedHome['tipo_calle'],
  captador_id: parseInt(userId),
  direccion: state.selectedHome['nombre_via'],
  anexo: state.selectedHome['anexo'],
  numero: state.selectedHome['numero'],
  bloque: state.selectedHome['bloque'],
  escalera: state.selectedHome['escalera'],
  piso: state.selectedHome['piso'],
  puerta: state.selectedHome['puerta'],
})

export const assembleCustomerFromState = (state) => ({
  email: state.customerForm.email,
  nombre: state.customerForm.nombre,
  apellidos: state.customerForm.apellidos,
  telefono: state.customerForm.telefono,
  identificador: state.customerForm.identificador,
  tipo_identificador: state.customerForm.tipo_identificador,
  poblacion: state.customerForm.poblacion,
  cp: state.customerForm.cp,
})

export const assembleCommonPayloadFromTmpApp = (subtype, state, tmpApplication, id_client) => ({
  uso: state.customerForm['uso'],
  subtipo_solicitud: subtype,
  usuario_id: id_client,
  tipo_solicitud: tmpApplication['tipo_solicitud'],
  instalador_nedgia: true,
  instalacion_exterior: state.selectedHome['dispone_gas'] !== 'GAS_NATURAL',
  solicitudTmp_id: tmpApplication['id'],
  solicitud_id: tmpApplication['solicitud_id'],
  anexo: tmpApplication['selectedHome'],
  bloque: tmpApplication['selectedHome'],
  cidi: tmpApplication['cidi'],
  cod_postal: tmpApplication['cod_postal'],
  direccion: tmpApplication['direccion'],
  distancia_real: state.customerForm['distancia_real'],
  escalera: tmpApplication['escalera'],
  municipio: tmpApplication['municipio'],
  numero: tmpApplication['numero'],
  piso: tmpApplication['piso'],
  puerta: tmpApplication['puerta'],
  tipo_calle: tmpApplication['tipo_calle'],
  tipo_vivienda: tmpApplication['tipo_vivienda'],
  observaciones_gestor: state.customerForm['observaciones'],
  heading: state.customerForm['heading'],
  pitch: state.customerForm['pitch'],
  latitud: state.customerForm['latitude'],
  longitud: state.customerForm['longitude'],
  sv_latitud: state.customerForm['sv_latitud'],
  sv_longitud: state.customerForm['sv_longitud'],
  sv_height: state.customerForm['sv_height'],
  sv_marker_x: state.customerForm['sv_marker_x'],
  sv_marker_y: state.customerForm['sv_marker_y'],
  sv_width: state.customerForm['sv_width'],
  povLat: state.customerForm['povLat'],
  povLng: state.customerForm['povLng'],
  empresa_ca_id: state.customerForm['empresa_ca_id'],
  numero_tarifa_aplicada: state.customerForm['tarifa'],
  potencia: state.customerForm['potencia_instalada_total'],
  consumo: state.customerForm['consumo_anual'],
  facturacion: {
    nombre: state.customerForm['interlocutor_facturacion'],
    telefono: state.customerForm['telefono_facturacion'],
    email: state.customerForm['email_facturacion'],
    tipo_identificador: 'NIF',
    nif: state.customerForm['nif_facturacion'],
  },
  gran_consumo: {
    punto_suministro_nombre: state.customerForm['punto_suministro_nombre'],
    punto_suministro_nif: state.customerForm['punto_suministro_nif'],
    punto_suministro_cnae: state.customerForm['punto_suministro_cnae'],
    solicitante_nombre_empresa: state.customerForm['solicitante_nombre_empresa'],
    solicitante_interlocutor: state.customerForm['solicitante_interlocutor'],
    solicitante_telefono: state.customerForm['solicitante_telefono'],
    solicitante_nif: state.customerForm['solicitante_nif'],
    solicitante_cargo: state.customerForm['solicitante_cargo'],
    solicitante_email: state.customerForm['solicitante_email'],
    presion_contaje: state.customerForm['presion_contaje'],
    horas_funcionamiento: parseInt(state.customerForm['horas_funcionamiento']),
    ubi_equipo_medida: state.customerForm['ubi_equipo_medida'],
    contador_previsto: state.customerForm['contador_previsto'],
    potencia_instalada_total: state.customerForm['potencia_instalada_total'],
    equipo_gran_consumo: state.customerForm['equipo_gran_consumo'].map(
      ({ cantidad, potencia, instalado_previsto, tipo }) => ({
        cantidad: parseInt(cantidad),
        potencia: parseInt(potencia),
        instalado_previsto,
        tipo,
      })
    ),
  },
  gas_p: state.customerForm['gas_p'],
})

export const assemblePayloadFromTmpApp = (subtype, state, tmpApplication, id_user) => {
  const common = assembleCommonPayloadFromTmpApp(subtype, state, tmpApplication, id_user)
  switch (subtype) {
    case APP_TYPE.SAG:
    case APP_TYPE.NN:
      return {
        ...common,
        num_suministros_finca_interesados: state.customerForm['num_suministros_finca_interesados'],
        num_suministros_finca_potenciales: state.customerForm['num_suministros_finca_potenciales'],
      }
    case APP_TYPE.SCR:
      return {
        ...common,
        num_cedula_habitabilidad: state.customerForm['num_cedula_habitabilidad'],
      }
    default:
      throw new Error('Unrecognized application type')
  }
}

export const assembleDocumentPayload = (file, form, id_client, id_app, id_budget) => {
  const payload = {
    tipo: file.type,
    documento: form[file.field].data.split(',')[1],
    nombre: form[file.field].name.replace(/\.[^/.]+$/, ''),
    extension: form[file.field].type,
    cliente_id: parseInt(id_client),
    solicitud_id: id_app.toString(),
  }
  if (id_budget) {
    payload.presupuesto_id = id_budget.toString()
  }
  return payload
}

//PENDING:
//- solicitud_id
//- client_id
export const assembleCertificate = ({ customerForm, selectedHome, consultData, empresa }) => {
  return {
    fecha_certificado: new Date(),
    gas_natural: true,
    //Data from application
    tipo_mercado: consultData.mercado,
    cups: consultData.cups,
    nombre_titular: customerForm.nombre + ' ' + customerForm.apellidos,
    tipo_documento_titular: customerForm.tipo_identificador,
    numero_documento_titular: customerForm.identificador,
    telefono_movil_visita: customerForm.telefono,
    email_visita: customerForm.email,
    provincia_emplazamiento: selectedHome.provincia,
    municipio_emplazamiento: selectedHome.municipio,
    via_emplazamiento: selectedHome.tipo_calle + ' ' + selectedHome.nombre_via,
    numero_emplazamiento: selectedHome.numero,
    portal_emplazamiento: selectedHome.bloque,
    escalera_emplazamiento: selectedHome.escalera,
    piso_emplazamiento: selectedHome.piso,
    puerta_emplazamiento: selectedHome.puerta,
    //Data from installator
    empresa: empresa.nombre,
    numero_registro: '--',
    telefono_movil_empresa: empresa.telefono,
  }
}

export const assembleFullCertificate = (certificateForm, id_client, id_app) => {
  return {
    ...certificateForm,
    solicitud_id: id_app,
    client_id: id_client,
  }
}
