import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid } from '@material-ui/core'

import { RatingInput } from '../../../../ui'
import { useAppContext } from '../../contexts'
import { COMBOS_MOCK } from '../../../../mocks'

export const ReviewApp = () => {
  const { formatMessage: fm } = useIntl()
  const { application } = useAppContext()
  const review = application.valoracion

  return (
    <Box bgcolor="grey.100" borderRadius={4} p={2}>
      <Box mb={2}>
        <Typography variant="subtitle2">
          {fm({ id: 'pages.application.detail.information.review.label' })}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Box mb={2}>
            <Typography variant="subtitle2" gutterBottom>
              {fm({ id: 'pages.application.detail.information.tiempo_contacto_instalador.label' })}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {
                COMBOS_MOCK.tipo_dias.data.find(
                  (option) => option.key === review.tiempo_contacto_instalador
                )?.value
              }
            </Typography>
          </Box>
          <Box mb={2}>
            <RatingInput
              {...{
                name: 'valoracion_tiempo_instalacion',
                value: review.valoracion_tiempo_instalacion,
                readOnly: true,
                title: fm({
                  id: 'pages.application.detail.information.valoracion_tiempo_instalacion.label',
                }),
                size: 'small',
                fontSize: 'small',
              }}
            />
          </Box>
          <RatingInput
            {...{
              name: 'valoracion',
              value: review.valoracion,
              readOnly: true,
              title: fm({ id: 'pages.application.detail.information.valoracion.label' }),
              size: 'small',
              fontSize: 'small',
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          {review.comentario ? (
            <Box mb={2} display="flex" flexDirection="column">
              <Typography variant="subtitle2" gutterBottom>
                {fm({ id: 'pages.application.detail.information.comentario.label' })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {review.comentario}
              </Typography>
            </Box>
          ) : null}
          {review.respuesta ? (
            <Box>
              <Typography variant="subtitle2" gutterBottom>
                {fm({ id: 'pages.application.detail.information.respuesta.label' })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {review.respuesta}
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  )
}
