import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { isValidCIF, isValidEmail, isValidNIE, isValidNIF } from '../../../../core/utils'
import { isValidPhone } from '../../../../core/utils/phoneUtils'

export const EditCompanyBtn = (props) => {
  const { formatMessage } = useIntl()
  const txt = (extension) =>
    formatMessage({ id: 'pages.sir.detail.fields.' + extension + '.error' })
  const { application, editCompanyMode, setCompanyEditMode, updateUserSir, getSirEvents } =
    useSirContext()
  const [errorMssg, setErrorMssg] = useState('')

  const [loading, setLoading] = useState(false)

  function handleCompanyEdition() {
    setCompanyEditMode(!editCompanyMode)
  }

  //Eliminamos el modo edición al desmontar el componente
  useEffect(() => {
    return () => {
      setCompanyEditMode(false)
    }
  }, [])

  function handleCancelCompanyEdition() {
    props.setFormState({
      ...application,
      usuario_sir: {
        ...application.usuario_sir,
        nombre_empresa: application.usuario_sir.nombre_empresa,
        nif_empresa: application.usuario_sir.nif_empresa,
        nombre_instalador: application.usuario_sir.nombre_instalador,
        apellidos_instalador: application.usuario_sir.apellidos_instalador,
        telefono_empresa: application.usuario_sir.telefono_empresa,
        email_empresa: application.usuario_sir.email_empresa,
        num_registro_empresa: application.usuario_sir.num_registro_empresa,
        categoria_empresa: application.usuario_sir.categoria_empresa,
      },
    })
    setErrorMssg('')
    setCompanyEditMode(false)
  }

  function validateCompanyData(usuario_sir) {
    if (usuario_sir.nombre_empresa === '') {
      setErrorMssg(txt('empresa_nombre'))
      return false
    }
    if (
      !isValidNIF(usuario_sir.nif_empresa) &&
      !isValidCIF(usuario_sir.nif_empresa) &&
      !isValidNIE(usuario_sir.nif_empresa)
    ) {
      setErrorMssg(txt('empresa_nif'))
      return false
    }
    if (usuario_sir.nombre_instalador === '') {
      setErrorMssg(txt('empresa_nombre_instalador'))
      return false
    }
    if (!isValidPhone(usuario_sir.telefono_empresa)) {
      setErrorMssg(txt('empresa_telefono'))
      return false
    }
    if (!isValidEmail(usuario_sir.email_empresa)) {
      setErrorMssg(txt('empresa_email'))
      return false
    }

    if (usuario_sir.num_registro_empresa === '') {
      setErrorMssg(txt('empresa_num_registro'))
      return false
    }
    if (!['A', 'B', 'C'].includes(usuario_sir.categoria_empresa)) {
      setErrorMssg(txt('empresa_categoria'))
      return false
    }
    return true
  }

  function handleSaveCompanyEdited(e) {
    e.preventDefault()
    if (editCompanyMode) {
      setErrorMssg('')
      const usuario_sir = props.formState.usuario_sir
      if (validateCompanyData(usuario_sir)) {
        let payload = {}

        payload['nombre_empresa'] = usuario_sir.nombre_empresa
        payload['nif_empresa'] = usuario_sir.nif_empresa
        payload['nombre_instalador'] = usuario_sir.nombre_instalador
        payload['apellidos_instalador'] = usuario_sir.apellidos_instalador
          ? usuario_sir.apellidos_instalador
          : ''
        payload['telefono_empresa'] = usuario_sir.telefono_empresa
        payload['email_empresa'] = usuario_sir.email_empresa
        payload['num_registro_empresa'] = usuario_sir.num_registro_empresa
        payload['categoria_empresa'] = usuario_sir.categoria_empresa

        setLoading(true)
        updateUserSir(props.formState.usuario_sir.id, payload)
          .then(() => {
            setErrorMssg('')
            setLoading(false)
            setCompanyEditMode(false)
            getSirEvents(props.formState.id)
          })
          .catch(() => {
            setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
            setLoading(false)
            setCompanyEditMode(true)
          })
      }
    }
  }

  return (
    <Box>
      {editCompanyMode ? (
        <Box display="flex" justifyContent="flex-start">
          <ThemeButton color="default" onClick={handleCancelCompanyEdition}>
            {formatMessage({ id: 'pages.sir.detail.button.cancel.label' })}
          </ThemeButton>
          <ThemeButton
            color="primary"
            loading={loading}
            onClick={handleSaveCompanyEdited}
            style={{ marginLeft: '10px' }}
          >
            {formatMessage({ id: 'pages.sir.detail.button.save.label' })}
          </ThemeButton>
        </Box>
      ) : (
        <ThemeButton color="primary" loading={loading} onClick={handleCompanyEdition}>
          {formatMessage({ id: 'pages.sir.detail.button.edit.company.label' })}
        </ThemeButton>
      )}
      {errorMssg ? (
        <Box mt={2}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      ) : null}
    </Box>
  )
}
