import React from 'react'
import { useIntl } from 'react-intl'
import { useCallback, useState } from 'react'
import { ThemeButton, useModalContext } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { useAppCalls } from '../../../application-detail/hooks'
import { SirStateChangerDialog } from '../dialogs/sir-state-changer-dialog.component'

export const SirStateChangerBtn = (props) => {
  const { application } = useSirContext()

  const [loading, setLoading] = useState(false)
  const { getAvailableStatesCall, changeToAvailableStateCall } = useAppCalls()

  const { formatMessage: fm } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  function refreshPage() {
    window.location.reload(false)
  }

  const getAvailableStatesToChange = useCallback(() => {
    return new Promise((resolve, reject) => {
      getAvailableStatesCall(application.id)
        .then(({ data }) => resolve(data))
        .catch(() => reject())
    })
  }, [application.id, getAvailableStatesCall])

  const changeToAvailableState = (state) => {
    return new Promise((resolve, reject) => {
      setLoading(true)
      changeToAvailableStateCall(application.id, state)
        .then(({ data }) => {
          setLoading(false)
          resolve(data)
          refreshPage()
        })
        .catch(() => {
          setLoading(false)
          reject()
        })
    })
  }

  const handleOpenModal = () => {
    open({
      Component: SirStateChangerDialog,
      data: {
        getAvailableStatesToChange,
        changeToAvailableState,
        appLoading: loading,
      },
      type: 'responsive',
      maxWidth: 'xs',
    })
  }

  return (
    <ThemeButton size="small" color="secondary" onClick={handleOpenModal} disabled={props.disabled}>
      {fm({ id: 'pages.application.detail.info.stateChanger.btn.label' })}
    </ThemeButton>
  )
}
