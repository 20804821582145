export const GRID_ITEM_SMALL = {
  item: true,
  xs: 6,
  sm: 6,
  md: 3,
}

export const REPORT_DETAILED_CONFIG = (intl) => {
  const calculateDuration = (start, end) => {
    if (!start || !end) return ('N/A')
    let seconds = (new Date(end) - new Date(start)) / 1000
    if (seconds < 0) return ('N/A')
    else if (seconds < 3600) return new Date(seconds * 1000).toISOString().slice(14, 19);
    else new Date(seconds * 1000).toISOString().slice(11, 19);
  }

  const DEFAULT_PROPS = {
    fontSize: 'small',
  }
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: 'pages.jobs.report.detailed.form.' + extension + '.title' })
  return {
    JOB_DETAILS_ID: {
      ...DEFAULT_PROPS,
      title: txt('id'),
      name: 'id',
      readOnly: true,
      fromData: (data) => ({
        value: data['id'],
      })
    },
    JOB_DETAILS_NAME: {
      ...DEFAULT_PROPS,
      title: txt('name'),
      name: 'name',
      readOnly: true,
      fromData: (data) => ({
        value: data['name'],
      })
    },
    JOB_DETAILS_VERSION: {
      ...DEFAULT_PROPS,
      title: txt('version'),
      name: 'version',
      readOnly: true,
      fromData: (data) => ({
        value: data['version'],
      })
    },
    JOB_DETAILS_START: {
      ...DEFAULT_PROPS,
      title: txt('launch'),
      name: 'launch',
      readOnly: true,
      fromData: (data) => ({
        value: new Date(data['start_time'] ).toLocaleString(),
      }),
      type: 'date'
    },
    JOB_DETAILS_END: {
      ...DEFAULT_PROPS,
      title: txt('elapsed'),
      name: 'elapsed',
      readOnly: true,
      fromData: (data) => ({
        value: calculateDuration(data['start_time'],data['end_time']),
      }),
    },
  }
}
export const REPORT_DETAILED_VIEW = [
  {
    title: 'Detalles del batch',
    isFolded: false,
    items: [[
      {ref: 'JOB_DETAILS_ID', conf: GRID_ITEM_SMALL},
      {ref: 'JOB_DETAILS_NAME', conf: GRID_ITEM_SMALL},
      {ref: 'JOB_DETAILS_VERSION', conf: GRID_ITEM_SMALL},
      {ref: 'JOB_DETAILS_START', conf: GRID_ITEM_SMALL},
      {ref: 'JOB_DETAILS_END', conf: GRID_ITEM_SMALL},
    ]]
  }
]