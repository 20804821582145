import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { AutocompleteInput, ThemeButton } from '../../../../ui'
import { ADD_FIELDS_CONFIG } from '../../constants/add-installer.constants'
import { useAppContext } from '../../contexts'

export const AddGcInstallerDialog = ({ setGCDialog, application }) => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)

  const [formState, setFormState] = useState({})
  const { installerValues, getInstallerValues, changeInstaller } = useAppContext()
  const handleOnChangeForm = (e) => {
    let { name, value } = e
    setFormState({
      empresa_ca_id: value.empresa_ca_id,
      [name]: { ...value },
      empresa_id: value.empresa_id,
    })
  }

  const handleAceptar = () => {
    changeInstaller(formState, application.id).then(() => {
      setGCDialog(false)
    })
  }

  const handleClose = () => {
    setGCDialog(false)
  }

  const inputProps = {
    formState: formState,
    onChange: handleOnChangeForm,
    combos,
  }

  return (
    <Box p={2} overflow="none" width="40em">
      <Box mb={2}>
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.consult.granconsumo.add.installer.title' })}
        </Typography>
      </Box>
      <form>
        <AutocompleteInput
          {...{
            ...ADD_FIELDS_CONFIG(inputProps).INSTALADORES,
            values: installerValues,
            onType: getInstallerValues,
          }}
        />
        <Box mt={2} display="flex" justifyContent="space-between" width="100%">
          <Box display="flex" justifyContent="space-between" width="100%">
            <ThemeButton color="primary" labelM="lg" onClick={handleClose}>
              {formatMessage({ id: 'global.close' })}
            </ThemeButton>
            <ThemeButton color="primary" labelM="lg" onClick={handleAceptar}>
              {formatMessage({ id: 'global.accept' })}
            </ThemeButton>
          </Box>
        </Box>
      </form>
    </Box>
  )
}
