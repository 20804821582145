import React from 'react'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useCombos } from '../../../features/combos'

import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { UserHeader } from '../../../features/users/components/header/user-header.component'
import { UserTable } from '../../../features/users/components/table'
import { useUserCalls } from '../../../features/users/hooks'

import { userStyles } from './user.styles'

export const UsersPage = withStyles(userStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getUsers } = useUserCalls()
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'users',
    call: getUsers,
  })
  return (
    <Container className={classes.container} maxWidth="false">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.users.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <UserHeader />
      </Box>
      <Box>
        <UserTable />
      </Box>
    </Container>
  )
})
