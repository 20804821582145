export const TASK_HISTORY_TABLE_CONFIG = (intl) => [
  {
    key: 'field',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.field' }),
    type: 'comboData',
  },
  {
    key: 'value',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.value' }),
  },
  {
    key: 'date',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.dg_ts_insert' }),
    type: 'datetime',
  },
]

export const TASK_DOCUMENTS_HISTORY_CONFIG = (intl) => [
  {
    key: 'tipo',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.document_type' }),
    type: 'comboDocumentData',
  },
  {
    key: 'nombre',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.name' }),
  },
  {
    key: 'dg_ts_insert',
    label: intl.formatMessage({ id: 'pages.supervision.detail.history.tableFields.dg_ts_insert' }),
    type: 'datetime',
  },
]
