//In order to avoid table ui malfunction one should make sure there's at least 1200
export const APPS_TABLE_COLUMNS = [
  { field: 'solicitud_id', align: 'left', width: 180 },
  { field: 'usuario_sspp', align: 'left', width: 140, sorting: false },
  {
    field: 'estado',
    align: 'left',
    width: 90,
    sorting: false,
  },
  { field: 'cups', align: 'left', width: 200 },
  {
    field: 'estado_suministro',
    align: 'left',
    width: 150,
    mytype: 'large_text',
    sorting: false,
  },
  {
    field: 'motivo_no_captabilidad',
    align: 'left',
    width: 150,
    mytype: 'large_text',
    sorting: false,
  },
  { field: 'mercado', align: 'left', width: 80 },
  {
    field: 'fecha_creacion',
    align: 'left',
    width: 110,
    type: 'date',
    exportType: 'date',
  },
  { field: 'distribuidora', align: 'left', width: 200 },
  { field: 'usuario_identificador', align: 'left', width: 120, sorting: false },
  { field: 'cod_postal', align: 'left', width: 65 },
  { field: 'direccion', align: 'left', width: 200, sorting: false },
  {
    field: 'provincia',
    align: 'left',
    width: 140,
    exportType: 'combo',
    comboId: 'provincias_sspp',
  },
  { field: 'municipio', align: 'left', width: 200 },
  {
    field: 'observaciones_cambio_estado',
    align: 'left',
    width: 350,
    mytype: 'large_text',
    sorting: false,
  },
  {
    field: 'fecha_modificacion',
    align: 'left',
    width: 110,
    type: 'date',
    exportType: 'date',
  },
]
export const EXTRA_COLUMNS = []
export const APPS_TABLE_ACTIONS = [
  // {
  //   field: 'view',
  //   width: 75,
  //   align: 'center',
  //   sorting: false,
  // },
]

export const APPS_EXPORT_COLUMNS = [
  { field: 'solicitud_id' },
  { field: 'usuario_sspp' },
  { field: 'estado' },
  { field: 'cups' },
  { field: 'estado_suministro' },
  { field: 'motivo_no_captabilidad' },
  { field: 'mercado' },
  { field: 'fecha_creacion', exportType: 'date' },
  { field: 'distribuidora' },
  { field: 'usuario_identificador' },
  { field: 'cod_postal' },
  { field: 'direccion' },
  // { field: 'provincia' },
  { field: 'municipio' },
  { field: 'observaciones' },
  { field: 'fecha_modificacion', exportType: 'date' },
]
