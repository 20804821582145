import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../core/ajax/contexts'
import { feedback } from '../../../core/feedback'

export const useTariffCalls = () => {
  const { getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getTariff = useCallback(
    (tariffId) =>
      new Promise((resolve, reject) => {
        getById(`/tarifa/obtenerDatosTarifa`, `${tariffId}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getTariffZone = useCallback((empresaId, tariffId) => {
    const enterprise = parseInt(empresaId)
    if (isNaN(enterprise)) {
      throw new Error('Wrong petition!')
    }
    return new Promise((resolve, reject) => {
      getById(`/tarifa/zonastarifa/${enterprise}/tarifa`, `${tariffId}`)
        .then((response) => resolve(response))
        .catch(({ response }) => {
          if (!response) {
            noResponseError()
            return reject('No response')
          }
          switch (response.status) {
            case 401:
            case 404:
              feedback(
                'error',
                formatMessage({ id: 'calls.tariffs.error.wrongTariffZone.description' })
              )
              break
            default:
              defaultError()
          }
          return reject(response.status)
        })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    getTariff,
    getTariffZone,
  }
}
