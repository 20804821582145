import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useIPContext } from '../../contexts'
import { GridForm } from './grid-form.component'
import { IP_NONCONFORMITY_STATES } from './ip-form.constants'
import { IP_VALIDATED_DELIVERY_FAILED_STATE } from './ip-form.constants'
import { IPStateBtns } from './ip-state-btns.component'
import { IPNonconfomityView } from './ip-nonconformity-view.component'

export const IPForm = () => {
  const { ip, inputsConfig, formConfig } = useIPContext()

  return (
    <form>
      <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
        <IPStateBtns />
      </Box>
      {ip.estado === IP_VALIDATED_DELIVERY_FAILED_STATE && !!ip.error_zeus?.length ? (
        <Alert severity="warning">
          <Typography variant="subtitle1">Envío a Zeus fallido: </Typography>
          {ip.error_zeus.map((error, i) => (
            <Typography key={i} variant="body1" style={{ marginLeft: 10 }}>
              - {error.mensaje}
            </Typography>
          ))}
        </Alert>
      ) : null}
      {IP_NONCONFORMITY_STATES[ip.estado] ? (
        <IPNonconfomityView />
      ) : (
        <GridForm {...{ grid: formConfig, inputs: inputsConfig() }} />
      )}
    </form>
  )
}
