import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { SelectInput, ThemeButton } from '../../../ui'
import { useGCCalls } from '../hooks'

export const ReasingGestor = ({ selectedRows, close, table, setTable }) => {
  const [gestorId, setGestorId] = useState(0)
  const combos = useSelector((state) => state.combos)
  const intl = useIntl()
  const { putReasingGestor } = useGCCalls()

  const handleOnChange = (e) => {
    setGestorId(e.target.value)
  }

  const handleReAsing = () => {
    const selectedIdsArray = []
    selectedRows.forEach((row) => {
      selectedIdsArray.push(parseInt(row.id))
    })
    const payload = {
      gestor_id: parseInt(gestorId),
      zonas_gran_consumo: selectedIdsArray,
    }
    putReasingGestor(payload)
    setTable(!table)
    close()
  }

  return (
    <Box p={4}>
      <Box display="flex">
        <Typography variant="h4" color="primary">
          Reasignar Gestor
        </Typography>
      </Box>
      <Box mt={4}>
        <SelectInput
          fullWidth={true}
          value={gestorId}
          values={combos.gestores.data}
          name="gestor_id"
          onChange={handleOnChange}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <ThemeButton
          color="primary"
          labelM="sm"
          onClick={handleReAsing}
          style={{ marginRight: '1em' }}
          disabled={!selectedRows?.length}
        >
          {intl.formatMessage({ id: 'global.Reasing' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
