import React from 'react'
import { withStyles, Container } from '@material-ui/core'

import { ProfileUser } from '../../../features/profile'
import { profileStyles } from './profile.styles'

export const ProfilePage = withStyles(profileStyles)(({ classes }) => {
  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <ProfileUser forceOpenEdit={false} />
      </Container>
    </div>
  )
})
