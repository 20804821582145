import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { useAjaxContext } from '../../../../core/ajax'
import { ThemeButton } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { Tooltip, Icon, Typography, Box } from '@material-ui/core'
import { useApplicationsCalls } from '../../hooks/application-calls.hook'

export const AutoassignBtn = () => {
  const { formatMessage } = useIntl()

  const userId = useSelector((state) => state.global.userId)
  const searchParams = useSelector((state) => state.applications.search)
  const { putById } = useAjaxContext()
  const [loading, setLoading] = useState(false)
  const { getApplications } = useApplicationsCalls()

  const handleAutoAssign = () => {
    setLoading(true)
    const url = window.location.href
    if (url.includes('solicitud_estado=37&l4_bot=true')) {
      putById('/gestor/asignar_solicitud_automatica', userId, { l4: true })
        .then(({ data }) => {
          getApplications(searchParams)
          setLoading(false)
          feedback(
            'success',
            formatMessage({ id: 'pages.applications.feedback.5' }, { id: data['solicitud_id'] })
          )
        })
        .catch(({ response }) => {
          setLoading(false)
          if (response.data?.code === 3001) {
            feedback(
              'warning',
              formatMessage({ id: 'pages.applications.feedback.warning.autoassign' })
            )
          } else if (response.data?.code === 3018) {
            feedback(
              'warning',
              formatMessage({ id: 'pages.applications.feedback.warning.autoassign_no_solicitudes' })
            )
          } else if (response.data?.code === 4303) {
            feedback(
              'warning',
              formatMessage({
                id: 'pages.applications.feedback.warning.autoassign_max_solicitudes',
              })
            )
          } else if (response.data?.code === 4207) {
            feedback(
              'error',
              formatMessage({ id: 'pages.applications.feedback.error.autoassign_no_gestor' })
            )
          } else {
            feedback('error', formatMessage({ id: 'pages.applications.feedback.error.autoassign' }))
          }
        })
    } else {
      putById('/gestor/asignar_solicitud_automatica', userId)
        .then(({ data }) => {
          getApplications(searchParams)
          setLoading(false)
          feedback(
            'success',
            formatMessage({ id: 'pages.applications.feedback.5' }, { id: data['solicitud_id'] })
          )
        })
        .catch(({ response }) => {
          setLoading(false)
          if (response.data?.code === 3001) {
            feedback(
              'warning',
              formatMessage({ id: 'pages.applications.feedback.warning.autoassign' })
            )
          } else if (response.data?.code === 3018) {
            feedback(
              'warning',
              formatMessage({ id: 'pages.applications.feedback.warning.autoassign_no_solicitudes' })
            )
          } else if (response.data?.code === 4303) {
            feedback(
              'warning',
              formatMessage({
                id: 'pages.applications.feedback.warning.autoassign_max_solicitudes',
              })
            )
          } else if (response.data?.code === 4207) {
            feedback(
              'error',
              formatMessage({ id: 'pages.applications.feedback.error.autoassign_no_gestor' })
            )
          } else {
            feedback('error', formatMessage({ id: 'pages.applications.feedback.error.autoassign' }))
          }
        })
    }
  }
  const tooltip = (
    <span
      style={{
        fontSize: 16,
        position: 'absolute',
        top: '-1em',
        right: '-1em',
      }}
    >
      <Tooltip
        placement="top"
        title={
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {formatMessage({ id: 'pages.applications.autoAssignButton.tooltip.label' })}
          </Typography>
        }
      >
        <Icon fontSize="inherit">help_outline</Icon>
      </Tooltip>
    </span>
  )

  return (
    <Box ml={2} mr={2}>
      <Box display="flex" flexDirection="row" style={{ position: 'relative' }}>
        <ThemeButton color="primary" labelM="sm" loading={loading} onClick={handleAutoAssign}>
          {formatMessage({ id: 'pages.applications.autoAssignButton.label' })}
        </ThemeButton>
        {tooltip}
      </Box>
    </Box>
  )
}
