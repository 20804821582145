import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  SupervisionForm,
  SupervisionContext,
  APPS_COMBOS_IDS,
  ORDER_COMBOS_IDS,
  SupervisionCertificateForm,
  SupervisionDEForm,
} from '../../../features/supervision'
import { useCombos } from '../../../features/combos/hooks'
import { SupervisionDashboardPage } from './dashboard'

const SupervisionPageBare = () => {
  useCombos(APPS_COMBOS_IDS, ORDER_COMBOS_IDS)

  return (
    <Switch>
      <Route exact path="/supervision" component={SupervisionDashboardPage} />
      <Route exact path="/supervision/:appId" component={SupervisionForm} />
      <Route exact path="/supervision/:appId/DE" component={SupervisionDEForm} />
      <Route
        exact
        path="/supervision/:appId/:certificateType"
        component={SupervisionCertificateForm}
      />

      <Redirect from="*" to="/supervision" />
    </Switch>
  )
}

export const SupervisionPage = (props) => {
  return (
    <SupervisionContext>
      <SupervisionPageBare {...props} />
    </SupervisionContext>
  )
}
