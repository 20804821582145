import React, { useCallback, useMemo, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useIPContext } from '../../contexts'
import { ipTableDataPreparation } from '../../logics'
import { IPCellCUPS } from '../custom-table-cells'
import { IP_TABLE_COLUMNS, DEFAULT_QUERY } from './ip-table.constants'
import { ipTableStyles } from './ip-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const IPTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useCombos([])
  const {
    data,
    pagination: { total_registros: totalEntries },
    loading,
    search,
    tableSelection,
    onClickTableRow,
    setSelectedTableRows,
    getTableData,
  } = useIPContext()
  const permissions = useSelector((state) => state.global.permissions)

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    } else {
      history.push(`/ip?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      IP_TABLE_COLUMNS.map((column) => {
        if (column.field === 'cupsCustom') {
          return {
            ...column,
            title: formatMessage({ id: `pages.ip.table.columns.${column.field}.title` }),
          }
        } else {
          return {
            ...column,
            title: formatMessage({ id: `pages.ip.table.columns.${column.field}.title` }),
            cellStyle: {
              ...ipTableStyles.cell,
              ...column.cellStyle,
              minWidth: column.width,
            },
            emptyValue: () => <span>--</span>,
            lookup:
              !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
                ? getLookup(combos[column.comboId].data)
                : null,
          }
        }
      }),
    [IP_TABLE_COLUMNS, ipTableStyles.cell, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return ipTableDataPreparation({ data, combos, intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, combos, intl]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      showSelectAllCheckbox: false,
      headerStyle: ipTableStyles.header,
      tableLayout: 'fixed',
      draggable: false,
    }),
    [ipTableStyles.header] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'cupsCustom' ? <IPCellCUPS {...props} /> : props.children}
        </MTableCell>
      ),
    }),
    []
  )
  const handleRowClick = useCallback(
    (_, row) => {
      onClickTableRow(row['id'])
    },
    [onClickTableRow]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onRowClick={permissions.ver_detalle_ip ? handleRowClick : null}
      onSelectionChange={handleSelectRows}
    />
  )
}
