import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Tooltip, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useIPCalls } from '../../hooks'
import { useCombos } from '../../../combos'

const tooltipStyles = makeStyles((theme) => ({
  // tooltip: {
  //   color: 'black',
  //   backgroundColor: 'white',
  // },
}))

export const IPCellCUPS = (props) => {
  const { formatMessage, formatDate } = useIntl()
  const cups = props.rowData['cups']
  const combos = useCombos(['estados_inspeccion'])
  const { getCUPSEventsCall } = useIPCalls()
  const tooltipClasses = tooltipStyles()

  const [open, setOpen] = useState(false)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const handleClose = () => setOpen(false)

  const handleOpen = () => {
    setLoading(true)
    setError(false)
    setOpen(true)
    getCUPSEventsCall(cups)
      .then(({ data }) => {
        setEvents(data)
        setLoading(false)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }

  const CUPSEvents = () => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height={60} width={60}>
          <CircularProgress size={20} color="ffffff" />
        </Box>
      )
    } else if (error) {
      return formatMessage({ id: 'pages.ip.table.columns.cupsCustom.tooltip.error' })
    } else {
      return events.map((event) => {
        const state = combos.estados_inspeccion.data.find(
          (option) => option.key === event.estado
        )?.value
        return (
          <Box display="flex">
            <Typography display="inline">
              [{formatDate(new Date(event.fecha_inspeccion))}] {state || '--'}{' '}
              {event.motivo_rechazo ? '(' + event.motivo_rechazo + ')' : ''}
            </Typography>
          </Box>
        )
      })
    }
  }

  return cups ? (
    <Tooltip
      arrow
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<CUPSEvents />}
      classes={tooltipClasses}
    >
      <Typography variant="subtitle2" color="primary">
        {cups}
      </Typography>
    </Tooltip>
  ) : props.rowData.codigo_instalacion ? (
    <Typography variant="body2" color="textSecondary">
      {props.rowData.codigo_instalacion}
    </Typography>
  ) : null
}
