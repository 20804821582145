import React from 'react'
import { withStyles, Typography, Box, Checkbox, Divider, FormHelperText } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FormFoldingPanel, TextInput, ThemeButton } from '../../../../ui'
import { FormsContainerStyles } from './certificate-form.styles'
import { CertificateDocuments } from '../certificate-documents'
import { CertificateDevices } from '../certificate-devices'
import { CertificateServices } from '../certificate-services'

export const CertificateFormStructure = withStyles(FormsContainerStyles)(
  ({
    classes,
    form,
    formState,
    handleChange,
    fieldList,
    documentList,
    deviceList,
    services,
    errors,
    showErrors,
    check,
    setCheck,
    onSubmit,
    viewCampanas = false,
  }) => {
    const intl = useIntl()
    const combos = useSelector((state) => state.combos)
    const { formatMessage } = useIntl()

    //Adapt field 'observaciones' to data
    const { fromData, ...observaciones_static } = fieldList['OBSERVACIONES']
    const observaciones = {
      ...observaciones_static,
      ...fromData(formState),
    }

    return (
      <form>
        <Box className={classes.mainBox}>
          {form.structure.map((block, i) => {
            return (
              <div key={i} style={{ margin: '24px 0 0 0' }}>
                <FormFoldingPanel
                  {...{
                    data: formState,
                    combos,
                    intl,
                    block,
                    fieldList,
                    errors,
                    showErrors,
                  }}
                />
              </div>
            )
          })}
        </Box>
        {services ? (
          <CertificateServices
            {...{ classes, formState, combos, handleChange, showErrors, errors, viewCampanas }}
          />
        ) : null}
        {deviceList && deviceList.length ? (
          <>
            <CertificateDevices
              data={formState}
              deviceList={deviceList}
              onChange={handleChange}
              errors={errors}
              showErrors={showErrors}
            />
            <Divider className={classes.divider} />
          </>
        ) : null}
        {documentList && documentList.length ? (
          <>
            <CertificateDocuments
              documentList={documentList}
              data={formState}
              updateInfo={handleChange}
              showErrors={showErrors}
              errors={errors}
            />
            <Divider className={classes.divider} />
          </>
        ) : null}
        <TextInput {...observaciones} />
        <Box display="flex" alignItems="center" mt={2}>
          <Checkbox
            color="primary"
            size="small"
            required
            name="compromise"
            onClick={() => {
              setCheck((check) => !check)
            }}
          />
          <Typography variant="body2">{form.disclaimer}</Typography>
        </Box>
        <Box>
          <FormHelperText error={showErrors && !check}>
            {showErrors && !check
              ? formatMessage({ id: 'pages.certificates.form.check.unchecked' })
              : null}
          </FormHelperText>
        </Box>
        <Box textAlign="center" mt={4}>
          <ThemeButton color="primary" labelM="lg" type="button" onClick={onSubmit}>
            {formatMessage({ id: 'global.create' })}
          </ThemeButton>
        </Box>
      </form>
    )
  }
)

export default CertificateFormStructure
