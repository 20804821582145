import React, { useEffect, useState } from 'react'
import { withStyles, TextField, Typography, Checkbox } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ListboxComponent } from './virtualized-list-box.component';
import { filterAutocompleteStyles } from './filter-autocomplete.styles';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons'

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const FilterAutocomplete = withStyles(filterAutocompleteStyles)(
  ({ classes, name, placeholder = '', onChange, values = [], multiple = false, ...props }) => {
    const [open, setOpen] = useState(false);

    // Determinar los valores seleccionados basados en la prop multiple
    const selectedValues = multiple
      ? (props.value ? props.value.split(',').map((val) => values.find((item) => item.key.toString() === val)) : [])
      : (props.value && values.length ? values.find((item) => item.key.toString() === props.value) : null);

    const filterOptions = (options, state) => {
      const { inputValue, getOptionLabel } = state;
      const value = inputValue.trim().toLowerCase();
      return options.filter((option) => {
        if (option.special) {
          return true;
        }
        return getOptionLabel(option)?.toLowerCase().includes(value);
      });
      return options;
    };

    const handleChange = (e, option) => {
      console.log("AS")
      onChange({
        target: {
          name,
          value: multiple
            ? (option ? option.map((opt) => opt.key) : [])
            : (option ? option.key : '')
        }
      });
    };

    return (
      <Autocomplete
        id={`${name}-autocomplete-input`}
        size="small"
        multiple={multiple}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={values}
        getOptionLabel={(option) => option?.value}
        getOptionSelected={(option, value) => option.key === value.key}
        onChange={handleChange}
        value={selectedValues}
        autoSelect
        ListboxComponent={ListboxComponent}
        filterOptions={filterOptions}
        renderOption={(option, { selected }) => (
          multiple ? (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.value}
            </React.Fragment>
          ) : option.value
        )}
        renderInput={(params) => {
          params.InputProps.startAdornment = (
            <React.Fragment>
              <Typography variant="body1" className={classes.title}>
                {props.label}:
              </Typography>
              {params.InputProps.startAdornment}
            </React.Fragment>
          );
          return (
            <TextField
              {...params}
              className={classes.root}
              variant="outlined"
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
              }}
            />
          );
        }}
      />
    );
  }
);
