import { useContext } from 'react'
import { ModalContext } from '../../../../ui/modal/contexts'
import { AdvanceDialog } from './advance-dialog.component'
import { ValidateCupsDialog } from '../dialogs'

export const useAdvanceDialogs = () => {
  const {
    actions: { open },
  } = useContext(ModalContext)

  const confirmAdvance = (application, callback) => {
    open({
      Component: AdvanceDialog,
      data: {
        application,
        callback,
      },
    })
  }

  const cupsContratable = (application, getApplication) => {
    open({
      Component: ValidateCupsDialog,
      data: { application, getApplication },
    })
  }

  return {
    confirmAdvance,
    cupsContratable,
  }
}
