import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ExportToXLSBtn, FilterBox } from '../../../../ui'
import { GUILDS_IP3_TABLE_COLUMNS, GUILDS_IP3_TABLE_FILTERS } from '../../constants'
import { useGuildsIP3Calls } from '../../hooks'
import { guildsIP3DataPreparation } from '../../logics'

export const GuildsIP3Header = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getGuilds } = useGuildsIP3Calls()
  const role = useSelector((state) => state.global.role)
  const guildsLoader = useSelector((state) => state.loader.guildsIP3)
  const selection = guildsLoader?.selection
  const search = guildsLoader?.config?.params

  const buttons = (
    <ExportToXLSBtn
      selectedRows={selection}
      columnsExport={GUILDS_IP3_TABLE_COLUMNS}
      getAllData={() =>
        new Promise((resolve, reject) => {
          getGuilds({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(guildsIP3DataPreparation(data.gremios, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }
      titleRoot="gremios_ip3_"
      headerNamespace="guilds"
    />
  )

  const data = GUILDS_IP3_TABLE_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.guildsIP3.filters.' + item.name + '.title',
      }),
    }
  })

  return <FilterBox filters={data}>{role === 'e_commerce' ? buttons : null}</FilterBox>
}
