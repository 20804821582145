export const DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const DOCUMENT_KEYS = [
  'd_certificado_iban',
  'registro_industrial',
  'documento_nif',
  'certificado_iban_op',
  'documento_pf',
  'formulario_rgpd',
]
