import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Switch, withStyles } from '@material-ui/core'
import LockOpen from '@material-ui/icons/LockOpen'
import EditIcon from '@material-ui/icons/Edit'

import { tableActionsStyles } from './table-actions.styles'
//import { MarketerEdit } from '../dialog'
import { useModalContext } from '../../../../ui'
import { editManager } from '../../slices'
import { toggleManagerActive } from '../../slices'
import { EditManager } from '../edit-user/edit-user.component'
import { AddPermissions } from '../add-permissions/add-permissions'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const {
    actions: { open },
  } = useModalContext()

  const managerId = props.rowData['id']

  const handleGoToPermissions = () =>
    open({
      Component: AddPermissions,
      data: { props, editManager },
      maxWidth: 'md',
    })

  const handleEditManager = () =>
    open({
      Component: EditManager,
      data: { managerId, editManager, props },
      maxWidth: 'md',
    })

  const tariffButtons = {
    edit: {
      onClick: handleEditManager,
      icon: <EditIcon />,
    },
    permissions: {
      onClick: handleGoToPermissions,
      icon: <LockOpen />,
    },
  }

  const availableActions = ['permissions', 'edit']

  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={tariffButtons[action].onClick}
          >
            {tariffButtons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActive = withStyles(switchStyles)(({ classes, ...props }) => {
  const dispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const managers = useSelector((state) => state.managers.data)
  const switchDisabled = '' + props.rowData.id === global.user_id
  const marketerActiveState = props.rowData['activo']

  const handleToggleState = () => dispatch(toggleManagerActive(props.rowData, managers))
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={marketerActiveState}
      onClick={handleToggleState}
      disabled={switchDisabled}
    />
  )
})
