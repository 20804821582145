import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import XLSX from 'xlsx'

import { ThemeButton, useModalContext, AcceptationDialog } from '../'
import { dataToXLS, getFormattedTime } from '../../core/utils'
import { ExportSomethingDialog } from './export-something.dialog'

const DEFAULT_GET_ALL_DATA = () => new Promise((resolve) => resolve([]))

export const ExportToXLSBtn = ({
  exportSelectionEnabled = true,
  exportAllEnabled = true,
  selectedRows = [],
  columnsExport = [],
  getAllData = DEFAULT_GET_ALL_DATA,
  titleRoot = 'export_',
  headerNamespace = 'applications',
  totalRecords,
  totalRecordsLimit = 0,
}) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const [loading, setLoading] = useState(false)

  const {
    actions: { open },
  } = useModalContext()

  const mainExport = (data) => {
    const result = dataToXLS(data, columnsExport, combos, intl, headerNamespace)
    var worksheet = XLSX.utils.aoa_to_sheet(result)
    var new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
    XLSX.writeFile(new_workbook, titleRoot + `${getFormattedTime()}.xlsx`, {
      bookType: 'xlsx',
      type: 'binary',
    })
    setLoading(false)
  }

  const handleExport = async () => {
    if (selectedRows && selectedRows.length) {
      setLoading(true)
      const data = selectedRows
      mainExport(data)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  const handleExportAll = async () => {
    if (totalRecordsLimit && totalRecords > totalRecordsLimit) {
      open({
        Component: AcceptationDialog,
        data: {
          title: intl.formatMessage({ id: 'global.exportAll.limitExcededDialog.title' }),
          text: intl.formatMessage(
            { id: 'global.exportAll.limitExcededDialog.description' },
            { total: totalRecords, limit: totalRecordsLimit }
          ),
          yesText: intl.formatMessage({ id: 'global.close' }),
        },
      })
    } else {
      setLoading(true)
      getAllData()
        .then((recievedData) => {
          mainExport(recievedData)
        })
        .catch(({ response }) => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {exportSelectionEnabled ? (
        <ThemeButton
          color="primary"
          labelM="sm"
          onClick={handleExport}
          style={{ marginRight: '1em' }}
        >
          {intl.formatMessage({ id: 'global.exportSelected' })} ({selectedRows.length})
        </ThemeButton>
      ) : null}
      {exportAllEnabled ? (
        <ThemeButton color="primary" labelM="sm" loading={loading} onClick={handleExportAll}>
          {intl.formatMessage({ id: 'global.exportAll' })}
        </ThemeButton>
      ) : null}
    </>
  )
}
