import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'

import { FormFoldingPanel } from '../../../../../ui'
import { useCombos } from '../../../../combos'
import {
  INSTALLATION_PANEL_CONFIG,
  INSTALLATION_PANEL_STRUCTURE,
} from './installation-panel.constants'
import { APPLICATION_STATES } from '../../../constants'
import { useAppContext } from '../../../contexts'
import { ReviewApp } from '../../review'
import { ReviewAppBtn } from '../../buttons'

export const InstallationPanel = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { application } = useAppContext()
  const role = useSelector((state) => state.global.role)
  const isGc = !!application.gran_consumo_id

  const fieldList = useMemo(() => {
    return INSTALLATION_PANEL_CONFIG({ intl, combos, isGc })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FormFoldingPanel
      {...{
        data: application,
        combos,
        intl,
        block: INSTALLATION_PANEL_STRUCTURE({ intl }),
        fieldList,
        extraContent: !!application.empresa_ca ? (
          application.valoracion ? (
            <>
              <Divider light style={{ margin: '20px 0' }} />
              <ReviewApp />
            </>
          ) : (role === 'selectra' || role === 'gss_vt' || role === 'accom') &&
            application.estado === APPLICATION_STATES.FINISHED.KEY ? (
            <>
              <Divider light style={{ margin: '20px 0' }} />
              <ReviewAppBtn />
            </>
          ) : null
        ) : null,
      }}
    />
  )
}
