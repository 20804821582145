import axios from 'axios'

import { loadLocalStorage, saveLocalStorage } from '../../utils'
import { PUBLIC_INSTANCE_CONFIG, PRIVATE_INSTANCE_CONFIG } from '../constants'

const publicInstance = axios.create(PUBLIC_INSTANCE_CONFIG)

const privateInstance = axios.create(PRIVATE_INSTANCE_CONFIG)

privateInstance.interceptors.request.use((request) => {
  const userState = loadLocalStorage('apg-auth')

  if (userState.token) {
    request.headers.Authorization = 'Bearer ' + userState.token
    request.headers.utc = -new Date().getTimezoneOffset()
  }
  return request
})

const URL_REFRESH_TOKEN = '/login/refresh_token'

let refreshTokenPool = { requestCount: 0 }
privateInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const { status, config } = error.response
    const userState = loadLocalStorage('apg-auth')
    if (config.url === URL_REFRESH_TOKEN || status !== 401 || error.config.retry) {
      throw error
    }
    try {
      if (!refreshTokenPool.promise) {
        const payload = { refresh_token: userState.refresh_token }
        refreshTokenPool.promise = privateInstance
          .post(URL_REFRESH_TOKEN, payload)
          .then((response) => {
            saveLocalStorage('dsc', { 'apg-auth': { ...userState, ...response.data } })
            return response
          })
          .catch(() => {
            throw new Error('token_refresh_failure')
          })
        refreshTokenPool.requestCount = 1
      } else {
        refreshTokenPool.requestCount++
      }
      const response = await refreshTokenPool.promise
      refreshTokenPool.requestCount--
      if (refreshTokenPool.requestCount === 0) {
        refreshTokenPool.promise = null
      }
      const newRequestConfig = {
        ...config,
        retry: true,
        headers: { ...config.headers, Authorization: 'Bearer ' + response.data.token },
      }
      return publicInstance(newRequestConfig)
    } catch (err) {
      console.error('Error fetching new oAuth2 tokens')
      saveLocalStorage('dsc', {})
      setTimeout(() => window.location.reload(), 1000)
      throw new Error('token_refresh_failure')
    }
  }
)

export const instances = { publicInstance, privateInstance }
