import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import XLSX from 'xlsx'

import { ThemeButton, useModalContext } from '../../../../ui'
import { dataToXLS, getFormattedTime } from '../../../../core/utils'
import { APPS_TABLE_COLUMNS, EXTRA_COLUMNS } from '../../constants'
import { useSupervisionContext } from '../../contexts'
import { useAppSupervisionCalls } from '../../hooks/supervision-calls.hook'
import { ExportNotEmptyDialog, ExportSomethingDialog } from '../dialogs'
import { supervisionDataPreparation } from '../../logics'

export const ExportToXLSBtn = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getAllSupervisionApps } = useAppSupervisionCalls()
  const [loading, setLoading] = useState(false)

  const {
    actions: { open },
  } = useModalContext()

  const {
    state: {
      tableSelection: selectedRows,
      search: { _num_x_pagina, _pagina, ...otherSearch },
      pagination: { total_solicitudes },
    },
  } = useSupervisionContext()
  const from = 'supervision'

  const mainExport = (data) => {
    const result = dataToXLS(data, [...APPS_TABLE_COLUMNS, ...EXTRA_COLUMNS], combos, intl, from)
    var worksheet = XLSX.utils.aoa_to_sheet(result)
    var new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
    XLSX.writeFile(new_workbook, `solicitudes_${getFormattedTime()}.xlsx`, {
      bookType: 'xlsx',
      type: 'binary',
    })
    setLoading(false)
  }

  const handleExportAll = async () => {
    setLoading(true)
    if (!total_solicitudes) {
      open({
        Component: ExportNotEmptyDialog,
      })
      setLoading(false)
      return
    } else {
      const data = await getAllSupervisionApps({
        params: {
          ...otherSearch,
          _num_x_pagina: 0,
        },
        timeout: 0,
      })
        .then(({ data }) => supervisionDataPreparation(data.tareas, { intl }, combos))
        .catch(() => setLoading(false))
      mainExport(data)
    }
  }

  const handleExport = async () => {
    if (selectedRows && selectedRows.length) {
      setLoading(true)
      mainExport(selectedRows)
    } else {
      open({
        Component: ExportSomethingDialog,
      })
    }
  }

  return (
    <>
      <ThemeButton
        color="primary"
        variant="text"
        labelM="sm"
        fullWidth={true}
        disabled={!selectedRows.length}
        loading={loading}
        onClick={handleExport}
        style={{ justifyContent: 'start' }}
      >
        {intl.formatMessage({ id: 'global.exportSelected' })} ({selectedRows.length})
      </ThemeButton>
      <ThemeButton
        color="primary"
        variant="text"
        labelM="sm"
        fullWidth={true}
        loading={loading}
        onClick={handleExportAll}
        style={{ justifyContent: 'start' }}
      >
        {intl.formatMessage({ id: 'global.exportAll' })}
      </ThemeButton>
    </>
  )
}
