import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { ValidateCupsDialog } from '../dialogs'
import { useAppContext } from '../../contexts'
import { requests } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'

export const ValidateCupsContratable = () => {
  const { application, getApplication } = useAppContext()
  const { formatMessage } = useIntl()
  const { put } = requests
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenCancelDialog = () => {
    const id = application['id']
    put(`/solicitudes/validar/${id}/rechazar_pasar_contratable`)
      .then(() => {
        getApplication(application['id'])
        feedback('success', 'cambio de estado realizado')
      })
      .catch(() => {
        console.error('Error canceling applicaiton')
      })
  }

  const handleApplicationValidation = () => {
    open({
      Component: ValidateCupsDialog,
      data: { application, getApplication },
    })
  }

  return (
    <Box p="1">
      <Typography paragraph variant="body2" style={{ fontFamily: 'FSEmeric-Medium' }}>
        {formatMessage({ id: 'pages.application.view.detail.validate.cups.title' })}
      </Typography>
      <Box flexWrap="wrap" display="flex" flexDirection="row">
        <ThemeButton
          size="small"
          style={{ marginRight: '1em', marginBottom: '1em' }}
          onClick={handleApplicationValidation}
        >
          {formatMessage({
            id: 'pages.application.view.detail.validate.application.validateButton.label',
          })}
        </ThemeButton>
        <ThemeButton size="small" color="default" onClick={handleOpenCancelDialog}>
          {formatMessage({
            id: 'pages.application.view.detail.validate.cups.cancelButton.label',
          })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
