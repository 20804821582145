import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

const CancelAppConfirmForm = ({ application, cancelApplication, close }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    const payload = { ...formState, list_solicitudes_id: [application['id']] }
    setLoading(true)
    cancelApplication(payload)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={handleConfirm}>
      <Box mb={4}>
        <Box mb={2}>
          <TextInput
            {...{
              title: formatMessage({ id: 'pages.sir.dialogs.cancelApp.fields.1.title' }),
              multiline: true,
              rows: 5,
              inputProps: { maxLength: TEXT_AREA_LENGTH },
              name: 'motivo_anulacion_cliente',
              value: formState['motivo_anulacion_cliente'],
              required: true,
              onChange: handleChange,
            }}
          />
        </Box>

        <TextInput
          {...{
            title: formatMessage({ id: 'pages.sir.dialogs.cancelApp.fields.2.title' }),
            multiline: true,
            rows: 5,
            inputProps: { maxLength: TEXT_AREA_LENGTH },
            name: 'motivo_anulacion_gestor',
            value: formState['motivo_anulacion_gestor'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton type="submit" color="primary" labelM="lg" disabled={loading} loading={loading}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </form>
  )
}

export const CancelAppConfirmDialog = ({ application, cancelApplication, close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={5} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.sir.dialogs.cancelApp.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">
          {formatMessage(
            { id: 'pages.sir.dialogs.cancelApp.message' },
            { ref: application['solicitud_id'] }
          )}
        </Typography>
      </Box>
      <CancelAppConfirmForm {...{ application, cancelApplication, close }} />
    </Box>
  )
}
