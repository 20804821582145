import React from 'react'
import { ReactComponent as ViewDocumentsIcon } from '../../../../resources/images/icon_view_documents.svg'
import { ReactComponent as UploadDocumentsIcon } from '../../../../resources/images/icon_upload_documents.svg'

import { SIR_STATES } from '../../constants'

export const HEADER_BUTTONS_CONFIG = (application) => [
  {
    id: 'viewDocuments',
    label: 'pages.sir.detail.documents.view',
    icon: () => <ViewDocumentsIcon />,
  },
  {
    id: 'uploadDocuments',
    label: 'pages.sir.detail.documents.upload',
    icon: () => <UploadDocumentsIcon />,
    hidden: !CAN_UPLOAD_DOCS_STATES[application.estado],
  },
]

export const CAN_UPLOAD_DOCS_STATES = {
  [SIR_STATES.CREATED.KEY]: true,
  [SIR_STATES.BUDGET_CREATED.KEY]: true,
  [SIR_STATES.BUDGET_SENDED.KEY]: true,
  [SIR_STATES.VALIDATE_PAYMENT.KEY]: true,
}
