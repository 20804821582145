export const STUCK_TABLE_COLUMNS = [
  { field: 'fecha_creacion_solicitud', align: 'left', width: 100, type: 'date' },
  {
    field: 'dias_retraso',
    align: 'center',
    width: 100,
  },
  {
    field: 'solicitud_id',
    align: 'left',
    width: 150,
  },
  {
    field: 'reintentos',
    align: 'center',
    width: 100,
  },
  {
    field: 'estado',
    align: 'center',
    width: 250,
  },

  {
    field: 'descripcion',
    align: 'left',
    width: 400,
    sorting: false,
  },
  { field: 'cidi', align: 'left', width: 150 },
  {
    field: 'camino_solicitud',
    align: 'left',
    width: 130,
  },
  {
    field: 'mercado',
    align: 'left',
    exportType: 'combo',
    comboId: 'tipo_mercado',
    width: 100,
    cellStyle: {
      paddingLeft: '2em',
    },
  },
  {
    field: 'origen',
    align: 'left',
    width: 120,
    cellStyle: {
      paddingLeft: '2em',
    },
  },
  {
    field: 'sociedad_eps',
    align: 'left',
    width: 130,
  },
  {
    field: 'estado_zeus',
    align: 'left',
    width: 130,
  },
  {
    field: 'estado_cups',
    align: 'left',
    width: 130,
  },
  {
    field: 'pez',
    align: 'center',
    width: 120,
    sorting: false,
  },
]

export const STUCK_ALERT_TYPES = [
  { type: 'alerta_normalizada', message: 'Petición normalizada.' },
  { type: 'alerta_potencial', message: 'Potencial no captable por su estado.' },
  { type: 'alerta_sociedad', message: 'Sociedad no definida o problemática (GLP, ...).' },
  { type: 'alerta_iban', message: 'No se ha informado del IBAN ni titular IBAN.' },
]

export const STUCK_TABLE_FILTERS = [
  {
    name: 'creada_desde',
    filterType: 'date',
    maxDateField: 'creada_hasta',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    minDateField: 'creada_desde',
  },
  {
    name: 'origen',
    label: 'Origen',
    values: [
      { key: 'instalador', value: 'Instalador' },
      { key: 'cliente', value: 'Cliente' },
      { key: 'gestorgc', value: 'Gestor GC' },
    ],
    size: 'small',
    multiple: true,
  },
  {
    name: 'mercado',
    comboId: 'tipo_mercado_campañas',
    size: 'small',
    multiple: true,
  },
]
