export const DEFAULT_DATA_SIZE = 10

export const APPS_TABLE_COLUMNS = (comboEstadoSolicitud) => ({
  solicitud_id: {
    field: 'solicitud_id',
    align: 'right',
    width: 180,
  },
  cod_solicitud_zeus: {
    field: 'cod_solicitud_zeus',
    align: 'left',
    width: 180,
  },
  cod_expediente_zeus: {
    field: 'cod_expediente_zeus',
    align: 'left',
    width: 180,
  },
  solicitud_estado: {
    field: 'solicitud_estado',
    align: 'left',
    exportType: 'combo',
    comboId: comboEstadoSolicitud,
    width: 250,
  },
  tipo_mercado: {
    field: 'tipo_mercado',
    align: 'center',
    width: 90,
    sorting: true,
  },
  tipo_mercado_original: {
    field: 'mercado_inicial',
    align: 'center',
    width: 90,
    sorting: true,
  },
  tipo_vivienda: {
    field: 'tipo_vivienda',
    align: 'left',
    width: 110,
  },
  cups: {
    field: 'cups',
    align: 'left',
    width: 190,
  },
  municipio: {
    field: 'municipio',
    align: 'left',
    width: 150,
  },
  direccion: {
    field: 'direccion',
    align: 'left',
    width: 250,
    // cellStyle: {
    //   textTransform: 'capitalize',
    // },
  },
  zona: {
    field: 'zona',
    align: 'left',
    width: 100,
  },
  provincia: {
    field: 'provincia',
    align: 'left',
    width: 100,
  },
  usuario_identificador: {
    field: 'usuario_identificador',
    align: 'left',
    width: 150,
  },
  gestor: {
    field: 'gestor',
    align: 'left',
    width: 100,
  },
  fecha_solicitud: {
    field: 'fecha_creacion',
    align: 'left',
    type: 'date',
    exportType: 'date',
    width: 130,
    sorting: true,
  },
  presupuestos_fecha_solicitud: {
    field: 'presupuestos_fecha_solicitud',
    align: 'left',
    type: 'date',
    exportType: 'date',
    width: 160,
    sorting: true,
  },
  presupuestos_fecha_realizacion: {
    field: 'presupuestos_fecha_realizacion',
    align: 'left',
    type: 'date',
    exportType: 'date',
    width: 140,
    sorting: true,
  },
  fecha_solicitud_zeus: {
    field: 'fecha_solicitud_zeus',
    align: 'left',
    type: 'date',
    exportType: 'date',
    width: 190,
    sorting: true,
  },
  tipo_gas: {
    field: 'tipo_gas',
    align: 'left',
    width: 120,
    sorting: true,
  },
  tipo_solicitud: {
    field: 'tipo_solicitud',
    align: 'left',
    width: 100,
  },
  data_alta: {
    field: 'fecha_creacion',
    align: 'left',
    type: 'date',
    exportType: 'date',
    width: 130,
  },
  instalador: {
    field: 'instalador',
    align: 'left',
    exportType: 'combo',
    comboId: 'empresas',
    width: 130,
  },
  asociacion: {
    field: 'gremio',
    align: 'left',
    exportType: 'combo',
    comboId: 'gremios',
    width: 150,
  },
  nif_instalador: {
    field: 'empresa_nif',
    align: 'left',
    width: 150,
  },
  origen: {
    field: 'tipo_solicitud',
    align: 'left',
    exportType: 'combo',
    comboId: 'origen_solicitud',
    width: 130,
  },
  actions: {
    field: 'actions',
    width: 80,
    align: 'center',
    sorting: false,
  },
  comms: {
    field: 'comunicaciones_pendientes',
    width: 60,
    sorting: true,
  },
  rating: {
    field: 'valoraciones_pendientes',
    width: 60,
    sorting: true,
  },
  alerts: {
    field: 'alerts',
    align: 'left',
    width: 250,
    sorting: false,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  codigo_goda: {
    field: 'codigo_goda',
    align: 'left',
    width: 150,
  },
  pendiente_peticionario: {
    field: 'pendiente_peticionario',
    width: 40,
    sorting: true,
  },
  empresa_instaladora: {
    field: 'empresa_instaladora',
    align: 'left',
    width: 100,
  },
  empresa_instaladora_telefono: {
    field: 'empresa_instaladora_telefono',
    align: 'right',
    width: 100,
  },
})

export const BASIC_COLUMN_LIST = [
  'solicitud_id',
  'cod_solicitud_zeus',
  'cod_expediente_zeus',
  'solicitud_estado',
  'tipo_mercado',
  'tipo_vivienda',
  'cups',
  'municipio',
  'direccion',
  'zona',
  'usuario_identificador',
  'gestor',
  'presupuestos_fecha_solicitud',
  'presupuestos_fecha_realizacion',
  'fecha_solicitud_zeus',
  'tipo_gas',
  'codigo_goda',
]

export const E_COMMERCE_COLUMN_LIST = [
  'solicitud_id',
  'cod_solicitud_zeus',
  'alerts',
  'solicitud_estado',
  'origen',
  'instalador',
  'nif_instalador',
  'asociacion',
  'tipo_mercado_original',
  'tipo_mercado',
  'tipo_vivienda',
  'cups',
  'provincia',
  'municipio',
  'direccion',
  'data_alta',
  'cod_expediente_zeus',
]

export const SSPP_COLUMN_LIST = [
  'solicitud_id',
]
// const getFieldsForExcel = () => {
//   const columns = [...APPS_TABLE_COLUMNS]
//   const columnsToExport = columns.filter(
//     (column) =>
//       column.field != 'usuario_identificador' && column.field != 'presupuestos_fecha_realizacion'
//   )
//   return columnsToExport
// }

// const getSelectraColumns = () => {
//   const selectraColumns = [...APPS_TABLE_COLUMNS]
//   const tipoSolicitudColumn = {
//     field: 'tipo_solicitud',
//     align: 'left',
//     width: 100,
//   }
//   const tipoSolicitudColumnPosition = 4
//   // Adding specific columns for selectra
//   selectraColumns.splice(tipoSolicitudColumnPosition, 0, tipoSolicitudColumn)
//   return selectraColumns
// }

// export const APPS_TABLE_SELECTRA_COLUMNS = getSelectraColumns()
// export const APPS_TABLE_EXPORT = getFieldsForExcel()

// if alert fields are modified probably you should add a new entry in i18n constants
export const APPS_ALERT_FIELDS = [
  'alerta_contacto_instalador',
  'alerta_aceptar_presupuesto_instalador',
  'alerta_subida_certificado',
]
export const WARNING_COLOR = '#fff4e5'
export const REGULAR_COLOR = '#ffffff'
