import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Switch, withStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import EditIcon from '@material-ui/icons/Edit'

import { tableActionsStyles } from './table-actions.styles'
import { MarketerEdit } from '../dialog'
import { useMarketersCalls } from '../../hooks'
import { useModalContext } from '../../../../ui'
import { toggleMarketerActive } from '../../slices'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const history = useHistory()
  const {
    actions: { open },
  } = useModalContext()
  const { getMarketer } = useMarketersCalls()
  const marketerId = props.rowData['id']
  const [marketer, setMarketer] = useState({})

  const handleGoToDetail = () => history.push(`/marketers/${marketerId}`)

  const handleEditMarketer = () =>
    open({
      Component: MarketerEdit,
      data: { marketerId, marketer, setMarketer, getMarketer, props },
      type: 'fullScreen',
    })

  const tariffButtons = {
    edit: {
      onClick: handleEditMarketer,
      icon: <EditIcon />,
    },
    detail: {
      onClick: handleGoToDetail,
      icon: <SearchIcon />,
    },
  }

  const availableActions = ['detail', 'edit']

  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={tariffButtons[action].onClick}
          >
            {tariffButtons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActive = withStyles(switchStyles)(({ classes, ...props }) => {
  const dispatch = useDispatch()
  const global = useSelector((state) => state.global)
  const marketers = useSelector((state) => state.marketers.data)
  const switchDisabled = '' + props.rowData.id === global.user_id
  const marketerActiveState = props.rowData['activo']

  const handleToggleState = () => dispatch(toggleMarketerActive(props.rowData, marketers))
  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={marketerActiveState}
      onClick={handleToggleState}
      disabled={switchDisabled}
    />
  )
})
