import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'

import { TableUrlPagination } from '../../../../ui'
import { SIR_TABLE_COLUMNS, ESTATE_CLOSED_VALUE, ESTATE_CLOSED_COLOR } from './sir-table.constants'
import { DEFAULT_QUERY } from '../../reducers'
import { sirTableStyles } from './sir-table.styles'
import { MTableCell } from 'material-table'
import { useSirContext } from '../../contexts'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const SirTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useSelector((state) => state.combos)
  const {
    data,
    pagination: { total_solicitudes: totalEntries },
    loading,
    search,
    tableSelection,
    onClickTableRow,
    setSelectedTableRows,
    getTableData,
  } = useSirContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    } else {
      history.push(`/sir?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      SIR_TABLE_COLUMNS.map((column) => {
        if (column.field === 'comunicaciones_pendientes')
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <NotificationsNoneIcon style={{ fontSize: 18 }} />
              </span>
            ),
          }
        return {
          ...column,
          title: formatMessage({ id: `pages.sir.table.columns.${column.field}.title` }),
          cellStyle: {
            ...sirTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((selectedRecord) => selectedRecord.id)
    return data.map((row) => {
      if (selectedIds.indexOf(row.id) > -1) {
        row.tableData = { ...row.tableData, checked: true }
      }
      return row
    })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      headerStyle: sirTableStyles.header,
      rowStyle: (rowData) => ({
        backgroundColor:
          rowData['estado_finca'] === ESTATE_CLOSED_VALUE ? ESTATE_CLOSED_COLOR : '#ffffff',
      }),
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFormattedCell = (props) => {
    if (
      props.columnDef.field === 'comunicaciones_pendientes' &&
      props.rowData.comunicaciones_pendientes > 0
    ) {
      return (
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <NotificationsActiveIcon color="secondary" style={{ fontSize: 18 }} />
        </span>
      )
    }
  }

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const mTableCellProps =
          props.columnDef.field === 'comunicaciones_pendientes' ? { ...props, value: null } : props
        return (
          <MTableCell {...mTableCellProps} style={{ padding: 0 }}>
            {getFormattedCell(props)}
          </MTableCell>
        )
      },
    }),
    []
  )
  const handleRowClick = useCallback(
    (_, row) => {
      onClickTableRow(row['id'])
    },
    [onClickTableRow]
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
