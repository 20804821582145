export const PO_REQ_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_FAILURE: 'SET_FAILURE',
  SET_IP: 'SET_IP',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
  SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
  SET_DETAIL_FAILURE: 'SET_DETAIL_FAILURE',
  SET_IP_STATE: 'SET_IP_STATE',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_IP_EVENTS: 'SET_IP_EVENTS',
}

export const PO_REQ_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: {},
  loading: false,
  detailLoading: false,
  detailError: false,
  msLoaded: false,
  error: false,
  poRequest: {},
  tableSelection: [],
  email_f1: "",
}
