import { JobsContext } from "../../features/jobs";
import { JobsRouter } from "./routers";
import React from "react";

export const JobsPage = (props) => {
  return (
    <JobsContext>
      <JobsRouter {...props} />
    </JobsContext>
  )
}
