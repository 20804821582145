import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { FormFoldingPanel, FoldingPanel, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos/hooks'
import { SIR_FORM_CONFIG, SIR_FORM_STRUCTURE, HIDDEN_EDIT_BTNS_STATES } from './sir-form.constants'
import { FILE_FIELD_NAMES, FILE_FIELD_IDS, NOT_CANCELLABLE_STATES } from './sir-form.constants'
import { useSirContext } from '../../contexts'
import { AppEventsTable } from '../events-table'
import { SIR_STATES } from '../../constants'
import { CancelAppBtn, RollbackAppBtn } from '../form-action-btns'
import ApplicationCommenter from '../commenter/application-commenter.component'
import { SirStateChangerBtn } from '../form-action-btns/sir-state-changer-btn-component'
import { isValidEmail, isValidNIF, isValidNIE, isValidCIF } from '../../../../core/utils'
import { isValidPhone } from '../../../../core/utils/phoneUtils'

export const SirForm = () => {
  const { formatMessage } = useIntl()
  const intl = useIntl()
  const combos = useCombos([])
  const {
    application,
    updateApplication,
    editMode,
    setEditMode,
    generateBudget,
    editCompanyMode,
    editUserMode,
    updateUserSir,
    setGenerateBudgetLoading,
  } = useSirContext()
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [formState, setFormState] = useState({
    ...application,
    presupuesto_sir: {
      ...application.presupuesto_sir,
      aplica_apertura_cierre: application.presupuesto_sir.aplica_apertura_cierre ? '01' : '00',
      rango_presion: application.rango_presion,
      sstt_id: application.sstt.id.toString(),
    },
  })

  //Eliminamos el modo edición al desmontar el componente
  useEffect(() => {
    return () => {
      setEditMode(false)
    }
  }, [])

  useEffect(() => {
    setFormState({
      ...application,
      presupuesto_sir: {
        ...application.presupuesto_sir,
        aplica_apertura_cierre: application.presupuesto_sir.aplica_apertura_cierre ? '01' : '00',
        rango_presion: application.rango_presion,
        sstt_id: application.sstt.id.toString(),
      },
    })
  }, [application]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      formState.presupuesto_sir.anulacion_acometida_existente &&
      !formState.presupuesto_sir.construccion_nueva_acometida &&
      !formState.presupuesto_sir.conexion_tallo_o_sustitucion_valvula &&
      !formState.presupuesto_sir.reapertura_acometida_cierre_programado
    ) {
      setFormState((formState) => ({
        ...formState,
        presupuesto_sir: {
          ...formState.presupuesto_sir,
          aplica_apertura_cierre: '00',
        },
      }))
    } else if (
      formState.presupuesto_sir.construccion_nueva_acometida ||
      formState.presupuesto_sir.conexion_tallo_o_sustitucion_valvula ||
      formState.presupuesto_sir.reapertura_acometida_cierre_programado
    ) {
      setFormState((formState) => ({
        ...formState,
        presupuesto_sir: {
          ...formState.presupuesto_sir,
          aplica_apertura_cierre: '01',
        },
      }))
    }
  }, [
    formState.presupuesto_sir.anulacion_acometida_existente,
    formState.presupuesto_sir.construccion_nueva_acometida,
    formState.presupuesto_sir.conexion_tallo_o_sustitucion_valvula,
    formState.presupuesto_sir.reapertura_acometida_cierre_programado,
  ])

  function validateCompanyData(usuario_sir) {
    const txt = (extension) =>
      formatMessage({ id: 'pages.sir.detail.fields.' + extension + '.error' })
    if (usuario_sir.nombre_empresa === '') {
      setErrorMssg(txt('empresa_nombre'))
      return false
    }
    if (
      !isValidNIF(usuario_sir.nif_empresa) &&
      !isValidNIE(usuario_sir.nif_empresa) &&
      !isValidCIF(usuario_sir.nif_empresa)
    ) {
      setErrorMssg(txt('empresa_nif'))
      return false
    }
    if (usuario_sir.nombre_instalador === '') {
      setErrorMssg(txt('empresa_nombre_instalador'))
      return false
    }
    if (!isValidPhone(usuario_sir.telefono_empresa)) {
      setErrorMssg(txt('empresa_telefono'))
      return false
    }
    if (!isValidEmail(usuario_sir.email_empresa)) {
      setErrorMssg(txt('empresa_email'))
      return false
    }

    if (usuario_sir.num_registro_empresa === '') {
      setErrorMssg(txt('empresa_num_registro'))
      return false
    }
    if (!['A', 'B', 'C'].includes(usuario_sir.categoria_empresa)) {
      setErrorMssg(txt('empresa_categoria'))
      return false
    }
    return true
  }

  function validateUserData(usuario_sir) {
    const txt = (extension) =>
      formatMessage({ id: 'pages.sir.detail.fields.' + extension + '.error' })
    if (usuario_sir.nombre_cliente === '') {
      setErrorMssg(txt('cliente_nombre'))
      return false
    }
    if (usuario_sir.apellidos_cliente === '') {
      setErrorMssg(txt('cliente_apellidos'))
      return false
    }
    if (
      !isValidNIF(usuario_sir.identificador_cliente) &&
      !isValidNIE(usuario_sir.identificador_cliente) &&
      !isValidCIF(usuario_sir.identificador_cliente)
    ) {
      setErrorMssg(txt('identificador_cliente'))
      return false
    }
    if (!isValidPhone(usuario_sir.telefono_cliente)) {
      setErrorMssg(txt('telefono_cliente'))
      return false
    }
    if (!isValidEmail(usuario_sir.email_cliente)) {
      setErrorMssg(txt('email_cliente'))
      return false
    }

    return true
  }

  const handleCancelForm = () => {
    setFormState({
      ...application,
      presupuesto_sir: {
        ...application.presupuesto_sir,
        aplica_apertura_cierre: application.presupuesto_sir.aplica_apertura_cierre ? '01' : '00',
        rango_presion: application.rango_presion,
        sstt_id: application.sstt.id.toString(),
      },
    })
    setErrorMssg('')
    setEditMode(false)
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (editMode) {
      setErrorMssg('')
      const isSameWorkDayAndHour =
        formState.presupuesto_sir.aplica_apertura_cierre === '01' &&
        formState.presupuesto_sir.dia_cierre === formState.presupuesto_sir.dia_apertura &&
        formState.presupuesto_sir.hora_cierre &&
        formState.presupuesto_sir.hora_apertura &&
        combos.horarios_apertura.data.findIndex(
          ({ key }) => key === formState.presupuesto_sir.hora_cierre
        ) >=
          combos.horarios_apertura.data.findIndex(
            ({ key }) => key === formState.presupuesto_sir.hora_apertura
          )
      if (isSameWorkDayAndHour) {
        return setErrorMssg(
          'El horario de cierre no puede ser igual o posterior al horario de apertura.'
        )
      }

      if (!validateCompanyData(formState.usuario_sir) || !validateUserData(formState.usuario_sir)) {
        return false
      }
      const budgetId = formState.presupuesto_sir.id
      let payload = {}
      let newPressure = null
      for (let key in formState.presupuesto_sir) {
        if (
          !FILE_FIELD_NAMES.some((fileName) => fileName === key) &&
          !FILE_FIELD_IDS.some((fileName) => fileName === key) &&
          (formState.presupuesto_sir[key] || formState.presupuesto_sir[key] === false)
        ) {
          if (key === 'num_viviendas') {
            payload[key] = parseInt(formState.presupuesto_sir[key])
          } else if (key === 'precio' || key === 'precio_con_iva' || key === 'precio_irc') {
            if (formState.presupuesto_sir[key] !== application.presupuesto_sir[key])
              payload[key] = formState.presupuesto_sir[key].toString()
          } else if (key === 'aplica_apertura_cierre') {
            payload[key] = formState.presupuesto_sir[key] === '01'
          } else if (key === 'sstt_id') {
            payload[key] = parseInt(formState.presupuesto_sir[key])
          } else payload[key] = formState.presupuesto_sir[key]
        }
        delete formState['rango_presion']
      }

      if (application.rango_presion !== formState.presupuesto_sir.rango_presion) {
        newPressure = formState.presupuesto_sir.rango_presion
      }

      if(formState.presupuesto_sir.modificacion_irc_propiedad_nedgia === false) {
        payload['precio_irc'] = "0"
      }
      setLoading(true)
      updateApplication(budgetId, application.id, payload, newPressure)
        .then(() => {
          if (application['estado'] === SIR_STATES.BUDGET_CREATED.KEY) {
            setGenerateBudgetLoading(true)
            generateBudget(application.id)
              .then(() => {
                setErrorMssg('')
              })
              .catch(() => {
                setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
                setLoading(false)
              })
          }
        })
        .catch(() => {
          setGenerateBudgetLoading(false)
          setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
          setLoading(false)
        })

      let payloadUser = {}
      for (let key in formState.usuario_sir) {
        if (formState.usuario_sir[key] || formState.usuario_sir[key] === false) {
          payloadUser[key] = formState.usuario_sir[key]
        }
      }
      updateUserSir(formState.usuario_sir.id, payloadUser)
        .then(() => {
          setErrorMssg('')
          setLoading(false)
          setEditMode(false)
        })
        .catch(() => {
          setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
          setLoading(false)
          setEditMode(true)
        })
    } else {
      setLoading(false)
      setEditMode(true)
    }
  }

  const handleChangeForm = useCallback(
    (e) => {
      const { name, value } = e.target
      const keys = name.split('.')
      if (keys[1] === undefined) {
        setFormState((formState) => ({
          ...formState,
          [name]: value,
        }))
      } else {
        setFormState((formState) => ({
          ...formState,
          [keys[0]]: { ...formState[keys[0]], [keys[1]]: value },
        }))
      }
    },
    [setFormState]
  )

  const fieldList = useMemo(() => {
    const list = SIR_FORM_CONFIG({
      intl,
      combos,
      editMode,
      formState,
      editUserMode,
      editCompanyMode,
    })

    for (let key in list) {
      const listItem = list[key]
      listItem.onChange = handleChangeForm
    }
    return list
  }, [editMode, formState, editCompanyMode, editUserMode]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmitForm}>
      <Box display="flex" mt={2} justifyContent="space-between">
        <Box display="flex" justifyContent="flex-start">
          {!NOT_CANCELLABLE_STATES[application['estado']] ? (
            <Box mr={2}>
              <CancelAppBtn />
            </Box>
          ) : null}
          {SIR_STATES.REVIEW.KEY === application['estado'] ? <RollbackAppBtn /> : null}
        </Box>
        {!HIDDEN_EDIT_BTNS_STATES[application['estado']] && formState.estado !== '90' ? (
          <Box display="flex" justifyContent="flex-end">
            {editMode ? (
              <Box mr={1}>
                <ThemeButton color="default" onClick={handleCancelForm} disabled={loading}>
                  {intl.formatMessage({ id: 'global.cancel' })}
                </ThemeButton>
              </Box>
            ) : null}
            <ThemeButton type="button" onClick={handleSubmitForm} color="primary" loading={loading}>
              {intl.formatMessage({
                id: `pages.sir.detail.button.${editMode ? 'save' : 'edit'}.label`,
              })}
            </ThemeButton>
          </Box>
        ) : null}
        {formState.estado === '00' || formState.estado === '03' || formState.estado === '90' ? (
          <Box ml={1}>
            <SirStateChangerBtn disabled={true} />
          </Box>
        ) : (
          <Box ml={1}>
            <SirStateChangerBtn />
          </Box>
        )}
      </Box>
      {errorMssg ? (
        <Box mt={2}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      ) : null}

      {SIR_FORM_STRUCTURE({ intl, formState, setFormState }).map((block) => {
        return (
          <div key={block.title}>
            <FormFoldingPanel {...{ data: formState, combos, intl, block, fieldList }} />
          </div>
        )
      })}
      <div>
        <FoldingPanel title={intl.formatMessage({ id: 'pages.sir.detail.history.title' })}>
          <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
            <ApplicationCommenter model={application} />
            <AppEventsTable model={application} />
          </Box>
        </FoldingPanel>
      </div>
    </form>
  )
}
