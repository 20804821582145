import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../../ui'
import { CancelAppDialog } from '../../dialogs'
import { useAppContext } from '../../../contexts'

export const CancelAppBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, getApplication } = useAppContext()

  const handleOpenCancelDialog = () => {
    open({
      Component: CancelAppDialog,
      data: { application, getApplication },
    })
  }

  return (
    <ThemeButton color="default" size="small" onClick={handleOpenCancelDialog}>
      {formatMessage({
        id: 'pages.application.view.detail.rollback.cancelButton.label',
      })}
    </ThemeButton>
  )
}
