import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { useSelector } from 'react-redux'

import { useConsultContext } from '../../contexts'
import { TextInput, SelectInput, ThemeButton, NumberInput, AutocompleteInput } from '../../../../ui'
import { CUSTOMER_FIELDS_CONFIG } from '../../constants/costumer-form.constants'
import feedback from '../../../../core/feedback'

export const GCform2 = () => {
  const intl = useIntl()
  const {
    consultData,
    customerForm,
    selectedHome,
    changeCustomerForm,
    nextStep,
    reset,
    prevStep,
    installerValues,
    getInstallerValues,
  } = useConsultContext()
  const [services, setServices] = useState([{ id: 1 }])
  const optionsValues = [
    { key: 'I', value: 'I' },
    { key: 'P', value: 'P' },
  ]

  const combos = useSelector((state) => state.combos)

  useEffect(() => {
    const isReset = Object.keys(consultData)
    const isResetOrNortmalized = Object.keys(selectedHome)
    if (isReset.length === 0 && isResetOrNortmalized.length === 0) reset()
    else
      changeCustomerForm({
        name: 'distancia_real',
        value: consultData.distancia_aproximada_de_la_red,
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddService = () => {
    setServices([...services, { id: services.length + 1 }])
  }

  const handleDeleteService = () => {
    if (services.length === 1) {
      feedback('warning', 'Es necesario al menos un equipo')
    } else {
      const arrayHelper = [...services]
      arrayHelper.length = arrayHelper.length - 1
      setServices(arrayHelper)
    }
  }

  const handleOnChangeForm = (e) => {
    if (e.name) {
      let { name, value } = e
      changeCustomerForm({ name: 'empresa_ca_id', value: value && value.empresa_ca_id })
      changeCustomerForm({ name, value: value && { ...value } })
    } else {
      let { name, value, checked } = e.target

      //Special case for services field in certificate form
      if (name === 'services') {
        if (value && value.includes('calefaccion')) {
          if (!value.includes('agua_caliente')) {
            value = [...value, 'agua_caliente']
          }
        }
      }
      if (name === 'sin_ubicacion') {
        value = checked
        changeCustomerForm({ name, value })
      } else {
        changeCustomerForm({ name, value })
      }
    }
  }

  const handleOnChange = (id, value, name) => {
    const service = [...services]
    service.forEach((pr) => {
      if (pr.id === id) {
        service[id - 1][name] = value
      }
    })
    setServices(service)
  }

  const handleSubmit = () => {
    changeCustomerForm({ name: 'equipo_gran_consumo', value: services })
    nextStep({ replace: true })
  }

  const handleGoBack = () => {
    prevStep({ replace: true })
  }

  const inputProps = {
    formState: customerForm,
    onChange: handleOnChangeForm,
    intl,
    combos,
    consultData,
  }

  return (
    <>
      <Box>
        <Box mb={6}>
          <Typography variant="h4" color="primary" paragraph>
            {intl.formatMessage({ id: 'pages.consult.title.2.datos_instalacion' })}
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box
            bgcolor="grey.300"
            p={3}
            style={{
              borderRadius: 6,
              elevation: 3,
              backgroundColor: '#EEEDED',
              shadowOffset: { width: 1, height: 1 },
              shadowColor: '#333',
              shadowOpacity: 0.3,
              shadowRadius: 2,
              marginHorizontal: 4,
              marginVertical: 6,
              boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Grid container spacing={3}>
              <Box display="flex" justifyContent="space-around" width="100%">
                <Grid item xs={12} sm={4}>
                  <Typography>Equipo</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography>Cantidad</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography>Potencia</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Typography>Instalado o Previsto</Typography>
                </Grid>
              </Box>
              {services.map((service, key) => {
                return (
                  <Box key={key} display="flex" justifyContent="space-around" width="100%" mt={1}>
                    <Grid item xs={12} sm={4}>
                      <TextInput
                        value={service['tipo']}
                        name="tipo"
                        onChange={({ target: { value, name } }) =>
                          handleOnChange(service.id, value, name)
                        }
                        required={true}
                      ></TextInput>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <NumberInput
                        value={service['cantidad']}
                        name="cantidad"
                        onChange={({ target: { value, name } }) =>
                          handleOnChange(service.id, value, name)
                        }
                        required={true}
                      ></NumberInput>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <NumberInput
                        value={service['potencia']}
                        name="potencia"
                        autoComplete="off"
                        onChange={({ target: { value, name } }) =>
                          handleOnChange(service.id, value, name)
                        }
                        required={true}
                      ></NumberInput>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <SelectInput
                        name="instalado_previsto"
                        value={service['instalado_previsto']}
                        values={optionsValues}
                        onChange={({ target: { value, name } }) =>
                          handleOnChange(service.id, value, name)
                        }
                        required={true}
                      ></SelectInput>
                    </Grid>
                  </Box>
                )
              })}
            </Grid>
            <Box display="flex" mt={2} justifyContent="flex-end" mr={6}>
              <Box mr={2}>
                <RemoveCircleOutlineIcon onClick={handleDeleteService} />
              </Box>
              <Box style={{ cursor: 'pointer' }}>
                <AddCircleOutlineIcon onClick={handleAddService} />
              </Box>
            </Box>
          </Box>
          <Box mt={7}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <NumberInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).METROS_NECESARIOS }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).RANGO_PRESION }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).PRESION_CONTAJE }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <NumberInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).HORAS_USO }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).UBICACION }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AutocompleteInput
                  {...{
                    ...CUSTOMER_FIELDS_CONFIG(inputProps).INSTALADORES,
                    values: installerValues,
                    onType: getInstallerValues,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).OBSERVACIONES }} />
              </Grid>
            </Grid>
            <Box mt={2} display="flex" justifyContent="space-around">
              <ThemeButton onClick={handleGoBack} color="primary" labelM="lg">
                {intl.formatMessage({ id: 'global.return' })}
              </ThemeButton>
              <ThemeButton type="submit" color="primary" labelM="lg">
                {intl.formatMessage({ id: 'global.continue' })}
              </ThemeButton>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}
