import React, { useEffect, useState } from "react";
import {
  Badge,
  Button, Card,
  CardActions, CardContent,
  IconButton,
  Popover,
  Typography
} from "@material-ui/core";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useNotificationsCalls } from "../../hooks";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { PopupDialogComponent } from "../../dialogs";
import { useIntl } from "react-intl";
import {NoticesPopupComponent} from "../notices-popup";

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      ellipsis: true,
      textAlign: 'justify',
    }
  },
  card: {
    width: '100%',
    marginBottom:10,
    '& .active': {
      border: '2px solid #f6bf00',
    }
  },
  bodyText: {
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}));
export const NoticesHistoryComponent = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {getAvisosByUser,markAsRead} = useNotificationsCalls()
  const [notifications, setNotifications] = useState(null)
  const [nottificationsNotRead, setNottificationsNotRead] = useState(0)

  const [selectedAviso, setSelectedAviso] = useState({})
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    getAvisosByUser({params: {full:true}}).then((response) => {
      setNotifications(response.data)
      setNottificationsNotRead(getNotReadNotifications(response.data))
    })
  },[])

  useEffect(() => {
    setNottificationsNotRead(getNotReadNotifications(notifications))
  },[notifications])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

  };

  function closeDialog() {
    setOpenDialog(false);
    setSelectedAviso({})
  }

  function readAviso() {
    markAsRead(selectedAviso.id).then(
      () => {
        let newAvisos = [...notifications]
        for(let i=0; i<newAvisos.length; i++){
            if(newAvisos[i].id === selectedAviso.id){
                newAvisos[i].ignore = true
            }
        }
        setNotifications(newAvisos)
        closeDialog()
      }
    )
  }

  const showDetail = (index) => () => {
    setAnchorEl(null)
    let aviso = {"id":notifications[index].id,"title": notifications[index].title, "text": notifications[index].body, "links": notifications[index].links, "ignore":notifications[index].ignore}
    setSelectedAviso(aviso)
    setOpenDialog(true)

  }

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateObj = new Date(date)
    return dateObj.toLocaleDateString('es-ES', options)
  }

  const getNotReadNotifications = (not) => {
    if (!not) return 0
    const notRead = not.filter((notification) => !notification.ignore).length
    return notRead
  }

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      {nottificationsNotRead > 0 ? (
      <IconButton aria-describedby={id} onClick={handleClick} color="primary" aria-label="Notificaciones">
        <Badge badgeContent={nottificationsNotRead} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    ) : (

      <IconButton aria-describedby={id} onClick={handleClick} color="primary" aria-label="Notificaciones">
        {notifications && notifications.length > 0 ? (<NotificationsIcon />) : (<NotificationsNoneIcon />)}
      </IconButton>
    )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{width: '20rem', padding:5, height:'80%'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', padding: 20,paddingBottom:0, marginBottom:20}}>
          <Typography variant="h6" gutterBottom color="primary">
            <i>{formatMessage({id:'pages.notices.history.title'})}</i>
          </Typography>
          </div>
          {notifications && notifications.map((notification,index) => (
            <Card className={classes.card + ' active'} key={index} variant="outlined" style={{background:'#f3f3f3', position:'relative'}}>
              {!notification.ignore ? ( <Badge color="secondary" badgeContent=" " style={{width:'100%'}}>
              <CardContent style={{paddingBottom: 0}}>
                <Typography variant="h6" component="div">
                  {notification.title}
                </Typography>
                <Typography variant="body2" component="div" className={classes.bodyText} dangerouslySetInnerHTML={{ __html: notification.body }}></Typography>
              </CardContent>
              </Badge> ) : (
                <CardContent style={{paddingBottom: 0}}>
              <Typography variant="h6" component="div">
                {notification.title}
              </Typography>
              <Typography variant="body2" component="div" className={classes.bodyText} dangerouslySetInnerHTML={{ __html: notification.body }}></Typography>
            </CardContent>
              )}
              <CardActions>
                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                <Button size="small" color="primary" onClick={showDetail(index)}>Ver detalles</Button>
                  <Typography variant="caption" component="div">{formatDate(notification.start_date)}</Typography>
                </div>
              </CardActions>
            </Card>

          ))}
          {notifications && notifications.length === 0 && (
            <Typography variant="body" component="div" style={{padding:"0 20px 20px 20px"}}>
              <i>{formatMessage({id:'pages.notices.history.empty'})}</i>
            </Typography>)
          }
        </div>
      </Popover>
      {selectedAviso.title && (
        <PopupDialogComponent open={openDialog} onClose={closeDialog} onRead={readAviso} {...selectedAviso}/>
      )}
      <NoticesPopupComponent avisos={notifications} setAvisos={setNotifications}/>
    </div>
  )
}