import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, Grid, Checkbox, Tooltip } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { TextInput, ThemeButton, SelectInput } from '../../../../ui'
import { EDIT_FORM_CONFIG } from './new-manager.constants'
import { useManagersCalls } from '../../hooks/managers-calls.hook'
import { addManager } from '../../slices'

export const NewManager = ({ close }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const managers = useSelector((state) => state.managers.data)
  const dispatch = useDispatch()
  const { getComboByRol } = useManagersCalls()
  const [formState, setFormState] = useState({})
  const [fethecdPerms, setFetchedPerms] = useState([])
  const [perms, setPerms] = useState([])
  const [havePerms, setHavePerms] = useState(true)

  const handleChangeForm = (e) => {
    if (e.target.name === 'rol') setPerms([])
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleNewUser = (e) => {
    e.preventDefault()
    dispatch(addManager({ ...formState, extra_permission: perms }, managers))
    close()
  }

  const handleTogglePermission = (e) => {
    const supportArray = perms
    e.persist()
    const index = supportArray.indexOf(e.target.name)
    if (index !== -1) {
      supportArray.splice(index, 1)
    } else {
      supportArray.push(e.target.name)
    }
    setPerms([...supportArray])
  }

  useEffect(() => {
    if (formState.rol) {
      getComboByRol(formState.rol).then((response) => {
        if (response.data.length === 0) {
          setHavePerms(false)
          setFetchedPerms(response.data)
        } else {
          setHavePerms(true)
          setFetchedPerms(response.data)
        }
      })
    }
  }, [formState.rol]) // eslint-disable-line react-hooks/exhaustive-deps

  const inputProps = { formState, combos, intl, onChange: handleChangeForm }
  return (
    <Box pl={4} pr={4} pb={4}>
      <Box mb={3}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({ id: 'pages.managers.dialogs.new.title' })}
        </Typography>
      </Box>
      <form onSubmit={handleNewUser}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).NOMBRE} />
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).APELLIDOS} />
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <SelectInput {...EDIT_FORM_CONFIG(inputProps).ROL} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).TELEFONO} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).USERNAME} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).CORREO_ELECTRONICO} />
          </Grid>
        </Grid>
        <Box mt={2} mb={2}>
          <Typography variant="h6" color="primary">
            Permisos
          </Typography>
        </Box>
        <Grid container spacing={1}>
          {fethecdPerms.length > 0 ? (
            fethecdPerms?.map((perm) => {
              return (
                <Grid item xs={2} sm={4}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={perms.some((pm) => pm === perm.key)}
                      name={perm.key}
                      onChange={(e) => handleTogglePermission(e)}
                    ></Checkbox>
                    <Tooltip
                      placement="top"
                      arrow
                      title={<Typography variant="body2">{perm.description}</Typography>}
                    >
                      <Typography>{perm.value}</Typography>
                    </Tooltip>
                  </Box>
                </Grid>
              )
            })
          ) : havePerms ? (
            <Box mt={2} ml={1}>
              <Typography variant="body3">Escoge un rol para poder editar los permisos</Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body3">Este rol no tiene permisos extra</Typography>
            </Box>
          )}
        </Grid>
        <Box mt={8} display="flex" justifyContent="space-between">
          <ThemeButton onClick={close} variant="outlined" labelM="lg">
            {intl.formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
          <ThemeButton color="primary" type="submit" labelM="lg">
            {intl.formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
