import React, { useCallback, useEffect, useRef } from 'react'
import { useConsultContext } from '../../contexts'
import { Box, Checkbox, Grid, Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { SelectInput, TextInput, ThemeButton, RadioInput } from '../../../../ui'
import { CUSTOMER_FIELDS_CONFIG } from '../../constants/costumer-form.constants'
import { useCombos } from '../../../combos'
import { isValidDocument } from '../../../../core/utils'
import { getUserConsistency } from '../../calls'
import { toast } from 'react-toastify'

export const GcForm = () => {
  const {
    consultData,
    customerForm,
    changeCustomerForm,
    changeCustomerFormArray,
    selectedHome,
    nextStep,
    prevStep,
    reset,
  } = useConsultContext()
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  useCombos(['rango_presion_gc'], ['rango_presion_gc'])
  useCombos(['presion_contaje'], ['presion_contaje'])
  useCombos(['contador'], ['contador'])
  useCombos(['cod_zona_zeus'], ['cond_zona_zeus'])
  const idDocError = ''
  const form = useRef()
  const URL_CATASTRO = 'https://www1.sedecatastro.gob.es/CYCBienInmueble/OVCBusqueda.aspx'

  useEffect(() => {
    const isReset = Object.keys(consultData)
    const isResetOrNortmalized = Object.keys(selectedHome)
    if (isReset.length === 0 && isResetOrNortmalized.length === 0) reset()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    let { name, value, checked } = e.target
    if (name === 'services') {
      if (value && value.includes('calefaccion')) {
        if (!value.includes('agua_caliente')) {
          value = [...value, 'agua_caliente']
        }
      }
    }

    if (
      value &&
      ['identificador', 'punto_suministro_nif', 'solicitante_nif', 'nif_facturacion'].includes(name)
    ) {
      isValidDocument(value)
        ? form.current[name].setCustomValidity('')
        : form.current[name].setCustomValidity('documento no valido')
    }

    if (name === 'sin_ubicacion') {
      value = checked
      changeCustomerForm({ name, value })
    } else {
      changeCustomerForm({ name, value })
    }

    if (name === 'persona') {
      if (value === 'J') {
        changeCustomerForm({ name: 'nombre', value: '.' })
      } else if (value === 'F') {
        changeCustomerForm({ name: 'nombre', value: undefined })
      }
    }

    //Special case for services field in certificate form
  }

  const handleFillSolitanteFields = (e) => {
    if (e.target.checked)
      changeCustomerFormArray({
        solicitante_interlocutor:
          customerForm['nombre'] === '.' ? customerForm['nombre'] === null : customerForm['nombre'],
        solicitante_nif: customerForm['identificador'],
        solicitante_telefono: customerForm['telefono'],
        solicitante_email: customerForm['email'],
      })
  }

  const handleFillFacturacionFields = (e) => {
    if (e.target.checked)
      changeCustomerFormArray({
        interlocutor_facturacion:
          customerForm['nombre'] === '.' ? customerForm['nombre'] === null : customerForm['nombre'],
        nif_facturacion: customerForm['identificador'],
        telefono_facturacion: customerForm['telefono'],
        email_facturacion: customerForm['email'],
      })
  }

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      const userConsostency = await getUserConsistency(customerForm['email'], customerForm['identificador']);
      if(userConsostency.validez_usuario) {
        nextStep({ replace: true })
      }
      else {
        toast.error(userConsostency.error)
      }


    },
    [customerForm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const inputProps = {
    formState: customerForm,
    onChange: handleChange,
    intl,
    combos,
    consultData,
  }

  useEffect(() => {
    changeCustomerForm({ name: 'persona', value: 'F' })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    prevStep({ replace: true })
  }

  const handleNavigate = () => {
    window.open(URL_CATASTRO, '_blank')
  }

  return (
    <>
      <Box>
        <Typography variant="h4" color="primary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.title.2' })}
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.customerSubtitle' })}
        </Typography>
      </Box>
      <Box pl={[0, 0, 5]} pr={[0, 0, 5]}>
        <form ref={form} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TIPO_PERSONA }} />
            </Grid>
            {customerForm.persona === 'F' ? (
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE }} />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).APELLIDOS }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).POBLACION }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).COD_POSTAL }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TIPO_IDENTIFICADOR }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                {...{
                  ...CUSTOMER_FIELDS_CONFIG(inputProps).IDENTIFICADOR,
                  error: !!idDocError,
                  helperText: idDocError,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).EMAIL }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TELEFONO }} />
            </Grid>
          </Grid>
          <Box>
            <Box mt={8} mb={8}>
              <Typography variant="h4" color="primary" paragraph>
                {intl.formatMessage({ id: 'pages.consult.title.2.datos_suministro' })}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBREDS }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NIFDS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).CALLEDS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NUMERODS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).POBLACIONDS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).COD_POSTALDS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).SUM_CNAE }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).REGISTRO_CATASTRAL }} />
                <Typography
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  variant="body2"
                  onClick={handleNavigate}
                >
                  Sede del catastro
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Box mt={8} mb={8}>
              <Typography variant="h4" color="primary" paragraph>
                {intl.formatMessage({ id: 'pages.consult.title.2.datos_solicitante' })}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE_EMPRESA }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE_INTERLOCUTOR }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).DNIS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TELEFONOS }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).CARGO }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).EMAILS }} />
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="autoFillDs"
                color="primary"
                onChange={(e) => handleFillSolitanteFields(e)}
              />
              <Typography>Autorellenar con los datos del cliente</Typography>
            </Box>
          </Box>
          <Box>
            <Box mt={8} mb={8}>
              <Typography variant="h4" color="primary" paragraph>
                {intl.formatMessage({ id: 'pages.consult.title.2.datos_facturacion' })}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE_EMPRESAF }} />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).NOMBRE_INTERLOCUTORF }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).DNIF }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TELEFONOF }} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).EMAILF }} />
              </Grid>
            </Grid>
            <Box display="flex" alignItems="center">
              <Checkbox
                name="autoFillDs"
                color="primary"
                onChange={(e) => handleFillFacturacionFields(e)}
              />
              <Typography>Autorellenar con los datos del cliente</Typography>
            </Box>
            <Box mt={2} display="flex" justifyContent="space-around">
              <ThemeButton onClick={handleGoBack} color="primary" labelM="lg">
                {intl.formatMessage({ id: 'global.return' })}
              </ThemeButton>
              <ThemeButton type="submit" color="primary" labelM="lg">
                {intl.formatMessage({ id: 'global.continue' })}
              </ThemeButton>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}
