import React from 'react'
export const ADDRESS_FORM_FIELDS = ['cp_municipio', 'direccion', 'numero', 'vivienda']

export const ADDRESS_FIELDS_CONFIG = ({ formState, onChange }) => ({
  CP_MUNICIPIO: {
    value: formState['cp_municipio'],
    name: 'cp_municipio',
    optionKey: 'municipio',
    title: 'Municipio o Código Postal',
    placeholder: 'Introduce municipio o CP',
    required: true,
    renderOption: (option) => `${option['cod_postal']} - ${option['municipio']}`,
    onChange,
  },
  DIRECCION: {
    value: formState['direccion'],
    name: 'direccion',
    optionKey: 'nombreVia',
    title: 'Dirección',
    placeholder: 'Nombre de la calle',
    renderOption: (option) => `${option['tipoVia']} ${option['nombreVia']}`,
    required: true,
    disabled: !formState['cp_municipio'],
    onChange,
  },
  NUMERO: {
    value: formState['numero'],
    name: 'numero',
    optionKey: 'numero',
    title: 'Número',
    placeholder: 'Ej. 22',
    renderOption: (option) =>
      option.special === 'other' ? 'Otro' : `${option['numero']} ${option['anexo']}`,
    required: true,
    disabled: !formState['direccion'],
    onChange,
  },
  VIVIENDA: {
    value: formState['vivienda'],
    name: 'vivienda',
    optionKey: 'vivienda',
    title: 'Vivienda',
    labelOption: (option) =>
      (option['bloque'] ? `Bloque ${option['bloque']} ` : '') +
      (option['escalera'] ? `Escalera ${option['escalera']} ` : '') +
      (option['piso'] ? `Piso ${option['piso']} ` : '') +
      (option['puerta'] ? `Puerta ${option['puerta']} ` : ''),
    renderOption: (option) => {
      if (option.special === 'other') {
        return 'No encuentro mi dirección'
      }
      const bloque = option['bloque'] ? (
        <span>
          Bloque <b>{option['bloque']} </b>
        </span>
      ) : (
        ''
      )
      const escalera = option['escalera'] ? (
        <span>
          Escalera <b>{option['escalera']}</b>
        </span>
      ) : (
        ''
      )
      const piso = option['piso'] ? (
        <span>
          Piso <b>{option['piso']}</b>
        </span>
      ) : (
        ''
      )
      const puerta = option['puerta'] ? (
        <span>
          Puerta <b>{option['puerta']}</b>
        </span>
      ) : (
        ''
      )
      return (
        <span>
          {bloque} {escalera} {piso} {puerta}
        </span>
      )
    },
    placeholder: 'Bloque, Escalera, Piso, Puerta',
    required: true,
    disabled: !formState['numero'],
    onChange,
  },
})
export const ADDRESS_FIELDS_CONFIG_NN = ({ formState, onChange, intl }) => ({
  CP_MUNICIPIO: {
    value: formState['cod_postal'] + ' - ' + formState['municipio'],
    name: 'cp_municipio',
    title: 'Municipio o Código Postal',
    readOnly: true,
  },
  DIRECCION: {
    value: formState['nombre_via'],
    name: 'nombre_via',
    title: 'Dirección',
    placeholder: 'Nombre de la calle',
    required: true,
    onChange,
  },
  NUMERO: {
    value: formState['numero'],
    name: 'numero',
    title: 'Número',
    placeholder: 'Ej. 22',
    required: true,
    onChange,
  },
  BLOQUE: {
    value: formState['bloque'],
    name: 'bloque',
    title: 'Bloque',
    placeholder: 'Ej. 3',
    onChange,
  },
  ESCALERA: {
    value: formState['escalera'],
    name: 'escalera',
    title: 'Escalera',
    placeholder: 'Ej. 2',
    onChange,
  },
  PISO: {
    value: formState['piso'],
    name: 'piso',
    title: 'Piso',
    placeholder: 'Ej. 2',
    onChange,
  },
  PUERTA: {
    value: formState['puerta'],
    name: 'puerta',
    title: 'Puerta',
    placeholder: 'Ej. 3',
    onChange,
  },
})
