import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'

import { TableUrlPagination } from '../../../../ui'
import { SR_TABLE_COLUMNS, DEFAULT_QUERY } from './sr-table.constants'
import { srTableStyles } from './sr-table.styles'
import { useSRContext } from '../../contexts'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const SRTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useSelector((state) => state.combos)
  const {
    data,
    pagination: { total_solicitudes: totalEntries },
    loading,
    search,
    tableSelection,
    setSelectedTableRows,
    getTableData,
  } = useSRContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = {
        ...DEFAULT_QUERY,
        ...urlSearchParams,
      }
      getTableData(searchParams)
    } else {
      history.replace(`/sr?${stringify(DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      SR_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.sr.table.columns.${column.field}.title` }),
          cellStyle: {
            ...srTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return data.map((row) => {
      const formattedColumns = {}
      SR_TABLE_COLUMNS.forEach((column) => {
        if (column.type === 'date' && row[column.field]) {
          formattedColumns[column.field] = intl.formatDate(row[column.field])
        }
      })
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return {
        ...row,
        ...formattedColumns,
        tableData: {
          ...row.tableData,
        },
      }
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      showSelectAllCheckbox: true,
      headerStyle: srTableStyles.header,
      tableLayout: 'fixed',
      draggable: false,
    }),
    []
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onSelectionChange={handleSelectRows}
    />
  )
}
