import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TARIFFS_TABLE_ACTIONS, TARIFFS_TABLE_COLUMNS } from '../../constants'
import { TableUrlPagination } from '../../../../ui'
import { tariffsTableStyles } from './tariffs-table.styles'
import { useRowSelector } from '../../../loader/hooks'
import { tariffsDataPreparation } from '../../logics'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const TariffsTable = () => {
  const intl = useIntl()
  const role = useSelector((state) => state.global.role)
  const tariffLoader = useSelector((state) => state.loader.tariffs)
  const data = tariffLoader?.data?.tarifas || []
  const totalEntries = tariffLoader?.data?.total_tarifas
  const search = tariffLoader?.config?.params
  const loading = tariffLoader?.loading

  const combos = useSelector((state) => state.combos)
  const history = useHistory()

  const handleGoToDetail = (e, row) => {
    history.push(`/tariffs/${row.tarifa_id}`)
  }
  const { selection, addSelection } = useRowSelector({
    identifier: 'tariffs',
    selectFilter: (state) => {
      return state?.data?.tarifas
    },
    idField: 'tarifa_id',
  })

  const handleSelectRows = useCallback((rows, row) => {
    addSelection(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //Column structure
  const formattedColumns = useMemo(
    () =>
      [...TARIFFS_TABLE_COLUMNS, ...TARIFFS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'actions' || column.field === 'active') {
          return { ...column, title: intl.formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: intl.formatMessage({ id: `pages.tariffs.table.columns.${column.field}.title` }),
          cellStyle: { ...tariffsTableStyles.cell, minWidth: column.minWidth },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )
  //Assembling and adjusting data
  const processedData = useMemo(() => {
    const selectedRows = selection.map((el) => el.tarifa_id + '' + el.provincia)
    let identificador = ''
    return tariffsDataPreparation(data, { intl }).map((row) => {
      identificador = row.tarifa_id + '' + row.provincia
      row.tableData.checked = selectedRows.indexOf(identificador) !== -1
      return row
    })
  }, [data, selection]) // eslint-disable-line react-hooks/exhaustive-deps

  const getSelectionTableOption = () => {
    return role === 'e_commerce' // Add the specific roles that wants this option available
  }
  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: getSelectionTableOption(),
      headerStyle: tariffsTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const line = (column) => {
          switch (column) {
            // case 'active':
            //   return <TableActive {...props} />
            default:
              return props.children
          }
        }
        return (
          <MTableCell {...props} style={{ padding: 0 }}>
            {line(props.columnDef.field)}
          </MTableCell>
        )
      },
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Not transformed in the url table interface
      data={processedData}
      isLoading={loading}
      onSelectionChange={handleSelectRows}
      onRowClick={handleGoToDetail}
    />
  )
}
