import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { JCAFilter, JCATable } from '../../../../features/jca'
import { useCustomCombo } from '../../../../features/combos'
import { JCA_CUSTOM_COMBOS_IDS } from '../../constants'
import { jcaDashboardStyles } from './jca-dashboard.styles'

export const JCADashboardPage = withStyles(jcaDashboardStyles)(({ classes }) => {
  useCustomCombo(JCA_CUSTOM_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.jca.dashboard.title' })}
        </Typography>
      </Box>
      <Box mb={2} display="flex">
        <JCAFilter />
      </Box>
      <Box>
        <JCATable />
      </Box>
    </Container>
  )
})
