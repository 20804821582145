export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }

export const APPS_FILTERS = [
  {
    name: 'tipo_documento',
    comboId: 'tipo_documento_sspp',
    size: 'small',
    multiple: true,
  },
  {
    name: 'estado',
    comboId: 'filtro_estados_sspp',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_mercado',
    comboId: 'tipo_mercado',
    size: 'small',
    multiple: true,
  },
  {
    name: 'provincia',
    comboId: 'provincias_sspp',
    cleanup: ['municipio'],
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'autocomplete',
    comboId: 'municipios_sspp',
    size: 'small',
    multiple: true,
  },
  {
    name: 'sociedad',
    comboId: 'sociedades',
    size: 'small',
    multiple: true,
  },
  {
    name: 'fecha_desde',
    filterType: 'date',
    maxDateField: 'fecha_hasta', //its dependent field
  },
  {
    name: 'fecha_hasta',
    filterType: 'date',
    minDateField: 'fecha_desde', //its dependent field
  },
]
