import React from 'react'
import { Typography, Button, Box, Chip, Tooltip } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { feedback } from '../../core/feedback'
import { requests } from '../../core/ajax'
import { downloadFile } from '../../core/utils'
import { FileUploaderAgnostic } from '../file-uploader-agnostic'
import { UploadTooltip } from '../upload-tooltip'

export const FileUploaderInput = (props) => {
  const { name, value, title, onChange, fileProps, readOnly = false } = props
  const { getById } = requests

  const handleFileUploadError = (data) => feedback('error', data.mssg)

  const handleDownloadCloudFile = (id) => {
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
      })
      .catch(() => {
        console.debug('Download document fail')
        feedback('error', 'Error al descargar el documento')
      })
  }

  const handleDownloadLocalFile = ({ data, name }) => {
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = data
    link.download = name
    link.click()
  }

  const handleDeleteFile = () => {
    onChange({ target: { name, value: null } })
  }

  return (
    <>
      {title ? (
        <Box display="flex" flexDirection="horizontal">
          <Typography style={{ marginRight: '0.5em' }} variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          {!readOnly ? <UploadTooltip file={fileProps} /> : null}
        </Box>
      ) : null}
      {!readOnly ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height={48}
          flexWrap="wrap"
        >
          <FileUploaderAgnostic
            name={name}
            onSuccess={onChange}
            onError={handleFileUploadError}
            required
            {...fileProps}
          >
            {() => (
              <Button component="span" variant="outlined">
                Seleccionar archivo
              </Button>
            )}
          </FileUploaderAgnostic>
        </Box>
      ) : null}
      {value ? (
        <Chip
          classes={{ labelSmall: { overflow: 'hidden', textOverflow: 'ellipsis' } }}
          size="small"
          avatar={<AttachFileIcon />}
          label={
            <Typography
              variant="body2"
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250 }}
            >
              {value.name + '.' + value.type}
            </Typography>
          }
          onClick={() => handleDownloadLocalFile(value)}
          onDelete={handleDeleteFile}
        />
      ) : props.currentDoc ? (
        <Tooltip
          arrow
          title={
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              Descargar fichero: {props.currentDoc.nombre + '.' + props.currentDoc.extension}
            </Typography>
          }
        >
          <Chip
            size="small"
            avatar={<AttachFileIcon />}
            label={
              <Typography
                variant="body2"
                style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250 }}
              >
                {props.currentDoc.nombre + '.' + props.currentDoc.extension}
              </Typography>
            }
            onClick={() => handleDownloadCloudFile(props.currentDoc.id)}
          />
        </Tooltip>
      ) : readOnly ? (
        '--'
      ) : null}
    </>
  )
}
