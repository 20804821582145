import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'

import { FileUploaderInput, BasicList } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useJCAContext } from '../../contexts'
import { JCAAddressesViewer } from './jca-addresses-viewer.component'
import { NONCONFOMITY_FIELDS, INSPECTION_FIELDS } from './jca-form.constants'

export const JCANonconfomityView = () => {
  const { formatMessage } = useIntl()
  const { jca } = useJCAContext()
  const combos = useCombos(['motivos_no_conformidad_jca'])
  return (
    <Box>
      <Box mt={4} mb={4}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.jca.detail.nonconformity.subtitle.1' })}
        </Typography>
        <BasicList {...{ data: jca, fields: NONCONFOMITY_FIELDS(formatMessage), combos }} />
      </Box>
      <Typography variant="h6" color="primary" paragraph>
        {formatMessage({ id: 'pages.jca.detail.nonconformity.subtitle.2' })}
      </Typography>
      <BasicList {...{ data: jca, fields: INSPECTION_FIELDS(formatMessage), combos }} />
      <JCAAddressesViewer {...{ addresses: jca.direcciones, instType: jca.tipo_instalacion }} />
      <Typography variant="h6" color="primary" paragraph>
        {formatMessage({ id: 'pages.jca.detail.nonconformity.subtitle.3' })}
      </Typography>
      <Grid container spacing={4}>
        {jca.justificante_jca ? (
          <Grid item xs={12} sm={6}>
            <FileUploaderInput
              {...{
                readOnly: true,
                title: formatMessage({ id: 'pages.jca.detail.fields.justificante_jca.title' }),
                name: 'justificante_jca',
                currentDoc: jca.justificante_jca,
              }}
            />
          </Grid>
        ) : null}
        {jca.certificado_ip ? (
          <Grid item xs={12} sm={6}>
            <FileUploaderInput
              {...{
                readOnly: true,
                title: formatMessage({ id: 'pages.jca.detail.fields.certificado_ip.title' }),
                name: 'certificado_ip',
                currentDoc: jca.certificado_ip,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}
