import React from 'react'
import { ReactComponent as ViewDocumentsIcon } from '../../../resources/images/icon_view_documents.svg'

export const HEADER_BUTTONS_CONFIG = [
  {
    id: 'viewDocuments',
    label: 'pages.supervision.edit.documents.view',
    icon: () => <ViewDocumentsIcon />,
  },
]
