export const HSE_URL = 'https://hse.nedgia.es'

export const seguimientoPuestaGasConfig = [['licencia', 'seguimiento_obras'], ['puesta_servicio']]

export const APP_EDITION_OPTIONS = {
  all: 'all',
  own: 'own',
  none: 'none',
}

export const GRID_ITEM_SMALL = {
  item: true,
  sm: 6,
  md: 2,
}
export const GRID_ITEM_MEDIUM = {
  item: true,
  sm: 6,
  md: 3,
}
export const GRID_ITEM_LARGE = {
  item: true,
  sm: 12,
  md: 6,
}
