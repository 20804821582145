export const APPS_COMBOS_IDS = [
  'tipo_mercado',
  'filtro_estados_sspp',
  'municipios_sspp',
  'sociedades',
  'provincias_sspp',
  'tipo_documento_sspp',
  'estados_solicitud',
  'estados_scr_sspp',
  'estados_pdte_sspp',
  'uso_punto_suministro',
  'productos_instalacion',
]
export const ORDER_COMBOS_IDS = ['municipios_sspp', 'provincias_sspp']
