import React, { useCallback, useMemo, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { parse, stringify } from 'qs'
import { Paper } from '@material-ui/core'

import { TableUrlPagination } from '../../../../ui'
import { useCombos } from '../../../combos'
import { useIP3HistContext } from '../../contexts'
import { ipHistTableDataPreparation } from '../../logics'
import { IP_HIST_TABLE_COLUMNS, IP_DEFAULT_QUERY } from './ip3-hist-table.constants'
import { ip3HistTableStyles } from './ip3-hist-table.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const IPHistTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const location = useLocation()
  const history = useHistory()
  const combos = useCombos([])
  const {
    data,
    pagination: { total_registros: totalEntries },
    loading,
    search,
    tableSelection,
    setSelectedTableRows,
    getTableData,
  } = useIP3HistContext()

  useEffect(() => {
    const searchString = location.search.split('?')[1]
    if (searchString) {
      const urlSearchParams = parse(searchString)
      const searchParams = urlSearchParams
      getTableData('ip', searchParams)
    } else {
      history.push(`/ip3-historical/ip?${stringify(IP_DEFAULT_QUERY)}`)
    }
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    setSelectedTableRows(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formattedColumns = useMemo(
    () =>
      IP_HIST_TABLE_COLUMNS.map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.ip3Historical.table.columns.${column.field}.title` }),
          cellStyle: {
            ...ip3HistTableStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            !column.hasOwnProperty('multiple') && column.comboId && combos[column.comboId]?.data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id)
    return ipHistTableDataPreparation({ data, combos, intl }).map((row) => {
      if (selectedIds.indexOf(row.id) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      showSelectAllCheckbox: false,
      headerStyle: ip3HistTableStyles.header,
      tableLayout: 'fixed',
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      data={processedData}
      isLoading={loading}
      onSelectionChange={handleSelectRows}
    />
  )
}
