import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { CollaboratorsDashboard } from '../../../features/collaborators'
import { useCombos, useCustomCombo } from '../../../features/combos/hooks/combos.hook'
import { COLLABORATORS_COMBOS_IDS } from '../constants'

export const CollaboratorsPage = () => {
  useCombos(COLLABORATORS_COMBOS_IDS)
  useCustomCombo(['empresas', 'gremios'])

  return (
    <Switch>
      <Route exact path="/collaborators" component={CollaboratorsDashboard} />
      <Redirect from="*" to="/collaborators" />
    </Switch>
  )
}
