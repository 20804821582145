import { useCallback, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  nonCaptablesValidationActions,
  nonCaptablesValidationInitialState,
  nonCaptablesValidationReducer,
} from '../reducers'
import { useAppNonCaptablesValidationCalls } from './non-captables-validation-calls.hook'

export const useNonCaptablesValidationReducer = () => {
  const [state, dispatch] = useReducer(
    nonCaptablesValidationReducer,
    nonCaptablesValidationInitialState
  )
  const { getNonCaptablesValidationApps, putNonCaptablesValidationApp } =
    useAppNonCaptablesValidationCalls()
  const history = useHistory()
  const { search } = useLocation() //Use strings and not objects to trigger useEffects a minimum ammount of times

  const nonCaptablesValidationLoad = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: nonCaptablesValidationActions.SUPERVISION_LOAD,
      })
      getNonCaptablesValidationApps(config)
        .then(({ data }) => {
          dispatch({
            type: nonCaptablesValidationActions.SET_SUPERVISION_DATA,
            payload: {
              data: data['solicitudes'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_solicitudes'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: nonCaptablesValidationActions.SUPERVISION_FAILURE,
          })
        })
    },
    [dispatch, nonCaptablesValidationActions] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const nonCaptablesValidationSelect = useCallback(
    (id, type) => {
      const remainingPathname = type ? '/' + type : ''
      history.push({
        pathname: id ? '/supervision/' + id + remainingPathname : '/supervision',
        search: search,
      })
    },
    [search, history]
  )

  const nonCaptablesValidationUpdateApplication = useCallback(
    (id, updatedValues) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: nonCaptablesValidationActions.SUPERVISION_LOAD,
        })
        putNonCaptablesValidationApp(id, updatedValues)
          .then(({ data }) => {
            dispatch({
              type: nonCaptablesValidationActions.UPDATE_SUPERVISED_APPLICATION,
              payload: updatedValues,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: nonCaptablesValidationActions.SUPERVISION_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch, nonCaptablesValidationActions] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const nonCaptablesValidationTableCheck = useCallback(
    (rows) => {
      dispatch({
        type: nonCaptablesValidationActions.UPDATE_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, nonCaptablesValidationActions, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    state,
    nonCaptablesValidationLoad,
    nonCaptablesValidationSelect,
    nonCaptablesValidationUpdateApplication,
    nonCaptablesValidationTableCheck,
  }
}
