import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { SR_FILTERS } from './sr-filter.constants'
import { ExportToXLSBtn } from '../exporttoxls-btn'

export const SRFilter = () => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)
  const filters = SR_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.sr.filters.' + item.name + '.title',
      }),
    }
  })
  return (
    <FilterBox filters={filters}>
      <ExportToXLSBtn />
    </FilterBox>
  )
}
