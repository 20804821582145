import { useEffect, useRef, useState } from 'react'

export const useTypingRefresh = ({
  timeout,
  action,
  textFromRef = (ref) => (ref.current ? ref.current : null),
}) => {
  const ref = useRef()
  const textFromRefValue = textFromRef(ref)?.value

  const [lastState, setLastState] = useState(false)

  useEffect(() => {
    // When it is unlinked to a reference, lastState is set to false
    if (!textFromRef(ref) || textFromRef(ref).value === null) {
      setLastState(false)
      return
    }
    //The first time it is linked to a reference, lastState is set to initial state
    const refval = textFromRef(ref)?.value
    if (lastState === false) {
      setLastState(refval)
      return
    }
    //In all other passes, we will send out an action call when 2 conditions are met:
    // A) no typing has happened during the wait time (refval === current)
    // B) there is some update with respect to the value of the last call (lastState !== current)
    const timer = setTimeout(() => {
      const current = textFromRef(ref)?.value
      if (current !== refval || current === lastState) {
        return
      }
      setLastState(refval)
      action(refval, textFromRef(ref))
    }, timeout)
    return () => clearTimeout(timer)
  }, [textFromRefValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    ref,
  }
}
