export const supervisionFormStyles = (theme) => ({
  fullForm: {
    // '& input:disabled': {
    //   border: 'none',
    //   padding: 0,
    //   color: 'black',
    //   '&:before': {
    //     content: '"HELP!!!"',
    //     backgroundColor: 'red',
    //   },
    //   '&:after': {
    //     content: '"NOT WORKING"',
    //   },
    // },
  },
  upperButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 24,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      justifyContent: 'center',
    },
  },
  bottomButtons: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  dummyButton: {
    position: 'absolute',
    right: 0,
    top: '0.5em',
    width: '20em',
    '& *': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& *': {
        visibility: 'visible',
      },
    },
  },
})
