import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Icon, CircularProgress } from '@material-ui/core'
import { toast } from 'react-toastify'

import { feedback } from '../../../../core/feedback'
import { ThemeButton, FileUploaderInput } from '../../../../ui'
import { usePublicOfferCalls } from '../../hooks/public-offer-calls.hook'
import { DateRangePicker } from '../../../../ui/date-range-picker'

const GeneralTermsList = () => {
  const { getPublicOffersGeneralTermsCall, postPublicOfferGeneralTerms } = usePublicOfferCalls()
  const [formState, setFormState] = useState([])
  const [dateChanged, setDateChanged] = useState(false)
  const [loading, setLoading] = useState()

  useEffect(() => {
    setLoading(true)
    getOPGeneralTermsList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getOPGeneralTermsList = () => {
    getPublicOffersGeneralTermsCall()
      .then((data) => {
        setLoading(false)
        setFormState(data.data)
      })
      .catch(() => {
        setLoading(false)
        feedback('error', `Hubo un error al cargar las Condiciones Generales`)
      })
  }

  const postOPGeneralTerms = (payload) => {
    return postPublicOfferGeneralTerms(payload)
  }

  const handleChangeDate = (date, name, index) => {
    setDateChanged(true)
    const newFormState = formState.map((item, i) => {
      if (i === index) {
        return { ...item, documento: { ...item.documento, [name]: date } }
      } else return item
    })
    setFormState(newFormState)
  }

  const handleUploadOPGeneralTermDoc = (e, ccaa, index) => {
    setDateChanged(false)
    if (e.target.value) {
      e.target.value.data = e.target.value.data.split(',').pop()
      const newItem = {
        ...ccaa,
        documento_nuevo: {
          type: e.target.value.type,
          name: e.target.value.name,
          data: e.target.value.data,
          fecha_desde: ccaa.documento.fecha_desde,
          fecha_hasta: ccaa.documento.fecha_hasta,
        },
      }
      const payload = {
        ...ccaa,
        documento: {
          documento: e.target.value.data,
          nombre: e.target.value.name,
          extension: e.target.value.type,
          fecha_desde: ccaa.documento.fecha_desde,
          fecha_hasta: ccaa.documento.fecha_hasta,
        },
      }
      var newFormState = formState
      newFormState[index] = newItem
      postOPGeneralTerms(payload)
        .then(() => {
          setFormState([...newFormState])
          toast.success(
            <>
              <Icon>check_circle</Icon>
              <Typography
                variant="body1"
                display="inline"
                style={{ verticalAlign: 'super', marginLeft: 8 }}
              >
                {`Condiciones Generales de ${ccaa.comunidad}  actualizadas con éxito`}
              </Typography>
            </>
          )
        })
        .catch((e) => {
          feedback(
            'error',
            `Hubo un error al actualizar las Condiciones Generales de ${ccaa.comunidad} `
          )
        })
    }
  }

  return (
    <Box mb={'2em'}>
      {loading ? (
        <Box display="flex" height="10em" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Box>
          {formState?.map((ccaa, index) => (
            <Box mt={'1em'}>
              <Box>
                <Typography variant="h6" color="primary">
                  {ccaa.comunidad}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" minHeight="8em">
                <Box>
                  <FileUploaderInput
                    {...{
                      value: ccaa['documento_nuevo'],
                      name: `documento_nuevo_${index}`,
                      title: `Condiciones Generales ${ccaa.comunidad}`,
                      onChange: (e) => handleUploadOPGeneralTermDoc(e, ccaa, index),
                      currentDoc: ccaa['documento'].id && ccaa['documento'],
                      fileProps: {
                        maxSize: 10000000, // 10Mb
                        accept: ['application/pdf'],
                      },
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="column" maxWidth="51%">
                  <DateRangePicker
                    startDate={ccaa.documento.fecha_desde}
                    endDate={ccaa.documento.fecha_hasta}
                    onChange={(date, name) => handleChangeDate(date, name, index)}
                    dateChanged={dateChanged}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export const GeneralTermsDialog = ({ close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={'0em 2em 2em 2em'}>
      <Typography variant="h5" color="primary">
        {formatMessage({ id: 'pages.public.offer.dialogs.general.terms.title' })}
      </Typography>
      <GeneralTermsList />
      <Box display="flex" justifyContent="center" width="100%" mt={'2em'}>
        <ThemeButton color="primary" labelM="lg" onClick={close}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
