import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Box, Grid, ListItem } from '@material-ui/core'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import { PageDisplayer, CardCheckList, TextInput } from '../../../../ui'
import { useTariffCalls, useZonesReducer } from '../../hooks'
import { tariffNewStyles } from './tariff-zone.style'

export const TariffZones = withStyles(tariffNewStyles)(({ classes }) => {
  //Tools
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { getTariff, getTariffZone } = useTariffCalls()
  const { comunidad, provincias, zips, zoneLoad } = useZonesReducer()

  //Available data:
  const { tariffId } = useParams()

  //Fetched data
  const [empresaId, setEmpresaId] = useState()
  const [provinciaMirada, setProvinciaMirada] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)

  useEffect(() => {
    getTariff(tariffId).then(({ data }) => {
      setEmpresaId(data.empresa_id)
    })
  }, [tariffId, getTariff, setEmpresaId])

  useEffect(() => {
    if (!empresaId) {
      return
    }
    getTariffZone(empresaId, tariffId).then(({ data }) => {
      setProvinciaMirada(data.codPostales[0] ? data.codPostales[0].prov : null)
      zoneLoad(data)
    })
  }, [empresaId, tariffId, getTariffZone, setProvinciaMirada, zoneLoad])

  const zip_choice = useMemo(
    () => (zips[provinciaMirada] ? zips[provinciaMirada] : []),
    [provinciaMirada, zips]
  )

  //GOING BACK
  const handleGoBack = useCallback(() => {
    history.push('/tariffs/' + tariffId)
  }, [history, tariffId])

  const handleCallGoBack = useCallback(() => {
    handleGoBack()
  }, [handleGoBack])

  //CHANGE PROVINCE BEING BROWSED
  const handleProvinciaSelect = useCallback(
    (provincia) => {
      setSearchQuery(null)
      setProvinciaMirada(provincia)
    },
    [setSearchQuery, setProvinciaMirada]
  )

  //CHANGE SEARCH QUERY
  const handleSearchQueryChange = useCallback(
    (event) => {
      const newText = event.target.value
      setSearchQuery(newText)
    },
    [setSearchQuery]
  )

  //Auxiliary components:
  const ChamaleonCheckbox = useCallback(
    withStyles({
      root: {
        color: '#fff',
        '&$checked': { color: '#fff' },
      },
      checked: {},
    })((props) => <Checkbox {...props} />),
    []
  )
  const CaseCheckbox = useCallback(({ provinciaMirada, element, ...props }) => {
    if (provinciaMirada === element.name) return <ChamaleonCheckbox {...props} />
    return <Checkbox {...props} />
  }, [])
  const provinceCheckRender = useCallback(
    (element) => {
      return (
        <ListItem
          key={element.name}
          className={
            classes.provinciaLine +
            (element.name === provinciaMirada ? ' ' + classes.provinciaLineChosen : '')
          }
          onClick={() => handleProvinciaSelect(element.name)}
        >
          <CaseCheckbox
            provinciaMirada={provinciaMirada}
            element={element.name}
            color="primary"
            name={element.name}
            checked={element.chosen === element.size}
            indeterminate={element.chosen < element.size && element.chosen > 0}
            // onChange={handleProvinciaCheck}
            disableRipple
          />
          {element.name}
        </ListItem>
      )
    },
    [provinciaMirada, classes, handleProvinciaSelect]
  )
  const zipCheckRender = useCallback(
    (element) => (
      <FormControlLabel
        key={element.value}
        className={classes.zipCard + (element.activa ? ' ' + classes.zipCardChosen : '')}
        label={element.value}
        control={
          <Checkbox
            color="primary"
            name={element.value}
            className={classes.zipMarker}
            checked={element.activa}
            disableRipple
          />
        }
      />
    ),
    [classes]
  )
  const zipBlockRender = useCallback(
    ({ children }) => <Box className={classes.zipContainer}>{children}</Box>,
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const communityField = useMemo(() => {
    return {
      value: comunidad,
      name: 'comunidad',
      readOnly: true,
    }
  }, [comunidad])

  //Main component
  return (
    <PageDisplayer
      loading={!zips || !zip_choice.length}
      error={false /*Canviar? */}
      handleGoBack={handleCallGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.zone.back' })}
    >
      <Typography variant="h4" color="textSecondary">
        {formatMessage({ id: 'pages.tariffs.zone.title' })}
      </Typography>
      <Box mt={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.cc_aa' })}
            </Typography>
            <TextInput {...communityField} />
          </Grid>
        </Grid>
      </Box>
      <Box mt={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.provinces.title' })}
            </Typography>
            <CardCheckList
              className={classes.colorPanel}
              keyField="name"
              nameField="name"
              elements={provincias}
              selected={provincias
                .filter((provincia) => provincia.size === provincia.chosen)
                .map((provincia) => provincia.name)}
              funcRenderElement={provinceCheckRender}
              wholeSelector={false}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.provinces.all' })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.zips.title' })}
            </Typography>
            <CardCheckList
              className={classes.colorPanel}
              keyField="value"
              nameField="value"
              elements={zip_choice}
              selected={zip_choice.filter((zip) => zip.activa).map((el) => el.value)}
              funcRenderElement={zipCheckRender}
              funcRenderContainer={zipBlockRender}
              searchBox={true}
              searchValue={searchQuery}
              searchPlaceholder={formatMessage({ id: 'pages.tariffs.zone.zips.filter' })}
              funcSearchOnChange={handleSearchQueryChange}
              wholeSelector={false}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.zips.all' })}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </PageDisplayer>
  )
})
