import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { TariffDetail, TariffZones } from '../../../features/tariff-detail'
import { TariffsDashboard } from '../../../features/tariffs'

export const TariffsPage = () => {
  return (
    <Switch>
      <Route exact path="/tariffs" component={TariffsDashboard} />
      <Route exact path="/tariffs/:tariffId" component={TariffDetail} />
      <Route exact path="/tariffs/:tariffId/zones" component={TariffZones} />
      <Redirect from="*" to="/tariffs" />
    </Switch>
  )
}
