import { useCallback } from 'react'

import { requests } from '../../../core/ajax'

export const useGuildsIP3Calls = () => {
  const { get } = requests

  const getGuilds = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/gremios/tabla_gremios', { ...config, params: { ...config.params, grabadora: true } })
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getGuilds,
  }
}
