import { toast } from 'react-toastify'

export const feedback = (type, message) => {
  toast.clearWaitingQueue()
  toast.dismiss()
  switch (type) {
    case 'info':
      return toast.info(message)
    case 'error':
      return toast.error(message)
    case 'success':
      return toast.success(message)
    case 'warning':
      return toast.warning(message)
    default:
      return
  }
}

export default feedback
