import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import {Box, Container, IconButton, Menu, Typography, withStyles} from '@material-ui/core'
import {AppsFilter, AppsTable, ExportToXLSBtn} from '../../../../features/supervision'
import {supervisionDashboardStyles} from './supervision-dashboard.styles'
import {AutoAssignBtn} from '../../../../features/supervision/components/autoassign-btn';
import {MoreVert} from '@material-ui/icons';

export const SupervisionDashboardPage = withStyles(supervisionDashboardStyles)(({classes}) => {
    const {formatMessage} = useIntl()

    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpenActions = (event) => setAnchorEl(event.currentTarget)

    const handleCloseActions = () => setAnchorEl(null)


    return (
        <Box>
            <Container className={classes.container} maxWidth="false">
                <Box mb={2}>
                    <Typography variant="h4" color="textSecondary">
                        {formatMessage({id: 'pages.supervision.title'})}
                    </Typography>
                </Box>
                <Box mb={2} display="flex">
                    <Box>
                        <AppsFilter>
                            <IconButton className={classes.actionsButton} onClick={handleOpenActions}>
                                <MoreVert/>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseActions}
                            >
                                <AutoAssignBtn/>
                                <ExportToXLSBtn/>
                            </Menu>
                        </AppsFilter>
                    </Box>
                </Box>
                <Box>
                    <AppsTable/>
                </Box>
            </Container>
        </Box>
    )
})
