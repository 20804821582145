export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }

export const APPS_FILTERS = [
  {
    name: 'tipologia',
    comboId: 'tipologias_tareas',
    size: 'small',
    multiple: true,
  },
  {
    name: 'estado',
    comboId: 'estados_sspp',
    size: 'small',
    multiple: true,
  },
  {
    name: 'mercado',
    comboId: 'tipo_mercado',
    size: 'small',
    multiple: true,
  },
  {
    name: 'provincia',
    comboId: 'provincias_by_cp',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'distribuidora',
    comboId: 'distribuidora',
    size: 'small',
    multiple: false,
  },
  {
    name: 'alerta',
    filterType: 'switch',
    cleanSelf: false,
  },
  { name: 'responsable', label: 'Responsable', comboId: 'gestores', size: 'small', multiple: true },
  {
    name: 'fecha_finalizacion_desde',
    filterType: 'date',
    maxDateField: 'fecha_finalizacion_hasta', //its dependent field
  },
  {
    name: 'fecha_finalizacion_hasta',
    filterType: 'date',
    minDateField: 'fecha_finalizacion_desde', //its dependent field
  },
]
