export const SR_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_sr',
    size: 'small',
    multiple: true,
  },
  {
    name: 'mercado',
    comboId: 'tipo_mercado',
    size: 'small',
    multiple: true,
  },
  {
    name: 'fecha_desde',
    filterType: 'date',
    maxDateField: 'fecha_hasta',
  },
  {
    name: 'fecha_hasta',
    filterType: 'date',
    minDateField: 'fecha_desde',
  },
]
