import {
  IP_HIST_TABLE_COLUMNS,
  JCA_HIST_TABLE_COLUMNS,
  IP_HIST_EXPORT_COLUMNS,
  JCA_HIST_EXPORT_COLUMNS,
} from '../components/table'

export const ipHistTableDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    IP_HIST_TABLE_COLUMNS.forEach((column) => {
      if (column.field === 'codigo_instalacion') {
        formattedText.codigo_instalacion = item.CUPS || item.cod_derivacion
      }
      if (column.field === 'direccion') {
        formattedText.direccion = item.nombre_via
          ? `${item.tipo_via || ''} ${item.nombre_via || ''} ${item.numero || ''}, ${
              item.poblacion || ''
            } (${item.codigo_postal || ''}), ${item.provincia || ''}`
          : '--'
      }
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
    }
  })
}

export const ipHistExportDataPreparation = ({ data, combos, intl }) => {
  const filteredData = data.filter((e) => e.estado !== '15')

  const formattedData = filteredData.map((item) => {
    const formattedText = {}
    IP_HIST_EXPORT_COLUMNS.forEach((column) => {
      if (column.field === 'tipo_documento') {
        formattedText[column.field] = 'IP'
      }
      if (column.exportType === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)

        formattedText[column.field] = formatedDate
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
  return formattedData
}

export const jcaHistTableDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    JCA_HIST_TABLE_COLUMNS.forEach((column) => {
      if (column.field === 'codigo_instalacion') {
        formattedText.codigo_instalacion = item.CUPS || item.cod_derivacion
      }
      if (column.field === 'direccion') {
        formattedText.direccion = `${item.tipo_via || ''} ${item.via || ''} ${item.numero || ''}, ${
          item.poblacion || ''
        } (${item.codigo_postal || ''}), ${item.provincia || ''}`
      }
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
    }
  })
}

export const jcaHistExportDataPreparation = ({ data, combos, intl }) => {
  const filteredData = data.filter((e) => e.estado !== '15')

  const formattedData = filteredData.map((item) => {
    const formattedText = {}
    JCA_HIST_EXPORT_COLUMNS.forEach((column) => {
      if (column.field === 'tipo_documento') {
        formattedText[column.field] = 'JCA'
      }
      if (column.exportType === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)

        formattedText[column.field] = formatedDate
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
  return formattedData
}
