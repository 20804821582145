import React, { createContext, useContext } from 'react'
import { useCampaignsReducer } from '../hooks/campaigns-reducer.hook'

const CampaignsContext = createContext()

export const CampaignsContextProvider = ({ children }) => {
  const reducer = useCampaignsReducer()
  return <CampaignsContext.Provider value={reducer}>{children}</CampaignsContext.Provider>
}

export const useCampaignsContext = () => useContext(CampaignsContext)
