import { IP3_HIST_INITIAL_STATE, IP3_HIST_ACTIONS_TYPES } from './ip3-hist-reducer.constants'

export const ip3HistReducer = (state = IP3_HIST_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case IP3_HIST_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case IP3_HIST_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case IP3_HIST_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }
    case IP3_HIST_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    default:
      throw new Error('unknown action')
  }
}
