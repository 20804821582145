export const PAYMENTS_TABLE_COLUMNS = [
  { field: 'solicitud_id', align: 'left', width: 180 },
  {
    field: 'pago_id',
    align: 'left',
    width: 180,
  },
  {
    field: 'resultado',
    align: 'left',
    width: 180,
  },
  { field: 'fecha_pago', align: 'left', width: 180, type: 'date' },
  {
    field: 'tipo_tarjeta',
    exportType: 'combo',
    comboId: 'tipo_tarjeta',
    width: 180,
    align: 'left',
  },
  {
    field: 'marca_tarjeta',
    exportType: 'combo',
    comboId: 'marca_tarjeta',
    align: 'left',
    width: 180,
  },
  { field: 'error', align: 'left', width: 180 },
  { field: 'cantidad_a_pagar', align: 'left', width: 180 },
  { field: 'sociedad', align: 'left', width: 180 },
  { field: 'numero_tarjeta', width: 180, align: 'left' },
  { field: 'titular_facturacion', width: 180, align: 'left' },
]

export const PAYMENTS_TABLE_FILTERS = [
  {
    name: 'fecha_pago_desde',
    filterType: 'date',
    maxDateField: 'fecha_pago_hasta',
  },
  {
    name: 'fecha_pago_hasta',
    filterType: 'date',
    minDateField: 'fecha_pago_desde',
  },
  {
    name: 'resultado',
    label: 'resultado',
    comboId: 'resultado_pago',
    size: 'small',
    multiple: true,
  },
]
