import { USER_TABLE_COLUMNS } from '../constants'

export const userDataPreparation = (data, { intl }, combos) => {
  return data.map((item) => {
    const formattedColumns = {}
    USER_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedColumns[column.field] = intl.formatDate(item[column.field])
      }
      if (column.infoType === 'logic') {
        formattedColumns[column.field] = item[column.field] ? 'Sí' : 'No'
      }
    })
    return {
      ...item,
      ...formattedColumns,
      estado: !item['oauth2_id']
        ? intl.formatMessage({ id: 'pages.users.table.columns.estado.unregistered' })
        : item['confirmed']
        ? intl.formatMessage({ id: 'pages.users.table.columns.estado.valid' })
        : intl.formatMessage({ id: 'pages.users.table.columns.estado.invalid' }),
      tableData: {
        ...item.tableData,
      },
    }
  })
}
