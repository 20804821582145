import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useAppContext } from '../../contexts'
import { ViewDocuments } from './view-documents.component'

export const ViewDocumentsBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application } = useAppContext()

  const handleOpenModal = () =>
    open({
      Component: ViewDocuments,
      data: { application },
      maxWidth: 'sm',
    })

  return (
    <ThemeButton variant="outlined" color="primary" size="small" onClick={handleOpenModal}>
      {formatMessage({
        id: 'pages.application.detail.viewDocsButton.label',
      })}
    </ThemeButton>
  )
}
