import React, { Component } from 'react'
import { AjaxContext } from '../../core/ajax'
import { APP_VERSION_MSSG } from '../../ui/layout/private-layout/constants'

class OnCrash extends Component {
  static contextType = AjaxContext
  state = { hasError: false, errorReported: false }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    const { post } = this.context
    const errorJSON = JSON.stringify(error, Object.getOwnPropertyNames(error))
    const errorMain = errorJSON.split('\\n')[0]
    const data = {
      environment: 'APG - ' + APP_VERSION_MSSG,
      type: 'React crash',
      path: window.location.hash,
      error: errorMain,
      info: JSON.stringify({ url: window.location, errorInfo: info, errorFull: errorJSON }),
    }
    post('/error_report', data).then(() => {
      console.debug('Error reported', data)
      this.setState({ errorReported: true })
    })
  }
  render() {
    return this.state.errorReported ? (
      <div style={{ margin: '1em' }}>
        <h1>¡Lo sentimos!</h1>
        <p>
          Ha habido un error no controlado en el sistema. Se ha notificado al proveedor de servicios
          para que pueda ser resuelto cuanto antes.
        </p>
      </div>
    ) : this.state.hasError ? (
      <div style={{ margin: '1em' }}>
        <h1>¡Lo sentimos!</h1>
        <p>Ha habido un error no controlado en el sistema. Enviando información...</p>
      </div>
    ) : (
      this.props.children
    )
  }
}

export default OnCrash
