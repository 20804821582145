import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { parse } from 'qs'

import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { ManagersTable } from '../table'
import { ManagersHeader } from '../header'
import { managerDashboardStyles } from './managers-dashboard.styles'
import { useLocation } from 'react-router-dom'
import { MARKETERS_DEFAULT_QUERY } from '../../constants'
import { setManagers } from '../../slices'
import { useCombos } from '../../../combos/hooks/combos.hook'

export const ManagersDashboard = withStyles(managerDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()
  const url = useLocation().search
  const urlSearchParams = parse(url.split('?')[1])
  const searchParams = {
    ...MARKETERS_DEFAULT_QUERY,
    ...urlSearchParams,
  }

  useCombos(['roles', 'permisos_extra'])
  useEffect(() => {
    dispatch(setManagers(searchParams))
  }, [url]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box>
      <Container className={classes.container} maxWidth="false">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.managers.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <ManagersHeader />
        </Box>

        <Box>
          <ManagersTable />
        </Box>
      </Container>
    </Box>
  )
})
