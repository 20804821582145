import { useCallback } from 'react'
import { requests, useAjaxContext } from '../../../core/ajax'
import { useIntl } from 'react-intl'
import { feedback } from '../../../core/feedback'

export const useMarketersCalls = () => {
  const { get } = requests

  const getMarketers = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/comercializadoras/tabla_comercializadoras', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const { getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getMarketer = useCallback(
    (marketerId) =>
      new Promise((resolve, reject) => {
        getById(`/comercializadoras`, `${marketerId}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 401:
              case 404:
              case 502:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.tariffs.error.wrongTariff.description' })
                )
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getMarketers,
    getMarketer,
  }
}
