import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import feedback from '../../../../core/feedback'
import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { ipHistExportDataPreparation } from '../../logics'
import { useIP3HistCalls } from '../../hooks'
import { useIP3HistContext } from '../../contexts'
import { IP_HIST_EXPORT_COLUMNS } from '../table/ip3-hist-table.constants'
import { IP_HIST_FILTERS, EXPORT_RECORDS_LIMIT } from './ip3-hist-filter.constants'
import { ip3HistFilterStyles } from './ip3-hist-filter.styles'

export const IPHistFilter = () => {
  const ip3HistClasses = ip3HistFilterStyles()
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  const { getIP3HistCall } = useIP3HistCalls()
  const { tableSelection, search, pagination } = useIP3HistContext()

  const filters = IP_HIST_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.ip3Historical.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox filters={filters} classes={ip3HistClasses} defaultOpen={true}>
      <ExportToXLSBtn
        exportSelectionEnabled={false}
        exportAllEnabled={true}
        selectedRows={tableSelection}
        totalRecords={pagination.total_registros}
        totalRecordsLimit={EXPORT_RECORDS_LIMIT}
        columnsExport={IP_HIST_EXPORT_COLUMNS}
        getAllData={() => {
          return new Promise((resolve, reject) => {
            getIP3HistCall('ip', { params: { ...search, _num_x_pagina: 0 } })
              .then(({ data }) => {
                return resolve(ipHistExportDataPreparation({ data: data.registros, combos, intl }))
              })
              .catch((err) => {
                feedback('error', formatMessage({ id: 'global.errorDownload' }))
                return reject(err)
              })
          })
        }}
        titleRoot="ip_historico_"
        headerNamespace="ipHistExport"
      />
    </FilterBox>
  )
}
