import { types } from '../constants'
//import { MOCK_NORMALIZED, MOCK_NORMALIZED_2, MOCK_NN } from '../../../mocks'

export const consultInitialState = {
  isLoading: false,
  step: 0,
  normalized: true,
  empresa: {},
  addressForm: {},
  townValues: [],
  installerValues: [],
  addressValues: [],
  numberValues: [],
  homeValues: [],
  selectedHome: {},
  consultData: {},
  tmpApplication: {},
  customerForm: {},
  certificateForm: {},
  isContratable: false,
  //...MOCK_NORMALIZED,
  //...MOCK_NORMALIZED_2,
  //...MOCK_NN,
}

export const consultReducer = (state, { type, payload }) => {
  switch (type) {
    case types.RESET:
      return consultInitialState
    case types.RENORMALIZE:
      return { ...state, normalized: true }
    case types.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case types.RESET_ADDRESS_FORM:
      return {
        ...state,
        ...payload,
      }
    case types.SET_ADDRESS_FORM:
      return {
        ...state,
        addressForm: { ...state.addressForm, [payload.key]: payload.value },
      }
    case types.SET_TOWN_VALUES:
      return {
        ...state,
        townValues: payload,
      }
    case types.SET_INSTALLER_VALUES:
      return {
        ...state,
        installerValues: payload,
      }
    case types.SET_ADDRESS_VALUES:
      return {
        ...state,
        addressValues: payload,
      }
    case types.SET_NUMBER_VALUES:
      return {
        ...state,
        numberValues: payload,
      }
    case types.SET_HOME_VALUES:
      return {
        ...state,
        homeValues: payload,
      }
    case types.SET_SELECTED_HOME:
      return {
        ...state,
        selectedHome: payload,
        isLoading: true,
      }
    case types.SET_CONSULT_DATA:
      return {
        ...state,
        consultData: payload,
        isLoading: false,
      }
    case types.SET_CUSTOMER_FORM:
      return {
        ...state,
        customerForm: { ...state.customerForm, [payload.key]: payload.value },
      }
    case types.SET_IS_CONTRATABLE:
      return {
        ...state,
        isContratable: payload,
      }
    case types.SET_CUSTOMER_FORM_ARRAY:
      return {
        ...state,
        customerForm: {
          ...state.customerForm,
          ...payload,
        },
      }
    case types.CHANGE_TO_UNNORMALIZED:
      return {
        ...state,
        selectedHome: payload,
        normalized: false,
      }
    case types.PREPARE_CERTIFICATE_FORM:
      return {
        ...state,
        certificateForm: { ...payload },
        isLoading: false,
      }
    case types.SET_CERTIFICATE_FORM:
      return {
        ...state,
        certificateForm: {
          ...state.certificateForm,
          [payload.key]: payload.value,
        },
      }
    case types.SET_EMPRESA:
      return {
        ...state,
        empresa: payload,
      }
    default:
      throw new Error('Unrecognized action')
  }
}
