import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeButton } from '../../../../ui';
import { EditSAPCodeDialog } from '../dialogs';
import { useIntl } from 'react-intl';
import { usePORequestsCalls } from '../../hooks/po-requests-calls.hook';
import { PO_REQUEST_STATES } from '../../constants';

export const EditSapCodeButton = ({ data, onChange }) => {
  const { formatMessage: fm } = useIntl();
  const [openDialog, setOpenDialog] = useState(false);
  const { putRequestCall } = usePORequestsCalls();

  const permissions = useSelector((state) => state.global.permissions);

  const allowedStates = [
    PO_REQUEST_STATES.PENDING_DOCS_REVIEW,
    PO_REQUEST_STATES.PENDING_ZEUS_REGISTER,
    PO_REQUEST_STATES.PENDING_ZEUS_ADHESION,
    PO_REQUEST_STATES.PROCESSED
  ];

  const isSapCodeEditable = allowedStates.includes(data.estado);

  if (!permissions['gestion_adhesiones_op'] || !isSapCodeEditable) {
    return null;
  }

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleUpdateSAPCode = async (updatedData) => {
    try {
      const response = await putRequestCall(data.id, { cod_sap: updatedData.cod_sap });
      if (response && response.data && response.data.cod_sap) {
        onChange({ target: { name: 'cod_sap', value: response.data.cod_sap } });
      }
      setOpenDialog(false);
    } catch (error) {
      console.error('Error updating SAP code:', error);
    }
  };

  return (
    <div>
      <ThemeButton
        onClick={handleButtonClick}
        style={{ margin: '16px' }}
      >
        {fm({ id: 'pages.poRequests.detail.editSAPCode.btn.label' })}
      </ThemeButton>

      <EditSAPCodeDialog
        open={openDialog}
        close={handleDialogClose}
        currentSAPCode={data.cod_sap}
        updateSAPCode={handleUpdateSAPCode}
      />
    </div>
  );
};
