import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Container, Typography } from '@material-ui/core'
import { stringify } from 'qs'
import { GridRow, PageDisplayer } from '../../../../ui'
import { useSelector } from 'react-redux'
import { useMarketersCalls } from '../../hooks'
import { MARKETER_DETAIL_ITEMS } from '../../constants'
import { OpChecks } from '../checks-op/checks-op.component'

export const MarketerDetail = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const { getMarketer } = useMarketersCalls()
  const { marketerId } = useParams()

  const [marketer, setMarketer] = useState({})
  const marketers = useSelector((state) => state.marketers)
  const search = marketers?.search
  const haveOpChecked = marketer?.checks_op?.some((check) => check.checked === true)

  useEffect(() => {
    getMarketer(marketerId).then(({ data }) => setMarketer(data))
  }, [marketerId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const marketerUrl = search ? '?' + stringify(search) : '/'
    history.push(`/marketers` + marketerUrl)
  }

  return (
    <PageDisplayer
      loading={!marketer || !Object.keys(marketer).length}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.marketers.back' })}
    >
      <div>
        <Container maxWidth="md">
          <Box mb={3}>
            <Box bgcolor="common.white" borderRadius={6} p={6} pt={3}>
              <Typography variant="h4" color="textSecondary">
                Detalle comercializadora
              </Typography>
              <Box bgcolor="grey.200" borderRadius={4} p={[2, 4]} mt={3}>
                <GridRow items={MARKETER_DETAIL_ITEMS} data={marketer} />
                {haveOpChecked && <OpChecks {...{ checksOp: marketer.checks_op }} />}
              </Box>
            </Box>
          </Box>
        </Container>
      </div>
    </PageDisplayer>
  )
}
