import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'
import { saveLocalStorage } from '../../../core/utils'

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoading: true,
    user: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
  },
})

export const profileReducer = profileSlice.reducer

export const selectUser = (state) => state.profile.user

export const { setUser, setLoading } = profileSlice.actions

export const setUserData = (userId, token) => (dispatch) => {
  const { getById } = requests

  dispatch(setLoading(true))
  getById('/gestor', userId)
    .then(({ data }) => {
      dispatch(setUser(data))
      dispatch(setLoading(false))
    })
    .catch(({ response }) => {
      if (response?.status === 403) {
        saveLocalStorage('dsc', {})
        dispatch(setUser({}))
        setTimeout(window.location.reload(), 1000)
      }
      dispatch(setLoading(false))
    })
}

export const resetUserData = () => (dispatch) => {
  dispatch(setLoading(false))
  dispatch(setUser({}))
}
