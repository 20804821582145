import { requests } from '../../../core/ajax'
const { get, getById, post } = requests

export const getDataForm = (cidi) => {
  return new Promise((resolve, reject) => {
    getById('/potenciales/formulario_data', cidi).then(({ data }) => resolve(data))
  })
}

export const getEmpresaCAByZip = (id_installer, zip) => {
  return new Promise((resolve, reject) => {
    get(`/instalador/chek_emprsea_ca_by_cp/${id_installer}/${zip}`).then(({ data }) =>
      resolve(data)
    )
  })
}

export const getEmpresasByCA = (id_gremio, cc_aa) => {
  return new Promise((resolve, reject) => {
    get(`/gremios/${id_gremio}/empresas`, { _search: { cc_aa } }).then((data) => {
      resolve(data)
    })
  })
}

export const getAppByCidi = (cidi) => {
  return new Promise((resolve, reject) => {
    getById('/solicitudes/cidi', cidi).then(({ data }) => {
      resolve(data)
    })
  })
}

export const getUserConsistency = (email, identifier) => {
  return new Promise((resolve, reject) => {
    get(`/usuario/usuario_valido/${email}/${identifier}`).then(({ data }) => {
      resolve(data)
    })
  })
}
export const getGestorGC = (municipioId, cod_cp, uso) => {
  return new Promise((resolve, reject) => {
    get(`/zona_gran_consumo/gestor/${municipioId}/${cod_cp}`, { params: { uso: uso } })
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        if (!response) {
          return reject('No response')
        }
        switch (response.status) {
          case '404': //Permisson denied by role
            return reject(response)
          default:
        }
        return reject(response)
      })
  })
}

export const getFormPaymentData = (payload) => {
  return new Promise((resolve, reject) => {
    post('/gran_consumo/calcular_datos', payload)
      .then(({ data }) => {
        resolve(data)
      })
      .catch(({ response }) => {
        if (!response) {
          return reject('No response')
        }
        switch (response.status) {
          case '404': //Permisson denied by role
            return reject(response)
          default:
        }
        return reject(response)
      })
  })
}
