export const tableSameCidiStyles = {
  header: {
    fontSize: 12,
    padding: 10,
    color: '',
    backgroundColor: '#f3f3f3',
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cell: {
    fontSize: 12,
    color: '#63666a',
    backgroundColor: '#FFFFFF',
    padding: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    padding: 0,
    margin: 0,
  },
  backdrop: {
    zIndex: 1,
    color: '#FFFFFF',
  },
}
