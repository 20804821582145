import React from 'react'
import { Box } from '@material-ui/core'

import { useSirContext } from '../../contexts'
import { GenerateBudgetBtn } from './generate-budget-btn.component'
import { SendBudgetBtn } from './send-budget-btn.component'
import { ValidatePaymentBtn } from './validate-payment-btn.component'
import { InvalidatePaymentBtn } from './invalidate-payment-btn.component'
import { ViewBillBtn } from './view-bill-btn.component'
import { SIR_STATES } from '../../constants'
import { CAN_GENERATE_BUDGET_STATES, CAN_SEND_BUDGET_STATES } from './form-action-btns.constants'
import { CAN_VIEW_BILL_STATES } from './form-action-btns.constants'
import { PendingDatesBtn } from '../form-action-btns/pending-dates-btn.component'

export const FormActionBtns = () => {
  const { application } = useSirContext()
  return (
    <Box display="flex" flexWrap="wrap">
      {CAN_GENERATE_BUDGET_STATES[application['estado']] ? (
        <Box mt={2} mr={2}>
          <GenerateBudgetBtn />
        </Box>
      ) : null}
      {CAN_SEND_BUDGET_STATES[application['estado']] ? (
        <Box mt={2}>
          <SendBudgetBtn />
        </Box>
      ) : null}
      {application['estado'] === SIR_STATES.VALIDATE_PAYMENT.KEY ? (
        <>
          <Box mt={2} mr={2}>
            <ValidatePaymentBtn />
          </Box>
          <Box mt={2} mr={2}>
            <InvalidatePaymentBtn />
          </Box>
        </>
      ) : null}
      {CAN_VIEW_BILL_STATES[application['estado']] ? (
        <Box mt={2} mr={2}>
          <ViewBillBtn />
        </Box>
      ) : null}
      {application['estado'] === '118' ? (
        <Box
          display="flex"
          justifyContent="flex-end"
          style={{ marginTop: '15px', marginRight: '0px', marginLeft: 'auto' }}
        >
          <PendingDatesBtn />
        </Box>
      ) : null}
    </Box>
  )
}
