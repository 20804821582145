export const checkboxComponentStyles = {
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    '&+*': { marginTop: 0, width: '100%' },
  },
  checkbox: {
    padding: 2,
  },
  formControlLabel: {
    paddingLeft: 7,
  },
}
