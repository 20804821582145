import React, { useEffect, useState } from "react";
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { TextInput, ThemeButton } from '../../../../ui'
import { InputGenerator } from "../../utils";
import { useJobsContext } from "../../contexts";

const TEXT_AREA_LENGTH = 250

export const ConfirmSendDialog = ({
  batch,
  executeFunction,
    close, ...props
}) => {
  const [inputValues, setInputValues] = useState({})
  const noAction = () => { close()}
  useEffect(() => {
    const newInputValues = {}
    batch.config && batch.config.forEach((param) => {
    newInputValues[param.label] = param.value
    })
    setInputValues(newInputValues)
  }, []);

  const handleInputChange = (e, new_value=undefined) => {
    if (e.target.checked) {
      setInputValues({
        ...inputValues,
        [e.target.name]: e.target.checked
      })
    }
    else if (new_value && !e.target.value) {
        setInputValues({
            ...inputValues,
            [e.target.name]: new_value
        })
    }
    else {
        setInputValues({
            ...inputValues,
            [e.target.name]: e.target.value
        })
    }
  }

  const launchBatch = () => {
    let input_array = []
    let input_map = {}
    batch.config && batch.config.forEach((param) => {
      if (param.name) {
        input_map[param.name] = inputValues[param.label]
      } else {
        if (typeof inputValues[param.label] === "boolean" || typeof inputValues[param.label] === "number") {
          input_array.push(inputValues[param.label].toString())
        }
        else {
          input_array.push(inputValues[param.label])
        }
      }
    })

    executeFunction(batch.id, input_array, input_map)
    close()
  }

    return (
        <Box p={4}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h5" color="primary" align="center">
                        ¿Estás seguro de ejecutar el batch {batch.name}?
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
              <Typography variant="p" color="default" align="center">
                {batch.description} - <i>Esta acción no se puede deshacer.</i>
              </Typography>
              {batch.config && batch.config.length > 0 &&
                <Box mt={2}>
                  <Typography variant="p" color="default" align="center">
                    <b>Parámetros:</b>
                  </Typography>
                  {batch.config.map((param, index) => (
                    <Box key={index} mt={1}>
                      <InputGenerator type={param.type} name={param.label} label={param.label} options={param.options} required={param.required} value={inputValues[param.label]} handleChange={handleInputChange} />
                    </Box>
                  ))}
                </Box>
              }
            </DialogContent>
            <DialogActions style={{justifyContent: 'space-between'}}>
                <ThemeButton
                    labelM="small"
                    color={"default"}
                    onClick={noAction}
                >
                    Cancelar
                </ThemeButton>
                <ThemeButton
                    labelM="small"
                    color={"primary"}
                    onClick={launchBatch}
                >
                  Ejecutar
                </ThemeButton>
            </DialogActions>
        </Box>
    )
}
