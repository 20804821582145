import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { ApplicationsDashboard, ApplicationDetailPage } from '../components'
import { CertificatesPage } from '../../certificates'
import { useCombos, useCustomCombo } from '../../../features/combos/hooks'
import { AppContext } from '../../../features/application-detail/contexts'
import { useApplicationUserPermitsContext } from '../contexts'

export const ApplicationsRouter = () => {
  const userPermits = useApplicationUserPermitsContext()
  useCombos(userPermits.combos)
  useCustomCombo(userPermits.customCombos)

  const ApplicationDetail = () => {
    return (
      <AppContext>
        <ApplicationDetailPage />
      </AppContext>
    )
  }

  return React.useMemo(
    () => (
      <Switch>
        <Route exact path="/applications" component={ApplicationsDashboard} />
        <Route exact path="/applications/:appId" component={ApplicationDetail} />
        <Route exact path="/applications/:appId/certificates" component={CertificatesPage} />
        <Redirect from="*" to="/applications" />
      </Switch>
    ),
    []
  )
}
