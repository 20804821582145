import { Box, Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'

import { FoldingPanel } from '../../../../../ui'
import { useAppContext } from '../../../contexts'
import { CampaignGrid } from './campaign.component'
import { useCampaignPanel } from './campaigns-panel.hook'

export const CampaignsPanel = () => {
  const intl = useIntl()
  const { formatMessage: fm } = useIntl()
  const { application } = useAppContext()
  const { campaignPanelFields, opCampaignPanelFields } = useCampaignPanel()
  return (
    <FoldingPanel title={fm({ id: 'pages.application.detail.campaigns.title' })}>
      <Box flex={1} pt={1} pb={1}>
        {application.campana ? (
          <Box>
            <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
              {intl.formatMessage({
                id: `pages.application.detail.campaigns.campaign.title`,
              })}
            </Typography>
            <CampaignGrid fields={campaignPanelFields} />
          </Box>
        ) : null}
        {application.campana && application.campana_op ? (
          <Divider light style={{ margin: '20px 0' }} />
        ) : null}

        {application.campana_op ? (
          <Box>
            <Typography variant="h6" color="primary" style={{ marginBottom: 10 }}>
              {intl.formatMessage({
                id: `pages.application.detail.campaigns.op_campaign.title`,
              })}
            </Typography>
            <CampaignGrid fields={opCampaignPanelFields} />
          </Box>
        ) : null}
      </Box>
    </FoldingPanel>
  )
}
