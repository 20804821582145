import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const { post } = requests
const noResponseError = () => {
  console.error('No response!')
}
const defaultError = () => {
  console.error('Unknown error!')
}

export const postProvisionalApp = (payload) => {
  return new Promise((resolve, reject) => {
    post('/solicitudes_tmp', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'No se ha creado la solicitud temporal')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const postUsuario = (payload) => {
  return new Promise((resolve, reject) => {
    post('/usuario', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          case 400:
            if (
              response.data?.code === 3635 ||
              (response.data?.code === 3636 && response?.data?.message)
            ) {
              feedback(
                'error',
                'Ya existe una solicitud con el mismo identificador (NIF) pero distinto email'
              )
            } else {
              defaultError()
            }
            break
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const postApp = (payload) => {
  return new Promise((resolve, reject) => {
    post('/solicitudes', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          case 400:
            if (response?.data?.code === 3017 && response?.data?.message) {
              feedback('error', response.data.message)
            } else {
              defaultError()
            }
            break
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}

export const updateAppDocs = (id_app, payload) => {
  return new Promise((resolve, reject) => {
    requests
      .putById('/solicitudes/update_docs', id_app, payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'Error: No se han podido asociar los documentos a la solicitud')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}
