import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core'

import { FileUploader, ThemeButton, UploadTooltip } from '../../../../ui'
import { documentsUploadStyles } from './documents-upload.style'
import { CANT_CREATE_CERTIFICATE_STATES, UPLOAD_BUTTONS_CONFIG } from '../../constants'
import { feedback } from '../../../../core/feedback'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents'

export const DocumentsUploadComponent = withStyles(documentsUploadStyles)(
  ({ close, application, updateApplicationDocs }) => {
    const intl = useIntl()

    const [model, setModel] = useState({ ...application })
    const [loading, setLoading] = useState(false)
    const [uploadButtonArray, setUploadButtonArray] = useState([])

    const handleUploadBudgetFile = (data, docId) => {
      setLoading(true)
      const payload = { [docId]: data['id'] }
      updateApplicationDocs(payload)
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          console.error('Error uploading croquis file')
        })
    }

    const handleUploadError = (data) => {
      feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
    }

    useEffect(() => {
      if (application) {
        setModel({
          ...application,
        })
      }
    }, [application])

    useEffect(() => {
      if (model) {
        setUploadButtonArray(
          UPLOAD_BUTTONS_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_CONFIG[doc])
            .filter((doc) => {
              if (
                (doc.id === 'd_IRG1_id' || doc.id === 'd_IRG2_id' || doc.id === 'd_IRG3_id') &&
                (!model[doc.subfield] ||
                  !model[doc.subfield].estado ||
                  model[doc.subfield].estado === 'Tramitado' ||
                  model[doc.subfield].estado === 'Anulado' ||
                  CANT_CREATE_CERTIFICATE_STATES[application.estado])
              )
                return false
              return !doc.subfield || model[doc.subfield]
            })
            .map((doc) => {
              return {
                ...doc,
                title: intl.formatMessage({
                  id: `pages.application.view.documentViewer.documents.${doc.field}`,
                }),
                label: intl.formatMessage({
                  id: 'global.documents.button.add',
                }),
                name: model[doc.model]?.nombre || '',
                estado: doc.subfield ? model[doc.subfield].estado : null,
              }
            })
        )
      }
    }, [model, application.estado, intl])

    return (
      <Box p={4}>
        <Box mb={4}>
          <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: '1em' }}>
            Recomendamos adjuntar los documentos correspondientes a sus apartados para evitar el
            rechazo de la petición.
          </Typography>
          <Grid container spacing={2}>
            {uploadButtonArray.map((uploadButton) => {
              return (
                <Grid key={uploadButton.id} item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography display="inline" variant="body2">
                          {uploadButton.title}
                        </Typography>
                        <UploadTooltip file={uploadButton} />
                      </Box>
                      <Typography
                        variant="body2"
                        color={uploadButton.estado === 'Rechazado' ? 'error' : 'initial'}
                      >
                        {uploadButton.name}
                      </Typography>
                    </Box>
                    <FileUploader
                      applicationId={model['id']}
                      budgetId={model['presupuesto']?.id}
                      clientId={model['usuario']['id']}
                      onSuccess={(data) => handleUploadBudgetFile(data, uploadButton.id)}
                      onError={handleUploadError}
                      {...uploadButton}
                    >
                      {(props) => (
                        <span
                          className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                          style={{ marginLeft: 10 }}
                          disabled={loading}
                        >
                          {props.loading ? (
                            <CircularProgress size={20} />
                          ) : (
                            intl.formatMessage({ id: 'global.documents.button.add' })
                          )}
                        </span>
                      )}
                    </FileUploader>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          <ThemeButton onClick={() => close()} color="primary" labelM="lg" loading={loading}>
            {intl.formatMessage({ id: 'global.close' })}
          </ThemeButton>
        </Box>
      </Box>
    )
  }
)
