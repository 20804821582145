import { IconButton } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { useExtractionCalls } from '../hooks'
import { useExtractionDialogs } from '../hooks/extraction-dialogs.hook'
import DateFnsAdapter from '@date-io/date-fns'

export const ExtractionButton = ({ classes }) => {
  const dateFns = new DateFnsAdapter()

  const email = useSelector((state) => state.profile.user.email)
  const role = useSelector((state) => state.global.role)

  const { requestNewExtractionByEmail, launchNewExtractionByEmail, launchNewSSPPExtraction } =
    useExtractionCalls()
  const { confirmExtractionProcess, confirmExtractionProcessByDates, announcePendingProcess } =
    useExtractionDialogs()

  const handleExtractionRequest = async () => {
    try {
      await requestNewExtractionByEmail()

      switch (role) {
        case 'e_commerce':
          confirmExtractionProcess(email, launchNewExtractionByEmail)
          break
        case 'soporte_procesos':
          confirmExtractionProcessByDates(email, ({ from, to }) => {
            launchNewSSPPExtraction({
              params: {
                from_date: dateFns.format(dateFns.date(from), 'dd/MM/yyyy'),
                to_date: dateFns.format(dateFns.date(to), 'dd/MM/yyyy'),
              },
            })
          })
          break
        default:
      }
    } catch (code) {
      if (code === 'already started') {
        announcePendingProcess(email)
      }
    }
  }

  return (
    <IconButton className={classes.iconButton} onClick={handleExtractionRequest}>
      <CloudDownload />
    </IconButton>
  )
}

export default ExtractionButton
