export const APP_ACTIONS_TYPES = {
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
  SET_APPLICATION: 'SET_APPLICATION',
  SET_EVENTS: 'SET_EVENTS',
  SET_DETAIL_FAILURE: 'SET_DETAIL_FAILURE',
  SET_INSTALLER_VALUE: 'SET_INSTALLER_VALUE',
}

export const APP_INITIAL_STATE = {
  loading: false,
  error: false,
  application: {},
  events: [],
  installerValue: [],
}
