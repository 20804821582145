export const SR_TABLE_COLUMNS = [
  // { field: 'distribuidora', align: 'left', width: 180 },
  // { field: 'unidad_organizativa', align: 'left', width: 180 },
  // { field: 'en_plazo', align: 'left', width: 180 },
  { field: 'dias_en_cartera', align: 'right', width: 50 },
  { field: 'cod_sr', align: 'center', width: 230 },
  { field: 'linea_negocio', align: 'left', width: 180 },
  { field: 'proceso', align: 'center', width: 180 },
  { field: 'motivo', align: 'center', width: 140 },
  {
    field: 'descripcion_tipologia',
    align: 'left',
    width: 240,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  {
    field: 'descripcion_subtipologia',
    align: 'left',
    width: 240,
    cellStyle: {
      whiteSpace: 'wrap',
    },
  },
  { field: 'asignado_a', align: 'left', width: 120 },
  { field: 'estado', align: 'left', width: 100 },
  { field: 'canal', align: 'left', width: 120 },
  { field: 'fecha_creacion', type: 'date', align: 'left', width: 100 },
  // { field: 'mercado', align: 'left', width: 100 },
  // // { field: 'codigo_postal', align: 'left', width: 180 },
  // {
  //   field: 'nombre',
  //   render: (rowData) =>
  //     rowData.nombre +
  //     (rowData.apellido1 ? ' ' + rowData.apellido1 : '') +
  //     (rowData.apellido2 ? ' ' + rowData.apellido2 : ''),
  //   align: 'left',
  //   width: 180,
  // },
  // { field: 'telefono', align: 'left', width: 120 },
  // { field: 'cups', align: 'left', width: 120 },
  // { field: 'cidi', align: 'left', width: 120 },
  { field: 'zip_code', align: 'left', width: 120 },
  {
    field: 'direccion',
    align: 'left',
    width: 250,
  },
  // { field: 'descripcion_evento_observacion', align: 'left', width: 120 },
  // {
  //   field: 'primer_evento_observacion',
  //   align: 'left',
  //   width: 440,
  //   cellStyle: {
  //     whiteSpace: 'wrap',
  //   },
  // },
  // { field: 'num_reasignaciones', align: 'left', width: 120 },
  { field: 'fecha_ultimo_evento', type: 'date', align: 'left', width: 120 },
  { field: 'tipo_ultimo_evento', align: 'left', width: 120 },
  // { field: 'ultimo_evento_observacion', align: 'left', width: 120 },
  // { field: 'sr_existente', align: 'left', width: 120 },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
