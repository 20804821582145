import React from 'react'
import { useIntl } from 'react-intl'
import { Box, ListItem, Typography } from '@material-ui/core'
import { RatingInput } from '../../../../ui'

export const ReviewItem = (props) => {
  const { formatDate, formatMessage } = useIntl()
  const handleOnClick = () => {
    if (props.id) {
      props.handleGoToDetail(props.id)
    }
  }
  const PrimaryItem = () => (
    <>
      <Typography component="span" variant="body2" color="textPrimary">
        {props.nombre}
      </Typography>
      {props.id_solicitud ? (
        <>
          {' - '}
          <Typography
            component="span"
            variant="caption"
            color="primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={handleOnClick}
          >
            {props.id_solicitud}
          </Typography>
        </>
      ) : null}
    </>
  )

  const SecondaryItem = () => (
    <>
      <Box display="flex" style={{ marginBottom: '0.5em' }}>
        <RatingInput
          {...{
            name: 'valoracion',
            value: props.valoracion,
            readOnly: true,
            size: 'small',
          }}
        />
        <Typography component="span" variant="body2" color="textPrimary" style={{ marginLeft: 4 }}>
          {formatDate(props.fecha_comentario)}
        </Typography>
      </Box>
      <Typography component="span" variant="body2" color="textPrimary">
        {props.comentario}
      </Typography>
      {props.respuesta ? (
        <Box display="flex" flexDirection="column" mt={1}>
          <Box>
            <Typography color="textPrimary" variant="subtitle2" style={{ fontWeight: 'bold' }}>
              {formatMessage({ id: 'pages.eeii.detail.ratings.answer' })}
              {props.autor_respuesta}:
            </Typography>
          </Box>
          <Box maxWidth="70%" display="flex" ml={1} mt={1}>
            <Typography component="span" variant="body2" color="textPrimary">
              {props.respuesta}
            </Typography>
            <Box ml={1} alignSelf="flex-end"></Box>
          </Box>
        </Box>
      ) : null}
    </>
  )

  return (
    <ListItem>
      <Box>
        <PrimaryItem />
        <SecondaryItem />
      </Box>
    </ListItem>
  )
}
