const GRID_ITEM_MID = {
  item: true,
  xs: 12,
  sm: 6,
}

export const INSTALLER_EDIT_CONFIG = () => ({
  items: [
    [
      { ref: 'NOMBRE', conf: GRID_ITEM_MID },
      { ref: 'APELLIDOS', conf: GRID_ITEM_MID },
      { ref: 'EMAIL', conf: GRID_ITEM_MID },
      { ref: 'TELEFONO', conf: GRID_ITEM_MID },
    ],
  ],
})

export const I18N_ROOT = 'pages.application.detail.dialogs.editClient.fields'

export const INSTALLER_EDIT_FIELDS = ({ intl, combos, onChange, ...restProps }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: I18N_ROOT + '.' + extension })
  const commonProps = {
    ...restProps,
    fontSize: 'small',
    onChange,
  }
  return {
    NOMBRE: {
      fromData: (data) => ({
        value: data['nombre'],
      }),
      name: 'nombre',
      componentType: 'text',
      title: txt('nombre.label'),
      required: true,
      ...commonProps,
    },
    APELLIDOS: {
      fromData: (data) => ({
        value: data['apellidos'],
      }),
      name: 'apellidos',
      componentType: 'text',
      title: txt('apellidos.label'),
      required: true,
      ...commonProps,
    },
    EMAIL: {
      fromData: (data) => ({
        value: data['email'],
      }),
      name: 'email',
      componentType: 'text',
      type: 'email',
      title: txt('email.label'),
      required: true,
      ...commonProps,
    },
    TELEFONO: {
      fromData: (data) => ({
        value: data['telefono'],
      }),
      name: 'telefono',
      componentType: 'text',
      title: txt('telefono.label'),
      required: true,
      ...commonProps,
    },
  }
}
