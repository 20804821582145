import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Checkbox, Tooltip } from "@material-ui/core";

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ConfirmationDialog, useModalContext } from '../../../../ui'
import feedback from '../../../../core/feedback'
import { useLoaderBasic } from "../../../loader/hooks";
import { useStuckCalls } from "../../hooks/stuck-calls.hook";

const ERROR_NO_ENVIABLE = 4205

export const SendToZeusCheckbox = ({ rowData: application }) => {
  const { getStuck } = useStuckCalls()
  const { formatMessage } = useIntl()
  const { putById } = useAjaxContext()
  const {
    actions: { open },
  } = useModalContext()

  const {
    state: { config },
    loadData,
  } = useLoaderBasic({ identifier: 'stuck', call: getStuck })

  const [checked, setChecked] = useState(application.enviable !== "unchecked")
  const [disabled, setDisabled] = useState(application.enviable === "disabled" || application.enviable === "forbbiden")
  const checkbox_tooltip = () => {
    if (application.enviable === "disabled") {
      return "La solicitud está siendo enviada"
    } else if (application.enviable === "forbbiden") {
      return "La solicitud no puede ser enviada a ZEUS"
    } else {
      return ""
    }
  }

  useEffect(() => {
    setChecked(application.enviable !== "unchecked")
    setDisabled(application.enviable === "disabled" || application.enviable === "forbbiden")
  }, [application.scr_id]) // eslint-disable-line react-hooks/exhaustive-deps

  const postCheckEnviarScr = useCallback((application, checked) => {
    const payload = {
      enviar_scr: checked,
    }
    putById('/scr/check_enviar_scr', application.scr_id, payload)
      .then((response) => {
        //refresh data
        loadData(config)
        setChecked((checked) => !checked)
      })
      .catch(({ response }) => {
        if (response.data?.code === ERROR_NO_ENVIABLE) {
          return feedback('error', 'Esta petición no puede ser enviada a ZEUS')
        }
        feedback('error', 'Error desconocido.')
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openModalCheckSentToZeus = (e) => {
    const checked = e.target.checked
    const TXT_ROOT = 'pages.application.detail.modal.checkboxSentToZeus.'
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: TXT_ROOT + 'title' }),
        text: formatMessage({ id: TXT_ROOT + 'text.' + (checked ? '1' : '2') }),
        yesText: formatMessage({ id: TXT_ROOT + 'yes' }),
        noText: formatMessage({ id: TXT_ROOT + 'no' }),
        yesAction: () => postCheckEnviarScr(application, checked),
      },
      type: 'centered',
    })
  }

  return (
    <Box display="flex" style={{ justifyContent: 'center', width: '100%' }} mt={1} mb={1}>
      {disabled && (<Tooltip title={checkbox_tooltip()} arrow>
        <div>
          <Checkbox
            {...{
              color: 'primary',
              checked: true,
              onChange: openModalCheckSentToZeus,
              disabled: true,
            }}
          />
        </div>
        </Tooltip>)}
      {!disabled && (<div>
        <Checkbox
          {...{
            color: 'primary',
            checked: checked,
            onChange: openModalCheckSentToZeus,
          }}
        />
      </div>)}
    </Box>
  )
}
