import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useCombos } from '../../../combos'
import { usePORequests } from '../../hooks'
import { PO_REQUEST_STATES } from '../../constants'
import { POReqFlowBtns } from '../buttons'
import { GridForm } from './grid-form.component'
import { INPUTS_CONFIG, FORM_CONFIG } from './po-requests-form.constants'
import { useModalContext } from '../../../../ui'
import { ValidateChangeDialog } from '../dialogs'

export const PORequestsForm = () => {
  const intl = useIntl()
  const combos = useCombos([])
  const { poRequest, validateChangedFields } = usePORequests()

  const [formState, setFormState] = useState(poRequest)

  const {
    actions: { open },
  } = useModalContext()

  const handleValidateChange = (name, title) => {
    open({
      Component: ValidateChangeDialog,
      data: {
        cambios_pendientes: formState.cambios_pendientes,
        requestId: formState.id,
        name,
        title,
        updateChangedFields,
        validateChangedFields,
      },
      maxWidth: 'sm',
    })
  }

  const updateChangedFields = () => {
    setFormState({ ...formState })
  }

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => null

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
  })

  const GRID = FORM_CONFIG({ intl })

  useEffect(() => {
    setFormState(poRequest)
  }, [poRequest])

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" justifyContent="flex-end" mt={2} mb={2}>
        <POReqFlowBtns />
      </Box>
      {poRequest.estado === PO_REQUEST_STATES.CANCELLED ? (
        <Alert severity="warning">
          <Typography variant="subtitle1">La petición está anulada</Typography>
          {poRequest.observaciones_anulacion ? (
            <Typography variant="body1">{poRequest.observaciones_anulacion}</Typography>
          ) : null}
        </Alert>
      ) : null}
      {poRequest.estado === PO_REQUEST_STATES.EXPIRED ? (
        <Alert severity="warning">
          <Typography variant="subtitle1">La petición está caducada</Typography>
        </Alert>
      ) : null}
      <GridForm {...{ grid: GRID, inputs: INPUTS, handleValidateChange }} />
    </form>
  )
}
