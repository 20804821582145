export const getAppsCountsEffect = (setData, getData, userId, accessAllowed, setLoading) => () => {
  if (accessAllowed)
    getData(`/solicitudes/contar_estados`, { params: { gestor_id: userId } })
      .then(({ data }) => {
        setData(data)
        setLoading(false)
      })
      .catch(
        ({ response }) => response && console.error('Error recuperando contadores', response.data)
      )
}
