import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useTokenGeneratorCalls = () => {
  const { post } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))

  const generateOneUseLink = useCallback(
    (userId, profileId = false, appId = false, info = {}) =>
      new Promise((resolve, reject) => {
        const body = {}
        if (profileId) {
          body.extra_1 = profileId
        }
        if (appId) {
          body.extra_2 = appId
        }
        body.extra_3 = JSON.stringify(info)
        post(`/gestor/generate_one_use_link/${userId}`, body)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                feedback('error', formatMessage({ id: 'pages.applications.feedback.6' }))
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    generateOneUseLink,
  }
}
