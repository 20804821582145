import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, IconButton, withStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { tableActionsStyles } from './table-actions.style'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const history = useHistory()
  const appId = props.rowData['id']

  const handleGoToDetail = () => history.push(`/applications/${appId}`)

  const appButtons = {
    detail: {
      onClick: handleGoToDetail,
      icon: <SearchIcon />,
    },
  }
  const availableActions = ['detail']

  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={appButtons[action].onClick}
          >
            {appButtons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})
