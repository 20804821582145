import { useCallback } from 'react'

import { DOCUMENT_ATTACHMENT_CONFIG, postDoc } from '../../documents'
import { useCertificatesCalls } from './certificates-calls.hook'

export const useCertificateLogics = () => {
  const { postCertificate } = useCertificatesCalls()

  const createCertificate = useCallback((endpoint, data, documentList) => {
    return new Promise(async (resolve, reject) => {
      const certificateData = { ...data }
      try {
        await Promise.all(
          documentList.map(async (file) => {
            const doc = data[file]
            if (!doc) {
              return
            }
            const payload = {
              tipo: DOCUMENT_ATTACHMENT_CONFIG[file].type,
              documento: doc.data.split(',')[1],
              nombre: doc.name.replace(/\.[^/.]+$/, ''),
              extension: doc.type,
              cliente_id: parseInt(data.client_id),
              solicitud_id: '' + data.solicitud_id,
            }
            if (data.presupuesto_id) {
              payload['presupuesto_id'] = '' + data.presupuesto_id
            }
            const documentResponse = await postDoc(payload)
            certificateData['d_' + file + '_id'] = documentResponse.id
            //... and, to reduce charge to the petition,
            certificateData[file] = null
            return
          })
        ).catch(() => {
          throw new Error('doc_failed')
        })
        const answer = await postCertificate(endpoint, certificateData).catch(() => {
          throw new Error('certificate_failed')
        })
        resolve(answer)
      } catch (err) {
        //TODO: Rollback
        reject(err)
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    createCertificate,
  }
}
