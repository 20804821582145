import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Box } from '@material-ui/core'
import { AccountCircleOutlined, AnnouncementOutlined, EuroOutlined } from '@material-ui/icons'
import { StarBorder, FindInPageOutlined } from '@material-ui/icons'

import { HeaderButtons, useModalContext } from '../../../../ui'
import { ReviewsDialog } from '../reviews/reviews-dialog.component'
import { DocumentsView } from '../documents-view'

const EEII_BUTTONS = (history, companyData, open) => ({
  APPLICATIONS: {
    id: 'link_applications',
    icon: <AnnouncementOutlined />,
    onClick: () =>
      history.push({
        pathname: '/applications',
        search: `?empresa=${companyData.id}`,
      }),
  },
  TARIFFS: {
    id: 'link_tariffs',
    icon: <EuroOutlined />,
    onClick: () =>
      history.push({
        pathname: '/tariffs',
        search: `?empresa_id=${companyData.id}`,
      }),
  },
  RATINGS: {
    id: 'ver_valoraciones',
    icon: <StarBorder />,
    onClick: () => {
      open({
        Component: ReviewsDialog,
        data: { companyId: companyData.id, history: history, isPrimaryCompany: true },
        type: 'responsive',
        closable: true,
        maxWidth: 'md',
      })
    },
  },
  COLLABORATORS: {
    id: 'link_collaborators',
    icon: <AccountCircleOutlined />,
    onClick: () =>
      history.push({
        pathname: '/collaborators',
        search: `?empresa=${companyData.id}`,
      }),
  },
  DOCUMENTS_VIEW: {
    id: 'documents_view',
    icon: <FindInPageOutlined />,
    onClick: () => {
      open({
        Component: DocumentsView,
        data: { company: companyData },
        type: 'responsive',
        closable: true,
        maxWidth: 'sm',
      })
    },
  },
})

const userButtons = (user) => {
  switch (user.role) {
    case 'gestor_ip':
      return ['DOCUMENTS_VIEW']
    case 'soporte_procesos':
      return ['APPLICATIONS', 'DOCUMENTS_VIEW']
    default:
      return ['APPLICATIONS', 'TARIFFS', 'RATINGS', 'DOCUMENTS_VIEW']
  }
}

export const EEIIActionButtons = ({ companyData }) => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  const user = useSelector((state) => state.global)
  const {
    actions: { open },
  } = useModalContext()
  const buttons = EEII_BUTTONS(history, companyData, open)
  const buttonChoice = userButtons(user)

  return (
    <Box mb={1}>
      <HeaderButtons
        iconButtons={buttonChoice.map((index) => {
          return {
            ...buttons[index],
            label: formatMessage({ id: `pages.eeii.headerButtons.${buttons[index].id}.title` }),
          }
        })}
      />
    </Box>
  )
}

export default EEIIActionButtons
