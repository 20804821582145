import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { requests } from '../../../core/ajax'
import { useApplicationUserPermitsContext } from '../../../pages/applications/contexts'
import { setApplications, setIsLoading } from '../slices'

export const useApplicationsCalls = () => {
  const { get, getById } = requests
  const dispatch = useDispatch()
  const { appUrl } = useApplicationUserPermitsContext()

  const getApplications = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        dispatch(setIsLoading(true))
        get(appUrl, { params: config })
          .then((response) => {
            dispatch(setApplications(config, response.data))
            resolve(response)
          })
          .catch(({ response }) => {
            dispatch(setIsLoading(false))
            return reject(response)
          })
      }),
    [appUrl] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getAllApplications = useCallback(
    (searchParams, totalElements) =>
      new Promise((resolve, reject) => {
        const payload = { ...searchParams, _num_x_pagina: totalElements }
        dispatch(setIsLoading(true))
        get(appUrl, { params: payload })
          .then(({ data }) => {
            dispatch(setIsLoading(false))
            resolve(data.solicitudes)
          })
          .catch(({ response }) => {
            dispatch(setIsLoading(false))
            return reject(response)
          })
      }),
    [appUrl] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getApplication = useCallback(
    (appId) =>
      new Promise((resolve, reject) => {
        getById('/solicitudes', `${appId}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      }),
    [appUrl] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getGestorGc = useCallback(
    (solicitudId) =>
      new Promise((resolve, reject) => {
        getById('/gestor/gran_consumo', `${solicitudId}`)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      }),
    [appUrl] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getApplications,
    getApplication,
    getAllApplications,
    getGestorGc,
  }
}
