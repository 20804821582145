import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FoldingPanel } from '../../../../ui'
import { TaskEventsTable } from '../events-table'

export const HistoryTaskPanel = ({ taskId }) => {
  const { formatMessage } = useIntl()

  return (
    <FoldingPanel
      isExpanded={false}
      title={formatMessage({ id: 'pages.application.detail.task_history.title' })}
    >
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <TaskEventsTable taskId={taskId} />
      </Box>
    </FoldingPanel>
  )
}
