import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { withStyles, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { FormFoldingPanel, PageDisplayer } from '../../../../ui'
import { ThemeButton } from '../../../../ui/theme-button'
import { useCombos } from '../../../combos/hooks'
import { SUPERVISION_FORM_STRUCTURE, SUPERVISION_DE_FORM_CONFIG } from '../../constants'
import { CANT_EDIT_COMMENTS_STATES } from '../../constants'
import { supervisionFormStyles } from './supervision-form.style'
import { HeaderButtons } from '../header'
import { useAppSupervisionCalls } from '../../hooks'
import feedback from '../../../../core/feedback'
import { prepareSelectedDEData } from '../../logics'

export const SupervisionDEForm = withStyles(supervisionFormStyles)(({ classes }) => {
  const intl = useIntl()
  const combos = useCombos([])
  const history = useHistory()
  const { search } = useLocation()
  const { getSupervisionAppDetail, putSupervisionDE } = useAppSupervisionCalls()
  const { appId } = useParams()

  const [loading, setLoading] = useState(true)
  const [saveLoading, setSaveLoading] = useState(false)
  const [application, setApplication] = useState({})
  const [formState, setFormState] = useState({})

  const canEdit =
    application.estado_certificado !== 'Anulado' &&
    !CANT_EDIT_COMMENTS_STATES[application.estado_solicitud]

  useEffect(() => {
    getSupervisionAppDetail(appId).then(({ data }) => {
      setApplication(prepareSelectedDEData(data['solicitud']))
      setFormState(prepareSelectedDEData(data['solicitud']))

      setLoading(false)
    })
  }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeForm = useCallback(
    (event) => {
      let { name, value } = event.target
      if (name === 'estado_facturacion') {
        value = value === 'Tramitada' ? true : false
      }
      if (name === 'observaciones_sspp') {
        setFormState((formState) => ({
          ...formState,
          facturacion: { ...formState.facturacion, [name]: value },
        }))
      }
      setFormState((formState) => ({
        ...formState,
        [name]: value,
      }))
    },
    [setFormState]
  )

  const goToSupervisionTable = () => {
    history.push({
      pathname: '/supervision',
      search: search,
    })
  }
  const handleSubmitForm = (e) => {
    e.preventDefault()
    setSaveLoading(true)
    const payload = {
      tramitado: formState.estado_facturacion,
      observaciones_sspp: formState?.facturacion?.observaciones_sspp,
    }

    putSupervisionDE(application.facturacion_id, payload)
      .then(() => {
        goToSupervisionTable()
        feedback('success', intl.formatMessage({ id: 'pages.supervision.edit.success' }))
      })
      .catch(() => {
        feedback('error', intl.formatMessage({ id: 'pages.supervision.edit.warning' }))
        setFormState(application)
        setSaveLoading(false)
      })
  }

  const getCustomBlock = (block) => {
    let customBlock = undefined
    if (
      block.panel === 'empresa_instaladora' &&
      (application['subtipo_solicitud'] === 'sag' ||
        application['subtipo_solicitud'] === 'pdte_normalizar_direccion')
    ) {
      customBlock = deleteFieldOfBlock(block, 'NUM_CEDULA_HABITABILIDAD')
    }
    if (block.panel === 'informacion' && application['subtipo_solicitud'] === 'scr') {
      customBlock = deleteFieldOfBlock(block, 'PUNTOS_SUMINISTRO_POTENCIALES')
      customBlock = deleteFieldOfBlock(customBlock, 'PUNTOS_SUMINISTRO_INTERESADOS')
    }
    return customBlock
  }

  const deleteFieldOfBlock = (block, fieldToDelete) => {
    const items = block.items.map((fieldGroup) => {
      const newfieldGroup = fieldGroup.filter((field) => field.ref !== fieldToDelete)
      return newfieldGroup
    })
    return { ...block, items }
  }

  const fieldList = useMemo(() => {
    return SUPERVISION_DE_FORM_CONFIG({
      intl,
      combos,
      onChange: handleChangeForm,
      application,
    })
  }, [application, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PageDisplayer
      loading={loading}
      handleGoBack={() => goToSupervisionTable()}
      textGoBack={intl.formatMessage({ id: 'pages.supervision.edit.back' })}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <HeaderButtons application={application} />
      </Box>
      <form onSubmit={handleSubmitForm} className={classes.fullForm}>
        {canEdit ? (
          <Box display="flex" flexDirection="row-reverse" mt={1}>
            <ThemeButton color="primary" size="small" loading={saveLoading} type="submit">
              {intl.formatMessage({
                id: 'pages.supervision.edit.button.save.label',
              })}
            </ThemeButton>
          </Box>
        ) : null}

        {SUPERVISION_FORM_STRUCTURE({ intl, DE: true }).map((b) => {
          const customBlock = getCustomBlock(b)
          const block = customBlock ? customBlock : b
          return (
            <div key={block.title}>
              <FormFoldingPanel {...{ data: formState, combos, intl, block, fieldList }} />
            </div>
          )
        })}
      </form>
    </PageDisplayer>
  )
})

export default SupervisionDEForm
