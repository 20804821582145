import React, { createContext, useContext } from 'react'
import { useNonCaptablesValidationReducer } from '../hooks/non-captables-validation-reducer.hook'

const NonCaptablesValidationContext = createContext()

export const NonCaptablesValidationContextProvider = ({ children }) => {
  const reducer = useNonCaptablesValidationReducer()
  return (
    <NonCaptablesValidationContext.Provider value={reducer}>
      {children}
    </NonCaptablesValidationContext.Provider>
  )
}

export const useNonCaptablesValidationContext = () => useContext(NonCaptablesValidationContext)
