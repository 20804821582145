import { createSlice } from '@reduxjs/toolkit'

const LOAD_STARTER = {
  data: [],
  config: {},
  loading: false,
  msLoaded: false,
  error: false,
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {},
  reducers: {
    blockLoad: (state, { payload }) => {
      return {
        ...state,
        [payload.identifier]: {
          ...LOAD_STARTER,
          ...state[payload.identifier],
          loading: true,
          error: false,
          config: payload.config,
        },
      }
    },
    blockFail: (state, { payload }) => {
      return {
        ...state,
        [payload.identifier]: {
          ...state[payload.identifier],
          loading: false,
          msLoaded: true,
          error: payload.error,
        },
      }
    },
    blockData: (state, { payload }) => {
      return {
        ...state,
        [payload.identifier]: {
          ...state[payload.identifier],
          loading: false,
          msLoaded: Date.now(),
          data: payload.data,
        },
      }
    },
    blockExtra: (state, { payload }) => {
      return {
        ...state,
        [payload.identifier]: {
          ...state[payload.identifier],
          ...payload.extra,
        },
      }
    },
    reset: () => {
      return {}
    },
  },
})

export const loaderReducer = loaderSlice.reducer

export const loaderActions = loaderSlice.actions
