import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { BasicListItem } from '../../../../ui'
import { useIPContext } from '../../contexts'

export const IPNonconfomityView = () => {
  const { ip } = useIPContext()
  const addresses = ip.direcciones

  return (
    <Box>
      <Box mt={1} mb={4}>
        <Alert severity="warning">
          <AlertTitle>Observaciones del instalador:</AlertTitle>
          {ip.observaciones || 'Sin observaciones'}
        </Alert>
      </Box>
      <Typography variant="h6" color="primary" paragraph>
        Direcciones:
      </Typography>
      {addresses.map((address, i) => (
        <>
          <Typography variant="subtitle1" gutterBottom>
            {address.cod_derivacion}
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            {`${address['via'] || ''} ${address['numero'] || ''}, ${address['poblacion'] || ''} (${
              address['codigopostal'] || ''
            }), ${address['provincia'] || ''}` +
              '\n' +
              `${address['bloque'] ? ', Portal: ' + address['bloque'] : ''}` +
              `${address['escalera'] ? ', Escalera: ' + address['escalera'] : ''}`}
          </Typography>
          <BasicListItem
            {...{ label: 'Nº CUPS', value: address['count_cups'] || 'No hay información' }}
          />
          <BasicListItem {...{ label: 'Tipo de gas', value: address['tipo_gas'] }} />
        </>
      ))}
    </Box>
  )
}
