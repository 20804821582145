import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { NOTICES_TABLE_COLUMNS } from "../../constants/notices-table.constants";
import { noticesTableStyles } from "./notices-table.styles";
import { MTableCell } from "material-table";
import { Paper, Tooltip } from "@material-ui/core";
import { TableUrlPagination } from "../../../../ui";
import { noticesDataPreparation } from "../../logics";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useNotificationsCalls } from "../../hooks";
import AccessTime from "@material-ui/icons/AccessTime";
import { useHistory, useLocation } from "react-router-dom";
import { PopupEditDialogComponent } from "../../dialogs";


const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const NoticesTableComponent = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const noticesLoader = useSelector((state) => state.loader.notices)
  const combos = useSelector((state) => state.combos)
  const {getAvisoById,updateAviso,deleteNotification} = useNotificationsCalls()

  const data = noticesLoader?.data?.registros || []
  const totalEntries = noticesLoader?.data?.total_registros
  const search = noticesLoader?.config?.params
  const loading = noticesLoader?.loading

  const [selectedAviso, setSelectedAviso] = useState({})
  const [openDialog, setOpenDialog] = useState(false);

  const history = useHistory()
  const location = useLocation()
  const currentSearch = new URLSearchParams(location.search)

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    return NOTICES_TABLE_COLUMNS.map((column) => {
      return {
        ...column,
        title: formatMessage({ id: `pages.notices.table.columns.${column.field}.title` }),
        cellStyle: {
          ...noticesTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }

    })
  },[combos])

  //Assembling and adjusting data
  const processedData = useMemo(() => {
    return noticesDataPreparation(data, { intl })
  }, [data])

  const finalTableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: noticesTableStyles.header,
      draggable: false,
    }),
    []
  )

  const getFormattedCell = (props) => {
    const { columnDef, value } = props
    const { field } = columnDef
    if (field === 'state') {
      //Format state as icon
      if (value === 'active') return <Tooltip title="Activa"><CheckIcon style={{ color: "green" }}/></Tooltip>
      else if (value === 'inactive') return <Tooltip title="Finalizada"><CloseIcon style={{color: "red"}} /></Tooltip>
      else if (value === 'ready') return <Tooltip title="Programada"><AccessTime color={"secondary"} /></Tooltip>
    }
    return props.children
  }

  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        if (props.columnDef.field === 'state') {
          return (
            <MTableCell {...props} style={{ padding: 0 }} value="">
              {getFormattedCell(props)}
            </MTableCell>
          )
        }
        else {
          return(
            <MTableCell {...props} style={{ padding: 0 }} >
              {getFormattedCell(props)}
            </MTableCell>
          )
        }
      },
    }), [])

  const openEditDialog = (event, rowData) => {
    getAvisoById(rowData.id).then(
      (response) => {
        const data = response.data
        const aviso = {
          id: data.id,
          title: data.title,
          text: data.body,
          links: data.links ? data.links : [],
          tags: data.tags ? data.tags : [],
          profilesSelected: data.target_users ? data.target_users : [],
          startDate: data.start_date,
          endDate: data.end_date,
          created_at: data.creation_date,
          users_reached: data.users_reached,
          profilesList: combos['roles_all'] && combos['roles_all'].data,
          ccaaList: combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data,
          ccaa: data.ccaa ? data.ccaa : [],
          tagsList: combos['tags_avisos'] && combos['tags_avisos'].data
        }
        setSelectedAviso(aviso)
        setOpenDialog(true)
      })
  }

  const closeDialog = () => {
    setOpenDialog(false)
    setSelectedAviso({})
  }

  const updateNotice = (notice) => {
    updateAviso(selectedAviso.id, notice).then(
      () => {
        closeDialog()
        //Refresh table
        currentSearch.set('updated', Date.now().toString())
        history.replace({
          search: '?' + currentSearch.toString(),
        })
        currentSearch.delete('updated')
        history.replace({
          search: '?' + currentSearch.toString(),
        })
      }
    )
  }

  const deleteNotice = () => {
    deleteNotification(selectedAviso.id).then(
      () => {
        closeDialog()
        //Refresh table
        currentSearch.set('updated', Date.now().toString())
        history.replace({
          search: '?' + currentSearch.toString(),
        })
        currentSearch.delete('updated')
        history.replace({
          search: '?' + currentSearch.toString(),
        })
      }
    )
  }


  return (
    <>
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={finalTableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onRowClick={openEditDialog}
    />
      <PopupEditDialogComponent open={openDialog} onClose={closeDialog} onEdit={updateNotice} onDelete={deleteNotice} {...selectedAviso}/>
    </>
  )
}