import React, { useState, useEffect } from 'react'
import { withStyles, Box, CircularProgress } from '@material-ui/core'
import CropOriginalIcon from '@material-ui/icons/CropOriginal'

import { useAjaxContext } from '../../../../core/ajax'
import { companyPanelStyles } from './company-panel.styles'
import CompanyGuild from './company-guild.component'

export const CompanyLogo = withStyles(companyPanelStyles)(
  ({ classes, hasPublicProfile, isPrimaryCompany, model }) => {
    const { getById } = useAjaxContext()

    const [loadingImage, setLoadingImage] = useState(false)
    const [image, setImage] = useState(null)

    const getLogoImage = (id) => {
      setLoadingImage(true)
      getById('/documento', id)
        .then(({ data }) => {
          setImage(data)
          setLoadingImage(false)
        })
        .catch(() => {
          console.debug('Error fetching logo')
          setLoadingImage(false)
        })
    }

    useEffect(() => {
      setImage(null)
      if (model['logo_id']) getLogoImage(model['logo_id'])
    }, [model.logo_id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box display="flex" flexDirection="column">
        {hasPublicProfile === '01' ? (
          <Box className={classes.logoContainer} margin={3} alignSelf="center">
            {loadingImage ? (
              <CircularProgress size={40} />
            ) : (
              <div>
                {image ? (
                  <img
                    src={
                      image['data']
                        ? image['data']
                        : image['documento']
                        ? `data:image/${image['extension']};base64,${
                            image['documento'].split("'")[1]
                          }`
                        : null
                    }
                    alt="error"
                    style={{ width: '100%', objectFit: 'contain' }}
                  />
                ) : (
                  <CropOriginalIcon style={{ fontSize: 80 }} />
                )}
              </div>
            )}
          </Box>
        ) : null}
        {isPrimaryCompany ? null : (
          <Box style={{ alignSelf: 'center', paddingBottom: '2em' }}>
            <CompanyGuild empresa_id={model.id} model={model} />
          </Box>
        )}
      </Box>
    )
  }
)
