import React from 'react'
import { useIntl } from 'react-intl'
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const AdvanceDialog = ({ close, application, callback }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box mb={2} textAlign="center">
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.detail.dialog.advance' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {formatMessage({ id: 'pages.application.detail.dialog.explain' })}
        </Typography>
        {/* {application.tipo_solicitud !== 'instalador' && !application.instalador?.id ? (
          <Alert severity="warning">
            <AlertTitle>Alerta: </AlertTitle>
            <Typography variant="body2" color="textSecondary">
              Esta petición no tiene ningún instalador vinculado. Si el cliente no ha recibido
              ninguna invitación a registrarse todavía, podría quedar encallada indefinidamente en
              el nuevo estado. ¿Está seguro que desea continuar?
            </Typography>
          </Alert>
        ) : null} */}
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <ThemeButton color="default" onClick={close} labelM="lg" style={{ marginRight: '1em' }}>
            {formatMessage({ id: 'global.return' })}
          </ThemeButton>
          <ThemeButton
            color="primary"
            onClick={() => callback(application.id).then(close).catch(close)}
            labelM="lg"
          >
            {formatMessage({ id: 'global.continue' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
