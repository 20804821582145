import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { ReasingGestor } from '../../dialogs'

export const ReasingBtn = ({ selectedRows = [], table, setTable }) => {
  const intl = useIntl()

  const {
    actions: { open },
  } = useModalContext()

  const handleReAsing = () => {
    open({
      Component: ReasingGestor,
      data: { selectedRows, table, setTable },
      type: 'responsive',
      closable: true,
      maxWidth: 'xs',
    })
  }

  return (
    <>
      <ThemeButton
        color="primary"
        labelM="sm"
        onClick={handleReAsing}
        style={{ marginRight: '1em' }}
        disabled={!selectedRows.length}
      >
        {intl.formatMessage({ id: 'global.Reasing' })} ({selectedRows.length})
      </ThemeButton>
    </>
  )
}
