import React from 'react'
import { Box, IconButton, withStyles } from '@material-ui/core'
import MailIcon from '@material-ui/icons/Mail'
import EditIcon from '@material-ui/icons/Edit'

import { tableActionsStyles } from './table-actions.style'
import { useInstallerCalls, useInstallerDialogs } from '../../hooks'
import { useLoaderBasic } from '../../../loader/hooks'

export const TableActions = withStyles(tableActionsStyles)((props) => {
  const { resendValidation, updateInstallerInfo, getInstallers } = useInstallerCalls()
  const { warnValidationMailSuccess, editInstallerDialog } = useInstallerDialogs()
  const {
    state: { config },
    loadData,
  } = useLoaderBasic({ identifier: 'installers', call: getInstallers })

  const handleEdit = () => {
    editInstallerDialog(props.rowData, (id, payload) =>
      updateInstallerInfo(id, payload).then(() => loadData(config))
    )
  }

  const handleForward = () => {
    resendValidation(props.rowData['email']).then(warnValidationMailSuccess)
  }

  const buttons = {
    edit: {
      onClick: handleEdit,
      icon: <EditIcon />,
    },
    forward: {
      onClick: handleForward,
      icon: <MailIcon />,
    },
  }

  const availableActions = []
  if (!props.rowData['confirmed']) {
    availableActions.push('edit')
  }
  if (props.rowData['oauth2_id'] && !props.rowData['confirmed']) {
    availableActions.push('forward')
  }

  return (
    <Box className={props.classes.actionRow} p={0}>
      {availableActions.map((action) => {
        return (
          <IconButton
            className={props.classes.actionButton}
            key={action}
            size="small"
            onClick={buttons[action].onClick}
          >
            {buttons[action].icon}
          </IconButton>
        )
      })}
    </Box>
  )
})
