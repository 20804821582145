import React from 'react'

import { SirContext } from '../../features/sir'
import { SirRouter } from './routers'

export const SirPage = (props) => {
  return (
    <SirContext>
      <SirRouter {...props} />
    </SirContext>
  )
}
