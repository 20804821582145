export const PUBLIC_OFFER_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: 300 },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: 130 },
  // Comunidad Autónoma
  { field: 'ccaa', align: 'left', width: 130 },
  // Mercados
  { field: 'mercados', align: 'left', width: 80 },
  // Productos
  { field: 'productos', align: 'left', width: 200 },
  { field: 'fechainicio', align: 'left', type: "date", width: '150px', exportType: 'date_iso' },
  { field: 'fechafin', align: 'left', type: "date", width: '150px', exportType: 'date_iso'},
  // Retribución Económica
  { field: 'descuento', align: 'center', width: 130 },
  // Ingreso a cuenta
  // { field: 'ingreso', align: 'right', width: 100 },
  // Ingreso a cuenta
  // {
  //   field: 'financiacion',
  //   align: 'center',
  //   width: 100,
  //   render: (rowData) => (rowData.financiacion ? `${rowData.meses_financiacion} meses` : 'No'),
  // },
  //origen de la Oferta Pública
  { field: 'origen', align: 'left', width: 150 },

  { field: 'fecha_publicacion', align: 'left', type: "date", width: '150px', exportType: 'date_iso' },
  { field: 'fecha_prorrogada', align: 'left', type: "date", width: '150px', exportType: 'date_iso'},
  // Prioridad de la campaña
  // { field: 'prioridad', align: 'left', width: 150 },
  // Activa
  { field: 'activa', disableClick: true, align: 'center', width: '100px', exportType: 'boolean' },
  { field: 'actions', disableClick: true, align: 'center', width: '100px', export: false },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
