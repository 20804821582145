import React from 'react'
import { withStyles, FormControl, Select, MenuItem, ListItemText, Icon } from '@material-ui/core'
import { Tooltip, Checkbox, Typography, Box } from '@material-ui/core'

import { selectInputStyles } from './select-input.styles'

export const SelectInput = React.memo(
  withStyles(selectInputStyles)(
    ({ classes, values = [], multiple = false, readOnly = false, selectAll = false, ...props }) => {
      const typographyVariant = props.fontSize === 'small' ? '2' : '1'
      const value = props.value || (multiple ? [] : '')
      const getValue = () => {
        if (multiple) {
          let optionsSelected = []
          value.forEach((v) => {
            const optionSelected = values.find((item) => item.key === v)
            if (optionSelected) optionsSelected.push(optionSelected.value)
          })
          return optionsSelected.join(', ')
        } else {
          let optionSelected
          let valueSelected = '--'
          if (value && values) {
            optionSelected = values.find((option) => option.key === value)
            valueSelected = optionSelected ? optionSelected.value : '--'
          }
          return valueSelected
        }
      }

      const selectValues = () => {
        return values.map((item, i) => (
          <MenuItem key={i} value={item.key} disabled={item.disabled}>
            {multiple ? (
              <>
                <Checkbox checked={value.indexOf(item.key) > -1} />
                <ListItemText primary={item.value} />
              </>
            ) : (
              item.value
            )}
          </MenuItem>
        ))
      }

      const multipleProps =
        multiple && values.length
          ? {
              renderValue: (selected) =>
                selected
                  .map((item) => {
                    let found = values.find((value) => value.key === item)
                    return found ? found.value : 'Unknown'
                  })
                  .join(', '),
            }
          : {}

      const allSelected =
        multiple && values.length && value.length ? values.length === value.length : false

      const changeHandle = (e) => {
        if (selectAll && e.target.value.includes('all')) {
          return props.onChange({
            ...e,
            target: {
              ...e.target,
              value: allSelected ? [] : values.map((el) => el.key),
            },
          })
        }
        return props.onChange(e)
      }

      return (
        <FormControl
          variant="outlined"
          className={classes.root}
          required={props.required}
          disabled={props.disabled}
        >
          <Box display="flex" flexDirection="horizontal">
            {props.title && (
              <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
                {props.title}
              </Typography>
            )}
            {props.tooltip && (
              <span style={{ fontSize: 16, marginLeft: 4 }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
                      {props.tooltip}
                    </Typography>
                  }
                >
                  <Icon fontSize="inherit">help_outline</Icon>
                </Tooltip>
              </span>
            )}
          </Box>
          {readOnly ? (
            <Typography variant={`body${typographyVariant}`} color="textSecondary">
              {getValue()}
            </Typography>
          ) : (
            <Select
              autoWidth
              className={classes.input}
              name={props.name}
              value={value}
              multiple={multiple}
              onChange={changeHandle}
              MenuProps={{ variant: 'menu' }}
              {...multipleProps}
            >
              {selectAll && multiple ? (
                <MenuItem divider value="all">
                  <Checkbox checked={allSelected ? true : false}></Checkbox>
                  <ListItemText>Seleccionar todos</ListItemText>
                </MenuItem>
              ) : null}
              {selectValues()}
            </Select>
          )}
        </FormControl>
      )
    }
  )
)
