export const supervisionDashboardStyles = (theme) => ({
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '50px 40px',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 20px',
    },
  },
  actionsButton: {
    borderRadius: '50%',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.common.white,
  },
})
