import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ExportToXLSBtn, FilterBox } from '../../../../ui'
import { GUILDS_TABLE_COLUMNS, GUILDS_TABLE_FILTERS } from '../../constants/guilds-table.constants'
import { useGuildCalls } from '../../hooks/guilds-calls.hook'
import { guildDataPreparation } from '../../logics'

export const GuildsHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getGuilds } = useGuildCalls()
  const role = useSelector((state) => state.global.role)
  const guildsLoader = useSelector((state) => state.loader.guilds)

  const selection = guildsLoader?.selection
  const search = guildsLoader?.config?.params

  const filters = GUILDS_TABLE_FILTERS

  const buttons = (
    <ExportToXLSBtn
      selectedRows={selection}
      columnsExport={GUILDS_TABLE_COLUMNS}
      getAllData={() =>
        new Promise((resolve, reject) => {
          getGuilds({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(guildDataPreparation(data.gremios, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }
      titleRoot="gremios_"
      headerNamespace="guilds"
    />
  )

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.guilds.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>{role === 'e_commerce' ? buttons : null}</FilterBox>
    </>
  )
}
