import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { withStyles, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import { FormFoldingPanel, PageDisplayer } from '../../../../ui'
import { ThemeButton } from '../../../../ui/theme-button'
import { useCombos } from '../../../combos/hooks'
import {
  NON_CAPTABLES_VALIDATION_FORM_CONFIG,
  NON_CAPTABLES_VALIDATION_FORM_STRUCTURE,
} from '../../constants'
import { NORMALIZATION_PENDING_STATE, PROCESSED_STATE, NOT_PROCESSED_STATES } from '../../constants'
import { CANT_EDIT_COMMENTS_STATES } from '../../constants'
import { useNonCaptablesValidationContext } from '../../contexts'
import { nonCaptablesValidationFormStyles } from './non-captables-validation-form.style'
import { HeaderButtons } from '../header'
import { useAppNonCaptablesValidationCalls } from '../../hooks'
import feedback from '../../../../core/feedback'

export const NonCaptablesValidationForm = withStyles(nonCaptablesValidationFormStyles)(
  ({ classes }) => {
    const intl = useIntl()
    const combos = useCombos([])
    const history = useHistory()
    const { search } = useLocation()
    const { nonCaptablesValidation } = useNonCaptablesValidationContext()
    const { getNonCaptablesValidationAppDetail, manualNormalization } =
      useAppNonCaptablesValidationCalls()
    const { appId } = useParams()

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [application, setApplication] = useState({})
    const [formState, setFormState] = useState({})

    useEffect(() => {
      getNonCaptablesValidationAppDetail(appId).then(({ data }) => {
        const stateSSPP = getCorrectProcessedState(data)
        setApplication({ ...data, estado: stateSSPP })
        setFormState({ ...data, estado: stateSSPP })
        setLoading(false)
      })
    }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCorrectProcessedState = (data) => {
      if (!NOT_PROCESSED_STATES[data.estado]) {
        if (data.subtipo_solicitud === 'pdte_normalizar_direccion') {
          return NORMALIZATION_PENDING_STATE
        } else {
          return PROCESSED_STATE
        }
      } else {
        return data.estado
      }
    }

    const handleChangeForm = useCallback(
      (event) => {
        const { name, value } = event.target
        setFormState((formState) => ({
          ...formState,
          [name]: value,
        }))
      },
      [setFormState]
    )

    const goToNonCaptablesValidationTable = () => {
      history.push({
        pathname: '/non-captables-validation',
        search: search,
      })
    }

    const handleSubmitForm = (e) => {
      e.preventDefault()
      if (
        application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
        formState['estado'] === NORMALIZATION_PENDING_STATE &&
        !(formState['cups'] || formState['cidi'])
      ) {
        return feedback(
          'error',
          'Es necesario indicar un CUPS o Cidi para completar la normalización'
        )
      }
      setSaveLoading(true)
      const payload = {
        solicitud_id: parseInt(appId),
        codigo_solicitud: application.solicitud_id,
        estado: formState.estado,
        cod_solicitud_zeus: formState.cod_solicitud_zeus,
        cups: formState.cups,
        cidi: formState.cidi,
        mercado: formState.mercado,
        observaciones_cambio_estado: formState.observaciones_cambio_estado,
      }
      nonCaptablesValidation(formState.id, payload)
        .then(() => {
          goToNonCaptablesValidationTable()
        })
        .catch(() => {
          const stateSSPP = getCorrectProcessedState(application)
          setFormState({ ...application, estado: stateSSPP })
          setSaveLoading(false)
        })
    }

    const getCustomBlock = (block) => {
      let customBlock = undefined
      if (
        block.panel === 'empresa_instaladora' &&
        (application['subtipo_solicitud'] === 'sag' ||
          application['subtipo_solicitud'] === 'pdte_normalizar_direccion')
      ) {
        customBlock = deleteFieldOfBlock(block, 'NUM_CEDULA_HABITABILIDAD')
      }
      if (block.panel === 'informacion' && application['subtipo_solicitud'] === 'scr') {
        customBlock = deleteFieldOfBlock(block, 'PUNTOS_SUMINISTRO_POTENCIALES')
        customBlock = deleteFieldOfBlock(customBlock, 'PUNTOS_SUMINISTRO_INTERESADOS')
      }
      return customBlock
    }

    const deleteFieldOfBlock = (block, fieldToDelete) => {
      const items = block.items.map((fieldGroup) => {
        const newfieldGroup = fieldGroup.filter((field) => field.ref !== fieldToDelete)
        return newfieldGroup
      })
      return { ...block, items }
    }

    const handleDummy = () => {
      if (!formState.cups && !formState.cidi) {
        return feedback('error', 'Necesitas un cups')
      }
      manualNormalization(formState.cups, formState.cidi)
    }

    const fieldList = useMemo(() => {
      return NON_CAPTABLES_VALIDATION_FORM_CONFIG({
        intl,
        combos,
        onChange: handleChangeForm,
        application,
      })
    }, [application, combos]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <PageDisplayer
        loading={loading}
        handleGoBack={() => goToNonCaptablesValidationTable()}
        textGoBack={intl.formatMessage({ id: 'pages.supervision.edit.back' })}
      >
        {application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
        (process.env.REACT_APP_ENVIRONMENT === 'Local' ||
          process.env.REACT_APP_ENVIRONMENT === 'Development') ? (
          <div className={classes.dummyButton}>
            <ThemeButton onClick={handleDummy}>Normalización manual</ThemeButton>
          </div>
        ) : null}
        <Box display="flex" justifyContent="space-between" flexWrap="wrap">
          <HeaderButtons application={application} />
        </Box>
        <form onSubmit={handleSubmitForm} className={classes.fullForm}>
          {!CANT_EDIT_COMMENTS_STATES[application.estado] ? (
            <Box display="flex" flexDirection="row-reverse" mt={1}>
              <ThemeButton color="primary" size="small" loading={saveLoading} type="submit">
                {intl.formatMessage({
                  id: 'pages.supervision.edit.button.save.label',
                })}
              </ThemeButton>
            </Box>
          ) : null}
          {NON_CAPTABLES_VALIDATION_FORM_STRUCTURE({ intl }).map((b) => {
            const customBlock = getCustomBlock(b)
            const block = customBlock ? customBlock : b
            return (
              <div key={block.title}>
                <FormFoldingPanel {...{ data: formState, combos, intl, block, fieldList }} />
              </div>
            )
          })}
        </form>
      </PageDisplayer>
    )
  }
)
