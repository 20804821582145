import React, { useState } from 'react'
import { withStyles } from '@material-ui/core'

import { encodeToBase64 } from '../../../core/utils'
import { useAjaxContext } from '../../../core/ajax'
import { ThemeButton } from '../../../ui'
import { DEFAULT_FILE_SIZE, API_ENDPOINT } from '../constants'
import { fileUploaderStyles } from './file-uploader.styles'
import feedback from '../../../core/feedback'

export const FileUploader = withStyles(fileUploaderStyles)(
  ({
    classes,
    label,
    accept,
    onSuccess,
    onError,
    disabled,
    maxSize = DEFAULT_FILE_SIZE,
    type = 'no_definido',
    applicationId,
    budgetId,
    clientId,
    ...props
  }) => {
    const { post } = useAjaxContext()

    const [loading, setLoading] = useState(false)

    const handleChangeUpload = async (e) => {
      setLoading(true)
      const file = e.target.files[0]
      if (file && file.size > maxSize) {
        setLoading(false)
        return onError({ mssg: `Excede el tamaño máximo. Tamaño máximo: ${maxSize / 1000000} MB` })
      } else if (file && !accept.some((acceptedType) => acceptedType === file.type)) {
        setLoading(false)
        return onError({
          mssg: `El formato no es correcto. Solo se aceptan los formatos: ${accept.map(
            (format) => format.split('/')[1]
          )}`,
        })
      }
      const mockErrorData = {
        mssg: 'Problema con la subida del fichero',
      }

      postDocument(file)
        .then(({ data }) => {
          setLoading(false)
          feedback('success', 'Documento subido')
          return onSuccess(data)
        })
        .catch(({ response }) => {
          setLoading(false)
          return onError(response ? response.data : mockErrorData)
        })
    }

    const postDocument = async (file) => {
      try {
        const base64File = await encodeToBase64(file)
        let payload = {
          documento: base64File,
          nombre: file.name.replace(/\.[^/.]+$/, ''),
          tipo: type,
          extension: file.type.split('/')[1],
          cliente_id: parseInt(clientId),
          solicitud_id: applicationId.toString(),
        }
        if (budgetId) payload = { ...payload, presupuesto_id: budgetId.toString() }
        return post(API_ENDPOINT, payload)
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
    }

    return (
      <div>
        <input
          id={`file-upload-${props.id}`}
          type="file"
          accept={accept.join(',')}
          className={classes.input}
          onChange={handleChangeUpload}
          disabled={disabled}
        />
        <label htmlFor={`file-upload-${props.id}`}>
          <ThemeButton component="span" {...{ loading, disabled, ...props }}>
            {label}
          </ThemeButton>
        </label>
      </div>
    )
  }
)
