export const PO_REQ_TABLE_COLUMNS = [
  {
    field: 'cambios_pendientes',
    width: 80,
    align: 'center',
    sorting: true,
  },
  {
    field: 'estado',
    align: 'left',
    width: 180,
    comboId: 'estados_peticion_op',
  },
  {
    field: 'fecha_peticion',
    align: 'left',
    width: 150,
    type: 'date',
  },
  {
    field: 'fecha_adhesion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'sociedad',
    align: 'left',
    width: 200,
  },
  {
    field: 'empresa_ca',
    align: 'left',
    width: 200,
  },
  {
    field: 'empresa_nif',
    align: 'left',
    width: 120,
  },
  {
    field: 'cod_sap',
    align: 'left',
    width: 120,
  },
  {
    field: 'empresa_telefono',
    align: 'left',
    width: 120,
  },
  {
    field: 'empresa_email',
    align: 'left',
    width: 200,
  },
  {
    field: 'nombre_campana',
    align: 'left',
    width: 120,
  },
  {
    field: 'gremio_nombre',
    align: 'left',
    width: 120,
  },
  {
    field: 'gremio_nif',
    align: 'left',
    width: 120,
  },
]

export const PO_REQ_EXPORT_COLUMNS = [
  { field: 'estado' },
  { field: 'fecha_peticion', exportType: 'date' },
  { field: 'fecha_adhesion', exportType: 'date' },
  { field: 'empresa_ca' },
  { field: 'empresa_nif' },
  { field: 'cod_sap' },
  { field: 'empresa_telefono' },
  { field: 'empresa_email' },
  { field: 'nombre_campana' },
  { field: 'gremio_nombre' },
  { field: 'gremio_nif' },
]

export const DEFAULT_QUERY = { _pagina: 1, _num_x_pagina: 10 }
