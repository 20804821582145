import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton, TextInput } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'

export const ValidateCupsDialog = ({ close, application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { put } = requests

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })
  const warningPlurifamiliar =
    !application.certificado_IRG2?.id && application.tipo_vivienda === 'Plurifamiliar'

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const data = { ...formState }
    const id = application['id']

    //hacer la llamada para validar la solicitud e informar el codigo Nap
    put(`/solicitudes/validar/${id}/pasar_contratable`, data)
      .then(() => {
        setLoading(false)
        getApplication(application['id'])
        feedback('success', 'Se ha validado que la acometida esta conectada')
        close()
      })
      .catch(() => {
        console.error('Error canceling applicaiton')
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <Box mb={2}>
            {warningPlurifamiliar ? (
              <Alert severity="warning">{formatMessage({ id: 'pages.detail.not.IRG2' })}</Alert>
            ) : null}
          </Box>

          <TextInput
            name="cod_nap"
            title={formatMessage({ id: 'pages.application.detail.dialogs.codigo_nap.title' })}
            value={formState['cod_nap']}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="space-around">
          <Box>
            <ThemeButton color="default" labelM="sm" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            Cups
            labelM="sm"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
