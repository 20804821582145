import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogTitle, DialogContent, Box, Typography } from '@material-ui/core';
import { TextInput, ThemeButton } from '../../../../ui';

export const EditSAPCodeDialog = ({ open, close, currentSAPCode, updateSAPCode }) => {
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [sapCode, setSapCode] = useState(currentSAPCode);

  useEffect(() => {
    setSapCode(currentSAPCode);
  }, [currentSAPCode]);

  const handleChange = (e) => setSapCode(e.target.value);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateSAPCode({ cod_sap: sapCode });
      setLoading(false);
      close();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <Box p={2}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h4" color="primary">
              {formatMessage({ id: 'pages.poRequests.detail.editSAPCode.dialog.title' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box pt={2}>
            <TextInput
              required={true}
              label={formatMessage({ id: 'pages.poRequests.detail.editSAPCode.dialog.title' })}
              name="cod_sap"
              value={sapCode}
              onChange={handleChange}
            />
          </Box>
          <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
            <ThemeButton onClick={handleSubmit} color="primary" loading={loading} disabled={loading}>
              {formatMessage({ id: 'global.accept' })}
            </ThemeButton>
            <ThemeButton onClick={close} color="inherit" disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
