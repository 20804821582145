import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  PublicOfferDashboardPage,
  PublicOfferDetailPage,
  PublicOfferCreatePage,
} from '../components'

export const PublicOfferRouter = () => {
  return (
    <Switch>
      <Route exact path="/public-offer" component={PublicOfferDashboardPage} />
      <Route exact path="/public-offer/new" component={PublicOfferCreatePage} />
      <Route exact path="/public-offer/:publicOfferId" component={PublicOfferDetailPage} />
      <Redirect from="*" to="/public-offer" />
    </Switch>
  )
}
