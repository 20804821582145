import React from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, List, ListItem } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

const BillListItem = ({ id, value }) => {
  const { formatMessage } = useIntl()

  return (
    <ListItem>
      <Typography variant="body1" color="textSecondary" style={{ marginRight: 10 }}>
        {formatMessage({ id: `pages.sir.dialogs.viewBill.fields.${id}.title` })}:
      </Typography>
      <Typography variant="body1" color="textPrimary">
        {value}
      </Typography>
    </ListItem>
  )
}

export const ViewBillDialog = ({ bill, close }) => {
  const { formatMessage, formatDate } = useIntl()
  const address = `${bill.calle} ${bill.numero}, ${bill.municipio} (${bill.cod_codigo_postal}), ${bill.provincia}`
  const name = `${bill.nombre} ${bill.apellidos}`

  return (
    <Box p={4}>
      <Box mb={3} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.sir.dialogs.viewBill.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <List dense>
          <BillListItem id="date" value={formatDate(bill.dg_ts_insert)} />
          <BillListItem id="pagador" value={bill.pagador} />
          <BillListItem id="address" value={address} />
          <BillListItem id="name" value={name} />
          <BillListItem id="nif" value={bill.nif} />
          <BillListItem id="email" value={bill.email} />
          <BillListItem id="telefono" value={bill.telefono} />
        </List>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg">
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
