export const loginStyles = (theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
  },
  leftWrapper: {
    width: '50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: 'url("images/auth_bg.png")',
  },
  rightWrapper: {
    backgroundColor: '#FFFFFF',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'url("images/auth_bg.png")',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundImage: 'none',
      backgroundColor: '#f3f3f3',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panel: {
    margin: '20px 0',
    padding: '30px 60px',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  logo: {
    width: 233,
    height: 104,
  },
})
