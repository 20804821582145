const styledBy = (property, mapping) => (props) => mapping[props[property]]

export const selectInputStyles = (theme) => ({
  root: {
    maxWidth: '100%',
    minWidth: styledBy('fullWidth', {
      undefined: 150,
      false: 150,
      true: '100%',
    }),
    // margin: styledBy('spaceless', {
    //   undefined: '12px 0',
    //   false: '12px 0',
    //   true: 0,
    // }),
  },
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    whiteSpace: 'pre-line',
    // fontSize: 16,
  },
  input: {
    height: 48,
    // height: styledBy('size', {
    //   undefined: '48px',
    //   small: '32px',
    //   medium: '48px',
    // }),
    // '&:focus': {
    //   height: 32,
    // },
  },
  inlineValue: {
    fontFamily: 'FSEmeric-Medium',
    marginLeft: 4,
  },
})
