import { JOBS_ACTIONS_TYPES } from './jobs-reducer.constants'

export const jobsReducer = (state, { type, payload }) => {
  switch (type) {
    case JOBS_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
      case JOBS_ACTIONS_TYPES.SET_LOADING:
        return {
          ...state,
          loading: true,
        }
      case JOBS_ACTIONS_TYPES.SET_FAILURE:
        return {
          ...state,
          loading: false,
          error: true,
          data: [],
        }
    case JOBS_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    case JOBS_ACTIONS_TYPES.SET_SEND_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      }
      case JOBS_ACTIONS_TYPES.SET_FORM_DATA:
        return {
          ...state,
          instance_data: payload.instance_data,
          loading: false,
          msLoaded: Date.now(),
        }
    default:
      throw new Error('unknown action')
  }
}
