import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { requests } from '../../../core/ajax'
import feedback from '../../../core/feedback'

export const useUserCalls = () => {
  const { get, postPublic, putById } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getUsers = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/usuario/clientes', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resendValidation = useCallback(
    (mail) =>
      new Promise((resolve, reject) => {
        postPublic(`/usuario/resend_mail/${mail}`, { area: 'cliente' })
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateClientInfo = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById(`/usuario`, id, payload)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                reject(response.data)
                break
              default:
                defaultError()
            }
            return reject(response.data)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getUsers,
    resendValidation,
    updateClientInfo,
  }
}
