export const ratingInputStyles = {
  title: {
    marginBottom: 5,
    fontFamily: 'FSEmeric-Medium',
    '&+*': { marginTop: 0, width: '100%' },
  },
  editMode: {
    justifyContent: 'center',
  },
  readMode: {},
}
