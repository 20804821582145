import React, { useState } from 'react'
import { withStyles, Switch } from '@material-ui/core'

import { usePublicOfferContext } from '../../contexts'
import { publicOfferStateSwitchStyles } from './public-offer-state-switch.styles'

export const StatePublicOfferStateSwitch = withStyles(publicOfferStateSwitchStyles)(
  ({ classes, ...props }) => {
    const { changePublicOfferStatus } = usePublicOfferContext()
    const state = props.rowData['activa']
    const publicOfferId = props.rowData['id']

    const [loading, setLoading] = useState(false)

    const handleToggleState = (e) => {
      setLoading(true)
      changePublicOfferStatus(publicOfferId, { activa: e.target.checked })
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    return (
      <Switch classes={classes} checked={state} disabled={loading} onChange={handleToggleState} />
    )
  }
)
