export const IP_HIST_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_inspeccion_hist',
    multiple: true,
  },
  {
    name: 'fecha_creacion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_creacion_fin',
  },
  {
    name: 'fecha_creacion_fin',
    filterType: 'date',
    minDateField: 'fecha_creacion_inicio',
  },
  {
    name: 'fecha_inspeccion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_inspeccion_fin',
  },
  {
    name: 'fecha_inspeccion_fin',
    filterType: 'date',
    minDateField: 'fecha_inspeccion_inicio',
  },
]

export const JCA_HIST_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_jca_hist',
    multiple: true,
  },
  {
    name: 'fecha_creacion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_creacion_fin',
  },
  {
    name: 'fecha_creacion_fin',
    filterType: 'date',
    minDateField: 'fecha_creacion_inicio',
  },
  {
    name: 'fecha_inspeccion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_inspeccion_fin',
  },
  {
    name: 'fecha_inspeccion_fin',
    filterType: 'date',
    minDateField: 'fecha_inspeccion_inicio',
  },
]

export const EXPORT_RECORDS_LIMIT = 5000
