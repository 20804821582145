import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { ThemeButton, useModalContext } from '../../../../ui'
import { useSupervisionContext } from '../../contexts'
import { useSelector } from 'react-redux'
import { AssignToDialog } from '../dialogs/assign-to-dialog.component'

export const AutoAssignBtn = () => {
  const { formatMessage } = useIntl()
  const { permissions } = useSelector((state) => state.global)
  const {
    state: { tableSelection },
    assignTasks,
  } = useSupervisionContext()
  const {
    actions: { open },
  } = useModalContext()

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(!tableSelection.length)
  const [label, setLabel] = useState('pages.applications.autoAssignButton.label')

  const onClickHandler = async () => {
    if (tableSelection.length) {
      if (permissions.coordinador_sspp) {
        open({
          Component: AssignToDialog,
          data: {
            callback: assign,
          },
        })
      } else {
        assign()
      }
    }
  }

  const assign = async (user) => {
    setLoading(true)
    await assignTasks({ tasks: tableSelection.map((task) => task.id), user })
    setLoading(false)
  }

  useEffect(() => {
    const someAssigned = tableSelection.some((item) => item.responsable)

    setDisabled(!tableSelection.length || (!permissions.coordinador_sspp && someAssigned))

    setLabel(
      permissions.coordinador_sspp
        ? 'pages.applications.autoAssignButton.label_coordinador_sspp'
        : someAssigned
        ? 'pages.applications.autoAssignButton.label_not_yours'
        : 'pages.applications.autoAssignButton.label'
    )
  }, [tableSelection, permissions.coordinador_sspp])

  return (
    <ThemeButton
      disabled={disabled}
      variant="text"
      labelM="sm"
      fullWidth={true}
      loading={loading}
      onClick={onClickHandler}
      style={{ justifyContent: 'start' }}
    >
      {formatMessage({ id: label })}
    </ThemeButton>
  )
}
