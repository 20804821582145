import React from 'react'

import { JCAContext } from '../../features/jca'
import { JCARouter } from './routers'

export const JCAPage = (props) => {
  return (
    <JCAContext>
      <JCARouter {...props} />
    </JCAContext>
  )
}
