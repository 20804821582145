import React, { useEffect, useState, useCallback } from 'react'
import { CardCheckList } from '../../../../ui/check-list/index'
import { useIntl } from 'react-intl'
import {
  withStyles,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core'

import { marketerNewStyles } from './marketer-zone.style'
import { ProvinceList } from '../province-list'
import { usePublicOfferCalls } from '../../hooks/public-offer-calls.hook'

export const MarketerZones = withStyles(marketerNewStyles)(
  ({
    classes,
    readOnly,
    provincias,
    handleChangeMunip,
    handleChangeProv,
    cps = [],
    selProvs = [],
  }) => {
    provincias = provincias.map((p) => ({ id: p.key, nombre: p.value }))
    const { formatMessage } = useIntl()
    const { getZipMunicipalities } = usePublicOfferCalls()
    const [searchQuery, setSearchQuery] = useState(null)
    const [towns, setTowns] = useState(cps.map((item) => ({ ...item, activa: true })))
    const [checkedProvinces, setCheckedProvinces] = useState(selProvs)
    const [isLoading, setIsLoading] = useState(false)
    const getTowns = async (provs) => {
      if (provs.length) {
        setIsLoading(true)
        const a = await Promise.all(
          provs.map(async (province) => {
            const codes = await getZipMunicipalities('provincia_id', province.id)
            return codes[0].cps
          })
        )
        const checkedCps = cps?.map((item) => item.cp)
        setTowns(
          a.flat().map((zip) => ({
            ...zip,
            activa: checkedCps.includes(zip.cp),
          }))
        )
        setIsLoading(false)
      } else setTowns([])
    }
    useEffect(() => {
      if (!readOnly) {
        getTowns(checkedProvinces)
      } else {
        setTowns(cps.map((item) => ({ ...item, activa: true })))
      }
    }, [readOnly, checkedProvinces]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectProvince = (province) => {
      if (readOnly) return
      setCheckedProvinces((provs) => {
        const provsCopy = Array.from(provs)
        const ind = provsCopy.findIndex((item) => item.id === province.id)
        if (ind !== -1) {
          provsCopy.splice(ind, 1)
        } else {
          provsCopy.push(province)
        }
        handleChangeProv(provsCopy)
        return provsCopy
      })
    }

    const handleToggleZip = (zip) => {
      if (readOnly) return
      zip.activa = !zip.activa
      handleChangeMunip([zip])
    }

    const handleWholeZips = (event, zips) => {
      if (readOnly) return
      event.persist()
      const selectedTowns = towns
        .filter((town) => zips.includes(town.cp))
        .map((x) => ({ ...x, activa: event.target.checked }))
      setTowns((prev) => {
        const copy = Array.from(prev ?? [])
        const checkedCps = selectedTowns.map((item) => item.cp)
        const array = copy.map((zip) => ({
          ...zip,
          activa: checkedCps.includes(zip.cp) && event.target.checked,
        }))
        return array
      })
      handleChangeMunip(selectedTowns)
    }

    const handleSearchQueryChange = useCallback(
      (event) => {
        const newText = event.target.value
        setSearchQuery(newText)
      },
      [setSearchQuery]
    )

    const zipCheckRender = (element) => (
      <FormControlLabel
        key={element.value}
        className={classes.zipCard + (element.activa ? ' ' + classes.zipCardChosen : '')}
        label={`${element['cp']}`}
        control={
          <Checkbox
            color="primary"
            name={`${element['cp']}`}
            className={classes.zipMarker}
            checked={element.activa}
            onChange={() => handleToggleZip(element)}
            disableRipple
          />
        }
      />
    )

    const zipBlockRender = useCallback(
      ({ children }) => <Box className={classes.zipContainer}>{children}</Box>,
      [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const funcSearchFilter = (element, filter) => {
      return `${element['cp']} - ${element['muncipio']?.join(', ')}`
        .toLowerCase()
        .includes(filter.toLowerCase())
    }

    return (
      <div className={classes.container}>
        <div className={classes.provs}>
          <Typography variant="h6" gutterBottom>
            {formatMessage({ id: 'pages.tariffs.zone.provinces.title' })}
          </Typography>
          <ProvinceList
            className={classes}
            provinces={provincias}
            handleSelectProvince={handleSelectProvince}
            checkedProvinces={checkedProvinces}
          />
        </div>
        <div className={classes.zips}>
          <Typography variant="h6" gutterBottom>
            {formatMessage({ id: 'pages.tariffs.zone.zips.title' })}
          </Typography>
          {isLoading ? (
            <Box
              className={classes.loader}
              display="flex"
              height={100}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress size={40} />
            </Box>
          ) : (
            <CardCheckList
              className={classes.colorPanel}
              keyField="cp"
              nameField="cp"
              elements={towns}
              selected={towns.filter((zip) => zip.activa).map((el) => el.cp)}
              funcRenderElement={zipCheckRender}
              funcRenderContainer={zipBlockRender}
              searchBox={true}
              searchValue={searchQuery}
              searchPlaceholder={formatMessage({ id: 'pages.tariffs.zone.zips.filter' })}
              funcSearchOnChange={handleSearchQueryChange}
              funcSearchFilter={funcSearchFilter}
              wholeSelector={false}
              wholeCaption={formatMessage({ id: 'pages.tariffs.zone.zips.all' })}
              funcWholeOnChange={handleWholeZips}
              toggleCps={true}
              readOnly={readOnly}
            />
          )}
        </div>
      </div>
    )
  }
)
