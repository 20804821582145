export const gridFormStyles = (theme) => ({
  panelRow: {
    marginBottom: 20,
    padding: 18,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
    backgroundColor: theme.palette.grey[100],
    boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
  },
  row: {
    marginBottom: 20,
  },
})
