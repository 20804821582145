import React, { useState } from 'react'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Typography, Box } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import 'date-fns'
import { useIntl } from 'react-intl'
import esLocale from 'date-fns/locale/es'
import { getISOStringInitDate } from '../../core/utils/dateUtils'

export const DateRangePicker = ({ startDate, endDate, onChange, dateChanged }) => {
  const { formatMessage } = useIntl()
  const [dateHasChanged, setDateHasChanged] = useState(false)

  const handleChange = (date, name) => {
    if (date && !isNaN(date.getTime())) {
      setDateHasChanged(true)
      onChange(getISOStringInitDate(date), name)
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <Box display="flex" justifyContent="flex-end" mr={'2em'}>
        <Box mr={'2em'}>
          <DatePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy"
            label="Fecha inicio"
            value={startDate}
            maxDate={endDate ? endDate : null}
            onChange={(date) => handleChange(date, 'fecha_desde')}
          />
        </Box>
        <DatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          label="Fecha fin"
          value={endDate}
          minDate={startDate ? startDate : null}
          onChange={(date) => handleChange(date, 'fecha_hasta')}
        />
      </Box>
      {dateChanged && dateHasChanged ? (
        <Box mt={'1em'}>
          <Typography
            variant="subtitle2"
            display="inline"
            color="error"
            style={{ verticalAlign: 'super' }}
          >
            {formatMessage({ id: 'pages.public.offer.dialogs.general.terms.warning' })}
          </Typography>
        </Box>
      ) : null}
    </MuiPickersUtilsProvider>
  )
}
