import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { parse } from 'qs'
import { TableUrlPagination } from '../../../../ui'

import { APPS_TABLE_COLUMNS, APPS_TABLE_ACTIONS, DEFAULT_QUERY } from '../../constants'
import { appsTableStyles } from './supervision-table.styles'
import { useSupervisionContext } from '../../contexts'
import { supervisionDataPreparation } from '../../logics'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { useCustomCombo } from '../../../../features/combos'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const AppsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  useCustomCombo(['gestores'])
  const location = useLocation()
  const {
    state: {
      data,
      pagination: { total_solicitudes: totalEntries },
      loading,
      search,
      tableSelection,
    },
    supervisionSelect,
    supervisionTableCheck,
    supervisionLoad,
  } = useSupervisionContext()

  useEffect(() => {
    supervisionTableCheck([])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlSearchParams = parse(location.search.split('?')[1])
    const searchParams = {
      ...DEFAULT_QUERY,
      ...urlSearchParams,
    }
    supervisionLoad(searchParams)
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    supervisionTableCheck(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //Formatted columns:
  const formattedColumns = useMemo(
    () =>
      [...APPS_TABLE_COLUMNS, ...APPS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'view' || column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        if (column.field === 'alerta')
          return {
            ...column,
            title: (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <NotificationsNoneIcon style={{ fontSize: 20 }} />
              </span>
            ),
          }
        return {
          ...column,
          title: (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingLeft: '1em',
              }}
            >
              {formatMessage({ id: `pages.supervision.table.columns.${column.field}.title` })}
            </span>
          ),
          cellStyle: { ...appsTableStyles.cell, ...column.cellStyle },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id + '' + el.tipo_documento)
    let identificador = ''
    return supervisionDataPreparation(data, { intl }, combos).map((row) => {
      identificador = row.id + '' + row.tipo_documento
      if (selectedIds.indexOf(identificador) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      fixedColumns: {
        left: 2,
      },
      headerStyle: appsTableStyles.header,
      draggable: false,
    }),
    []
  )

  const getFormattedCell = (props) => {
    if (props.columnDef.field === 'alerta' && props.rowData.alerta) {
      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <NotificationsActiveIcon color="secondary" style={{ fontSize: 20 }} />
        </span>
      )
    }
  }

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const sTableCellProps =
          props.columnDef.field === 'alerta' ? { ...props, value: null } : props
        return <MTableCell {...sTableCellProps}>{getFormattedCell(props)}</MTableCell>
      },
    }),
    []
  )

  //Case-specific additions
  const handleRowClick = useCallback(
    (e, row) => {
      supervisionSelect(row.id, row.certificateType, row.docType)
    },
    [supervisionSelect]
  )
  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
