import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { CampaignsDashboardPage, CampaignsDetailPage, CampaignsCreatePage } from '../components'

export const CampaignsRouter = () => {
  return (
    <Switch>
      <Route exact path="/campaigns" component={CampaignsDashboardPage} />
      <Route exact path="/campaigns/new" component={CampaignsCreatePage} />
      <Route exact path="/campaigns/:campaignId" component={CampaignsDetailPage} />
      <Redirect from="*" to="/campaigns" />
    </Switch>
  )
}
