import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { useCombos, useCustomCombo } from '../../../features/combos'
import { EEIIIPHeader } from '../../../features/eeii-ip/components/header'
import { EEIIIPTable } from '../../../features/eeii-ip/components/table'
import { useEEIIIPCalls } from '../../../features/eeii-ip/hooks'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'

import { eeiiStyles } from './eeii-ip.styles'

export const EEIIIPPage = withStyles(eeiiStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getEEIIIP } = useEEIIIPCalls()
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])
  useCustomCombo(['gremios_ip'])

  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'eeiiIp',
    call: getEEIIIP,
  })
  return (
    <Container className={classes.container} maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.eeiiIp.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <EEIIIPHeader />
      </Box>
      <Box>
        <EEIIIPTable />
      </Box>
    </Container>
  )
})

export default EEIIIPPage
