import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography, Checkbox } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { TextInput, ThemeButton } from '../../../../../ui'
import { getTypeOfDocument } from '../../../../../core/utils'
import { BILLING_DATA_FORM_FIELDS, prepareAutoFill } from '../../../constants/payment.constants'
import { feedback } from '../../../../../core/feedback'

export const BillingDataDialog = ({
  application,
  isTransferencia,
  close,
  sendPaymentFile,
  data,
  getPaymentInfo,
  sendForm,
}) => {
  const { formatMessage } = useIntl()
  const [isChecked, setIsChecked] = useState(true)
  const [errorMssg, setErrorMssg] = useState('')
  const [loading, setLoading] = useState(false)

  const PAYMENT_URL = 'https://sis-t.redsys.es:25443/sis/realizarPago'

  const INITIAL_PAYMENT_DATA = {
    Ds_SignatureVersion: '',
    Ds_MerchantParameters: '',
    Ds_Signature: '',
  }

  const INITIAL_FORM_DATA = {
    nombre: application.facturacion?.nombre,
    apellidos: application.facturacion?.apellidos,
    telefono: application.facturacion?.telefono,
    email: application.facturacion?.email,
    tipo_identificador: application.facturacion?.tipo_identificador,
    nif: application.facturacion?.nif,
    cod_postal: application.facturacion?.cod_postal,
    municipio: application.facturacion?.municipio,
    direccion: application.facturacion?.direccion,
    numero: application.facturacion?.numero,
    provincia: application.facturacion?.provincia,
  }

  const changeFormState = (e) => {
    const { name, value } = e.target
    setFormState({
      ...formState,
      [name]: value,
    })
  }

  const [paymentData, setPaymentData] = useState(INITIAL_PAYMENT_DATA)
  const [formState, setFormState] = useState(INITIAL_FORM_DATA)
  const paymentBtnId = `paymentBtn${application.id}`
  const inputProps = { formState, onChange: changeFormState, fm: formatMessage }

  const payload = {
    facturacion: { ...formState },
  }

  const autoRellenar = () => {
    setFormState(prepareAutoFill(formState, application))
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    if (formState['tipo_identificador'] !== 'PASAPORTE' && !getTypeOfDocument(formState.nif)) {
      return setErrorMssg('No es un NIF o NIE válido')
    }
    setLoading(true)
    sendForm(payload, application.id)
      .then(() => {
        if (!isTransferencia) {
          feedback(
            'success',
            formatMessage({ id: 'pages.gasApplications.wizard.payment.form.success' })
          )
          getPaymentInfo(application.id).then(({ data }) => {
            setPaymentData(data)
          })
        } else {
          sendPaymentFile(application, data)
          feedback(
            'success',
            formatMessage({ id: 'pages.gasApplications.wizard.payment.transfer.form.success' })
          )
        }
        setLoading(false)
        if (isTransferencia) close()
      })
      .catch(() => {
        setLoading(false)
        feedback('error', formatMessage({ id: 'pages.gasApplications.wizard.payment.form.error' }))
      })
  }

  useEffect(() => {
    if (paymentData.Ds_MerchantParameters) document.getElementById(paymentBtnId).click()
  }, [paymentData]) // eslint-disable-line

  return (
    <>
      <Box mt={3} ml={5}>
        <Typography variant="h4" color="primary" paragraph>
          {formatMessage({ id: 'pages.gasApplications.wizard.payment.form.title' })}
        </Typography>
      </Box>
      <form onSubmit={handleSubmitForm} action={PAYMENT_URL}>
        <Box mr={5} ml={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).NOMBRE} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).APELLIDOS} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).TELEFONO} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).EMAIL} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).NIF} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).CODIGO_POSTAL} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).MUNICIPIO} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).DIRECCION} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).NUMERO} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...BILLING_DATA_FORM_FIELDS(inputProps).PROVINCIA} />
            </Grid>
          </Grid>
          {errorMssg ? (
            <Box mb={0.5}>
              <Alert severity="error">{errorMssg}</Alert>
            </Box>
          ) : null}
          <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                color="primary"
                size="small"
                name="autorellenar"
                disabled={loading}
                onClick={() => {
                  setIsChecked(!isChecked)
                  if (isChecked) {
                    autoRellenar()
                  }
                }}
              />
              {formatMessage({ id: 'pages.gasApplications.wizard.payment.form.checkbox.title' })}
            </div>
            <Box textAlign="right" display={isTransferencia ? 'flex' : 'none'}>
              <ThemeButton color="primary" type="submit" labelM="large" loading={loading}>
                {formatMessage({
                  id: 'pages.gasApplications.wizard.payment.form.button_guardar.title',
                })}
              </ThemeButton>
            </Box>
          </Box>
        </Box>
      </form>
      <form method="post" name="from" action={PAYMENT_URL}>
        <input
          type="hidden"
          name="Ds_MerchantParameters"
          value={paymentData.Ds_MerchantParameters}
        />
        <input type="hidden" name="Ds_Signature" value={paymentData.Ds_Signature} />
        <input type="hidden" name="Ds_SignatureVersion" value={paymentData.Ds_SignatureVersion} />
        <button id={paymentBtnId} type="submit" style={{ display: 'none' }} />
      </form>
    </>
  )
}
