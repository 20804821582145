import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { CAN_GENERATE_BUDGET_STATES } from './form-action-btns.constants'

export const GenerateBudgetBtn = () => {
  const { formatMessage } = useIntl()
  const { application, generateBudget, editMode, generate_budget_loading } = useSirContext()

  const [loading, setLoading] = useState(false)

  const handleCreateBudget = () => {
    setLoading(true)
    generateBudget(application.id)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const disabled = editMode || !CAN_GENERATE_BUDGET_STATES[application['estado']]

  return (
    <ThemeButton color="primary" loading={loading || generate_budget_loading} loadingShowText={true} disabled={disabled} onClick={handleCreateBudget}>
      {formatMessage({ id: 'pages.sir.detail.button.creatBudget.label' })}
    </ThemeButton>
  )
}
