export const PO_REQUEST_STATES = {
  CREATED: '10',
  PENDING_SAP: '20',
  PENDING_DOCS_REVIEW: '30',
  PENDING_ZEUS_REGISTER: '40',
  PENDING_ZEUS_ADHESION: '50',
  PROCESSED: '60',
  REJECTED: '00',
  EXPIRED: '01',
  CANCELLED: '02',
}

export const PO_MASSIVE_ACTIONS_STATES = [
  PO_REQUEST_STATES.CREATED,
  PO_REQUEST_STATES.PENDING_SAP,
  PO_REQUEST_STATES.PENDING_DOCS_REVIEW,
  PO_REQUEST_STATES.PENDING_ZEUS_REGISTER,
  PO_REQUEST_STATES.PENDING_ZEUS_ADHESION,
  PO_REQUEST_STATES.PROCESSED,
  PO_REQUEST_STATES.REJECTED,
]