import { useCallback, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { supervisionActions, supervisionInitialState, supervisionReducer } from '../reducers'
import { useAppSupervisionCalls } from './supervision-calls.hook'
import { parse } from 'qs'
import { DEFAULT_QUERY } from '../constants'

export const useSupervisionReducer = () => {
  const [state, dispatch] = useReducer(supervisionReducer, supervisionInitialState)
  const { getSupervisionApps, putSupervisionApp, assignTasksCall } = useAppSupervisionCalls()
  const history = useHistory()
  const { search } = useLocation() //Use strings and not objects to trigger useEffects a minimum ammount of times

  const supervisionLoad = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: supervisionActions.SUPERVISION_LOAD,
      })
      getSupervisionApps(config)
        .then(({ data }) => {
          dispatch({
            type: supervisionActions.SET_SUPERVISION_DATA,
            payload: {
              data: data['tareas'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_tareas'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: supervisionActions.SUPERVISION_FAILURE,
          })
        })
    },
    [dispatch, supervisionActions] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const supervisionSelect = useCallback(
    (id, type, document) => {
      if (document === 'DE') {
        const remainingPathname = document ? '/' + document : ''
        history.push({ pathname: id ? '/supervision/' + id + remainingPathname : '/supervision' })
      } else {
        const remainingPathname = type ? '/' + type : ''
        history.push({
          pathname: id ? '/supervision/' + id + remainingPathname : '/supervision',
          search: search,
        })
      }
    },
    [search, history]
  )

  const supervisionUpdateApplication = useCallback(
    (id, updatedValues) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: supervisionActions.SUPERVISION_LOAD,
        })
        putSupervisionApp(id, updatedValues)
          .then(({ data }) => {
            dispatch({
              type: supervisionActions.UPDATE_SUPERVISED_APPLICATION,
              payload: updatedValues,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: supervisionActions.SUPERVISION_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch, supervisionActions] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const supervisionTableCheck = useCallback(
    (rows) => {
      dispatch({
        type: supervisionActions.UPDATE_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, supervisionActions, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const assignTasks = useCallback(
    async (tasks) => {
      await assignTasksCall(tasks)

      supervisionLoad({
        ...DEFAULT_QUERY,
        ...parse(search.split('?')[1]),
      })
    },
    [dispatch, supervisionActions, search] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    state,
    supervisionLoad,
    supervisionSelect,
    supervisionUpdateApplication,
    supervisionTableCheck,
    assignTasks,
  }
}
