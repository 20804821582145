import React, { createContext, useContext } from 'react'
import { useSupervisionReducer } from '../hooks/supervision-reducer.hook'

const SupervisionContext = createContext()

export const SupervisionContextProvider = ({ children }) => {
  const reducer = useSupervisionReducer()
  return <SupervisionContext.Provider value={reducer}>{children}</SupervisionContext.Provider>
}

export const useSupervisionContext = () => useContext(SupervisionContext)
