export const SR_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SIR_LOAD',
  SET_FAILURE: 'SIR_FAILURE',
}

export const SR_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: {},
  loading: false,
  msLoaded: false,
  error: false,
  tableSelection: [],
}
