import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { JCA_FILTERS } from './jca-filter.constants'
import { JCA_EXPORT_COLUMNS } from '../table/jca-table.constants'
import { useJCACalls } from '../../hooks'
import { useJCAContext } from '../../contexts'
import feedback from '../../../../core/feedback'
import { jcaFilterStyles } from './jca-filter.styles'
import { jcaExportDataPreparation } from '../../logics/data-prepare.logics'

export const JCAFilter = () => {
  const jcaClasses = jcaFilterStyles()
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  const { getJJCAACall } = useJCACalls()
  const { tableSelection, search } = useJCAContext()

  const filters = JCA_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.jca.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox filters={filters} classes={jcaClasses}>
      <ExportToXLSBtn
        exportSelectionEnabled={false}
        exportAllEnabled={true}
        selectedRows={tableSelection}
        columnsExport={JCA_EXPORT_COLUMNS}
        getAllData={() => {
          return new Promise((resolve, reject) => {
            getJJCAACall({ params: { ...search, _num_x_pagina: 0 } })
              .then(({ data }) => {
                return resolve(jcaExportDataPreparation({ data: data.registros, combos, intl }))
              })
              .catch((err) => {
                feedback('error', formatMessage({ id: 'global.errorDownload' }))
                return reject(err)
              })
          })
        }}
        titleRoot="jca_"
        headerNamespace="jcaExport"
      />
    </FilterBox>
  )
}
