export const TARIFFS_FILTERS = [
  {
    name: 'empresa_id',
    filterType: 'autocomplete',
    comboId: 'empresas',
    size: 'small',
  },
  {
    name: 'comunidad',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
    cleanup: ['provincias'],
  },
  {
    name: 'provincia',
    comboId: 'provincias',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'cod_postal',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'activa',
    filterType: 'switch',
    cleanSelf: true,
  },
  {
    name: 'calefaccion',
    filterType: 'switch',
    cleanSelf: true,
  },
]
