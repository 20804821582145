import React from 'react'

import { ExportToXLSBtn, FilterBox } from "../../../../ui";
import { STUCK_TABLE_COLUMNS, STUCK_TABLE_FILTERS } from "../../constants";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useStuckCalls } from "../../hooks/stuck-calls.hook";
import { stuckDataPreparation } from "../../logics";


export const StuckHeader = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const stuckLoader = useSelector((state) => state.loader.stuck)
  const combos = useSelector((state) => state.combos)
  const { getStuck } = useStuckCalls()
  const search = stuckLoader?.config?.params

  const filters = STUCK_TABLE_FILTERS
  const buttons = (
    <ExportToXLSBtn
      exportSelectionEnabled={false}
      columnsExport={STUCK_TABLE_COLUMNS}
      getAllData={() =>
        new Promise((resolve, reject) => {
          getStuck({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              console.log(data)
              return resolve(stuckDataPreparation(data.solicitudes, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }
      titleRoot="stuck_"
      headerNamespace="stuck"
    />
  )
  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      console.log(combos[item.comboId])
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.stuck.filters.' + item.name + '.title',
      }),
    }
  })
  return (
    <>
      <FilterBox filters={data}>{buttons}</FilterBox>
    </>
  )
}
