import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, ListItem, Typography, Checkbox } from '@material-ui/core'
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { requests } from '../../../../core/ajax'
import { downloadFile } from '../../../../core/utils'
import { messageItemStyles } from './message-item.styles'

// const modelExample = {
//   id: 111,
//   fecha: '2021-05-06T15:52:12.451Z',
//   nombre: 'Guillem Flavi\u00e0',
//   mensaje: 'Consulta al gestor',
//   instalador_id: null,
//   gestor_id: null,
//   usuario_id: 208,
//   tipo_perfil_remitente: 'client',
//   leido: false,
//   documentos: [
//     {
//       documento_id: 2626,
//       dg_ts_insert: '2021-05-06T15:52:12.458Z',
//     },
//   ],
// }

const isOwnMessage = (ownProfile, messageProfile) => {
  if (ownProfile === messageProfile) {
    return true
  } else {
    return false
  }
}

export const MessageItem = withStyles(messageItemStyles)(
  ({ classes, profile, markAsRead, message }) => {
    const { formatDate, formatTime } = useIntl()
    const { getById } = requests
    const isOwn = isOwnMessage(profile, message.tipo_perfil_remitente)
    const listItemClassName = isOwn ? classes.listItemOwn : classes.listItem

    const [loading, setLoading] = useState(false)

    const handleDownloadFile = (id) => {
      setLoading(true)
      getById('/documento', id)
        .then(({ data }) => {
          downloadFile(data['documento'], data['extension'], data['nombre'])
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }

    const PrimaryItem = () => (
      <Box display="flex">
        <Box flex={1}>
          <Typography component="span" variant="body2" color="textPrimary">
            {message.nombre + ' - ' + formatDate(message.fecha) + ' ' + formatTime(message.fecha)}
          </Typography>
        </Box>
        {message.documentos.length ? (
          <IconButton
            size="small"
            onClick={() => handleDownloadFile(message.documentos[0].documento_id)}
            disabled={loading}
          >
            {loading ? <CircularProgress size={18} /> : <AttachFileIcon fontSize="inherit" />}
          </IconButton>
        ) : null}
      </Box>
    )

    const SecondaryItem = () => (
      <>
        <Typography component="span" variant="body2" color="textPrimary">
          {message.mensaje}
        </Typography>
        {!isOwn && !message.leido ? (
          <Box textAlign="right">
            <Tooltip arrow title="Marcar como leido">
              <Checkbox
                edge="end"
                size="small"
                onChange={() => markAsRead(message.id)}
                checked={message.leido}
              />
            </Tooltip>
          </Box>
        ) : null}
      </>
    )

    return (
      <ListItem className={listItemClassName}>
        <Box width="100%">
          <PrimaryItem />
          <SecondaryItem />
        </Box>
      </ListItem>
    )
  }
)
