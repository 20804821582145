import React from 'react'
import { CounterBtn } from './counter-btn.component'

import { SEMAPHORE_CONFIG } from './semaphore-btn.constants'

export const SemaphoreBtns = (props) => {
  return (
    <>
      <CounterBtn {...SEMAPHORE_CONFIG(props).GREEN} />
      <CounterBtn {...SEMAPHORE_CONFIG(props).YELLOW} />
      <CounterBtn {...SEMAPHORE_CONFIG(props).RED} />
    </>
  )
}
