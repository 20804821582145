import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import {
  NonCaptablesValidationForm,
  NonCaptablesValidationContext,
  APPS_COMBOS_IDS,
  ORDER_COMBOS_IDS,
  NonCaptablesValidationCertificateForm,
} from '../../../features/non-captables-validation'
import { useCombos } from '../../../features/combos/hooks'
import { NonCaptablesValidationDashboardPage } from './dashboard'

const NonCaptablesValidationPageBare = () => {
  useCombos(APPS_COMBOS_IDS, ORDER_COMBOS_IDS)
  return (
    <Switch>
      <Route
        exact
        path="/non-captables-validation"
        component={NonCaptablesValidationDashboardPage}
      />
      <Route exact path="/non-captables-validation/:appId" component={NonCaptablesValidationForm} />
      <Route
        exact
        path="/non-captables-validation/:appId/:certificateType"
        component={NonCaptablesValidationCertificateForm}
      />
      <Redirect from="*" to="/non-captables-validation" />
    </Switch>
  )
}

export const NonCaptablesValidationPage = (props) => {
  return (
    <NonCaptablesValidationContext>
      <NonCaptablesValidationPageBare {...props} />
    </NonCaptablesValidationContext>
  )
}
