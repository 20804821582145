import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton } from '../../../../ui'
import { INPUTS_CONFIG, FORM_CONFIG } from './campaign-form.constants'
import { GridForm } from './grid-form.component'
import { useCampaignsContext } from '../../contexts'
import { useCombos } from '../../../combos/hooks'
import { useCampaginsValidations } from '../../hooks'
import { CloneBtn } from '../buttons'
import { prepareCampaignDto } from './campaign-form.utils'

export const CampaignsEditForm = () => {
  const intl = useIntl()
  const combos = useCombos(['gremios'])
  const { campaign, editCampaign } = useCampaignsContext()
  const { formErrors } = useCampaginsValidations()

  const [formState, setFormState] = useState({
    ...campaign,
    financiacion: campaign.financiacion ? '01' : '00',
  })
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const handleChangeForm = (e) => {
    if (
      e.target.value === 'cliente' ||
      e.target.value === 'instalador' ||
      e.target.value === 'gremio'
    ) {
      eraseGremiosAndProvsFields(e)
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const eraseGremiosAndProvsFields = (e) => {
    delete formState.comunidades
    formState.is_op = false
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleCancel = () => {
    setErrorMssg(false)
    setFormState({ ...campaign, financiacion: campaign.financiacion ? '01' : '00' })
    setEditMode(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // if (
    //   formState.mercados?.some(
    //     (mercado) =>
    //       mercado.nombre === 'Pyme' ||
    //       mercado.nombre === 'Gran Consumo' ||
    //       mercado.nombre === 'Caldera Centralizada'
    //   )
    // ) {
    //   //Campaign without elementos/productos
    //   formState['elementos'] = []
    // } else {
    //   //Campaign without tariff
    //   formState['tarifas'] = []
    // }
    const errors = formErrors(formState)
    if (editMode) {
      if (!errors) {
        setLoading(true)
        editCampaign(prepareCampaignDto(formState))
          .then((data) => {
            setErrorMssg(errors)
            setEditMode(false)
            setFormState({ ...data, financiacion: data.financiacion ? '01' : '00' })
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        setErrorMssg(errors)
      }
    } else {
      setEditMode(true)
      setFormState({ ...formState, enviar_email: null })
    }
  }

  const INPUTS = INPUTS_CONFIG({
    data: formState,
    combos,
    intl,
    onChange: handleChangeForm,
    readOnly: !editMode,
  })

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" mt={2} mb={2}>
        <Box display="flex" justifyContent="flex-start" flex={1}>
          <CloneBtn {...{ disabled: editMode }} />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          {editMode ? (
            <Box mr={1}>
              <ThemeButton color="default" onClick={handleCancel} disabled={loading}>
                {intl.formatMessage({ id: 'global.cancel' })}
              </ThemeButton>
            </Box>
          ) : null}
          <ThemeButton type="submit" color="primary" loading={loading}>
            {intl.formatMessage({
              id: `pages.campaigns.detail.button.${editMode ? 'save' : 'edit'}.label`,
            })}
          </ThemeButton>
        </Box>
      </Box>
      {errorMssg ? <Alert severity="error">{errorMssg}</Alert> : null}
      <GridForm {...{ grid: FORM_CONFIG, inputs: INPUTS }} />
    </form>
  )
}
