import { useCallback, useReducer } from 'react'

import { useSRCalls } from './sr-calls.hook'
import { srReducer } from '../reducers'
import { SR_INITIAL_STATE, SR_ACTIONS_TYPES } from '../reducers'

export const useSRReducer = () => {
  const [state, dispatch] = useReducer(srReducer, SR_INITIAL_STATE)
  const { getSRsCall } = useSRCalls()

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: SR_ACTIONS_TYPES.SET_LOADING,
      })
      getSRsCall(config)
        .then(({ data }) => {
          dispatch({
            type: SR_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['sr'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_solicitudes'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: SR_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: SR_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getTableData,
    setSelectedTableRows,
    ...state,
  }
}
