import { useCallback } from 'react'
import { requests } from '../../../core/ajax'

export const useEEIIIPCalls = () => {
  const { get, put } = requests

  const getEEIIIP = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/empresa/tabla_empresas_ip', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const switchCompanyState = useCallback(
    (empresa_id) =>
      new Promise((resolve, reject) => {
        put(`/empresa/${empresa_id}/modificar_actividad`)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getEEIIIP,
    switchCompanyState,
  }
}
