import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { Grid, Box, Typography, Checkbox, Backdrop, CircularProgress } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Alert } from '@material-ui/lab'

import { useConsultContext } from '../../contexts'
import { getFormPaymentData } from '../../calls/consult-information.calls'
import { TextInput, SelectInput, MapPicker, PanoramaPicker, ThemeButton } from '../../../../ui'
import { CUSTOMER_FIELDS_CONFIG } from '../../constants/costumer-form.constants'
import { useTooFarDialog } from '../../dialogs'
import { distHaversines } from '../../../../core/maps/haversine.func'
import { CertificateDocuments } from '../certificate-documents'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../constants'
import { useUserContext } from '../../../../core/user'
import { feedback } from '../../../../core/feedback'

const MAX_DISTANCE_ALLOWED = 0.02 //In km.

export const GCform3 = () => {
  const {
    customerForm,
    consultData,
    reset,
    changeCustomerFormArray,
    changeCustomerForm,
    createApplication,
    prevStep,
    isLoading,
    selectedHome,
  } = useConsultContext()
  const { userState } = useUserContext()
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const panoramaRef = useRef()
  //Coordinate storage:
  const tooFar = useTooFarDialog()
  const docsList = ['DN']
  const documentList = useMemo(
    () => {
      if (customerForm.ubi_equipo_medida === 'Interior') {
        docsList.push('ACIF')
      }
      const documents = DOCUMENT_ATTACHMENT_CONFIG
      return docsList?.length ? docsList.map((doc) => documents[doc]) : null
    },
    [docsList] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const [check, setCheck] = useState(false)

  const choosesPosition =
    (consultData.automatic_budget && customerForm.pasar_contratable !== true) ||
    process.env.REACT_APP_MOCKS
  const position = {
    lat: customerForm.latitude,
    lng: customerForm.longitude,
  }

  const panoramaPosition = {
    lat: customerForm.sv_latitud,
    lng: customerForm.sv_longitud,
  }
  const pov = {
    heading: customerForm.heading,
    pitch: customerForm.pitch,
  }
  const center = consultData.latitude
    ? {
        lat: consultData.latitude,
        lng: consultData.longitude,
      }
    : null
  const handlePosition = (position) => {
    const dist = distHaversines({ origin: center, target: position })
    if (dist > MAX_DISTANCE_ALLOWED) {
      tooFar(
        () =>
          changeCustomerFormArray({
            latitude: position.lat,
            longitude: position.lng,
          }),
        () => changeCustomerFormArray({})
      )
      //Dirty trick to re-trigger positioning:
      return
    }
    changeCustomerFormArray({
      latitude: position.lat,
      longitude: position.lng,
    })
  }
  const handlePanoramaPosition = (position) => {
    const dist = distHaversines({ origin: center, target: position })
    if (dist > MAX_DISTANCE_ALLOWED) {
      tooFar(
        () =>
          changeCustomerFormArray({
            sv_width: panoramaRef.current?.getBoundingClientRect().width,
            sv_height: panoramaRef.current?.getBoundingClientRect().height,
            sv_marker_x: position.pixel?.x,
            sv_marker_y: position.pixel?.y,
            sv_latitud: position.lat,
            sv_longitud: position.lng,
          }),
        () => changeCustomerFormArray({})
      )
      return
    }
    changeCustomerFormArray({
      sv_width: panoramaRef.current?.getBoundingClientRect().width,
      sv_height: panoramaRef.current?.getBoundingClientRect().height,
      sv_marker_x: position.pixel?.x,
      sv_marker_y: position.pixel?.y,
      sv_latitud: position.lat,
      sv_longitud: position.lng,
    })
  }
  const setPov = (pov) => {
    changeCustomerFormArray({
      heading: pov.heading,
      pitch: pov.pitch,
      povLat: pov.povLat,
      povLng: pov.povLng,
    })
  }

  useEffect(() => {
    const isReset = Object.keys(consultData)
    const isResetOrNortmalized = Object.keys(selectedHome)
    if (isReset.length === 0 && isResetOrNortmalized.length === 0) {
      reset()
    } else {
      const servicesForPayload = []
      customerForm.equipo_gran_consumo.forEach((service) => {
        servicesForPayload.push({
          potencia: parseInt(service.potencia),
          cantidad: parseInt(service.cantidad),
        })
      }, [])

      const payload = {
        distancia_real: parseInt(customerForm?.distancia_real),
        horas_funcionamiento: parseInt(customerForm?.horas_funcionamiento),
        cidi: consultData.cidi ? String(consultData?.cidi) : null,
        equipo_gran_consumo: servicesForPayload,
      }

      getFormPaymentData(payload).then((response) => {
        changeCustomerFormArray({
          consumo_anual: response.consumo_anual,
          potencia_instalada_total: response.potencia_instalada_total,
          potencia_instalada_total_2: response.potencia_instalada_total_2,
          tarifa: response.tarifa,
          derechos_alta: response.derechos_alta,
          derechos_acometida: response.derechos_acometida,
          derechos_extension: response.derechos_extension,
          mercado: consultData.mercado,
        })
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const handleOnChangeForm = (e) => {
    let { name, value, checked } = e.target

    //Special case for services field in certificate form
    if (name === 'services') {
      if (value && value.includes('calefaccion')) {
        if (!value.includes('agua_caliente')) {
          value = [...value, 'agua_caliente']
        }
      }
    }
    if (name === 'sin_ubicacion') {
      value = checked
      changeCustomerForm({ name, value })
    } else {
      changeCustomerForm({ name, value })
    }
  }

  const handleChange = useCallback(
    (event) => {
      let { name, value } = event.target

      changeCustomerForm({ name, value })
    },
    [changeCustomerForm]
  )

  const inputProps = {
    formState: customerForm,
    onChange: handleOnChangeForm,
    intl,
    combos,
    consultData,
    customerForm,
  }

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()

      createApplication(userState.type_profile).then((response) => {
        feedback('success', 'Solicitud creada')
      })
    },
    [userState, customerForm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleGoBack = () => {
    prevStep({ replace: true })
  }

  return (
    <>
      <Box>
        <Typography variant="h4" color="primary" paragraph>
          {intl.formatMessage({ id: 'pages.consult.title.2.datos_generados' })}
        </Typography>
      </Box>
      <Box pl={[0, 0, 5]} pr={[0, 0, 5]}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <SelectInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).CONTADOR }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).POTENCIA_INSTALADA }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).POTENCIA_INSTALADA_2 }} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).CONSUMO_ANUAL }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).TARIFA }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).MERCADO }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).DERECHOS_EXTENSION }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).DERECHOS_ALTA }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput {...{ ...CUSTOMER_FIELDS_CONFIG(inputProps).DERECHOS_ACOMETIDA }} />
            </Grid>
            {choosesPosition ? (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={6} sx={{display:"flex", flexDirection:"column", justifyContent:"end"}}>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
                      <Typography variant="h5" color="textPrimary" align="center" paragraph>
                        {intl.formatMessage({ id: 'pages.consult.title.4' })}
                      </Typography>
                      <div
                        style={{
                          position: 'relative',
                          height: '25em',
                          width: '523px',
                          top: 0,
                          margin: "0 auto"
                        }}
                      >
                        <MapPicker {...{ position, setPosition: handlePosition, center }} />
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={6} sx={{display:"flex", flexDirection:"column", justifyContent:"end"}}>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
                      <Typography variant="h5" color="textPrimary" align="center" paragraph>
                        {intl.formatMessage({ id: 'pages.consult.title.5' })}
                      </Typography>
                      <div
                        ref={panoramaRef}
                        style={{
                          position: 'relative',
                          height: '25em',
                          width: '524px',
                          margin: "0 auto"
                        }}
                      >
                        <PanoramaPicker
                          {...{
                            position: panoramaPosition,
                            setPosition: handlePanoramaPosition,
                            center,
                            ...pov,
                            setPov,
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <Alert severity="warning">
                  {intl.formatMessage({ id: 'pages.consult.description.1' })}
                </Alert>
              </Grid>
            ) : null}
          </Grid>
          <Box mt={2}>
            <CertificateDocuments
              documentList={documentList}
              data={customerForm}
              updateInfo={handleChange}
            />
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <Checkbox
              color="primary"
              size="small"
              required
              name="compromise"
              onClick={() => {
                setCheck((check) => !check)
              }}
            />
            <Typography variant="body2">
              {intl.formatMessage({ id: 'pages.consult.disclaimer' })}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
            mt={2}
          >
            <ThemeButton labelM="lg" onClick={handleGoBack} color="primary" justifyContent="center">
              {intl.formatMessage({ id: 'global.return' })}
            </ThemeButton>

            <ThemeButton color="secondary" labelM="lg" type="submit" disabled={!check}>
              {intl.formatMessage({
                id: 'global.accept',
              })}
            </ThemeButton>
          </Box>
          {consultData['cidi'] && choosesPosition ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Checkbox
                color="primary"
                size="small"
                name="sin_ubicacion"
                onClick={(e) => {
                  handleOnChangeForm(e)
                }}
              />
              <Typography variant="body2">
                {intl.formatMessage({ id: 'pages.consult.sinubicacion' })}
              </Typography>
            </Box>
          ) : null}
        </form>
      </Box>
      <Backdrop open={isLoading} style={{ zIndex: 99 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}
