import { useContext } from 'react'
import { useIntl } from 'react-intl'
import { AcceptationDialog } from '../../../ui'
import { ModalContext } from '../../../ui/modal/contexts'
import { EditInstallerDialog } from '../components/dialogs'

export const useInstallerDialogs = () => {
  const intl = useIntl()
  const {
    actions: { open, close },
  } = useContext(ModalContext)

  const warnValidationMailSuccess = () => {
    open({
      Component: AcceptationDialog,
      data: {
        title: intl.formatMessage({ id: 'pages.users.calls.resend.success.title' }),
        text: intl.formatMessage({ id: 'pages.users.calls.resend.success.text' }),
        yesText: intl.formatMessage({ id: 'pages.users.calls.resend.success.yesText' }),
      },
      type: 'centered',
    })
  }

  const editInstallerDialog = (user, updateClientInfo) => {
    open({
      Component: EditInstallerDialog,
      data: {
        close,
        user,
        updateClientInfo,
      },
      maxWidth: 'sm',
    })
  }

  return {
    warnValidationMailSuccess,
    editInstallerDialog,
  }
}
