import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { Login, PasswordRecovery } from '../../pages'

export const PublicRouter = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/password-recovery/:id" component={PasswordRecovery} />
    <Redirect from="*" to="/login" />
  </Switch>
)
