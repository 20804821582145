export const clientPathStyles = (theme) => {
  return {
    title: {
      paddingTop: '1em',
      paddingBottom: '0.5em',
    },
    label: {
      fontWeight: 'bold',
      padding: '0 0.3em',
    },
    icon: {
      paddingLeft: '0.3em',
    },
    success: {
      color: theme.palette.success.main,
    },
    warning: {
      color: theme.palette.warning.main,
    },
    error: {
      color: theme.palette.error.main,
    },
  }
}
