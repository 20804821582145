export const SEMAPHORE_CONFIG = (props) => ({
  GREEN: {
    id: props.id,
    path: props.path,
    searchParams: {
      ...props.searchParams,
      fecha_cambio_estado_desde: props.counters.semaforo.verde.fecha_desde,
    },
    count: props.counters.semaforo.verde.numero,
    borderColor: '#00955e',
    bgColor: '#d9efe7',
  },
  YELLOW: {
    id: props.id,
    path: props.path,
    searchParams: {
      ...props.searchParams,
      fecha_cambio_estado_desde: props.counters.semaforo.amarillo.fecha_desde,
      fecha_cambio_estado_hasta: props.counters.semaforo.amarillo.fecha_hasta,
    },
    count: props.counters.semaforo.amarillo.numero,
    borderColor: '#fed967',
    bgColor: '#fbf6e5',
  },
  RED: {
    id: props.id,
    path: props.path,
    searchParams: {
      ...props.searchParams,
      fecha_cambio_estado_hasta: props.counters.semaforo.rojo.fecha_hasta,
    },
    count: props.counters.semaforo.rojo.numero,
    borderColor: '#e53a16',
    bgColor: '#f7ded9',
  },
})
