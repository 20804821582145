export const NOTICES_TABLE_COLUMNS = [
  {
    field: 'title',
    align: 'left',
    width: 200,
  },
  {
    field: 'state',
    align: 'center',
    width: 50,
  },
  {
    field: 'start_date',
    align: 'center',
    width: 100,
    type: 'date'
  },
  {
    field: 'end_date',
    align: 'center',
    width: 100,
    type: 'date'
  },
  {
    field: 'target_users',
    align: 'center',
    width: 200,
  },
  {
    field: 'tags',
    align: 'center',
    width: 150,
  }
]

export const NOTICES_TABLE_FILTERS = [
  {
    name: 'state',
    label: 'Estado',
    values: [
      { key: 'ready', value: 'Programada' },
      { key: 'active', value: 'Activa' },
      { key: 'inactive', value: 'Finalizada' },
    ],
    size: 'small',
  },
  {
    name: 'roles',
    comboId: 'roles_all',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tags',
    comboId: 'tags_avisos',
    size: 'small',
    multiple: true,
  },
  ]