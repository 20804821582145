import React, { useState } from 'react'
import { withStyles, Switch } from '@material-ui/core'

import { useCampaignsContext } from '../../contexts'
import { campaignStateSwitchStyles } from './campaign-state-switch.styles'

export const CampaignStateSwitch = withStyles(campaignStateSwitchStyles)(
  ({ classes, ...props }) => {
    const { changeCampaignStatus } = useCampaignsContext()
    const state = props.rowData['activa']
    const campaignId = props.rowData['id']

    const [loading, setLoading] = useState(false)

    const handleToggleState = (e) => {
      setLoading(true)
      changeCampaignStatus(campaignId, { activa: e.target.checked })
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    return (
      <Switch classes={classes} checked={state} disabled={loading} onChange={handleToggleState} />
    )
  }
)
