import React from 'react'
import { withStyles, FormControl, Icon } from '@material-ui/core'
import { Tooltip, Typography, Box } from '@material-ui/core'

import { listInputStyles } from './list-input.styles'
import { BasicTable } from '../basic-table'

export const ListInput = React.memo(
  withStyles(listInputStyles)(
    ({ classes, values = [], multiple = false, readOnly = false, selectAll = false, ...props }) => {
      const typographyVariant = props.fontSize === 'small' ? '2' : '1'
      const value = props.value

      const getValue = () => {
        if (value.length > 0) {
          return <BasicTable config={props.subtitle} data={value} size="small" />
        } else return '--'
      }

      return (
        <FormControl
          variant="outlined"
          className={classes.root}
          required={props.required}
          disabled={props.disabled}
        >
          <Box display="flex" flexDirection="horizontal">
            {props.title && (
              <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
                {props.title}
              </Typography>
            )}
            {props.tooltip && (
              <span style={{ fontSize: 16, marginLeft: 4 }}>
                <Tooltip
                  placement="top"
                  title={
                    <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
                      {props.tooltip}
                    </Typography>
                  }
                >
                  <Icon fontSize="inherit">help_outline</Icon>
                </Tooltip>
              </span>
            )}
          </Box>
          {readOnly ? (
            <Typography variant={`body${typographyVariant}`} color="textSecondary">
              {getValue()}
            </Typography>
          ) : (
            <Typography variant={`body${typographyVariant}`} color="textSecondary">
              {getValue()}
            </Typography>
          )}
        </FormControl>
      )
    }
  )
)
