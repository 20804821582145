import * as nonCaptablesValidation from './non-captables-validation-reducer.constants'

export const nonCaptablesValidationInitialState = {
  //General data:
  data: [],
  //Loading state and meta-data:
  search: {},
  pagination: { totalSolicitudes: 0 },
  loading: false,
  msLoaded: false,
  error: false,
  //Selected object
  selection_id: null,
  tableSelection: [],
}

export const nonCaptablesValidationReducer = (state, { type, payload }) => {
  switch (type) {
    case nonCaptablesValidation.SET_SUPERVISION_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case nonCaptablesValidation.SUPERVISION_LOAD:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case nonCaptablesValidation.SUPERVISION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
        msLoaded: true,
      }
    case nonCaptablesValidation.SUPERVISION_APP_DETAIL_LOAD:
      return {
        ...state,
        application: payload,
        // isCertificate: payload.isCertificate,
        detailLoading: false,
      }
    case nonCaptablesValidation.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case nonCaptablesValidation.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }
    case nonCaptablesValidation.UPDATE_SUPERVISED_APPLICATION:
      return {
        ...state,
        loading: false,
        data: state.data.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
      }
    case nonCaptablesValidation.SELECT_SUPERVISED_APPLICATION:
      return {
        ...state,
        selection_id: payload,
      }
    case nonCaptablesValidation.SET_IS_CERTIFICATE:
      return {
        ...state,
        certificate: payload,
      }
    case nonCaptablesValidation.UPDATE_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    default:
      throw new Error('unknown action')
  }
}
