import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  withStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Collapse,
  Box,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { basicTableStyles } from '../basic-table/basic-table.styles';

const defaultKey = (i, row) => i;

export const CollapsibleTable = withStyles(basicTableStyles)(
  ({
    rowKey = defaultKey,
    classes,
    config,
    data,
    context,
    getdata,
    size = 'medium',
    showHeaders = true,
    renderDetails,
    ...props
  }) => {
    const { formatDate, formatTime, formatMessage } = useIntl();
    const [openRows, setOpenRows] = useState({});

    const toggleRow = (index, hasDetails) => {
      if (hasDetails) {
        setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
      }
    };

    const getParsedValue = (type, value, Component, context, row) => {
      switch (type) {
        case 'date':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatDate(new Date(value))}
            </Typography>
          );
        case 'datetime':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatDate(new Date(value)) + ' - ' + formatTime(new Date(value))}
            </Typography>
          );
        case 'component':
          return <Component value={value} context={context} row={row} />;
        case 'responsable':
          const finalValue = row[value] ? `${row[value]} (${row['rol_usuario']})` : null;
          return finalValue;
        case 'comboDocumentData':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatMessage({ id: `pages.supervision.edit.documents.${value}` })}
            </Typography>
          );
        case 'comboData':
          return (
            <Typography variant="body2" color="textSecondary">
              {formatMessage({ id: `global.basic_table.${value}` })}
            </Typography>
          );
        default:
          return value;
      }
    };

    return (
      <TableContainer>
        <Table className={classes.table} aria-label="collapsible table" size={size}>
          {showHeaders && (
            <TableHead>
              <TableRow>
                <TableCell />
                {config.map(({ align = 'left', label }, i) => (
                  <TableCell key={i} align={align}>
                    <Typography variant="subtitle1">{label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {data.map((row, i) => {
              const hasDetails = row.fields && row.fields.length > 0;

              return (
                <React.Fragment key={rowKey(i, row)}>
                  <TableRow
                    className={
                      props.onClick
                        ? props.loading
                          ? classes.loadingRow
                          : classes.clickableRow
                        : classes.row
                    }
                    hover={!!props.onClick}
                    onClick={() => (props.onClick ? props.onClick(row) : null)}
                  >
                    <TableCell>
                      {hasDetails && (
                        <IconButton size="small" onClick={() => toggleRow(i, hasDetails)}>
                          {openRows[i] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                      )}
                    </TableCell>
                    {config.map(({ key, align = 'left', type, component }, j) => (
                      <TableCell key={j} align={align}>
                        {getParsedValue(type, row[key], component, context, row)}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={config.length + 1}>
                      <Collapse in={openRows[i]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          {renderDetails && hasDetails ? renderDetails(row) : null}
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
);

