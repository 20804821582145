import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

const { post } = requests
const noResponseError = () => {
  console.error('No response!')
}
const defaultError = () => {
  console.error('Unknown error!')
}

export const postDoc = (payload) => {
  return new Promise((resolve, reject) => {
    post('/documento', payload)
      .then(({ data }) => resolve(data))
      .catch(({ response }) => {
        feedback('error', 'Error: No se han podido asociar los documentos a la solicitud')
        if (!response) {
          noResponseError()
          return reject('No response')
        }
        switch (response.status) {
          default:
            defaultError()
        }
        return reject(response.status)
      })
  })
}
