import { APPS_ALERT_FIELDS } from '../constants'
import { capitalizeStr } from '../../../core/utils'

export const applicationDataPreparation = (data, { intl }, columns = []) => {
  return data.map((item) => {
    const formattedDates = {}
    const customInfo = {}
    columns.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.field === 'direccion') {
        formattedDates[column.field] = capitalizeStr(item[column.field])
      }
      if (column.field === 'alerts') {
        customInfo[column.field] = APPS_ALERT_FIELDS.map((field) => {
          if (item[field]) {
            return intl.formatMessage({
              id: `pages.applications.alerts.${field}.title`,
            })
          } else {
            return ''
          }
        })
          .filter((el) => el)
          .join(', ')

        if (customInfo[column.field] === '') {
          customInfo[column.field] = '--'
        }
      }
      // if (column.field === 'valoraciones_pendientes') {
      //   if (!item[column.field]) {
      //     customInfo[column.field] = 'No'
      //   } else {
      //     customInfo[column.field] = 'Sí'
      //   }
      // }
    })
    return {
      ...item,
      ...formattedDates,
      ...customInfo,
      estados_solicitud_presupuesto: item['solicitud_estado'] + item['presupuesto_estado'],
      tableData: {
        ...item.tableData,
      },
    }
  })
}
