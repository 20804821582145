//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const TARIFFS_TABLE_COLUMNS = [
  {
    field: 'nombre_tarifa',
    align: 'left',
    width: 300,
  },
  {
    field: 'nombre_empresa',
    align: 'left',
    width: 300,
  },
  {
    field: 'cif',
    align: 'left',
    width: 300,
  },
  {
    field: 'comunidad',
    align: 'left',
    width: 300,
  },
  {
    field: 'provincia',
    align: 'left',
    width: 300,
  },
  {
    field: 'activa',
    align: 'left',
    width: 300,
  },
  {
    field: 'calefaccion',
    align: 'left',
    width: 300,
  },
  {
    field: 'fecha_alta',
    align: 'left',
    type: 'date',
    width: 300,
  },
  {
    field: 'fecha_modificacion',
    align: 'left',
    type: 'date',
    width: 300,
  },
]

export const TARIFFS_TABLE_ACTIONS = [
  // {
  //   field: 'active',
  //   width: 70,
  //   align: 'center',
  //   sorting: false,
  // },
  // {
  //   field: 'actions',
  //   width: 130,
  //   align: 'center',
  //   sorting: false,
  // },
]
