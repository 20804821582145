import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

const marketersSlice = createSlice({
  name: 'marketers',
  initialState: {
    isLoading: false,
    data: [],
    search: {},
    pagination: {},
    selectedUser: {},
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload
    },
    setMarketersData: (state, { payload }) => {
      state.data = payload.data
      if (payload.search) {
        state.search = payload.search
      }
      if (payload.pagination) {
        state.pagination = payload.pagination
      }
      state.isLoading = false
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload
    },
  },
})

export const marketersReducer = marketersSlice.reducer

export const { setLoading, setMarketersData } = marketersSlice.actions

export const setMarketers = (searchParams) => (dispatch) => {
  const { _pagina, _num_x_pagina, ...restSearchParams } = searchParams
  const { get } = requests

  const config = {
    params: { ...restSearchParams, _pagina, _num_x_pagina },
  }
  
  dispatch(setLoading(true))
  get(`comercializadoras/tabla_comercializadoras`, config)
    .then(({ data }) => {
      dispatch(
        setMarketersData({
          data: data.comercializadoras,
          search: searchParams,
          pagination: {
            total_paginas: data['total_paginas'],
            total_comercializadoras: data['total_comercializadoras'],
          },
        }),
      )
    })
    .catch(({ response }) => {
      console.error('Error recuperando comercializadoras', response)
      dispatch(setLoading(false))
    })
}

export const toggleMarketerActive = (row, marketers) => (dispatch) => {
  const { putById } = requests

  putById('/comercializadoras', row['id'], { ...row, activo: !row['activo'] })
    .then(({ data }) => {
      const newMarketers = marketers.slice()
      newMarketers[row.tableData.id] = { ...newMarketers[row.tableData.id], activo: data['activo'] }
      dispatch(
        setMarketersData({
          data: newMarketers,
        }),
      )
    })
    .catch(() => {
      console.error('Error changing marketer state')
    })
}