import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ConfirmationDialog, ThemeButton, useModalContext } from "../../../../ui";
import React, { useEffect, useState } from "react";
import feedback from "../../../../core/feedback";
import { useAjaxContext } from "../../../../core/ajax";
import { Box, Icon, Tooltip, Typography } from "@material-ui/core";
import { useApplicationsCalls } from "../../hooks";

export const FinishConnecProcessBtnComponent = () => {
  const intl = useIntl()
  const { formatMessage } = useIntl()
  const selectedRows = useSelector((state) => state.applications.selectedRows)
  const {
    actions: { open, close },
  } = useModalContext()
  const { put } = useAjaxContext()
  const [loading, setLoading] = useState(false)
  const [enableButton, setEnableButton] = useState(false)
  const estados_aceptados = ["20", "25","15", "30", "36"]
  const [validSelectedRows, setValidSelectedRows] = useState([])
  const searchParams = useSelector((state) => state.applications.search)
  const { getApplications } = useApplicationsCalls()


  useEffect(() => {
    getValidSelectedRows()
  }, [selectedRows]);


  const getValidSelectedRows = () => {
    let data_to_send = []
    if (selectedRows && selectedRows.length) {
      for (let i = 0; i < selectedRows.length; i++) {
        if ((selectedRows[i].tipo_mercado === "SP" || selectedRows[i].tipo_mercado === "SH") && selectedRows[i].tipo_vivienda === "Plurifamiliar") {
            console.log(selectedRows[i].solicitud_estado)
          if (estados_aceptados.includes(selectedRows[i].solicitud_estado)) {
            data_to_send.push(selectedRows[i].id)
          }
        }
      }
    }
    console.log(data_to_send)
    setEnableButton(data_to_send.length > 0)
    setValidSelectedRows(data_to_send)
  }

  const finishAccom = async () => {
      if (validSelectedRows.length === 0) return;
      setLoading(true)
      put('/solicitudes/finalizar_tramites_acometida', { list_solicitudes_id: validSelectedRows})
        .then(({ data }) => {
          setLoading(false)
          getApplications(searchParams)
          feedback(
            'success',
            formatMessage({ id: 'pages.applications.finishAccomButton.feedback.success' })
          )
        })
        .catch(({ response }) => {
          setLoading(false)
          if (response.data?.code === 3001) {
            feedback(
              'warning',
              formatMessage({ id: 'pages.applications.feedback.warning.autoassign' })
            )
          } else {
            feedback('error', formatMessage({ id: 'pages.applications.feedback.error.autoassign' }))
          }
        })
    close()
}
  const handleButtonClick = async () => {
    if (validSelectedRows.length > 0) {
      open({
        Component: ConfirmationDialog,
        data: {
          title: formatMessage({ id: 'pages.applications.finishAccomButton.confirm.title.label' }),
          text: formatMessage({ id: 'pages.applications.finishAccomButton.confirm.text.label' }),
          yesText: formatMessage({ id: 'global.accept' }),
          noText: formatMessage({ id: 'global.cancel' }),
          yesAction: finishAccom,
          yesAndClose: false,
        },
        type: 'centered',
      })
    }
  }

  useEffect(() => {
    setEnableButton(false)
    if (selectedRows && selectedRows.length) {
      for(let i = 0; i < selectedRows.length; i++) {
        if((selectedRows[i].tipo_mercado === "SP" || selectedRows[i].tipo_mercado === "SH") && selectedRows[i].tipo_vivienda ==="Plurifamiliar"){
          if (estados_aceptados.includes(selectedRows[i].solicitud_estado)) {
            setEnableButton(true)
            break
          }

        }
      }
    }
  }, [selectedRows]);

  const tooltip = (
    <span
      style={{
        fontSize: 16,
        position: 'absolute',
        top: '-1em',
        right: '-1em',
      }}
    >
      <Tooltip
        placement="top"
        title={
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {formatMessage({ id: 'pages.applications.finishAccomButton.tooltip.label' })}
          </Typography>
        }
      >
        <Icon fontSize="inherit">help_outline</Icon>
      </Tooltip>
    </span>
  )

  return (
    <Box ml={2} mr={2}>
      <Box display="flex" flexDirection="row" style={{ position: 'relative' }}>
      <ThemeButton
        color="primary"
        labelM="sm"
        onClick={handleButtonClick}
        disabled={!enableButton}
      >
        {intl.formatMessage({ id: 'pages.applications.finishAccomButton.label' })}
      </ThemeButton>
        {tooltip}
      </Box>
      </Box>
  )

}