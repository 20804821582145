import React, { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { ThemeButton, FormPanel } from '../../../../ui'
import { CLIENT_EDIT_FIELDS, CLIENT_EDIT_CONFIG } from './edit-client-form.constants'
import { getTypeOfDocument, isValidEmail } from '../../../../core/utils'

export const EditClientForm = ({ close, application, updateClientInfo }) => {
  const intl = useIntl()
  const { formatMessage: fm } = intl
  const combos = useSelector((state) => state.combos)
  const role = useSelector((state) => state.global.role)

  const [formState, setFormState] = useState(application.usuario)
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const isCallCenter = role === 'call_center'
  const disabledButton = isCallCenter && formState.oauth2_id

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const docType = getTypeOfDocument(formState['identificador'])
    if (!isValidEmail(formState['email'])) {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.1' }))
    } else if (formState['tipo_identificador'] === 'NIE' && docType !== 'NIE') {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.2' }))
    } else if (formState['tipo_identificador'] === 'NIF' && docType !== 'NIF') {
      return setErrorMssg(fm({ id: 'pages.application.detail.dialogs.editClient.feedback.3' }))
    } else {
      setLoading(true)
      const payload = {
        nombre: formState.nombre,
        apellidos: formState.apellidos,
        tipo_identificador: formState.tipo_identificador,
        identificador: formState.identificador,
        email: formState.email,
        telefono: formState.telefono,
        solicitud_id: application.id,
      }
      updateClientInfo(payload)
        .then(() => {
          setLoading(false)
          close()
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  const fieldList = useMemo(
    () => CLIENT_EDIT_FIELDS({ combos, intl, formState, onChange: handleChange, isCallCenter }),
    [combos, formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const block = useMemo(
    () => CLIENT_EDIT_CONFIG({ intl, formState }),
    [formState] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <form onSubmit={handleSubmit}>
      {formState.oauth2_id ? (
        <Alert severity="warning">
          {isCallCenter
            ? fm({ id: 'pages.application.detail.client.call_center.noEdit' })
            : fm({ id: 'pages.application.detail.client.noEdit' })}
        </Alert>
      ) : null}
      <FormPanel {...{ data: formState, combos, intl, block, fieldList }} />
      {errorMssg && (
        <Box mt={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-around" p={3} flexWrap="wrap">
        <Box width={190} mb={[3, 0]} p="0 5px">
          <ThemeButton onClick={close} variant="outlined" fullWidth disabled={loading}>
            {fm({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box width={190} p="0 5px">
          <ThemeButton
            type="submit"
            color="primary"
            fullWidth
            loading={loading}
            disabled={loading || disabledButton}
          >
            {fm({ id: 'global.save' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
