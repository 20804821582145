import { APPS_TABLE_COLUMNS, CERTIFICATE_TYPE } from '../constants'

export const supervisionDataPreparation = (data, { intl }, combos) => {
  return data.map((item) => {
    const formattedDates = {}
    let certificateType = null
    APPS_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (
        column.comboId === 'tipo_documento_sspp' &&
        combos[column.comboId] &&
        combos[column.comboId].data
      ) {
        const comboItem = combos[column.comboId].data.find(
          (comboItem) => comboItem.key === item.tipo_documento
        )
        certificateType = comboItem ? getValidCertificateType(comboItem) : null
      }
    })
    return {
      ...item,
      ...formattedDates,
      titular: item.usuario_nombre + ' ' + item.usuario_apellidos,
      certificateType: certificateType,
      docType: item.tipo_documento,
      tableData: {
        ...item.tableData,
      },
    }
  })
}

export const prepareSelectedCertificateData = (docType, data) => {
  const selectedCertificate = getSelectedCertificate(docType, data)
  if (!selectedCertificate) {
    return null
  }
  const selectedCertificateData = {
    selectedCertificateId: selectedCertificate.id,
    selectedCertificateCreationDate: selectedCertificate.fecha_creacion,
    selectedCertificateModificationDate: selectedCertificate.fecha_modificacion,
    selectedCertificateDate: selectedCertificate.fecha_certificado,
    selectedCertificateType: docType,
    // override the estado, observaciones_cambio_estado and observaciones when load a certificate
    estado_certificado: selectedCertificate.estado,
    estado: data.estado,
    estado_tarea: data.estado_tarea,
    observaciones_cambio_estado: selectedCertificate.observaciones_cambio_estado,
    observaciones: selectedCertificate.observaciones,
  }

  const preparedData = {
    ...data,
    ...selectedCertificateData,
    tipoDocumento: docType,
  }
  return preparedData
}

export const prepareSelectedDEData = (data) => {
  const selectedDeData = {
    estado_facturacion: data.facturacion.tramitado,
    facturacion_id: data.facturacion.id,
    fecha_facturacion: data.dg_ts_insert,
  }

  const preparedData = {
    ...data,
    ...selectedDeData,
  }
  return preparedData
}

const getValidCertificateType = (comboItem) => {
  switch (comboItem.type) {
    case CERTIFICATE_TYPE.IRG1:
    case CERTIFICATE_TYPE.IRG2:
    case CERTIFICATE_TYPE.IRG3:
    case CERTIFICATE_TYPE.CM:
      return comboItem.type
    default:
      return null
  }
}

const getCertificate = (certificateType, data) => {
  switch (certificateType) {
    case CERTIFICATE_TYPE.IRG1:
      return data.certificado_IRG1
    case CERTIFICATE_TYPE.IRG2:
      return data.certificado_IRG2
    case CERTIFICATE_TYPE.IRG3:
      return data.certificado_IRG3
    case CERTIFICATE_TYPE.CM:
      return data.certificado_modificacion
    default:
      return null
  }
}

const isAValidCertificateType = (docType, data) => {
  const certificate = getCertificate(docType, data)
  return certificate && certificate.id
}

const getSelectedCertificate = (docType, data) => {
  let certificate = null
  if (isAValidCertificateType(docType, data)) {
    certificate = getCertificate(docType, data)
  }
  return certificate
}
