import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import { ThemeButton, useModalContext, ConfirmationDialog } from '../../../../ui'
import { useJCAContext } from '../../contexts'
import { JCA_CAN_VALIDATE_STATES, JCA_NONCONFORMITY_STATE } from './jca-form.constants'
import { JCA_CAN_REJECT_STATES, JCA_CAN_SEND_TO_ZEUS_STATES } from './jca-form.constants'
import { JCA_CAN_SEND_TO_ZEUS_MANUALLY_STATES } from './jca-form.constants'
import { ResolveIssueDialog, RejectJCADialog } from '../dialogs'

export const JCAStateBtns = () => {
  const { formatMessage } = useIntl()
  const { jca, validateJCA, rejectJCA, resolveIssue, sendToZeusJCA } = useJCAContext()
  const { loadOnZeusJCA } = useJCAContext()
  const {
    actions: { open },
  } = useModalContext()
  const permissions = useSelector((state) => state.global.permissions)

  const handleValidateJCA = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.jca.detail.validateDialog.title' }),
        text: formatMessage({ id: 'pages.jca.detail.validateDialog.description' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => validateJCA(jca.id),
      },
      type: 'centered',
    })
  }

  const handleLoadOnZeusJCA = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.jca.detail.loadedOnZeusBtn.title' }),
        text: formatMessage({ id: 'pages.jca.detail.loadedOnZeusBtn.description' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => loadOnZeusJCA(jca.id),
      },
      type: 'centered',
    })
  }

  const handleSendToZeusJCA = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.jca.detail.sendToZeusBtn.title' }),
        text: formatMessage({ id: 'pages.jca.detail.sendToZeusBtn.description' }),
        yesText: formatMessage({ id: 'global.accept' }),
        noText: formatMessage({ id: 'global.cancel' }),
        yesAction: () => sendToZeusJCA(jca.id),
      },
      type: 'centered',
    })
  }

  const handleRejectJCA = () => {
    open({
      Component: RejectJCADialog,
      data: { jca, rejectJCA },
      type: 'centered',
    })
  }

  const handleResolveIssue = () => {
    open({
      Component: ResolveIssueDialog,
      data: { jcaId: jca.id, resolveIssue },
      type: 'centered',
    })
  }

  return (
    <Box display="flex" justifyContent="flex-end">
      {permissions.validar_jca && jca.estado === JCA_NONCONFORMITY_STATE ? (
        <Box>
          <ThemeButton color="secondary" onClick={handleResolveIssue}>
            {formatMessage({ id: 'pages.jca.detail.resolveIssueBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      {permissions.validar_jca && JCA_CAN_VALIDATE_STATES[jca.estado] ? (
        <Box ml={1}>
          <ThemeButton color="secondary" onClick={handleValidateJCA}>
            {formatMessage({ id: 'pages.jca.detail.validateBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      {permissions.validar_jca && JCA_CAN_SEND_TO_ZEUS_STATES[jca.estado] ? (
        <Box ml={1}>
          <ThemeButton color="secondary" onClick={handleSendToZeusJCA}>
            {formatMessage({ id: 'pages.jca.detail.sendToZeusBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      {permissions.validar_jca &&
      (JCA_CAN_SEND_TO_ZEUS_STATES[jca.estado] ||
        JCA_CAN_SEND_TO_ZEUS_MANUALLY_STATES[jca.estado]) ? (
        <Box ml={1}>
          <ThemeButton color="default" onClick={handleLoadOnZeusJCA}>
            {formatMessage({ id: 'pages.jca.detail.loadedOnZeusBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
      {permissions.rechazar_ip && JCA_CAN_REJECT_STATES[jca.estado] ? (
        <Box ml={1}>
          <ThemeButton variant="outlined" onClick={handleRejectJCA}>
            {formatMessage({ id: 'pages.jca.detail.rejectBtn.label' })}
          </ThemeButton>
        </Box>
      ) : null}
    </Box>
  )
}
