import React from 'react'
import { useSelector } from 'react-redux'
import { withStyles, Switch } from '@material-ui/core'
import { useEEIIIPCalls } from '../../hooks'
import { useLoaderBasic } from '../../../loader/hooks'
import { useModalContext, ConfirmationDialog } from '../../../../ui'
import feedback from '../../../../core/feedback'
import { useIntl } from 'react-intl'

const switchStyles = (theme) => ({
  root: {
    marginRight: 10,
  },
  switchBase: {
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  track: {
    backgroundColor: theme.palette.grey[500],
  },
  checked: {},
})

export const TableActions = withStyles(switchStyles)(({ classes, rowData, ...props }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const permissions = useSelector((state) => state.global.permissions)
  const { switchCompanyState } = useEEIIIPCalls()
  const { addExtra } = useLoaderBasic({ identifier: 'eeiiIp' })
  const {
    actions: { open },
  } = useModalContext()

  const rowState = rowData['activo']

  const eeiiIpLoader = useSelector((state) => state.loader.eeiiIp)
  const eeiiIpData = eeiiIpLoader?.data
  const eeiiIpCompanies = eeiiIpLoader?.data?.empresas || []

  const handleToggleState = () =>
    new Promise(function (resolve, reject) {
      const companyId = rowData.id
      switchCompanyState(companyId)
        .then(({ data }) => {
          const { activo: updatedState } = data
          const updatedEeiiIpCompanies = eeiiIpCompanies.map((e) => {
            return e.id === companyId ? { ...e, activo: updatedState } : e
          })

          addExtra({ data: { ...eeiiIpData, empresas: updatedEeiiIpCompanies } })
          resolve()
        })
        .catch((response) => {
          feedback('error', response?.data?.message || 'No se ha podido completar la operación')
          console.error('Error changing company state')
          reject()
        })
    })

  const handleSwitch = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage(
          {
            id: `pages.eeiiIp.switchCompanyDialog.${
              rowData.activo ? 'deactivate' : 'activate'
            }.title`,
          },
          { company: rowData.nombre }
        ),
        text: formatMessage(
          {
            id: `pages.eeiiIp.switchCompanyDialog.${
              rowData.activo ? 'deactivate' : 'activate'
            }.desc`,
          },
          { company: rowData.nombre }
        ),
        yesText: formatMessage({ id: 'pages.eeiiIp.switchCompanyDialog.confirm' }),
        noText: formatMessage({ id: 'pages.eeiiIp.switchCompanyDialog.cancel' }),
        yesAction: handleToggleState,
      },
      type: 'centered',
    })
  }

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        track: classes.track,
        checked: classes.checked,
      }}
      checked={rowState}
      onClick={handleSwitch}
      disabled={!permissions.desactivar_empresa}
    />
  )
})
