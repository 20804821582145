import React from 'react'
import { Paper, Box, List, ListItem, ListItemText, Icon } from '@material-ui/core'

export const ProvinceList = ({
  className,
  provinces,
  handleSelectProvince,
  selectedProvince,
  checkedProvinces,
}) => {
  return (
    <Paper elevation={2} className={className.provinciaLine}>
      <Box>
        <List>
          {provinces.map((province) => (
            <ListItem
              className={
                selectedProvince?.provincia === province.key ? className.provinciaLineChosen : ''
              }
              id={province.key}
              onClick={() => handleSelectProvince(province.key)}
            >
              <ListItemText>{province.value}</ListItemText>
              {checkedProvinces.map((checkedprovince) =>
                checkedprovince === province.key ? <Icon>check_circle</Icon> : null
              )}
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  )
}
