import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { IPHistFilter, IPHistTable } from '../../../../features/ip3-historical'
import { useCombos } from '../../../../features/combos'
import { IP3_HIST_COMBOS_IDS } from '../../constants'
import { ipHistDashboardStyles } from './ip-hist-dashboard.styles'

export const IPHistDashboardPage = withStyles(ipHistDashboardStyles)(({ classes }) => {
  useCombos(IP3_HIST_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth="xl">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.ip3Historical.ip.dashboard.title' })}
        </Typography>
      </Box>
      <Box mb={2} display="flex">
        <IPHistFilter />
      </Box>
      <Box>
        <IPHistTable />
      </Box>
    </Container>
  )
})
