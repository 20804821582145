import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { Box } from '@material-ui/core'
import { useModalContext } from '../../../../ui/modal'
import { AddCp } from '../../dialogs/add-newcp.dialog'

export const NewCPBtn = ({ table, setTable }) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleAddNewCp = () => {
    open({
      Component: AddCp,
      data: { table, setTable },
      type: 'responsive',
      closable: true,
      maxWidth: 'md',
    })
  }

  return (
    <Box ml={2} mr={2}>
      <Box display="flex" flexDirection="row" style={{ position: 'relative' }}>
        <ThemeButton color="primary" labelM="sm" onClick={handleAddNewCp}>
          {formatMessage({ id: 'pages.granconsumo.newcp.button.label' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
