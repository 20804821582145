import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { requests } from '../../../../core/ajax'

const TEXT_AREA_LENGTH = 250

export const AnswerIssueDialog = ({ close, application, getApplication }) => {
  const { formatMessage } = useIntl()
  const { post } = requests

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmitForm = (e) => {
    e.preventDefault()
    setLoading(true)
    const userId = application.usuario.id
    const payload = {
      solicitud_id: application.id,
      ...formState,
    }
    post(`/send_mail/usuario/${userId}/comunicacion_interna`, payload)
      .then(() => {
        setLoading(false)
        getApplication(application['id'])
        const getAddressee = () => {
          switch (application.autor_ultimo_cambio_estado) {
            case 'administrador':
            case 'instalador':
              return 'instalador'
            default:
              return 'cliente'
          }
        }
        feedback(
          'success',
          formatMessage(
            { id: 'pages.application.detail.feedback.1' },
            { addressee: getAddressee() }
          )
        )
        close()
      })
      .catch(() => {
        console.error('Error canceling applicaiton')
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <form onSubmit={handleSubmitForm}>
        <Box mb={2}>
          <TextInput
            name="mensaje"
            title={`${formatMessage({
              id: 'pages.application.detail.dialogs.answerIssue.fields.1',
            })}:`}
            value={formState['mensaje']}
            multiline
            minRows={5}
            inputProps={{ maxLength: TEXT_AREA_LENGTH }}
            onChange={handleChangeForm}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Box mr={6}>
            <ThemeButton color="default" labelM="lg" onClick={close} disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <ThemeButton
            type="submit"
            color="primary"
            labelM="lg"
            disabled={loading}
            loading={loading}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
