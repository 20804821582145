const currencyValue = (value, intl) =>
  value || value === 0
    ? intl.formatNumber(value, {
        style: 'currency',
        currency: 'EUR',
      })
    : ''

const GRID_ITEM_QUADRUPLET = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
}

export const RESULTS_VIEW_CONFIG = [
  {
    foldable: false,
    items: [
      [
        { ref: 'MERCADO', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'TIPO_DE_GAS', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'DISTANCIA_APROX_RED', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'IMPORTE_APROX_RED', conf: GRID_ITEM_QUADRUPLET },
      ],
      [],
      [
        { ref: 'PRESION_DE_SERVICIO', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'CUPS', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'TIPO_VIVIENDA', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'ESTADO', conf: GRID_ITEM_QUADRUPLET },
      ],
      [],
      [
        { ref: 'FECHA_ULTIMO_CESE', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'COSTES_CANON_CONTADOR', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'COSTES_CANON_IRC', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'COSTES_CANON_INST', conf: GRID_ITEM_QUADRUPLET },
      ],
      [],
      [
        { ref: 'COSTES_DERECHOS_ALTA', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'COSTES_DERECHOS_ACOMETIDA', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'NATURALEZA_GAS', conf: GRID_ITEM_QUADRUPLET },
        { ref: 'CONSUMO_MAX_ANUAL', conf: GRID_ITEM_QUADRUPLET },
      ],
      [],
      [{ ref: 'CIDI', conf: GRID_ITEM_QUADRUPLET }],
    ],
  },
]

const ROOT_CONSULT_FIELDS = 'pages.consult.resultsFields'

export const RESULTS_VIEW_FIELDS = ({ combos, intl }) => {
  const { formatMessage } = intl
  const txt = (extension) => formatMessage({ id: ROOT_CONSULT_FIELDS + '.' + extension })

  return {
    MERCADO: {
      fromData: (data) => ({
        value: data['mercado'],
      }),
      name: 'mercado',
      values: combos['tipo_mercado']?.data || [],
      componentType: 'select',
      readOnly: true,
      title: txt('1'),
    },
    TIPO_DE_GAS: {
      fromData: (data) => ({
        value: data['tipo_de_gas'],
      }),
      name: 'tipo_de_gas',
      readOnly: true,
      title: txt('2'),
    },
    CIDI: {
      fromData: (data) => ({
        value: data['cidi'],
      }),
      name: 'cidi',
      readOnly: true,
      title: txt('18'),
    },
    DISTANCIA_APROX_RED: {
      fromData: (data) => ({
        value:
          data['camino_solicitud'] === '01'
            ? `${data['distancia_aproximada_de_la_red'] || 0} m`
            : '--',
      }),
      name: 'mercado',
      readOnly: true,
      tooltip: txt('tooltip_disclaimer'),
      title: txt('3'),
    },
    IMPORTE_APROX_RED: {
      fromData: (data) => ({
        value:
          data['camino_solicitud'] === '01'
            ? currencyValue(data['importe_aproximado_extensión_de_red'], intl)
            : '--',
      }),
      name: 'mercado',
      tooltip: txt('tooltip_disclaimer'),
      readOnly: true,
      title: txt('4'),
    },
    PRESION_DE_SERVICIO: {
      fromData: (data) => ({
        value: data['presion_de_servicio'],
      }),
      name: 'mercado',
      readOnly: true,
      title: txt('5'),
    },
    CUPS: {
      fromData: (data) => ({
        value: data['cups'],
      }),
      name: 'mercado',
      readOnly: true,
      title: txt('6'),
    },
    // FECHA_DEMANDA_ANTERIOR: (data)=>({
    //   name: 'fecha_demanda_anterior',
    //   value: data['fecha_demanda_anterior'],
    //   readOnly: true,
    //   title: txt('7'),
    // }),
    ESTADO: {
      fromData: (data) => ({
        value: data['estado_cups'] || data['estado'],
      }),
      name: 'estado',
      readOnly: true,
      title: txt('16'),
    },
    FECHA_ULTIMO_CESE: {
      fromData: (data) => ({
        value: data['fecha_ultimo_cese'],
      }),
      name: 'fecha_ultimo_cese',
      readOnly: true,
      title: txt('8'),
    },
    COSTES_CANON_CONTADOR: {
      fromData: (data) => ({
        value: currencyValue(data['costes_canon_contador'], intl),
      }),
      tooltip: txt('tooltip_costes_alquiler'),
      name: 'costes_canon_contador',
      readOnly: true,
      title: txt('9'),
    },
    COSTES_CANON_IRC: {
      fromData: (data) => ({
        value: currencyValue(data['costes_canon_irc'], intl),
      }),
      tooltip: txt('tooltip_canon_irc'),
      name: 'costes_canon_irc',
      readOnly: true,
      title: txt('10'),
    },
    COSTES_CANON_INST: {
      fromData: (data) => ({
        value: currencyValue(data['costes_canon_instalacion'], intl),
      }),
      tooltip: txt('tooltip_canon_instalacion'),
      name: 'costes_canon_instalacion',
      readOnly: true,
      title: txt('11'),
    },
    COSTES_DERECHOS_ALTA: {
      fromData: (data) => ({
        value: currencyValue(data['costes_de_derechos_de_alta'], intl),
      }),
      name: 'costes_de_derechos_de_alta',
      tooltip: txt('tooltip_costes_derecho_alta'),
      readOnly: true,
      title: txt('12'),
    },
    COSTES_DERECHOS_ACOMETIDA: {
      fromData: (data) => ({
        value: currencyValue(data['costes_de_derechos_de_acometida'], intl),
      }),
      name: 'costes_de_derechos_de_acometida',
      tooltip: txt('tooltip_costes_derecho_acometida'),
      readOnly: true,
      title: txt('13'),
    },
    NATURALEZA_GAS: {
      fromData: (data) => ({
        value: data['naturaleza_del_gas'],
      }),
      name: 'naturaleza_del_gas',
      readOnly: true,
      title: txt('14'),
    },
    CONSUMO_MAX_ANUAL: {
      fromData: (data) => ({
        value: data['consumo_max_anual'],
      }),
      name: 'consumo_max_anual',
      readOnly: true,
      title: txt('15'),
    },
    TIPO_VIVIENDA: {
      fromData: (data) => ({
        value: data['tipo_vivienda'],
      }),
      name: 'tipo_vivienda',
      readOnly: true,
      title: txt('17'),
    },
  }
}

export const PATH_EDIT_CONFIG = (data) => ({
  componentType: 'select',
  value: data['camino_solicitud'],
  name: 'camino_solicitud',
  title: 'Tipología de solicitud',
  values: [
    { key: '01', value: 'Requiere grabación y acometida' },
    { key: '03', value: 'Genera certificado directamente' },
  ],
})
