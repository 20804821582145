export const SIR_TABLE_COLUMNS = [
  { field: 'comunicaciones_pendientes', width: 40, sorting: true },
  { field: 'solicitud_id', align: 'left', width: 120 },
  { field: 'sociedad', align: 'left', width: 140 },
  {
    field: 'solicitud_estado',
    align: 'left',
    comboId: 'estados_solicitud_sir',
    sorting: false,
    width: 100,
    exportType: 'combo',
  },
  { field: 'fecha_creacion', align: 'left', width: 130, type: 'date', exportType: 'date_iso' },
  // { field: 'gestor_sir', align: 'left', width: 140 },
  {field: 'fecha_envio_presupuesto', align:'left', width:130, type: 'date', sorting: true },
  { field: 'centro_sstt', align: 'left', width: 140 },
  { field: 'direccion', align: 'left', width: 180 },
  { field: 'municipio', align: 'left', width: 150 },
  { field: 'trabajos_realizar', align: 'left', sorting: false, width: 180 },
  { field: 'empresa_instaladora', align: 'left', width: 180 },
  { field: 'nif_empresa', align: 'left', width: 120 },
  { field: 'nombre_cliente', align: 'left', width: 150 },
  { field: 'tipo_solicitante', align: 'left', width: 130 },
  { field: 'estado_finca', align: 'left', comboId: 'estado_finca', width: 180 },
]

export const ADDITIONAL_EXPORT = [
  { field: 'importe', align: 'right', width: 120, type: 'currency', exportType: 'currency' },
  { field: 'importe_iva', align: 'right', width: 120, type: 'currency', exportType: 'currency' },
  { field: 'observaciones_cliente', width: 40, sorting: true },
  { field: 'observaciones_gestor', align: 'left', width: 120 },
  { field: 'observaciones_internas', align: 'left', width: 140 },
  {
    field: 'num_encargo_goda',
    align: 'left',
    sorting: false,
    width: 100,
  },
  { field: 'fecha_encargo_goda', align: 'left', width: 130, type: 'date', exportType: 'date_iso' },
  // { field: 'gestor_sir', align: 'left', width: 140 },
  { field: 'fecha_gor_icaro', align: 'left', width: 140, type: 'date', exportType: 'date_iso' },
  { field: 'num_gor_icaro', align: 'left', width: 180 },
]

export const ESTATE_CLOSED_VALUE = 'Cerrada sin servicio de gas'
export const ESTATE_CLOSED_COLOR = '#fff4e5'
