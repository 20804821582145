import { useCallback } from 'react'

import { requests } from '../../../core/ajax'

export const useGuildCalls = () => {
  const { getById } = requests

  const getGuild = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/gremios', id)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getGuild,
  }
}
