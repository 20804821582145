import React from 'react'
import { IntlProvider } from 'react-intl'

import { messages, locale } from './constants'

export default ({ children }) => (
  <IntlProvider locale={locale} messages={messages}>
    {children}
  </IntlProvider>
)
