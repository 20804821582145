import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box, Tooltip, Icon } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'

import {
  ThemeButton,
  FoldingPanel,
  UploadTooltip,
  useModalContext,
  ConfirmationDialog,
} from '../../../../../ui'
import { FileUploader } from '../../../../../ui'
import { DOC_BUTTONS_CONFIG, HSE_URL, APPLICATION_STATES } from '../../../constants'
import { ALLOWED_EDIT_STATES, ALLOWED_SEND_BUDGET_STATES } from '../../../constants'
import { feedback } from '../../../../../core/feedback'
import { ViewDocumentsBtn } from '../../view-documents'
import { SendBudgetDialog } from '../../dialogs'
import { useAppContext } from '../../../contexts'
import { useSelector } from 'react-redux'
import { BillingDataDialog } from '../../dialogs'

export const DocumentationPanel = () => {
  const { formatMessage } = useIntl()
  const {
    application,
    updateBudget,
    generateBudget,
    sendBudget,
    acceptBudget,
    loading,
    sendPaymentFile,
    sendForm,
    getPaymentInfo,
  } = useAppContext()
  const { disabledForUser } = useAppContext()
  const defaultDisabled = disabledForUser()
  const createBudgetDisabled =
    defaultDisabled ||
    !ALLOWED_EDIT_STATES[application['estado']] ||
    !application?.presupuesto_exterior ||
    !application.presupuesto_exterior.d_croquis ||
    !application.presupuesto_exterior.distancia_real == null ||
    /* (!application.cod_expediente_zeus &&
      application.gran_consumo_id &&
      application.uso !== 'pymes') */
    (application.cod_solicitud_zeus === null
      ? false
      : application.cod_expediente_zeus !== null
      ? false
      : true) ||
    loading
  const {
    actions: { open },
  } = useModalContext()

  const role = useSelector((state) => state.global.role)
  const PAYMENT_FILE_SIZE = 10000000

  const [createBudgetLoading, setCreateBudgetLoading] = useState(false)
  const [canAcceptBudget, setCanAcceptBudget] = useState(false)
  const [canUploadDoc, setCanUploadDoc] = useState(false)

  const handleUploadError = (data) => {
    return feedback('error', data.mssg)
  }

  const handleUploadSuccess = (data, docId) => {
    const payload = { [docId]: data['id'] }
    updateBudget(payload)
      .then(() => {
        if (application['estado'] === APPLICATION_STATES.BUDGET_CREATED.KEY) {
          generateBudget()
        }
      })
      .catch(() => console.error('Error uploading croquis file'))
  }

  const handleSendPresupuesto = () => {
    open({
      Component: SendBudgetDialog,
      data: { application, sendBudget },
      type: 'responsive',
    })
  }

  const handleGenerateBudget = () => {
    setCreateBudgetLoading(true)
    generateBudget()
      .then(() => setCreateBudgetLoading(false))
      .catch(() => setCreateBudgetLoading(false))
  }

  const handleAcceptBudget = useCallback(
    () => {
      open({
        Component: ConfirmationDialog,
        data: {
          title: formatMessage({ id: 'pages.application.detail.acceptBudget.title' }),
          text: formatMessage({ id: 'pages.application.detail.acceptBudget.text' }),
          yesText: formatMessage({ id: 'pages.application.detail.acceptBudget.yes' }),
          noText: formatMessage({ id: 'pages.application.detail.acceptBudget.no' }),
          yesAction: () => {
            acceptBudget()
          },
        },
      })
    },
    [application] // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    setCanAcceptBudget(
      application['estado'] === '30' &&
        (role === 'gestor' || role === 'delegado' || role === 'gestor_gc')
    )
    setCanUploadDoc(
      application['estado'] === '35' &&
        (role === 'gestor' || role === 'delegado' || role === 'gestor_gc')
    )
  }, [application, role])

  const handleUploadFile = (data) => {
    handleOpenModal(true, data)
  }

  const handleOpenModal = (isTransferencia, data) => {
    open({
      Component: BillingDataDialog,
      data: { application, isTransferencia, sendPaymentFile, data, sendForm, getPaymentInfo },
      maxWidth: 'md',
      type: 'responsive',
      closable: true,
    })
  }

  return (
    <FoldingPanel
      isExpanded
      title={formatMessage({ id: 'pages.application.detail.documentation' })}
    >
      <Box display="flex" justifyContent="space-between" flexWrap="wrap" width={'100%'}>
        <Box>
          <Box display="flex" flexDirection="horizontal">
            <Typography style={{ marginRight: '0.5em' }} variant="subtitle2">
              {formatMessage({ id: 'pages.application.detail.croquisButton.title' })}
            </Typography>
            <UploadTooltip file={DOC_BUTTONS_CONFIG(application, formatMessage).CROQUIS} />
          </Box>
          <FileUploader
            {...DOC_BUTTONS_CONFIG(application, formatMessage).CROQUIS}
            disabled={!ALLOWED_EDIT_STATES[application['estado']] || defaultDisabled || loading}
            onSuccess={(data) =>
              handleUploadSuccess(data, DOC_BUTTONS_CONFIG(application, formatMessage).CROQUIS.id)
            }
            onError={(data) => handleUploadError(data)}
          />
        </Box>
        {/* <Box>
          <Box display="flex" flexDirection="horizontal">
            <Typography style={{ marginRight: '0.5em' }} variant="subtitle2">
              {formatMessage({ id: 'pages.application.detail.talloButton.title' })}
            </Typography>
            <UploadTooltip file={DOC_BUTTONS_CONFIG(application, formatMessage).TALLO} />
          </Box>
          <FileUploader
            {...DOC_BUTTONS_CONFIG(application, formatMessage).TALLO}
            disabled={!ALLOWED_EDIT_STATES[application['estado']] || defaultDisabled || loading}
            onSuccess={(data) =>
              handleUploadSuccess(data, DOC_BUTTONS_CONFIG(application, formatMessage).TALLO.id)
            }
            onError={(data) => handleUploadError(data)}
          />
        </Box> */}
        <Box display="flex" flexDirection="column" mt={1}>
          <Box display="flex" mb={2}>
            <ThemeButton
              color="primary"
              size="small"
              disabled={createBudgetDisabled}
              onClick={handleGenerateBudget}
              loading={createBudgetLoading}
            >
              {formatMessage({ id: 'pages.application.detail.generateButton.label' })}
            </ThemeButton>
            <Tooltip
              arrow
              title={formatMessage({ id: 'pages.application.detail.generateButton.tooltip' })}
            >
              <div data-role="upload help label">
                <Icon fontSize="inherit">help_outline</Icon>
              </div>
            </Tooltip>
          </Box>
          <ViewDocumentsBtn />
        </Box>
        <Box display="flex" flexDirection="column" mt={1}>
          <Box mb={2}>
            <ThemeButton
              color="primary"
              size="small"
              onClick={handleSendPresupuesto}
              disabled={
                !ALLOWED_SEND_BUDGET_STATES[application['estado']] || defaultDisabled || loading
              }
            >
              {formatMessage({
                id: 'pages.application.detail.sendButton.label',
              })}
            </ThemeButton>
          </Box>
          {canAcceptBudget && (
            <Box mb={2}>
              <ThemeButton color="secondary" size="small" onClick={handleAcceptBudget}>
                {formatMessage({
                  id: 'pages.application.detail.acceptButton.label',
                })}
              </ThemeButton>
            </Box>
          )}
          {canUploadDoc && (
            <Box>
              <FileUploader
                size={'small'}
                maxSize={PAYMENT_FILE_SIZE}
                applicationId={application['id']}
                budgetId={application['presupuesto_exterior']['id']}
                clientId={application.usuario_id}
                label={formatMessage({
                  id: 'pages.gasApplications.wizard.payment.button2.label',
                })}
                icon={<PublishOutlinedIcon />}
                color="secondary"
                type="comprobante_pago"
                accept={['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']}
                onSuccess={handleUploadFile}
                onError={(data) => handleUploadError(data)}
                style={{ marginRight: '1.5em' }}
              />
            </Box>
          )}
          <Box mt={application.estado === '35' || application.estado === '30' ? 2 : 0}>
            <ThemeButton color="primary" size="small" onClick={() => window.open(HSE_URL)}>
              {formatMessage({
                id: 'pages.application.detail.hseButton.label',
              })}
            </ThemeButton>
          </Box>
        </Box>
      </Box>
    </FoldingPanel>
  )
}
