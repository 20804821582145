import React, { createContext, useContext } from 'react'
import { useSirReducer } from '../hooks/jobs-reducer.hook'

const JobsContext = createContext()

export const JobsContextProvider = ({ children }) => {
  const reducer = useSirReducer()
  return <JobsContext.Provider value={reducer}>{children}</JobsContext.Provider>
}

export const useJobsContext = () => useContext(JobsContext)