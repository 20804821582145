import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

const InvalidatePaymentConfirmForm = ({ application, invalidatePayment, close }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    setLoading(true)
    invalidatePayment(application, formState)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => setLoading(false))
  }

  return (
    <form onSubmit={handleConfirm}>
      <Box mb={4}>
        <TextInput
          {...{
            multiline: true,
            rows: 5,
            inputProps: { maxLength: TEXT_AREA_LENGTH },
            name: 'motivo',
            value: formState['motivo'],
            required: true,
            onChange: handleChange,
          }}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton type="submit" color="primary" labelM="lg" disabled={loading} loading={loading}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </form>
  )
}

export const InvalidatePaymentConfirmDialog = ({ application, invalidatePayment, close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.sir.dialogs.invalidatePayment.title' })}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="body1">
          {formatMessage(
            { id: 'pages.sir.dialogs.invalidatePayment.message' },
            { ref: application['solicitud_id'] }
          )}
        </Typography>
      </Box>
      <InvalidatePaymentConfirmForm {...{ application, invalidatePayment, close }} />
    </Box>
  )
}
