import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

export const SendIntAndExtBudgetDialog = ({ close, application, sendBudget, isExtBudget }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})

  const handleSendPresupuesto = () => {
    setLoading(true)
    const payload = {
      nombre: application.usuario.nombre,
      solicitud_id: application['id'],
      presupuesto_id: isExtBudget
        ? application.presupuesto_exterior.id
        : application.presupuesto_interior.id,
      mail_cliente: application.usuario.email,
      ...formState,
    }
    sendBudget(payload)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  return (
    <Box p={4}>
      <Box mb={2} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.application.detail.sendBudget.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="body1">
          {formatMessage({ id: 'pages.application.detail.sendBudget.description' })}
        </Typography>
      </Box>
      <Box mb={4}>
        <TextInput
          name="comentario"
          value={formState['comentario']}
          multiline
          rows={5}
          inputProps={{ maxLength: TEXT_AREA_LENGTH }}
          onChange={handleChangeForm}
        />
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          color="primary"
          onClick={handleSendPresupuesto}
          labelM="lg"
          disabled={loading}
          loading={loading}
        >
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
