import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ThemeButton, BasicTable } from '../../../../ui'
import { downloadFile } from '../../../../core/utils'
import { CURRENT_DOCUMENTS_LIST_CONFIG, FILE_FIELD_NAMES } from '../../constants'
import { DOCUMENT_ATTACHMENT_CONFIG } from '../../../documents/constants/document.constants'

export const DocumentsViewComponent = ({ close, application = {} }) => {
  const { formatMessage } = useIntl()
  const { getById } = useAjaxContext()
  const [loading, setLoading] = useState(false)

  const [currentDocuments, setCurrentDocuments] = useState([])

  useEffect(() => {
    getListItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getListItems = () => {
    let currentDocuments = []

    FILE_FIELD_NAMES.forEach((doc) => {
      const el = DOCUMENT_ATTACHMENT_CONFIG[doc]
      if (!el) {
        return
      }
      const documentData = !el.subfield
        ? application[el.model]
        : application[el.subfield] && application[el.subfield][el.model]
        ? application[el.subfield][el.model]
        : {}
      if (!documentData || !documentData.id) {
        return
      }
      currentDocuments.push({
        id: documentData.id,
        tipo: formatMessage({
          id: `pages.supervision.edit.documents.${doc}`,
        }),
        nombre: documentData.nombre,
        dg_ts_insert: documentData.dg_ts_insert,
      })
    })

    setCurrentDocuments(currentDocuments)
  }

  const handleDownloadFile = ({ id }) => {
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box p={4}>
      {currentDocuments.length === 0 ? (
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.view.documentViewer.notFound' })}
          </Typography>
        </Box>
      ) : (
        <>
          {currentDocuments.length !== 0 && (
            <Box mb={4}>
              <Typography variant="h6" color="primary" paragraph>
                {formatMessage({ id: 'pages.application.view.documentViewer.documentsTitle' })}
              </Typography>
              <BasicTable
                config={CURRENT_DOCUMENTS_LIST_CONFIG}
                data={currentDocuments}
                onClick={handleDownloadFile}
                loading={loading}
              />
            </Box>
          )}
        </>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={() => close()} color="primary" labelM="lg" loading={loading}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
