import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ActionButton, useModalContext } from '../../../../ui'
import {
  CANT_UPLOAD_DOCS_STATES,
  APP_FINISHED_STATES,
  CAN_SEE_CERTIFICATE,
} from '../../constants'
import { ReactComponent as UploadDocumentsIcon } from '../../../../resources/images/icon_upload_documents.svg'
import { NewCertificateUnavailableDialog } from '../dialogs'
import { DocumentsUpload } from '../documents-upload'

import { RequestGasPComponent } from '../request-gas_p'
import { ReactComponent as NewCertificateIcon } from '../../../../resources/images/icon_new_certificate.svg'

export const HeaderButtons = ({ application, updateApplicationDocs, postGASP, putGASP }) => {
  const { formatMessage: fm } = useIntl()
  const {
    actions: { open },
    isOpen,
  } = useModalContext()

  const { role } = useSelector((state) => state.global)
  const history = useHistory()
  const { appId } = useParams()

  const getApplicationlWithActiveBudget = (application) => {
    const { presupuesto_exterior: presupuesto, ...restApplication } = application
    return {
      ...restApplication,
      presupuesto: presupuesto,
    }
  }
  const uploadDocumentsOnClick = useCallback(
    () =>
      open({
        Component: DocumentsUpload,
        data: { application: getApplicationlWithActiveBudget(application), updateApplicationDocs },
        type: 'responsive',
        maxWidth: 'sm',
      }),
    [application] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const requestGASPOnClick = useCallback(
    () =>
      open({
        Component: RequestGasPComponent,
        data: { application: getApplicationlWithActiveBudget(application), postGASP, putGASP },
        type: 'responsive',
        maxWidth: 'sm',
      }),
    [application] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateModalDeps = useCallback(
    () => {
      if (isOpen) uploadDocumentsOnClick()
    },
    [isOpen, application] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const headerButtonsConfig = () => [
    {
      id: 'newCertificate',
      label: fm({ id: 'pages.application.detail.actionButtons.newCertificate.label' }),
      icon: () => <NewCertificateIcon />,
      hidden: !CAN_SEE_CERTIFICATE[role] ? true : application.estado === '10' ? true : false,
      onClick: () => {
        if (
          APP_FINISHED_STATES[application.estado] ||
          (application['gran_consumo_id'] &&
            application['gran_consumo']['ubi_equipo_medida'] === 'Interior' &&
            !application['d_ACIF'])
        ) {
          open({
            Component: NewCertificateUnavailableDialog,
            type: 'responsive',
            maxWidth: 'sm',
            data: { application },
          })
        } else {
          history.push(`/applications/${appId}/certificates`)
        }
      },
    },
    {
      id: 'uploadDocuments',
      label: fm({ id: 'pages.application.detail.actionButtons.uploadDocuments.label' }),
      icon: () => <UploadDocumentsIcon />,
      hidden:
        CANT_UPLOAD_DOCS_STATES[application.estado] ||
        role === 'call_center' ||
        application.estado === '10',
      onClick: uploadDocumentsOnClick,
    },
    {
      id: 'request_gas_p',
      label: application.gas_p
        ? fm({ id: 'pages.application.detail.actionButtons.modify_request_gas_p.label' })
        : fm({ id: 'pages.application.detail.actionButtons.request_gas_p.label' }),
      icon: () => <UploadDocumentsIcon />,
      hidden:
        CANT_UPLOAD_DOCS_STATES[application.estado] ||
        !application.gran_consumo_id || role === 'gestor_gc' ||
        (application.subtipo_solicitud === 'pdte_normalizar_direccion' &&
          application.gas_p == null) ||
        application.estado === '10',
      onClick: requestGASPOnClick,
    },
  ]

  useEffect(() => {
    updateModalDeps()
  }, [application]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" flexWrap="wrap">
      {headerButtonsConfig(application, role).map((button, i) => {
        return !button.hidden ? <ActionButton key={i} {...button} /> : null
      })}
    </Box>
  )
}
