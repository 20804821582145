import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Box, Checkbox, Typography } from '@material-ui/core'

import { useAjaxContext } from '../../../../../core/ajax/contexts'
import { ConfirmationDialog, useModalContext } from '../../../../../ui'
import { useAppContext } from '../../../contexts'
import { feedback } from '../../../../../core/feedback'

export const SendToZeusCheckbox = () => {
  const { formatMessage, formatDate } = useIntl()
  const { putById } = useAjaxContext()
  const {
    actions: { open },
  } = useModalContext()
  const { application, getApplication } = useAppContext()

  const postCheckEnviarScr = useCallback((application, checked) => {
    const payload = {
      enviar_scr: checked,
    }
    return new Promise((resolve, reject) =>
      putById('/scr/check_enviar_scr', application.scr_id, payload)
        .then(({ data }) => {
          if (checked && data.message) feedback('info', data.message)
          getApplication(application.id).then(() => resolve())
        })
        .catch(({ response }) => {
          console.error('response: ', response)
          reject()
        })
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openModalCheckSentToZeus = (e) => {
    const checked = e.target.checked
    const TXT_ROOT = 'pages.application.detail.modal.checkboxSentToZeus.'
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: TXT_ROOT + 'title' }),
        text: formatMessage({ id: TXT_ROOT + 'text.' + (checked ? '1' : '2') }),
        yesText: formatMessage({ id: TXT_ROOT + 'yes' }),
        noText: formatMessage({ id: TXT_ROOT + 'no' }),
        yesAction: () => postCheckEnviarScr(application, checked),
      },
      type: 'centered',
    })
  }

  return (
    <Box display="flex" style={{ alignItems: 'center' }} mb={1}>
      <Checkbox
        {...{
          size: 'small',
          color: 'primary',
          checked: application.enviar_scr,
          onChange: openModalCheckSentToZeus,
        }}
      />
      <Typography variant="subtitle2">
        {`${formatMessage({
          id: `pages.application.view.detail.information.checkboxSentToZeus.label.${
            application.enviar_scr ? '2' : '1'
          }`,
        })} ${
          application.fecha_solicitud_zeus
            ? formatMessage(
                {
                  id: 'pages.application.view.detail.information.checkboxSentToZeus.label.3',
                },
                { date: formatDate(application.fecha_solicitud_zeus) }
              )
            : ''
        }`}
      </Typography>
    </Box>
  )
}
