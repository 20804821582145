export const JCA_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_FAILURE: 'SET_FAILURE',
  SET_JCA: 'SET_JCA',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
  SET_DETAIL_LOADING: 'SET_DETAIL_LOADING',
  SET_DETAIL_FAILURE: 'SET_DETAIL_FAILURE',
  SET_JCA_STATE: 'SET_JCA_STATE',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_JCA_EVENTS: 'SET_JCA_EVENTS',
}

export const JCA_INITIAL_STATE = {
  data: [],
  search: {},
  pagination: {},
  loading: false,
  detailLoading: true,
  detailError: false,
  msLoaded: false,
  error: false,
  jca: {},
  tableSelection: [],
  editMode: false,
  events: [],
}
