import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, useModalContext, RatingInput } from '../../../../ui'
import { ReviewsDialog } from './reviews-dialog.component'

export const ReviewsBtn = ({ formState, isPrimaryCompany }) => {
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenCancelDialog = () => {
    open({
      Component: ReviewsDialog,
      data: { companyId: formState.id, isPrimaryCompany },
      type: 'responsive',
      closable: true,
      maxWidth: 'md',
    })
  }

  return (
    <>
      <Typography variant="subtitle2">Valoración</Typography>
      <Box display="flex" alignItems="center">
        <RatingInput
          {...{
            name: 'valoracion',
            value: formState.valoracion_media || 0,
            readOnly: true,
          }}
        />
        <Typography variant="body1" style={{ margin: '0 10px 0 6px' }}>
          ({parseFloat(formState.valoracion_media).toFixed(2)})
        </Typography>
        <ThemeButton color="primary" size="small" onClick={handleOpenCancelDialog}>
          Ver Valoraciones
        </ThemeButton>
      </Box>
    </>
  )
}
