import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Grid, Tooltip, Chip, Typography, CircularProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AttachFileIcon from '@material-ui/icons/AttachFile'

import { downloadFile } from '../../../../core/utils'
import { requests } from '../../../../core/ajax'
import { TextInput, SelectInput, ThemeButton, ConfirmationDialog } from '../../../../ui'
import { useModalContext } from '../../../../ui'
import { COMPANY_FORM_CONFIG } from '../../constants'
import feedback from '../../../../core/feedback'

export const IpIBANDisplayer = ({ inputProps, getEIData }) => {
  const { formState, intl } = inputProps
  const fm = intl.formatMessage
  const permissions = useSelector((state) => state.global.permissions)
  const {
    actions: { open },
  } = useModalContext()
  const boxProps = {
    bgcolor: 'grey.200',
    borderRadius: 4,
    p: 2,
    display: 'flex',
  }

  const [loading, setLoading] = useState(false)

  const handleChangeState = (action) => {
    const { put } = requests
    const companyId = formState.id
    const ibanState = action === 'validate' ? '20' : '01'
    put(`/empresa/modificar_estado_iban/${companyId}/${ibanState}`)
      .then(() => {
        getEIData()
      })
      .catch((response) => {
        feedback('error', response.data.message || 'No se ha podido completar la operación')
      })
  }

  const handleConfirm = (action) => {
    const TXT_ROOT = 'pages.eeii.detail.ibanDisplayer.modal.'
    open({
      Component: ConfirmationDialog,
      data: {
        title: fm({ id: TXT_ROOT + 'title' }),
        text: fm({ id: TXT_ROOT + 'descr.' + (action === 'validate' ? '1' : '2') }),
        yesText: fm({ id: TXT_ROOT + 'yes' }),
        noText: fm({ id: TXT_ROOT + 'no' }),
        yesAction: () => handleChangeState(action),
      },
      type: 'centered',
    })
  }

  const handleDownloadFile = (id) => {
    const { getById } = requests
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box {...boxProps}>
      <Grid container spacing={3}>
        {(formState.estado_iban === '15' || formState.estado_iban === '01') &&
        formState.iban_validado ? (
          <Grid item xs={12}>
            <Alert severity="info">
              <Typography variant="body1">
                {fm({ id: 'pages.eeii.detail.ibanDisplayer.existValidIBAN' })}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{formState.iban_validado}</strong>
              </Typography>
            </Alert>
          </Grid>
        ) : null}
        <Grid item xs={6} md={6}>
          <TextInput {...{ ...COMPANY_FORM_CONFIG(inputProps).IBAN }} />
          {formState.d_certificado_iban ? (
            <Tooltip
              arrow
              title={
                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                  {fm({ id: 'pages.eeii.detail.fields.certificado.tooltip' })}
                </Typography>
              }
            >
              <Chip
                avatar={
                  loading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <AttachFileIcon style={{ backgroundColor: 'transparent' }} />
                  )
                }
                label={`${formState.d_certificado_iban.nombre}.${formState.d_certificado_iban.extension}`}
                onClick={() => handleDownloadFile(formState.d_certificado_iban.id)}
              />
            </Tooltip>
          ) : null}
        </Grid>
        <Grid item xs={6} md={6}>
          <SelectInput {...{ ...COMPANY_FORM_CONFIG(inputProps).ESTADO_IBAN }} />
        </Grid>
        {permissions.modificar_estado_iban ? (
          <>
            {formState.estado_iban === '15' || formState.estado_iban === '01' ? (
              <Grid item xs={6} md={6}>
                <ThemeButton color="primary" onClick={() => handleConfirm('validate')}>
                  {fm({ id: 'pages.eeii.detail.ibanDisplayer.validateBttn.label' })}
                </ThemeButton>
              </Grid>
            ) : null}
            {formState.estado_iban === '15' || formState.estado_iban === '20' ? (
              <Grid item xs={6} md={6}>
                <ThemeButton color="primary" onClick={() => handleConfirm('reject')}>
                  {fm({ id: 'pages.eeii.detail.ibanDisplayer.rejectBttn.label' })}
                </ThemeButton>
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    </Box>
  )
}
