import { useEffect, useReducer } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { types } from '../constants'
import { feedback } from '../../../core/feedback'
import {
  assembleNormalizedHome,
  assembleNotNormalizedHome,
  checkConsultAnswers,
  createApp,
  // createAppAndCertificate,
  // checkUser,
  refineAddress,
} from '../logics'
import {
  getAppByCidi,
  getDataForm,
  // getEmpresaCAByZip,
  getFlats,
  getNumbers,
  getStreets,
  getZipMunicipalities,
  getInstallers,
} from '../calls'
// import { rollbackApp } from '../calls/consult-creation.calls'
import { consultReducer, consultInitialState } from '../reducers'
// import { APP_PATH_RULES } from '../constants/application-types.constants'
// import { assembleCertificate } from '../logics/object-assembling.logics'
// import { useSelector } from 'react-redux'

export const useConsultActions = () => {
  const [state, dispatch] = useReducer(consultReducer, consultInitialState)
  const intl = useIntl()
  const role = useSelector((state) => state.global.role)
  const userId = useSelector((state) => state.global.userId)

  const location = useLocation()
  const history = useHistory()
  const stepSearch = parseInt(new URLSearchParams(location.search).get('step'))
  const step = isNaN(stepSearch) ? 0 : stepSearch

  const setStep = (step, options = {}) => {
    if (step === 0) {
      dispatch({
        type: types.RENORMALIZE,
      })
    }
    if (options.replace) {
      history.replace({ search: `?step=${step}` })
    } else {
      history.push({ search: `?step=${step}` })
    }
  }

  useEffect(() => {
    if (step === 0 && !state.normalized) {
      dispatch({
        type: types.RENORMALIZE,
      })
    }
    if (
      (step === 1 && (!state.addressForm || !state.addressForm.cp_municipio)) ||
      // (step === 2 && (!state.selectedHome || !state.selectedHome['cod_postal'])) ||
      // (step === 3 &&
      //   (!state.selectedHome ||
      //     !state.consultData['flowType'] ||
      //     !APP_PATH_RULES[state.consultData['flowType']]?.certificate ||
      //     !state.certificateForm ||
      //     !state.certificateForm['tipo_mercado'])) ||
      (step > 1 && role !== 'gestor_gc')
    ) {
      dispatch({
        type: types.RESET,
      })
      setStep(0, { replace: true })
    }
  }, [step]) // eslint-disable-line react-hooks/exhaustive-deps

  const actions = {
    reset: () => {
      dispatch({
        type: types.RESET,
      })
      setStep(0)
    },
    changeStep: (payload, options = {}) => {
      setStep(payload, options)
    },
    nextStep: (options) => {
      setStep(step + 1, options)
    },
    prevStep: () => {
      setStep(step - 1)
    },
    changeToNotNormalized: (dataUpdate = {}) => {
      const homeData = assembleNotNormalizedHome(state, dataUpdate)
      dispatch({
        type: types.CHANGE_TO_UNNORMALIZED,
        payload: homeData,
      })
      setStep(1)
    },
    changeAddressForm: ({ value, name }) => {
      if (value) {
        if (name === 'direccion') {
          getNumbers(state.addressForm, value).then((data) => {
            dispatch({
              type: types.SET_NUMBER_VALUES,
              payload: data,
            })
          })
        }
        if (name === 'numero') {
          getFlats(state.addressForm, value).then((data) => {
            dispatch({
              type: types.SET_HOME_VALUES,
              payload: data,
            })
          })
        }
      } else {
        dispatch({
          type: types.SET_ADDRESS_FORM,
          payload: { key: name, value: null },
        })
      }
      dispatch({
        type: types.RESET_ADDRESS_FORM,
        payload: refineAddress(name, value, state),
      })
    },
    changeNotNormalizedAddressForm: ({ value, name }) => {
      dispatch({
        type: types.SET_SELECTED_HOME,
        payload: {
          ...state.selectedHome,
          [name]: value,
        },
      })
      dispatch({
        type: types.SET_LOADING,
        payload: false,
      })
    },
    getTownValues: (value) => {
      getZipMunicipalities(value).then((data) => {
        dispatch({
          type: types.SET_TOWN_VALUES,
          payload: data,
        })
      })
    },
    getInstallerValues: (value) => {
      getInstallers(value).then((data) => {
        dispatch({
          type: types.SET_INSTALLER_VALUES,
          payload: data,
        })
      })
    },
    getAddressValues: (value) => {
      getStreets(state.addressForm, value).then((data) => {
        dispatch({
          type: types.SET_ADDRESS_VALUES,
          payload: data,
        })
      })
    },
    changeIsContratable: (payload) => {
      dispatch({
        type: types.SET_IS_CONTRATABLE,
        payload: payload,
      })
    },
    getConsult: (selectedHome, installerId) => {
      const homeData = assembleNormalizedHome(selectedHome, state)
      dispatch({
        type: types.SET_SELECTED_HOME,
        payload: homeData,
      })
      getDataForm(selectedHome['cidi']).then((consultResponse) => {
        //getEmpresaCAByZip(installerId, homeData['cod_postal']).then((empresa) => {
        dispatch({
          type: types.SET_CONSULT_DATA,
          payload: checkConsultAnswers(selectedHome['estado'], consultResponse, homeData),
        })
        setStep(1)
        //})
      })
    },
    // setEmpresa: (empresa) => {
    //   dispatch({
    //     type: types.SET_EMPRESA,
    //     payload: empresa,
    //   })
    // },
    changeCustomerForm: ({ value, name }) => {
      dispatch({
        type: types.SET_CUSTOMER_FORM,
        payload: { key: name, value },
      })
    },
    changeCustomerFormArray: (array) => {
      dispatch({
        type: types.SET_CUSTOMER_FORM_ARRAY,
        payload: array,
      })
    },
    checkIfAppExist: async (cidi) => {
      return new Promise((resolve, reject) => {
        getAppByCidi(cidi).then((data) => {
          const existenceState = data['solicitud_existente']
          if (existenceState === 0 && data.bloqueo_creacion_zeus) {
            return feedback('error', data.bloqueo_creacion_zeus)
          }
          switch (existenceState) {
            //Cases with errors:
            case 1:
              feedback(
                'error',
                intl.formatMessage({ id: 'pages.consult.existence.error.' + existenceState })
              )
              return reject(existenceState)
            case 2:
            case 3:
            default:
            //In contrast to APINS, here they are allowed to proceed
          }
          resolve()
        })
      })
    },
    createApplication: (profile = { type_profile: 'instalador' }) => {
      return new Promise((resolve, reject) => {
        dispatch({
          type: types.SET_LOADING,
          payload: true,
        })
        createApp(state, profile, userId)
          .then((id) => {
            dispatch({
              type: types.RESET,
            })
            setStep(0)
            return resolve(id)
          })
          .catch((error) => {
            dispatch({
              type: types.SET_LOADING,
              payload: false,
            })
            return reject()
          })
      })
    },
    //   prepareCertificate: () => {
    //     dispatch({
    //       type: types.SET_LOADING,
    //       payload: true,
    //     })
    //     checkUser(state)
    //       .then(() => {
    //         dispatch({
    //           type: types.PREPARE_CERTIFICATE_FORM,
    //           payload: assembleCertificate(state),
    //         })
    //         setStep(3)
    //       })
    //       .catch((error) => {
    //         dispatch({
    //           type: types.SET_LOADING,
    //           payload: false,
    //         })
    //       })
    //   },
    //   changeCertificateForm: ({ value, name }) => {
    //     dispatch({
    //       type: types.SET_CERTIFICATE_FORM,
    //       payload: {
    //         key: name,
    //         value,
    //       },
    //     })
    //   },
    //   createCertificate: (
    //     installerId,
    //     appSubtype,
    //     documentsToUpload,
    //     documentsToUploadCertificate,
    //     endpoint
    //   ) =>
    //     new Promise((resolve, reject) => {
    //       //Start procedure
    //       dispatch({
    //         type: types.SET_LOADING,
    //         payload: true,
    //       })
    //       createAppAndCertificate(
    //         state,
    //         installerId,
    //         appSubtype,
    //         documentsToUpload,
    //         documentsToUploadCertificate,
    //         endpoint
    //       )
    //         .then(() => {
    //           feedback('success', 'Se ha creado el certificado')
    //           dispatch({
    //             type: types.RESET,
    //           })
    //           setStep(0)
    //           resolve()
    //         })
    //         .catch((error) => {
    //           dispatch({
    //             type: types.SET_LOADING,
    //             payload: false,
    //           })
    //           if (error.tmpApp) {
    //             rollbackApp(error.tmpApp)
    //           }
    //           return reject(error)
    //         })
    //     }),
  }

  return {
    ...actions,
    ...state,
    step,
  }
}
