export const IP_FILTERS = [
  {
    name: 'estado',
    comboId: 'estados_inspeccion',
    multiple: true,
  },
  {
    name: 'tipo_instalacion',
    comboId: 'tipo_instalacion',
    multiple: true,
  },
  {
    name: 'gremio_ip_id',
    comboId: 'gremios_ip',
    multiple: true,
  },
  {
    name: 'fecha_creacion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_creacion_fin',
  },
  {
    name: 'fecha_creacion_fin',
    filterType: 'date',
    minDateField: 'fecha_creacion_inicio',
  },
  {
    name: 'fecha_inspeccion_inicio',
    filterType: 'date',
    maxDateField: 'fecha_inspeccion_fin',
  },
  {
    name: 'fecha_inspeccion_fin',
    filterType: 'date',
    minDateField: 'fecha_inspeccion_inicio',
  },
]
