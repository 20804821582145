import React, { createContext, useContext } from 'react'

import { usePersistReducer } from '../../utils'
import { userReducer } from '../../user/reducers'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [userState, userDispatch] = usePersistReducer(userReducer, {}, 'apg-auth')

  return <UserContext.Provider value={{ userState, userDispatch }}>{children}</UserContext.Provider>
}

const useUserContext = () => useContext(UserContext)

export { UserContext, UserProvider, useUserContext }
