import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { MARKETERS_TABLE_ACTIONS, MARKETERS_TABLE_COLUMNS } from '../../constants'
import { TableUrlPagination } from '../../../../ui'
import { marketersTableStyles } from './marketers-table.styles'
import { TableActions, TableActive } from '../table-actions'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const MarketersTable = () => {
  const intl = useIntl()
  const {
    data,
    search,
    pagination: { total_comercializadoras: totalEntries },
    isLoading,
  } = useSelector((state) => state.marketers)
  const combos = useSelector((state) => state.combos)

  //Column structure
  const formattedColumns = useMemo(
    () =>
      [...MARKETERS_TABLE_COLUMNS, ...MARKETERS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'actions' || column.field === 'active') {
          return {
            ...column,
            title: intl.formatMessage({
              id: `pages.marketers.table.columns.${column.field}.title`,
            }),
          }
        }
        return {
          ...column,
          title: intl.formatMessage({ id: `pages.marketers.table.columns.${column.field}.title` }),
          cellStyle: { ...marketersTableStyles.cell, minWidth: column.minWidth },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  const processedData = useMemo(
    () =>
      data.map((item) => {
        return {
          ...item,
          tableData: {
            ...item.tableData,
          },
        }
      }),
    [data] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      fixedColumns: {
        right: 2,
      },
      headerStyle: marketersTableStyles.header,
      draggable: false,
    }),
    []
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const line = (column) => {
          switch (column) {
            case 'actions':
              return <TableActions {...props} />
            case 'active':
              return <TableActive {...props} />
            default:
              return props.children
          }
        }
        return (
          <MTableCell {...props} style={{ padding: 0 }}>
            {line(props.columnDef.field)}
          </MTableCell>
        )
      },
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Not transformed in the url table interface
      data={processedData}
      isLoading={isLoading}
    />
  )
}
