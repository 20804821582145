import React from 'react'
import { withStyles, Typography, Container, Box, CircularProgress } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'

import { pageDisplayerStyles } from './page-displayer.styles'

export const PageDisplayer = withStyles(pageDisplayerStyles)(
  ({
    loading = false,
    error = false,
    handleGoBack,
    textGoBack = 'Back',
    maxWidth = 'lg',
    classes,
    children,
    padding = ['15px', '20px 40px'],
  }) => {
    if (loading) {
      return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <CircularProgress size={40} />
        </Box>
      )
    }
    if (error) {
      return (
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <p>{error.message}</p>
        </Box>
      )
    }
    return (
      <Container className={classes.root} maxWidth={maxWidth}>
        <Box height="100%" mt={[2, 4]}>
          <div className={classes.return} onClick={handleGoBack}>
            <ArrowBackIos fontSize="inherit" />
            <Typography variant="body1">{textGoBack}</Typography>
          </div>
          <Box bgcolor="common.white" p={padding}>
            {children}
          </Box>
        </Box>
      </Container>
    )
  }
)

export default PageDisplayer
