import React from 'react'
import { Paper, Box, List, ListItem, ListItemText, Icon } from '@material-ui/core'

export const ProvinceList = ({ className, provinces, handleSelectProvince, checkedProvinces }) => {
  const checkedIds = checkedProvinces.map((prov) => prov.id)
  return (
    <Paper elevation={2} className={className.provinciaLine}>
      <Box>
        <List>
          {provinces.map((province) => (
            <ListItem
              id={province.id}
              key={province.id}
              onClick={() => handleSelectProvince(province)}
            >
              <ListItemText>{province.nombre}</ListItemText>
              <Icon style={{ visibility: checkedIds.includes(province.id) ? 'visible' : 'hidden' }}>
                check_circle
              </Icon>
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
  )
}
