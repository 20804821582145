export const GRANCONSUMO_TABLE_COLUMNS = [
  {
    field: 'comunidad',
    align: 'left',
    width: 180,
  },
  {
    field: 'provincia',
    align: 'left',
    width: 180,
  },
  { field: 'municipio', align: 'left', width: 180 },
  {
    field: 'codigo_postal',
    width: 180,
    align: 'left',
  },
  {
    field: 'grupo_presion',
    align: 'left',
    width: 180,
  },
  { field: 'mercado', align: 'left', width: 180 },
  { field: 'nombre_gestor', align: 'left', width: 180 },
  { field: 'email', width: 180, align: 'left' },
  { field: 'telefono', width: 180, align: 'left' },
]

export const GRANCONSUMO_TABLE_FILTERS = [
  {
    name: 'comunidad',
    label: 'comunidad',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
  },
  {
    name: 'provincia',
    label: 'provincia',
    comboId: 'provincias',
    size: 'small',
    multiple: true,
  },
  {
    name: 'municipio',
    filterType: 'basic-input',
    size: 'small',
  },
  {
    name: 'cp',
    filterType: 'basic-input',
    size: 'small',
  },
  { name: 'mercado', label: 'mercado', comboId: 'tipo_mercado_gc', size: 'small', multiple: true },
  { name: 'gestor', label: 'Responsable', comboId: 'gestores', size: 'small', multiple: true },
]
