import React, { createContext, useContext } from 'react'
import { usePublicOfferReducer } from '../hooks/public-offer-reducer.hook'

const PublicOfferContext = createContext()

export const PublicOfferContextProvider = ({ children }) => {
  const reducer = usePublicOfferReducer()
  return <PublicOfferContext.Provider value={reducer}>{children}</PublicOfferContext.Provider>
}

export const usePublicOfferContext = () => useContext(PublicOfferContext)
