import React from 'react'

import { CompanyForm } from '../form'
import { EEIITabs } from '../tabs'

export const EEIIPanel = ({ companyData, getEIData }) => {
  return companyData.captadora ? (
    <EEIITabs
      {...{
        primaryCompany: companyData,
        secondaryCompanies: companyData.empresas_filiales,
        getEIData,
      }}
    />
  ) : (
    <CompanyForm {...{ model: companyData, isPrimaryCompany: true, getEIData }} />
  )
}
