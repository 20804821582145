import {
  getUserConsistency,
  postApp,
  postProvisionalApp,
  postUsuario,
  updateAppDocs,
} from '../calls'
import {
  assembleCustomerFromState,
  assembleDocumentPayload,
  assemblePayloadFromTmpApp,
  assembleTmpAppFromState,
} from './object-assembling.logics'
import { APP_TYPE, DOCUMENT_ATTACHMENT_CONFIG } from '../constants'
import { postDoc } from '../../documents'
import { toast } from 'react-toastify'

class InvalidUserError extends Error {
  constructor(message) {
    super(message)
    this.code = 'invalid_user'
  }
}

export const checkUser = (email, identifier) => {
  return new Promise(async (resolve, reject) => {
    const userValidity = await getUserConsistency(
      email,
      identifier
    )
    if (!userValidity?.validez_usuario) {
      var message
      switch (userValidity.code) {
        case 3512:
          message = 'Este mail es inválido o no existe.'
          break
        case 3635:
          message = 'Este NIF ya está enlazado con otro correo electrónico'
          break
        case 3636:
          message = 'Este correo electrónico ya está enlazado con otro NIF'
          break
        case 3637:
          message =
            'Ya existe una solicitud con diferente correo electrónico/NIF para este mismo punto de suministro'
          break
        default:
          message = userValidity.error || 'El usuario no es válido'
          break
      }
      return reject(new InvalidUserError(message))
    }
    resolve(userValidity)
  })
}

const stepTemporaryApp = (state, profile, userId) => {
  return new Promise(async (resolve, reject) => {
    const tmpApplication = await postProvisionalApp(assembleTmpAppFromState(state, profile, userId))
    if (!tmpApplication || !tmpApplication.id) return reject('no_tmp_app')
    resolve(tmpApplication)
  })
}

const stepUser = (state) => {
  return new Promise((resolve, reject) => {
    postUsuario(assembleCustomerFromState(state))
      .then((userData) => {
        resolve(userData['id'])
      })
      .catch(() => {
        return reject('user_failed')
      })
  })
}

const stepApp = (appSubtype, state, tmpApplication, id_client) => {
  return new Promise((resolve, reject) => {
    postApp(assemblePayloadFromTmpApp(appSubtype, state, tmpApplication, id_client))
      .then((appData) => {
        resolve({
          id_app: appData['id'],
          id_budget: appData['presupuesto_exterior']?.id,
          appData,
        })
      })
      .catch(() => {
        return reject('app_failed')
      })
  })
}

const stepDocuments = (form, id_client, id_app, id_budget) => {
  return new Promise((resolve, reject) => {
    Promise.all(
      Object.keys(DOCUMENT_ATTACHMENT_CONFIG)
        .filter((doc) => form[doc])
        .map(async (doc) => {
          const file = DOCUMENT_ATTACHMENT_CONFIG[doc]
          const documentResponse = await postDoc(
            assembleDocumentPayload(file, form, id_client, id_app, id_budget)
          )
          return { id: file.id, field: file.field, value: documentResponse.id }
        })
    )
      .then((uploadedDocuments) => {
        resolve(uploadedDocuments)
      })
      .catch((err) => {
        return reject('doc_failed')
      })
  })
}

const stepLinkDocuments = (uploadedDocuments, id_budget, id_app) => {
  return new Promise(async (resolve, reject) => {
    const payload = uploadedDocuments.reduce(
      (docs, doc) => ({
        ...docs,
        [doc.id]: doc.value,
      }),
      {}
    )

    try {
      await updateAppDocs(id_app, payload).catch(() => {
        throw new Error('app_update_failed')
      })
    } catch (err) {
      return reject(err)
    }
    resolve()
  })
}

export const createApp = (state, profile, userId) => {
  return new Promise(async (resolve, reject) => {
    let tmpApplication = {}
    try {
      tmpApplication = await stepTemporaryApp(state, profile, userId).catch((err) => {
        throw new Error(err)
      })

      if (profile === 'gestor_gc') {
        const id_client = await stepUser(state).catch((err) => {
          throw new Error(err)
        })

        const { id_app, id_budget } = await stepApp(
          state.customerForm.isNN ? APP_TYPE.NN : APP_TYPE.SCR,
          state,
          tmpApplication,
          id_client
        ).catch((err) => {
          throw new Error(err)
        })

        const uploadedDocuments = await stepDocuments(
          state.customerForm,
          id_client,
          id_app,
          id_budget
        ).catch((err) => {
          throw new Error(err)
        })

        await stepLinkDocuments(uploadedDocuments, id_budget, id_app).catch((err) => {
          throw err
        })

        resolve(id_app)
      } else {
        resolve(tmpApplication.id)
      }
    } catch (err) {
      console.debug('error during app creation: ', err)
      toast.error(err)
      if (tmpApplication.id) {
        return reject({ tmpApp: tmpApplication.id })
      } else {
        return reject(err)
      }
    }
  })
}
