import { STUCK_ALERT_TYPES, STUCK_TABLE_COLUMNS } from '../constants'

export const stuckDataPreparation = (data, { intl }, combos) => {
  return (
    data?.map((item) => {
      const formattedColumns = {}
      STUCK_TABLE_COLUMNS.forEach((column) => {
        if (column.type === 'date' && item[column.field]) {
          formattedColumns[column.field] = intl.formatDate(item[column.field])
        }
        if (column.infoType === 'logic') {
          formattedColumns[column.field] = item[column.field] ? 'Sí' : 'No'
        }
      })
      return {
        ...item,
        ...formattedColumns,
        alertas: STUCK_ALERT_TYPES.filter((el) => {
          return item[el.type]
        })
          .map((el) => el.message)
          .join(' '),
        tableData: {
          ...item.tableData,
        },
      }
    }) || []
  )
}
