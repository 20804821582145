export const COMMS_CHANNELS = [
  {
    key: 'sistema',
    value: 'Sistema',
    readOnly: true,
  },
  {
    key: 'cliente_sir',
    value: 'Comunicación con Cliente',
    readOnly: false,
  },
  {
    key: 'instalador_sir',
    value: 'Comunicación con Instalador',
    readOnly: false,
  },
]
