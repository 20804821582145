import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { useSirContext } from '../../contexts'
import { ActionButton, useModalContext } from '../../../../ui'
import { DocumentsView } from '../documents-view'
import { DocumentsUpload } from '../documents-upload'
import { HEADER_BUTTONS_CONFIG } from './detail-header.constants'

export const DetailHeaderBtns = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, updateApplication, uploadDoc } = useSirContext()

  const handlers = {
    viewDocuments: () =>
      open({
        Component: DocumentsView,
        data: { budget: application.presupuesto_sir },
        type: 'responsive',
        maxWidth: 'sm',
      }),
    uploadDocuments: () =>
      open({
        Component: DocumentsUpload,
        data: { application, updateApplication, uploadDoc },
        type: 'responsive',
        maxWidth: 'sm',
      }),
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" flexGrow={2} flexWrap="wrap">
        {HEADER_BUTTONS_CONFIG(application).map((button, i) => (
          <ActionButton
            key={i}
            {...button}
            onClick={handlers[button.id]}
            label={formatMessage({ id: button.label })}
          />
        ))}
      </Box>
    </Box>
  )
}
