import React from 'react'

import { FilterBox } from '../../../../ui'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { JOBS_TABLE_FILTERS } from './jobs-table.constants'

export const JobsHeader = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const jobsLoader = useSelector((state) => state.loader.jobs)
  const combos = useSelector((state) => state.combos)
  const search = jobsLoader?.config?.params

  const data = JOBS_TABLE_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.jobs.table.filters.' + item.name + '.title',
      }),
    }
  })
  return (
    <>
      <FilterBox filters={data}></FilterBox>
    </>
  )
}
