import React from 'react'
import EuroIcon from '@material-ui/icons/Euro'

export const getFormattedValue = (original) => {
  const text = String(original).replace(/[^0-9.,]/g, '')
  const parts = text.split(/[.,]/)
  const separator = parts.length > 1 ? ',' : ''
  const val = parts[0] + separator + (parts.length > 1 ? parts[1].substring(0, 2) : '')
  return val
}

export const getEndAdornment = (units) => {
  switch (units) {
    case '€':
      return <EuroIcon style={{ opacity: 0.5, fontSize: 16 }} />
    default:
      return units
  }
}
