import React, { useEffect, useState, useCallback } from 'react'
import { Box, Paper, Typography, Backdrop, CircularProgress, withStyles } from '@material-ui/core'
import MaterialTable, { MTableCell } from 'material-table'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { useApplicationUserPermitsContext } from '../../../../pages/applications/contexts'
import { useApplicationsCalls } from '../../../applications/hooks'
import { applicationDataPreparation } from '../../../applications/logics'
import { tableSameCidiStyles } from './table-same-cidi.styles'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const TableSameCidi = withStyles(tableSameCidiStyles)(({ classes, id, cidi }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const { columns } = useApplicationUserPermitsContext()
  const combos = useSelector((state) => state.combos)
  const history = useHistory()
  const { getApplications } = useApplicationsCalls()

  const [applications, setApplications] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [moreDisclaimer, setMoreDisclaimer] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getApplications({ cidi })
      .then(({ data }) => {
        const sameCidiApplications = data.solicitudes.filter((application) => application.id !== id)
        setApplications(sameCidiApplications)
        setMoreDisclaimer(data.total_paginas > 1)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
  }, [id, cidi]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoToDetail = (e, row) => {
    history.push(`/applications/${row.id}`)
    // window.scrollTo(0, 0)
  }

  const formattedColumns = useCallback(
    columns
      .filter((column) => {
        return column.field !== 'comunicaciones_pendientes'
      })
      .map((column) => {
        return {
          ...column,
          title: formatMessage({ id: `pages.applications.table.columns.${column.field}.title` }),
          cellStyle: {
            ...tableSameCidiStyles.cell,
            ...column.cellStyle,
            minWidth: column.width,
            maxWidth: column.width,
          },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [columns, combos]
  )

  const processedData = useCallback(
    applicationDataPreparation(applications, { intl }, formattedColumns),
    [applications, formattedColumns]
  )

  const tableOptions = {
    toolbar: false,
    selection: false,
    headerStyle: tableSameCidiStyles.header,
    draggable: false,
    paging: false,
  }

  const tableComponents = {
    Container: ({ children }) => (
      <Paper elevation={0} style={{ width: '100%' }}>
        {children}
      </Paper>
    ),
    Cell: (props) => (
      <MTableCell {...props} style={{ padding: 0 }}>
        {props.children}
      </MTableCell>
    ),
  }

  if (isLoading) {
    return (
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  return applications.length ? (
    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
      <MaterialTable
        columns={formattedColumns}
        options={tableOptions}
        components={tableComponents}
        data={processedData}
        isLoading={isLoading}
        onRowClick={handleGoToDetail}
      />
      {moreDisclaimer ? (
        <span>
          Hay registradas más solicitudes para esa dirección. Por favor, contacta con el gestor de
          servicios si deseas obtenerlas todas
        </span>
      ) : null}
    </Box>
  ) : (
    <Box p={2}>
      <Typography variant="body1">No hay solicitudes en la misma dirección.</Typography>
    </Box>
  )
})

export default TableSameCidi
