import React from 'react'

import { SRContextProvider } from '../../features/sr'
import { SRRouter } from './routers'

export const SRPage = (props) => {
  return (
    <SRContextProvider>
      <SRRouter {...props} />
    </SRContextProvider>
  )
}
