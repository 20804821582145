export const PAYMENT_FILE_SIZE = 10000000

export const TEXT_AREA_LENGTH = 1000

export const CUSTOMER_FIELDS_CONFIG = ({
  formState,
  onChange,
  combos,
  intl,
  consultData,
  customerForm,
}) => ({
  NOMBRE: {
    value: formState['nombre'],
    name: 'nombre',
    title: 'Nombre',
    placeholder: 'Ej. Lucía',
    required: true,
    onChange,
  },
  APELLIDOS: {
    value: formState['apellidos'],
    name: 'apellidos',
    title: formState['persona'] === 'F' ? 'Apellidos' : 'Razón Social',
    placeholder: formState['persona'] === 'F' ? 'Ej. Sánchez Molinero' : 'Razón Social',
    required: true,
    onChange,
  },
  TIPO_PERSONA: {
    value: formState['persona'],
    name: 'persona',
    title: 'Tipo de Persona',
    required: true,
    onChange,
    values: [
      { key: 'F', value: 'Física' },
      { key: 'J', value: 'Jurídica' },
    ],
  },
  POBLACION: {
    value: formState['poblacion'],
    name: 'poblacion',
    title: 'Población',
    placeholder: 'Ej. Cubelles',
    required: true,
    onChange,
  },
  COD_POSTAL: {
    inputProps: {
      type: 'cod_codigo_postal',
      maxLength: 5,
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
    },
    value: formState['cod_codigo_postal'],
    name: 'cod_codigo_postal',
    title: 'Código postal',
    placeholder: 'Ej. 08028',
    required: true,
    type: 'cod_codigo_postal',
    onChange,
  },
  TIPO_IDENTIFICADOR: {
    value: formState['tipo_identificador'],
    name: 'tipo_identificador',
    title: 'Tipo de documento',
    placeholder: 'Selecciona',
    tooltip: intl.formatMessage({ id: 'pages.consult.tooltip.tipo_identificador' }),
    required: true,
    fullWidth: true,
    onChange,
    values: [
      { key: '', value: '' },
      { key: 'NIF', value: 'NIF' },
      { key: 'NIE', value: 'NIE' },
      { key: 'Passport', value: 'Pasaporte' },
    ],
  },
  IDENTIFICADOR: {
    value: formState['identificador'],
    name: 'identificador',
    title: 'Número',
    placeholder: 'Ej. 00000000A',
    required: true,
    onChange,
  },
  EMAIL: {
    inputProps: { type: 'email' },
    value: formState['email'],
    name: 'email',
    title: 'Correo electrónico',
    placeholder: 'Ej. nombre@dominio.com',
    required: true,
    type: 'email',
    onChange,
  },
  TELEFONO: {
    value: formState['telefono'],
    name: 'telefono',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    required: true,
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
  },
  NOMBREDS: {
    value: formState['punto_suministro_nombre'],
    name: 'punto_suministro_nombre',
    title: 'Nombre o Razón social',
    placeholder: 'Ej. Jorge',
    required: true,
    onChange,
  },
  NIFDS: {
    value: formState['punto_suministro_nif'],
    name: 'punto_suministro_nif',
    title: 'NIF/CIF',
    placeholder: 'Ej. 00000000A',
    required: true,
    onChange,
  },
  INSTALADORES: {
    value: formState['empresa'],
    name: 'empresa',
    optionKey: 'empresa',
    title: 'Escoge Instalador',
    renderOption: (option) =>
      `${option['nombre']} - ${
        combos.cod_zona_zeus.data.find((provincia) => {
          return parseInt(provincia.key) === parseInt(option['comunidad_id'])
        })?.value
      }`,
    onChange,
  },
  CALLEDS: {
    value: consultData['nombreVia'],
    name: 'direccion',
    title: 'Calle',
    placeholder: 'Ej. Alcalde',
    required: true,
    readOnly: true,
    onChange,
  },
  NUMERODS: {
    value: consultData['numero'],
    name: 'numero',
    title: 'Número',
    placeholder: 'Ej. 11',
    required: true,
    readOnly: true,
    onChange,
  },
  POBLACIONDS: {
    value: consultData['municipio'],
    name: 'poblacionds',
    title: 'Población',
    placeholder: 'Ej. Cubelles',
    required: true,
    readOnly: true,
    onChange,
  },
  COD_POSTALDS: {
    inputProps: {
      type: 'cod_codigo_postal',
      maxLength: 5,
      pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
    },
    value: consultData['cod_postal'],
    name: 'cod_postalds',
    title: 'Cod. postal',
    placeholder: 'Ej. 08028',
    required: true,
    readOnly: true,
    onChange,
  },
  SUM_CNAE: {
    value: formState['punto_suministro_cnae'],
    name: 'punto_suministro_cnae',
    title: 'CNAE',
    required: true,
    onChange,
    inputProps: {
      maxLength: 4,
      pattern: '[0-9]{4}',
    },
  },
  REGISTRO_CATASTRAL: {
    value: formState['referencia_catastral'],
    name: 'referencia_catastral',
    title: 'Referencia catastral',
    onChange,
  },
  NOMBRE_EMPRESA: {
    value: formState['solicitante_nombre_empresa'],
    name: 'solicitante_nombre_empresa',
    title: 'Nombre Empresa',
    required: true,
    onChange,
  },
  NOMBRE_INTERLOCUTOR: {
    value: formState['solicitante_interlocutor'],
    name: 'solicitante_interlocutor',
    title: 'Nombre interlocutor',
    placeholder: 'Ej. Jorge',
    required: true,
    onChange,
  },
  TELEFONOS: {
    value: formState['solicitante_telefono'],
    name: 'solicitante_telefono',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    required: true,
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
  },
  DNIS: {
    value: formState['solicitante_nif'],
    name: 'solicitante_nif',
    title: 'DNI',
    placeholder: 'Ej. 00000000A',
    required: true,
    onChange,
  },
  CARGO: {
    value: formState['solicitante_cargo'],
    name: 'solicitante_cargo',
    title: 'Cargo',
    placeholder: 'Ej. Responsable',
    required: true,
    onChange,
  },
  EMAILS: {
    inputProps: { type: 'email' },
    value: formState['solicitante_email'],
    name: 'solicitante_email',
    title: 'Correo electrónico',
    placeholder: 'Ej. nombre@dominio.com',
    required: true,
    type: 'email',
    onChange,
  },
  NOMBRE_EMPRESAF: {
    value: formState['empresa_facturacion'],
    name: 'empresa_facturacion',
    title: 'Nombre Empresa',
    required: true,
    onChange,
  },
  NOMBRE_INTERLOCUTORF: {
    value: formState['interlocutor_facturacion'],
    name: 'interlocutor_facturacion',
    title: 'Nombre interlocutor',
    placeholder: 'Ej. Jorge',
    required: true,
    onChange,
  },
  TELEFONOF: {
    value: formState['telefono_facturacion'],
    name: 'telefono_facturacion',
    title: 'Teléfono',
    placeholder: 'Ej. 600000000',
    required: true,
    onChange,
    inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
  },
  DNIF: {
    value: formState['nif_facturacion'],
    name: 'nif_facturacion',
    title: 'DNI',
    placeholder: 'Ej. 00000000A',
    required: true,
    onChange,
  },
  EMAILF: {
    inputProps: { type: 'email' },
    value: formState['email_facturacion'],
    name: 'email_facturacion',
    title: 'Correo electrónico',
    placeholder: 'Ej. nombre@dominio.com',
    required: true,
    type: 'email',
    onChange,
  },
  METROS_NECESARIOS: {
    value: formState['distancia_real'],
    name: 'distancia_real',
    title: 'Metros necesarios',
    placeholder: 'Ej. 12',
    required: true,
    onChange,
  },
  RANGO_PRESION: {
    value: formState['rango_presion'],
    name: 'rango_presion',
    title: 'Rango de presión maxima',
    placeholder: 'Selecciona',
    required: true,
    fullWidth: true,
    onChange,
    values: combos?.rango_presion_gc?.data || [],
  },
  PRESION_CONTAJE: {
    value: formState['presion_contaje'],
    name: 'presion_contaje',
    title: 'Presión contaje',
    placeholder: 'Selecciona',
    required: true,
    fullWidth: true,
    onChange,
    values: combos?.presion_contaje?.data || [],
  },
  HORAS_USO: {
    value: formState['horas_funcionamiento'],
    name: 'horas_funcionamiento',
    title: 'Horas de uso',
    placeholder: 'Ej. 80',
    required: true,
    onChange,
  },
  UBICACION: {
    value: formState['ubi_equipo_medida'],
    name: 'ubi_equipo_medida',
    title: 'Ubicación Contador',
    placeholder: 'Selecciona',
    required: true,
    type: 'email',
    onChange,
    values: [
      { key: 'Interior', value: 'Interior' },
      { key: 'Exterior', value: 'Exterior' },
    ],
  },
  CERTIFICADO_INTERIOR: {
    value: formState['formulario_rgpd_nuevo'],
    name: 'formulario_rgpd_nuevo',
    title: 'Certificado interior',
    onChange,
    //fileProps: FILE_PROPS,
    currentDoc: formState['formulario_rgpd'],
    required: formState.ubi_equipo_media === 'Interior' ? true : false,
  },
  OBSERVACIONES: {
    value: formState['observaciones'],
    name: 'observaciones',
    title: 'Observaciones',
    multiline: true,
    rows: 5,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    onChange,
  },
  CONTADOR: {
    value: formState['contador_previsto'],
    name: 'contador_previsto',
    title: 'Contador',
    placeholder: 'Selecciona',
    required: true,
    fullWidth: true,
    onChange,
    values: combos?.contador?.data || [],
  },
  POTENCIA_INSTALADA: {
    value: formState['potencia_instalada_total'],
    name: 'potencia_instalada_total',
    title: 'Potencia Instalada(kW)',
    required: true,
    readOnly: true,
    onChange,
  },
  POTENCIA_INSTALADA_2: {
    value: formState['potencia_instalada_total_2'],
    name: 'potencia_instalada_total_2',
    title: 'Potencia Instalada((N)m3/h)',
    required: true,
    readOnly: true,
    onChange,
  },
  CONSUMO_ANUAL: {
    value: formState['consumo_anual'],
    name: 'consumo_anual',
    title: 'Consumo anual(kWh)',
    required: true,
    readOnly: true,
    onChange,
  },
  TARIFA: {
    value: formState['tarifa'],
    name: 'tarifa',
    title: 'Tarifa',
    required: true,
    readOnly: true,
    onChange,
  },
  MERCADO: {
    value: formState['mercado'],
    name: 'mercado',
    title: 'Mercado',
    required: true,
    readOnly: true,
    onChange,
  },
  DERECHOS_EXTENSION: {
    value: customerForm?.isNN ? '--' : `${formState['derechos_extension']}`,
    name: 'derechos_extension',
    title: 'Derechos Extensión',
    readOnly: true,
    required: true,

    onChange,
  },
  DERECHOS_ALTA: {
    value: customerForm?.isNN ? '--' : `${formState['derechos_alta']}`,
    name: 'derechos_alta',
    title: 'Derechos Alta',
    required: true,
    readOnly: customerForm?.isNN ? customerForm.isNN : false,

    onChange,
  },
  DERECHOS_ACOMETIDA: {
    value: customerForm?.isNN ? '--' : `${formState['derechos_acometida']}`,
    name: 'derechos_acometida',
    title: 'Derechos Acometida',
    required: true,
    readOnly: customerForm?.isNN ? customerForm.isNN : false,

    onChange,
  },
})

/*
Tipos de documentos: acif
*/
