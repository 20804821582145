import { STUCK_ALERT_TYPES, STUCK_TABLE_COLUMNS } from '../constants'
import { NOTICES_TABLE_COLUMNS } from "../constants/notices-table.constants";

export const noticesDataPreparation = (data, { intl }, combos) => {
  return (
    data?.map((item) => {
      const formattedColumns = {}
      NOTICES_TABLE_COLUMNS.forEach((column) => {
        if (column.type === 'date' && item[column.field]) {
          formattedColumns[column.field] = new Date(item[column.field]).toLocaleDateString()
        }
        if (column.infoType === 'logic') {
          formattedColumns[column.field] = item[column.field] ? 'Sí' : 'No'
        }
      })
      return {
        ...item,
        ...formattedColumns,
        tableData: {
          ...item.tableData,
        },
      }
    }) || []
  )
}
