import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const ValidateChangeDialog = ({
  close,
  cambios_pendientes,
  requestId,
  name,
  title,
  validateChangedFields,
}) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const handleSubmit = () => {
    const fieldId = getChangedFieldId()
    setLoading(true)
    validateChangedFields(fieldId, requestId)
      .then((data) => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getChangedFieldId = () => {
    let fieldId = null
    cambios_pendientes.forEach((cambio) => {
      if (cambio.campo === name) fieldId = cambio.id
    })
    // updateChangedFields(fieldId)
    return fieldId
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.poRequests.detail.validateChange.dialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" paragraph>
            {formatMessage(
              { id: 'pages.poRequests.detail.validateChange.dialog.description' },
              { title }
            )}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
          <Box width={150} mb={[3, 0]}>
            <ThemeButton onClick={close} color="inherit" disabled={loading} fullWidth>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <Box width={150}>
            <ThemeButton
              onClick={handleSubmit}
              color="primary"
              fullWidth
              loading={loading}
              disabled={loading}
            >
              {formatMessage({ id: 'global.accept' })}
            </ThemeButton>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  )
}
