import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { TextInput, ThemeButton } from '../../../../ui'
import { EDIT_FORM_CONFIG } from './edit-user-constants'

export const EditManager = ({ close, editManager, props }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const managers = useSelector((state) => state.managers.data)
  const manager = props.rowData
  const dispatch = useDispatch()
  const [formState, setFormState] = useState(manager)

  const handleChangeForm = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleEditUser = (e) => {
    e.preventDefault()
    if(JSON.stringify(manager) !== JSON.stringify(formState)) {
      dispatch(editManager(props.rowData, formState, managers))
    }
    close()
  }

  const inputProps = { formState, combos, intl, onChange: handleChangeForm }
  return (
    <Box p={4}>
      <Box mb={3}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({ id: 'pages.managers.dialogs.edit.title' })}
        </Typography>
      </Box>
      <form onSubmit={handleEditUser}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).NOMBRE} />
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).APELLIDOS} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).USERNAME} />
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).ROL} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).TELEFONO} />
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <TextInput {...EDIT_FORM_CONFIG(inputProps).CORREO_ELECTRONICO} />
          </Grid>
        </Grid>
        <Box mt={8} display="flex" justifyContent="space-between">
          <ThemeButton onClick={close} variant="outlined" labelM="lg">
            {intl.formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
          <ThemeButton color="primary" type="submit" labelM="lg">
            {intl.formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </form>
    </Box>
  )
}
