import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { useAjaxContext } from "../../../core/ajax"
import { feedback } from '../../../core/feedback'

export const useNotificationsCalls = () => {
  const { get,post,putById,deleteById,getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getAvisos = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        if (!config.params.updated) {
          get(`/avisos`, config)
            .then((response) => {
              resolve(response)
            })
            .catch(({ response }) => {
              if (!response) {
                noResponseError()
                return reject('No response')
              }
              switch (response.status) {
                default:
                  defaultError()
              }
              return reject(response.status)
            })
        }
      }
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postNotification = useCallback(
    (data) =>
      new Promise((resolve, reject) => {
        post(`/avisos`, data)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'calls.notices.success.post.description' })
            )
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data && response.data.message) {
                  feedback('error', response.data.message)
                }
                else {
                  feedback(
                    'error',
                    formatMessage({ id: 'calls.notices.error.post.description' })
                  )
                }
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getAvisosByUser = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get(`/avisos/by_user`, config)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            /*switch (response.status) {
              default:
                defaultError()
            } */
            return reject(response.status)
          })
      }),
  [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const markAsRead = useCallback(
    (aviso_id) =>
      new Promise((resolve, reject) => {
        putById(`/avisos/check`,aviso_id,{})
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteNotification = useCallback(
    (aviso_id) =>
      new Promise((resolve, reject) => {
        deleteById(`/avisos`,aviso_id)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'calls.notices.success.delete.description' })
            )
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getAvisoById = useCallback(
    (aviso_id) =>
      new Promise((resolve, reject) => {
        getById(`/avisos`,aviso_id)
          .then((response) => {
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
  [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateAviso = useCallback(
    (aviso_id,data) =>
      new Promise((resolve, reject) => {
        putById(`/avisos`, aviso_id, data)
          .then((response) => {
            feedback(
              'success',
              formatMessage({ id: 'calls.notices.success.put.description' })
            )
            resolve(response)
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                if (response.data && response.data.message) {
                  feedback('error', response.data.message)
                }
                else {
                  feedback(
                    'error',
                    formatMessage({ id: 'calls.notices.error.post.description' })
                  )
                }
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )



  return {
    getAvisos,
    postNotification,
    getAvisosByUser,
    markAsRead,
    deleteNotification,
    getAvisoById,
    updateAviso
  }
}