import React, { useCallback, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'
import { parse } from 'qs'
import { TableUrlPagination } from '../../../../ui'

import { APPS_TABLE_COLUMNS, APPS_TABLE_ACTIONS, DEFAULT_QUERY } from '../../constants'
import { appsTableStyles } from './non-captables-validation-table.styles'
import { useNonCaptablesValidationContext } from '../../contexts'
import { TableActions } from '../table-actions'
import { nonCaptablesValidationDataPreparation } from '../../logics'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const AppsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  const location = useLocation()
  const {
    state: {
      data,
      pagination: { total_solicitudes: totalEntries },
      loading,
      search,
      tableSelection,
    },
    nonCaptablesValidationSelect,
    nonCaptablesValidationTableCheck,
    nonCaptablesValidationLoad,
  } = useNonCaptablesValidationContext()

  useEffect(() => {
    nonCaptablesValidationTableCheck([])
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const urlSearchParams = parse(location.search.split('?')[1])
    const searchParams = {
      ...DEFAULT_QUERY,
      ...urlSearchParams,
    }
    nonCaptablesValidationLoad(searchParams)
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectRows = useCallback((rows, row) => {
    nonCaptablesValidationTableCheck(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //Formatted columns:
  const formattedColumns = useMemo(
    () =>
      [...APPS_TABLE_COLUMNS, ...APPS_TABLE_ACTIONS].map((column) => {
        if (column.field === 'view' || column.field === 'active') {
          return { ...column, title: formatMessage({ id: `global.${column.field}` }) }
        }
        return {
          ...column,
          title: formatMessage({
            id: `pages.nonCaptablesValidation.table.columns.${column.field}.title`,
          }),
          cellStyle: { ...appsTableStyles.cell, ...column.cellStyle },
          emptyValue: () => <span>--</span>,
          lookup:
            column.hasOwnProperty('comboId') &&
            combos[column.comboId] &&
            combos[column.comboId].data
              ? getLookup(combos[column.comboId].data)
              : null,
        }
      }),
    [combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Assembling and adjusting data
  //Notice that tableSelection is intentionally excluded from the dependency array to avoid unnecessary rerenders
  const processedData = useMemo(() => {
    const selectedIds = tableSelection.map((el) => el.id + '' + el.tipo_documento)
    let identificador = ''
    return nonCaptablesValidationDataPreparation(data, { intl }, combos).map((row) => {
      identificador = row.id + '' + row.tipo_documento
      if (selectedIds.indexOf(identificador) !== -1) {
        row.tableData.checked = true
      }
      return row
    })
  }, [data, combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const tableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      fixedColumns: {
        left: 1,
      },
      headerStyle: appsTableStyles.header,
      draggable: false,
    }),
    []
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => {
        const line = (column) => {
          if (column === 'view') {
            return <TableActions {...props} />
          }
          return props.children
        }
        return <MTableCell {...props}>{line(props.columnDef.field)}</MTableCell>
      },
    }),
    []
  )

  //Case-specific additions
  const handleRowClick = useCallback(
    (e, row) => {
      nonCaptablesValidationSelect(row.id, row.certificateType)
    },
    [nonCaptablesValidationSelect]
  )
  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={tableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onRowClick={handleRowClick}
      onSelectionChange={handleSelectRows}
    />
  )
}
