export const APPS_COMBOS_IDS = [
  'tipo_mercado',
  'filtro_estados_sspp',
  'municipios_sspp',
  'sociedades',
  'estados_solicitud',
  'estados_scr_sspp',
  'estados_pdte_sspp',
  'uso_punto_suministro',
  'estados_sspp',
  'tipologias_tareas',
  'provincias_by_cp',
  'distribuidora',
  'origen_solicitud',
]
export const ORDER_COMBOS_IDS = ['municipios_sspp', 'provincias_sspp']
