import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Checkbox, Typography, Grid, Tooltip, CircularProgress } from '@material-ui/core'

import { useManagersCalls } from '../../hooks/managers-calls.hook'
import { ThemeButton } from '../../../../ui'

export const AddPermissions = ({ props, close, editManager }) => {
  const managers = useSelector((state) => state.managers.data)
  const { getComboByRol, getManager } = useManagersCalls()
  const { formatMessage } = useIntl()
  const dispatch = useDispatch()

  const [fethecdPerms, setFetchedPerms] = useState([])
  const [perms, setPerms] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getComboByRol(props.rowData.rol).then((response) => {
      setLoading(false)
      setFetchedPerms(response.data)
    })
    getManager(props.rowData.id).then((response) => {
      setPerms(response.data.permissions)
    })
  }, [props]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleTogglePermission = (e) => {
    const supportArray = perms
    e.persist()
    const index = supportArray.indexOf(e.target.name)
    if (index !== -1) {
      supportArray.splice(index, 1)
    } else {
      supportArray.push(e.target.name)
    }
    setPerms([...supportArray])
  }

  const handleChangePermissions = () => {
    dispatch(editManager(props.rowData, { extra_permission: perms }, managers))
    close()
  }

  return (
    <Box p={4} overflow="hidden">
      <Box mb={2} ml={1} mt={2}>
        <Typography variant="h2" color="primary">
          {formatMessage({ id: 'pages.managers.edit.permissions' })}
        </Typography>
      </Box>
      {loading ? (
        <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
          <CircularProgress size={20} />
        </Box>
      ) : (
        <Grid container spacing={1}>
          {fethecdPerms.length > 0 ? (
            fethecdPerms?.map((perm) => {
              return (
                <Tooltip
                  placement="top"
                  arrow
                  title={<Typography variant="body2">{perm.description}</Typography>}
                >
                  <Grid item xs={2} sm={4}>
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        checked={perms.some((pm) => pm === perm.key)}
                        name={perm.key}
                        onChange={(e) => handleTogglePermission(e)}
                      ></Checkbox>

                      <Typography>{perm.value}</Typography>
                    </Box>
                  </Grid>
                </Tooltip>
              )
            })
          ) : (
            <Box p={4} mb={2} ml={1} mt={2}>
              <Typography variant="h6">
                {formatMessage({ id: 'pages.managers.edit.permissions.noPerms' })}
              </Typography>
            </Box>
          )}
        </Grid>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={2}>
        <Box ml={2}>
          <ThemeButton color="default" onClick={close}>
            {formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
        <Box mr={2}>
          <ThemeButton
            onClick={handleChangePermissions}
            color="primary"
            disabled={fethecdPerms.length === 0}
          >
            {formatMessage({ id: 'global.accept' })}
          </ThemeButton>
        </Box>
      </Box>
    </Box>
  )
}
