import { GUILDS_TABLE_COLUMNS } from '../constants'

export const guildDataPreparation = (data, { intl }, combos) => {
  return data.map((item) => {
    const formattedColumns = {
      zonas_servicio: item['zonas_servicio']?.split(',').join(', '),
    }
    GUILDS_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedColumns[column.field] = intl.formatDate(item[column.field])
      }
    })
    return {
      ...item,
      ...formattedColumns,
      tableData: {
        ...item.tableData,
      },
    }
  })
}
