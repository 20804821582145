import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  withStyles,
  Box,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'

import { expansionPanelStyles } from './expansion-panel.styles'

export const ExpansionPanelComponent = withStyles(expansionPanelStyles)(
  ({ classes, triggerPosition = 'left', isExpanded = false, ...props }) => {
    const { formatMessage } = useIntl()

    const [expanded, setExpanded] = useState(isExpanded)

    return (
      <>
        <Accordion
          className={classes.accordion}
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary>
            <Box mb={2} display="flex">
              {props.summary}
              <Box flexGrow={1} textAlign={triggerPosition}>
                <Typography variant="body2" color="error">
                  {formatMessage({
                    id: `pages.budget.view.panel1.summary.expanded.${expanded.toString()}`,
                  })}
                </Typography>
              </Box>
            </Box>
            <Divider light />
            <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
              {props.summaryExtra && <Box flexGrow={1}>{props.summaryExtra}</Box>}
            </Box>
          </AccordionSummary>
          <AccordionDetails>{props.details}</AccordionDetails>
        </Accordion>
        {props.footer && (
          <Box ml={2} mr={2}>
            {props.footer}
          </Box>
        )}
      </>
    )
  }
)
