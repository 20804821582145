import { APPLICATION_STATES } from './application-states.constants'

export const DOC_BUTTONS_CONFIG = (model, formatMessage) => ({
  CROQUIS: {
    id: 'croquis_id',
    maxSize: 10000000,
    applicationId: model['id'],
    budgetId: model['presupuesto_exterior']?.id,
    clientId: model['usuario']['id'],
    label: formatMessage({
      id: 'pages.application.detail.croquisButton.label',
    }),
    extra: model['presupuesto_exterior']?.d_croquis
      ? model['presupuesto_exterior']['d_croquis']['nombre']
      : '',
    truncate: model['presupuesto_exterior']?.d_croquis ? '12em' : false,
    variant: 'outlined',
    size: 'small',
    type: 'croquis',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  },
  TALLO: {
    id: 'tallo_id',
    maxSize: 10000000,
    applicationId: model['id'],
    budgetId: model['presupuesto_exterior']?.id,
    clientId: model['usuario']['id'],
    label: formatMessage({
      id: 'pages.application.detail.talloButton.label',
    }),
    extra: model['presupuesto_exterior']?.d_tallo
      ? model['presupuesto_exterior']['d_tallo']['nombre']
      : '',
    truncate: model['presupuesto_exterior']?.d_tallo ? '12em' : false,
    variant: 'outlined',
    size: 'small',
    type: 'tallo',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  },
})

export const ALLOWED_EDIT_STATES = {
  [APPLICATION_STATES.ASSIGNED.KEY]: APPLICATION_STATES.ASSIGNED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
}

export const ALLOWED_SEND_BUDGET_STATES = {
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: APPLICATION_STATES.BUDGET_CREATED.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: APPLICATION_STATES.BUDGET_SENDED.DESCRIPTION,
}

export const DISABLED_STATES = {
  [APPLICATION_STATES.REVIEW.KEY]: APPLICATION_STATES.REVIEW.DESCRIPTION,
  [APPLICATION_STATES.REJECTED.KEY]: APPLICATION_STATES.REJECTED.DESCRIPTION,
  [APPLICATION_STATES.REJECTED_2.KEY]: APPLICATION_STATES.REJECTED_2.DESCRIPTION,
  [APPLICATION_STATES.FINISHED.KEY]: APPLICATION_STATES.FINISHED.DESCRIPTION,
}

export const HAS_PAYED_STATES = {
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: APPLICATION_STATES.PAYMENT_VALIDATION.DESCRIPTION,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: APPLICATION_STATES.ORDER_PENDING.DESCRIPTION,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: APPLICATION_STATES.BUDGET_PAID.DESCRIPTION,
  [APPLICATION_STATES.CERTIFICATE_ATTACHED.KEY]:
    APPLICATION_STATES.CERTIFICATE_ATTACHED.DESCRIPTION,
  [APPLICATION_STATES.CERTIFICATE_PROCESSED.KEY]:
    APPLICATION_STATES.CERTIFICATE_PROCESSED.DESCRIPTION,
  [APPLICATION_STATES.FINISHED.KEY]: APPLICATION_STATES.FINISHED.DESCRIPTION,
  [APPLICATION_STATES.REVIEW.KEY]: APPLICATION_STATES.REVIEW.DESCRIPTION,
  [APPLICATION_STATES.REJECTED.KEY]: APPLICATION_STATES.REJECTED.DESCRIPTION,
  [APPLICATION_STATES.REJECTED_2.KEY]: APPLICATION_STATES.REJECTED_2.DESCRIPTION,
}

export const VALID_STATES = {
  [APPLICATION_STATES.REVIEW.KEY]: true,
  [APPLICATION_STATES.PENDING_VALIDATION.KEY]: true,
  [APPLICATION_STATES.CREATED.KEY]: true,
  [APPLICATION_STATES.NOT_ASSIGNED.KEY]: true,
  [APPLICATION_STATES.ASSIGNED.KEY]: true,
  [APPLICATION_STATES.BUDGET_CREATED.KEY]: true,
  [APPLICATION_STATES.BUDGET_SENDED.KEY]: true,
  [APPLICATION_STATES.BUDGET_ACCEPTED.KEY]: true,
  [APPLICATION_STATES.PAYMENT_VALIDATION.KEY]: true,
  [APPLICATION_STATES.ORDER_PENDING.KEY]: true,
  [APPLICATION_STATES.BUDGET_PAID.KEY]: true,
}

export const SELECTRA_TYPE = {
  Selectra_E2E: true,
  Selectra_NI: true,
  Selectra_CC: true,
}

export const CHANGE_INSTALLER_OBSERVATIONS = [
  { key: '', value: '' },
  { key: 'No me ha contactado.', value: 'No me ha contactado.' },
  {
    key: 'Mala experiencia con el instalador elegido',
    value: 'Mala experiencia con el instalador elegido',
  },
  {
    key: 'No me ha enviado el presupuesto de la instalación interior.',
    value: 'No me ha enviado el presupuesto de la instalación interior.',
  },
  {
    key: 'No me gusta su propuesta de aparatos y solución de instalación.',
    value: 'No me gusta su propuesta de aparatos y solución de instalación.',
  },
  { key: 'Es caro.', value: 'Es caro.' },
  { key: 'Quiero más opciones de presupuesto.', value: 'Quiero más opciones de presupuesto.' },
  { key: 'Sin especificar.', value: 'Sin especificar.' },
]
