import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid, CircularProgress } from '@material-ui/core'

import { ThemeButton, FileUploaderAgnostic, UploadTooltip } from '../../../../ui'
import { DOCUMENT_ATTACHMENT_CONFIG } from './documents-upload.constants'
import { feedback } from '../../../../core/feedback'

export const DocumentsUploadComponent = ({ close, application, updateApplication, uploadDoc }) => {
  const { formatMessage } = useIntl()

  //Since our modal component does not update upon data refreshes, we need to keep state duplication
  const [model, setModel] = useState(application)
  const [loading, setLoading] = useState(false)

  const handleUploadBudgetFile = (e, doc) => {
    const document = {
      documento: e.target.value.data.split(',')[1],
      nombre: e.target.value.name.split('.')[0],
      tipo: doc.type,
      extension: e.target.value.type,
      presupuesto_id: model.presupuesto_sir.id.toString(),
      solicitud_id: model.id.toString(),
      tipo_solicitud: 'sir',
    }
    setLoading(true)
    uploadDoc(document)
      .then((data) => {
        const payload = {
          [doc.id]: data.id,
        }
        updateApplication(model.presupuesto_sir.id, null, payload)
          .then((data) => {
            setModel(data)
            setLoading(false)
            feedback('success', formatMessage({ id: 'pages.sir.detail.documents.feedback.1' }))
          })
          .catch(() => {
            setLoading(false)
            feedback('error', formatMessage({ id: 'pages.sir.detail.documents.feedback.2' }))
          })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleUploadError = (data) => {
    feedback('error', data?.mssg || formatMessage({ id: 'pages.sir.detail.documents.feedback.2' }))
  }

  return (
    <Box p={4}>
      <Box mb={4}>
        <Grid container spacing={2}>
          {DOCUMENT_ATTACHMENT_CONFIG.map((doc) => (
            <Grid key={doc.id} item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Box display="flex" flexDirection="horizontal">
                    <Typography style={{ marginRight: '0.5em' }} variant="body2">
                      {formatMessage({ id: `pages.sir.detail.documents.${doc.field}` })}
                    </Typography>
                    <UploadTooltip file={doc} />
                  </Box>
                  {model.presupuesto_sir[doc.model] ? (
                    <Typography variant="body2">
                      {model.presupuesto_sir[doc.model].nombre}
                    </Typography>
                  ) : null}
                </Box>
                <FileUploaderAgnostic
                  {...{
                    name: doc.id,
                    maxSize: doc.maxSize,
                    accept: doc.accept,
                    onSuccess: (e) => handleUploadBudgetFile(e, doc),
                    onError: handleUploadError,
                  }}
                >
                  {(props) => (
                    <div
                      className={'MuiButtonBase-root MuiButton-outlined MuiButton-root'}
                      style={{ marginLeft: 10, pointerEvents: loading }}
                    >
                      {props.loading ? <CircularProgress size={20} /> : 'Adjuntar'}
                    </div>
                  )}
                </FileUploaderAgnostic>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={() => close()} color="primary" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
