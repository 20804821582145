export const GUILDS_TABLE_COLUMNS = [
  { field: 'fecha_alta', align: 'left', width: 140, type: 'date' },
  {
    field: 'nombre_gremio',
    align: 'left',
    cellStyle: {
      textTransform: 'capitalize',
    },
  },
  {
    field: 'cif',
    align: 'left',
    width: 120,
    cellStyle: {
      textTransform: 'uppercase',
    },
  },
  { field: 'telefono', align: 'left', width: 120 },
  { field: 'email', align: 'left' },
  { field: 'zonas_servicio', align: 'left', width: 250 },
  { field: 'numero_demandas', align: 'left', width: 40 },
  { field: 'numero_solicitudes', align: 'left', width: 40 },
  { field: 'web', align: 'left' },
]

export const GUILDS_TABLE_FILTERS = [
  {
    name: 'comunidad_autonoma',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
  },
]
