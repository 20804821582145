export const pageDisplayerStyles = (theme) => ({
  root: {
    height: '100%',
  },
  return: {
    width: 'max-content',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 15px 0',
    paddingLeft: '40px',
    '&:hover': { cursor: 'pointer' },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '15px',
    },
  },
})
