import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Typography, Box, CircularProgress, Grid } from '@material-ui/core'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useUserContext } from '../../../../core/user'
import { useAjaxContext } from '../../../../core/ajax'
import { feedback } from '../../../../core/feedback'
import { resetUserData, selectUser } from '../../slices'
import { verifyPwd } from '../../../../core/utils/pwdUtils'
import { logoutUser } from '../../../global'
import { resetAppsData } from '../../../applications/slices'
import { ThemeButton, GridRow, PasswordInput } from '../../../../ui'
import { USER_GRID_ROW_ITEMS } from '../../constants'
import { profileUserStyles } from './profile-user.styles'

export const ProfileUserComponent = withStyles(profileUserStyles)(({ classes }) => {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const { userDispatch } = useUserContext()
  const user = useSelector(selectUser)
  const isLoading = useSelector((state) => state.profile.isLoading)
  const { put } = useAjaxContext()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  const handleLogout = () => {
    dispatch(resetAppsData())
    dispatch(logoutUser(userDispatch))
    dispatch(resetUserData())
  }

  useEffect(() => {
    verifyPwd(
      formState['newPassword'],
      formState['repitNewPassword'],
      isValidPassword,
      setIsValidPassword,
      setErrorMssg
    )
  }, [formState]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    if (isValidPassword) {
      const payload = {
        password: formState['currentPassword'],
        new_password: formState['newPassword'],
      }
      put('/usuario/modify_password', payload)
        .then(() => {
          setLoading(false)
          feedback('success', formatMessage({ id: 'pages.profile.pwd.feedback.1' }))
        })
        .catch((response) => {
          setLoading(false)
          const errorMssg =
            response?.response?.data?.message ||
            formatMessage({ id: 'pages.profile.pwd.feedback.2' })
          feedback('error', errorMssg)
        })
    }
  }

  return isLoading ? (
    <Box display="flex" height={100} justifyContent="center" alignItems="center">
      <CircularProgress size={40} />
    </Box>
  ) : (
    <Box mb={3}>
      <Box display="flex" mb={1} flexWrap="wrap">
        <Box display="flex" alignItems="center" flexGrow={4} mr={2}>
          <AccountCircleOutlinedIcon className={classes.userImg} />
          <Typography variant="h4" color="primary">
            {(user['nombre'] || '') + ' ' + (user['apellidos'] || '')}
          </Typography>
        </Box>
        <Box display="flex">
          <ThemeButton color="default" onClick={handleLogout}>
            {formatMessage({ id: 'pages.profile.header.button2' })}
          </ThemeButton>
        </Box>
      </Box>
      <Box bgcolor="common.white" borderRadius={6} p={3}>
        <Typography variant="h6" color="primary" paragraph>
          {formatMessage({ id: 'pages.profile.user.title' })}:
        </Typography>
        <GridRow items={USER_GRID_ROW_ITEMS} data={user} justify="flex-start" spacing={8} />
      </Box>
      <Box bgcolor="common.white" borderRadius={6} p={3}>
        <form onSubmit={handleSubmitForm}>
          <Box mb={3}>
            <Typography variant="h6" color="primary" paragraph>
              {formatMessage({ id: 'pages.profile.pwd.subtitle' })}
            </Typography>
            <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
              {formatMessage({ id: 'pages.profile.pwd.description' })}
            </Typography>
          </Box>
          <Box mb={4}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <PasswordInput
                  value={formState['currentPassword']}
                  name="currentPassword"
                  title={formatMessage({ id: 'pages.profile.pwd.fields.currentPwd' })}
                  placeholder={formatMessage({ id: 'pages.profile.pwd.fields.currentPwd' })}
                  onChange={handleChangeForm}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput
                  value={formState['newPassword']}
                  name="newPassword"
                  title={formatMessage({ id: 'pages.profile.pwd.fields.newPwd' })}
                  placeholder={formatMessage({ id: 'pages.profile.pwd.fields.newPwd' })}
                  onChange={handleChangeForm}
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <PasswordInput
                  value={formState['repitNewPassword']}
                  name="repitNewPassword"
                  title={formatMessage({ id: 'pages.profile.pwd.fields.repitPwd' })}
                  placeholder={formatMessage({ id: 'pages.profile.pwd.fields.repitPwd' })}
                  onChange={handleChangeForm}
                  required
                />
              </Grid>
            </Grid>
          </Box>
          {errorMssg && (
            <Box mb={1} bgcolor="grey">
              <Typography variant="body2" color="error">
                {errorMssg}
              </Typography>
            </Box>
          )}
          <Box display="flex" justifyContent="center">
            <ThemeButton type="submit" loading={loading} disabled={!isValidPassword}>
              {formatMessage({ id: 'pages.profile.pwd.button.save' })}
            </ThemeButton>
          </Box>
        </form>
      </Box>
    </Box>
  )
})
