import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { PageDisplayer } from '../../../../ui'
import { useCombos } from '../../../../features/combos'
import { usePORequests, PORequestsForm } from '../../../../features/po-requests'
import { PO_REQUESTS_COMBOS_IDS } from '../../constants'

export const PORequestsDetailPage = () => {
  const history = useHistory()
  const { poRequestId } = useParams()
  useCombos(PO_REQUESTS_COMBOS_IDS)
  const { formatMessage } = useIntl()
  const { detailLoading, detailError, getPORequest } = usePORequests()

  useEffect(() => {
    getPORequest(poRequestId)
  }, [poRequestId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    // const prevSearch = history.location.state?.prevSearch || ''
    // history.push('/po-requests' + prevSearch)
    history.goBack()
  }

  return (
    <PageDisplayer
      maxWidth="md"
      loading={detailLoading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.poRequests.detail.goBack' })}
    >
      {detailError ? (
        formatMessage({ id: 'pages.poRequests.detail.error' })
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h4" color="textSecondary">
              {formatMessage({ id: 'pages.poRequests.detail.title' })}
            </Typography>
          </Box>
          <Box mb={2}>
            <PORequestsForm />
          </Box>
        </>
      )}
    </PageDisplayer>
  )
}
