import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { useCombos, useCustomCombo } from '../../../features/combos'
import { GCHeader, GCTable, useGCCalls } from '../../../features/gran-consumo'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { guildStyles } from './gran-consumo.style'

export const GranConsumoPage = withStyles(guildStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getGestoresGC } = useGCCalls()
  const [table, setTable] = useState(false)
  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'gestoresGc',
    call: getGestoresGC,
  })
  useCombos(['tipo_mercado'], ['tipo_mercado'])
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])
  useCombos(['provincias'], ['provincias'])
  useCombos(['grupo_presion'], ['grupo_presion'])
  useCombos(['provincias_gc'], ['provincias_gc'])
  useCombos(['tipo_mercado_gc'], ['tipo_mercado_gc'])

  useCustomCombo(['gestores'])

  return (
    <Container className={classes.container} maxWidth="false">
      <Box mb={3} mt={1}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.gran_consumo.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <GCHeader table={table} setTable={setTable} />
      </Box>
      <Box>
        <GCTable />
      </Box>
    </Container>
  )
})
