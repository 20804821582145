import { useCallback, useReducer } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useSirCalls } from './sir-calls.hook'
import { sirReducer } from '../reducers'
import { SIR_INITIAL_STATE, SIR_ACTIONS_TYPES } from '../reducers'
import feedback from '../../../core/feedback'
import { useIntl } from 'react-intl'

export const useSirReducer = () => {
  const [state, dispatch] = useReducer(sirReducer, SIR_INITIAL_STATE)
  const {
    getSirApps,
    getSirApp,
    putSirApp,
    putSirBudget,
    putSirUser,
    generateBudgetCall,
    sendBudgetCall,
    validatePaymentCall,
    cancelApplicationCall,
    rollbackApplicationCall,
    getSirEventsCall,
    postSirEventCall,
    postDocCall,
    getFiltersCall,
    postFilterCall,
    deleteFilterCall,
  } = useSirCalls()
  const history = useHistory()
  const { search } = useLocation()
  const { formatMessage } = useIntl()

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_LOADING,
      })
      getSirApps(config)
        .then(({ data }) => {
          dispatch({
            type: SIR_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['solicitudes'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_solicitudes: data['total_solicitudes'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: SIR_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getApplication = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: SIR_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getSirApp(id)
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_APPLICATION,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateApplication = useCallback(
    (budgetId, appId, updatedValues, newPressure) =>
      new Promise((resolve, reject) => {
        if (newPressure) {
          const payload = { rango_presion: newPressure }
          putSirApp(appId, payload)
            .then(({ data }) => {
              putSirBudget(budgetId, updatedValues)
                .then(({ data }) => {
                  dispatch({
                    type: SIR_ACTIONS_TYPES.SET_APPLICATION,
                    payload: data,
                  })
                })
                .then(() => resolve())
                .catch(() => {
                  return reject('Error')
                })
            })
            .catch(() => {
              return reject('Error')
            })
        } else {
          putSirBudget(budgetId, updatedValues)
            .then(({ data }) => {
              dispatch({
                type: SIR_ACTIONS_TYPES.SET_APPLICATION,
                payload: data,
              })
              return data
            })
            .then((data) => resolve(data))
            .catch(() => {
              return reject('Error')
            })
        }
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const updateUserSir = useCallback((user_id, updatedValues) =>
    new Promise((resolve,reject) =>  {
    putSirUser(user_id, updatedValues)
      .then(({ data }) => resolve(data))
      .catch(() => {
        return reject('Error')
      })
  }),
  [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
)


  const uploadDoc = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postDocCall(payload)
          .then(({ data }) => resolve(data))
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const generateBudget = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
          payload: true,
        })
        generateBudgetCall({ solicitud_id: id })
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_APPLICATION,
              payload: data,
            })
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
              payload: false,
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
              payload: false,
            })
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendBudget = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        dispatch({
          type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
          payload: true,
        })
        sendBudgetCall(payload)
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_APPLICATION,
              payload: data,
            })
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
              payload: false,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validatePayment = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        validatePaymentCall(id, '112')
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_APPLICATION,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const invalidatePayment = useCallback(
    (application, payload) =>
      new Promise((resolve, reject) => {
        putSirBudget(application.presupuesto_sir.id, payload)
          .then(() => {
            validatePaymentCall(application.id, '103')
              .then(({ data }) => {
                dispatch({
                  type: SIR_ACTIONS_TYPES.SET_APPLICATION,
                  payload: data,
                })
              })
              .then(() => resolve())
              .catch(() => {
                return reject('Error')
              })
          })
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const cancelApplication = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        cancelApplicationCall(payload)
          .then(() => {
            const search = history.location.state?.prevSearch
              ? history.location.state.prevSearch
              : ''
            history.push('/sir' + search)
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rollbackApplication = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        rollbackApplicationCall(id)
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_APPLICATION,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getSirEvents = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getSirEventsCall(id)
          .then(({ data }) => {
            const sortedData = data.sort(
              (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
            )
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_EVENTS,
              payload: sortedData,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const postSirEvent = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        postSirEventCall(id, payload)
          .then(() => {
            getSirEvents(id)
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setEditMode = useCallback(
    (payload) =>
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_EDIT_MODE,
        payload,
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setCompanyEditMode = useCallback(
    (payload) =>
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_COMPANY_EDIT_MODE,
        payload,
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setUserEditMode = useCallback(
    (payload) =>
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_USER_EDIT_MODE,
        payload,
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = useCallback(
    (id) => {
      const props = {
        pathname: `/sir/${id}`,
        state: { prevSearch: history.location.search },
      }
      return history.push(props)
    },
    [search, history] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getFilters = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getFiltersCall(id)
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.SET_FILTERS,
              payload: data,
            })
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const saveFilter = useCallback(
    (payload) =>
      new Promise((resolve, reject) => {
        postFilterCall(payload)
          .then(({ data }) => {
            dispatch({
              type: SIR_ACTIONS_TYPES.UPDATE_FILTER,
              payload: { key: data.nombre, value: data.filtro },
            })
            feedback('success', formatMessage({ id: 'pages.sir.table.save_filter.success' }))
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const deleteFilter = useCallback(
    (nombre) =>
      new Promise((resolve, reject) => {
        deleteFilterCall(nombre)
          .then(() => {
            dispatch({
              type: SIR_ACTIONS_TYPES.DELETE_FILTER,
              payload: nombre,
            })
            feedback('success', formatMessage({ id: 'pages.sir.table.delete_filter.success' }))
          })
          .then(() => resolve())
          .catch(() => {
            return reject('Error')
          })
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setGenerateBudgetLoading = useCallback(
    (payload) =>
      dispatch({
        type: SIR_ACTIONS_TYPES.SET_GENERATE_BUDGET_LOADING,
        payload,
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    setSelectedTableRows,
    onClickTableRow,
    updateApplication,
    updateUserSir,
    uploadDoc,
    getTableData,
    getApplication,
    generateBudget,
    validatePayment,
    invalidatePayment,
    cancelApplication,
    rollbackApplication,
    getSirEvents,
    postSirEvent,
    sendBudget,
    setEditMode,
    setCompanyEditMode,
    setUserEditMode,
    getFilters,
    saveFilter,
    deleteFilter,
    setGenerateBudgetLoading,
    ...state,
  }
}
