//Configuration of application documents:
//- subfield: truthy when the document is not directly linked to the app. Indicates which subfield it is linked to (e.g., presupuesto_exterior, certificado_IRG3, etc)
//- id: name of the "id" field in app (or budget)
//- model: field of the full document info in the full app/budget model
//- type: we need to provide it to back when uploading the document
//- label: extension to an internationalization key string (documents may have different names in different places)
//- accept: array of types accepted
//- maxSize: in bytes

export const DOCUMENT_ATTACHMENT_CONFIG = {
  AC: {
    id: 'd_AC_id',
    model: 'd_AC',
    type: 'AC',
    field: 'AC',
    label: 'ac.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  AI: {
    id: 'd_AI_id',
    model: 'd_AI',
    type: 'AI',
    field: 'AI',
    label: 'ai.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  AR: {
    id: 'd_AR_id',
    model: 'd_AR',
    type: 'AR',
    field: 'AR',
    label: 'ar.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CA: {
    id: 'd_CA_id',
    model: 'd_CA',
    type: 'CA',
    field: 'CA',
    label: 'ca.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CC: {
    id: 'd_CC_id',
    model: 'd_CC',
    field: 'CC',
    type: 'CC',
    label: 'cc.title',
    accept: ['application/pdf'],
    maxSize: 2 * 1024 * 1024,
  },
  CI: {
    subfield: 'presupuesto_exterior',
    id: 'croquis_id',
    model: 'd_croquis',
    field: 'CI',
    type: 'CI',
    label: 'ci.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  CH: {
    id: 'd_CH_id',
    model: 'd_CH',
    type: 'CH',
    field: 'CH',
    label: 'ch.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CM: {
    id: 'd_CM_id',
    model: 'd_CM',
    field: 'CM',
    type: 'CM',
    label: 'cm.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CP: {
    id: 'd_CP_id',
    model: 'd_CP',
    type: 'CP',
    field: 'CP',
    label: 'cp.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  CPI: {
    id: 'd_CPI_id',
    model: 'd_CPI',
    type: 'CPI',
    field: 'CPI',
    label: 'cpi.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  CR: {
    id: 'd_CR_id',
    model: 'd_CR',
    type: 'CR',
    field: 'CR',
    label: 'cr.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  DN: {
    id: 'd_DN_id',
    model: 'd_DN',
    type: 'DN',
    field: 'DN',
    label: 'dn.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  EC: {
    id: 'd_EC_id',
    model: 'd_EC',
    type: 'EC',
    field: 'EC',
    label: 'ec.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  ACIF: {
    id: 'd_ACIF_id',
    model: 'd_ACIF',
    type: 'ACIF',
    field: 'ACIF',
    label: 'acif.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  EF: {
    id: 'd_EF_id',
    model: 'd_EF',
    type: 'EF',
    field: 'EF',
    label: 'ef.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  FC1: {
    id: 'd_FC1_id',
    model: 'd_FC1',
    field: 'FC1',
    type: 'FC',
    label: 'fc1.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  FC2: {
    id: 'd_FC2_id',
    model: 'd_FC2',
    field: 'FC2',
    type: 'FC',
    label: 'fc1.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  FC3: {
    id: 'd_FC3_id',
    model: 'd_FC3',
    field: 'FC3',
    type: 'FC',
    label: 'fc1.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  FI: {
    subfield: 'presupuesto_exterior',
    id: 'tallo_id',
    model: 'd_tallo',
    type: 'FI',
    field: 'FI',
    label: 'fi.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  IRG1: {
    subfield: 'certificado_IRG1',
    id: 'd_IRG1_id',
    model: 'd_IRG1',
    field: 'IRG1',
    type: 'IR',
    label: 'irg1.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  IRG2: {
    subfield: 'certificado_IRG2',
    id: 'd_IRG2_id',
    model: 'd_IRG2',
    field: 'IRG2',
    type: 'IR',
    label: 'irg2.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  IRG3: {
    subfield: 'certificado_IRG3',
    id: 'd_IRG3_id',
    model: 'd_IRG3',
    field: 'IRG3',
    type: 'IR',
    label: 'irg3.title',
    accept: ['application/pdf'],
    maxSize: 2 * 1024 * 1024,
  },
  SG: {
    id: 'd_SG_id',
    model: 'd_SG',
    type: 'SG',
    field: 'SG',
    label: 'sg.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  SP: {
    id: 'd_SP_id',
    model: 'd_SP',
    type: 'SP',
    field: 'SP',
    label: 'sp.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CCEE: {
    id: 'd_CCEE_id',
    model: 'd_CCEE',
    type: 'CCEE',
    field: 'CCEE',
    label: 'ccee.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  SCR: {
    id: 'd_SCR_id',
    model: 'd_SCR',
    type: 'SCR',
    field: 'SCR',
    label: 'scr.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CB: {
    id: 'd_CB_id',
    model: 'd_CB',
    type: 'CB',
    field: 'CB',
    label: 'cb.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  CPA: {
    subfield: 'presupuesto_exterior',
    id: 'd_CPA_id',
    model: 'd_comprobante',
    type: 'CPA',
    field: 'CPA',
    label: 'cp.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  RC: {
    id: 'd_RC_id',
    model: 'd_RC',
    type: 'RC',
    field: 'RC',
    label: 'rc.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  PR: {
    subfield: 'presupuesto_exterior',
    id: 'd_presupuesto',
    model: 'd_presupuesto',
    type: 'PR',
    field: 'PR',
    label: 'pr.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  PRA: {
    subfield: 'presupuesto_exterior',
    id: 'd_presupuesto_aceptado',
    model: 'd_presupuesto_aceptado',
    type: 'PRA',
    field: 'PRA',
    label: 'pra.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
  // croquis instalador
  croquis_i: {
    id: 'd_croquis_i_id',
    model: 'd_croquis_i',
    type: 'croquis_i',
    field: 'croquis_i',
    label: 'croquis_i.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  // tallo instalador
  tallo_i: {
    id: 'd_tallo_i_id',
    model: 'd_tallo_i',
    type: 'tallo_i',
    field: 'tallo_i',
    label: 'tallo_i.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  SGP: {
    id: 'd_SGP_id',
    model: 'd_SGP',
    type: 'SGP',
    field: 'SGP',
    label: 'sgp.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
}

export const DOCUMENT_ATTACHMENT_GAS_P_CONFIG = {
  peticion_gasp: {
    id: 'd_peticion_gasp_id',
    model: 'd_peticion_gasp',
    type: 'peticion_gasp',
    field: 'peticion_gasp',
    label: 'peticion_gasp.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  escritura_poderes: {
    id: 'd_escritura_poderes_id',
    model: 'd_escritura_poderes',
    type: 'escritura_poderes',
    field: 'escritura_poderes',
    label: 'escritura_poderes.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  dn: {
    id: 'd_dn_id',
    model: 'd_dn',
    type: 'DN',
    field: 'dn',
    label: 'dn.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  cc: {
    id: 'd_cc_id',
    model: 'd_cc',
    type: 'CC',
    field: 'cc',
    label: 'cc.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  proyecto_gas: {
    id: 'd_proyecto_gas_id',
    model: 'd_proyecto_gas',
    type: 'proyecto_gas',
    field: 'proyecto_gas',
    label: 'proyecto_gas.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  proyecto_calefaccion: {
    id: 'd_proyecto_calefaccion_id',
    model: 'd_proyecto_calefaccion',
    type: 'proyecto_calefaccion',
    field: 'proyecto_calefaccion',
    label: 'proyecto_calefaccion.title',
    accept: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
}
