import { isRegExp } from 'qs/lib/utils'
import { GRID_ITEM_MEDIUM } from '../../../constants'

export const INSTALLATION_PANEL_CONFIG = ({ combos, intl, isGc }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.application.detail.installation.fields.' + extension })
  const DEFAULT_PROPS = {
    fontSize: 'small',
    readOnly: true,
  }
  return {
    HOME_TYPE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['tipo_vivienda'],
      }),
      title: txt('tipo_vivienda.title'),
      name: 'tipo_vivienda',
    },
    CIDI: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['cidi'],
      }),
      title: txt('cidi.title'),
      name: 'cidi',
    },
    PRODUCTS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        // TODO: valorar la utilización de un componente como el checklistinput
        value: [
          { key: 'agua_caliente', value: 'Agua caliente' },
          { key: 'calefaccion', value: 'Calefacción' },
          { key: 'cocina', value: 'Cocina' },
        ]
          .filter(({ key }) => data.presupuesto_interior?.[key])
          .map(({ value }) => value)
          .join(' + '),
      }),
      title: txt('products.title'),
      name: 'products',
    },
    ENERGY: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        // TODO: valorar la utilización de un componente como el checklistinput
        value: `ACS: ${data.presupuesto_interior?.['actual_acs'] || ''}\n Calefacción: ${
          data.presupuesto_interior?.['actual_cal'] || ''
        }\n Cocina: ${data.presupuesto_interior?.['actual_cocina'] || ''}`,
      }),
      title: txt('energy.title'),
      name: 'energy',
    },
    IRI: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data['iri_id'],
        values: combos?.iri?.data || [],
      }),
      title: txt('iri_id.title'),
      name: 'iri_id',
    },
    FLOORS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['plantas_cal'],
      }),
      title: txt('plantas_cal.title'),
      name: 'plantas_cal',
    },
    SURFACE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['metros_cal']
          ? data.presupuesto_interior.metros_cal + ' m2'
          : null,
      }),
      title: txt('metros_cal.title'),
      name: 'metros_cal',
    },
    ROOMS: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['estancias_cal'],
      }),
      title: txt('estancias_cal.title'),
      name: 'estancias_cal',
    },
    PEOPLE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['personas_acs'],
      }),
      title: txt('personas_acs.title'),
      name: 'personas_acs',
    },
    RADIATOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['radiador_cal'],
      }),
      title: txt('radiador_cal.title'),
      name: 'radiador_cal',
    },
    TOWEL_RAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['toallero_cal'],
      }),
      title: txt('toallero_cal.title'),
      name: 'toallero_cal',
    },
    THERMOSTAT: {
      ...DEFAULT_PROPS,
      componentType: 'select',
      fromData: (data) => ({
        value: data.presupuesto_interior?.['termostato_cal'],
        values: [
          { key: true, value: 'Sí' },
          { key: false, value: 'No' },
        ],
      }),
      title: txt('termostato_cal.title'),
      name: 'termostato_cal',
    },
    BOILER: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['modelo_caldera'],
      }),
      title: txt('modelo_caldera.title'),
      name: 'modelo_caldera',
    },
    BOILER_PLACE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['nueva_caldera'],
      }),
      title: txt('nueva_caldera.title'),
      name: 'nueva_caldera',
    },
    HEATER: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['modelo_calentador'],
      }),
      title: txt('modelo_calentador.title'),
      name: 'modelo_calentador',
    },
    HEATER_PLACE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data.presupuesto_interior?.['nuevo_calentador'],
      }),
      title: txt('nuevo_calentador.title'),
      name: 'nuevo_calentador',
    },
    GAS_TYPE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['tipo_gas'],
      }),
      title: txt('tipo_gas.title'),
      name: 'tipo_gas',
    },
    COMPANY_NAME: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['empresa_ca'] ? data['empresa_ca']['nombre'] : null,
      }),
      title: txt('company_name.title'),
      name: 'company_name',
    },
    COMPANY_PHONE: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['empresa_ca'] ? data['empresa_ca']['telefono'] : null,
      }),
      title: txt('company_phone.title'),
      name: 'company_phone',
    },
    COMPANY_EMAIL: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: data['empresa_ca'] ? data['empresa_ca']['email'] : null,
      }),
      title: txt('company_email.title'),
      name: 'company_email',
    },
    INSTALLER_NAME: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({
        value: !!data.instalador
          ? `${data.instalador.nombre} ${data.instalador?.apellidos || ''}`
          : null,
      }),
      title: txt('company_installer.title'),
      name: 'company_installer',
    },
    POWER: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['potencia'] }),
      title: txt('power.title'),
      componentType: isGc ? null : 'select',
      values: combos?.rangos_potencia?.data || [],
      name: 'potencia',
    },
    CONSUMO: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['consumo'] }),
      title: txt('consumo.title'),
      componentType: isGc ? null : 'select',
      values: combos?.rangos_consumo_todos?.data || [],
      name: 'consumo',
    },
    UBI_CONTADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['ubi_contador'] }),
      title: txt('ubi_contador.title'),
      componentType: 'select',
      values: combos?.ubicacion_contador?.data || [],
      name: 'ubi_contador',
    },
    PROP_CONTADOR: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['prop_contador'] }),
      title: txt('prop_contador.title'),
      componentType: 'select',
      values: combos?.propiedad_contador?.data || [],
      name: 'prop_contador',
    },
    PROP_IRC: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['prop_irc_armario_reg'] }),
      title: txt('prop_irc.title'),
      componentType: 'select',
      values: combos?.propiedad_irc_armario_regulacion?.data || [],
      name: 'prop_irc_armario_reg',
    },
    CASE_CC: {
      ...DEFAULT_PROPS,
      fromData: (data) => ({ value: data['caso_caldera_centr'] }),
      title: txt('case_cc.title'),
      componentType: 'select',
      values: combos?.caso_caldera_centralizada?.data || [],
      name: 'caso_caldera_centr',
    },
  }
}

export const INSTALLATION_PANEL_STRUCTURE = ({ intl }) => {
  //Item structure: Blocks > Rows on max size > Items,
  //Use prepend and append properties to add content at the top/bottom of blocks
  //Leave empty rows to indicate a <hr/> division at that point
  const { formatMessage } = intl
  return {
    foldable: false,
    isFolded: true,
    title: formatMessage({ id: 'pages.application.detail.installation.title' }),
    items: [
      [
        { ref: 'COMPANY_NAME', conf: GRID_ITEM_MEDIUM },
        { ref: 'COMPANY_PHONE', conf: GRID_ITEM_MEDIUM },
        { ref: 'COMPANY_EMAIL', conf: GRID_ITEM_MEDIUM },
        { ref: 'INSTALLER_NAME', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'HOME_TYPE', conf: GRID_ITEM_MEDIUM },
        { ref: 'CIDI', conf: GRID_ITEM_MEDIUM },
        { ref: 'IRI', conf: GRID_ITEM_MEDIUM },
        { ref: 'GAS_TYPE', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'POWER', conf: GRID_ITEM_MEDIUM },
        { ref: 'CONSUMO', conf: GRID_ITEM_MEDIUM },
        { ref: 'UBI_CONTADOR', conf: GRID_ITEM_MEDIUM },
        { ref: 'PROP_CONTADOR', conf: GRID_ITEM_MEDIUM },
        { ref: 'PROP_IRC', conf: GRID_ITEM_MEDIUM },
        { ref: 'CASE_CC', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'SURFACE', conf: GRID_ITEM_MEDIUM },
        { ref: 'FLOORS', conf: GRID_ITEM_MEDIUM },
        { ref: 'ROOMS', conf: GRID_ITEM_MEDIUM },
        { ref: 'PEOPLE', conf: GRID_ITEM_MEDIUM },
      ],
      [],
      [
        { ref: 'PRODUCTS', conf: GRID_ITEM_MEDIUM },
        { ref: 'ENERGY', conf: GRID_ITEM_MEDIUM },
        { ref: 'BOILER', conf: GRID_ITEM_MEDIUM },
        { ref: 'BOILER_PLACE', conf: GRID_ITEM_MEDIUM },
        { ref: 'HEATER', conf: GRID_ITEM_MEDIUM },
        { ref: 'HEATER_PLACE', conf: GRID_ITEM_MEDIUM },
        { ref: 'RADIATOR', conf: GRID_ITEM_MEDIUM },
        { ref: 'TOWEL_RAIL', conf: GRID_ITEM_MEDIUM },
        { ref: 'THERMOSTAT', conf: GRID_ITEM_MEDIUM },
      ],
    ],
  }
}
