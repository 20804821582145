import React, { useMemo, useState } from 'react'
import {
  Paper,
  Box,
  InputAdornment,
  List,
  ListItem,
  FormControlLabel,
  Checkbox, Switch,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'

import { TextInput } from '../'
import { useIntl } from 'react-intl'

//Basic default functionality
const defaultRenderElement = (element, chosen, keyField, nameField) => (
  <ListItem key={element[keyField]}>
    <FormControlLabel
      label={element[nameField]}
      control={<Checkbox checked={chosen} disableRipple />}
    />
  </ListItem>
)

const defaultRenderContainer = ({ children }) => <List>{children}</List>

const defaultFilter = (element, filter, nameField) => {
  return element[nameField].toUpperCase().indexOf(filter) > -1
}

//Component Definition:
export const CardCheckList = ({
                                className,
                                //Main input:
                                keyField = 'key',
                                nameField = 'name',
                                elements = [],
                                selected = [],
                                funcRenderElement = defaultRenderElement,
                                funcRenderContainer = defaultRenderContainer,
                                //Optional Search box functionality
                                searchBox,
                                searchValue,
                                searchPlaceholder = '',
                                searchIcon = Search,
                                funcSearchFilter = defaultFilter,
                                funcSearchOnChange = (event) => {
                                },
                                //Optional Whole Selection checkbox functionality
                                wholeSelector,
                                wholeCaption = 'ALL',
                                funcWholeOnChange = (event, keys) => {
                                },
                                toggleCps = false,
                                readOnly = false,
                              }) => {
  const { formatMessage } = useIntl()

  const [showCps, setShowCps] = useState(selected.length > 0)

  //List to display: we filter element's nameField according to searchValue and render according to the given function
  const [elementsDisplay, elementsChosen, renderedList] = useMemo(() => {
    const elementsChosen = []
    const renderedList = []
    const elementsDisplay = []
    elements.forEach((el) => {
      if (searchValue && !funcSearchFilter(el, searchValue, nameField)) {
        return
      }
      const chosen = selected.indexOf(el[keyField]) > -1
      if (chosen) {
        elementsChosen.push(el)
      }
      renderedList.push(funcRenderElement(el, chosen, keyField, nameField))
      elementsDisplay.push(el[keyField])
      return
    })
    return [elementsDisplay, elementsChosen, renderedList]
  }, [selected, searchValue, elements, nameField, keyField, funcSearchFilter, funcRenderElement])

  //List container component
  const MyContainer = funcRenderContainer

  //Search box:
  let searchBoxJSX = null
  if (searchBox) {
    const SearchIcon = searchIcon
    searchBoxJSX = (
      <Box p={3}>
        <TextInput
          name="_search"
          style={{ backgroundColor: 'white' }}
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={funcSearchOnChange}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ marginRight: '1em' }} position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    )
  }

  //show cps toggle
  let toggleShowCpsJSX = null
  const handleSwitchChange = (event) => {
    const newEvent = {...event, target: {...event.target, checked: false}, persist: () => {}}
    if(readOnly) return;
    setShowCps((prev) => {
      const newShowCps = !prev
      if(!newShowCps) {
        funcWholeOnChange(newEvent, selected)
      }
      return newShowCps
    })
  }
  if (toggleCps) {
    toggleShowCpsJSX = (
      <Box p={3} textAlign={'center'}>
        <FormControlLabel control={<Switch
          checked={!showCps}
          onChange={handleSwitchChange}
          readOnly={readOnly}
          inputProps={{ 'aria-label': 'controlled' }}
        />} label={formatMessage({ id: 'pages.tariffs.zone.zips.toggle' })} />
      </Box>
    )
  }

  //Whole selector:
  let wholeSelectorJSX = null
  if (wholeSelector) {
    const wholeNum = elementsDisplay.length
    const wholeChosen = elementsChosen.length
    const isWhole = wholeNum === wholeChosen
    wholeSelectorJSX = (
      <Box p={3}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isWhole}
              indeterminate={wholeChosen > 0 && !isWhole}
              onChange={(event) => funcWholeOnChange(event, elementsDisplay)}
              disableRipple
            />
          }
          label={wholeCaption + ' (' + wholeNum + ')'}
        />
        <hr />
      </Box>
    )
  }

  //Full component:
  return (
    <Paper elevation={2} className={className}>
      {toggleShowCpsJSX}
      {(showCps || !toggleCps) && (
        <>
          {searchBoxJSX}
          {wholeSelectorJSX}
          <MyContainer>{renderedList}</MyContainer>
        </>
      )}
    </Paper>
  )
}

export default CardCheckList
