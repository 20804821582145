import React from 'react'
import { Box, Checkbox, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import { TextInput } from '../../../../ui'
import { GUILD_INFO } from '../../constants'
import { companyGuildStyles } from './company-guild.styles'

export const CompanyGuild = withStyles(companyGuildStyles)(({ classes, model = {} }) => {
  const intl = useIntl()
  const history = useHistory()
  const guildData = model.gremio || {}
  const data = GUILD_INFO({ data: { ...guildData, status: model.aceptacion_gremio }, intl })

  const handleGuildLink = () => {
    history.push(`/guilds/${model.gremio_id}`)
  }

  if (!model.gremio_id) {
    return <span>{intl.formatMessage({ id: 'pages.eeii.guild.info.none' })}</span>
  }
  return (
    <div
      data-role="company guild manager"
      className={classes.root}
      onClick={handleGuildLink}
      style={{ cursor: 'pointer' }}
    >
      <div data-role="guild info">
        <Typography variant="h6" color="primary" style={{ marginBottom: '1em' }}>
          {intl.formatMessage({ id: 'pages.eeii.guild.info.header' })}
        </Typography>
        <Box display="flex" flexDirection="column">
          {['NOMBRE', 'TELEFONO', 'STATUS'].map((entry, i) => {
            const el = data[entry]
            return (
              <Box key={i} p={1}>
                <TextInput {...el} key={entry} />
              </Box>
            )
          })}
        </Box>
        <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
          <Checkbox
            {...{
              ...data['MAIL_CHOICE'],
              checked: model.recibir_mails_solicitud_agremiado,
              disabled: true,
            }}
          />
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({ id: 'pages.eeii.guild.selector.mail.checkbox' })}
          </Typography>
        </Box>
        <Box display="flex" style={{ alignItems: 'center' }} mt={2} mb={3}>
          <Checkbox
            {...{
              ...data['DEMANDA_CHOICE'],
              checked: model.gremio_recibe_demandas,
              disabled: true,
            }}
          />
          <Typography variant="body2" color="textSecondary">
            {intl.formatMessage({ id: 'pages.eeii.guild.selector.demandas.checkbox' })}
          </Typography>
        </Box>
      </div>
    </div>
  )
})

export default CompanyGuild
