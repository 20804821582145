export const CAMPAIGNS_TABLE_COLUMNS = [
  // Nombre de la campaña
  { field: 'nombre', align: 'left', width: '200px' },
  // Código de la campaña
  { field: 'codigo', align: 'left', width: '150px' },
  // Comunidad Autónoma
  { field: 'ccaa', align: 'left', width: '150px' },
  // Mercados
  { field: 'mercados', align: 'left', width: '100px' },
  // Productos
  { field: 'productos', align: 'left', width: '300px' },
  { field: 'fechainicio', align: 'left', type: "date", width: '150px', exportType: 'date_iso' },
  { field: 'fechafin', align: 'left', type: "date", width: '150px', exportType: 'date_iso'},
  // Subvención
  { field: 'descuento', align: 'right', width: '100px' },
  // Ingreso a cuenta
  { field: 'ingreso', align: 'right', width: '100px' },
  { field: 'fecha_publicacion', align: 'left', type: "date", width: '150px', exportType: 'date_iso' },
  { field: 'fecha_prorrogada', align: 'left', type: "date", width: '150px', exportType: 'date_iso'},
  {
    field: 'financiacion',
    align: 'left',
    render: (rowData) => (rowData.financiacion ? `${rowData.meses_financiacion} meses` : 'No'),
    width: '150px'
  },
  // Prioridad de la campaña
  // { field: 'prioridad', align: 'left', width: 150 },
  // Activa
  { field: 'activa', disableClick: true, align: 'center', width: '100px', exportType: 'boolean' },
  { field: 'actions', disableClick: true, align: 'center', width: '100px', export: false },
]

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
