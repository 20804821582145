import { JCA_EXPORT_COLUMNS, JCA_TABLE_COLUMNS } from '../components/table/jca-table.constants'

export const jcaTableDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedDates = {}
    const formattedText = {}
    JCA_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedDates[column.field] = intl.formatDate(item[column.field])
      }
      if (column.multiple && Array.isArray(item[column.field]) && item[column.field].length > 0) {
        const itemValues = item[column.field].map(({ nombre }) => nombre)
        formattedText[column.field] = itemValues.join(', ')
      }
    })
    return {
      ...item,
      ...formattedDates,
      ...formattedText,
    }
  })
}

export const jcaExportDataPreparation = ({ data, combos, intl }) => {
  return data.map((item) => {
    const formattedText = {}
    JCA_EXPORT_COLUMNS.forEach((column) => {
      if (column.field === 'tipo_documento') {
        formattedText[column.field] = 'JCA'
      }
      if (column.field === 'estado') {
        const estadoValue = combos.estados_jca.data?.find(
          (option) => option.key === item[column.field]
        )
        formattedText[column.field] = estadoValue?.value || item[column.field]
      }
      if (column.type === 'date' && item[column.field]) {
        const date = new Date(item[column.field])
        const formatedDate = intl.formatDate(date)

        formattedText[column.field] = formatedDate
      }
      if (column.field === 'tipo_gas' && item[column.field]) {
        let tipoGas = ''
        if (item[column.field] === 'GAS NATURAL') {
          tipoGas = 'GN'
        } else {
          tipoGas = 'GLP'
        }

        formattedText[column.field] = tipoGas
      }
    })
    return {
      ...item,
      ...formattedText,
    }
  })
}
