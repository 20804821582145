import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { CardCheckList } from '../../../../ui/check-list/index'
import { ThemeButton } from '../../../../ui/theme-button'
import { useIntl } from 'react-intl'
import {
  withStyles,
  Typography,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem,
  ListItemText,
  CircularProgress,
  Icon,
} from '@material-ui/core'
import { toast } from 'react-toastify'

import { useAjaxContext } from '../../../../core/ajax'
import { useZoneCalls, useZonesReducer } from '../../hooks/'
import { marketerNewStyles } from './marketer-zone.style'
import { ProvinceList } from '../province-list'
import { feedback } from '../../../../core/feedback/'

export const MarketerZones = withStyles(marketerNewStyles)(
  ({ classes, marketerId, formState, close }) => {
    const mercados_combo = useSelector((state) => state.combos.tipo_mercado_comercializadora.data)
    const provincias_combo = useSelector((state) => state.combos.provincias_comercializadora.data)
    const { formatMessage } = useIntl()
    const { getZoneData } = useZoneCalls()
    const { putById } = useAjaxContext()
    const {
      mercados,
      zips,
      provincias,
      currentProvincia,
      provinciasToSend,
      zoneLoad,
      zoneUpdateZip,
      zoneProvince,
      zipsToSend,
      zoneWholeZipCheck,
      zoneWholeZipsToSend,
      provinceToSend,
      marketerData,
    } = useZonesReducer()

    const [selectedMarkets, setSelectedMarkets] = useState([])
    const [provinciaMirada, setProvinciaMirada] = useState(null)
    const [searchQuery, setSearchQuery] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const isAllMarketsSelected =
      mercados_combo.length && selectedMarkets?.length
        ? mercados_combo.length === selectedMarkets.length
        : false

    useEffect(() => {
      formState.hasOwnProperty('provincias') && marketerData(formState)
    }, [marketerId, formState]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const supportArray = []
      mercados.forEach((mercadoId) =>
        mercados_combo.forEach((mercado) => {
          if (mercado.id === mercadoId) {
            supportArray.push(mercado.value)
            setSelectedMarkets(supportArray)
          }
        })
      )
    }, [mercados, formState]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSelectProvince = useCallback(
      (province) => {
        let isProvinciaInTheState = false
        provincias.forEach((provincia, i) => {
          if (provincia.prov === province) {
            isProvinciaInTheState = true
            zoneProvince(provincia.prov, i)
            setProvinciaMirada(province)
          }
        })
        if (!isProvinciaInTheState) {
          setIsLoading(true)
          getZoneData(marketerId, province)
            .then((data) => {
              setProvinciaMirada(province)
              zoneLoad(data)
              zoneProvince(province, provincias.length)
              isProvinciaInTheState = false
              setIsLoading(false)
            })
            .catch((error) => {
              console.log(error)
            })
          //provinceToSend(provinciaMirada)
        } else {
          setProvinciaMirada(province)
          isProvinciaInTheState = false
        }
      },
      [provincias] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleToggleZip = useCallback(
      (zip) => {
        const province = provinciaMirada
        const newState = !zip.activa
        const singleZip = zip.value
        zoneUpdateZip(province, singleZip, newState)
        zipsToSend(zip.value)
        provinceToSend()
      },
      [provinciaMirada] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleWholeZips = useCallback(
      (event, zips) => {
        const newState = event.target.checked
        zoneWholeZipCheck(provinciaMirada, newState)
        zoneWholeZipsToSend(provinciaMirada, newState, zips)
        provinceToSend()
      },
      [provinciaMirada] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const handleSearchQueryChange = useCallback(
      (event) => {
        const newText = event.target.value
        setSearchQuery(newText)
      },
      [setSearchQuery]
    )

    const handleSelect = (e) => {
      if (e.target.value.includes('all')) {
        if (selectedMarkets.length === mercados_combo.length) {
          setSelectedMarkets([])
        } else {
          const wholeMarkets = []
          mercados_combo.forEach((mercado) => {
            wholeMarkets.push(mercado.value)
            setSelectedMarkets(wholeMarkets)
          })
        }
      } else {
        setSelectedMarkets(e.target.value)
      }
    }

    const zipCheckRender = useCallback(
      (element) => (
        <FormControlLabel
          key={element.value}
          className={classes.zipCard + (element.activa ? ' ' + classes.zipCardChosen : '')}
          label={element.value}
          control={
            <Checkbox
              color="primary"
              name={element.value}
              className={classes.zipMarker}
              checked={element.activa}
              onChange={() => handleToggleZip(element, provinciaMirada)}
              disableRipple
            />
          }
        />
      ),
      [classes, handleToggleZip] // eslint-disable-line react-hooks/exhaustive-deps
    )
    const zipBlockRender = useCallback(
      ({ children }) => <Box className={classes.zipContainer}>{children}</Box>,
      [] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const transformMarketsValuesOnIds = () => {
      const marketIdArray = []
      selectedMarkets.map((market) =>
        mercados_combo.forEach((combomarkert) => {
          if (market === combomarkert.value) {
            marketIdArray.push(combomarkert.id)
          }
        })
      )
      return marketIdArray
    }
    const handleSaveData = () => {
      setIsLoading(true)
      const idToSend = transformMarketsValuesOnIds()
      const dataToSend = {
        mercados: idToSend,
        provincias: provinciasToSend,
        cod_codigo_postal: zips,
      }

      putById('/comercializadoras', `${marketerId}/filtros`, dataToSend)
        .then(({ data }) => {
          setIsLoading(false)
          toast.success(
            <>
              <Icon>check_circle</Icon>
              <Typography
                variant="body1"
                display="inline"
                style={{ verticalAlign: 'super', marginLeft: 8 }}
              >
                Filtros guardados
              </Typography>
            </>
          )
          close()
        })
        .catch(({ response }) => {
          feedback('error', response.data.message || 'No se han podido guardar los cambios')
          setIsLoading(false)
        })
    }

    return (
      <Box mt={3}>
        <Box display="flex" justifyContent="space-between" pt={6} pb={3} flexWrap="wrap">
          <Box width={190} mb={[3, 0]}>
            <ThemeButton onClick={close} variant="outlined" fullWidth>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <Box width={190}>
            <ThemeButton onClick={handleSaveData} type="submit" color="primary" fullWidth>
              {formatMessage({ id: 'global.save' })}
            </ThemeButton>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={1} mb={8}>
          <Typography variant="h4" color="textSecondary">
            Editar filtros comercializadora
          </Typography>
        </Box>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <InputLabel id="mercados-multiple-chip-label">Mercados</InputLabel>
            <Select
              fullWidth
              multiple
              labelId="mercados-multiple-chip-label"
              id="mercados-multiple-chip-label"
              name={'mercados'}
              value={selectedMarkets}
              onChange={handleSelect}
              input={<Input id="select-mercados-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={{ variant: 'menu' }}
            >
              <MenuItem divider value="all">
                <Checkbox checked={isAllMarketsSelected} />
                <ListItemText>{formatMessage({ id: 'global.select.all' })}</ListItemText>
              </MenuItem>
              {mercados_combo.map((mercado) => (
                <MenuItem key={mercado.id} value={mercado.value}>
                  <Checkbox checked={selectedMarkets.includes(mercado.value)} />
                  <ListItemText primary={mercado.value} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.provinces.title' })}
            </Typography>
            <ProvinceList
              className={classes}
              provinces={provincias_combo}
              handleSelectProvince={handleSelectProvince}
              selectedProvince={currentProvincia}
              checkedProvinces={provinciasToSend}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6" gutterBottom>
              {formatMessage({ id: 'pages.tariffs.zone.zips.title' })}
            </Typography>
            {isLoading ? (
              <Box
                className={classes.loader}
                display="flex"
                height={100}
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress size={40} />
              </Box>
            ) : (
              <CardCheckList
                className={classes.colorPanel}
                keyField="value"
                nameField="value"
                elements={provincias[currentProvincia.key]?.cps.sort((a, b) => {
                  return a.value - b.value
                })}
                selected={provincias[currentProvincia.key]?.cps
                  .filter((zip) => zip.activa)
                  .map((el) => el.value)}
                funcRenderElement={zipCheckRender}
                funcRenderContainer={zipBlockRender}
                searchBox={true}
                searchValue={searchQuery}
                searchPlaceholder={formatMessage({ id: 'pages.tariffs.zone.zips.filter' })}
                funcSearchOnChange={handleSearchQueryChange}
                wholeSelector={true}
                wholeCaption={formatMessage({ id: 'pages.tariffs.zone.zips.all' })}
                funcWholeOnChange={handleWholeZips}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    )
  }
)
