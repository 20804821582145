import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { ipFilterStyles } from './ip-filter.styles'
import { IP_FILTERS } from './ip-filter.constants'
import { IP_EXPORT_COLUMNS } from '../table/ip-table.constants'
import { ipExportDataPreparation } from '../../logics'
import { useIPCalls } from '../../hooks'
import { useIPContext } from '../../contexts'
import feedback from '../../../../core/feedback'

export const IPFilter = () => {
  const ipClasses = ipFilterStyles()
  const intl = useIntl()
  const { formatMessage } = intl
  const combos = useSelector((state) => state.combos)
  const { getIIPPCall } = useIPCalls()
  const { tableSelection, search } = useIPContext()

  const filters = IP_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.ip.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <FilterBox filters={filters} classes={ipClasses}>
      <ExportToXLSBtn
        exportSelectionEnabled={false}
        exportAllEnabled={true}
        selectedRows={tableSelection}
        columnsExport={IP_EXPORT_COLUMNS}
        getAllData={() => {
          return new Promise((resolve, reject) => {
            getIIPPCall({ params: { ...search, _num_x_pagina: 0 }, timeout: 0 })
              .then(({ data }) => {
                return resolve(ipExportDataPreparation({ data: data.registros, combos, intl }))
              })
              .catch((err) => {
                feedback('error', formatMessage({ id: 'global.errorDownload' }))
                return reject(err)
              })
          })
        }}
        titleRoot="ip_"
        headerNamespace="ipExport"
      />
    </FilterBox>
  )
}
