import { useCallback } from 'react'
import { requests } from '../../../core/ajax'

export const useTariffsCalls = () => {
  const { get } = requests

  const getTariffs = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/tarifa/tabla_tarifas', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getTariffs,
  }
}
