import { Route, Switch, Redirect } from 'react-router-dom'
import React from 'react'

import { ManagersDashboard } from '../../../features/managers/components/dashboard'

export const ManagersPage = () => {
  return (
    <Switch>
      <Route exact path="/managers" component={ManagersDashboard} />
      <Redirect from="*" to="/managers" />
    </Switch>
  )
}
