import React from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const RestrictionsDialog = ({ close, lines }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box mb={2} textAlign="center">
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.view.detail.information.restrictions.label' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={4}>
          {lines && lines.length ? (
            <>
              <Typography variant="body1">
                {formatMessage({
                  id: 'pages.application.view.detail.information.restrictions.intro',
                })}
              </Typography>
              <ul>
                {lines.map((line, i) => (
                  <li key={i}>
                    <Typography variant="body1">{line}</Typography>
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <Typography variant="body1">
              {formatMessage({ id: 'pages.application.view.detail.information.restrictions.none' })}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <ThemeButton color="primary" onClick={close} labelM="lg">
            {formatMessage({ id: 'global.return' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
