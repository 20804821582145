export const COLLABORATORS_FILTERS = [
  {
    name: 'activo',
    filterType: 'switch',
    cleanSelf: true,
  },
  {
    name: 'empresa',
    filterType: 'autocomplete',
    comboId: 'empresas',
    size: 'small',
  },
  {
    name: 'gremio',
    filterType: 'autocomplete',
    comboId: 'gremios',
    size: 'small',
  },
  {
    name: 'ca',
    comboId: 'cod_zona_zeus',
    size: 'small',
    multiple: true,
    cleanup: ['provincias'],
  },
  {
    name: 'tipo_perfil',
    comboId: 'tipo_perfil',
    size: 'small',
  },
]
