import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'

export const ValidatePaymentBtn = () => {
  const { formatMessage } = useIntl()
  const { application, validatePayment, editMode } = useSirContext()

  const [loading, setLoading] = useState(false)

  const handleValidatePayment = () => {
    setLoading(true)
    validatePayment(application.id)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <ThemeButton
      color="primary"
      loading={loading}
      disabled={editMode}
      onClick={handleValidatePayment}
    >
      {formatMessage({ id: 'pages.sir.detail.button.validatePayment.label' })}
    </ThemeButton>
  )
}
