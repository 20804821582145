import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { TextInput, SelectInput, ThemeButton } from '../../../../ui'
import { useCombos } from '../../../combos'

const TEXT_AREA_LENGTH = 250

export const ResolveIssueDialog = ({ close, jcaId, resolveIssue }) => {
  const { formatMessage } = useIntl()
  const combos = useCombos(['estados_no_conformidad'])

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    resolveIssue(jcaId, formState)
      .then((callback) => {
        setLoading(false)
        close()
        callback.redirectToTable()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.jca.detail.resolveIssueDialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Typography variant="body1" paragraph>
              {formatMessage({ id: 'pages.jca.detail.resolveIssueDialog.description.1' })}
            </Typography>
            <Typography variant="body1" paragraph>
              {formatMessage({ id: 'pages.jca.detail.resolveIssueDialog.description.2' })}
            </Typography>
          </Box>
          <Box mb={2}>
            <SelectInput
              required={true}
              name="resultado_incidencia"
              value={formState.resultado_incidencia}
              values={combos.resultado_no_conformidad?.data || []}
              onChange={handleChangeForm}
            />
          </Box>
          <Box mb={3}>
            <TextInput
              name="observaciones_incidencia_gestor"
              value={formState.observaciones_incidencia_gestor}
              placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
              multiline={true}
              onChange={handleChangeForm}
              rows={4}
              rowsMax={4}
            />
          </Box>
          <Box display="flex" justifyContent="space-around" flexWrap="wrap">
            <Box width={150} mb={[3, 0]}>
              <ThemeButton onClick={close} color="inherit" disabled={loading} fullWidth>
                {formatMessage({ id: 'global.cancel' })}
              </ThemeButton>
            </Box>
            <Box width={150}>
              <ThemeButton
                type="submit"
                color="primary"
                fullWidth
                loading={loading}
                disabled={loading}
              >
                {formatMessage({ id: 'global.accept' })}
              </ThemeButton>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Box>
  )
}
