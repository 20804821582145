export const ROOT_CERTIFICATES_DOCUMENTS = 'pages.certificates.documents'

export const DOCUMENT_ATTACHMENT_CONFIG = {
  DN: {
    id: 'd_DN_id',
    model: 'd_DN',
    type: 'DN',
    field: 'DN',
    label: 'dn.title',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  ACIF: {
    id: 'd_ACIF_id',
    model: 'd_ACIF',
    type: 'ACIF',
    field: 'ACIF',
    label: 'acif.title',
    accept: ['application/pdf'],
    maxSize: 10000000,
  },
}
