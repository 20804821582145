import { configureStore } from '@reduxjs/toolkit'

import { globalReducer } from '../../features/global'
import { profileReducer } from '../../features/profile/slices'
import { marketersReducer } from '../../features/marketers/slices'
import { managersReducer } from '../../features/managers/slices'
import { applicationsReducer } from '../../features/applications/slices'
import { combosReducer } from '../../features/combos/slices'
import { loaderReducer } from '../../features/loader/slices'

// TODO: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

export default configureStore({
  reducer: {
    // router: connectRouter(history),
    global: globalReducer,
    profile: profileReducer,
    marketers: marketersReducer,
    managers: managersReducer,
    loader: loaderReducer,
    applications: applicationsReducer,
    combos: combosReducer,
  },
})
