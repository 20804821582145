import React, { useState } from 'react'

import {
  withStyles,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { passwordInputStyles } from './password-input.styles'

export const PasswordInput = withStyles(passwordInputStyles)(({ classes, ...props }) => {
  const [visibility, setVisibility] = useState(false)

  return (
    <FormControl className={classes.root}>
      <div className={classes.title}>{props.title}</div>
      <TextField
        {...props}
        type={visibility ? 'text' : 'password'}
        variant="outlined"
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={props.onChange}
        InputProps={{
          ...props.InputProps,
          className: classes.input,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setVisibility(!visibility)}>
                {visibility ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText id="my-helper-text" error={props.error}>
        {props.error ? props.errorMessage : props.helpMessage}
      </FormHelperText>
    </FormControl>
  )
})
