import React from 'react'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useCombos } from '../../../features/combos'

import { useURLLoader } from '../../../features/loader/hooks/loader.hook'
import { useInstallerCalls } from '../../../features/installers/hooks'
import { InstallerTable } from '../../../features/installers/components/table/installer-table.component'
import { InstallersHeader } from '../../../features/installers/components/header'
import { installersStyles } from './installers.styles'

export const InstallersPage = withStyles(installersStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getInstallers } = useInstallerCalls()
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'installers',
    call: getInstallers,
  })
  return (
    <Container className={classes.container} maxWidth="false">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.users.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <InstallersHeader />
      </Box>
      <Box>
        <InstallerTable />
      </Box>
    </Container>
  )
})
