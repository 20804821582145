import React, { useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Paper } from '@material-ui/core'
import { MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'

import { paymentTableStyles } from './payment-table.styles'
import { paymentDataPreparation } from '../../logics'
import { PAYMENTS_TABLE_COLUMNS } from '../../constants'
import { useRowSelector } from '../../../loader/hooks'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const PaymentsTable = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const paymentsLoader = useSelector((state) => state.loader.payments)
  const combos = useSelector((state) => state.combos)

  const data = paymentsLoader?.data?.pagos || []
  const totalEntries = paymentsLoader?.data?.total_pagos
  const search = paymentsLoader?.config?.params
  const role = useSelector((state) => state.global.role)
  const loading = paymentsLoader?.loading

  const handleGoToDetail = (e, row) => {
    if (role !== 'sir') {
      history.push(`/applications/${row.sol_id}`)
    } else {
      history.push(`sir/${row.sol_id}`)
    }
  }

  //Managing selection
  useEffect(() => {
    if (selection.length) {
      addSelection([])
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const { selection, addSelection } = useRowSelector({
    identifier: 'payments',
    selectFilter: (state) => {
      return state?.data?.pagos
    },
    idField: 'pago_id',
  })

  const handleSelectRows = useCallback((rows, row) => {
    addSelection(rows)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    return PAYMENTS_TABLE_COLUMNS.map((column) => {
      if (column.field === 'actions')
        return {
          ...column,
          title: formatMessage({ id: 'global.actions' }),
        }
      return {
        ...column,
        title: formatMessage({ id: `pages.payments.table.columns.${column.field}.title` }),
        cellStyle: {
          ...paymentTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }
    })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Assembling and adjusting data
  const processedData = useMemo(() => {
    const selectedIds = selection.map((el) => el.pago_id)
    return paymentDataPreparation(data, { intl }).map((row) => {
      row.tableData.checked = selectedIds.indexOf(row.pago_id) !== -1
      return row
    })
  }, [data, selection]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const finalTableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: true,
      headerStyle: paymentTableStyles.header,
      draggable: false,
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.children}
        </MTableCell>
      ),
    }),
    []
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={finalTableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
      onSelectionChange={handleSelectRows}
      onRowClick={handleGoToDetail}
    />
  )
}
