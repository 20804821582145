export const SIR_STATES = {
  CREATED: {
    KEY: '100',
    DESCRIPTION: 'The application has been created by customer through WP web',
  },
  // ASSIGNED: {
  //   KEY: '101',
  //   DESCRIPTION:
  //     'The application has been assigned to a sir manager who will validate it and creates a budget',
  // },
  BUDGET_CREATED: {
    KEY: '102',
    DESCRIPTION:
      'The sir manager has created a budget and now is ready to be sended to the customer',
  },
  BUDGET_SENDED: {
    KEY: '103',
    DESCRIPTION:
      'The application has been sent to the customer and now needs to be approved by the customer',
  },
  VALIDATE_PAYMENT: {
    KEY: '110',
    DESCRIPTION:
      'The customer has been paid and the payment needs to be validated by the sir manager',
  },
  PAYMENT_VALIDATED: {
    KEY: '112',
    DESCRIPTION: 'The payment made by the customer has been validated by the sir manager',
  },
  ORDER_MADE: {
    KEY: '114',
    DESCRIPTION: '114',
  },
  CONSTRUCTION: {
    KEY: '116',
    DESCRIPTION: '116',
  },
  PENDING_CONFIRMATION_PROGRAMMING_DATES: {
    KEY: '118',
    DESCRIPTION: 'Pending confirmation of reopening due to closure programming dates',
  },
  SCHEDULED: {
    KEY: '120',
    DESCRIPTION: '120',
  },
  FINISHED: {
    KEY: '90',
    DESCRIPTION: '90',
  },
  REVIEW: {
    KEY: '00',
    DESCRIPTION: 'The application has been cancelled by the customer',
  },
  CANCELLED: {
    KEY: '03',
    DESCRIPTION: 'The application has been definitively cancelled',
  },
}
