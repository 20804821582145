import React, { useState } from 'react'
import { TextInput } from "../../../ui";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const InputGenerator = ({
  type = 'text',
  label = '',
  name = '',
  value = '',
  required = false,
  options = [],
  handleChange = () => { },

}) => {

  const inputProps = {
    required,
    name: name,
    type: type,
    label: label,
    value: value,
    onChange: handleChange,
    ...options,
  }
  switch (type) {
    case 'text':
      return <TextInput {...inputProps} />
    case 'int':
      inputProps.type = 'number'
      return <TextInput {...inputProps} />
    case 'textarea':
      inputProps.multiline = true
      return <TextInput {...inputProps} />
    case 'select':
      return (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            {...inputProps}
          >
            {options.map((option) => (
              <MenuItem value={option.value}>{option.key}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    case 'checkbox':
      return <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={handleChange}
            name={name}
            color="primary"
            fullWidth
          />
        }
        label={label}
      />
    case 'list':
      value = value || []
      return <Autocomplete
          sx={{marginTop: '8px', marginBottom: '8px'}}
        multiple
        defaultValue=""
        freeSolo
        onChange={handleChange}
        options={[]}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={label} placeholder="Pulsa intro para añadir" name={name} />
        )}
      />
    case 'datepicker':
      return (
        <form noValidate >
          <TextField
            fullWidth
            id={name}
            label={label}
            type="date"
            defaultValue={value}
            onChange={handleChange}
            name={name}
            value={value}
            reqired={required}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      )
    default:
          return <TextInput {...inputProps} />

  }
}