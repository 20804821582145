import React, { useState, useEffect } from 'react'
import { withStyles, Tabs, Tab, Typography, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { parse } from 'qs'

import { CompanyForm } from '../form/company-form.component'
import { useCombos } from '../../../combos'
import { eeiiTabsStyles } from './eeii-tabs.styles'

const TabTitle = ({ company }) => {
  const combos = useCombos([])
  const ccaaCombo = combos.cod_zona_zeus?.data

  const ccaaName =
    company.comunidad_autonoma &&
    ccaaCombo?.find(({ key }) => key === company.comunidad_autonoma + '')
  return (
    <>
      <Typography variant="subtitle1" color="primary">
        {company.nombre}
      </Typography>
      {company.comunidad_autonoma && (
        <Typography variant="caption">{(ccaaName && ccaaName.value) || '--'}</Typography>
      )}
    </>
  )
}

export const EEIITabs = withStyles(eeiiTabsStyles)(
  ({ classes, primaryCompany, secondaryCompanies = [], getEIData }) => {
    const history = useHistory()
    const params = parse(history.location.search.substring(1))
    let defaultTabIndex = 0
    if (params.ca) {
      const selectedSecCompanyIndex = secondaryCompanies.findIndex(
        (company) => company.id.toString() === params.ca
      )
      if (selectedSecCompanyIndex !== -1) {
        defaultTabIndex = selectedSecCompanyIndex + 1
      }
    }

    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultTabIndex)

    const handleChange = (_, newValue) => {
      setSelectedTabIndex(newValue)
      const secondaryCompanyId = secondaryCompanies[newValue - 1]?.id
      const newParams = secondaryCompanyId ? `?ca=${secondaryCompanyId}` : ''
      history.push(`${history.location.pathname}${newParams}`)
    }

    useEffect(() => {
      if (!secondaryCompanies[selectedTabIndex - 1]) {
        setSelectedTabIndex(0)
      }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Tabs value={selectedTabIndex} onChange={handleChange} variant="scrollable">
          <Tab
            key={0}
            label={<TabTitle company={primaryCompany} />}
            classes={{ selected: classes.tabSelected }}
          />
          {secondaryCompanies.map((company, i) => {
            return (
              <Tab
                key={i + 1}
                label={<TabTitle company={company} />}
                classes={{ selected: classes.tabSelected }}
              />
            )
          })}
        </Tabs>
        <Box p={3} bgcolor="grey.100">
          {selectedTabIndex === 0 || !secondaryCompanies[selectedTabIndex - 1] ? (
            <CompanyForm {...{ model: primaryCompany, isPrimaryCompany: true, getEIData }} />
          ) : (
            <CompanyForm
              {...{
                model: {
                  ...secondaryCompanies[selectedTabIndex - 1],
                  publica: primaryCompany.publica,
                },
                isPrimaryCompany: false,
              }}
            />
          )}
        </Box>
      </>
    )
  }
)
