import React from 'react'

import { FilterBox, ThemeButton } from "../../../../ui";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNotificationsCalls } from "../../hooks";
import { NOTICES_TABLE_FILTERS } from "../../constants/notices-table.constants";
import { useHistory, useLocation } from "react-router-dom";
import { PopupEditDialogComponent } from "../../dialogs";


export const NoticesHeader = () => {
  const intl = useIntl()
  const { formatMessage } = intl
  const noticesLoader = useSelector((state) => state.loader.notices)
  const combos = useSelector((state) => state.combos)
  const {postNotification} = useNotificationsCalls()
  const search = noticesLoader?.config?.params
  const history = useHistory()
  const location = useLocation()
  const currentSearch = new URLSearchParams(location.search)

  const [openDialog, setOpenDialog] = React.useState(false);

  const filters = NOTICES_TABLE_FILTERS
  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.notices.filters.' + item.name + '.title',
      }),
    }
  })

  const newNotice = () => {
    setOpenDialog(true)
  }
  const closeDialog = () => {
    setOpenDialog(false)
  }

  const createNotice = (notice) => {
    postNotification(notice).then(
        () => {
            closeDialog()
          currentSearch.set('updated', Date.now().toString())
          history.replace({
            search: '?' + currentSearch.toString(),
          })
          currentSearch.delete('updated')
          history.replace({
            search: '?' + currentSearch.toString(),
          })
        }
    )
  }

  return (
    <>
      <FilterBox filters={data} >
          <ThemeButton
            onClick={newNotice}
            labelM="sm"
          color="primary">
            {formatMessage({ id: 'pages.notices.filters.header.create.button' })}
        </ThemeButton>
      </FilterBox>
      <PopupEditDialogComponent
        open={openDialog}
        onClose={closeDialog}
        edit={true}
        onEdit={createNotice}
        profilesList={combos['roles_all'] && combos['roles_all'].data}
        ccaaList = {combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data}
        tagsList = {combos['tags_avisos'] && combos['tags_avisos'].data}
      />
    </>
  )
}
