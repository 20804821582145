import { PUBLIC_OFFER_ACTIONS_TYPES } from './public-offer-reducer.constants'

export const publicOfferReducer = (state, { type, payload }) => {
  switch (type) {
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_TABLE_DATA:
      return {
        ...state,
        data: payload.data,
        search: payload.search,
        pagination: payload.pagination,
        loading: false,
        msLoaded: Date.now(),
      }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_PUBLIC_OFFER:
      return {
        ...state,
        publicOffer: payload,
        detailLoading: false,
      }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        error: false,
        loading: true,
      }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        msLoaded: true,
      }

    case PUBLIC_OFFER_ACTIONS_TYPES.SET_TABLE_SELECTION:
      return {
        ...state,
        tableSelection: [
          ...state.tableSelection.filter(
            (el) => state.data.map((item) => item.id).indexOf(el.id) === -1
          ),
          ...payload,
        ],
      }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_LOADING:
      return {
        ...state,
        detailError: false,
        detailLoading: true,
      }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        detailError: true,
        detailLoading: false,
      }
    // case PUBLIC_OFFER_ACTIONS_TYPES.SET_EDIT_MODE:
    //   return {
    //     ...state,
    //     editMode: payload,
    //   }
    case PUBLIC_OFFER_ACTIONS_TYPES.SET_PUBLIC_OFFER_STATUS: {
      let newData = [...state.data]
      const rowIndex = newData.findIndex((row) => row.id === payload.id)
      newData[rowIndex] = { ...state.data[rowIndex], activa: payload.state }
      return {
        ...state,
        data: newData,
      }
    }
    default:
      throw new Error('unknown action')
  }
}
