import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { PublicOfferCreateForm } from '../../../../features/public-offer'
import { useCombos } from '../../../../features/combos/hooks'
import { PUBLIC_OFFER_COMBOS_IDS } from '../../constants'
import { PageDisplayer } from '../../../../ui'

export const PublicOfferCreatePage = () => {
  const history = useHistory()
  const { formatMessage } = useIntl()
  useCombos(PUBLIC_OFFER_COMBOS_IDS)

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/public-offer' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.campaigns.detail.goBack' })}
    >
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.public.offer.create.title' })}
        </Typography>
      </Box>
      <PublicOfferCreateForm />
    </PageDisplayer>
  )
}
