import React, { useEffect, useState } from 'react'
import { Box, DialogContent } from '@material-ui/core'

import { useMarketersCalls } from '../../hooks'
import { MarketerZones } from '../zones'

export const MarketerEditDialogComponent = ({ close, marketerId, marketer, props }) => {
  const { getMarketer } = useMarketersCalls()

  const [formState, setFormState] = useState({})

  useEffect(() => {
    getMarketer(props.rowData['id']).then(({ data }) => {
      setFormState({
        ...formState,
        mercados: data.filtros.mercados,
        provincias: data.filtros.provincias,
        cps: data.filtros.cod_codigo_postal,
      })
    })
  }, [props.rowData.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box p={2}>
      <DialogContent>
        {
          <MarketerZones
            {...{
              marketerId: marketerId,
              formState: formState,
              close,
            }}
          />
        }
      </DialogContent>
    </Box>
  )
}
