import React, { useEffect, useState } from 'react'
import { useHistory, useParams} from 'react-router'

import { EEIIActionButtons, EEIIPanel, useEEIICalls } from '../../../features/eeii-detail'
import { PageDisplayer } from '../../../ui'
import { useCombos } from '../../../features/combos'

export const EEIIDetail = () => {
  const { getEI } = useEEIICalls()
  const { id } = useParams()
  const history = useHistory()
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])

  const [companyData, setCompanyData] = useState({})
  const [loading, setLoading] = useState(true)

  const getEIData = () => {
    getEI(id).then(({ data }) => {
      setCompanyData(data)
      setLoading(false)
    })
  }

  useEffect(() => {
    getEIData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    // const prevSearch = history.location.state?.prevSearch || ''
    // history.push('/eeii' + prevSearch)
    if (history.action !== 'POP') {
      history.goBack();
    }
    else {
      history.push('/eeii')
    }
  }

  return (
    <PageDisplayer loading={loading} handleGoBack={handleGoBack} textGoBack={'Atrás'}>
      <EEIIActionButtons {...{ companyData }} />
      <EEIIPanel {...{ companyData, getEIData }} />
    </PageDisplayer>
  )
}

export default EEIIDetail
