import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import XLSX from 'xlsx'

import { useModalContext } from '../../../ui'
import { dataToXLS, getFormattedTime } from '../../../core/utils'
import { ExportNotEmptyDialog } from '../components/dialogs'
import { useAjaxContext } from '../../../core/ajax'
import { applicationDataPreparation } from '../logics'
import { useApplicationUserPermitsContext } from '../../../pages/applications/contexts'

export const useApplicationExports = () => {
  const intl = useIntl()
  const search = useSelector((state) => state.applications.search)
  const pagination = useSelector((state) => state.applications.pagination)
  const combos = useSelector((state) => state.combos)
  const [loading, setLoading] = useState(false)
  const { get } = useAjaxContext()
  const { columns_export, appUrl } = useApplicationUserPermitsContext()

  const {
    actions: { open },
  } = useModalContext()

  const mainExport = (data) => {
    const result = dataToXLS(data, columns_export, combos, intl)
    var worksheet = XLSX.utils.aoa_to_sheet(result)
    var new_workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(new_workbook, worksheet, 'SheetJS')
    XLSX.writeFile(new_workbook, `solicitudes_${getFormattedTime()}.xlsx`, {
      bookType: 'xlsx',
      type: 'binary',
    })
    setLoading(false)
  }

  const handleExportAll = async () => {
    setLoading(true)
    const { _num_x_pagina, _pagina, ...otherSearch } = search
    const { total_solicitudes } = pagination
    if (!total_solicitudes) {
      open({
        Component: ExportNotEmptyDialog,
      })
      setLoading(false)
      return
    }
    get(appUrl, {
      params: { ...otherSearch, _num_x_pagina: total_solicitudes, _pagina: '1' },
      timeout: 0,
    })
      .then(({ data }) => {
        const recievedData = applicationDataPreparation(data.solicitudes, { intl }, columns_export)
        mainExport(recievedData)
      })
      .catch(({ response }) => {
        setLoading(false)
      })
  }

  return { loading, setLoading, mainExport, handleExportAll }
}
