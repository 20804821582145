export const GRID_ITEM = {
  FULL: {
    item: true,
    xs: 12,
    sm: 12,
    md: 12,
  },
  LARGE: {
    item: true,
    xs: 12,
    sm: 6,
    md: 6,
  },
}

export const IP_VALIDATED_DELIVERY_FAILED_STATE = '42'

export const IP_REJECTED_STATES = {
  20: true,
  23: true,
  25: true,
  26: true,
  27: true,
}

export const IP_CAN_VALIDATE_STATES = {
  20: true,
  23: true,
  24: true,
  25: true,
  26: true,
}

export const IP_CAN_REJECT_STATES = {
  24: true,
  25: true,
  26: true,
  40: true,
  42: true,
}

export const IP_NONCONFORMITY_STATES = {
  15: true,
}

export const IP_CAN_SEND_TO_ZEUS_STATES = {
  42: true,
}

export const IP_CAN_SEND_TO_ZEUS_MANUALLY_STATES = {
  40: true,
}

export const IP_CAN_VIEW_AI_VALIDATIONS_STATES = {
  25: true,
  26: true,
  27: true,
  40: true,
  41: true,
  42: true,
}
