import React from 'react'
import { useIntl } from 'react-intl'
import { withStyles, Box, Container, Typography, CircularProgress } from '@material-ui/core'

import { AppsTable, AppsHeader } from '../../../../features/applications'
import { useApplicationUserPermitsContext } from '../../contexts'
import { applicationsDashboardStyles } from './applications-dashboard.styles'

export const ApplicationsDashboard = withStyles(applicationsDashboardStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { loading } = useApplicationUserPermitsContext()

  if (loading) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  return (
    <Box>
      <Container className={classes.container} maxWidth="false">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.applications.title' })}
          </Typography>
        </Box>
        <Box mb={2}>
          <AppsHeader />
        </Box>
        <Box>
          <AppsTable />
        </Box>
      </Container>
    </Box>
  )
})
