export const REJECTED_BUDGETS_LIST_CONFIG = [
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const OLD_CROQUIS_LIST_CONFIG = [
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const CURRENT_DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const BUDGET_DOCUMENT_KEYS = [
  'd_croquis',
  'd_tallo',
  'd_comprobante',
  'd_presupuesto',
  'd_presupuesto_aceptado',
]

export const GASP_DOCUMENT_KEYS = [
  'd_peticion_gasp',
  'd_escritura_poderes',
  'd_dn',
  'd_cc',
  'd_proyecto_gas',
  'd_proyecto_calefaccion',
]

export const APP_DOCUMENT_KEYS = [
  'd_AI',
  'd_ACIF',
  'd_EP',
  'd_CC',
  'd_CP',
  'd_CR',
  'd_DN',
  'd_CH',
  'd_SG',
  'd_AR',
  'd_EF',
  'd_FC1',
  'd_FC1',
  'd_PRA',
  'd_FC1',
  'd_CM',
  'd_CA',
  'd_CI',
  'd_SCR',
  'd_CCEE',
  'd_CB',
  'd_RC',
  'd_SP',
  'd_EC',
  'd_AC',
  'd_CPI',
  'd_tallo_i',
  'd_croquis_i',
]
