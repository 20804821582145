import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, Box, Typography } from '@material-ui/core'

import { TextInput, ThemeButton } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

export const RejectRequestDialog = ({ close, onSubmit }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({ observaciones_rechazo: '' })

  const handleChangeForm = (e) => setFormState({ ...formState, [e.target.name]: e.target.value })

  const handleSubmit = () => {
    setLoading(true)
    onSubmit(formState)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.poRequests.detail.rejectRequest.dialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'pages.poRequests.detail.rejectRequest.dialog.description' })}
        </Typography>
        <Box pt={2}>
          <TextInput
            required={true}
            name="observaciones_rechazo"
            value={formState.observaciones_rechazo}
            placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
            multiline={true}
            onChange={handleChangeForm}
            rows={4}
            rowsMax={4}
          />
        </Box>
        <Box display="flex" justifyContent="space-around" pt={3} flexWrap="wrap">
          <Box width={150} mb={[3, 0]}>
            <ThemeButton onClick={close} color="inherit" disabled={loading} fullWidth>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <Box width={150}>
            <ThemeButton
              onClick={handleSubmit}
              color="primary"
              fullWidth
              loading={loading}
              disabled={loading}
            >
              {formatMessage({ id: 'global.accept' })}
            </ThemeButton>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  )
}
