import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Badge, IconButton, Tooltip, withStyles } from '@material-ui/core'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'

import { useWidth } from '../../../../core/utils'
import LogoNedgiaFull from '../../../../resources/images/logo_nedgia_full.svg'
import LogoNedgia from '../../../../resources/images/logo_nedgia.svg'
import { MenuComponent as Menu } from '../../../menu'
import {
  APP_VERSION_MSSG,
  DEFAULT_ROUTE,
  MENU_ITEMS,
  PROFILE_ROUTE,
  SETTINGS_ROUTE,
} from '../constants'
import { privateLayoutStyles } from './private-layout.styles'
import { useWordpressTokenGenerator } from '../../../../features/applications/hooks/wordpress-generator-link.hook'
import { MOCK_NOTIFICATIONS } from '../../../../mocks'
import { ExtractionButton } from '../../../../features/extraction/components'
import useSize from '../../../../core/utils/useSize'
import { NoticesHistoryComponent } from '../../../../features/notices/components/notices-history'

export const PrivateLayoutComponent = withStyles(privateLayoutStyles)(
  ({ children, classes, userRole, userPermissions }) => {
    const screenWidth = useWidth()

    const isMobileWidth = screenWidth === 'sm' || screenWidth === 'xs'

    const headerRef = useRef()
    const menuRef = useRef()
    const logoRef = useRef()
    const userIconRef = useRef()
    const [headerSize, updateHeaderSize] = useSize(headerRef)
    const [menuSize, updateMenuSize] = useSize(menuRef)
    // const [logoSize, updateLogoSize] = useSize(logoRef)
    const [userIconSize, updateUserIconSize] = useSize(userIconRef)

    const [mobileView, setMobileView] = useState(false)
    const { openWordpressUrl } = useWordpressTokenGenerator()

    useEffect(() => {
      setMobileView(false)
    }, [headerSize.width])

    useEffect(() => {
      if (!mobileView) {
        updateHeaderSize()
        updateMenuSize()
        // updateLogoSize()
        updateUserIconSize()
      }
    }, [mobileView]) // eslint-disable-line react-hooks/exhaustive-deps

    if (menuSize.width + 120 + userIconSize.width > headerSize.width - 100) {
      if (!mobileView) {
        setMobileView(true)
      }
    }

    const menu = (role, permissions) => {
      let opcions_menu = []
      switch (role) {
        case 'call_center':
          opcions_menu = [...MENU_ITEMS.CALL_CENTER]
          break
        case 'selectra':
          opcions_menu = [...MENU_ITEMS.SELECTRA]
          break
        case 'gss_vt':
          opcions_menu = [...MENU_ITEMS.GSS_VT]
          break
        case 'accom':
          opcions_menu = [...MENU_ITEMS.ACCOM]
          break
        case 'soporte_procesos':
          opcions_menu = [...MENU_ITEMS.SOPORTE_PROCESOS]
          break
        case 'sir':
          opcions_menu = [...MENU_ITEMS.SIR]
          break
        case 'delegado':
          opcions_menu = [...MENU_ITEMS.DELEGADO]
          break
        case 'gestor_gc':
          opcions_menu = [...MENU_ITEMS.GESTOR_GC]
          break
        case 'gestor':
          opcions_menu = [...MENU_ITEMS.GESTOR]
          break
        case 'e_commerce':
          opcions_menu = [...MENU_ITEMS.E_COMMERCE]
          break
        case 'gestor_ip':
          opcions_menu = [...MENU_ITEMS.GESTOR_IP]
          break
        default:
          opcions_menu = [...MENU_ITEMS.DEFAULT]
          break
      }
      if (
        permissions['editar_usuario'] ||
        permissions['editar_instalador'] ||
        permissions['crear_gestor'] ||
        role === 'gestor_gc'
      ) {
        opcions_menu.push({
          label: 'Usuarios registrados',
          children: [],
        })
        if (permissions['editar_usuario']) {
          opcions_menu.forEach((opcion) => {
            if (opcion.label === 'Usuarios registrados') {
              opcion.children.push({
                label: 'Clientes',
                path: '/users',
              })
            }
          })
        }
        if (permissions['editar_instalador']) {
          opcions_menu.forEach((opcion) => {
            if (opcion.label === 'Usuarios registrados') {
              opcion.children.push({
                label: 'Instaladores',
                path: '/installers',
              })
            }
          })
        }
        if (permissions['crear_gestor']) {
          opcions_menu.forEach((opcion) => {
            if (opcion.label === 'Usuarios registrados') {
              opcion.children.push({
                label: 'Gestores',
                path: '/managers',
              })
            }
          })
        }
        if (role === 'gestor_gc') {
          opcions_menu.forEach((opcion) => {
            if (opcion.label === 'Usuarios registrados') {
              opcion.children.push({
                label: 'Gestores GC',
                path: '/gran-consumo',
              })
            }
          })
        }
      }
      if (permissions['ver_campana'])
        opcions_menu.push({
          label: 'Campañas',
          path: '/campaigns',
        })
      if (permissions['ver_op'])
        opcions_menu.push({
          label: 'Oferta Pública',
          path: '/public-offer',
        })
      if (permissions['filtros_comercializadora'])
        opcions_menu.push({
          label: 'Comercializadoras',
          path: '/marketers',
        })
      if (permissions['seguimiento_encalladas'])
        opcions_menu.push({
          label: 'Errores grabación',
          path: '/stuck',
        })
      if (permissions['ver_tabla_no_captables'])
        opcions_menu.push({
          label: 'Validación no Captables',
          path: '/non-captables-validation',
        })
      if (permissions['gestion_adhesiones_op'])
        opcions_menu.push({
          label: 'Peticiones OP',
          path: '/po-requests',
        })
      if (permissions['ver_pago'])
        opcions_menu.push({
          label: 'Pagos',
          path: '/payments',
        })
      if (permissions['ver_tareas'])
        opcions_menu.push({
          label: 'Tareas',
          path: '/supervision',
        })
      if (permissions['batch_launcher'])
        opcions_menu.push({
          label: 'Jobs',
          path: '/jobs',
        })
      if (permissions['crear_notificaciones'])
        opcions_menu.push({
          label: 'Gestión de avisos',
          path: '/notices',
        })
      if (permissions['creador_de_contenidos']) {
        opcions_menu.push({
          label: 'Academia Comercial Nedgia',
          action: () => {
            openWordpressUrl()
          },
        })
      }

      return opcions_menu
    }

    return React.useMemo(() => {
      return (
        <div className={classes.root}>
          <div
            className={classes.headerWrapper}
            ref={headerRef}
            style={
              localStorage.getItem('bypass') === 'true' ? { backgroundColor: '#ff000085' } : {}
            }
          >
            <div className={classes.headerLeftContent} style={mobileView ? { order: '2' } : {}}>
              <div ref={logoRef} className={classes.logo}>
                <Tooltip title={APP_VERSION_MSSG}>
                  <Link to={DEFAULT_ROUTE}>
                    {screenWidth === 'xs' ? (
                      <img src={LogoNedgia} alt="" />
                    ) : (
                      <img
                        src={LogoNedgiaFull}
                        alt="Nedgia_Logo"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    )}
                  </Link>
                </Tooltip>
              </div>
            </div>
            <div ref={menuRef} className={classes.menu} style={mobileView ? { order: '1' } : {}}>
              <Menu
                items={menu(userRole, userPermissions)}
                isMobileWidth={isMobileWidth}
                mobileView={mobileView}
              />
            </div>
            <div
              ref={userIconRef}
              className={classes.headerRightContent}
              style={mobileView ? { order: '3' } : {}}
            >
              <NoticesHistoryComponent />
              {false && ( // TODO: Manage this when notices and settings turn available
                <>
                  <Link to={SETTINGS_ROUTE}>
                    <IconButton className={classes.iconButton}>
                      <SettingsOutlinedIcon />
                    </IconButton>
                  </Link>
                  <IconButton className={classes.iconButton}>
                    <Badge variant="dot" color="error" invisible={MOCK_NOTIFICATIONS.length === 0}>
                      <NotificationsOutlinedIcon />
                    </Badge>
                  </IconButton>
                </>
              )}
              {userRole === 'e_commerce' || userRole === 'soporte_procesos' ? (
                <ExtractionButton classes={classes} />
              ) : null}
              <Link to={PROFILE_ROUTE}>
                <IconButton className={classes.iconButton}>
                  <AccountCircleOutlinedIcon />
                </IconButton>
              </Link>
            </div>
          </div>
          <div
            className={classes.bodyWrapper}
            style={
              localStorage.getItem('bypass') === 'true' ? { backgroundColor: '#ff000030' } : {}
            }
          >
            {children}
          </div>
        </div>
      )
    }, [userRole, userPermissions, isMobileWidth, mobileView, headerSize.width]) // eslint-disable-line react-hooks/exhaustive-deps
  }
)
