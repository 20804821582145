import { createSlice } from '@reduxjs/toolkit'
import { requests } from '../../../core/ajax'

import { COMBOS_MOCK } from '../../../mocks'

const combosSlice = createSlice({
  name: 'combos',
  initialState: { ...COMBOS_MOCK },
  reducers: {
    getCombos: (state, { payload }) => {
      payload.forEach((combo) => {
        state[combo] = {}
        state[combo].data = []
        state[combo].isLoading = true
      })
    },
    setCombo: (state, { payload }) => {
      state[payload.comboId] = {}
      state[payload.comboId].data = payload.data
      state[payload.comboId].isLoading = false
    },
    setCombos: (state, { payload }) => {
      payload.combosIds.forEach((combo) => {
        state[combo] = {}
        state[combo].data = payload.data[combo]
        state[combo].isLoading = false
      })
    },
    setCombosLoading: (state, { payload }) => {
      payload.combosIds.forEach((combo) => {
        state[combo].isLoading = payload.isLoading
      })
    },
    clearCombos: (state, {}) => {
      Object.keys(state).forEach((key) => {
       if(!COMBOS_MOCK[key]) {
         state[key] = null
       }
      })
    }
  },
})

export const combosReducer = combosSlice.reducer

export const { getCombos, setCombo, setCombos, setCombosLoading, clearCombos } = combosSlice.actions

export const setCombosByIds = (combos) => (dispatch) => {
  const { get } = requests
  const combosIds = combos.map((combo) => combo.comboId)

  let comboIdsString = ''
  if (combosIds.length === combos.length) {
    comboIdsString = combosIds.join(',')
  }
  dispatch(getCombos(combosIds))
  get('/combo_box', { params: { parent_id: comboIdsString } })
    .then(({ data }) => {
      let preparedData = {}
      if (combosIds.length > 1) {
        preparedData = data
      } else {
        preparedData[combosIds[0]] = data
      }
      combos.forEach((combo) => {
        if (combo.order) {
          preparedData[combo.comboId] = preparedData[combo.comboId].sort((a, b) =>
            a.value > b.value ? 1 : b.value > a.value ? -1 : 0
          )
        }
      })
      dispatch(setCombos({ combosIds, data: preparedData }))
    })
    .catch(({ response }) => {
      console.error('Error recuperando combo', response && response.data)
      dispatch(setCombosLoading({ combosIds, isLoading: false }))
    })
}

export const clearCombosAction = () => (dispatch) => {
  dispatch(clearCombos())
}
