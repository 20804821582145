import React from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'
import { FilterBox } from '../../../../ui'
import { APPS_FILTERS } from '../../constants'
import { appsFilterStyles } from './apps-filter.styles'

export const AppsFilter = withStyles(appsFilterStyles)(({ children }) => {
  const { formatMessage } = useIntl()
  const location = useLocation()

  //Preparing filter data
  const combos = useSelector((state) => state.combos)
  const currentSearch = new URLSearchParams(location.search)
  const provincia = currentSearch.get('provincia')
  const data = APPS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.name === 'municipio' && item.values && provincia) {
        item.values = item.values.filter((municipio) => {
          return -1 < provincia.split(',').indexOf(municipio.provincia)
        })
      }
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.supervision.filters.' + item.name + '.title',
      }),
    }
  })
  return <FilterBox filters={data}>{children}</FilterBox>
})
