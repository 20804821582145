import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid, Typography } from '@material-ui/core'
import { ThemeButton, TextInput, SelectInput } from '../../../ui'
import { ADD_FORM_CONFIG } from './add-news.constants'
import { useGCCalls } from '../hooks'
import feedback from '../../../core/feedback'

export const AddCp = ({ close, table, setTable }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { postGestorGc, getMunicipiosByProvincia } = useGCCalls()

  const [loading, setLoading] = useState(false)
  const [formState, setFormState] = useState({})
  const [municipios, setMunicipios] = useState([])
  const [provincias, setProvincias] = useState([])
  const [municipioName, setMunicipioName] = useState('')

  const handleAddNewCp = () => {
    setLoading(true)
    if (
      municipios[municipioName].some((municipio) => {
        return municipio.municipio === formState['municipio'].toUpperCase()
      })
    ) {
      const supportArray = []
      municipios[municipioName].forEach((municipio) => {
        if (municipio.municipio === formState['municipio'].toUpperCase()) {
          return supportArray.push(municipio.cod_codigo_postal)
        }
      })

      if (
        supportArray.some((cp) => {
          return cp === formState['cod_codigo_postal']
        })
      ) {
        postGestorGc(formState)
          .then((response) => {
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
          })
        setTable(!table)
        close()
      } else {
        feedback('error', 'El codigo postal informado no pertenece a este municipio')
      }
    } else {
      feedback('error', 'El municipio elegido no corresponde a esta provincia')
    }

    //postGestorGc(formState)
  }

  const handleChangeForm = (e) => {
    if (e.target.name === 'comunidad_id') {
      setProvincias(
        combos.provincias_gc.data.find((comunidad) => {
          return comunidad.comunidad_id === parseInt(e.target.value)
        }).provincias
      )
      delete formState.municipio
      delete formState.cod_codigo_postal
    }
    if (e.target.name === 'provincia_id') {
      setMunicipioName(
        provincias.find((provincia) => {
          return provincia.key === parseInt(e.target.value)
        }).value
      )
      delete formState.municipio
      delete formState.cod_codigo_postal
      getMunicipiosByProvincia(e.target.value).then((response) => {
        setMunicipios(response.data)
      })
    }
    if (e.target.name === 'municipio') {
      e.target.value = e.target.value.toUpperCase()
    }

    if (
      e.target.name === 'comunidad_id' ||
      e.target.name === 'provincia_id' ||
      e.target.name === 'gestor_id'
    ) {
      setFormState({ ...formState, [e.target.name]: parseInt(e.target.value) })
    } else {
      setFormState({ ...formState, [e.target.name]: e.target.value })
    }
  }

  const inputProps = { formState, combos, intl, onChange: handleChangeForm, municipios, provincias }

  return (
    <Box p={4}>
      <Box mb={3}>
        <Typography variant="h3" color="primary">
          {intl.formatMessage({ id: 'pages.granconsumo.dialogs.add.title' })}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4} md={3}>
          <SelectInput {...ADD_FORM_CONFIG(inputProps).COMUNIDAD} />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <SelectInput {...ADD_FORM_CONFIG(inputProps).PROVINCIA} />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <TextInput {...ADD_FORM_CONFIG(inputProps).MUNICIPIO} />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <TextInput {...ADD_FORM_CONFIG(inputProps).COD_POSTAL} />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <SelectInput {...ADD_FORM_CONFIG(inputProps).MERCADO} />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <SelectInput {...ADD_FORM_CONFIG(inputProps).RANGO_PRESION} />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <SelectInput {...ADD_FORM_CONFIG(inputProps).GESTOR} />
        </Grid>
      </Grid>
      <Box mt={8} display="flex" justifyContent="space-between">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {intl.formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          color="primary"
          onClick={handleAddNewCp}
          labelM="lg"
          disabled={loading}
          loading={loading}
        >
          {intl.formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
