import React from 'react'
import { withStyles, FormControlLabel, Box, Typography, Radio, RadioGroup } from '@material-ui/core'

import { cardRadioButtonStyles } from './card-radio-button.styles'

export const CardRadioButton = React.memo(
  withStyles(cardRadioButtonStyles)(({ classes, values = [], ...props }) => {
    const value = props.value ? props.value : null
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'
    const handleChange = (_, value) => {
      props.onChange({ target: { name: props.name, value } })
    }
    return (
      <Box>
        {props.title && (
          <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
            {props.title}
          </Typography>
        )}
        <Box display="flex" justifyContent="center">
          <RadioGroup
            value={value}
            style={{ display: 'flex', flexDirection: 'row' }}
            onChange={handleChange}
          >
            {values.map((element, i) => {
              return (
                <FormControlLabel
                  key={i}
                  className={classes.card + (element.key === value ? ' ' + classes.cardChosen : '')}
                  value={element.key}
                  control={<Radio className={classes.cardMarker} />}
                  label={element.value}
                />
              )
            })}
          </RadioGroup>
        </Box>
      </Box>
    )
  })
)
