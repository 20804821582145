import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { BasicTable } from '../../../../ui'
import { SIR_EVENTS_TABLE_CONFIG } from './events-table.constants'
import { useSirContext } from '../../contexts'

export const AppEventsTable = ({ model }) => {
  const { id } = model
  const intl = useIntl()
  const { getSirEvents, events } = useSirContext()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getSirEvents(id)
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [model]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading)
    return (
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  return events.length ? (
    <BasicTable config={SIR_EVENTS_TABLE_CONFIG(intl)} data={events} loading={loading} />
  ) : (
    intl.formatMessage({ id: 'pages.sir.detail.history.emptyList' })
  )
}
