import { useCallback, useReducer } from 'react'

import { zonesReducer } from '../reducers'
import * as zonesActions from '../constants'

export const useZonesReducer = () => {
  const [state, dispatch] = useReducer(zonesReducer, {
    mercados: [],
    provincias: [],
    currentProvincia: {},
    zips: [],
    provinciasToSend: [],
  })

  const marketerData = useCallback((payload) => {
    dispatch({
      type: zonesActions.SET_MARKETERS_DATA,
      payload: payload,
    })
  }, [])

  //funciones previas al refactor
  const zoneLoad = useCallback((payload) => {
    dispatch({
      type: zonesActions.SET_ZONES_DATA,
      payload: payload,
    })
  }, [])

  const zoneProvince = useCallback((provincia, key) => {
    dispatch({
      type: zonesActions.CHECK_SINGLE_PROVINCE,
      payload: {
        currentProvincia: { provincia, key },
      },
    })
  }, [])

  const zoneUpdateZip = useCallback((provincia, zip, newState) => {
    dispatch({
      type: zonesActions.CHECK_SINGLE_ZIP,
      payload: {
        provincia: provincia,
        zip: zip,
        newState: newState,
      },
    })
  }, [])

  const zipsToSend = useCallback((zipValue) => {
    dispatch({
      type: zonesActions.ZIPS_TO_SEND,
      payload: zipValue,
    })
  }, [])

  const zoneWholeZipCheck = useCallback((provincia, newState) => {
    dispatch({
      type: zonesActions.WHOLE_ZIP_TO_CHECK,
      payload: {
        provincia: provincia,
        newState: newState,
      },
    })
  }, [])

  const zoneWholeZipsToSend = useCallback((provincia, newState, zips) => {
    dispatch({
      type: zonesActions.WHOLE_ZIPS_TO_SEND,
      payload: {
        provincia: provincia,
        newState: newState,
        zips: zips,
      },
    })
  }, [])

  const provinceToSend = useCallback(() => {
    dispatch({
      type: zonesActions.PROVINCE_TO_SEND,
    })
  }, [])

  return {
    mercados: state.mercados,
    currentProvincia: state.currentProvincia,
    provincias: state.provincias,
    zips: state.zips,
    provinciasToSend: state.provinciasToSend,
    marketerData: marketerData,
    zoneWholeZipsToSend: zoneWholeZipsToSend,
    zoneWholeZipCheck: zoneWholeZipCheck,
    zoneLoad: zoneLoad,
    zoneUpdateZip: zoneUpdateZip,
    zoneProvince: zoneProvince,
    zipsToSend: zipsToSend,
    provinceToSend: provinceToSend,
  }
}
