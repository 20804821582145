import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { usePublicOfferContext, PublicOfferEditForm } from '../../../../features/public-offer'
import { PUBLIC_OFFER_COMBOS_IDS } from '../../constants'
import { useCombos } from '../../../../features/combos/hooks'
import { PageDisplayer } from '../../../../ui'

export const PublicOfferDetailPage = () => {
  const history = useHistory()
  const { publicOfferId } = useParams()
  useCombos(PUBLIC_OFFER_COMBOS_IDS)
  const { formatMessage } = useIntl()
  const { detailLoading, detailError, getPublicOffer, publicOffer } = usePublicOfferContext()

  useEffect(() => {
    getPublicOffer(publicOfferId)
  }, [publicOfferId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/public-offer' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      loading={detailLoading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.campaigns.detail.goBack' })}
    >
      {detailError ? (
        formatMessage({ id: 'pages.campaigns.detail.error' }, { publicOfferId })
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h4" color="textSecondary">
              {publicOffer['nombre'] || '{Nombre de la campaña}'}
            </Typography>
          </Box>
          <PublicOfferEditForm />
        </>
      )}
    </PageDisplayer>
  )
}
