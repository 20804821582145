import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useIPCalls = () => {
  const { get, getById, putById, put } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getIIPPCall = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/inspecciones/tabla_gestores', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getIPCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        getById('/inspecciones', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putIPCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/inspecciones', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putIPStateCall = useCallback(
    (id, state) =>
      new Promise((resolve, reject) => {
        put(`/inspecciones/cambiar_estado_gestor/${id}/${state}`, null, { timeout: 30000 })
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putResolveIssueCall = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
        putById('/inspecciones/cerrar_no_conformidad', id, config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putRejectIPCall = useCallback(
    (id, data) =>
      new Promise((resolve, reject) => {
        putById('/inspecciones/rechazo_gestor', id, data)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSendToZeusCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        putById('/inspecciones/reenviar_zeus_gestor', id)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getIPEventsCall = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        const config = { params: { inspeccion_id: id } }
        get('/bitacora/ip3', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getCUPSEventsCall = useCallback(
    (cups) =>
      new Promise((resolve, reject) => {
        getById('/inspecciones/agrupacion_cups', cups)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getIIPPCall,
    getIPCall,
    putIPCall,
    putIPStateCall,
    putResolveIssueCall,
    putRejectIPCall,
    putSendToZeusCall,
    getIPEventsCall,
    getCUPSEventsCall,
  }
}
