export const IP_TABLE_COLUMNS = [
  {
    field: 'cif_empresa',
    align: 'left',
    width: 120,
    sorting: false,
  },
  {
    field: 'estado',
    align: 'left',
    width: 240,
    comboId: 'estados_inspeccion',
  },
  {
    field: 'motivo_rechazo_ip3',
    aling: 'left',
    width: 230,
  },
  {
    field: 'tipo_instalacion',
    align: 'left',
    width: 100,
  },
  {
    field: 'fecha_creacion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'fecha_inspeccion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'cupsCustom',
    align: 'left',
    width: 220,
  },
  {
    field: 'via',
    align: 'left',
    width: 400,
    render: (rowData) =>
      `${rowData.tipo_via} ${rowData.via} ${rowData.numero}, ${rowData.poblacion} (${rowData.codigopostal}), ${rowData.provincia}`,
  },
  // {
  //   field: 'nombre_gremio',
  //   align: 'left',
  //   width: 200,
  // },
]

export const IP_EXPORT_COLUMNS = [
  { field: 'escenario' },
  { field: 'tipo_documento' },
  { field: 'id' },
  { field: 'estado', exportType: 'combo', comboId: 'estados_inspeccion' },
  { field: 'motivo_rechazo_ip3' },
  { field: 'motivo_error' },
  {field: 'plantilla_validacion'},
  { field: 'fecha_estado', exportType: 'date' },
  { field: 'tipo_gas' },
  { field: 'une' },
  { field: 'tipo_instalacion' },
  { field: 'cups' },
  { field: 'cod_derivacion' },
  { field: 'provincia' },
  { field: 'poblacion' },
  { field: 'codigopostal' },
  { field: 'tipo_via' },
  { field: 'nombre_via' },
  { field: 'via' },
  { field: 'numero' },
  { field: 'anexo' },
  { field: 'bloque' },
  { field: 'escalera' },
  { field: 'piso' },
  { field: 'puerta' },
  { field: 'resultado' },
  { field: 'anomalias' },
  { field: 'cod_anomalias' },
  { field: 'situacion_suministro' },
  { field: 'fecha_inspeccion', exportType: 'date' },
  { field: 'numero_factura' },
  { field: 'importe_factura' },
  { field: 'fecha_factura', exportType: 'date' },
  { field: 'observaciones' },
  { field: 'lopd_aceptacion' },
  { field: 'fecha_creacion', exportType: 'date' },
  { field: 'nombre_empresa' },
  { field: 'cif_empresa' },
  { field: 'email_empresa' },
  { field: 'idusuario_empresa' },
  { field: 'iban_validado' },
  { field: 'iban_temporal' },
  { field: 'provincia_empresa' },
  { field: 'poblacion_empresa' },
  { field: 'cod_postal_empresa' },
  { field: 'reg_industrial_empresa' },
  { field: 'telefono_empresa' },
  {field: 'plantillas_empresa'},
  { field: 'fecha_creacion_ip', exportType: 'date' }, //Según el excel de referencia, debería ser fecha creación empresa (campo que no está incluido en la respuesta de BE)
  { field: 'identificador_inspector' },
  { field: 'nombre_instalador' },
  { field: 'apellidos_instalador' },
  { field: 'categoria_inspector' },
]

export const DEFAULT_QUERY = { _pagina: 1, _num_x_pagina: 10 }
