export const cardRadioButtonStyles = (theme) => ({
  card: {
    margin: '0.5em',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '1em',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#ffb400',
    },
  },
  cardChosen: {
    backgroundColor: '#ffb400',
    color: '#fff',
    fontWeight: 'bold',
  },
  cardMarker: {
    '&:first-child': {
      display: 'block',
      width: '1px',
      padding: '0',
      '& svg': {
        display: 'none',
      },
    },
  },
})
