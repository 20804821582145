//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const COLLABORATORS_TABLE_COLUMNS = [
  {
    field: 'email',
    align: 'left',
    width: 330,
  },
  {
    field: 'empresa_gremio_asociacion',
    align: 'left',
    width: 200,
  },
  {
    field: 'telefono',
    align: 'left',
    width: 150,
    sorting: false,
  },
  {
    field: 'tipo_perfil',
    align: 'left',
    width: 150,
    exportType: 'combo',
    comboId: 'roles_instalador',
  },
  {
    field: 'activo',
    align: 'left',
    width: 80,
  },
  {
    field: 'zona_actuacion',
    align: 'left',
    width: 330,
    sorting: false,
  },
]

export const COLLABORATORS_TABLE_ACTIONS = [
  // {
  //   field: 'active',
  //   width: 100,
  //   align: 'center',
  //   sorting: false,
  //   disableClick: true,
  // },
]
