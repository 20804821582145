import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { SRTable, SRFilter } from '../../../../features/sr'
import { useCombos } from '../../../../features/combos/hooks'
import { SR_COMBOS_IDS } from '../../constants'
import { srDashboardStyles } from './sr-dashboard.styles'

export const SRDashboardPage = withStyles(srDashboardStyles)(({ classes }) => {
  useCombos(SR_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth="false">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.sr.dashboard.title' })}
        </Typography>
      </Box>
      <Box mb={2} display="flex">
        <SRFilter />
      </Box>
      <Box>
        <SRTable />
      </Box>
    </Container>
  )
})
