export const TableActionsStyles = (theme) => ({
  actionButton: {
    borderRadius: '100%',
    border: '1px solid #333',
    height: '1.7em',
    width: '1.7em',
  },
  actionButtonDisabled: {
    borderRadius: '100%',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    height: '1.7em',
    width: '1.7em',
  },
  actionRow: {
    padding: '0 1em',
    display: 'flex',
    justifyContent: 'space-between',
  },
})