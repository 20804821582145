import { Box, Typography, withStyles } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import Check from '@material-ui/icons/Check'
import WarningOutlined from '@material-ui/icons/WarningOutlined'
import React from 'react'
import { CLIENT_PATH_DATES } from '../panels/client-panel/client-panel.constants'
import { clientPathStyles } from './client-path.styles'

const CLIENT_PATH_PREFIX = 'pages.application.detail.client.path'

export const ClientPath = withStyles(clientPathStyles)(({ classes, data, intl }) => {
  return (
    <Box>
      <Typography variant="subtitle2" className={classes.title}>
        Seguimiento del cliente:
      </Typography>
      {CLIENT_PATH_DATES.map((el) => {
        const date = el.field(data)
        const title = (
          <Typography variant="subtitle2" className={classes.label}>
            {intl.formatMessage({ id: `${CLIENT_PATH_PREFIX}.${el.key}.title` })}:{' '}
          </Typography>
        )
        return (
          <Box key={el.key} display="flex" flexDirection="horizontal">
            {el.alert(data) ? (
              <>
                <WarningOutlined className={[classes.icon, classes.error].join(' ')} />
                {title}
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({ id: `${CLIENT_PATH_PREFIX}.${el.key}.warning` })}
                </Typography>
              </>
            ) : date ? (
              <>
                <Check className={[classes.icon, classes.success].join(' ')} />
                {title}
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage(
                    { id: `${CLIENT_PATH_PREFIX}.${el.key}.full` },
                    { date: intl.formatDate(date) }
                  )}
                </Typography>
              </>
            ) : (
              <>
                <AccessTime className={[classes.icon, classes.warning].join(' ')} />
                {title}
                <Typography variant="body2" color="textSecondary">
                  {intl.formatMessage({ id: `${CLIENT_PATH_PREFIX}.${el.key}.empty` })}
                </Typography>
              </>
            )}
          </Box>
        )
      })}
    </Box>
  )
})

export default ClientPath
