import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FoldingPanel } from '../../../../../ui'
import { useAppContext } from '../../../contexts'
import { TableSameCidi } from '../../table-same-cidi'

export const RelatedAppsPanel = () => {
  const { formatMessage } = useIntl()
  const { application } = useAppContext()

  return (
    <Box>
      <FoldingPanel
        isExpanded={false}
        noPadding="true"
        title={formatMessage({ id: 'pages.application.view.detail.sameCidi.title' })}
      >
        <TableSameCidi cidi={application.cidi} id={application.id} />
      </FoldingPanel>
    </Box>
  )
}
