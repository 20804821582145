import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ThemeButton, BasicTable } from '../../../../ui'
import { downloadFile } from '../../../../core/utils'
import { DOCUMENTS_LIST_CONFIG, DOCUMENT_KEYS } from './constants'

export const DocumentsView = ({ close, company }) => {
  const { formatMessage } = useIntl()
  const { getById } = useAjaxContext()

  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([])

  useEffect(() => {
    getListItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getListItems = () => {
    let docs = []

    for (let key in company) {
      if (DOCUMENT_KEYS.some((element) => element === key && company[key]))
        docs.push({
          ...company[key],
          tipo: formatMessage({
            id: `pages.eeii.detail.documentViewer.documents.${company[key]['tipo']}`,
          }),
        })
    }
    setDocuments(docs)
  }

  const handleDownloadFile = ({ id }) => {
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box p={4}>
      {documents.length === 0 ? (
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.detail.documentViewer.notFound' })}
          </Typography>
        </Box>
      ) : (
        <Box mb={4}>
          <Typography variant="h6" color="primary" paragraph>
            {formatMessage({ id: 'pages.application.detail.documentViewer.documentsTitle' })}
          </Typography>
          <BasicTable
            config={DOCUMENTS_LIST_CONFIG}
            data={documents}
            onClick={handleDownloadFile}
            loading={loading}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={close} color="primary" labelM="lg" loading={loading}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
