export const REJECTED_BUDGETS_LIST_CONFIG = [
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const CURRENT_DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const FILE_FIELD_NAMES = [
  'IRG1',
  'IRG2',
  'IRG3',
  'AI',
  'AR',
  'CA',
  'CB',
  'CPA',
  'CC',
  'CCEE',
  'CH',
  'CP',
  'CR',
  'PRA',
  'DN',
  'RC',
  'SG',
  'CI',
  'EF',
  'FI',
  'SP',
  'EC',
  'AC',
  'CPI',
  'FC1',
  'FC2',
  'FC3',
  'tallo_i',
  'croquis_i',
  'd_AI',
  'd_ACIF',
  'd_EP',
  'd_CC',
  'd_CP',
  'd_CR',
  'd_DN',
  'd_CH',
  'd_SG',
  'd_AR',
  'd_EF',
  'd_FC1',
  'd_FC1',
  'd_PRA',
  'd_FC1',
  'd_CM',
  'd_CA',
  'd_CI',
  'd_CCEE',
  'd_CB',
  'd_RC',
  'd_SP',
  'd_EC',
  'd_AC',
  'd_CPI',
  'd_tallo_i',
  'd_croquis_i',
]
