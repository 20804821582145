import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Typography, Box } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const ResendBudgetConfirmDialog = ({ sendBudget, application, close }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    const payload = {
      // nombre: application['usuario_sir']['nombre_cliente'],
      solicitud_id: application['id'],
      // presupuesto_id: application['presupuesto_sir']['id'],
      // mail_cliente: application['usuario_sir']['email_cliente'],
    }
    setLoading(true)
    sendBudget(payload)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box p={4}>
      <Box mb={2} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.sir.dialogs.resendBudget.title' })}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant="body1" align="center">
          {formatMessage({ id: 'pages.sir.dialogs.resendBudget.message' })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          color="primary"
          onClick={handleConfirm}
          labelM="lg"
          disabled={loading}
          loading={loading}
        >
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
