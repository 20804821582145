import React from 'react'
import { Grid } from '@material-ui/core'

import { TextInput, SelectInput, DateInput } from '../../../../../ui'
import { HAS_PAID_STATES } from './info-panel.constants'
import { useAppContext } from '../../../contexts'
import { GRID_ITEM } from './info-panel.constants'
import { ViewDocumentsBtn } from '../../view-documents'

export const InfoBudgetInt = ({ fields }) => {
  const { application, disabledForUser } = useAppContext()
  const defaultDisabled = disabledForUser()

  return (
    <Grid container spacing={3}>
      <Grid {...GRID_ITEM.SMALL}>
        <SelectInput {...{ ...fields.estado }} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput {...fields.solicitud_id} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput {...fields.numero_tarifa_aplicada} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput {...{ ...fields.tipo_solicitud }} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <DateInput {...fields.fecha_solicitud} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <DateInput {...fields.fecha_puesta_servicio} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput {...fields.codigo_financiacion} />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput
          {...{
            ...fields.num_encargo_goda,
            readOnly: defaultDisabled || HAS_PAID_STATES[application.estado],
          }}
        />
      </Grid>
      <Grid {...GRID_ITEM.SMALL}>
        <TextInput
          {...{
            ...fields.num_gor_icaro,
            readOnly: defaultDisabled || HAS_PAID_STATES[application.estado],
          }}
        />
      </Grid>
      <Grid {...GRID_ITEM.FULL}>
        <ViewDocumentsBtn />
      </Grid>
    </Grid>
  )
}
