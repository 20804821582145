import { TextInput, DateInput, SelectInput, ThemeButton } from '../../../../ui';
import { FileUploaderInput } from '../../../../ui';
import { PO_REQUEST_STATES } from '../../constants';
import { EditSapCodeButton } from '../buttons/edit-sap-code-button.component';

const GRID_ITEM_LARGE = {
  item: true,
  sm: 12,
  md: 6,
}

const GRID_ITEM_MID = {
  item: true,
  xs: 12,
  sm: 6,
  md: 3,
}

export const INPUTS_CONFIG = ({ data, combos, intl, onChange }) => {
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: 'pages.poRequests.detail.fields.' + extension + '.title' });
  const DEFAULT_PROPS = { onChange, readOnly: true, required: false };
  return {
    NOMBRE_OP: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['campana_nombre'],
      title: txt('nombre_op'),
      name: 'campana_nombre',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'campana_nombre'
      }),
    },
    ESTADO: {
      ...DEFAULT_PROPS,
      component: SelectInput,
      values: combos.estados_peticion_op?.data || [],
      value: data['estado'],
      title: txt('estado'),
      name: 'estado',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'estado'
      }),
    },
    FECHA_CREACION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['dg_ts_insert'],
      title: txt('fecha_creacion'),
      name: 'dg_ts_insert',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'dg_ts_insert'
      }),
    },
    FECHA_ADHESION: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['fecha_adhesion'],
      title: txt('fecha_adhesion'),
      name: 'fecha_adhesion',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'fecha_adhesion'
      }),
    },
    COD_REGISTRO_ZEUS: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['codigo_registro_zeus'],
      title: txt('codigo_registro_zeus'),
      name: 'codigo_registro_zeus',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'codigo_registro_zeus'
      }),
    },
    COD_ADHESION_ZEUS: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['codigo_adhesion_zeus'],
      title: txt('codigo_adhesion_zeus'),
      name: 'codigo_adhesion_zeus',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'codigo_adhesion_zeus'
      }),
    },
    OBSERVACIONES_RECHAZO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['observaciones_rechazo'],
      title: txt('observaciones_rechazo'),
      name: 'observaciones_rechazo',
      multiline: true,
      rows: 3,
      hidden: data.estado !== PO_REQUEST_STATES.REJECTED,
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'observaciones_rechazo'
      }),
    },
    // OBSERVACIONES_ANULACION: {
    //   ...DEFAULT_PROPS,
    //   component: TextInput,
    //   value: data['observaciones_anulacion'],
    //   title: txt('observaciones_anulacion'),
    //   name: 'observaciones_anulacion',
    //   multiline: true,
    //   rows: 3,
    //   hidden: data.estado !== PO_REQUEST_STATES.CANCELLED,
    // },
    OBSERVACIONES_INSTALADOR: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['observaciones_instalador'],
      title: txt('observaciones_instalador'),
      name: 'observaciones_instalador',
      multiline: true,
      rows: 3,
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'observaciones_instalador'
      }),
    },
    EMPRESA_CA_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_ca_nombre'],
      title: txt('empresa_ca_nombre'),
      name: 'empresa_ca_nombre',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_ca_nombre'
      }),
    },
    EMPRESA_CA_RESPONSABLE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_ca_responsable_legal'],
      title: txt('empresa_ca_responsable'),
      name: 'empresa_ca_responsable_legal',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_ca_responsable_legal'
      }),
    },
    EMPRESA_CA_NIF_RESPONSABLE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_ca_nif_responsable_legal'],
      title: txt('empresa_ca_nif_responsable_legal'),
      name: 'empresa_ca_nif_responsable_legal',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_ca_nif_responsable_legal'
      }),
    },
    // EMPRESA_CA_TELEFONO: {
    //   ...DEFAULT_PROPS,
    //   component: TextInput,
    //   value: data['empresa_ca_telefono'],
    //   title: txt('empresa_ca_telefono'),
    //   name: 'empresa_ca_telefono',
    // },
    // EMPRESA_CA_EMAIL: {
    //   ...DEFAULT_PROPS,
    //   component: TextInput,
    //   value: data['empresa_ca_email'],
    //   title: txt('empresa_ca_email'),
    //   name: 'empresa_ca_email',
    // },
    GREMIO_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['gremio_nombre'],
      title: txt('gremio_nombre'),
      name: 'gremio_nombre',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'gremio_nombre'
      }),
    },
    GREMIO_NIF: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['gremio_nif'],
      title: txt('gremio_nif'),
      name: 'gremio_nif',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'gremio_nif'
      }),
    },
    EMPRESA_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_nombre'],
      title: txt('empresa_nombre'),
      name: 'empresa_nombre',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_nombre'
      }),
    },
    EMPRESA_NIF: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_nif'],
      title: txt('empresa_nif'),
      name: 'empresa_nif',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_nif'
      }),
    },
    COD_SAP: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['cod_sap'],
      title: txt('cod_sap'),
      name: 'cod_sap',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'cod_sap'
      }),
    },
    COD_REGISTRO_INDUSTRIAL: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_ca_codigo_registro_industrial'],
      title: txt('empresa_ca_codigo_registro_industrial'),
      name: 'empresa_ca_codigo_registro_industrial',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_ca_codigo_registro_industrial'
      }),
    },
    EMPRESA_RESPONSABLE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_responsable_legal'],
      title: txt('empresa_responsable_legal'),
      name: 'empresa_responsable_legal',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_responsable_legal'
      }),
    },
    EMPRESA_NIF_RESPONSABLE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_nif_responsable_legal'],
      title: txt('empresa_nif_responsable_legal'),
      name: 'empresa_nif_responsable_legal',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_nif_responsable_legal'
      }),
    },
    EMPRESA_SOCIEDAD: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['empresa_ca_sociedad'],
      title: txt('empresa_ca_sociedad'),
      name: 'empresa_ca_sociedad',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'empresa_ca_sociedad'
      }),
    },
    EMPRESA_IBAN: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['iban_op'],
      title: txt('iban_op'),
      name: 'iban_op',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'iban_op'
      }),
    },
    D_REGISTRO_SAP: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['cod_sap'],
      title: txt('d_registro_sap'),
      name: 'd_registro_sap',
      currentDoc: data.d_registro_sap?.id ? data['d_registro_sap'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'd_registro_sap'
      }),
    },
    CAMBIAR_CODIGO_SAP: {
      component: EditSapCodeButton,
      data: data,
      onChange: onChange,
    },
    D_REGISTRO_ZEUS: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('d_registro_zeus'),
      name: 'd_registro_zeus',
      currentDoc: data.d_registro_zeus?.id ? data['d_registro_zeus'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'd_registro_zeus'
      }),
    },
    D_ADHESION_ZEUS: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('d_adhesion_zeus'),
      name: 'd_adhesion_zeus',
      currentDoc: data.d_adhesion_zeus?.id ? data['d_adhesion_zeus'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'd_adhesion_zeus'
      }),
    },
    D_NIF_EMPRESA: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('documento_nif'),
      name: 'documento_nif',
      currentDoc: data.documento_nif?.id ? data['documento_nif'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'documento_nif'
      }),
    },
    D_PERSONA_FISICA: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('documento_pf'),
      name: 'documento_pf',
      currentDoc: data.documento_pf?.id ? data['documento_pf'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'documento_pf'
      }),
    },
    D_REGISTRO_INDUSTRIAL: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('registro_industrial'),
      name: 'registro_industrial',
      currentDoc: data.registro_industrial?.id ? data['registro_industrial'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'registro_industrial'
      }),
    },
    D_FORMULARIO_RGPD: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('formulario_rgpd'),
      name: 'formulario_rgpd',
      currentDoc: data.formulario_rgpd?.id ? data['formulario_rgpd'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'formulario_rgpd'
      }),
    },
    D_CERTIFICADO_IBAN_OP: {
      ...DEFAULT_PROPS,
      component: FileUploaderInput,
      title: txt('certificado_iban_op'),
      name: 'certificado_iban_op',
      currentDoc: data.certificado_iban_op?.id ? data['certificado_iban_op'] : null,
      fileProps: {
        maxSize: 10000000, // 10Mb
        accept: ['application/pdf'],
      },
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'certificado_iban_op'
      }),
    },
    CAMPANA_NOMBRE: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['campana_nombre'],
      title: txt('campana_nombre'),
      name: 'campana_nombre',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'campana_nombre'
      }),
    },
    CAMPANA_CODIGO: {
      ...DEFAULT_PROPS,
      component: TextInput,
      value: data['campana_codigo'],
      title: txt('campana_codigo'),
      name: 'campana_codigo',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'campana_codigo'
      }),
    },
    CAMPANA_FECHA_INICIO: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['campana_fecha_inicio'],
      title: txt('campana_fecha_inicio'),
      name: 'campana_fecha_inicio',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'campana_fecha_inicio'
      }),
    },
    CAMPANA_FECHA_FIN: {
      ...DEFAULT_PROPS,
      component: DateInput,
      value: data['campana_fecha_fin'],
      title: txt('campana_fecha_fin'),
      name: 'campana_fecha_fin',
      hasChanges: data.cambios_pendientes?.some((cambio) => {
        return cambio.campo === 'campana_fecha_fin'
      }),
    },
  }
}

export const FORM_CONFIG = ({ intl }) => [
  {
    fields: [
      { ref: 'NOMBRE_OP', conf: GRID_ITEM_MID },
      { ref: 'ESTADO', conf: GRID_ITEM_MID },
      { ref: 'FECHA_CREACION', conf: GRID_ITEM_MID },
      { ref: 'FECHA_ADHESION', conf: GRID_ITEM_MID },
      { ref: 'COD_REGISTRO_ZEUS', conf: GRID_ITEM_MID },
      { ref: 'COD_ADHESION_ZEUS', conf: GRID_ITEM_MID },
      { ref: 'OBSERVACIONES_INSTALADOR', conf: GRID_ITEM_LARGE },
      { ref: 'OBSERVACIONES_RECHAZO', conf: GRID_ITEM_LARGE },
    ],
  },
  {
    title: 'Documentos petición OP',
    fields: [
      { ref: 'D_REGISTRO_SAP', conf: GRID_ITEM_LARGE },
      { ref: 'CAMBIAR_CODIGO_SAP'},
      { ref: 'D_REGISTRO_ZEUS', conf: GRID_ITEM_LARGE },
      { ref: 'D_ADHESION_ZEUS', conf: GRID_ITEM_LARGE },
    ],
    panel: true,
  },
  {
    title: 'Información de campaña',
    fields: [
      { ref: 'CAMPANA_NOMBRE', conf: GRID_ITEM_MID },
      { ref: 'CAMPANA_CODIGO', conf: GRID_ITEM_MID },
      { ref: 'CAMPANA_FECHA_INICIO', conf: GRID_ITEM_MID },
      { ref: 'CAMPANA_FECHA_FIN', conf: GRID_ITEM_MID },
    ],
    panel: true,
  },
  {
    title: 'Información de empresa',
    fields: [
      { ref: 'EMPRESA_CA_NOMBRE', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_CA_RESPONSABLE', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_CA_NIF_RESPONSABLE', conf: GRID_ITEM_MID },
      // { ref: 'EMPRESA_CA_TELEFONO', conf: GRID_ITEM_MID },
      // { ref: 'EMPRESA_CA_EMAIL', conf: GRID_ITEM_MID },
      { ref: 'GREMIO_NOMBRE', conf: GRID_ITEM_MID },
      { ref: 'GREMIO_NIF', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_SOCIEDAD', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_IBAN', conf: GRID_ITEM_LARGE },
    ],
    panel: true,
  },
  {
    title: 'Información de empresa principal',
    fields: [
      { ref: 'EMPRESA_NOMBRE', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_NIF', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_RESPONSABLE', conf: GRID_ITEM_MID },
      { ref: 'EMPRESA_NIF_RESPONSABLE', conf: GRID_ITEM_MID },
      { ref: 'COD_SAP', conf: GRID_ITEM_MID },
      { ref: 'COD_REGISTRO_INDUSTRIAL', conf: GRID_ITEM_LARGE },
    ],
    panel: true,
  },
  {
    title: 'Documentos de empresa',
    fields: [
      { ref: 'D_NIF_EMPRESA', conf: GRID_ITEM_LARGE },
      { ref: 'D_PERSONA_FISICA', conf: GRID_ITEM_LARGE },
      { ref: 'D_REGISTRO_INDUSTRIAL', conf: GRID_ITEM_LARGE },
      { ref: 'D_FORMULARIO_RGPD', conf: GRID_ITEM_LARGE },
      { ref: 'D_CERTIFICADO_IBAN_OP', conf: GRID_ITEM_LARGE },
    ],
    panel: true,
  },
]
