export const DOCUMENT_ATTACHMENT_CONFIG = [
  {
    id: 'd_CI_id',
    model: 'd_CI',
    field: 'd_CI',
    type: 'CI',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
  {
    id: 'd_FI_id',
    model: 'd_FI',
    field: 'd_FI',
    type: 'FI',
    accept: ['image/jpg', 'image/jpeg', 'image/png'],
    maxSize: 10000000,
  },
  {
    id: 'd_justificante_pago_sir_id',
    model: 'd_justificante_pago_sir',
    field: 'd_justificante_pago_sir',
    type: 'comprobante_pago',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },

  {
    id: 'd_FC1_id',
    model: 'd_FC1',
    field: 'd_FC1',
    type: 'FC1',
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
    maxSize: 10000000,
  },
]
