import React, { createContext, useContext } from 'react'

import { useJCAReducer } from '../hooks/jca-reducer.hook'

const JCAContext = createContext()

export const JCAContextProvider = ({ children }) => {
  const reducer = useJCAReducer()
  return <JCAContext.Provider value={reducer}>{children}</JCAContext.Provider>
}

export const useJCAContext = () => useContext(JCAContext)
