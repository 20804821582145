const { REACT_APP_WP_URL } = process.env

export const LINK_CREATE_APP = (token) => {
  return `${REACT_APP_WP_URL}?root=1&id=${token}`
}

export const APPS_FILTERS = [
  {
    name: 'creada_desde',
    filterType: 'date',
    maxDateField: 'creada_desde',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    minDateField: 'creada_hasta',
  },
  {
    name: 'solicitud_estado',
    label: 'Estado sol.',
    comboId: 'estados_solicitud',
    size: 'small',
    multiple: true,
  },

  // {
  //   name: 'presupuesto_estado',
  //   label: 'Estado pto.',
  //   comboId: 'estados_presupuesto',
  //   size: 'small',
  //   multiple: true,
  // },
  // {
  //   name: 'cod_zona_zeus',
  //   label: 'Zona',
  //   comboId: 'cod_zona_zeus',
  //   size: 'small',
  // },
  // {
  //   name: 'presupuestos_tipo',
  //   label: 'Tipo pres.',
  //   comboId: 'tipo_presupuesto',
  //   size: 'small',
  // },
]
export const APPS_FILTERS_SELECTRA = [

  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud',
    size: 'small',
    multiple: true,
  },
  {
    name: 'tipo_solicitud',
    comboId: 'origen_solicitud',
    size: 'small',
    multiple: true,
  },
]

export const APPS_FILTERS_GSS = [
  {
    name: 'creada_desde',
    filterType: 'date',
    maxDateField: 'creada_desde',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    minDateField: 'creada_hasta',
  },
  {
    name: 'solicitud_estado',
    comboId: 'estados_solicitud',
    size: 'small',
    multiple: true,
  },
]

// export const APP_HEADER_COMBOS = ['estados_solicitud', 'estados_presupuesto']
export const APP_HEADER_COMBOS = ['estados_solicitud']
export const APP_HEADER_COMBOS_SELECTRA = ['estados_solicitud', 'origen_solicitud']
export const APP_HEADER_COMBOS_GSS = ['estados_solicitud']
