import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { SirFilter, SirTable } from '../../../../features/sir'
import { useCombos } from '../../../../features/combos/hooks'
import { SIR_DB_COMBOS_IDS, SIR_DB_ORDER_COMBOS_IDS } from './sir-dashboard.constants'
import { sirDashboardStyles } from './sir-dashboard.styles'

export const SirDashboardPage = withStyles(sirDashboardStyles)(({ classes }) => {
  useCombos(SIR_DB_COMBOS_IDS, SIR_DB_ORDER_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.sir.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <SirFilter />
      </Box>
      <Box>
        <SirTable />
      </Box>
    </Container>
  )
})
