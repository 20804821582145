import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'

import { ThemeButton } from '../../../../../ui'

export const AppStateChangerDialog = ({
  close,
  getAvailableStatesToChange,
  changeToAvailableState,
}) => {
  const { formatMessage: fm } = useIntl()

  const [availableStates, setAvailableStates] = useState([])
  const [selectedState, setSelectedState] = useState('')
  const [loadingData, setLoadingData] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleChangeSelection = (e) => {
    setSelectedState(e.target.value)
  }

  const handleChangeState = () => {
    setLoading(true)
    changeToAvailableState(selectedState)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getAvailableStatesToChange()
      .then((data) => {
        setAvailableStates(data)
        setLoadingData(false)
      })
      .catch(() => {
        setLoadingData(false)
      })
  }, [getAvailableStatesToChange])

  if (loadingData) {
    return (
      <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }
  if (!availableStates.length) {
    return (
      <Box p={4}>
        <Box mb={4} textAlign="center">
          <Typography variant="h6" color="primary">
            {fm({ id: 'pages.application.detail.info.stateChanger.dialog.noData' })}
          </Typography>
        </Box>
        <Box textAlign="center">
          <ThemeButton onClick={close}>{fm({ id: 'global.close' })}</ThemeButton>
        </Box>
      </Box>
    )
  }
  return (
    <Box p={4}>
      <Box mb={1} textAlign="center">
        <Typography variant="h5" color="primary">
          {fm({ id: 'pages.application.detail.info.stateChanger.dialog.title' })}
        </Typography>
      </Box>
      <Box p={2} mb={1}>
        <RadioGroup name="selectedState" value={selectedState} onChange={handleChangeSelection} row>
          {availableStates.map(({ key, value, peligro = '' }) => {
            return (
              <FormControlLabel
                style={{ display: 'flex', alignItems: 'flex-start' }}
                key={key}
                disabled={loading}
                value={key}
                name="selectedState"
                control={<Radio size="small" color="primary" />}
                label={
                  <>
                    <Typography variant="subtitle1" style={{ paddingTop: '0.6em' }}>
                      {value}
                    </Typography>
                    {peligro ? (
                      <>
                        <Typography display="inline" variant="body2">
                          {'Atención: '}
                        </Typography>
                        <Typography display="inline" variant="body2" color="textSecondary">
                          {peligro}
                        </Typography>
                      </>
                    ) : null}
                  </>
                }
              />
            )
          })}
        </RadioGroup>
      </Box>
      <Box display="flex" justifyContent="space-around" pl={5} pr={5}>
        <ThemeButton onClick={close} variant="outlined" disabled={loading}>
          {fm({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton
          onClick={handleChangeState}
          color="primary"
          loading={loading}
          disabled={loading || !selectedState}
        >
          {fm({ id: 'global.save' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
