import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { ExportToXLSBtn, FilterBox } from '../../../../ui'
import { EEII_TABLE_COLUMNS, EEII_TABLE_FILTERS } from '../../constants/eeii-table.constants'
import { eeiiDataPreparation } from '../../logics'
import { useEEIICalls } from '../../hooks'

export const EEIIHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getEEII } = useEEIICalls()
  const role = useSelector((state) => state.global.role)
  const eeiiLoader = useSelector((state) => state.loader.eeii)

  const selection = eeiiLoader?.selection
  const search = eeiiLoader?.config?.params

  const filters = EEII_TABLE_FILTERS

  const buttons = (
    <ExportToXLSBtn
      selectedRows={selection}
      columnsExport={EEII_TABLE_COLUMNS}
      getAllData={() => {
        return new Promise((resolve, reject) => {
          getEEII({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(eeiiDataPreparation(data.empresas, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }}
      titleRoot="eeii_"
      headerNamespace="eeii"
    />
  )

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.eeii.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>{role === 'e_commerce' ? buttons : null}</FilterBox>
    </>
  )
}
