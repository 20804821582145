import React, { useMemo } from 'react'
import NumberFormat from 'react-number-format'
import { withStyles, TextField, FormControl, Tooltip, Icon, Typography } from '@material-ui/core'
import { InputAdornment } from '@material-ui/core'

import { getEndAdornment } from './number-input.utils'
import { numberInputStyles } from './number-input.styles'

function NumberFormatCustom(props) {
  const { inputRef, onChange, maxValue = 0, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values
        return formattedValue === '' || !maxValue || floatValue <= maxValue
      }}
    />
  )
}

export const NumberInput = withStyles(numberInputStyles)(
  ({
    classes,
    readOnly = false,
    value = '',
    decimalScale = undefined,
    fixedDecimalScale = false,
    maxValue = 0,
    units = null,
    ...props
  }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const InputProps = useMemo(() => {
      return {
        className: classes.input,
        ...props.InputProps,
        inputComponent: (inputComponentProps) => (
          <NumberFormatCustom {...{ decimalScale, maxValue, ...inputComponentProps }} />
        ),
        endAdornment: units ? (
          <InputAdornment position="end">{getEndAdornment(units)}</InputAdornment>
        ) : null,
      }
    }, [classes.input, props.InputProps, decimalScale, maxValue, units])

    const tooltip = useMemo(() => {
      return props.tooltip ? (
        <span style={{ fontSize: 16, marginLeft: 4 }}>
          <Tooltip
            placement="top"
            title={
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {props.tooltip}
              </Typography>
            }
          >
            <Icon fontSize="inherit">help_outline</Icon>
          </Tooltip>
        </span>
      ) : null
    }, [props.tooltip])

    return (
      <FormControl className={classes.root}>
        {props.title ? (
          <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
            {props.title + (tooltip ? tooltip : '')}
          </Typography>
        ) : null}
        {readOnly ? (
          <Typography
            variant={`body${typographyVariant}`}
            color="textSecondary"
            style={{ whiteSpace: 'pre-line' }}
          >
            {value || value === 0
              ? value.toString().replace(/\./g, ',') + (units ? ' ' + units : '')
              : '--'}
          </Typography>
        ) : (
          <TextField
            {...props}
            variant="outlined"
            value={value}
            onChange={props.onChange}
            name={props.name}
            InputProps={InputProps}
          />
        )}
      </FormControl>
    )
  }
)
