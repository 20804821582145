import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'
import { useAjaxContext } from '../../../../core/ajax/contexts'

export const ValidateAppDialog = ({ close, appId, fetchApplication }) => {
  const { formatMessage } = useIntl()
  const { put } = useAjaxContext()

  const [loading, setLoading] = useState(false)

  const handleConfirm = () => {
    setLoading(true)
    put(`/solicitudes/validar/${appId}/desmarcar_no_captable`)
      .then(({ data }) => {
        fetchApplication(appId)
        setLoading(false)
        close()
      })
      .catch((err) => {
        console.error('Error with application validation')
        setLoading(false)
      })
  }

  return (
    <Box p={4}>
      <Box mb={2} textAlign="center">
        <Typography variant="h6" color="primary">
          {formatMessage({ id: 'pages.application.view.detail.validate.appDialog.title' })}
        </Typography>
      </Box>
      <Box textAlign="center" mb={4}>
        <Typography variant="body1">
          {formatMessage({ id: 'pages.application.view.detail.validate.appDialog.description' })}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-around">
        <ThemeButton onClick={close} variant="outlined" labelM="lg" disabled={loading}>
          {formatMessage({ id: 'global.cancel' })}
        </ThemeButton>
        <ThemeButton color="primary" onClick={handleConfirm} labelM="lg" loading={loading}>
          {formatMessage({ id: 'global.accept' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
