import React, { useState } from 'react'
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { useNotificationsCalls } from "../../hooks";
import { PopupDialogComponent } from "../../dialogs";

export const NoticesPopupComponent = ({avisos,setAvisos}) => {
  const {markAsRead} = useNotificationsCalls()
  const intl = useIntl();
  const [notReadAvisos, setNotReadAvisos] = useState([])
  const [avisosPosition, setAvisosPosition] = useState(0)
  const [selectedAviso, setSelectedAviso] = useState({})
  const [open, setOpen] = useState(false);
  const [readedCount, setReadedCount] = useState(0);



  useEffect(() => {
    getNotReadNotifications()
  },[avisos])

  useEffect(() => {
    selectAviso()
  },[avisosPosition, notReadAvisos])

  const getNotReadNotifications = () => {
    if (!avisos) setNotReadAvisos([])
    else {
      let notRead = avisos.filter((notification) => !notification.ignore)

      if(notRead.length === 0) {
        closeDialog()
      }

      if(avisosPosition > notRead.length - 1) {
        setAvisosPosition(avisosPosition - 1)
      }
      setNotReadAvisos(notRead)
    }
  }
  function closeDialog() {
    setOpen(false);
  }

  function nextAviso() {
    if (avisosPosition < notReadAvisos.length - 1) {
      setAvisosPosition(avisosPosition + 1)
    }
  }

  function previousAviso() {
    if (avisosPosition > 0) {
      setAvisosPosition(avisosPosition - 1)
    }
  }

  function readAviso() {
    markAsRead(notReadAvisos[avisosPosition].id).then(
      () => {
        let newAvisos = [...notReadAvisos]
        newAvisos[avisosPosition].ignore = true
        setNotReadAvisos(notReadAvisos)

        let newAvisosAll = [...avisos]
        for(let i=0; i<newAvisosAll.length; i++){
            if(newAvisosAll[i].id === notReadAvisos[avisosPosition].id){
                newAvisosAll[i].ignore = true
            }
        }
        setAvisos(newAvisosAll)

      }
    )
  }

  function selectAviso() {
    if (notReadAvisos.length === 0) return
    if (!notReadAvisos[avisosPosition].ignore) notReadAvisos[avisosPosition].ignore = false
    let aviso = {"id": notReadAvisos[avisosPosition].id, "title": notReadAvisos[avisosPosition].title, "text": notReadAvisos[avisosPosition].body, "links": notReadAvisos[avisosPosition].links,quantity: notReadAvisos.length, position: avisosPosition+1, "ignore":notReadAvisos[avisosPosition].ignore}
    setSelectedAviso(aviso)
    setOpen(true)
  }

  if (selectedAviso.title){
    return (
        <PopupDialogComponent open={open} onClose={closeDialog} onPrev={previousAviso} onNext={nextAviso} onRead={readAviso} {...selectedAviso}/>
    )
  }

  else return null
}