import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { MANAGERS_FILTERS } from '../../constants'
import { NewManagerBtn } from '../new-manager.btn/new-manager.btn'

export const ManagersHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)

  const filters = MANAGERS_FILTERS

  const buttons = <NewManagerBtn />

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.managers.filters.' + item.name + '.title',
      }),
    }
  })

  return (
    <>
      <FilterBox filters={data}>{buttons}</FilterBox>
    </>
  )
}
