export const SET_SUPERVISION_DATA = 'SET_SUPERVISION_DATA'
export const SUPERVISION_LOAD = 'SUPERVISION_LOAD'
export const SUPERVISION_FAILURE = 'SUPERVISION_FAILURE'
export const UPDATE_SUPERVISED_APPLICATION = 'UPDATE_SUPERVISED_APPLICATION'
export const SELECT_SUPERVISED_APPLICATION = 'SELECT_SUPERVISED_APPLICATION'
export const UPDATE_TABLE_SELECTION = 'UPDATE_TABLE_SELECTION'
export const SUPERVISION_APP_DETAIL_LOAD = 'SUPERVISION_APP_DETAIL_LOAD'
export const SET_DETAIL_LOADING = 'SET_DETAIL_LOADING'
export const SET_DETAIL_FAILURE = 'SET_DETAIL_FAILURE'
export const SET_IS_CERTIFICATE = 'SET_IS_CERTIFICATE'
