import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { ThemeButton, useModalContext } from '../../../../ui'
import { GeneralTermsDialog } from '../dialogs'

export const AddGeneralTermsBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenGeneralTermsDialog = () => {
    open({
      Component: GeneralTermsDialog,
      data: {},
      type: 'responsive',
      scroll: 'paper',
      closable: true,
      maxWidth: 'md',
    })
  }

  return (
    <Box>
      <ThemeButton color="primary" onClick={handleOpenGeneralTermsDialog}>
        {formatMessage({ id: 'pages.public.offer.general.terms.button.label' })}
      </ThemeButton>
    </Box>
  )
}
