import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { BasicTable } from '../../../../ui'
import { JCA_EVENTS_TABLE_CONFIG } from './events-table.constants'
import { useJCAContext } from '../../contexts'

export const JCAEventsTable = () => {
  const intl = useIntl()
  const { getJCAEvents, events, jca } = useJCAContext()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (jca.id) {
      setLoading(true)
      getJCAEvents(jca.id)
        .then(() => {
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }, [jca]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading)
    return (
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  return events.length ? (
    <BasicTable config={JCA_EVENTS_TABLE_CONFIG(intl)} data={events} loading={loading} />
  ) : (
    intl.formatMessage({ id: 'pages.jca.detail.events.emptyList' })
  )
}
