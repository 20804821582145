import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Typography, Box } from '@material-ui/core'

import { CertificatesFormsContainer } from '../../../features/certificates'
import { PageDisplayer } from '../../../ui'
import { useAjaxContext } from '../../../core/ajax'

export const CertificatesPage = () => {
  const history = useHistory()
  const { appId } = useParams()
  const { getById } = useAjaxContext()
  const { formatMessage } = useIntl()

  const [application, setApplication] = useState({})
  const [loading, setLoading] = useState(true)

  const getApplication = (appId) => {
    getById('/solicitudes', `${appId}`)
      .then(({ data }) => {
        setApplication(data)

        setLoading(false)
      })
      .catch(({ response }) => {
        console.error(response && response.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    getApplication(appId)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => history.push(`/applications/${appId}`)

  return (
    <PageDisplayer
      loading={loading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'global.returnPrevious' })}
    >
      <Box bgcolor="common.white" borderRadius={4}>
        <Typography variant="h4" color="primary" paragraph>
          {formatMessage({ id: 'pages.certificates.title' })}
        </Typography>

        <CertificatesFormsContainer application={application} />
      </Box>
    </PageDisplayer>
  )
}
