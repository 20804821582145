import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { IPHistDashboardPage, JCAHistDashboardPage } from '../components'

export const IP3HistRouter = () => {
  return (
    <Switch>
      <Route exact path="/ip3-historical/ip" component={IPHistDashboardPage} />
      <Route exact path="/ip3-historical/jca" component={JCAHistDashboardPage} />
      <Redirect from="*" to="/ip3-historical/ip" />
    </Switch>
  )
}
