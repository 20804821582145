import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../ui'

export const ProfileChoiceDialog = ({ close, action, options }) => {
  const intl = useIntl()
  const [selection, setSelection] = useState(false)
  return (
    <Box p={4}>
      <DialogTitle>
        <Box mb={2} textAlign="center">
          <Typography variant="h6" color="primary">
            {intl.formatMessage({ id: 'pages.application.profileChoiceDialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box mb={2} textAlign="center">
          <SelectInput
            placeholder="Selecciona perfil"
            value={selection}
            values={options}
            onChange={(event) => {
              setSelection(event.target.value)
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {selection ? (
          <Box display="flex" justifyContent="flex-end">
            <ThemeButton
              color="primary"
              onClick={() => {
                action(selection)
                close()
              }}
              labelM="lg"
            >
              {intl.formatMessage({ id: 'pages.application.profileChoiceDialog.button.select' })}
            </ThemeButton>
          </Box>
        ) : null}
        <Box display="flex" justifyContent="flex-end">
          <ThemeButton color="default" onClick={close} labelM="lg">
            {intl.formatMessage({ id: 'global.cancel' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
