import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography, Grid, CircularProgress } from '@material-ui/core'

import { ThemeButton, UploadTooltip, SelectInput, FileUploaderNotAssociated } from '../../../../ui'
import { UPLOAD_BUTTONS_GAS_P_CONFIG } from '../../../application-detail/constants/request-gas_p.constants'
import { feedback } from '../../../../core/feedback'
import { DOCUMENT_ATTACHMENT_GAS_P_CONFIG } from '../../../documents'
import { useCombos } from '../../../combos'

export const RequestGasPNotNormalizedComponent = ({
  close,
  application,
  handleRedirectionToWordpress,
  dataUpdate,
  role,
  changeToNotNormalized,
  changeCustomerForm,
}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const combos = useCombos(['potencia_gas_p'])
  const [canSave, setCanSave] = useState(false)
  const [uploadButtonArray, setUploadButtonArray] = useState([])
  const [power, setPower] = useState(
    application.gas_p?.potencia_instalacion_gas ? application.gas_p.potencia_instalacion_gas : ''
  )

  const [documentData, setDocumentData] = useState({
    d_peticion_gasp: application.gasp_documents ? application.gasp_documents.d_peticion_gasp : null,
    d_escritura_poderes: application.gasp_documents
      ? application.gasp_documents.d_escritura_poderes
      : null,
    d_dn: application.gasp_documents ? application.gasp_documents.d_dn : null,
    d_cc: application.gasp_documents ? application.gasp_documents.d_cc : null,
    d_proyecto_gas: application.gasp_documents ? application.gasp_documents.d_proyecto_gas : null,
    d_proyecto_calefaccion: application.gasp_documents
      ? application.gasp_documents.d_proyecto_calefaccion
      : null,
  })

  const handleUploadFile = (data, buttonData) => {
    setDocumentData({ ...documentData, [buttonData]: data })
  }

  const handleChangePower = (e) => {
    setPower(e.target.value)
  }

  const handleRequestGas = () => {
    setLoading(true)
    const payload = {
      potencia_instalacion_gas: power,
      d_peticion_gasp_id: documentData['d_peticion_gasp'].id,
      d_escritura_poderes_id: documentData['d_escritura_poderes']
        ? documentData['d_escritura_poderes']?.id
        : null,
      d_dn_id: documentData['d_dn'] ? documentData['d_dn']?.id : null,
      d_cc_id: documentData['d_cc'] ? documentData['d_cc']?.id : null,
      d_proyecto_gas_id: documentData['d_proyecto_gas'] ? documentData['d_proyecto_gas']?.id : null,
      d_proyecto_calefaccion_id: documentData['d_proyecto_calefaccion']
        ? documentData['d_proyecto_calefaccion']?.id
        : null,
    }
    if (role === 'gestor_gc') {
      changeCustomerForm({ name: 'gas_p', value: payload })
      changeToNotNormalized(dataUpdate)
    } else setTimeout(() => handleRedirectionToWordpress(payload), 100)
    feedback(
      'success',
      intl.formatMessage({ id: 'pages.application.detail.actionButtons.request_gas_p.success' })
    )
    setLoading(false)
    close()
  }

  useEffect(() => {
    if (documentData['d_peticion_gasp']?.id) {
      setCanSave(true)
      return
    }
    setCanSave(false)
  }, [power, documentData])

  useEffect(() => {
    if (application && power) {
      setUploadButtonArray(
        UPLOAD_BUTTONS_GAS_P_CONFIG.map((doc) => DOCUMENT_ATTACHMENT_GAS_P_CONFIG[doc]).map(
          (doc) => {
            return {
              ...doc,
              title: intl.formatMessage({
                id: `pages.application.view.documentViewer.documents.${doc.field}`,
              }),
              name: documentData[doc.model]?.nombre || '',
            }
          }
        )
      )
    }
  }, [application, power, documentData, intl])

  const handleUploadError = (data) => {
    feedback('error', data?.mssg || 'La subida del documento no se pudo completar.')
  }

  return (
    <Box p={4}>
      <Box mb={4}>
        <Typography
          variant="h5"
          style={{ marginBottom: '2em', display: 'flex', justifyContent: 'center' }}
        >
          {application.gas_p
            ? intl.formatMessage({
                id: 'pages.application.detail.actionButtons.modify_request_gas_p.label',
              })
            : intl.formatMessage({
                id: 'pages.application.detail.actionButtons.request_gas_p.label',
              })}
        </Typography>
        <Grid container spacing={4}>
          <>
            <Box mb={2} ml={1}>
              <SelectInput
                required
                fullWidth
                name="potencia_gas_p"
                title="Potencia instalación gas"
                value={power}
                values={combos['potencia_gas_p']?.data}
                onChange={handleChangePower}
              />
            </Box>
            {uploadButtonArray.map((uploadButton) => {
              return (
                <Grid key={uploadButton.id} item xs={12}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography display="inline" variant="body1">
                          {uploadButton.title}
                        </Typography>
                        <UploadTooltip file={uploadButton} />
                      </Box>
                      <Typography
                        variant="body2"
                        color={uploadButton.estado === 'Rechazado' ? 'error' : 'initial'}
                      >
                        {uploadButton.name}
                      </Typography>
                    </Box>
                    <FileUploaderNotAssociated
                      onSuccess={(data) => handleUploadFile(data, uploadButton.model)}
                      onError={handleUploadError}
                      {...uploadButton}
                    >
                      {(props) => (
                        <span
                          className="MuibuttonBase-root MuiButton-root MuiButton-outlined"
                          style={{ marginLeft: 10 }}
                          disabled={loading}
                        >
                          {props.loading ? (
                            <CircularProgress size={12} />
                          ) : (
                            intl.formatMessage({ id: 'pages.certificates.documents.button.add' })
                          )}
                        </span>
                      )}
                    </FileUploaderNotAssociated>
                  </Box>
                </Grid>
              )
            })}
          </>
        </Grid>
      </Box>
      <Box display="flex" justifyContent="space-around" mt={3}>
        <ThemeButton onClick={() => close()} color="primary" labelM="lg">
          {intl.formatMessage({ id: 'global.close' })}
        </ThemeButton>
        <ThemeButton
          onClick={() => handleRequestGas()}
          color="primary"
          labelM="lg"
          loading={loading}
          disabled={!canSave}
        >
          {intl.formatMessage({ id: 'global.save' })}
        </ThemeButton>
      </Box>
      {!canSave && power !== '' ? (
        <Box>
          <Typography
            variant="body2"
            style={{ marginTop: '1.5em', display: 'flex', justifyContent: 'flex-start' }}
          >
            {intl.formatMessage({
              id: 'pages.application.view.documentViewer.cannot_save.title',
            })}
          </Typography>
        </Box>
      ) : null}
    </Box>
  )
}
