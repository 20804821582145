export const PO_REQ_FILTERS = [

  {
    name: 'estado',
    comboId: 'estados_peticion_op',
    multiple: true,
  },
  {
    name: 'ccaa',
    label: 'CCAA',
    values: [
      {
        key: '1',
        value: 'Andaluc\u00eda',
      },
      {
        key: '13',
        value: 'Madrid',
      },
      {
        key: '2',
        value: 'Aragon',
      },
      {
        key: '8',
        value: 'Castilla la Mancha',
      },
      {
        key: '7',
        value: 'Castilla y Leon',
      },
      {
        key: '9',
        value: 'Catalunya',
      },
      {
        key: '12',
        value: 'Galicia',
      },
      {
        key: '15',
        value: 'Navarra',
      },
      {
        key: '17',
        value: 'La Rioja',
      },
      {
        key: '10',
        value: 'Comunidad Valenciana',
      },
      {
        key: '4',
        value: 'Baleares',
      },
    ],
    multiple: false,
  },
  {
    name: 'tipo_mercado',
    comboId: 'tipo_mercado_campañas',
    multiple: true,
  },
  {
    name: 'fecha_peticion_desde',
    filterType: 'date',
    maxDateField: 'fecha_peticion_hasta',
  },
  {
    name: 'fecha_peticion_hasta',
    filterType: 'date',
    minDateField: 'fecha_peticion_desde',
  },
]
