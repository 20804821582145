import React, { useEffect, useState } from 'react'
import { Box, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { ThemeButton } from '../theme-button'
import { DateInput } from '../date-input'
import DateFnsAdapter from '@date-io/date-fns'

export const ConfirmationDialog = ({
  title = 'Warning:',
  text = 'Are you sure you want to proceed?',
  yesText = 'Yes',
  yesColor = 'primary',
  yesAction = () => {},
  yesAndClose = true,
  fromTo = false,
  noText = 'No',
  noColor = 'default',
  noAction = () => {},
  noAndClose = true,
  close,
  gestor,
  awaiting=true,
}) => {
  const dateFns = new DateFnsAdapter()

  const [loading, setLoading] = useState(false)

  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [maxDate, setMaxDate] = useState()

  const handleConfirm = () => {
    setLoading(true)
    if(awaiting) {
      Promise.resolve(yesAction({ from, to }))
        .then(() => {
          setLoading(false)
          if (yesAndClose) {
            close()
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }
    else {
      yesAction({ from, to })
      setLoading(false)
      if (yesAndClose) {
        close()
      }
    }
  }

  useEffect(() => {
    setMaxDate(dateFns.addDays(dateFns.date(from), 30))
  }, [from]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
        </Box>
        {gestor && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={1}>
            <Typography variant="h5" color="error">
              {gestor}
            </Typography>
          </Box>
        )}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{text}</Typography>
        {fromTo && (
          <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={1}>
            <Box width={200} m={[0, 1]}>
              <DateInput
                label="from"
                value={from}
                onChange={({ target: { value } }) => {
                  setFrom(value)
                }}
              />
            </Box>

            <Box width={200} m={[0, 1]}>
              <DateInput
                label="to"
                value={to}
                onChange={({ target: { value } }) => {
                  setTo(value)
                }}
                disabled={!from}
                minDate={from}
                maxDate={maxDate}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ThemeButton
          labelM="small"
          color={yesColor}
          onClick={handleConfirm}
          disabled={loading || (fromTo && (!from || !to))}
          loading={loading}
        >
          {yesText}
        </ThemeButton>
        <ThemeButton
          labelM="small"
          color={noColor}
          onClick={() => {
            noAction()
            if (noAndClose) {
              close()
            }
          }}
        >
          {noText}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}
