import React from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FoldingPanel } from '../../../../../ui'
import { useApplicationUserPermitsContext } from '../../../../../pages/applications/contexts'
import { AppEventsTable } from '../../events-table'
import { ApplicationCommenter } from '../../commenter'

export const HistoryPanel = () => {
  const { formatMessage } = useIntl()
  const { commentsOnLog } = useApplicationUserPermitsContext()

  return (
    <FoldingPanel
      isExpanded={false}
      title={formatMessage({ id: 'pages.application.detail.history.title' })}
    >
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        {commentsOnLog ? <ApplicationCommenter /> : null}
        <AppEventsTable />
      </Box>
    </FoldingPanel>
  )
}
