import React, { createContext, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { USER_APP_CONFIG } from '../constants'

const ApplicationUserPermits = createContext()

export const ApplicationUserPermitsProvider = ({ children }) => {
  const user = useSelector((state) => state.profile.user) || {}
  const userPermits = useMemo(() => {
    return USER_APP_CONFIG(user)
  }, [user])
  return (
    <ApplicationUserPermits.Provider value={userPermits}>
      {children}
    </ApplicationUserPermits.Provider>
  )
}

export const useApplicationUserPermitsContext = () => useContext(ApplicationUserPermits)
