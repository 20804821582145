export const SET_MARKETERS_DATA = 'SET_MARKETERS_DATA'

//funciones antes del refactor
export const SET_ZONES_DATA = 'SET_ZONES_DATA'
export const CHECK_SINGLE_ZIP = 'CHECK_SINGLE_ZIP'
export const CHECK_SINGLE_PROVINCE = 'CHECK_SINGLE_PROVINCE'
export const ZIPS_TO_SEND = 'ZIPS_TO_SEND'
export const WHOLE_ZIP_TO_CHECK = 'WHOLE_ZIP_TO_CHECK'
export const WHOLE_ZIPS_TO_SEND = 'WHOLE_ZIPS_TO_SEND'
export const PROVINCE_TO_SEND = 'PROVINCE_TO_SEND'
