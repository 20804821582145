import { APP_INITIAL_STATE, APP_ACTIONS_TYPES } from './app-reducer.constants'

export const appReducer = (state = APP_INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case APP_ACTIONS_TYPES.SET_APPLICATION:
      return {
        ...state,
        application: payload,
        loading: false,
      }
    case APP_ACTIONS_TYPES.SET_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case APP_ACTIONS_TYPES.SET_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
      }
    case APP_ACTIONS_TYPES.SET_EVENTS:
      return {
        ...state,
        events: payload,
      }
    case APP_ACTIONS_TYPES.SET_DETAIL_FAILURE:
      return {
        ...state,
        error: true,
      }
    case APP_ACTIONS_TYPES.SET_INSTALLER_VALUE:
      return {
        ...state,
        installerValues: payload,
      }
    default:
      throw new Error('unknown action')
  }
}
