import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, Grid } from '@material-ui/core'

import { TextInput } from '../../../../ui'
import { GUILD_FORM_CONFIG } from '../../constants'

export const GuildForm = ({ model = {} }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)

  const disabled = true
  const formState = model

  const inputProps = { formState, combos, intl }

  return (
    <form>
      <Box p={3} bgcolor="grey.200" style={{ boxShadow: '1px 2px 4px 1px rgba(0, 0, 0, 0.2)' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).NOMBRE, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).NIF, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).RAZON, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).WEB, disabled }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).DIRECCION, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).COD_POSTAL, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).EMAIL, disabled }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput {...{ ...GUILD_FORM_CONFIG(inputProps).TELEFONO, disabled }} />
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}
