import React from 'react'
import { useIntl } from 'react-intl'

import { useModalContext, ThemeButton } from '../../../../../ui'
import { EditClientDialog } from '../../dialogs'

export const EditClientBtn = (props) => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  // React.useEffect(() => {
  //   handleOpenModal()
  // }, [])

  const handleOpenModal = () =>
    open({
      Component: EditClientDialog,
      data: props,
      type: 'responsive',
    })

  return (
    <ThemeButton size="small" color="primary" onClick={handleOpenModal}>
      {formatMessage({ id: 'global.edit' })}
    </ThemeButton>
  )
}
