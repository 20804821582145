export const IP_HIST_TABLE_COLUMNS = [
  {
    field: 'IP_documentum_id',
    align: 'left',
    width: 120,
  },
  {
    field: 'factura_documentum_id',
    align: 'left',
    width: 140,
  },
  {
    field: 'nif_empresa',
    align: 'left',
    width: 120,
    sorting: false,
  },
  {
    field: 'estado',
    align: 'left',
    width: 120,
  },
  {
    field: 'observaciones_rechazo',
    aling: 'left',
    width: 600,
  },
  {
    field: 'tipo_instalacion',
    align: 'left',
    width: 100,
  },
  {
    field: 'fecha_creacion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'fecha_inspeccion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'codigo_instalacion',
    align: 'left',
    width: 220,
  },
  {
    field: 'direccion',
    align: 'left',
    width: 400,
  },
]

export const IP_HIST_EXPORT_COLUMNS = [
  { field: 'escenario' },
  { field: 'tipo_documento' },
  { field: 'estado' },
  { field: 'observaciones_rechazo' },
  { field: 'fecha_estado', exportType: 'date' },
  { field: 'tipo_gas' },
  { field: 'UNE' },
  { field: 'tipo_instalacion' },
  { field: 'CUPS' },
  { field: 'cod_derivacion' },
  { field: 'provincia' },
  { field: 'poblacion' },
  { field: 'codigo_postal' },
  { field: 'tipo_via' },
  { field: 'via' },
  { field: 'nombre_via' },
  { field: 'numero' },
  { field: 'anexo' },
  { field: 'bloque' },
  { field: 'escalera' },
  { field: 'piso' },
  { field: 'puerta' },
  { field: 'resultado' },
  { field: 'anomalias' },
  { field: 'situacion_suministro' },
  { field: 'fecha_inspeccion', exportType: 'date' },
  { field: 'IP_documentum_id' },
  { field: 'numero_factura' },
  { field: 'importe_factura' },
  { field: 'fecha_factura', exportType: 'date' },
  { field: 'observaciones' },
  { field: 'LOPD' },
  { field: 'fecha_creacion', exportType: 'date' },
  { field: 'nombre_empresa' },
  { field: 'nif_empresa' },
  { field: 'email_empresa' },
  { field: 'iban_empresa' },
  { field: 'provincia_empresa' },
  { field: 'poblacion_empresa' },
  { field: 'cod_postal_empresa' },
  { field: 'reg_industrial_empresa' },
  { field: 'telefono_empresa' },
  { field: 'fecha_creacion_empresa', exportType: 'date' },
  { field: 'nif_instalador' },
  { field: 'nombre_instalador' },
  { field: 'apellido1_instalador' },
  { field: 'apellido2_instalador' },
  { field: 'categoria_inspector' },
]

export const JCA_HIST_TABLE_COLUMNS = [
  {
    field: 'JCA_documentum_id',
    align: 'left',
    width: 120,
  },
  {
    field: 'nif_empresa',
    align: 'left',
    width: 120,
    sorting: false,
  },
  {
    field: 'estado',
    align: 'left',
    width: 120,
  },
  {
    field: 'observaciones_rechazo',
    aling: 'left',
    width: 600,
  },
  {
    field: 'tipo_instalacion',
    align: 'left',
    width: 100,
  },
  {
    field: 'fecha_creacion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'fecha_inspeccion',
    align: 'left',
    width: 160,
    type: 'date',
  },
  {
    field: 'codigo_instalacion',
    align: 'left',
    width: 220,
  },
  {
    field: 'direccion',
    align: 'left',
    width: 400,
  },
]

export const JCA_HIST_EXPORT_COLUMNS = [
  { field: 'escenario' },
  { field: 'estado' },
  { field: 'observaciones_rechazo' },
  { field: 'fecha_estado', exportType: 'date' },
  { field: 'tipo_gas' },
  { field: 'UNE' },
  { field: 'tipo_instalacion' },
  { field: 'CUPS' },
  { field: 'cod_derivacion' },
  { field: 'provincia' },
  { field: 'poblacion' },
  { field: 'codigo_postal' },
  { field: 'tipo_via' },
  { field: 'nombre_via' },
  { field: 'via' },
  { field: 'numero' },
  { field: 'anexo' },
  { field: 'bloque' },
  { field: 'escalera' },
  { field: 'piso' },
  { field: 'puerta' },
  { field: 'anomalias' },
  { field: 'situacion_suministro' },
  { field: 'fecha_inspeccion', exportType: 'date' },
  { field: 'JCA_documentum_id' },
  { field: 'observaciones' },
  { field: 'LOPD' },
  { field: 'fecha_creacion', exportType: 'date' },
  { field: 'nombre_empresa' },
  { field: 'nif_empresa' },
  { field: 'email_empresa' },
  { field: 'iban_empresa' },
  { field: 'provincia_empresa' },
  { field: 'poblacion_empresa' },
  { field: 'cod_postal_empresa' },
  { field: 'reg_industrial_empresa' },
  { field: 'telefono_empresa' },
  { field: 'fecha_creacion_empresa', exportType: 'date' },
]

export const IP_DEFAULT_QUERY = {
  _pagina: 1,
  _num_x_pagina: 10,
  fecha_creacion_inicio: '16/9/2021',
  fecha_creacion_fin: '16/11/2021',
}

export const JCA_DEFAULT_QUERY = {
  _pagina: 1,
  _num_x_pagina: 10,
}
