import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { isValidEmail, isValidNIF, isValidNIE, isValidCIF } from '../../../../core/utils'
import { isValidPhone } from '../../../../core/utils/phoneUtils'

export const EditUserBtn = (props) => {
  const { formatMessage } = useIntl()
  const txt = (extension) =>
    formatMessage({ id: 'pages.sir.detail.fields.' + extension + '.error' })
  const {
    application,
    updateApplication,
    editUserMode,
    setUserEditMode,
    updateUserSir,
    getSirEvents,
  } = useSirContext()
  const [errorMssg, setErrorMssg] = useState('')

  const [loading, setLoading] = useState(false)

  function handleUserEdition() {
    setUserEditMode(!editUserMode)
  }

  //Eliminamos el modo edición al desmontar el componente
  useEffect(() => {
    return () => {
      setUserEditMode(false)
    }
  }, [])

  function handleCancelUserEdition() {
    props.setFormState({
      ...application,
      usuario_sir: {
        ...application.usuario_sir,
        nombre_cliente: application.usuario_sir.nombre_cliente,
        apellidos_cliente: application.usuario_sir.apellidos_cliente,
        identificador_cliente: application.usuario_sir.identificador_cliente,
        email_cliente: application.usuario_sir.email_cliente,
        telefono_cliente: application.usuario_sir.telefono_cliente,
      },
      /*
      cups: application.cups,
      provincia: application.provincia,
      municipio: application.municipio,
      tipo_calle: application.tipo_calle,
      direccion: application.direccion,
      numero: application.numero,
      bloque: application.bloque,
      escalera: application.escalera,
      piso: application.piso,
      puerta: application.puerta,
      cod_postal: application.cod_postal, */
      presupuesto_sir: {
        ...application.presupuesto_sir,
        estado_finca: application.presupuesto_sir.estado_finca,
      },
    })
    setErrorMssg('')
    setUserEditMode(false)
  }

  function validateUserData(usuario_sir) {
    if (usuario_sir.nombre_cliente === '') {
      setErrorMssg(txt('cliente_nombre'))
      return false
    }
    if (usuario_sir.apellidos_cliente === '') {
      setErrorMssg(txt('cliente_apellidos'))
      return false
    }
    if (
      !isValidNIF(usuario_sir.identificador_cliente) &&
      !isValidNIE(usuario_sir.identificador_cliente) &&
      !isValidCIF(usuario_sir.identificador_cliente)
    ) {
      setErrorMssg(txt('identificador_cliente'))
      return false
    }
    if (!isValidPhone(usuario_sir.telefono_cliente)) {
      setErrorMssg(txt('telefono_cliente'))
      return false
    }
    if (!isValidEmail(usuario_sir.email_cliente)) {
      setErrorMssg(txt('email_cliente'))
      return false
    }
    /*     if (props.formState.provincia === '') {
      setErrorMssg(txt('provincia'))
      return false
    }
    if (props.formState.municipio === '') {
      setErrorMssg(txt('municipio'))
      return false
    }
    if (props.formState.direccion === '') {
      setErrorMssg(txt('direccion'))
      return false
    }
    if (props.formState.numero === '') {
      setErrorMssg(txt('numero'))
      return false
    }
    const regex = /^\d{5}$/
    if (props.formState.cod_postal === '' || !regex.test(props.formState.cod_postal)) {
      setErrorMssg(txt('cod_postal'))
      return false
    } */

    return true
  }

  function handleSaveUserEdited(e) {
    e.preventDefault()
    if (editUserMode) {
      setErrorMssg('')
      const usuario_sir = props.formState.usuario_sir
      if (validateUserData(usuario_sir)) {
        let payload = {}

        payload['nombre_cliente'] = usuario_sir.nombre_cliente
        payload['apellidos_cliente'] = usuario_sir.apellidos_cliente
        payload['identificador_cliente'] = usuario_sir.identificador_cliente
        payload['telefono_cliente'] = usuario_sir.telefono_cliente
        payload['email_cliente'] = usuario_sir.email_cliente

        /*  
        payload['cups'] = props.formState.cups
        payload['provincia'] = props.formState.provincia
        payload['municipio'] = props.formState.municipio
        payload['tipo_calle'] = props.formState.tipo_calle
        payload['direccion'] = props.formState.direccion
        payload['numero'] = props.formState.numero
        payload['bloque'] = props.formState.bloque
        payload['escalera'] = props.formState.escalera
        payload['piso'] = props.formState.piso
        payload['puerta'] = props.formState.puerta
        payload['cod_postal'] = props.formState.cod_postal
        */

        setLoading(true)
        let payload_presupuesto = {}
        if (
          props.formState.presupuesto_sir.estado_finca !== application.presupuesto_sir.estado_finca
        ) {
          payload_presupuesto['estado_finca'] = props.formState.presupuesto_sir.estado_finca
          updateApplication(
            application.presupuesto_sir.id,
            application.id,
            payload_presupuesto,
            null
          )
            .then(() => {
              setErrorMssg('')
            })
            .catch(() => {
              setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
              setLoading(false)
            })
        }

        updateUserSir(props.formState.usuario_sir.id, payload)
          .then(() => {
            setErrorMssg('')
            setLoading(false)
            setUserEditMode(false)
            getSirEvents(props.formState.id)
          })
          .catch(() => {
            setErrorMssg(formatMessage({ id: 'calls.supervision.error.put.description' }))
            setLoading(false)
            setUserEditMode(true)
          })
      }
    }
  }
  return (
    <Box>
      {editUserMode ? (
        <Box display="flex" justifyContent="flex-start">
          <ThemeButton color="default" loading={loading} onClick={handleCancelUserEdition}>
            {formatMessage({ id: 'pages.sir.detail.button.cancel.label' })}
          </ThemeButton>
          <ThemeButton
            color="primary"
            loading={loading}
            onClick={handleSaveUserEdited}
            style={{ marginLeft: '10px' }}
          >
            {formatMessage({ id: 'pages.sir.detail.button.save.label' })}
          </ThemeButton>
        </Box>
      ) : (
        <ThemeButton color="primary" loading={loading} onClick={handleUserEdition}>
          {formatMessage({ id: 'pages.sir.detail.button.edit.user.label' })}
        </ThemeButton>
      )}
      {errorMssg ? (
        <Box mt={2}>
          <Alert severity="error">{errorMssg}</Alert>
        </Box>
      ) : null}
    </Box>
  )
}
