import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Paper, withStyles } from '@material-ui/core'
import { MTableBodyRow, MTableCell } from 'material-table'

import { TableUrlPagination } from '../../../../ui'

import { installerTableStyles } from './installer-table.styles'
import { TableActions } from '../table-actions'

import { installerDataPreparation } from '../../logics'
import { INSTALLER_TABLE_ACTIONS, INSTALLER_TABLE_COLUMNS } from '../../constants'

const getLookup = (combo) => {
  let lookup = {}
  combo.forEach((item) => (lookup[item.key] = item.value))
  return lookup
}

export const InstallerTable = withStyles(installerTableStyles)(({ classes }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const installerLoader = useSelector((state) => state.loader.installers)
  const combos = useSelector((state) => state.combos)

  const data = installerLoader?.data?.rows || []
  const totalEntries = installerLoader?.data?.total_rows
  const search = installerLoader?.config?.params
  const loading = installerLoader?.loading

  //Formatted columns:
  const formattedColumns = useMemo(() => {
    return [...INSTALLER_TABLE_COLUMNS, ...INSTALLER_TABLE_ACTIONS].map((column) => {
      if (column.field === 'actions')
        return {
          ...column,
          title: formatMessage({ id: 'global.actions' }),
        }
      return {
        ...column,
        title: formatMessage({ id: `pages.users.table.columns.${column.field}.title` }),
        cellStyle: {
          ...installerTableStyles.cell,
          ...column.cellStyle,
          minWidth: column.width,
          maxWidth: column.width,
        },
        emptyValue: () => <span>--</span>,
        lookup:
          column.hasOwnProperty('comboId') && combos[column.comboId] && combos[column.comboId].data
            ? getLookup(combos[column.comboId].data)
            : null,
      }
    })
  }, [combos]) // eslint-disable-line react-hooks/exhaustive-deps

  //Assembling and adjusting data
  const processedData = useMemo(() => {
    return installerDataPreparation(data, { intl })
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  //Table configuration
  const finalTableOptions = useMemo(
    () => ({
      toolbar: false,
      selection: false,
      headerStyle: installerTableStyles.header,
      draggable: false,
      fixedColumns: {
        right: 1,
      },
    }),
    []
  )

  //Function to build table parts
  const tableComponents = useMemo(
    () => ({
      Container: ({ children }) => <Paper elevation={0}>{children}</Paper>,
      Row: (props) => {
        return (
          <MTableBodyRow
            {...props}
            className={props.data?.confirmed ? classes.success : classes.failure}
          />
        )
      },
      Cell: (props) => (
        <MTableCell {...props} style={{ padding: 0 }}>
          {props.columnDef.field === 'actions' ? <TableActions {...props} /> : props.children}
        </MTableCell>
      ),
    }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <TableUrlPagination
      columns={formattedColumns}
      components={tableComponents}
      options={finalTableOptions}
      totalEntries={totalEntries}
      search={search}
      //Directly passed to child
      data={processedData}
      isLoading={loading}
    />
  )
})
