import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { Alert } from '@material-ui/lab'
import { useJobsContext } from "../../contexts";
import { Accordion, AccordionDetails, Box, styled, Typography, withStyles } from "@material-ui/core";
import { REPORT_DETAILED_CONFIG, REPORT_DETAILED_VIEW } from "./report-detailed.constants";
import { FormFoldingPanel } from "../../../../ui";
import { useCombos } from "../../../combos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#0041651A',
    marginTop: '20px',
    minHeight: 64,
  },
  content: {
    margin: '12px 0',
  },
})(MuiAccordionSummary);
export const ReportDetailedComponent = () => {
  const { formatMessage } = useIntl()
  const combos = useCombos([])
  const intl = useIntl()
  const {
    instance_data,
    loading,
  } = useJobsContext()

  const fieldList = REPORT_DETAILED_CONFIG(intl)

  if(loading) return ("")
  else {
    return (
      <Box sx={{ marginTop: '20px' }}>
        {instance_data.status === 'OK' ? (
          <Alert severity="success">
            {formatMessage({ id: 'pages.jobs.report.detailed.status.ok.message'})} {new Date(instance_data.start_time).toLocaleString()}
          </Alert>
        ) : null}
        {instance_data.status === 'KO' ? (
          <Alert severity="error">
            {formatMessage({ id: 'pages.jobs.report.detailed.status.ko.message'})} {new Date(instance_data.start_time).toLocaleString()}
          </Alert>
        ) : null}
        {instance_data.status === 'RUNNING' ? (
          <Alert severity="info">
            {formatMessage({ id: 'pages.jobs.report.detailed.status.running.message'})} {new Date(instance_data.start_time).toLocaleString()}
          </Alert>
        ) : null}

        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            {REPORT_DETAILED_VIEW.map((block) => {
              return (
                <div key={block.title}>
                  <FormFoldingPanel
                    {...{ data: instance_data, combos, intl, block, fieldList }}
                  ></FormFoldingPanel>
                </div>
              )
            })}
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">
                  {formatMessage({id: 'pages.jobs.report.detailed.form.params.title'})}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {instance_data.params
                  ? instance_data.params.map((param) => {
                    return (
                      <Box key={param.key}>
                        <Typography variant="body1" gutterBottom>
                          {param.key}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {param.value}
                        </Typography>
                      </Box>
                    )
                  })
                  : formatMessage({ id: 'pages.jobs.report.detailed.form.params.empty.title'})}
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6">{formatMessage({ id: "pages.jobs.report.detailed.form.logs.title"})}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={10}
                  placeholder={formatMessage({ id: "pages.jobs.report.detailed.form.logs.empty.title"})}
                  defaultValue={instance_data.logfile ? atob(instance_data.logfile) : ""}
                  style={{
                    width: '100%',
                    border: 'none !important',
                    fontFamily: 'monospace, sans-serif',
                    maxWidth: '100%',
                  }}
                  maxRows={15}
                  readOnly
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Box>
    )
  }

}
