import React, { useState } from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { TextInput, ThemeButton } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

export const FilterConfirmDialog = ({
    title = 'Warning:',
    text = 'Are you sure you want to proceed?',
    yesText = 'Yes',
    yesColor = 'primary',
    yesAction = () => { },
    yesAndClose = true,
    noText = 'No',
    noColor = 'default',
    noAction = () => { },
    noAndClose = true,
    close,
}) => {
    const [value, setValue] = useState("")
    const handleChange = (e) => [
        setValue(e.target.value)
    ]
    return (
        <Box p={4}>
            <DialogTitle>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h4" color="primary">
                        {title}
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <TextInput
                    required
                    name="comentario"
                    title={text}
                    value={value}
                    inputProps={{ maxLength: TEXT_AREA_LENGTH }}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <ThemeButton
                    labelM="small"
                    color={noColor}
                    onClick={() => {
                        noAction()
                        if (noAndClose) {
                            close()
                        }
                    }}
                >
                    {noText}
                </ThemeButton>
                <ThemeButton
                    labelM="small"
                    color={yesColor}
                    onClick={() => {
                        yesAction(value)
                        if (yesAndClose) {
                            close()
                        }
                    }}
                >
                    {yesText}
                </ThemeButton>
            </DialogActions>
        </Box>
    )
}
