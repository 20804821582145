import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { stringify } from 'qs'
import { withStyles, Box, Paper, Typography } from '@material-ui/core'

import { CounterBtn } from './counter-btn.component'
import { SemaphoreBtns } from './semaphore-btns.component'
import { cpCardStyles } from './c-p-card.styles'
import NotificationsActive from '@material-ui/icons/NotificationsActive'

export const CPCardComponent = withStyles(cpCardStyles)(
  ({ classes, id, icon, title, count, path, searchParams, lightMode = false }) => {
    const { formatMessage } = useIntl()

    return (
      <Paper elevation={3} className={classes.container}>
        <Box width="30%" textAlign="center">
          {icon}
        </Box>
        {lightMode ? (
          <Box width="70%" display="flex" alignItems="center">
            <Link to={`${path}${searchParams ? '?' : ''}${stringify(searchParams)}`}>
              <Typography variant="h6" color="primary">
                {formatMessage({ id: title })}
              </Typography>
            </Link>
          </Box>
        ) : (
          <Box width="70%" display="flex" flexDirection="column" pt={1}>
            <Typography variant="body2">{formatMessage({ id: title })}</Typography>
            <Box display="flex" justifyContent="space-between" mt={1} mb={2} pr={2}>
              {typeof count === 'object' && count.hasOwnProperty('semaforo') ? (
                <SemaphoreBtns {...{ id, counters: count, path, searchParams }} />
              ) : (
                <CounterBtn {...{ id, path, searchParams, count: count?.numero }} />
              )}
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={1} mb={2} pr={2}>
              <NotificationsActive color="secondary" />
              <CounterBtn
                {...{
                  id,
                  path,
                  searchParams: { ...searchParams, comunicaciones_pendientes: 'true' },
                  count: count?.comunicaciones_pendientes,
                }}
              />
            </Box>
          </Box>
        )}
      </Paper>
    )
  }
)
