import React, { createContext, useContext } from 'react'

import { useIPReducer } from '../hooks/ip-reducer.hook'

const IPContext = createContext()

export const IPContextProvider = ({ children }) => {
  const reducer = useIPReducer()
  return <IPContext.Provider value={reducer}>{children}</IPContext.Provider>
}

export const useIPContext = () => useContext(IPContext)
