import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box, DialogContent, DialogActions } from '@material-ui/core'

import { Communications } from './communications.component'
import {
  COMMS_CHANNELS,
  COMMS_CHANNELS_ECOMMERCE,
  COMMS_CALL_CENTER_CHANNELS,
  COMMS_CHANNELS_GESTOR_GC,
} from './communications.constants'
import { ThemeButton } from '../../../../ui'

export const CommunicationsDialog = ({ close, application, checkUnreadComms }) => {
  const { formatMessage } = useIntl()
  const role = useSelector((state) => state.global.role)
  const addPlataforma = role === 'gestor' || role === 'delegado'
  const commsChannels =
    role === 'e_commerce'
      ? COMMS_CHANNELS_ECOMMERCE
      : role === 'call_center'
      ? COMMS_CALL_CENTER_CHANNELS
      : role === 'gestor_gc'
      ? COMMS_CHANNELS_GESTOR_GC
      : addPlataforma
      ? [
          ...COMMS_CHANNELS,
          { key: 'call_center', value: 'Comunicación con Plataforma', readOnly: false },
        ]
      : COMMS_CHANNELS

  const handleClose = () => {
    checkUnreadComms()
    close()
  }

  return (
    <>
      <DialogContent>
        <Communications {...{ application, profile: role, channels: commsChannels }} />
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <ThemeButton color="primary" labelM="lg" onClick={handleClose}>
            {formatMessage({ id: 'global.close' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </>
  )
}
