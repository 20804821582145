import { createSlice } from '@reduxjs/toolkit'
import { saveLocalStorage } from '../../../core/utils'
import { loaderActions } from '../../loader/slices'
import { formatPermissions } from '../utils'

const globalSlice = createSlice({
  name: 'global',
  initialState: {
    isAuthorized: false,
    userId: '',
    role: '',
    accessToken: '',
  },
  reducers: {
    setAuthorization: (state, { payload }) => {
      state.isAuthorized = payload
    },
    setAuthData: (state, { payload }) => {
      state.userId = payload['user_id']
      state.role = payload['role']
      state.accessToken = payload['token']
      state.permissions = payload['permissions']
    },
  },
})

export const globalReducer = globalSlice.reducer

export const { setAuthorization, setAuthData } = globalSlice.actions

export const loginUser = (userState, userDispatch) => (dispatch) => {
  const localData = {
    'apg-auth': userState,
  }
  saveLocalStorage('dsc', localData)
  let permissions = {}
  if (userState.permissions) {
    permissions = formatPermissions(userState.permissions)
  }
  dispatch(setAuthData({ ...userState, permissions }))
  dispatch(setAuthorization(true))
  if (userDispatch)
    userDispatch({
      type: 'LOGIN',
      payload: userState,
    })
}

export const logoutUser = (userDispatch) => (dispatch) => {
  const localData = {
    'apg-auth': {},
  }
  saveLocalStorage('dsc', localData)
  dispatch(setAuthorization(false))
  dispatch(setAuthData({}))
  dispatch(loaderActions.reset())
  userDispatch({ type: 'LOGOUT' })
}
