import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { JCADashboardPage, JCADetailPage } from '../components'

export const JCARouter = () => {
  const permissions = useSelector((state) => state.global.permissions)

  return (
    <Switch>
      <Route exact path="/jca" component={JCADashboardPage} />
      {permissions.ver_detalle_jca ? (
        <Route exact path="/jca/:jcaId" component={JCADetailPage} />
      ) : null}
      <Redirect from="*" to="/jca" />
    </Switch>
  )
}
