import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'
import { CERTIFICATE_TYPE } from '../constants'
import { useSelector } from 'react-redux'

export const useAppSupervisionCalls = () => {
  const { get, put, post, putById } = requests
  const { formatMessage } = useIntl()
  const userId = useSelector((state) => state.global.userId)

  const getCertificateURL = (certificateType, id) => {
    switch (certificateType) {
      case CERTIFICATE_TYPE.IRG1:
        return `/certificado_IRG1/${id}`
      case CERTIFICATE_TYPE.IRG2:
        return `/certificado_IRG2/${id}`
      case CERTIFICATE_TYPE.IRG3:
        return `/certificado_IRG3/${id}`
      case CERTIFICATE_TYPE.CM:
        return `/certificado_modificacion/${id}`
      default:
        throw new Error('Unrecognized certificate type')
    }
  }
  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getSupervisionApps = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/tareas/tabla_sspp', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getSupervisionAppDetail = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        get(`/tareas/${id}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getBitacoraTask = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        get(`/bitacora/tareas/${id}`)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putTaskCall = useCallback(
    (id, data) =>
      new Promise((resolve, reject) => {
        put(`/tareas/${id}`, data)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.supervision.error.put.description' }))
                return reject(response.status)
              case 404:
                if (response.data.code === 3638)
                  return feedback('error', 'El Cidi facilitado no existe')
                if (response.data.code === 4230)
                  return feedback('error', 'El CUPS facilitado no existe')
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const getAllSupervisionApps = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/tareas/tabla_sspp', config)
          .then((response) => resolve(response))
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case '403': //Permisson denied by role
                return reject(response)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const putSupervisionApp = useCallback(
    (id, data) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/${id}`, data)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.supervision.error.put.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSupervisionCertificate = useCallback(
    (certificateType, selectedCertificateId, data) =>
      new Promise((resolve, reject) => {
        const certificateUrl = getCertificateURL(certificateType, selectedCertificateId)
        put(`${certificateUrl}`, data)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.certificate.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback(
                  'error',
                  formatMessage({ id: 'calls.supervision.certificate.error.put.description' })
                )
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putSupervisionDE = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById('/facturacion', id, payload).then((response) => {
          resolve(response)
        })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putValidateNonCaptableApp = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        put(`/solicitudes/validar_no_captable/${id}`)
          .then((response) => {
            resolve(response)
            return feedback(
              'success',
              formatMessage({ id: 'calls.supervision.success.put.description' })
            )
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', formatMessage({ id: 'calls.supervision.error.put.description' }))
                return reject(response.status)
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )
  const manualNormalization = useCallback(
    (cups, cod_cidi) =>
      new Promise((resolve, reject) => {
        //const cod_cidi = Math.floor(Math.random() * 1000000)
        post(
          `/potenciales/dummy`,
          {
            cups,
            cod_cidi,
            estado_cups: 'CONTRATABLE',
          },
          { timeout: 0 }
        )
          .then((response) => {
            resolve(response)
            return feedback('success', 'Potencial creado')
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const assignTasksCall = useCallback(
    async ({ tasks, user }) => {
      try {
        const payload = {
          sspp_id: parseInt(user || userId),
          tareas_ids: tasks,
        }

        const response = await put('/tareas/asignar', payload)

        feedback(
          'success',
          formatMessage(
            { id: 'pages.applications.feedback.5' },
            { id: response.data.map((tasck) => tasck.solicitud_serial_id).join(', ') }
          )
        )
      } catch ({ response }) {
        let message = { id: 'pages.applications.feedback.error.autoassign' }

        switch (response.data?.code) {
          case 3001:
            message = { id: 'pages.applications.feedback.warning.autoassign' }
            break
          case 3018:
            message = { id: 'pages.applications.feedback.warning.autoassign_no_solicitudes' }
            break
          case 4303:
            message = { id: 'pages.applications.feedback.warning.autoassign_max_solicitudes' }
            break
          case 4207:
            message = { id: 'pages.applications.feedback.error.autoassign_no_gestor' }
            break
          default:
            defaultError()
        }

        feedback('warning', formatMessage(message))
      }
    },
    [userId] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getSupervisionApps,
    getAllSupervisionApps,
    putSupervisionApp,
    getSupervisionAppDetail,
    putSupervisionCertificate,
    manualNormalization,
    putValidateNonCaptableApp,
    putSupervisionDE,
    assignTasksCall,
    getBitacoraTask,
    putTaskCall,
  }
}
