import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'

import { FilterBox } from '../../../../ui'
import { CAMPAIGNS_FILTERS } from './campaigns-filter.constants'
import { AddCampaignBtn } from '../buttons'
import { ExportToXLSBtn } from '../exporttoxls-btn'
import { useCampaignsCalls } from '../../hooks'
import { useLocation } from 'react-router-dom'
import { parse } from 'qs'

export const CampaignsFilter = () => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getCampaignCodesFilter } = useCampaignsCalls()
  const location = useLocation()
  const [codesFilterValues, setCodesFilterValues] = React.useState([])

  const getCodes = () => {
    const searchString = location.search.split('?')[1]
    let config = {}
    if (searchString) {
      const urlSearchParams = parse(searchString)
      config = {
        params: {...urlSearchParams, is_op: false},
      }
    }
    getCampaignCodesFilter(config).then(({ data }) => {
      setCodesFilterValues(data)
    })
  }

  const filters = CAMPAIGNS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    if (item.name === 'codigo') {
      item.values = codesFilterValues
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.campaigns.filters.' + item.name + '.title',
      }),
    }
  })

  React.useEffect(() => {
    getCodes()
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <FilterBox filters={filters}>
      <Box display="flex" justifyContent="flex-end">
        <ExportToXLSBtn />
        <AddCampaignBtn />
      </Box>
    </FilterBox>
  )
}
