import { getAddress } from '../../../core/utils/address-formatter'

const ROOT_NONCAPTABLESVALIDATION_FORM = 'pages.supervision.edit'

const TEXT_AREA_LENGTH = 250

export const ESTADO_FIELD_INITIAL_STATES = {
  '09': true, // Normalización - Creado
  '10': true, // Creado
}
export const NOT_PROCESSED_STATES = {
  '10': true, // Creado
  '02': true, // Rechazado
  '09': true, // Normalización - Creado
  '08': true, // Normalización - Rechazado
  '03': true, // Anulado
}

export const CANT_EDIT_COMMENTS_STATES = {
  '03': true,
  '04': true,
  '88': true,
  '90': true,
}

export const NORMALIZATION_PENDING_STATE = '88'

export const PROCESSED_STATE = '15'

const getNonCaptablesValidationCommonFields = (combos, intl, txt, onChange, application) => {
  return {
    // MERCADO: {
    //   componentType: 'select',
    //   fullWidth: true,
    //   fromData: (data) => ({
    //     value: data['mercado'],
    //     values: combos['tipo_mercado'] ? combos['tipo_mercado'].data : [],
    //   }),
    //   title: txt('informacion.mercado.title'),
    //   name: 'mercado',
    //   onChange,
    //   readOnly:
    //     !ESTADO_FIELD_INITIAL_STATES[application['estado']] &&
    //     (!application.estado_certificado || application.estado_certificado !== 'Creado'),
    // },
    // NUMERO_POLIZA: {
    //   fromData: (data) => ({
    //     value: data['cod_solicitud_zeus'],
    //   }),
    //   title: txt('informacion.poliza_scr.title'),
    //   name: 'cod_solicitud_zeus',
    //   inputProps: { maxLength: 18 },
    //   onChange,
    //   readOnly:
    //     !ESTADO_FIELD_INITIAL_STATES[application['estado']] &&
    //     (!application.estado_certificado || application.estado_certificado !== 'Creado'),
    // },
    ESTADO_SOLICITUD: {
      componentType: 'select',
      fullWidth: true,
      fromData: (data) => ({
        value: data['estado'],
        values: combos.estados_solicitud?.data || [],
      }),
      title: txt('informacion.solicitud_estado.title'),
      name: 'estado_solicitud',
      readOnly: true,
    },
    CUPS: {
      fromData: (data) => ({
        value: data['cups'],
      }),
      title: txt('informacion.cups.title'),
      name: 'cups',
      inputProps: { maxLength: 20 },
      onChange,
      readOnly: !(
        ESTADO_FIELD_INITIAL_STATES[application['estado']] &&
        !application.estado_certificado &&
        application.cups_editable
      ),
    },
    CIDI: {
      fromData: (data) => ({
        value: data['cidi'],
      }),
      title: txt('informacion.cidi.title'),
      name: 'cidi',
      inputProps: { maxLength: 50 },
      onChange,
      readOnly: !(
        ESTADO_FIELD_INITIAL_STATES[application['estado']] && !application.estado_certificado
      ),
    },
    SOLICITUD_ID: {
      fromData: (data) => ({
        value: data['solicitud_id'],
      }),
      title: txt('informacion.codigo_solicitud.title'),
      name: 'solicitud_id',
      readOnly: true,
    },
    CODIGO_FINANCIACION: {
      fromData: (data) => ({
        value: data['codigo_financiacion'],
      }),
      title: txt('informacion.codigo_financiacion.title'),
      name: 'codigo_financiacion',
      readOnly: true,
    },
    NUMERO_TARIFA_APLICADA: {
      fromData: (data) => ({
        value: data['numero_tarifa_aplicada'],
      }),
      title: txt('informacion.numero_tarifa_aplicada.title'),
      name: 'numero_tarifa_aplicada',
      readOnly: true,
    },
    TIPO_PETICION: {
      fromData: (data) => ({
        value: data['camino_solicitud']
          ? data['camino_solicitud'] === '01'
            ? 'Acometida'
            : data['camino_solicitud'] === '02' || data['camino_solicitud'] === '03'
            ? 'Instalación interior'
            : data['camino_solicitud'] === '04'
            ? 'Trámite certificado'
            : 'Lo desconozco'
          : 'Lo desconozco',
      }),
      title: txt('informacion.tipo_peticion.title'),
      name: 'tipo_peticion',
      readOnly: true,
    },
    USUARIO_GRABADOR: {
      fromData: (data) => ({
        value: data['usuario_grabador'],
      }),
      title: txt('informacion.usuario_grabador.title'),
      name: 'usuario_grabador',
      readOnly: true,
    },
    EMPRESA: {
      fromData: (data) => ({
        value: data['sociedad'],
      }),
      title: txt('informacion.sociedad.title'),
      name: 'sociedad',
      readOnly: true,
    },
    CODIGO_DELEGACION: {
      fromData: (data) => ({
        value: 'waiting definition',
      }),
      title: txt('informacion.codigo_delegacion.title'),
      name: 'codigo_delegacion',
      readOnly: true,
    },
    DESCRIPCION_DELEGACION: {
      fromData: (data) => ({
        value: 'waiting definition',
      }),
      title: txt('informacion.descripcion_delegacion.title'),
      name: 'descripcion_delegacion',
      readOnly: true,
    },
    MUNICIPIO: {
      fromData: (data) => ({
        value: data['municipio'],
      }),
      title: txt('informacion.municipio.title'),
      name: 'municipio',
      readOnly: true,
    },
    PROVINCIA: {
      fromData: (data) => ({
        value: data['provincia'],
      }),
      title: txt('informacion.provincia.title'),
      name: 'provincia',
      readOnly: true,
    },
    PUNTOS_SUMINISTRO_POTENCIALES: {
      fromData: (data) => ({
        value: data['puntos_suministo_potenciales'],
      }),
      title: txt('informacion.puntos_suministo_potenciales.title'),
      name: 'puntos_suministo_potenciales',
      readOnly: true,
    },
    PUNTOS_SUMINISTRO_INTERESADOS: {
      fromData: (data) => ({
        value: data['puntos_suministo_interesados'],
      }),
      title: txt('informacion.puntos_suministo_interesados.title'),
      name: 'puntos_suministo_interesados',
      readOnly: true,
    },
    NOMBRE_CLIENTE: {
      fromData: (data) => ({
        value: data['usuario']['nombre'] + ' ' + data['usuario']['apellidos'],
      }),
      title: txt('datos_cliente.nombre.title'),
      name: 'usuario_nombre',
      readOnly: true,
    },
    USUARIO_IDENTIFICADOR: {
      fromData: (data) => ({
        value: data['usuario']['identificador'],
      }),
      title: txt('datos_cliente.identificador.title'),
      name: 'usuario_identificador',
      readOnly: true,
    },
    USUARIO_EMAIL: {
      fromData: (data) => ({
        value: data['usuario']['email'],
      }),
      title: txt('datos_cliente.email.title'),
      name: 'usuario_email',
      readOnly: true,
    },
    DIRECCION: {
      fromData: (data) => ({
        value: getAddress(data) + (data['cod_postal'] ? ', ' + data['cod_postal'] : ''),
      }),
      title: txt('datos_cliente.direccion.title'),
      name: 'direccion',
      readOnly: true,
    },
    TELEFONO_MOVIL: {
      fromData: (data) => ({
        value: data['usuario']['telefono'],
      }),
      title: txt('datos_cliente.telefono.title'),
      name: 'usuario_telefono',
      readOnly: true,
    },
    TYPE_CLIENT: {
      fromData: (data) => ({
        value: combos['uso_punto_suministro']
          ? combos['uso_punto_suministro'].data?.filter((doc) => doc.key === data['uso'])[0]?.value
          : '--',
      }),
      title: txt('datos_cliente.uso_punto_suministro.title'),
      name: 'uso_punto_suministro',
      readOnly: true,
    },
    SAP: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['cod_sap'] : '',
      }),
      title: txt('empresa_instaladora.sap.title'),
      name: 'sap',
      readOnly: true,
    },
    NIF: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['cif'] : '',
      }),
      title: txt('empresa_instaladora.nif.title'),
      name: 'nif',
      readOnly: true,
    },
    USERNAME: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['email'] : '',
      }),
      title: txt('empresa_instaladora.username.title'),
      name: 'username',
      readOnly: true,
    },
    EEII: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['razon_social'] : '',
      }),
      title: txt('empresa_instaladora.eeii.title'),
      name: 'eeii',
      readOnly: true,
    },
    MOVIL_EEII: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['telefono'] : '',
      }),
      title: txt('empresa_instaladora.movil_eeii.title'),
      name: 'movil_eeii',
      readOnly: true,
    },
    SCR: {
      fromData: (data) => ({
        value: data['cod_solicitud_zeus'],
      }),
      title: txt('empresa_instaladora.scr.title'),
      name: 'scr',
      readOnly: true,
    },
    MAIL_EEII: {
      fromData: (data) => ({
        value: data['empresa'] ? data['empresa']['email'] : '',
      }),
      title: txt('empresa_instaladora.mail_eeii.title'),
      name: 'mail_eeii',
      readOnly: true,
    },
    NUM_CEDULA_HABITABILIDAD: {
      fromData: (data) => ({
        value: data['numero_cedula_habitabilidad'],
      }),
      title: txt('empresa_instaladora.num_cedula_habitabilidad.title'),
      name: 'num_cedula_habitabilidad',
      readOnly: true,
    },
    GREMI: {
      fromData: (data) => ({
        value: data['gremio_name'],
      }),
      title: txt('empresa_instaladora.gremio.title'),
      name: 'gremio',
      readOnly: true,
    },
    REFERENCIA_CATASTRAL: {
      fromData: (data) => ({
        value: data['referencia_catastral'],
      }),
      title: txt('informacion.referencia_catastral.title'),
      name: 'referencia_catastral',
      readOnly: true,
    },
  }
}

const getNonCaptablesValidationSpecificAppFields = (
  combos,
  intl,
  txt,
  fields,
  onChange,
  application
) => {
  fields.MERCADO = {
    componentType: 'select',
    fullWidth: true,
    fromData: (data) => ({
      value: data['mercado'],
      values: combos['tipo_mercado'] ? combos['tipo_mercado'].data : [],
    }),
    title: txt('informacion.mercado.title'),
    name: 'mercado',
    onChange,
    readOnly: !ESTADO_FIELD_INITIAL_STATES[application['estado']],
  }
  fields.NUMERO_POLIZA = {
    fromData: (data) => ({
      value: data['cod_solicitud_zeus'],
    }),
    title: txt('informacion.poliza_scr.title'),
    name: 'cod_solicitud_zeus',
    inputProps: { maxLength: 18 },
    onChange,
    readOnly: !ESTADO_FIELD_INITIAL_STATES[application['estado']],
  }
  fields.ESTADO = {
    componentType: 'select',
    fullWidth: true,
    fromData: (data) => ({
      value: data['estado'],
      values:
        data['subtipo_solicitud'] === 'pdte_normalizar_direccion'
          ? ESTADO_FIELD_INITIAL_STATES[data.estado]
            ? combos['estados_pdte_sspp']?.data.filter(({ key }) => key !== '90') || []
            : combos['estados_pdte_sspp']?.data || []
          : combos['estados_scr_sspp']?.data || [],
    }),
    title: txt('informacion.estado_scr.title'),
    name: 'estado',
    onChange,
    readOnly:
      !ESTADO_FIELD_INITIAL_STATES[application['estado']] &&
      (!application.estado_certificado || application.estado_certificado !== 'Creado'),
  }
  fields.FECHA_CREACION = {
    fromData: (data) => ({
      value: data['fecha_solicitud'] ? intl.formatDate(data['fecha_solicitud']) : null,
    }),
    title: txt('informacion.fecha_solicitud.title'),
    name: 'fecha_solicitud',
    onChange,
    readOnly: true,
  }
  fields.FECHA_MODIFICACION = {
    fromData: (data) => ({
      value: data['dg_ts_update'] ? intl.formatDate(data['dg_ts_update']) : null,
    }),
    title: txt('informacion.fecha_modificacion.title'),
    name: 'fecha_modificacion',
    onChange,
    readOnly: true,
  }
  fields.FECHA_CERTIFICADO = {
    fromData: (data) => ({
      value: '--',
    }),
    title: txt('informacion.fecha_certificado.title'),
    name: 'fecha_certificado',
    onChange,
    readOnly: true,
  }
  fields.DOCUMENTO = {
    fromData: (data) => ({
      value: combos['tipo_documento_sspp']
        ? combos['tipo_documento_sspp'].data?.filter(
            (doc) => doc.key === data['subtipo_solicitud']
          )[0]?.value
        : '--',
    }),
    title: txt('informacion.documento.title'),
    name: 'documento',
    onChange,
    readOnly: true,
  }
  fields.OBSERVACIONES_CAMBIO_ESTADO = {
    fromData: (data) => ({
      value: data['observaciones_cambio_estado'],
    }),
    multiline: true,
    rows: 3,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    title: txt('informacion.observaciones_cambio_estado.title'),
    name: 'observaciones_cambio_estado',
    placeholder: intl.formatMessage(
      { id: 'pages.supervision.edit.informacion.observaciones.placeholder' },
      { maxLength: TEXT_AREA_LENGTH }
    ),
    readOnly: CANT_EDIT_COMMENTS_STATES[application.estado],
    onChange,
  }
  fields.OBSERVACIONES = {
    fromData: (data) => ({
      value: data['observaciones'],
    }),
    multiline: true,
    rows: 3,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    title: txt('informacion.observaciones.title'),
    name: 'observaciones',
    placeholder: intl.formatMessage(
      { id: 'pages.supervision.edit.informacion.observaciones.placeholder' },
      { maxLength: TEXT_AREA_LENGTH }
    ),
    readOnly: true,
  }
  return fields
}

const getNonCaptablesValidationSpecificCertificateFields = (
  combos,
  intl,
  txt,
  fields,
  onChange,
  application
) => {
  fields.MERCADO = {
    componentType: 'select',
    fullWidth: true,
    fromData: (data) => ({
      value: data['mercado'],
      values: combos['tipo_mercado'] ? combos['tipo_mercado'].data : [],
    }),
    title: txt('informacion.mercado.title'),
    name: 'mercado',
    onChange,
    readOnly: application.estado_certificado !== 'Creado',
  }
  fields.NUMERO_POLIZA = {
    fromData: (data) => ({
      value: data['cod_solicitud_zeus'],
    }),
    title: txt('informacion.poliza_scr.title'),
    name: 'cod_solicitud_zeus',
    inputProps: { maxLength: 18 },
    onChange,
    readOnly: application.estado_certificado !== 'Creado',
  }
  fields.ESTADO = {
    componentType: 'select',
    fullWidth: true,
    fromData: (data) => ({
      value: data.estado_certificado,
      values: combos['filtro_estados_sspp'] ? combos['filtro_estados_sspp'].data : [],
    }),
    title: txt('informacion.estado_certificado.title'),
    name: 'estado_certificado',
    onChange,
    readOnly: application.estado_certificado !== 'Creado',
  }
  fields.FECHA_CREACION = {
    fromData: (data) => ({
      value: data['selectedCertificateCreationDate']
        ? intl.formatDate(data['selectedCertificateCreationDate'])
        : null,
    }),
    title: txt('informacion.fecha_solicitud.title'),
    name: 'fecha_solicitud',
    readOnly: true,
  }
  fields.FECHA_MODIFICACION = {
    fromData: (data) => ({
      value: data['selectedCertificateModificationDate']
        ? intl.formatDate(data['selectedCertificateModificationDate'])
        : null,
    }),
    title: txt('informacion.fecha_modificacion.title'),
    name: 'fecha_modificacion',
    readOnly: true,
  }
  fields.FECHA_CERTIFICADO = {
    fromData: (data) => ({
      value: data['selectedCertificateDate']
        ? intl.formatDate(data['selectedCertificateDate'])
        : null,
    }),
    title: txt('informacion.fecha_certificado.title'),
    name: 'fecha_certificado',
    readOnly: true,
  }
  fields.DOCUMENTO = {
    fromData: (data) => ({
      value: data['tipoDocumento'],
    }),
    title: txt('informacion.documento.title'),
    name: 'documento',
    readOnly: true,
  }
  fields.OBSERVACIONES_CAMBIO_ESTADO = {
    fromData: (data) => ({
      value: data['observaciones_cambio_estado'],
    }),
    multiline: true,
    rows: 3,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    title: txt('informacion.observaciones_cambio_estado.title'),
    name: 'observaciones_cambio_estado',
    placeholder: intl.formatMessage(
      { id: 'pages.supervision.edit.informacion.observaciones.placeholder' },
      { maxLength: TEXT_AREA_LENGTH }
    ),
    // readOnly: !(
    //   application.estado_certificado === 'Creado' ||
    //   (application.estado_certificado === 'Tramitado' &&
    //     !CANT_EDIT_COMMENTS_STATES[application.estado])
    // ),
    readOnly:
      CANT_EDIT_COMMENTS_STATES[application.estado] || application.estado_certificado === 'Anulado',
    onChange,
  }
  fields.OBSERVACIONES = {
    fromData: (data) => ({
      value: data['observaciones'],
    }),
    multiline: true,
    rows: 3,
    inputProps: { maxLength: TEXT_AREA_LENGTH },
    title: txt('informacion.observaciones.title'),
    name: 'observaciones',
    placeholder: intl.formatMessage(
      { id: 'pages.supervision.edit.informacion.observaciones.placeholder' },
      { maxLength: TEXT_AREA_LENGTH }
    ),
    readOnly: true,
  }
  return fields
}

export const NON_CAPTABLES_VALIDATION_FORM_CONFIG = ({ combos, intl, onChange, application }) => {
  const txt = (extension) =>
    intl.formatMessage({ id: ROOT_NONCAPTABLESVALIDATION_FORM + '.' + extension })
  const commonFields = getNonCaptablesValidationCommonFields(
    combos,
    intl,
    txt,
    onChange,
    application
  )
  // Add specific fields
  const allFields = getNonCaptablesValidationSpecificAppFields(
    combos,
    intl,
    txt,
    commonFields,
    onChange,
    application
  )
  return allFields
}

export const NON_CAPTABLES_VALIDATION_CERTIFICATE_FORM_CONFIG = ({
  combos,
  intl,
  onChange,
  application,
}) => {
  const txt = (extension) =>
    intl.formatMessage({ id: ROOT_NONCAPTABLESVALIDATION_FORM + '.' + extension })
  const commonFields = getNonCaptablesValidationCommonFields(
    combos,
    intl,
    txt,
    onChange,
    application
  )
  // Add specific fields
  const allFields = getNonCaptablesValidationSpecificCertificateFields(
    combos,
    intl,
    txt,
    commonFields,
    onChange,
    application
  )
  return allFields
}

export const CERTIFICATE_TYPE = {
  IRG1: 'IRG1',
  IRG2: 'IRG2',
  IRG3: 'IRG3',
  CM: 'CM',
}

export const GRID_ITEM_EXTRA_SMALL = {
  item: true,
  xs: 6,
  sm: 6,
  md: 2,
}
export const GRID_ITEM_SMALL = {
  item: true,
  xs: 6,
  sm: 6,
  md: 3,
}
export const GRID_ITEM_LARGE = {
  item: true,
  xs: 12,
  sm: 12,
  md: 6,
}

export const NON_CAPTABLES_VALIDATION_FORM_STRUCTURE = ({ intl }) => {
  //Item structure: Blocks > Rows on max size > Items,
  //Use prepend and append properties to add content at the top/bottom of blocks
  //Leave empty rows to indicate a <hr/> division at that point
  const { formatMessage } = intl
  const txt = (extension) =>
    formatMessage({ id: ROOT_NONCAPTABLESVALIDATION_FORM + '.' + extension })
  return [
    {
      title: txt('informacion.title'),
      panel: 'informacion',
      items: [
        [
          { ref: 'MERCADO', conf: GRID_ITEM_SMALL },
          { ref: 'TIPO_PETICION', conf: GRID_ITEM_SMALL },
          { ref: 'ESTADO', conf: GRID_ITEM_SMALL },
          { ref: 'ESTADO_SOLICITUD', conf: GRID_ITEM_SMALL },
          { ref: 'NUMERO_POLIZA', conf: GRID_ITEM_SMALL },
          { ref: 'CUPS', conf: GRID_ITEM_SMALL },
          { ref: 'CIDI', conf: GRID_ITEM_SMALL },
          { ref: 'REFERENCIA_CATASTRAL', conf: GRID_ITEM_SMALL },
        ],
        [
          { ref: 'OBSERVACIONES_CAMBIO_ESTADO', conf: GRID_ITEM_LARGE },
          { ref: 'OBSERVACIONES', conf: GRID_ITEM_LARGE },
        ],
        [],
        [
          { ref: 'FECHA_CREACION', conf: GRID_ITEM_SMALL },
          { ref: 'FECHA_CERTIFICADO', conf: GRID_ITEM_SMALL },
          { ref: 'USUARIO_GRABADOR', conf: GRID_ITEM_SMALL },
          { ref: 'FECHA_MODIFICACION', conf: GRID_ITEM_SMALL },
        ],
        [],
        [
          { ref: 'EMPRESA', conf: GRID_ITEM_SMALL },
          { ref: 'DOCUMENTO', conf: GRID_ITEM_SMALL },
        ],
        [],
        [
          { ref: 'PUNTOS_SUMINISTRO_POTENCIALES', conf: GRID_ITEM_SMALL },
          { ref: 'PUNTOS_SUMINISTRO_INTERESADOS', conf: GRID_ITEM_SMALL },
          { ref: 'SOLICITUD_ID', conf: GRID_ITEM_SMALL },
          { ref: 'CODIGO_FINANCIACION', conf: GRID_ITEM_SMALL },
          { ref: 'NUMERO_TARIFA_APLICADA', conf: GRID_ITEM_SMALL },
        ],
      ],
    },
    {
      title: txt('datos_cliente.title'),
      items: [
        [
          { ref: 'NOMBRE_CLIENTE', conf: GRID_ITEM_SMALL },
          { ref: 'USUARIO_IDENTIFICADOR', conf: GRID_ITEM_SMALL },
          { ref: 'USUARIO_EMAIL', conf: GRID_ITEM_SMALL },
          { ref: 'TELEFONO_MOVIL', conf: GRID_ITEM_SMALL },
          { ref: 'TYPE_CLIENT', conf: GRID_ITEM_SMALL },
          { ref: 'DIRECCION', conf: GRID_ITEM_SMALL },
          { ref: 'MUNICIPIO', conf: GRID_ITEM_SMALL },
          { ref: 'PROVINCIA', conf: GRID_ITEM_SMALL },
        ],
      ],
    },
    {
      title: txt('empresa_instaladora.title'),
      panel: 'empresa_instaladora',
      items: [
        [
          { ref: 'SAP', conf: GRID_ITEM_SMALL },
          { ref: 'NIF', conf: GRID_ITEM_SMALL },
          { ref: 'USERNAME', conf: GRID_ITEM_SMALL },
          { ref: 'EEII', conf: GRID_ITEM_SMALL },
        ],
        [],
        [
          { ref: 'MOVIL_EEII', conf: GRID_ITEM_SMALL },
          { ref: 'SCR', conf: GRID_ITEM_SMALL },
          { ref: 'MAIL_EEII', conf: GRID_ITEM_SMALL },
          { ref: 'GREMI', conf: GRID_ITEM_SMALL },
        ],
        [],
        [{ ref: 'NUM_CEDULA_HABITABILIDAD', conf: GRID_ITEM_SMALL }],
      ],
    },
  ]
}
