import React from 'react'

// import { ReactComponent as AssignPendingIcon } from '../../../resources/images/icon_assign_pending.svg'
import { ReactComponent as ClientPendingIcon } from '../../../resources/images/icon_client_pending.svg'
import { ReactComponent as ProcessingIcon } from '../../../resources/images/icon_processing.svg'
import { ReactComponent as CancelPendingIcon } from '../../../resources/images/icon_cancel_pending.svg'
import { ReactComponent as BudgetIcon } from '../../../resources/images/icon_budget.svg'
import { ReactComponent as WorkingIcon } from '../../../resources/images/icon_working.svg'
// import { ReactComponent as CancelledOrderIcon } from '../../../resources/images/icon_cancelled_order.svg'
// import { ReactComponent as ConfirmPendingIcon } from '../../../resources/images/icon_confirm_pending.svg'
// import { ReactComponent as AlertIcon } from '../../../resources/images/icon_alert.svg'

export const MA_ITEMS_GESTOR = (userId) => [
  // {
  //   id: 'SR',
  //   title: 'pages.controlPanel.cards.1.title',
  //   icon: <ProcessingIcon />,
  //   path: '/sr',
  //   searchParams: {},
  // },
  {
    id: 'en_elaboracion_presupuesto',
    title: 'pages.controlPanel.cards.3.title',
    icon: <BudgetIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '20|25',
    },
  },
  {
    id: 'presupuesto_enviado',
    title: 'pages.controlPanel.cards.4.title',
    icon: <ClientPendingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '30|35',
    },
  },
  {
    id: 'pendiente_modificacion',
    title: 'pages.controlPanel.cards.5.title',
    icon: <CancelPendingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '00',
    },
  },
  {
    id: 'pendiente_validar_pago',
    title: 'pages.controlPanel.cards.8.title',
    icon: <ProcessingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '36',
    },
  },
  {
    id: 'pendiente_encargo_obra',
    title: 'pages.controlPanel.cards.6.title',
    icon: <WorkingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '37',
    },
  },
  // {
  //   id: 'encargo_rechazado',
  //   title: 'pages.controlPanel.cards.7.title',
  //   icon: <CancelledOrderIcon />,
  //   path: '/applications',
  //   searchParams: {
  //     gestor_id: userId,
  //     solicitud_estado: '04',
  //   },
  // },
  // {
  //   id: 'comunicaciones_pendientes',
  //   title: 'pages.controlPanel.cards.9.title',
  //   icon: <AlertIcon />,
  //   path: '/applications',
  //   searchParams: {
  //     gestor_id: userId,
  //     comunicaciones_pendientes: true,
  //   },
  // },
]

export const MA_ITEMS_DELEGADO = (userId) => [
  // {
  //   id: 'SR',
  //   title: 'pages.controlPanel.cards.1.title',
  //   icon: <ProcessingIcon />,
  //   path: '/sr',
  //   searchParams: {},
  // },
  // {
  //   id: 'sin_asignar_gestor',
  //   title: 'pages.controlPanel.cards.2.title',
  //   icon: <AssignPendingIcon />,
  //   path: '/applications',
  //   searchParams: {
  //     gestor_id: userId,
  //     solicitud_estado: '15',
  //   },
  // },
  {
    id: 'en_elaboracion_presupuesto',
    title: 'pages.controlPanel.cards.3.title',
    icon: <BudgetIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '20|25',
    },
  },
  {
    id: 'presupuesto_enviado',
    title: 'pages.controlPanel.cards.4.title',
    icon: <ClientPendingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '30|35',
    },
  },
  {
    id: 'pendiente_validar_pago',
    title: 'pages.controlPanel.cards.8.title',
    icon: <ProcessingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '36',
    },
  },
  {
    id: 'pendiente_modificacion',
    title: 'pages.controlPanel.cards.5.title',
    icon: <CancelPendingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '00',
    },
  },
  {
    id: 'pendiente_encargo_obra',
    title: 'pages.controlPanel.cards.6.title',
    icon: <WorkingIcon />,
    path: '/applications',
    searchParams: {
      gestor_id: userId,
      solicitud_estado: '37',
    },
  },
  // {
  //   id: 'encargo_rechazado',
  //   title: 'pages.controlPanel.cards.7.title',
  //   icon: <CancelledOrderIcon />,
  //   path: '/applications',
  //   searchParams: {
  //     gestor_id: userId,
  //     solicitud_estado: '04',
  //   },
  // },
  // {
  //   id: 'comunicaciones_pendientes',
  //   title: 'pages.controlPanel.cards.9.title',
  //   icon: <AlertIcon />,
  //   path: '/applications',
  //   searchParams: {
  //     gestor_id: userId,
  //     comunicaciones_pendientes: true,
  //   },
  // },
]
