import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'

export const RollbackAppBtn = () => {
  const { formatMessage } = useIntl()
  const { application, rollbackApplication } = useSirContext()

  const [loading, setLoading] = useState(false)

  const handleCreateBudget = () => {
    setLoading(true)
    rollbackApplication(application.id)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  return (
    <ThemeButton color="primary" loading={loading} disabled={loading} onClick={handleCreateBudget}>
      {formatMessage({ id: 'pages.sir.detail.button.rollbackApp.label' })}
    </ThemeButton>
  )
}
