import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { ResendBudgetConfirmDialog } from '../dialogs'
import { SIR_STATES } from '../../constants'
import { CAN_SEND_BUDGET_STATES } from './form-action-btns.constants'

export const SendBudgetBtn = () => {
  const {
    actions: { open },
  } = useModalContext()
  const { formatMessage } = useIntl()
  const { application, sendBudget, editMode, generate_budget_loading } = useSirContext()

  const [loading, setLoading] = useState(false)

  const sendBudgetLogic = () => {
    const payload = {
      // nombre: application['usuario_sir']['nombre_cliente'],
      solicitud_id: application['id'],
      // presupuesto_id: application['presupuesto_sir']['id'],
      // mail_cliente: application['usuario_sir']['email_cliente'],
    }
    setLoading(true)
    sendBudget(payload)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleSendBudget = () => {
    if (application['estado'] === SIR_STATES.BUDGET_SENDED.KEY) {
      open({
        Component: ResendBudgetConfirmDialog,
        data: { sendBudget, application },
      })
    } else {
      sendBudgetLogic()
    }
  }

  const disabled = editMode || !CAN_SEND_BUDGET_STATES[application['estado']]

  return (
    <ThemeButton color="primary" loading={loading } disabled={disabled || generate_budget_loading} loadingShowText={true} onClick={handleSendBudget}>
      {formatMessage({ id: 'pages.sir.detail.button.sendBudget.label' })}
    </ThemeButton>
  )
}
