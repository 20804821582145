export const cpCardStyles = (theme) => ({
  container: {
    minWidth: 304,
    maxWidth: 304,
    height: 136,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
    borderTopRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
})
