import { useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { feedback } from '../../../core/feedback'
import { useIPCalls } from './ip-calls.hook'
import { ipReducer } from '../reducers'
import { IP_INITIAL_STATE, IP_ACTIONS_TYPES, IP_VALIDATED_STATE } from '../reducers'
import { IP_VALIDATED_DELIVERY_FAILED_STATE } from '../components/form/ip-form.constants'
import { prepareIPDto } from '../utils'
import { TextInput, DateInput, NumberInput, SelectInput, CheckListInput } from '../../../ui'
import { FileUploaderInput } from '../../../ui'
import { IPAddressesViewer, IPAIValidations } from '../components'
import { GRID_ITEM, IP_REJECTED_STATES, IP_CAN_VIEW_AI_VALIDATIONS_STATES } from '../components'
import { useCombos } from '../../combos'

export const useIPReducer = () => {
  const [state, dispatch] = useReducer(ipReducer, IP_INITIAL_STATE)
  const history = useHistory()
  const { formatMessage: fm } = useIntl()
  const { getIIPPCall, getIPCall, putIPCall, putIPStateCall, putRejectIPCall } = useIPCalls()
  const { putResolveIssueCall, putSendToZeusCall, getIPEventsCall } = useIPCalls()
  const combos = useCombos([])

  const handleChangeForm = useCallback(
    (e) => {
      dispatch({
        type: IP_ACTIONS_TYPES.SET_FORM_STATE,
        payload: { ...state.formState, [e.target.name]: e.target.value },
      })
    },
    [state.formState]
  )

  const redirectToTable = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/ip' + prevSearch)
  }

  const getTableData = useCallback(
    (search) => {
      const config = { params: search }
      dispatch({
        type: IP_ACTIONS_TYPES.SET_LOADING,
      })
      getIIPPCall(config)
        .then(({ data }) => {
          dispatch({
            type: IP_ACTIONS_TYPES.SET_TABLE_DATA,
            payload: {
              data: data['registros'],
              search: search,
              pagination: {
                total_paginas: data['total_paginas'],
                total_registros: data['total_registros'],
              },
            },
          })
        })
        .catch(() => {
          dispatch({
            type: IP_ACTIONS_TYPES.SET_FAILURE,
          })
        })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getIP = useCallback(
    (id) => {
      new Promise((resolve, reject) => {
        dispatch({
          type: IP_ACTIONS_TYPES.SET_DETAIL_LOADING,
        })
        getIPCall(id)
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector?.nombre || '',
                categoria_instl: data.inspector?.categoria || '',
                cif_empresa: data.empresa?.cif || '',
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const editIP = useCallback(
    (formState) => {
      const { id, ...restFormState } = formState
      const payload = prepareIPDto(restFormState)
      return new Promise((resolve, reject) => {
        putIPCall(id, payload)
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: data,
            })
            return data
          })
          .then((data) => resolve(data))
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_DETAIL_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const validateIP = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putIPStateCall(id, IP_VALIDATED_STATE)
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector.nombre,
                categoria_instl: data.inspector.categoria,
                cif_empresa: data.empresa.cif,
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const rejectIP = useCallback(
    (id, rejectReason) => {
      return new Promise((resolve, reject) => {
        putRejectIPCall(id, { observaciones_rechazo: rejectReason })
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector.nombre,
                categoria_instl: data.inspector.categoria,
                cif_empresa: data.empresa.cif,
              },
            })
          })
          .then(() => resolve())
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const resolveIssue = useCallback(
    (id, formState) => {
      return new Promise((resolve, reject) => {
        putResolveIssueCall(id, formState)
          .then(() => resolve({ redirectToTable }))
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const loadOnZeusIP = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putIPStateCall(id, '41')
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector?.nombre || '',
                categoria_instl: data.inspector?.categoria || '',
                cif_empresa: data.empresa?.cif || '',
              },
            })
            resolve({ redirectToTable })
          })
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const sendToZeusIP = useCallback(
    (id) => {
      return new Promise((resolve, reject) => {
        putSendToZeusCall(id)
          .then(({ data }) => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP,
              payload: {
                ...data,
                nombre_instl: data.inspector?.nombre || '',
                categoria_instl: data.inspector?.categoria || '',
                cif_empresa: data.empresa?.cif || '',
              },
            })
            if (data.estado === IP_VALIDATED_DELIVERY_FAILED_STATE) {
              feedback('info', 'Se ha vuelto a enviar a Zeus pero el envío ha fallado de nuevo')
            }
            resolve({ redirectToTable })
          })
          .catch(() => {
            dispatch({
              type: IP_ACTIONS_TYPES.SET_FAILURE,
            })
            return reject('Error')
          })
      })
    },
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getIPEvents = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getIPEventsCall(id)
          .then(({ data }) => {
            const formattedData = data
              .map((record) => {
                let responsable = ''
                if (record.tipo_usuario) {
                  responsable = fm({
                    id: `pages.ip.detail.events.responsable.${record.tipo_usuario}`,
                  })
                }
                return {
                  ...record,
                  responsable: `${
                    record.gestor || record.instalador || record.automatico || '--'
                  } ${responsable ? '(' + responsable + ')' : ''}`,
                  mensaje: `${record.mensaje} ${
                    record.descripcion ? `(${record.descripcion})` : ''
                  }`,
                }
              })
              .sort((a, b) => new Date(b.dg_ts_insert) - new Date(a.dg_ts_insert))
            dispatch({
              type: IP_ACTIONS_TYPES.SET_IP_EVENTS,
              payload: formattedData,
            })
          })
          .then(() => resolve())
          .catch((error) => reject(error))
      }),
    [dispatch] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const setSelectedTableRows = useCallback(
    (rows) => {
      dispatch({
        type: IP_ACTIONS_TYPES.SET_TABLE_SELECTION,
        payload: rows,
      })
    },
    [dispatch, state] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onClickTableRow = (id) => {
    const props = {
      pathname: `/ip/${id}`,
      state: { prevSearch: history.location.search },
    }
    return history.push(props)
  }

  const inputsConfig = useCallback(
    () => {
      const data = state.formState
      const txt = (extension) => fm({ id: 'pages.ip.detail.fields.' + extension + '.title' })
      const DEFAULT_PROPS = { onChange: handleChangeForm, readOnly: true, required: true }
      return {
        TIPO_INSTALACION: {
          ...DEFAULT_PROPS,
          component: SelectInput,
          value: data['tipo_instalacion'],
          values: combos.tipo_instalacion?.data || [],
          title: txt('tipo_instalacion'),
          name: 'tipo_instalacion',
        },
        UNE: {
          ...DEFAULT_PROPS,
          component: SelectInput,
          values: combos.une?.data || [],
          value: data['une'],
          title: txt('une'),
          name: 'une',
        },
        TIPO_GAS: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['tipo_gas'],
          title: txt('tipo_gas'),
          name: 'tipo_gas',
        },
        CIF_EMPRESA: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['cif_empresa'],
          title: txt('cif_empresa'),
          name: 'cif_empresa',
        },
        ESTADO: {
          ...DEFAULT_PROPS,
          component: SelectInput,
          values: combos.estados_inspeccion?.data || [],
          value: data['estado'],
          title: txt('estado'),
          name: 'estado',
        },
        OBSERVACIONES_RECHAZO: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['observaciones_rechazo'],
          title: txt('observaciones_rechazo'),
          name: 'observaciones_rechazo',
          hidden: !(data['observaciones_rechazo']?.length && IP_REJECTED_STATES[data['estado']]),
        },
        INSTALADOR_NOMBRE: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['nombre_instl'],
          title: txt('nombre_instl'),
          name: 'nombre_instl',
        },
        INSTALADOR_CATEGORIA: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['categoria_instl'],
          title: txt('categoria_instl'),
          name: 'categoria_instl',
        },
        RESULTADO: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['resultado'],
          values: combos.resultado_inspeccion?.data || [],
          title: txt('resultado'),
          name: 'resultado',
        },
        FECHA_INSPECCION: {
          ...DEFAULT_PROPS,
          component: DateInput,
          value: data['fecha_inspeccion'],
          title: txt('fecha_inspeccion'),
          name: 'fecha_inspeccion',
        },
        FECHA_CREACION: {
          ...DEFAULT_PROPS,
          component: DateInput,
          value: data['fecha_creacion'],
          title: txt('fecha_creacion'),
          name: 'fecha_creacion',
        },
        ANOMALIAS: {
          ...DEFAULT_PROPS,
          component: CheckListInput,
          values: data.anomalias?.map((a) => ({ key: a.id, value: a.tipo_anomalia })) || [],
          value: data.anomalias?.map((a) => ({ key: a.id, value: a.tipo_anomalia })) || [],
          comboId: 'anomalias',
          title: txt('anomalias'),
          name: 'anomalias',
        },
        NUMERO_FACTURA: {
          ...DEFAULT_PROPS,
          component: TextInput,
          value: data['numero_fra'],
          title: txt('numero_fra'),
          name: 'numero_fra',
        },
        FECHA_FACTURA: {
          ...DEFAULT_PROPS,
          component: DateInput,
          value: data['fecha_fra'],
          title: txt('fecha_fra'),
          name: 'fecha_fra',
        },
        IMPORTE_FACTURA: {
          ...DEFAULT_PROPS,
          component: NumberInput,
          value: data['importe_fra'],
          title: txt('importe_fra'),
          name: 'importe_fra',
          units: '€',
        },
        CERTIFICADO: {
          ...DEFAULT_PROPS,
          component: FileUploaderInput,
          title: txt('certificado_ip'),
          name: 'certificado_ip',
          currentDoc: data['certificado_ip'],
          fileProps: {
            maxSize: 10000000, // 10Mb
            accept: ['application/pdf'],
          },
        },
        FACTURA: {
          ...DEFAULT_PROPS,
          component: FileUploaderInput,
          title: txt('factura_ip'),
          name: 'factura_ip',
          currentDoc: data['factura_ip'],
          fileProps: {
            maxSize: 10000000, // 10Mb
            accept: ['application/pdf'],
          },
        },
        DIRECCIONES: {
          component: IPAddressesViewer,
          addresses: data['direcciones'] || [],
          instType: data['tipo_instalacion'],
        },
        IA_VALIDATIONS: {
          component: IPAIValidations,
          hidden:
            !IP_CAN_VIEW_AI_VALIDATIONS_STATES[state.ip.estado] || !state.ip.ai_validations?.length,
        },
      }
    },
    [state.formState, fm, handleChangeForm, combos] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const formConfig = [
    {
      fields: [
        { ref: 'TIPO_INSTALACION', conf: GRID_ITEM.LARGE },
        { ref: 'UNE', conf: GRID_ITEM.LARGE },
        { ref: 'TIPO_GAS', conf: GRID_ITEM.LARGE },
        { ref: 'FECHA_CREACION', conf: GRID_ITEM.LARGE },
        { ref: 'ESTADO', conf: GRID_ITEM.LARGE },
        { ref: 'OBSERVACIONES_RECHAZO', conf: GRID_ITEM.LARGE },
        { ref: 'IA_VALIDATIONS', conf: GRID_ITEM.FULL },
      ],
      divider: true,
    },
    {
      title: fm({ id: 'pages.ip.detail.gridForm.title.1' }),
      fields: [
        { ref: 'CIF_EMPRESA', conf: GRID_ITEM.LARGE },
        { ref: 'INSTALADOR_NOMBRE', conf: GRID_ITEM.LARGE },
        { ref: 'INSTALADOR_CATEGORIA', conf: GRID_ITEM.LARGE },
      ],
      divider: true,
    },
    {
      title: fm({ id: 'pages.ip.detail.gridForm.title.2' }),
      fields: [
        { ref: 'DIRECCIONES', conf: GRID_ITEM.FULL },
        { ref: 'FECHA_INSPECCION', conf: GRID_ITEM.LARGE },
        { ref: 'CERTIFICADO', conf: GRID_ITEM.FULL },
      ],
      divider: true,
    },
    {
      title: fm({ id: 'pages.ip.detail.gridForm.title.3' }),
      fields: [
        { ref: 'NUMERO_FACTURA', conf: GRID_ITEM.LARGE },
        { ref: 'FECHA_FACTURA', conf: GRID_ITEM.LARGE },
        { ref: 'IMPORTE_FACTURA', conf: GRID_ITEM.FULL },
        { ref: 'FACTURA', conf: GRID_ITEM.FULL },
      ],
    },
  ]

  return {
    setSelectedTableRows,
    onClickTableRow,
    getTableData,
    getIP,
    editIP,
    validateIP,
    rejectIP,
    resolveIssue,
    sendToZeusIP,
    loadOnZeusIP,
    getIPEvents,
    inputsConfig,
    formConfig,
    ...state,
  }
}
