import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { ThemeButton, TextInput } from '../../../../ui'

const TEXT_AREA_LENGTH = 250

export const RejectJCADialog = ({ close, jca, rejectJCA }) => {
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const [reason, setReason] = useState('')

  const handleSubmit = () => {
    setLoading(true)
    rejectJCA(jca.id, reason)
      .then(() => {
        setLoading(false)
        close()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleChangeReason = (e) => {
    const {
      target: { value },
    } = e
    setReason(value)
  }

  return (
    <Box p={2}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {formatMessage({ id: 'pages.jca.detail.rejectDialog.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'pages.jca.detail.rejectDialog.description.1' })}
        </Typography>
        <Typography variant="body1" paragraph>
          {formatMessage({ id: 'pages.jca.detail.rejectDialog.description.2' })}
        </Typography>
        <Box>
          <TextInput
            required={false}
            name="reason"
            value={reason}
            placeholder={`Máx. ${TEXT_AREA_LENGTH} caracteres`}
            multiline={true}
            onChange={handleChangeReason}
            rows={4}
            rowsMax={4}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-around" flexWrap="wrap" p={1}>
          <Box width={150} mb={[3, 0]}>
            <ThemeButton onClick={close} color="inherit" fullWidth disabled={loading}>
              {formatMessage({ id: 'global.cancel' })}
            </ThemeButton>
          </Box>
          <Box width={150}>
            <ThemeButton
              onClick={handleSubmit}
              color="primary"
              fullWidth
              disabled={loading}
              loading={loading}
            >
              {formatMessage({ id: 'global.accept' })}
            </ThemeButton>
          </Box>
        </Box>
      </DialogActions>
    </Box>
  )
}
