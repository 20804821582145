import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core'

import { CollapsibleTable } from '../../../../ui'
import { APP_EVENTS_TABLE_CONFIG } from './events-table.constants'
import { useAppContext } from '../../contexts'

export const AppEventsTable = () => {
  const intl = useIntl()
  const { application, getAppEvents, events } = useAppContext()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getAppEvents()
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [application]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  }

  if (!events.length) {
    return (
      <Typography>
        {intl.formatMessage({ id: 'pages.application.detail.history.emptyList' })}
      </Typography>
    )
  }

  const renderEventDetails = (event) => (
    <Box margin={1}>
      {event.fields && event.fields.length > 0 ? (
        <TableContainer component={Paper} style={{ padding: '16px' }}>
          <Table size="small" aria-label="details">
            <TableHead>
              <TableRow>
                <TableCell align="center"><strong>{intl.formatMessage({ id: 'pages.application.detail.history.field' })}</strong></TableCell>
                <TableCell align="center"><strong>{intl.formatMessage({ id: 'pages.application.detail.history.oldValue' })}</strong></TableCell>
                <TableCell align="center"><strong>{intl.formatMessage({ id: 'pages.application.detail.history.newValue' })}</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {event.fields.map((field, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{field.field_name}</TableCell>
                  <TableCell align="center">{field.old_value}</TableCell>
                  <TableCell align="center">{field.new_value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Box>
  )

  return (
    <CollapsibleTable
      config={APP_EVENTS_TABLE_CONFIG(intl)}
      data={events}
      renderDetails={renderEventDetails}
    />
  )
}


// http://localhost:3000/#!/applications/4319 <--- Sonia issue
// 4175 TESTING FUNCIONA BIEN
// las que yo he visto son de mercado GC y PYME, creadas desde WP (se duplica 1 vez) y creadas desde gestor GC (se duplican 2 o 3)