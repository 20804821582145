import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { MarketersDashboard } from '../../../features/marketers'
import { MarketerDetail } from '../../../features/marketers/components/detail/marketers-detail.component'

export const MarketersPage = () => {
  return (
    <Switch>
      <Route exact path="/marketers" component={MarketersDashboard} />
      <Route exact path="/marketers/:marketerId" component={MarketerDetail} />
      <Redirect from="*" to="/marketers" />
    </Switch>
  )
}
