import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import { useCampaignsContext, CampaignsEditForm } from '../../../../features/campaigns'
import { CAMPAIGNS_COMBOS_IDS } from '../../constants'
import { useCombos } from '../../../../features/combos/hooks'
import { PageDisplayer } from '../../../../ui'

export const CampaignsDetailPage = () => {
  const history = useHistory()
  const { campaignId } = useParams()
  useCombos(CAMPAIGNS_COMBOS_IDS)
  const { formatMessage } = useIntl()
  const { detailLoading, detailError, getCampaign, campaign } = useCampaignsContext()

  useEffect(() => {
    getCampaign(campaignId)
  }, [campaignId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const prevSearch = history.location.state?.prevSearch || ''
    history.push('/campaigns' + prevSearch)
  }

  return (
    <PageDisplayer
      maxWidth="md"
      loading={detailLoading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.campaigns.detail.goBack' })}
    >
      {detailError ? (
        formatMessage({ id: 'pages.campaigns.detail.error' }, { campaignId })
      ) : (
        <>
          <Box mb={2}>
            <Typography variant="h4" color="textSecondary">
              {campaign['nombre'] || '{Nombre de la campaña}'}
            </Typography>
          </Box>
          <CampaignsEditForm />
        </>
      )}
    </PageDisplayer>
  )
}
