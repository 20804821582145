export const REJECTED_BUDGETS_LIST_CONFIG = [
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const CURRENT_DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const FILE_FIELD_NAMES = [
  'IRG1',
  'IRG2',
  'IRG3',
  'AI',
  'AR',
  'CA',
  'CB',
  'CPA',
  'CC',
  'CCEE',
  'CH',
  'CP',
  'CR',
  'DN',
  'RC',
  'SG',
  'CI',
  'EF',
  'FI',
  'SP',
  'EC',
  'AC',
  'CPI',
  'FC1',
  'FC2',
  'FC3',
  'tallo_i',
  'croquis_i',
]
