export const JOBS_TABLE_COLUMNS = [
  { field: "id", width: 20,},
  { field: "name", align: "left", width: 120 },
  { field: "params", align: "left", width: 150 },
  { field: "status", align: "left", width: 100 },
  {
    field: "start_time",
    align: "left",
    type: "date",
    width: 50
  },
  { field: "end_time", align: "left", width: 50},
];

export const REPORT_TABLE_FILTERS = [
  {
    name: 'creada_desde',
    filterType: 'date',
    maxDateField: 'creada_hasta',
  },
  {
    name: 'creada_hasta',
    filterType: 'date',
    minDateField: 'creada_desde',
  },
  {
    name: "status",
    values: [
      { key: 'OK', value: 'Success' },
      { key: 'KO', value: 'Error' },
      { key: 'RUNNING', value: 'Running' },
      { key: 'NA', value: 'Sin ejecutar'}
    ],
    size: 'small',
  },
  {
    name: "batch_id",
    size: 'small',
    comboId: "batch_list",
  }
];
