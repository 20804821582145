import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { TextInput, ThemeButton } from '../../../../ui'
import { useSirContext } from '../../contexts'

const DEFAULT_STATE = { mensaje: '' }

const TEXT_AREA_LENGTH = 250

export const ApplicationCommenter = ({ model }) => {
  const { id } = model
  const { formatMessage } = useIntl()
  const { postSirEvent } = useSirContext()

  const [formState, setFormState] = useState(DEFAULT_STATE)
  const [loading, setLoading] = useState(false)

  const handleUpdate = ({ target }) => {
    const update = { [target.name]: target.value }
    setFormState((state) => ({
      ...state,
      ...update,
    }))
  }

  const handleComment = (e) => {
    e.preventDefault()
    setLoading(true)
    postSirEvent(id, formState)
      .then(() => {
        setFormState(DEFAULT_STATE)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <form
      onSubmit={handleComment}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <TextInput
        {...{
          name: 'mensaje',
          value: formState.mensaje,
          type: 'text_area',
          multiline: true,
          inputProps: { maxLength: TEXT_AREA_LENGTH },
          disabled: false,
          fullWidth: false,
          onChange: (e) => handleUpdate(e),
        }}
      />
      <div style={{ paddingLeft: 10 }}>
        <ThemeButton loading={loading} onClick={handleComment}>
          {formatMessage({ id: 'global.send' })}
        </ThemeButton>
      </div>
    </form>
  )
}

export default ApplicationCommenter
