import React from 'react'

import { PORequestsProvider } from '../../features/po-requests'
import { PORequestsRouter } from './routers'

export const PORequestsPage = () => {
  return (
    <PORequestsProvider>
      <PORequestsRouter />
    </PORequestsProvider>
  )
}
