import React, { createContext, useContext } from 'react'
import { useSirReducer } from '../hooks/sir-reducer.hook'

const SirContext = createContext()

export const SirContextProvider = ({ children }) => {
  const reducer = useSirReducer()
  return <SirContext.Provider value={reducer}>{children}</SirContext.Provider>
}

export const useSirContext = () => useContext(SirContext)
