export const INSTALLER_TABLE_COLUMNS = [
  { field: 'dg_ts_insert', align: 'right', width: 160, type: 'date' },
  {
    field: 'nombre',
    align: 'right',
    cellStyle: {
      textTransform: 'uppercase',
    },
  },
  {
    field: 'apellidos',
    align: 'left',
    cellStyle: {
      textTransform: 'uppercase',
    },
  },
  {
    field: 'estado',
    align: 'center',
    sorting: false,
  },
  {
    field: 'email',
    align: 'center',
  },
]

export const INSTALLER_TABLE_ACTIONS = [
  {
    field: 'actions',
    width: 110,
    align: 'left',
    sorting: false,
  },
]

export const INSTALLER_TABLE_FILTERS = []
