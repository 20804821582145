import { useCallback } from 'react'
import { requests } from '../../../core/ajax'

export const useManagersCalls = () => {
  const { get, putById, getById } = requests

  const getManagers = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('gestor/tabla_gestores', config)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const putManager = useCallback(
    (id, payload) =>
      new Promise((resolve, reject) => {
        putById(`/gestor`, id, payload)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getComboByRol = useCallback(
    (rol) =>
      new Promise((resolve, reject) => {
        get('combo_box?parent_id=permisos_extra', { params: { rol: rol } })
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const getManager = useCallback(
    (userId) =>
      new Promise((resolve, reject) => {
        getById('/gestor', userId)
          .then((response) => {
            return resolve(response)
          })
          .catch(({ response }) => {
            return reject(response)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getManagers,
    putManager,
    getComboByRol,
    getManager,
  }
}
