import React from 'react'
import feedback from '../../../../core/feedback'
import { ThemeButton } from '../../../../ui'
import { useAdvanceDialogs } from './advance-dialog.hooks'
import { useAppContext } from '../../contexts'

export const AdvanceButton = () => {
  const { confirmAdvance, cupsContratable } = useAdvanceDialogs()
  const { application, requestChange, getApplication } = useAppContext()
  const handleAdvanceRequest = () => {
    if (!application.pasar_contratable && !application.codigo_nap) {
      return cupsContratable(application, getApplication)
    } else {
      return confirmAdvance(application, handleAdvance)
    }
  }

  const handleAdvance = () => {
    return requestChange(application.id).then(() => {
      feedback('success', 'Cambio realizado con éxito')
    })
  }

  return (
    <ThemeButton
      color="primary"
      size="small"
      disabled={
        (application.pasar_contratable && application.estado_cups !== 'CONTRATABLE') ||
        application.estado === '10' ||
        application.estado === '90' ||
        // Limitar el uso del botón de "Pasar a Contratable" en los estados superiores a Pendiente encargo obra (37)
        // ni en el camino interior (02) ni el pendiente de certificado (40)
        application.estado >= '37' ||
        application.camino_solicitud === '02'
      }
      onClick={handleAdvanceRequest}
    >
      {!application.pasar_contratable && !application.codigo_nap
        ? 'Pasar Cups a Contratable'
        : 'Finalizar trámites de acometida'}
    </ThemeButton>
  )
}

export default AdvanceButton
