import { persistReducer } from './persist-reducer.util'
export { useWindowSize } from './useWindowSize'
export { useWidth } from './useWidth' // similar to 'useWindowSize' but with material-ui theme breakpoints
export * from './fileUtils'
export * from './dateUtils'
export * from './idDocValidation'
export * from './emailUtils'
export * from './pwdUtils'
export { loadLocalStorage, saveLocalStorage } from './localStorage'

export const usePersistReducer = persistReducer()
