import { useCallback } from 'react'
import { requests } from '../../../core/ajax'

export const useEEIICalls = () => {
  const { getById } = requests

  const getEI = useCallback(
    (id) =>
      new Promise((resolve, reject) => {
        getById('/empresa', id)
          .then((response) => resolve(response))
          .catch(({ err }) => {
            return reject(err)
          })
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return {
    getEI,
  }
}
