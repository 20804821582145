import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'
import { stringify } from 'qs'
import { TariffForm } from '../form'
import { PageDisplayer } from '../../../../ui'
import { useSelector } from 'react-redux'
import { useTariffCalls } from '../../hooks'
import { tariffStyles } from './tariff-detail.style'

export const TariffDetail = withStyles(tariffStyles)(({ classes }) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const history = useHistory()
  const { getTariff } = useTariffCalls()
  const { tariffId } = useParams()

  const [tariff, setTariff] = useState({})
  const tariffLoader = useSelector((state) => state.loader.tariffs)
  const search = tariffLoader?.config?.params

  useEffect(() => {
    getTariff(tariffId).then(({ data }) => setTariff(data))
  }, [tariffId])// eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const tariffUrl = search ? '?' + stringify(search) : '/'
    history.push(`/tariffs` + tariffUrl)
  }
  const handleGoToTariffZones = () => {
    history.push('/tariffs/' + tariffId + '/zones')
  }

  const inputProps = { formState: tariff, intl, disabled: true }

  return (
    <PageDisplayer
      loading={!tariff || !Object.keys(tariff).length}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.tariffs.edit.back' })}
    >
      <TariffForm {...{ inputProps, submit: false, goToTariffZones: handleGoToTariffZones }} />
    </PageDisplayer>
  )
})
