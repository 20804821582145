import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { tariffsFilterStyles } from './tariffs-filter.styles'
import { TARIFFS_FILTERS, TARIFFS_TABLE_COLUMNS } from '../../constants'
import { tariffsDataPreparation } from '../../logics'
import { useTariffsCalls } from '../../hooks'
import { useCombos, useCustomCombo } from '../../../combos/hooks/combos.hook'

export const TariffsFilter = withStyles(tariffsFilterStyles)(({ classes }) => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const role = useSelector((state) => state.global.role)
  const tariffsLoader = useSelector((state) => state.loader.tariffs)
  const selection = tariffsLoader?.selection
  const search = tariffsLoader?.config?.params
  const location = useLocation()
  const currentSearch = new URLSearchParams(location.search)
  const ccaa = currentSearch.get('comunidad')
  const { getTariffs } = useTariffsCalls()

  useCombos(['cod_zona_zeus', 'provincias'])

  useCustomCombo(['empresas'])

  const buttons = (
    <ExportToXLSBtn
      selectedRows={selection}
      columnsExport={TARIFFS_TABLE_COLUMNS}
      getAllData={() =>
        new Promise((resolve, reject) => {
          getTariffs({ params: { ...search, _num_x_pagina: 0 } })
            .then(({ data }) => {
              return resolve(tariffsDataPreparation(data.tarifas, { intl }))
            })
            .catch((err) => {
              return reject(err)
            })
        })
      }
      titleRoot="tarifas_"
      headerNamespace="tariffs"
    />
  )

  const data = TARIFFS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
      if (item.comboId === 'provincias' && item.values && ccaa) {
        item.values = item.values.filter((provincia) => {
          return -1 < ccaa.split(',').indexOf(provincia.ca)
        })
      }
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.tariffs.filters.' + item.name + '.title',
      }),
    }
  })

  return <FilterBox filters={data}>{role === 'e_commerce' ? buttons : null}</FilterBox>
})
