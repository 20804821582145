const TEXT_AREA_LENGTH = 250
const BINARY_VALUES = [
  { key: '01', value: 'Sí' },
  { key: '00', value: 'No' },
]
export const GUILD_FORM_PREFIX = 'pages.guilds.detail.guild.info'
export const GUILD_FORM_CONFIG = ({ formState, onChange, combos, intl }) => {
  return {
    NOMBRE: {
      required: true,
      readOnly: true,
      value: formState['nombre'],
      name: 'nombre',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.nombre.title' }),
      onChange,
    },
    RAZON: {
      required: true,
      readOnly: true,
      value: formState['razon_social'],
      name: 'razon_social',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.razon_social.title' }),
      onChange,
    },
    TELEFONO: {
      required: true,
      inputProps: { type: 'tel', pattern: '[6789]{1}[0-9]{8}', maxLength: 9 },
      readOnly: true,
      value: formState['telefono'],
      name: 'telefono',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.telefono.title' }),
      onChange,
    },
    WEB: {
      readOnly: true,
      value: formState['web'],
      name: 'web',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.web.title' }),
      onChange,
    },
    EMAIL: {
      required: true,
      type: 'email',
      readOnly: true,
      value: formState['email'],
      name: 'email',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.email.title' }),
      onChange,
    },
    DIRECCION: {
      required: true,
      readOnly: true,
      value: formState['direccion'],
      name: 'direccion',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.direccion.title' }),
      onChange,
    },
    COD_POSTAL: {
      required: true,
      inputProps: {
        pattern: '0[1-9][0-9]{3}|[1-4][0-9]{4}|5[0-2][0-9]{3}',
        maxLength: 5,
      },
      readOnly: true,
      value: formState['cod_codigo_postal'],
      name: 'cod_codigo_postal',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.cod_codigo_postal.title' }),
      onChange,
    },
    NIF: {
      readOnly: true,
      value: formState['cif'],
      name: 'nif',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.nif.title' }),
      onChange,
    },
    ACEPTA_F: {
      required: true,
      readOnly: true,
      value: formState['acepta_financiacion'],
      name: 'acepta_financiacion',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.acepta_financiacion.title' }),
      values: BINARY_VALUES,
      fullWidth: true,
      onChange,
    },
    DESCRIPCION: {
      required: true,
      readOnly: true,
      value: formState['descripcion'],
      name: 'descripcion',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.descripcion.title' }),
      multiline: true,
      rows: 5,
      inputProps: { maxLength: TEXT_AREA_LENGTH },
      onChange,
    },
    CCAA: {
      fullWidth: true,
      readOnly: true,
      value: formState['cc_aa'],
      values: combos['cod_zona_zeus'] && combos['cod_zona_zeus'].data,
      name: 'cc_aa',
      title: intl.formatMessage({ id: 'pages.eeii.detail.fields.cc_aa.title' }),
      multiple: true,
      onChange,
    },
  }
}
