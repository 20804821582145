import React from 'react'
import { useIntl } from 'react-intl'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'

import { ThemeButton } from '../../../../ui'

export const ExportSomethingDialog = ({ close }) => {
  const { formatMessage } = useIntl()

  return (
    <Box p={4}>
      <DialogTitle>
        <Box mb={2} textAlign="center">
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.sr.exportToXLS.dialogs.selectionNull.title' })}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {formatMessage({ id: 'pages.sr.exportToXLS.dialogs.selectionNull.text' })}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end">
          <ThemeButton color="primary" onClick={close} labelM="lg">
            {formatMessage({ id: 'global.return' })}
          </ThemeButton>
        </Box>
      </DialogActions>
    </Box>
  )
}
