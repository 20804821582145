import { Box, Container, Typography, withStyles } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useCombos, useManualCombo } from '../../../features/combos'
import { EEIIHeader } from '../../../features/eeii/components/header'
import { EEIITable } from '../../../features/eeii/components/table'
import { useEEIICalls } from '../../../features/eeii/hooks'
import { useGuildCalls } from '../../../features/guilds/hooks/guilds-calls.hook'
import { useURLLoader } from '../../../features/loader/hooks/loader.hook'

import { eeiiStyles } from './eeii.styles'

export const EEIIPage = withStyles(eeiiStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { getEEII } = useEEIICalls()
  const { getGuilds } = useGuildCalls()
  useCombos(['cod_zona_zeus'], ['cod_zona_zeus'])
  const { setManualCombo } = useManualCombo()

  useEffect(() => {
    getGuilds({ params: { _num_x_pagina: 0 } }).then(({ data }) => {
      const comboData = data.gremios.map((el) => {
        return {
          key: '' + el.gremio_id,
          value: el.nombre,
        }
      })
      setManualCombo('guilds', comboData)
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useURLLoader({
    defaultChoices: { _pagina: 1, _num_x_pagina: 10 },
    minRefreshMs: 1000,
    identifier: 'eeii',
    call: getEEII,
  })
  return (
    <Container className={classes.container} maxWidth="false">
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.eeii.title' })}
        </Typography>
      </Box>
      <Box mb={2}>
        <EEIIHeader />
      </Box>
      <Box>
        <EEIITable />
      </Box>
    </Container>
  )
})

export default EEIIPage
