import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { withStyles, Box, IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { CancelDialog, AssignToDialog } from '../dialogs'
import { useModalContext } from '../../../../ui/modal'
import { moreActionsBtnStyles } from './more-actions-btn.styles'
import { useApplicationsCalls, useApplicationExports } from '../../hooks'

export const MoreActionsBtn = withStyles(moreActionsBtnStyles)(({ classes }) => {
  const intl = useIntl()
  const history = useHistory()
  const userRole = useSelector((state) => state.global.role)
  const selectedRows = useSelector((state) => state.applications.selectedRows)
  const totalElements = useSelector((state) => state.applications.pagination.total_solicitudes)
  const { mainExport, handleExportAll } = useApplicationExports()

  const {
    actions: { open },
  } = useModalContext()
  const { getApplications } = useApplicationsCalls()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenActions = (event) => setAnchorEl(event.currentTarget)

  const handleCloseActions = () => setAnchorEl(null)

  const handleOpenModal = (option) => {
    const component = option === 'cancel' ? CancelDialog : AssignToDialog
    setAnchorEl(null)
    open({
      Component: component,
      data: { history, getApplications },
      type: 'responsive',
    })
  }

  const handleExport = () => {
    setAnchorEl(null)
    mainExport(selectedRows)
  }
  const handleExportAllRequest = () => {
    setAnchorEl(null)
    handleExportAll()
  }

  return (
    <Box>
      <IconButton className={classes.actionsButton} onClick={handleOpenActions}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseActions}>
        <MenuItem disabled={!selectedRows.length} onClick={() => handleExport()}>
          {intl.formatMessage({ id: 'pages.applications.exportToXLS.label' })}
        </MenuItem>
        <MenuItem disabled={!totalElements} onClick={() => handleExportAllRequest()}>
          {intl.formatMessage({ id: 'pages.applications.exportAllToXLS.label' })}
        </MenuItem>
        {userRole === 'delegado' && (
          <MenuItem disabled={!selectedRows.length} onClick={() => handleOpenModal('assignTo')}>
            {intl.formatMessage({ id: 'pages.applications.assignButton.label' })}
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
})
