export const GUILDS_IP3_TABLE_COLUMNS = [
  { field: 'fecha_alta', align: 'left', width: 140, type: 'date' },
  {
    field: 'nombre_gremio',
    align: 'left',
    cellStyle: {
      textTransform: 'capitalize',
    },
  },
  {
    field: 'cif',
    align: 'left',
    width: 120,
    cellStyle: {
      textTransform: 'uppercase',
    },
  },
  { field: 'telefono', align: 'left', width: 120 },
  { field: 'email', align: 'left' },
  { field: 'web', align: 'left' },
]

export const GUILDS_IP3_TABLE_FILTERS = []
