import React, { createContext, useReducer } from 'react'

import { usePORequestsActions } from '../hooks'
import { poRequestsReducer, PO_REQ_INITIAL_STATE } from '../reducers'

export const PORequestsContext = createContext()

export const PORequestsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(poRequestsReducer, PO_REQ_INITIAL_STATE)

  const actions = usePORequestsActions(state, dispatch)

  return (
    <PORequestsContext.Provider value={{ ...state, ...actions }}>
      {children}
    </PORequestsContext.Provider>
  )
}
