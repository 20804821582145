import React from 'react'
import { Box } from '@material-ui/core'

import { CPCardComponent } from './c-p-card.component'

export const CPDashboardComponent = ({ counters, cards }) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-around">
      {cards.map((item, i) => (
        <CPCardComponent key={i} {...{ ...item, count: counters[item.id] }} />
      ))}
    </Box>
  )
}
