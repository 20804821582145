import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core'

import {
  AcceptationDialog,
  ConfirmationDialog,
  FormFoldingPanel,
  ThemeButton,
  useModalContext,
} from '../../../../ui'
import { useConsultContext } from '../../contexts'
import {
  APP_PATH_RULES,
  RESULTS_VIEW_CONFIG,
  RESULTS_VIEW_FIELDS,
} from '../../constants'
import { ProfileChoiceDialog } from '../../../applications/components/dialogs'
import { useTokenGeneratorCalls } from '../../../applications/hooks/token-generator-calls.hook'
import { LINK_CREATE_APP } from '../../../applications/constants'
import { postPath } from '../../calls/consult-address.calls'
import { getGestorGC } from '../../calls/consult-information.calls'
import feedback from '../../../../core/feedback'

export const ConsultResultsView = () => {
  const intl = useIntl()
  const {
    isLoading,
    addressForm,
    selectedHome: home,
    consultData,
    createApplication,
    reset,
    getConsult,
    checkIfAppExist,
    changeCustomerForm,
    changeStep,
    changeIsContratable,
    isContratable,
  } = useConsultContext()
  const combos = useSelector((state) => state.combos)
  const user = useSelector((state) => state.global)
  const [selectedUso, setSelectedUso] = useState(undefined)
  const [usoError, setUsoError] = useState(false)
  const { generateOneUseLink } = useTokenGeneratorCalls()
  const {
    actions: { open },
  } = useModalContext()
  const isMandatoryFieldsInformed = () => {
    return selectedUso
  }

  const handleToggleFilter = (toogleState) => {
    changeIsContratable(toogleState)
    changeCustomerForm({ name: 'pasar_contratable', value: toogleState })
  }

  const handleSubmit = () => {
    const mercadoId = combos.tipo_mercado.data.find(
      (mercado) => mercado.key === consultData.mercado
    )?.id

    /*if (
      (selectedUso === 'gran_consumo' ||
        selectedUso === 'caldera_centralizada' ||
        consultData.mercado === 'GC' ||
        consultData.mercado === 'CC') &&
      user.role !== 'gestor_gc'
    ) {
      getGestorGC(mercadoId, consultData.cod_postal, selectedUso)
        .then((response) => {
          const { nombre, telefono, email } = response
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.dialogs.granconsumo.title' }),
              text: intl.formatMessage(
                { id: 'pages.consult.granconsumo.text' },
                { nombre: nombre, telefono: telefono, email: email }
              ),
              yesText: intl.formatMessage({ id: 'pages.consult.dialogs.granconsumo.yesText' }),
            },
            type: 'centered',
          })
        })
        .catch(({ data }) => {
          open({
            Component: AcceptationDialog,
            data: {
              title: intl.formatMessage({ id: 'pages.consult.dialogs.granconsumo.title' }),
              text: intl.formatMessage({ id: 'pages.consult.dialogs.granconsumo.fail.text' }),
              yesText: intl.formatMessage({ id: 'pages.consult.dialogs.granconsumo.yesText' }),
            },
            maxWidth: 'md',
            type: 'centered',
          })
        })
    } else {

    } */
    if (isMandatoryFieldsInformed()) {
      if (combos['call_center_sub_origins']?.data.length > 1) {
        open({
          Component: ProfileChoiceDialog,
          data: {
            options: combos['call_center_sub_origins']?.data,
            action: (profileId) => handleCreateApp(profileId),
          },
        })
      } else if (user.role === 'gss_vt') {
        handleCreateApp('GSS_VT')
      } else if (user.role === 'gestor_gc') {
        checkIfAppExist(home['cidi']).then(() => {
          changeStep(2, { replace: true })
        })
      } else {
        const type = user.role === 'call_center' ? 'Call_center' : 'Gestor'
        handleCreateApp(type)
      }
    } else {
      setUsoError(true)
    }
  }

  const handleCreateApp = (profile) => {
    checkIfAppExist(home['cidi'])
      .then(() => {
        createApplication(profile).then((id) => {
          redirectToWordpress(id, profile)
        })
      })
      .catch(console.debug('Application already exists'))
  }

  const redirectToWordpress = (appId, profileId) => {
    generateOneUseLink(user.userId, profileId, appId, {
      ...home,
      uso: selectedUso,
      pasar_contratable: isContratable,
    }).then(({ data }) => {
      window.open(LINK_CREATE_APP(data.message))
      reset()
    })
  }

  const handlePrevious = () => {
    reset()
  }

  const isActivo = home.estado_cups === 'activo' && home.mercado === 'NE'
  const isContratacionEnCurso =
    home.estado_cups === 'contratación en curso' && home.mercado === 'NE'
  const isSinIRC = home.estado_cups === 'sin red/irc' && home.mercado === 'NE'
  const isContratableAdvise = home.mercado === 'NE' && home.estado_cups === 'contratable'
  const isEspontanea = home.mercado === 'ES'
  const can_continue_flow = consultData['flowType'] && consultData['flowType'] !== 'none'

  const warnChangePathDialog = (callback) => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: intl.formatMessage({ id: 'pages.consult.dialogs.changePath.title' }),
        text: intl.formatMessage({ id: 'pages.consult.dialogs.changePath.text' }),
        yesText: intl.formatMessage({ id: 'pages.consult.dialogs.changePath.yes' }),
        noText: intl.formatMessage({ id: 'pages.consult.dialogs.changePath.no' }),
        yesAction: callback,
      },
    })
  }

  const handleChangePathRequest = (e) => {
    const target = e.target
    warnChangePathDialog(() =>
      handleChangePath({ cidi: '' + consultData['cidi'], camino_solicitud: target.value })
        .then(() => {
          getConsult(addressForm['vivienda'], user?.id)
          feedback('success', 'El cambio de prescripción se ha guardado correctamente')
        })
        .catch(() => {
          feedback('error', 'No ha sido posible guardar el cambio')
        })
    )
  }

  const handleChangePath = postPath
  const handleSelectUso = (e) => {
    const target = e.target
    setSelectedUso(target.value)
    setUsoError(false)
    changeCustomerForm({ name: 'uso', value: target.value })
  }
  const Uso = () => {
    const usosData = combos['uso_punto_suministro'] ? combos['uso_punto_suministro'].data : []
    return (
      <>
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" display="inline" style={{ marginRight: 8 }}>
            {`${intl.formatMessage({ id: 'pages.consult.usoResultSubtitle' })}: `}
          </Typography>
          <FormControl component="fieldset" onChange={handleSelectUso}>
            <RadioGroup row value={selectedUso}>
              {usosData.map((uso) => {
                const label = uso.value
                return (
                  <FormControlLabel
                    key={uso.key}
                    value={uso.key}
                    control={<Radio size="small" color="primary" />}
                    label={
                      <Typography variant="body1" color="textSecondary">
                        {label}
                      </Typography>
                    }
                  />
                )
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <FormHelperText id="my-helper-text" error={usoError}>
          {usoError ? intl.formatMessage({ id: 'pages.consult.uso.error.message' }) : ''}
        </FormHelperText>
      </>
    )
  }
  return (
    <>
      <Typography variant="h4" color="textSecondary" paragraph>
        {intl.formatMessage({ id: 'pages.consult.title.1' })}
      </Typography>
      <Typography variant="subtitle1" display="inline">
        {`${intl.formatMessage({ id: 'pages.consult.resultsSubtitle' })}: `}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="textSecondary" display="inline">
          {`${home['tipo_calle']} ${home['nombre_via']} ${home['numero']}` +
            `${home['anexo'] ? '-' + home['anexo'] : ''} ` +
            `${home['bloque'] ? '-' + home['bloque'] : ''} ` +
            `${home['escalera'] ? '-' + home['escalera'] : ''} ` +
            `${home['piso']} ${home['puerta']}` +
            `, ${home['cod_postal']} ${home['municipio']} ${home['provincia']}`}
        </Typography>
        {consultData.estado_cups !== 'CONTRATABLE' ? (
          <FormControlLabel
            variant="outlined"
            label="Ya esta conectada, pasar a contratable"
            control={
              <Switch
                {...{
                  onClick: ({ target: { checked } }) => handleToggleFilter(checked),
                  checked: isContratable,
                }}
              />
            }
            labelPlacement="start"
          />
        ) : null}
      </Box>

      {!can_continue_flow && consultData['mensaje'] ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {consultData['mensaje'] ? intl.formatMessage({ id: consultData['mensaje'] }) : null}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isEspontanea ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {intl.formatMessage({ id: 'pages.consult.continue.flowtype.alert.espontanea' })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isContratableAdvise ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {intl.formatMessage({ id: 'pages.consult.continue.flowtype.alert.contratable' })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isSinIRC ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {intl.formatMessage({ id: 'pages.consult.continue.flowtype.alert.sinirc' })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isContratacionEnCurso ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {intl.formatMessage({
                id: 'pages.consult.continue.flowtype.alert.contratacionencurso',
              })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {isActivo ? (
        <Box mt={3} display="flex" justifyContent="space-around">
          <Alert severity="warning">
            <Typography variant="body1">
              {intl.formatMessage({
                id: 'pages.consult.continue.flowtype.alert.activa',
              })}
            </Typography>
          </Alert>
        </Box>
      ) : null}
      {can_continue_flow ? <Uso /> : null}
      <Box bgcolor="grey.300" p={3} mt={3}>
        {RESULTS_VIEW_CONFIG.map((block, i) => (
          <div key={i}>
            <FormFoldingPanel
              {...{
                data: consultData,
                combos,
                intl,
                block,
                fieldList: RESULTS_VIEW_FIELDS({ combos, intl }),
              }}
            />
          </div>
        ))}
        <Box mt={3} display="flex" justifyContent="space-around">
          <ThemeButton
            type="button"
            variant="outlined"
            labelM="lg"
            disabled={isLoading}
            onClick={handlePrevious}
          >
            {intl.formatMessage({ id: 'pages.consult.resultsButtons.goBack' })}
          </ThemeButton>
          {can_continue_flow ? (
            <ThemeButton type="button" labelM="lg" onClick={handleSubmit} loading={isLoading}>
              {intl.formatMessage({
                id: `pages.consult.resultsButtons.goNext.${
                  APP_PATH_RULES[consultData['flowType']]?.certificate ? '2' : '1'
                }`,
              })}
            </ThemeButton>
          ) : null}
        </Box>
      </Box>
    </>
  )
}
