import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, CircularProgress } from '@material-ui/core'

import { BasicTable } from '../../../../ui'
import { APP_EVENTS_TABLE_CONFIG } from './events-table.constants'
import { useAppSupervisionCalls } from '../../hooks'

export const TaskEventsTable = ({ taskId }) => {
  const intl = useIntl()
  const { getBitacoraTask } = useAppSupervisionCalls()

  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])

  useEffect(() => {
    getBitacoraTask(taskId)
      .then((data) => {
        const sortedData = data.data.sort(
          (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
        )
        setEvents(sortedData)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [taskId]) // eslint-disable-line react-hooks/exhaustive-deps
  if (loading)
    return (
      <Box display="flex" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={40} />
      </Box>
    )
  return events.length ? (
    <BasicTable config={APP_EVENTS_TABLE_CONFIG(intl)} data={events} loading={loading} />
  ) : (
    intl.formatMessage({ id: 'pages.application.detail.history.emptyList' })
  )
}
