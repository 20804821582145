import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ThemeButton, BasicTable } from '../../../../ui'
import { downloadFile } from '../../../../core/utils'
import {
  REJECTED_BUDGETS_LIST_CONFIG,
  CURRENT_DOCUMENTS_LIST_CONFIG,
  BUDGET_DOCUMENT_KEYS,
  OLD_CROQUIS_LIST_CONFIG,
  APP_DOCUMENT_KEYS,
  GASP_DOCUMENT_KEYS,
} from '../../constants'

export const ViewDocuments = ({ close, application }) => {
  const { formatMessage } = useIntl()
  const { getById } = useAjaxContext()
  const [loading, setLoading] = useState(false)

  const [currentDocuments, setCurrentDocuments] = useState([])
  const [rejectedBudgets, setRejectedBudgets] = useState([])
  const [oldCroquis, setOldCroquis] = useState([])

  useEffect(() => {
    getListItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getListItems = () => {
    let currentDocuments = []
    let rejectedBudgets = []
    let oldCroquis = []

    if (application['presupuestos_rechazados']) {
      application['presupuestos_rechazados'].forEach((presupuesto) => {
        if (presupuesto['d_presupuesto'])
          rejectedBudgets.push({
            ...presupuesto['d_presupuesto'],
          })
      })
    }

    if (application['old_croquis']) {
      application['old_croquis'].forEach((croquis) => {
        oldCroquis.push(croquis)
      })
    }

    let activeBudget = application['presupuesto_exterior']
    if (activeBudget) {
      for (let key in activeBudget) {
        if (BUDGET_DOCUMENT_KEYS.some((element) => element === key && activeBudget[key]))
          currentDocuments.push({
            ...activeBudget[key],
            tipo: formatMessage({
              id: `pages.application.detail.documentViewer.documents.${activeBudget[key]['tipo']}`,
            }),
          })
      }
    }
    let gasP = application['gas_p']
    if (gasP) {
      for (let key in gasP) {
        if (GASP_DOCUMENT_KEYS.some((element) => element === key && gasP[key]))
          currentDocuments.push({
            ...gasP[key],
            tipo: formatMessage({
              id: `pages.application.detail.documentViewer.documents.${gasP[key]['tipo']}`,
            }),
          })
      }
    }
    if (application['certificado_IRG3'] && application['certificado_IRG3']['d_IRG3']) {
      currentDocuments.push({
        ...application['certificado_IRG3']['d_IRG3'],
        tipo: formatMessage({
          id: `pages.application.detail.documentViewer.documents.IR`,
        }),
      })
    }
    if (application['certificado_IRG2'] && application['certificado_IRG2']['d_IRG2']) {
      currentDocuments.push({
        ...application['certificado_IRG2']['d_IRG2'],
        tipo: formatMessage({
          id: `pages.application.detail.documentViewer.documents.IRG2`,
        }),
      })
    }
    if (application['certificado_IRG1'] && application['certificado_IRG1']['d_IRG1']) {
      currentDocuments.push({
        ...application['certificado_IRG1']['d_IRG1'],
        tipo: formatMessage({
          id: `pages.application.detail.documentViewer.documents.IRG1`,
        }),
      })
    }
    for (let key in application) {
      if (APP_DOCUMENT_KEYS.some((element) => element === key && application[key]))
        currentDocuments.push({
          ...application[key],
          tipo: formatMessage({
            id: `pages.application.detail.documentViewer.documents.${application[key]['tipo']}`,
          }),
        })
    }
    setCurrentDocuments(currentDocuments)
    setRejectedBudgets(rejectedBudgets)
    setOldCroquis(oldCroquis)
  }

  const handleDownloadFile = ({ id }) => {
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  return (
    <Box p={4}>
      {currentDocuments.length === 0 && rejectedBudgets.length === 0 ? (
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.application.detail.documentViewer.notFound' })}
          </Typography>
        </Box>
      ) : (
        <>
          {currentDocuments.length !== 0 && (
            <Box mb={4}>
              <Typography variant="h6" color="primary" paragraph>
                {formatMessage({ id: 'pages.application.detail.documentViewer.documentsTitle' })}
              </Typography>
              <BasicTable
                config={CURRENT_DOCUMENTS_LIST_CONFIG}
                data={currentDocuments}
                onClick={handleDownloadFile}
                loading={loading}
              />
            </Box>
          )}
          {rejectedBudgets.length !== 0 && (
            <Box mb={4}>
              <Typography variant="h6" color="primary" paragraph>
                {formatMessage({
                  id: 'pages.application.detail.documentViewer.rejectedBudgetsTitle',
                })}
              </Typography>
              <BasicTable
                config={REJECTED_BUDGETS_LIST_CONFIG}
                data={rejectedBudgets}
                onClick={handleDownloadFile}
                loading={loading}
              />
            </Box>
          )}
          {oldCroquis.length !== 0 && (
            <Box mb={4}>
              <Typography variant="h6" color="primary" paragraph>
                {formatMessage({
                  id: 'pages.application.detail.documentViewer.oldCroquisTitle',
                })}
              </Typography>
              <BasicTable
                config={OLD_CROQUIS_LIST_CONFIG}
                data={oldCroquis}
                onClick={handleDownloadFile}
                loading={loading}
              />
            </Box>
          )}
        </>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={() => close()} color="primary" labelM="lg" loading={loading}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
