import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'
import {
  AppsTable,
  AppsFilter,
  NonCaptablesExportToXLSBtn,
} from '../../../../features/non-captables-validation'
import { nonCaptablesValidationDashboardStyles } from './non-captables-validation-dashboard.styles'

export const NonCaptablesValidationDashboardPage = withStyles(
  nonCaptablesValidationDashboardStyles
)(({ classes }) => {
  const { formatMessage } = useIntl()
  return (
    <Box>
      <Container className={classes.container} maxWidth="false">
        <Box mb={2}>
          <Typography variant="h4" color="textSecondary">
            {formatMessage({ id: 'pages.nonCaptablesValidation.title' })}
          </Typography>
        </Box>
        <Box mb={2} display="flex">
          <Box width={'100%'}>
            <AppsFilter>
              <NonCaptablesExportToXLSBtn />
            </AppsFilter>
          </Box>
        </Box>
        <Box>
          <AppsTable />
        </Box>
      </Container>
    </Box>
  )
})
