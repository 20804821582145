import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@material-ui/core'

import { useSirContext, DetailHeaderBtns, SirForm } from '../../../../features/sir'
import { SIR_DET_COMBOS_IDS } from './sir-detail.constants'
import { useCombos } from '../../../../features/combos/hooks'
import { PageDisplayer } from '../../../../ui'
import { CommunicationsBtn } from '../../../../features/sir/components/communications'

export const SirDetailPage = () => {
  const history = useHistory()
  const { appId } = useParams()
  useCombos(SIR_DET_COMBOS_IDS)
  const { formatMessage } = useIntl()
  const { detailLoading, detailError, getApplication } = useSirContext()

  useEffect(() => {
    getApplication(appId)
  }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    const search = history.location.state?.prevSearch ? history.location.state.prevSearch : ''
    history.push('/sir' + search)
  }

  return (
    <PageDisplayer
      loading={detailLoading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.sir.detail.goBack' })}
    >
      {detailError ? (
        formatMessage({ id: 'pages.sir.detail.error' }, { appId })
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <DetailHeaderBtns />
            <CommunicationsBtn />
          </Box>
          <SirForm />
        </>
      )}
    </PageDisplayer>
  )
}
