export const guildsIP3TableStyles = {
  header: {
    fontSize: 14,
    padding: 10,
    color: '#1a171b',
    backgroundColor: 'rgba(0, 65, 101, 0.1)',
    border: 'none',
    textAlign: 'center',
  },
  cell: {
    fontSize: 14,
    color: '#63666a',
    backgroundColor: '#FFFFFF',
    padding: 10,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  adjustableCell: {
    fontSize: 14,
    color: '#63666a',
    backgroundColor: '#FFFFFF',
    padding: 10,
    paddingRight: 10,
  },
  row: {
    padding: 0,
    margin: 0,
  },
}
