import React from 'react'

import { IP3HistContext } from '../../features/ip3-historical'
import { IP3HistRouter } from './routers'

export const IP3HistPage = () => {
  return (
    <IP3HistContext>
      <IP3HistRouter />
    </IP3HistContext>
  )
}
