import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { ThemeButton } from '../theme-button'

export const AcceptationDialog = ({
  title = 'Warning:',
  text = 'Are you sure you want to proceed?',
  yesText = 'Yes',
  yesColor = 'primary',
  yesAction = () => {},
  yesAndClose = true,
  close,
}) => {
  return (
    <Box p={4}>
      <DialogTitle>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography variant="h4" color="primary">
            {title}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{text}</Typography>
      </DialogContent>
      <DialogActions>
        <ThemeButton
          labelM="small"
          color={yesColor}
          onClick={() => {
            yesAction()
            if (yesAndClose) {
              close()
            }
          }}
        >
          {yesText}
        </ThemeButton>
      </DialogActions>
    </Box>
  )
}
