export const tableActionsStyles = (theme) => ({
  actionButton: {
    borderRadius: '100%',
    border: '1px solid #333',
    height: '1.7em',
    width: '1.7em',
  },
  actionRow: {
    display: 'flex',
    justifyContent: 'space-around',
  },
})