import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { ThemeButton, SelectInput } from '../../../../ui'
import { CHANGE_INSTALLER_OBSERVATIONS } from '../../constants'

export const AddObservationDialog = ({
  isChangeInstaller,
  handleChangeInstaller,
  handleDeleteInstaller,
}) => {
  const { formatMessage } = useIntl()

  const [observations, setObservations] = useState('')
  const [hasObservations, setHasObservations] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleChangeObservations = (e) => {
    const {
      target: { value },
    } = e
    setHasObservations(value !== '' ? true : false)
    setObservations(value)
  }

  const changeInstaller = () => {
    setLoading(true)
    handleChangeInstaller(observations)
  }

  const deleteInstaller = () => {
    setLoading(true)
    handleDeleteInstaller(observations)
  }

  return (
    <Box pb={4} pl={4} pr={4} mb={1}>
      <Typography variant="h4" color="primary" align="center" paragraph>
        {formatMessage({
          id: 'pages.application.detail.panels.6.observaciones.title',
        })}
      </Typography>
      <Box mb={2} mt={3}>
        <SelectInput
          fullWidth={true}
          required={true}
          title={formatMessage({
            id: 'pages.application.detail.panels.6.observaciones.title',
          })}
          placeholder={formatMessage({
            id: 'pages.application.detail.panels.6.observaciones.description',
          })}
          value={observations}
          values={CHANGE_INSTALLER_OBSERVATIONS}
          name="observations"
          onChange={handleChangeObservations}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        {isChangeInstaller ? (
          <ThemeButton
            labelM="large"
            color="primary"
            onClick={changeInstaller}
            disabled={loading || !hasObservations}
          >
            {formatMessage({ id: 'pages.application.detail.panels.6.cambiar_instalador.title' })}
          </ThemeButton>
        ) : (
          <ThemeButton
            color="secondary"
            labelM="large"
            disabled={loading || !hasObservations}
            onClick={deleteInstaller}
          >
            {formatMessage({
              id: 'pages.application.detail.panels.6.eliminar_instalador.title',
            })}
          </ThemeButton>
        )}
      </Box>
    </Box>
  )
}
