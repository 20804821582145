import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useSirContext } from '../../contexts'
import { ViewBillDialog } from '../dialogs'

export const ViewBillBtn = () => {
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()
  const { application, getBillData } = useSirContext()

  const handleValidatePayment = () => {
    const bill = application.presupuesto_sir.factura_sir
    open({
      Component: ViewBillDialog,
      data: { bill, getBillData },
    })
  }

  return application.presupuesto_sir.factura_sir ? (
    <ThemeButton
      color="primary"
      onClick={handleValidatePayment}
      disabled={!application.presupuesto_sir.factura_sir}
    >
      {formatMessage({ id: 'pages.sir.detail.button.viewBill.label' })}
    </ThemeButton>
  ) : null
}
