import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox, ExportToXLSBtn } from '../../../../ui'
import { PO_REQ_TABLE_COLUMNS } from '../table'
import { usePORequests } from '../../hooks'
import { PO_REQ_FILTERS } from './po-requests-filter.constants'
import { PORequestTableActionsDialog } from '../dialogs/table-actions-dialog.component'
import { Box } from '@material-ui/core'

export const PORequestsFilter = () => {
  const { formatMessage: fm } = useIntl()
  const combos = useSelector((state) => state.combos)
  const { getExportPreparedData } = usePORequests()

  const filters = PO_REQ_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: fm({ id: 'pages.poRequests.filters.' + item.name + '.title' }),
    }
  })

  return (
    <FilterBox filters={filters}>
      <Box display="flex" justifyContent="flex-end">
        <PORequestTableActionsDialog />
        <ExportToXLSBtn
          exportSelectionEnabled={false}
          exportAllEnabled={true}
          columnsExport={PO_REQ_TABLE_COLUMNS}
          getAllData={getExportPreparedData}
          titleRoot="peticiones_OP_"
          headerNamespace="poRquestsExport"
        />
      </Box>
    </FilterBox>
  )
}
