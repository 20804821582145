import React, { useEffect, useState } from 'react'
import { TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { ListboxComponent } from './virtualized-list-box.component'

const REQUEST_TIMEOUT = 300

export const AutocompleteInput = ({
  name,
  optionKey,
  title,
  placeholder = '',
  requestPath,
  onChange,
  onType,
  values = [],
  required = false,
  disabled = false,
  labelOption,
  inputMinLength = 3,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (onType && inputValue && inputValue.length >= inputMinLength) {
      const timer = setTimeout(() => {
        const currentInputValue = inputValue
        if (inputValue === currentInputValue) {
          onType(inputValue)
        }
      }, REQUEST_TIMEOUT)
      return () => clearTimeout(timer)
    }
  }, [inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderOption = props.renderOption || ((option) => option[optionKey] || option)

  const optionLabel = labelOption || renderOption

  return (
    <Autocomplete
      {...props}
      id={`${name}-autocomplete-input`}
      size="small"
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={values}
      getOptionSelected={(option, value) =>
        option[optionKey] ? option[optionKey] === value[optionKey] : option === value
      }
      getOptionLabel={optionLabel}
      renderOption={renderOption}
      onChange={(event, value) => onChange({ event, value, name })}
      value={props.value || null}
      disabled={disabled}
      autoSelect
      ListboxComponent={ListboxComponent}
      filterOptions={(options, state) => {
        const { inputValue, getOptionLabel } = state
        const value = inputValue.trim().toLowerCase()
        return options.filter((option) => {
          if (option.special) {
            return true
          }
          return getOptionLabel(option).toLowerCase().includes(value)
        })
      }}
      renderInput={(params) => (
        <>
          <Typography variant="subtitle1" gutterBottom>
            {title}
          </Typography>
          <TextField
            {...params}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              required,
            }}
          />
        </>
      )}
    />
  )
}
