export const getAddress = (data) => {
  const main = [
    data['tipo_calle'] ? data['tipo_calle'] : null,
    data['direccion'],
    data['numero'],
    data['anexo'],
  ]
    .filter((el) => el)
    .join(' ')
  const detail = [data['bloque'], data['escalera'], data['piso'], data['puerta']]
    .filter((el) => el)
    .join(' ')
  return main + (detail ? ', ' + detail : '')
}

export const getFullAddress = (data, separator = ', ') => {
  const place = [data['cod_postal'], data['municipio']].filter((el) => el).join(' ')
  return getAddress(data) + separator + place
}
