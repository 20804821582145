export const CURRENT_DOCUMENTS_LIST_CONFIG = [
  {
    key: 'tipo',
    label: 'Documento',
  },
  {
    key: 'nombre',
    label: 'Nombre',
  },
  {
    key: 'dg_ts_insert',
    label: 'Fecha subida',
    type: 'date',
  },
]

export const FILE_FIELD_NAMES = ['d_CI', 'd_FI', 'd_PS', 'd_justificante_pago_sir', 'd_ftni']
