import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { stringify } from 'qs'

export const CounterBtn = (item) => {
  const history = useHistory()

  const handleClick = (id) => {
    const searchParams = item.searchParams
    history.push(`${item.path}${searchParams ? '?' : ''}${stringify(searchParams)}`)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 56,
        height: 24,
        borderRadius: 12,
        border: `solid 1px ${item.borderColor || '#000000'}`,
        backgroundColor: item.bgColor || '#fafafa',
        cursor: 'pointer',
      }}
      onClick={() => handleClick(item.id)}
    >
      <Typography variant="body1">{item.count > -1 ? item.count : '--'}</Typography>
    </div>
  )
}
