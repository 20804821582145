import * as zonesActions from '../constants'

/*Goals of our reducer:
- Easy access to province list and zip list per province, both of array type
- Maximal persistance of information while updating immutably
- Avoid innecessary calculations threading over arrays
Price to pay for it:
- Slight redundancy

The state will be like:
{ 
  comunidad: "Catalunya",
  zips: {
    Barcelona: [
      {activa: true, value: '08519'},
      ...
    ],
    ...
  },
  provincias: [
    {name: "Barcelona", chosen: 256, size: 400}, 
    ...
  ]
}
*/

export const zonesReducer = (state, { type, payload }) => {
  switch (type) {
    case zonesActions.SET_MARKETERS_DATA:
      payload.provincias.forEach((provincia) => {})
      return {
        ...state,
        provinciasToSend: payload.provincias,
        mercados: payload.mercados,
        zips: payload.cps,
      }

    case zonesActions.SET_ZONES_DATA:
      return {
        ...state,
        provincias: [...state.provincias, payload.cod_codigo_postal[0]],
      }

    //Cases antes de refactor

    case zonesActions.CHECK_SINGLE_ZIP:
      return {
        ...state,
        provincias: state.provincias.map((provincia) =>
          provincia.prov !== payload.provincia
            ? provincia
            : {
                ...provincia,
                cps: provincia.cps.map((cp, i) =>
                  cp.value !== payload.zip ? cp : { ...cp, activa: payload.newState }
                ),
              }
        ),
      }
    case zonesActions.ZIPS_TO_SEND:
      const indexToPush = state.zips.indexOf(payload)
      const arrayZips = [...state.zips]
      indexToPush === -1 ? arrayZips.push(payload) : arrayZips.splice(indexToPush, 1)
      return {
        ...state,
        zips: arrayZips,
      }
    case zonesActions.CHECK_SINGLE_PROVINCE:
      return {
        ...state,
        currentProvincia: payload.currentProvincia,
      }

    case zonesActions.WHOLE_ZIP_TO_CHECK:
      return {
        ...state,
        provincias: state.provincias.map((provincia) =>
          provincia.prov !== payload.provincia
            ? provincia
            : {
                ...provincia,
                cps: provincia.cps.map((cp) => ({ ...cp, activa: payload.newState })),
              }
        ),
      }
    case zonesActions.WHOLE_ZIPS_TO_SEND:
      const pushOrPop = payload.newState
      const arrayWholeZips = [...state.zips]
      if (pushOrPop === false) {
        payload.zips.forEach((zip) => {
          let indexToSplice = arrayWholeZips.indexOf(zip)
          arrayWholeZips.splice(indexToSplice, 1)
        })
      } else {
        payload.zips.forEach((zip) => {
          let indexToPush = arrayWholeZips.indexOf(zip)
          if (indexToPush === -1) {
            arrayWholeZips.push(zip)
          }
        })
      }
      return {
        ...state,
        zips: arrayWholeZips,
      }
    case zonesActions.PROVINCE_TO_SEND:
      let checked = false
      const provinceCheck = [...state.provinciasToSend]
      let index = provinceCheck.indexOf(state.currentProvincia.provincia)
      state.provincias[state.currentProvincia.key].cps.forEach((cp) => {
        if (
          state.zips.includes(cp.value) &&
          !provinceCheck.includes(state.currentProvincia.provincia) &&
          !checked
        ) {
          provinceCheck.push(state.currentProvincia.provincia)
          checked = true
        } else if (!checked) {
          if (index > -1) provinceCheck.splice(index, 1)
        }
      })

      return {
        ...state,
        provinciasToSend: provinceCheck,
      }

    default:
      throw new Error('Unrecognized action')
  }
}
