import { useIntl } from 'react-intl'

const computeDate = (date, days) => {
  if (!date) return
  const daysValue = parseInt(days || '0')
  const result = new Date(date)
  result.setDate(result.getDate() + daysValue)
  return result
}

export const useCampaginsValidations = () => {
  const { formatMessage } = useIntl()
  const formErrors = (form) => {
    const fm = (id) => formatMessage({ id: 'pages.campaigns.errors.' + id })
    if (!form.mercados?.length) {
      return fm(1)
    } else if (
      !form.tarifas?.length &&
      form.mercados?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      )
    ) {
      return fm(10)
    } else if (
      !form.elementos?.length &&
      !form.mercados?.some(
        (mercado) =>
          mercado.nombre === 'Pyme' ||
          mercado.nombre === 'PYME' ||
          mercado.nombre === 'Gran Consumo' ||
          mercado.nombre === 'GC' ||
          mercado.nombre === 'Caldera Centralizada' ||
          mercado.nombre === 'CC'
      )
    ) {
      return fm(2)
    } else if (!form.fechafin && !form.fechainicio) {
      return fm(3)
    } else if (new Date(form.fechainicio).getTime() > computeDate(form.fechafinvigencia, form.tiempograbacion)?.getTime()) {
      return fm(5)
    } else if (!form.comunidades?.length) {
      return fm(6)
    } else if (form.comunidades?.length && form.comunidades.some((ca) => !ca.provincias?.length)) {
      return fm(7)
      // } else if (
      //   (!form.descuento || form.descuento === 0 || form.descuento === '0') &&
      //   (!form.ingreso || form.ingreso === 0 || form.ingreso === '0')
      // ) {
      //   return fm(8)
    } else if (
      form.comunidades?.length &&
      form.comunidades.some((ca) => !ca.d_condiciones_economicas && !ca.d_condiciones_economicas_nuevo)
    ) {
      if (form.financiacion !== '01') {
        return fm(12)
      }
    } else if (form.origen === 'gremio' && !form.is_op === true) {
      return fm(11)
    } else if (typeof form.meses_financiacion === 'string') {
      const result = Math.round(parseFloat(form.meses_financiacion))
      if (result <= 0) return fm(9)
    }
  }
  return {
    formErrors,
  }
}
