export const JOBS_ACTIONS_TYPES = {
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_LOADING: 'SET_LOADING',
  SET_FAILURE: 'SET_FAILURE',
  SET_TABLE_SELECTION: 'SET_TABLE_SELECTION',
  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_SEND_FAILURE: 'SET_SEND_FAILURE',
}

export const JOBS_INITIAL_STATE = {
  data: [],
  instance_data: {},
  search: {},
  pagination: { total_reports: 0 },
  loading: false,
  detailLoading: true,
  detailError: false,
  msLoaded: false,
  error: false,
  tableSelection: [],
}

export const DEFAULT_QUERY = { _pagina: '1', _num_x_pagina: '10' }
