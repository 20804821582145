import React from 'react'
import { withStyles, Typography } from '@material-ui/core'
import { Checkbox, Box } from '@material-ui/core'

import { checkboxComponentStyles } from './checkbox.styles'

export const CheckboxComponent = withStyles(checkboxComponentStyles)(
  ({ classes, readOnly = false, required = false, value = false, hidden, ...props }) => {
    const typographyVariant = props.fontSize === 'small' ? '2' : '1'

    const handleChange = (e) => {
      let newValue
      if (e.target.checked) {
        newValue = e.target.checked
      } else if (!e.target.checked) {
        newValue = e.target.checked
      }
      props.onChange({ target: { name: props.name, value: newValue } })
    }

    return (
      <>
        {hidden ? null : (
          <>
            <Typography variant={`subtitle${typographyVariant}`} className={classes.title}>
              {props.title}
            </Typography>
            {readOnly ? (
              <Box display={'flex'} alignItems={'center'}>
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  size="small"
                  checked={value}
                  onChange={handleChange}
                  name={props.name}
                  disabled
                />
                <Typography variant="body1" color="textSecondary">
                  {props.label}
                </Typography>
              </Box>
            ) : (
              <Box display={'flex'} alignItems={'center'}>
                <Checkbox
                  className={classes.checkbox}
                  color="primary"
                  size="small"
                  checked={value}
                  onChange={handleChange}
                  name={props.name}
                />
                <Typography variant="body1" color="textSecondary">
                  {props.label}
                </Typography>
              </Box>
            )}
          </>
        )}
      </>
    )
  }
)
