import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { IPDashboardPage, IPDetailPage } from '../components'

export const IPRouter = () => {
  const permissions = useSelector((state) => state.global.permissions)

  return (
    <Switch>
      <Route exact path="/ip" component={IPDashboardPage} />
      {permissions.ver_detalle_ip ? (
        <Route exact path="/ip/:ipId" component={IPDetailPage} />
      ) : null}
      <Redirect from="*" to="/ip" />
    </Switch>
  )
}
