import { Box } from '@material-ui/core'
import React from 'react'
import { ActionButton } from '../../action-button'
import { ThemeButton } from '../../theme-button'

export const HeaderButtons = ({ iconButtons = [], fullButtons = [] }) => {
  return (
    <Box display="flex" flexWrap="wrap" width="100%">
      {iconButtons.length ? (
        <Box display="flex" flexGrow={2} flexWrap="wrap">
          {iconButtons.map((button, i) =>
            !button['hidden'] ? <ActionButton key={i} {...button} /> : null
          )}
        </Box>
      ) : null}
      {fullButtons.length ? (
        <Box display="flex" flexGrow={0.5} justifyContent="flex-end">
          {fullButtons.map((button, i) => {
            return (
              <ThemeButton key={i} color="primary" labelM="sm" {...button}>
                {button.label}
              </ThemeButton>
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}

export default HeaderButtons
