import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ThemeButton, useModalContext } from '../../../../ui'
import { usePublicOfferContext } from '../../contexts'
import { ClonePublicOfferDialog } from '../dialogs'

export const CloneBtn = ({ disabled }) => {
  const intl = useIntl()
  const history = useHistory()
  const {
    actions: { open },
  } = useModalContext()
  const { publicOffer, createPublicOffer } = usePublicOfferContext()

  const handleClick = () => {
    open({
      Component: ClonePublicOfferDialog,
      data: { publicOffer, createPublicOffer, history },
    })
  }

  return (
    <ThemeButton color="secondary" onClick={handleClick} disabled={disabled}>
      {intl.formatMessage({ id: 'pages.campaigns.detail.button.clone.label' })}
    </ThemeButton>
  )
}
