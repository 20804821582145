import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { SirDashboardPage } from '../components/dashboard'
import { SirDetailPage } from '../components/detail'

export const SirRouter = () => {
  return (
    <Switch>
      <Route exact path="/sir" component={SirDashboardPage} />
      <Route exact path="/sir/:appId" component={SirDetailPage} />
      <Redirect from="*" to="/sir" />
    </Switch>
  )
}
