import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { ThemeButton, useModalContext } from '../../../../ui'
import { useCampaignsContext } from '../../contexts'
import { CloneCampaignDialog } from '../dialogs'

export const CloneBtn = ({ disabled }) => {
  const intl = useIntl()
  const history = useHistory()
  const {
    actions: { open },
  } = useModalContext()
  const { campaign, createCampaign } = useCampaignsContext()

  const handleClick = () => {
    open({
      Component: CloneCampaignDialog,
      data: { campaign, createCampaign, history },
    })
  }

  return (
    <ThemeButton color="secondary" onClick={handleClick} disabled={disabled}>
      {intl.formatMessage({ id: 'pages.campaigns.detail.button.clone.label' })}
    </ThemeButton>
  )
}
