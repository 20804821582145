import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { requests } from '../../../core/ajax'
import { feedback } from '../../../core/feedback'

export const useJobsCalls = () => {
  const { get, getById, put, putById, post, deleteCall } = requests
  const { formatMessage } = useIntl()

  const noResponseError = () =>
    feedback('error', formatMessage({ id: 'calls.responseless.error.description' }))
  const defaultError = () =>
    feedback('error', formatMessage({ id: 'calls.default.error.description' }))

  const getBatchs = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/batch', config)
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const sendBatch = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        post('/batch', config)
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              case 503:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const getBatchsReport = useCallback(
    (config) =>
      new Promise((resolve, reject) => {
        get('/batch/report', config)
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

 const getReportsByBatchId = useCallback(
    (id, config) =>
new Promise((resolve, reject) => {
        get('/batch/report', config)
          .then((response) => {
            if (response) {
              resolve(response)
            } else {
              noResponseError()
              return reject('No response')
            }
          })
          .catch(({ response }) => {
            if (!response) {
              noResponseError()
              return reject('No response')
            }
            switch (response.status) {
              case 400:
                feedback('error', response.data.message)
                break
              default:
                defaultError()
            }
            return reject(response.status)
          })
      }
    ),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps

  const getReportByReportId = useCallback(
    (id, config) =>
      new Promise((resolve, reject) => {
          getById('/batch/report', id, config)
            .then((response) => {
              if (response) {
                resolve(response)
              } else {
                noResponseError()
                return reject('No response')
              }
            })
            .catch(({ response }) => {
              if (!response) {
                noResponseError()
                return reject('No response')
              }
              switch (response.status) {
                case 400:
                  feedback('error', response.data.message)
                  break
                default:
                  defaultError()
              }
              return reject(response.status)
            })
        }
      ),
    []
  ) // eslint-disable-line react-hooks/exhaustive-deps


  return {
    getBatchs,
    sendBatch,
    getBatchsReport,
    getReportsByBatchId,
    getReportByReportId
  }
}
