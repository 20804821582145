export const messageItemStyles = (theme) => ({
  listItem: {
    maxWidth: '87%',
    marginRight: '10%',
    marginLeft: '3%',
    marginBottom: 6,
    backgroundColor: theme.palette.grey.A100,
    borderRadius: 8,
    boxShadow: (props) => (!props.message.leido ? `-4px 4px 3px ${theme.palette.grey.A200}` : ''),
  },
  listItemOwn: {
    maxWidth: '87%',
    marginLeft: '10%',
    marginRight: '3%',
    marginBottom: 6,
    backgroundColor: theme.palette.common.white,
    borderRadius: 8,
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
})
