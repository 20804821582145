import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { useAjaxContext } from '../../../../core/ajax/contexts'
import { ThemeButton, BasicTable } from '../../../../ui'
import { downloadFile } from '../../../../core/utils'
import { CURRENT_DOCUMENTS_LIST_CONFIG, FILE_FIELD_NAMES } from './documents-view.constants'
import { DocumentViewAccordion } from '../../../../ui/document-view-accordion'

export const DocumentsViewComponent = ({ close, budget = {} }) => {
  const { formatMessage } = useIntl()
  const { getById } = useAjaxContext()
  const [loading, setLoading] = useState(false)

  const [currentDocuments, setCurrentDocuments] = useState([])
  const [fc1HistoricDocs, setFc1HistoricDocs] = useState(budget.d_FC1)

  useEffect(() => {
    getListItems()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateHistoricProps('d_FC1')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const updateHistoricProps = (historicName) => {
    const newHistoricProps = budget[historicName]
    newHistoricProps.forEach((el) => {
      el.tipo = formatMessage({
        id: `pages.sir.detail.documents.${historicName}`,
      })
    })

    switch (historicName) {
      case 'd_FC1':
        setFc1HistoricDocs(newHistoricProps)
        break
      default:
        console.debug('historicName is not defined')
    }
  }

  const getListItems = () => {
    let currentDocuments = []

    FILE_FIELD_NAMES.forEach((el) => {
      if (!budget[el] || !budget[el].id) {
        return
      }
      currentDocuments.push({
        id: budget[el].id,
        tipo: formatMessage({
          id: `pages.sir.detail.documents.${el}`,
        }),
        nombre: budget[el].nombre,
        dg_ts_insert: budget[el].dg_ts_insert,
      })
    })

    setCurrentDocuments(currentDocuments)
  }

  const handleDownloadFile = ({ id }) => {
    setLoading(true)
    getById('/documento', id)
      .then(({ data }) => {
        downloadFile(data['documento'], data['extension'], data['nombre'])
        setLoading(false)
      })
      .catch(() => setLoading(false))
  }

  const tableData = currentDocuments.sort(
    (a, b) => new Date(b['dg_ts_insert']) - new Date(a['dg_ts_insert'])
  )

  return (
    <Box p={4}>
      {currentDocuments.length === 0 ? (
        <Box textAlign="center" mb={4}>
          <Typography variant="h6" color="primary">
            {formatMessage({ id: 'pages.sir.detail.documents.notFound' })}
          </Typography>
        </Box>
      ) : (
        <>
          {currentDocuments.length !== 0 && (
            <Box mb={4}>
              {/* <Typography variant="h6" color="primary" paragraph>
                {formatMessage({ id: 'pages.sir.detail.documents.view' })}
              </Typography> */}
              <Box mb={4}>
                <Typography variant="h6" color="primary" paragraph>
                  {'Documentos Actuales'}
                </Typography>
                <BasicTable
                  config={CURRENT_DOCUMENTS_LIST_CONFIG}
                  data={tableData}
                  onClick={handleDownloadFile}
                  loading={loading}
                />
              </Box>
              {FILE_FIELD_NAMES.map((docType) => {
                const nameHistoric = docType + '_historico'
                const list = budget[nameHistoric]
                const docList = list?.map((el) => {
                  return {
                    ...el,
                    tipo: formatMessage({
                      id: `pages.sir.detail.documents.${docType}`,
                    }),
                  }
                })
                if (!list?.length) {
                  return null
                }
                return (
                  <DocumentViewAccordion
                    title={'pages.sir.detail.documents.' + nameHistoric + '.title'}
                    data={docList}
                    config={CURRENT_DOCUMENTS_LIST_CONFIG}
                    onClick={handleDownloadFile}
                    loading={loading}
                  />
                )
              })}
              {fc1HistoricDocs.length !== 0 && (
                <DocumentViewAccordion
                  title={'pages.sir.detail.documents.d_FC1'}
                  data={fc1HistoricDocs}
                  config={CURRENT_DOCUMENTS_LIST_CONFIG}
                  onClick={handleDownloadFile}
                  loading={loading}
                />
              )}
            </Box>
          )}
        </>
      )}
      <Box display="flex" justifyContent="center">
        <ThemeButton onClick={() => close()} color="primary" labelM="lg" loading={loading}>
          {formatMessage({ id: 'global.close' })}
        </ThemeButton>
      </Box>
    </Box>
  )
}
