import { EEII_TABLE_COLUMNS } from '../constants'

export const eeiiDataPreparation = (data, { intl }, combos) => {
  return data.map((item) => {
    const formattedColumns = {}
    EEII_TABLE_COLUMNS.forEach((column) => {
      if (column.type === 'date' && item[column.field]) {
        formattedColumns[column.field] = intl.formatDate(item[column.field])
      }
      if (column.infoType === 'logic') {
        formattedColumns[column.field] = item[column.field] ? 'Sí' : 'No'
      }
      if (column.infoType === 'toRound') {
        formattedColumns[column.field] = item[column.field] ? item[column.field].toFixed(2) : '--'
      }
    })
    return {
      ...item,
      ...formattedColumns,
      tableData: {
        ...item.tableData,
      },
    }
  })
}
