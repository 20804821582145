import { requests } from '../../../core/ajax'
const { get, post } = requests

//Call: getNumbers(state.addressForm,value)
export const getNumbers = (address, { id_via }) => {
  const {
    cp_municipio: { cod_postal, municipio },
  } = address
  return new Promise((resolve, reject) => {
    const config = { params: { cod_postal, municipio, id_via, numero: '' } }
    get('/viaje_cliente_instalador/obtener_numeros', config).then(({ data }) => resolve(data))
  })
}

//Call: getFlats(state.addressForm,value)
export const getFlats = (address, { anexo, numero }) => {
  const {
    cp_municipio: { cod_postal, municipio },
    direccion: { id_via },
  } = address
  return new Promise((resolve, reject) => {
    const config = { params: { cod_postal, municipio, id_via, anexo, numero } }
    get('/viaje_cliente_instalador/obtener_piso_y_resto_datos', config).then(({ data }) =>
      resolve(data)
    )
  })
}

export const getZipMunicipalities = (cp_municipio) => {
  return new Promise((resolve, reject) => {
    const config = { params: { cp_municipio } }
    get('/viaje_cliente_instalador/obtener_cp_municipio', config).then(({ data }) => resolve(data))
  })
}

export const getInstallers = (nombre) => {
  return new Promise((resolve, reject) => {
    const config = { params: { nombre } }
    get('/empresa_ca/obtener_empresa_filiales', config).then(({ data }) => resolve(data))
  })
}

//Call: getStreets(state.addressForm,value)
export const getStreets = (address, direccion) => {
  const {
    cp_municipio: { cod_postal, municipio },
  } = address
  return new Promise((resolve, reject) => {
    const config = { params: { cod_postal, municipio, direccion } }
    get('/viaje_cliente_instalador/obtener_calles', config).then(({ data }) => resolve(data))
  })
}

export const postPath = (payload) => {
  return new Promise((resolve, reject) => {
    post('/excepciones_caminos', payload)
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err))
  })
}
