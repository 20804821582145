import es_ES from './es_ES.json'

const LOCALE_DEFAULT = 'es-ES'

const availableMessages = {
  'es-ES': es_ES,
}

const locale = availableMessages[navigator.language] ? navigator.language : LOCALE_DEFAULT

const messages = availableMessages[locale]

export { messages, locale }
