import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { useApplicationUserPermitsContext } from '../../../../pages/applications/contexts'

export const AppsHeader = () => {
  const intl = useIntl()
  const combos = useSelector((state) => state.combos)
  const { filters, headerButtons } = useApplicationUserPermitsContext()

  const data = filters.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: intl.formatMessage({
        id: 'pages.applications.filters.' + item.name + '.title',
      }),
    }
  })
  const buttonsComponent = headerButtons()
  return (
    <>
      <FilterBox filters={data}>{buttonsComponent}</FilterBox>
    </>
  )
}
