import React, { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Button} from "@material-ui/core";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HistoryIcon from '@material-ui/icons/History';

import { useModalContext } from '../../../../ui/modal'
import { ConfirmSendDialog } from '../dialogs'
import { useJobsContext } from "../../contexts";

export const TableActions = (props) => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const {executeBatch} = useJobsContext()
  const {
    actions: { open, close },
  } = useModalContext()
  const batch = props.rowData
  const jobId = props.rowData['id']

  const handleHistoryClick = useCallback(
    (e, row) => {
      history.push(`/jobs/historic/?batch_id=${jobId}`)
    },
    [history, jobId]
  )

  const handleOpenModal = (props) => {
    open({
      Component: ConfirmSendDialog,
      data: { batch: batch, executeFunction: executeBatch,history,  },
    })
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent:'right', gap: '5px' }}>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpenModal}
        startIcon={<PlayArrowIcon />}
      >
        {formatMessage({ id: 'pages.jobs.table.actions.run' })}
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={handleHistoryClick}
        startIcon={<HistoryIcon />}
      >
        {formatMessage({ id: 'pages.jobs.table.actions.history' })}
      </Button>
    </div>
  )
}
