import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { FilterBox } from '../../../../ui'
import { COLLABORATORS_FILTERS } from '../../constants'

export const CollaboratorsFilter = () => {
  const { formatMessage } = useIntl()
  const combos = useSelector((state) => state.combos)

  const data = COLLABORATORS_FILTERS.map((item, i) => {
    if (item.comboId) {
      item.values = combos[item.comboId] && combos[item.comboId].data
    }
    return {
      ...item,
      label: formatMessage({
        id: 'pages.collaborators.filters.' + item.name + '.title',
      }),
    }
  })

  return <FilterBox filters={data}></FilterBox>
}
