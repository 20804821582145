import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import { TextInput, SelectInput, ThemeButton, useModalContext } from '../../../../../ui'
import { NumberInput, DateInput } from '../../../../../ui'
import {
  BUDGET_NOT_SENT_YET_STATES,
  CAN_UPDATE_ZEUS_CODE,
  HAS_PAID_STATES,
  ALLOWED_IF_L4,
} from './info-panel.constants'
import { Restrictions } from '../../restrictions'
import { ViewDocumentsBtn } from '../../view-documents'
import { useApplicationUserPermitsContext } from '../../../../../pages/applications/contexts'
import { useAppContext } from '../../../contexts'
import { GRID_ITEM } from './info-panel.constants'
import { SendIntAndExtBudgetDialog } from '../../dialogs'
import { ALLOWED_SEND_BUDGET_STATES } from '../../../constants'

export const InfoBudgetExt = ({ fields }) => {
  const { application, disabledForUser, sendBudget } = useAppContext()
  const { seesDocumentPanel } = useApplicationUserPermitsContext()
  const defaultDisabled = disabledForUser()
  const { formatMessage } = useIntl()
  const userRole = useSelector((state) => state.global.role)

  const isExtBudget = true
  const canEditCodExpZeus =
    defaultDisabled ||
    !(
      CAN_UPDATE_ZEUS_CODE[application.estado] ||
      (ALLOWED_IF_L4[application.estado] &&
        !application.l4_exception &&
        application.originally_auto_budget)
    )

  const {
    actions: { open },
  } = useModalContext()

  const handleSendPresupuesto = () => {
    open({
      Component: SendIntAndExtBudgetDialog,
      data: { application, sendBudget, isExtBudget },
      type: 'responsive',
    })
  }

  return (
    <>
      <Grid container spacing={3} style={{ marginBottom: '1em' }}>
        <Grid {...GRID_ITEM.SMALL}>
          <SelectInput {...{ ...fields.estado }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.solicitud_id }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fecha_solicitud }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.fecha_puesta_servicio }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.tipo_solicitud }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.cidi }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <SelectInput {...{ ...fields.tipo_vivienda }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.numero_tarifa_aplicada }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput {...fields.ppto_ext_precio_instalacion_tuberia} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput
            {...{
              ...fields.ppto_ext_descuento_acometida,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.ppto_ext_fecha_solicitud }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <DateInput {...{ ...fields.ppto_ext_fecha_caducidad }} />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput {...{ ...fields.ppto_ext_distancia_acometida }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput
            {...{
              ...fields.ppto_ext_distancia_real,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.rango_presion_potenciales }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput
            {...{
              ...fields.rango_presion,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <NumberInput
            {...{
              ...fields.ppto_ext_plazo_dias_obras,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput {...{ ...fields.cod_expediente_zeus, readOnly: canEditCodExpZeus }} />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <SelectInput
            {...{
              ...fields.tipo_gas,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <SelectInput
            {...{
              ...fields.distribuidora,
              readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          {fields.ppto_ext_consumo_anual_real.value !== null ? (
            <NumberInput
              {...{
                ...fields.ppto_ext_consumo_anual_real,
                readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
              }}
            />
          ) : (
            <NumberInput
              {...{
                ...fields.ppto_ext_consumo_anual,
                readOnly: defaultDisabled || !BUDGET_NOT_SENT_YET_STATES[application.estado],
              }}
            />
          )}
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput
            {...{
              ...fields.num_encargo_goda,
              readOnly: defaultDisabled || !HAS_PAID_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput
            {...{
              ...fields.num_gor_icaro,
              readOnly: defaultDisabled || !HAS_PAID_STATES[application.estado],
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput
            {...{
              ...fields.tipo_pago,
              readOnly: true,
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.SMALL}>
          <TextInput
            {...{
              ...fields.iban_sociedad,
              readOnly: true,
            }}
          />
        </Grid>
        <Grid {...GRID_ITEM.FULL}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              {!seesDocumentPanel ? (
                <Box mr={1}>
                  <ViewDocumentsBtn />
                </Box>
              ) : null}
              <Restrictions lines={application.condicionante_obras?.split('#')} />
            </Box>
            {userRole === 'call_center' ? (
              <Box alignItems="center">
                <ThemeButton
                  color="primary"
                  size="small"
                  onClick={handleSendPresupuesto}
                  disabled={!ALLOWED_SEND_BUDGET_STATES[application['estado']] || defaultDisabled}
                >
                  {formatMessage({
                    id: 'pages.application.detail.sendButton.label',
                  })}
                </ThemeButton>{' '}
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
