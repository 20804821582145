import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Container, Typography, withStyles } from '@material-ui/core'

import { CampaignsFilter, CampaignsTable } from '../../../../features/campaigns'
import { useCombos } from '../../../../features/combos/hooks'
import { CAMPAIGNS_COMBOS_IDS } from '../../constants'
import { campaignsDashboardStyles } from './campaigns-dashboard.styles'

export const CampaignsDashboardPage = withStyles(campaignsDashboardStyles)(({ classes }) => {
  useCombos(CAMPAIGNS_COMBOS_IDS)
  const { formatMessage } = useIntl()

  return (
    <Container className={classes.container} maxWidth={false}>
      <Box mb={2}>
        <Typography variant="h4" color="textSecondary">
          {formatMessage({ id: 'pages.campaigns.dashboard.title' })}
        </Typography>
      </Box>
      <Box mb={2} display="flex">
        <CampaignsFilter />
      </Box>
      <Box>
        <CampaignsTable />
      </Box>
    </Container>
  )
})
