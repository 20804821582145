//Material table makes the alignment of some columns to the right highly suboptimal.
//We need to make sure it doesn't bring problems in large designs
//Meaning that, in our case, the um of columns + action sizes should be at least 1200

export const MANAGERS_TABLE_COLUMNS = [
  {
    field: 'nombre',
    align: 'left',
    width: 250,
  },
  {
    field: 'apellidos',
    align: 'left',
    width: 250,
  },
  {
    field: 'rol',
    align: 'left',
    width: 180,
    exportType: 'combo',
    comboId: 'roles',
  },
  {
    field: 'username',
    align: 'left',
    width: 250,
  },
  {
    field: 'email',
    align: 'left',
    width: 250,
  },
  {
    field: 'telefono',
    align: 'left',
    width: 250,
  },
  { field: 'fecha_creacion', align: 'left', width: 180, type: 'date' },
]

export const MANAGERS_TABLE_ACTIONS = [
  {
    field: 'active',
    width: 130,
    align: 'center',
    sorting: false,
  },
  {
    field: 'actions',
    width: 130,
    align: 'center',
    sorting: false,
  },
]

export const MANAGERS_FILTERS = [
  {
    name: 'activo',
    filterType: 'switch',
  },

  {
    name: 'rol',
    label: 'Rol',
    comboId: 'roles',
    size: 'small',
    multiple: true,
  },
]

export const MARKETERS_DEFAULT_QUERY = {
  _pagina: '1',
  _num_x_pagina: '10',
}
