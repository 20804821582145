import React from 'react'

import { CampaignsContext } from '../../features/campaigns'
import { CampaignsRouter } from './routers'

export const CampaignsPage = (props) => {
  return (
    <CampaignsContext>
      <CampaignsRouter {...props} />
    </CampaignsContext>
  )
}
