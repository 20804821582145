import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useIntl } from 'react-intl'

import { ThemeButton, useModalContext, ConfirmationDialog } from '../../../../ui'
import { EditDevolucionDialog } from '../dialogs'
import { useAppContext } from '../../contexts'

export const ValidateDevolution = () => {
  const {
    application,
    getApplication,
    putHoldApp,
    putCancelDevolutuinWithoutTaskdApp,
  } = useAppContext()
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const handleOpenCancelDialog = () => {
    putCancelDevolutuinWithoutTaskdApp(application.id)
  }

  const handleOpenDevolution = () => {
    open({
      Component: EditDevolucionDialog,
      data: { application, getApplication },
    })
  }

  const handleHoldApp = () => {
    open({
      Component: ConfirmationDialog,
      data: {
        title: formatMessage({ id: 'pages.application.view.holdApp.title' }),
        text: formatMessage({ id: 'pages.application.view.holdApp.text' }),
        yesText: formatMessage({ id: 'pages.application.view.holdApp.yes' }),
        noText: formatMessage({ id: 'pages.application.view.holdApp.no' }),
        yesAction: () => {
          putHoldApp(application.id)
        },
      },
      type: 'centered',
    })
  }

  return (
    <Box p="1">
      <Typography paragraph variant="body2" style={{ fontFamily: 'FSEmeric-Medium' }}>
        {formatMessage({ id: 'pages.application.view.detail.validate.devolution.title' })}
      </Typography>
      <Box flexWrap="wrap" display="flex" flexDirection="row">
        <ThemeButton
          size="small"
          style={{ marginRight: '1em', marginBottom: '1em' }}
          onClick={handleHoldApp}
        >
          {formatMessage({
            id: 'pages.application.view.detail.validate.hold.validateButton.label',
          })}
        </ThemeButton>
        <ThemeButton size="small" color="default" onClick={handleOpenCancelDialog}>
          {formatMessage({
            id: 'pages.application.view.detail.validate.reject.cancelButton.label',
          })}
        </ThemeButton>
        <Box ml={1}>
          <ThemeButton size="small" color="default" onClick={handleOpenDevolution}>
            {formatMessage({
              id: 'pages.application.view.detail.validate.devolucion.cancelButton.label',
            })}
          </ThemeButton>
        </Box>
      </Box>
    </Box>
  )
}
